import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import moment from 'moment';
import { OptionType } from 'react-switch-selector/dist/SwitchSelector';
import Table from 'components/Table';
import { couponCollection } from 'shared/constants/global';
import { checkCouponFirebase } from 'shared/functions/global';
import Headline from 'components/Headline';
import Button from 'components/Button';
import styles from './styles.module.scss';

const skeletonTable: TableDataType[][] = new Array(3).fill([
  { type: 'text', value: '' },
  { type: 'text', value: '' },
  { type: 'image', value: '' },
  { type: 'qr', value: '' },
]);

export default function Coupons() {
  const { t } = useTranslation();

  const header = ['Code', 'Rabatt (%)', 'Datum', 'Benutzt?'];

  const [couponData, setCouponData] = useState<TableDataType[][]>([]);
  const [isPending, setIsPending] = useState(false);
  const [isNewCode, setIsNewCode] = useState(false);
  const [currentRabattChangeType, setCurrentRabattChangeType] = useState('10');

  const rabattTypes: OptionType[] = [
    { value: '10', label: '10%' },
    { value: '20', label: '20%' },
    { value: '30', label: '30%' },
  ];

  const getCouponData = async () => {
    setIsPending(true);
    const data: TableDataType[][] = (await couponCollection.get()).docs.map(item => [
      { type: 'text', value: item.data().coupon },
      { type: 'text', value: item.data().rabatt },
      { type: 'text', value: moment.unix(item.data().created).format('DD.MM.YYYY') },
      { type: 'boolean', value: item.data().used.toString() },
    ]);
    setCouponData(data);
    setIsPending(false);
  };

  const updateRabattType = (value: any) => {
    setCurrentRabattChangeType(value.value as string);
  };

  const addCoupon = () => {
    checkCouponFirebase(parseFloat(currentRabattChangeType), getCouponData);
  };

  useEffect(() => {
    getCouponData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.wrapper}>
      <Headline level={1} classLevel={3} className={styles.header}>
        Rabattcodes
      </Headline>
      {isNewCode ? (
        <>
          <div className="py-20 flex space-x-30">
            <div className="my-auto">Wieviel Rabatt?</div>
            <div className="w-100 my-auto">
              <Select
                name="rabatt"
                className="react-select-container"
                classNamePrefix="react-select"
                unstyled
                options={rabattTypes}
                value={
                  currentRabattChangeType !== ''
                    ? rabattTypes.filter(st => st.value === currentRabattChangeType)
                    : rabattTypes[0]
                }
                onChange={updateRabattType}
              />
            </div>
          </div>
          <div className="flex space-x-20 pb-20">
            <Button onClick={() => addCoupon()}>Generieren</Button>
            <Button buttonStyle="white" onClick={() => setIsNewCode(false)}>
              Abbrechen
            </Button>
          </div>
        </>
      ) : (
        <div className="py-20">
          <Button onClick={() => setIsNewCode(true)}>Rabattcode generieren</Button>
        </div>
      )}

      {isPending || !couponData ? (
        <Table header={Array.from(new Array(4))} body={skeletonTable} className="w-full" isSkeleton />
      ) : (
        <Table header={header} body={couponData} className="w-full" />
      )}
    </div>
  );
}

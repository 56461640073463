import React, { useContext, useMemo, useState, useEffect, Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { HeartIcon } from '@heroicons/react/outline';
import InfiniteScroll from 'react-infinite-scroll-component';
import Card from 'components/Card';
import Skeleton from 'components/skeleton';
import { FilterContext } from 'providers/FilterProvider';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import { getRoundedValue } from 'shared/functions/global';
import { difficultyGroup } from 'shared/constants/filter';
import Button from 'components/Button';
import styles from './styles.module.scss';

const RecipeSection: React.FC = () => {
  const {
    initialNutrition,
    initialDifficulty,
    initialEatingHabits,
    initialIntolerances,
    myFavoritesValue,
    myRecipesValue,
    myCommunityRecipesValue,
    caloriesRange,
    carbohydratesRange,
    proteinRange,
    fatRange,
    ingredientsFilter,
    ingredientsNotIncludeFilter,
    nutritionSearchValue,
  } = useContext(FilterContext);

  const { recipesList, favoritesRecipesList } = useContext(MainContext);

  const location = useLocation<LocationState>();

  const { userData, theme } = useContext(AuthContext);

  const perPage = 20;
  const [lastObjectPosition, setLastObjectPosition] = useState(20);
  const [loadedRecipes, setLoadedRecipes] = useState<any[]>([]);

  const [currentLoadedRecipeIndex, setCurrentLoadedRecipeIndex] = useState(0);
  const [isPending, setIsPending] = useState(false);

  const loadRecipes = () => {
    setLoadedRecipes(currentRecipes => {
      return [...currentRecipes, ...recipesFilteredList.slice(lastObjectPosition, lastObjectPosition + perPage)];
    });

    setLastObjectPosition(currentValue => {
      return currentValue + perPage;
    });
  };

  const history = useHistory();

  const recipesFilteredList: RecipesType[] = useMemo(() => {
    if (!recipesList) return [];
    return recipesList.filter(
      element =>
        (initialDifficulty.length === 0 ||
          initialDifficulty.findIndex(
            item => element.difficulty_level === difficultyGroup.findIndex(difficulty => difficulty === item) + 1
          ) > -1) &&
        (initialNutrition.length === 0 || initialNutrition.findIndex(item => element[item.toLowerCase()]) > -1) &&
        (initialEatingHabits.length === 0 || initialEatingHabits.every(item => element[item.toLowerCase()]) === true) &&
        (initialIntolerances.length === 0 ||
          initialIntolerances.findIndex(item => element[item.toLowerCase()]) === -1) &&
        ((myRecipesValue === true && element.userRecipe === true) ||
          (myCommunityRecipesValue === true && element.communityRecipe === true) ||
          (myFavoritesValue === true &&
            favoritesRecipesList?.find(item => item.origId === element.uid) !== undefined) ||
          (myRecipesValue === false && myCommunityRecipesValue === false && myFavoritesValue === false)) &&
        parseFloat(element.kcal_total) >= caloriesRange.min &&
        parseFloat(element.kcal_total) <= (caloriesRange.max === 3000 ? 50000 : caloriesRange.max) &&
        parseFloat(element.carbohydrates_total) >= carbohydratesRange.min &&
        parseFloat(element.carbohydrates_total) <= (carbohydratesRange.max === 500 ? 50000 : carbohydratesRange.max) &&
        parseFloat(element.protein_total) >= proteinRange.min &&
        parseFloat(element.protein_total) <= (proteinRange.max === 150 ? 50000 : proteinRange.max) &&
        parseFloat(element.fat_total) >= fatRange.min &&
        parseFloat(element.fat_total) <= (fatRange.max === 250 ? 50000 : fatRange.max) &&
        element.name.toLowerCase().includes(nutritionSearchValue.toLowerCase()) &&
        (ingredientsFilter.length === 0 ||
          ingredientsFilter.findIndex(
            (name: string) =>
              element.ingredients.findIndex((item: Ingredients) => item.name.toLowerCase() === name.toLowerCase()) ===
              -1
          ) === -1) &&
        (ingredientsNotIncludeFilter.length === 0 ||
          ingredientsNotIncludeFilter.findIndex(
            (name: string) =>
              element.ingredients.findIndex((item: Ingredients) => item.name.toLowerCase() === name.toLowerCase()) > -1
          ) === -1)
    );
  }, [
    caloriesRange.max,
    caloriesRange.min,
    carbohydratesRange.max,
    carbohydratesRange.min,
    fatRange.max,
    fatRange.min,
    favoritesRecipesList,
    ingredientsFilter,
    ingredientsNotIncludeFilter,
    initialDifficulty,
    initialEatingHabits,
    initialIntolerances,
    initialNutrition,
    myFavoritesValue,
    myRecipesValue,
    myCommunityRecipesValue,
    nutritionSearchValue,
    proteinRange.max,
    proteinRange.min,
    recipesList,
  ]);

  function goToDetailPage(uid: string, index: number) {
    const thisScrollPos = document.getElementById('mainContainer')?.scrollTop;

    history.push({
      pathname: `/nutrition/recipes/${uid}`,
      state: { from: 'nutrition', scrollPos: index, recipeUid: uid },
    });
  }

  const loadPreviousRecipes = () => {
    setIsPending(true);
    setLastObjectPosition(20);
    setLoadedRecipes(currentRecipes => {
      return [...recipesFilteredList.slice(0, 20)];
    });
    setCurrentLoadedRecipeIndex(0);
    setIsPending(false);
  };

  // Definiere die Zielwerte des Users
  const userValues = {
    calories: { min: 2950, max: 3050 },
    carbohydrates: { min: 347, max: 385 },
    protein: { min: 170, max: 195 },
    fat: { min: 70, max: 92 },
  };

  // Definiere eine Funktion, die ein zufälliges Rezept für eine bestimmte Kategorie zurückgibt
  function getRandomRecipeForCategory(category: any) {
    const recipesForCategory = recipesFilteredList.filter((recipe: any) => recipe[category] === true);
    const randomIndex = Math.floor(Math.random() * recipesForCategory.length);
    return recipesForCategory[randomIndex];
  }

  // Definiere eine Funktion, die prüft, ob die Gesamtwerte der ausgewählten Rezepte den Zielwerten des Users entsprechen
  function checkTotalValues(meals: any) {
    let kcalValue = 0;
    let carbohydratesValue = 0;
    let proteinValue = 0;
    let fatValue = 0;

    const totalValues = meals.reduce(
      (acc: any, meal: any) => {
        kcalValue += parseFloat(meal.kcal_total);
        carbohydratesValue += parseFloat(meal.carbohydrates_total);
        proteinValue += parseFloat(meal.protein_total);
        fatValue += parseFloat(meal.fat_total);
        return {
          kcal_total: kcalValue,
          carbohydrates_total: carbohydratesValue,
          protein_total: proteinValue,
          fat_total: fatValue,
        };
      },
      { calories: 0, carbohydrates: 0, protein: 0, fat: 0 }
    );

    console.log('Werte');
    console.log(totalValues.kcal_total);
    console.log(totalValues.carbohydrates_total);
    console.log(totalValues.protein_total);
    console.log(totalValues.fat_total);

    return (
      totalValues.kcal_total >= userValues.calories.min &&
      totalValues.kcal_total <= userValues.calories.max &&
      totalValues.carbohydrates_total >= userValues.carbohydrates.min &&
      totalValues.carbohydrates_total <= userValues.carbohydrates.max &&
      totalValues.protein_total >= userValues.protein.min &&
      totalValues.protein_total <= userValues.protein.max &&
      totalValues.fat_total >= userValues.fat.min &&
      totalValues.fat_total <= userValues.fat.max
    );
  }

  function generateMealPlan() {
    let breakfast = getRandomRecipeForCategory('breakfast');
    let lunch = getRandomRecipeForCategory('lunch');
    let dinner = getRandomRecipeForCategory('dinner');
    let snack = getRandomRecipeForCategory('snacks');

    let meals = [breakfast, lunch, dinner, snack];

    console.log(meals);

    let checkCounter = 0;

    while (!checkTotalValues(meals)) {
      checkCounter += 1;
      breakfast = getRandomRecipeForCategory('breakfast');
      lunch = getRandomRecipeForCategory('lunch');
      dinner = getRandomRecipeForCategory('dinner');
      snack = getRandomRecipeForCategory('snacks');
      meals = [breakfast, lunch, dinner, snack];
    }

    console.log('fertig');
    console.log(checkCounter);

    return meals;
  }

  const test = () => {
    // Beispielaufruf der Funktion
    const mealPlan = generateMealPlan();

    // Beispielausgabe des generierten Speiseplans
    console.log('gefunden');
    console.log(mealPlan);
  };

  useEffect(() => {
    if (recipesList) {
      setLoadedRecipes(
        recipesList.slice(0, 20).map((recipes: any) => {
          return {
            ...recipes,
          };
        })
      );
    }
  }, [recipesList]);

  useEffect(() => {
    setLastObjectPosition(20);

    if (
      location?.state?.scrollPos !== undefined &&
      location?.state?.recipeUid !== undefined &&
      recipesFilteredList.length > 20
    ) {
      let findIndex = recipesFilteredList.findIndex(x => x.uid === location.state.recipeUid);

      if (findIndex !== undefined) {
        if (findIndex === 0) {
          findIndex = 1;
        }
        setCurrentLoadedRecipeIndex(findIndex);

        setLastObjectPosition(findIndex + 19);

        setLoadedRecipes(
          recipesFilteredList.slice(findIndex - 1, findIndex + 19).map((recipes: any) => {
            return {
              ...recipes,
            };
          })
        );
      } else {
        setLoadedRecipes(
          recipesFilteredList.slice(location.state.scrollPos - 1, location.state.scrollPos + 19).map((recipes: any) => {
            return {
              ...recipes,
            };
          })
        );
      }

      history.replace({ state: {} });
    } else {
      setLoadedRecipes(
        recipesFilteredList.slice(0, 20).map((recipes: any) => {
          return {
            ...recipes,
          };
        })
      );
    }
  }, [recipesFilteredList]);

  return (
    <>
      <div className="font-extralight pb-20">{recipesFilteredList.length} Rezepte gefunden</div>
      {currentLoadedRecipeIndex > 0 && (
        <div className="pb-20">
          <Button className="py-2 uppercase w-full md:w-auto" onClick={() => loadPreviousRecipes()}>
            Vorherige Rezepte laden
          </Button>
        </div>
      )}

      {/* skeleton loading */}
      {!recipesList ? (
        <div className={styles.wrapper}>
          {Array.from(Array(15).keys()).map((_, index) => (
            <Card className="mx-auto" key={`skeleton${index}`} isLoading>
              <Skeleton className={styles.skeletonTitle} translucent />
              <div className={styles.description}>
                <div className={styles.center}>
                  <Skeleton className={styles.skeletonValue} translucent />
                  <p className="text-8">KC</p>
                </div>
                <div className={styles.center}>
                  <Skeleton className={styles.skeletonValue} translucent />
                  <p className="text-8">EW</p>
                </div>
                <div className={styles.center}>
                  <Skeleton className={styles.skeletonValue} translucent />
                  <p className="text-8">FT</p>
                </div>
                <div className={styles.center}>
                  <Skeleton className={styles.skeletonValue} translucent />
                  <p className="text-8">KH</p>
                </div>
              </div>
            </Card>
          ))}
        </div>
      ) : (
        <>
          {loadedRecipes.length > 0 && (
            <InfiniteScroll
              dataLength={loadedRecipes.length} // This is important field to render the next data
              next={() => loadRecipes()}
              scrollableTarget={document.getElementById('mainContainer')}
              hasMore={lastObjectPosition < recipesFilteredList.length}
              loader={<h4>Loading...</h4>}
            >
              <div className={styles.wrapper}>
                {loadedRecipes.map((recipe: RecipesType, index: number) => (
                  <Fragment key={index}>
                    {recipe.uid !== undefined && (
                      <div
                        className="cursor-pointer"
                        key={index}
                        onClick={() => goToDetailPage(recipe.uid, index)}
                        onKeyDown={() => goToDetailPage(recipe.uid, index)}
                        aria-hidden="true"
                        id={recipe.uid}
                      >
                        <Card
                          image={
                            recipe.imageUrl !== ''
                              ? recipe.imageUrl
                              : theme?.logo !== undefined && userData?.role !== 2
                              ? theme.logo
                              : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
                          }
                          className="mx-auto relative"
                        >
                          {favoritesRecipesList?.find(item => item.origId === recipe.uid) !== undefined && (
                            <div className="absolute top-7 right-10 bg-lightGray rounded-full p-5">
                              <div>
                                <HeartIcon width={20} height={20} className="text-accentColor" fill="#C97132" />
                              </div>
                            </div>
                          )}
                          {recipe.userRecipe && (
                            <div className="absolute top-10 left-10 bg-accentColor text-buttonTextColor text-10 font-bold rounded-full px-10 py-1">
                              <div>Eigenes Rezept</div>
                            </div>
                          )}
                          {recipe.communityRecipe && (
                            <div className="absolute top-10 left-10 bg-accentColor text-buttonTextColor text-10 font-bold rounded-full px-10 py-1">
                              <div>Community Rezept</div>
                            </div>
                          )}
                          <div className={styles.title}>{recipe.name}</div>
                          <div className={styles.description}>
                            <div className={styles.center}>
                              <p>{getRoundedValue(recipe.kcal_total)}</p>
                              <p className="text-8">KC</p>
                            </div>
                            <div className={styles.center}>
                              <p>{getRoundedValue(recipe.protein_total)}</p>
                              <p className="text-8">EW</p>
                            </div>
                            <div className={styles.center}>
                              <p>{getRoundedValue(recipe.fat_total)}</p>
                              <p className="text-8">FT</p>
                            </div>
                            <div className={styles.center}>
                              <p>{getRoundedValue(recipe.carbohydrates_total)}</p>
                              <p className="text-8">KH</p>
                            </div>
                          </div>
                        </Card>
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            </InfiniteScroll>
          )}
        </>
      )}
    </>
  );
};

export default RecipeSection;

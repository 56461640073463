import React, { createContext, ReactNode, useState, useContext, useEffect, useMemo } from 'react';
import firebase from 'services/firebase';
import axios from 'axios';
import { AuthContext } from 'providers/AuthProvider';

type ContextProps = {
  userPlanList: PlanType[] | undefined;
  planList: PlanType[] | undefined;
  recipesList: RecipesType[] | undefined;
  ingredientList: IngredientType[] | undefined;
  favoritesRecipesList: RecipeFavorites[] | undefined;
  favoritesPlansList: PlansFavorites[] | undefined;
  favoritesIngredientsList: any[] | undefined;
  userMessagesList: Messages[] | undefined;
  purchaseList: PurchaseListType[] | undefined;
  quickList: any[] | undefined;
  tempRecipeObject: any;
  accessTokenString: string;
  setAccessTokenString: Function;
  setTempRecipeObject: Function;
};

type Props = {
  children: ReactNode;
};

export const MainContext = createContext<ContextProps>({
  userPlanList: [],
  planList: [],
  recipesList: [],
  ingredientList: [],
  favoritesRecipesList: [],
  favoritesPlansList: [],
  userMessagesList: [],
  purchaseList: [],
  quickList: [],
  favoritesIngredientsList: [],
  tempRecipeObject: {},
  setTempRecipeObject: () => {},
  accessTokenString: '',
  setAccessTokenString: () => {},
});

export const MainProvider = ({ children }: Props) => {
  // Get User Data
  const authContext = useContext(AuthContext);
  const { tenant, tenantData } = useContext(AuthContext);
  const [loadOtherData, setLoadOtherData] = useState(false);

  const userPlansFavoritesCollection = firebase
    .firestore()
    .collection(`tenants/${tenant}/users`)
    .doc(authContext.user?.uid)
    .collection('favorites_plans');

  // Get Plan Data
  const [planMainList, setPlanMainList] = useState<PlanType[]>();
  const [planUserList, setPlanUserList] = useState<PlanType[]>();
  const planList = useMemo(() => {
    if (planMainList && planUserList) {
      return [...planUserList, ...planMainList];
    }
    return undefined;
  }, [planMainList, planUserList]);
  const userPlanList = useMemo(() => {
    if (planUserList) {
      return [...planUserList];
    }
    return undefined;
  }, [planUserList]);

  const planCollection = firebase.firestore().collection('plans').orderBy('startDate.seconds', 'desc');
  const userPlansCollection = firebase
    .firestore()
    .collection(`tenants/${tenant}/users`)
    .doc(authContext.user?.uid)
    .collection('plans')
    .orderBy('startDate.seconds', 'desc');

  // Get Recipe Data
  const [recipesMainList, setRecipesMainList] = useState<RecipesType[]>();
  const [recipesUserList, setRecipesUserList] = useState<RecipesType[]>();
  const [recipesTenantList, setRecipesTenantList] = useState<RecipesType[]>();
  const recipesList = useMemo(() => {
    if (
      tenantData?.settings?.functions?.tortijaRecipes !== undefined &&
      tenantData?.settings?.functions?.tortijaRecipes === false
    ) {
      if (recipesUserList && recipesTenantList) {
        return [...recipesUserList, ...recipesTenantList];
      }
    } else if (recipesMainList && recipesUserList && recipesTenantList) {
      return [...recipesUserList, ...recipesTenantList, ...recipesMainList];
    }

    return undefined;
  }, [recipesMainList, recipesUserList, recipesTenantList]);

  // Set temporary recipe object
  const [tempRecipeObject, setTempRecipeObject] = useState<any[]>();

  // Set Fatsecret Access Token
  const [accessTokenString, setAccessTokenString] = useState('');

  const recipesCollection = firebase.firestore().collection('recipes').orderBy('created', 'desc');
  const userRecipesCollection = firebase
    .firestore()
    .collection(`tenants/${tenant}/users`)
    .doc(authContext.user?.uid)
    .collection('recipes');

  const tenantRecipesCollection = firebase.firestore().collection(`tenants/${tenant}/recipes`);

  // Get Favourite Recipe Data
  const [favoritesRecipesList, setFavoritesRecipesList] = useState<RecipeFavorites[]>();
  const userRecipesFavoritesCollection = firebase
    .firestore()
    .collection(`tenants/${tenant}/users`)
    .doc(authContext.user?.uid)
    .collection('favorites_recipes');

  // Get Favourite Ingredient Data
  const [favoritesIngredientsList, setFavoritesIngredientsList] = useState<any[]>();
  const userIngredientsFavoritesCollection = firebase
    .firestore()
    .collection(`tenants/${tenant}/users`)
    .doc(authContext.user?.uid)
    .collection('favorites_ingredients')
    .orderBy('dateAdded', 'desc');

  // Get Favourite Plan Data
  const [favoritesPlansList, setFavoritesPlansList] = useState<PlansFavorites[]>([]);

  // Get PurchaseList Data
  const [purchaseList, setPurchaseList] = useState<PurchaseListType[]>([]);
  const userPurchaseListCollection = firebase
    .firestore()
    .collection(`tenants/${tenant}/users`)
    .doc(authContext.user?.uid)
    .collection('purchaseList');

  const tenantIngridientCollection = firebase.firestore().collection(`tenants/${tenant}/ingredients`);

  // Get Ingredient Data
  const [ingredientMainList, setIngredientMainList] = useState<IngredientType[]>();
  const [ingredientUserList, setIngredientUserList] = useState<IngredientType[]>();
  const [ingredientTenantList, setIngredientTenantList] = useState<IngredientType[]>();
  const ingredientList = useMemo(() => {
    if (ingredientMainList && ingredientUserList && ingredientTenantList) {
      return [...ingredientUserList, ...ingredientTenantList, ...ingredientMainList];
    }
    return undefined;
  }, [ingredientMainList, ingredientUserList, ingredientTenantList]);

  const ingredientCollection = firebase.firestore().collection('ingredients').orderBy('name');
  const userIngredientsCollection = firebase
    .firestore()
    .collection(`tenants/${tenant}/users`)
    .doc(authContext.user?.uid)
    .collection('ingredients')
    .orderBy('name');

  // Get PurchaseList Data
  const [quickList, setQuickList] = useState<any[]>([]);
  const quickListCollection = firebase
    .firestore()
    .collection(`tenants/${tenant}/users`)
    .doc(authContext.user?.uid)
    .collection('quickList')
    .orderBy('dateAdded', 'desc');

  // Shared
  const [userMessagesList, setUserMessagesList] = useState<Messages[]>();
  const userMessagesListCollection = firebase
    .firestore()
    .collection('messages')
    .where('userMail', '==', authContext.userData?.email.toLowerCase());

  useEffect(() => {
    if (loadOtherData) {
      const unsubscribeRecipes = recipesCollection.onSnapshot(docs => {
        const list: RecipesType[] = docs.docs.map(item => ({ uid: item.id, ...item.data() } as RecipesType));
        setRecipesMainList(list);
      });
      const unsubscribeUserRecipes = userRecipesCollection.onSnapshot(docs => {
        const list: RecipesType[] = docs.docs.map(item => ({ uid: item.id, ...item.data() } as RecipesType));
        setRecipesUserList(list);
      });
      const unsubscribeTenantRecipes = tenantRecipesCollection.onSnapshot(docs => {
        const list: RecipesType[] = docs.docs.map(item => ({ uid: item.id, ...item.data() } as RecipesType));
        setRecipesTenantList(list);
      });

      const unsubscribeIngredient = ingredientCollection.onSnapshot(docs => {
        const list: IngredientType[] = docs.docs.map(item => ({ uid: item.id, ...item.data() } as IngredientType));
        setIngredientMainList(list);
      });
      const unsubscribeUserIngredient = userIngredientsCollection.onSnapshot(docs => {
        const list: IngredientType[] = docs.docs.map(item => ({ uid: item.id, ...item.data() } as IngredientType));
        setIngredientUserList(list);
      });
      const unsubscribeTenantIngredient = tenantIngridientCollection.onSnapshot(docs => {
        const list: IngredientType[] = docs.docs.map(item => ({ uid: item.id, ...item.data() } as IngredientType));
        setIngredientTenantList(list);
      });

      const unsubscribeRecipeFavorites = userRecipesFavoritesCollection.onSnapshot(docs => {
        const list: RecipeFavorites[] = docs.docs.map(
          item => ({ uid: item.id, name: item.data().name, origId: item.data().origId } as RecipeFavorites)
        );
        setFavoritesRecipesList(list);
      });

      const unsubscribeIngredientsFavorites = userIngredientsFavoritesCollection.onSnapshot(docs => {
        const list: any[] = docs.docs.map(item => ({ uid: item.id, ...item.data() } as any));
        setFavoritesIngredientsList(list);
      });

      const unsubscribePurchaseList = userPurchaseListCollection.onSnapshot(docs => {
        const list: PurchaseListType[] = docs.docs.map(item => ({ ...item.data() } as PurchaseListType));
        setPurchaseList(list);
      });

      const unsubscribeQuickList = quickListCollection.onSnapshot(docs => {
        const list: any[] = docs.docs.map(item => ({ ...item.data() } as any));
        setQuickList(list);
      });

      const unsubscribePlanFavorites = userPlansFavoritesCollection.onSnapshot(docs => {
        const list: PlansFavorites[] = docs.docs.map(
          item => ({ uid: item.id, name: item.data().name, origId: item.data().origId } as PlansFavorites)
        );
        setFavoritesPlansList(list);
      });

      const sharedMessages = userMessagesListCollection.onSnapshot(docs => {
        const list: Messages[] = docs.docs.map(item => ({ ...item.data() } as Messages));
        setUserMessagesList(list);
      });

      return () => {
        unsubscribeRecipes();
        unsubscribeUserRecipes();
        unsubscribeTenantRecipes();
        unsubscribeIngredient();
        unsubscribeUserIngredient();
        unsubscribeTenantIngredient();
        unsubscribeRecipeFavorites();
        unsubscribePlanFavorites();
        unsubscribePurchaseList();
        sharedMessages();
        unsubscribeQuickList();
        unsubscribeIngredientsFavorites();
      };
    }

    return () => {};
  }, [loadOtherData]);

  useEffect(() => {
    const unsubscribePlans = planCollection.onSnapshot(docs => {
      const list: PlanType[] = docs.docs.map(item => ({ uid: item.id, ...item.data() } as PlanType));
      setPlanMainList(list);
    });
    const unsubscribeUserPlans = userPlansCollection.onSnapshot(docs => {
      const list: PlanType[] = docs.docs.map(item => ({ uid: item.id, ...item.data() } as PlanType));
      setPlanUserList(list);
      setLoadOtherData(true);
    });

    if (accessTokenString.length === 0) {
      try {
        axios({
          url: `${process.env.REACT_APP_API_URL}/generateToken`,
          method: 'post',
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        }).then(response => {
          setAccessTokenString(response.data.access_token);
        });
      } catch (err) {
        setAccessTokenString('error');
      }
    }

    /* const unsubscribeRecipes = recipesCollection.onSnapshot(docs => {
      console.log('unsubscribeRecipes start');
      const list: RecipesType[] = docs.docs.map(item => ({ uid: item.id, ...item.data() } as RecipesType));
      setRecipesMainList(list);
      console.log('unsubscribeRecipes end');
    }); 
    const unsubscribeUserRecipes = userRecipesCollection.onSnapshot(docs => {
      console.log('unsubscribeUserRecipes start');
      const list: RecipesType[] = docs.docs.map(item => ({ uid: item.id, ...item.data() } as RecipesType));
      setRecipesUserList(list);
      console.log('unsubscribeUserRecipes end');
    }); 

    const unsubscribeIngredient = ingredientCollection.onSnapshot(docs => {
      console.log('unsubscribeIngredient start');
      const list: IngredientType[] = docs.docs.map(item => ({ uid: item.id, ...item.data() } as IngredientType));
      setIngredientMainList(list);
      console.log('unsubscribeIngredient end');
    });
    const unsubscribeUserIngredient = userIngredientsCollection.onSnapshot(docs => {
      console.log('unsubscribeUserIngredient start');
      const list: IngredientType[] = docs.docs.map(item => ({ uid: item.id, ...item.data() } as IngredientType));
      setIngredientUserList(list);
      console.log('unsubscribeUserIngredient end');
    });

    const unsubscribeRecipeFavorites = userRecipesFavoritesCollection.onSnapshot(docs => {
      const list: RecipeFavorites[] = docs.docs.map(
        item => ({ uid: item.id, name: item.data().name, origId: item.data().origId } as RecipeFavorites)
      );
      setFavoritesRecipesList(list);
    });

    const unsubscribePurchaseList = userPurchaseListCollection.onSnapshot(docs => {
      const list: PurchaseListType[] = docs.docs.map(item => ({ ...item.data() } as PurchaseListType));
      setPurchaseList(list);
    });

    const unsubscribeQuickList = quickListCollection.onSnapshot(docs => {
      const list: any[] = docs.docs.map(item => ({ ...item.data() } as any));
      setQuickList(list);
    });

    const unsubscribePlanFavorites = userPlansFavoritesCollection.onSnapshot(docs => {
      const list: PlansFavorites[] = docs.docs.map(
        item => ({ uid: item.id, name: item.data().name, origId: item.data().origId } as PlansFavorites)
      );
      setFavoritesPlansList(list);
    });

    const sharedMessages = userMessagesListCollection.onSnapshot(docs => {
      const list: Messages[] = docs.docs.map(item => ({ ...item.data() } as Messages));
      setUserMessagesList(list);
    }); */

    return () => {
      unsubscribeUserPlans();
      unsubscribePlans();
      // unsubscribeRecipes();
      // unsubscribeRecipes();
      // unsubscribeUserRecipes();
      // unsubscribeIngredient();
      // unsubscribeUserIngredient();
      // unsubscribeRecipeFavorites();
      // unsubscribePlanFavorites();
      // unsubscribePurchaseList();
      // sharedMessages();
      // unsubscribeQuickList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContext.Provider
      value={{
        planList,
        userPlanList,
        recipesList,
        ingredientList,
        favoritesRecipesList,
        favoritesPlansList,
        favoritesIngredientsList,
        purchaseList,
        tempRecipeObject,
        quickList,
        setTempRecipeObject,
        userMessagesList,
        accessTokenString,
        setAccessTokenString,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import { AuthContext } from 'providers/AuthProvider';
import Button from 'components/Button';
import { ROLE_MEMBER } from 'shared/constants/global';
import Headline from 'components/Headline';
import FirstPersonalInformation from './components/PersonalInformation/firstPage';
import ThirdPersonalInformation from './components/PersonalInformation/thirdPage';
import FormOfNutrition from './components/FormOfNutrition';
import EatingHabits from './components/EatingHabits';
import Incompatibilities from './components/Incompatibilities';
import SecondPersonalInformation from './components/PersonalInformation/secondPage';
import GoalStep from './components/GoalStep';
import ShareProfile from './components/ShareProfile';

import styles from './styles.module.scss';

function ProfileWizard() {
  const authContext = useContext(AuthContext);
  const { tenant, userData } = useContext(AuthContext);
  const db = firebase.firestore();
  const { t } = useTranslation();

  const history = useHistory();
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const maxSteps = userData?.role === ROLE_MEMBER ? 7 : 6;

  const nextEnable = useMemo(() => {
    const profile = userData;

    switch (currentStep) {
      case 0:
        if (
          profile?.gender &&
          profile?.gender.length &&
          profile.age &&
          profile.age.length &&
          profile.bodySize &&
          profile.bodySize.length &&
          profile.bodyWeight &&
          profile.bodyWeight.length &&
          profile.email &&
          profile.email.length
        ) {
          return true;
        }
        return false;
      case 1:
        if (
          profile?.calories &&
          profile.calories.length &&
          profile.physicalStrainChange &&
          profile.physicalStrainChange.length
        ) {
          return true;
        }
        return false;
      case 3:
        if (profile?.goal) {
          return true;
        }
        return false;
      case 4:
        if (profile?.formOfNutrition) {
          return true;
        }
        return false;
      default:
        return true;
    }
  }, [userData, currentStep]);

  const onSubmit = async () => {
    setIsUpdating(true);
    try {
      const profile: UserInfo = userData as UserInfo;
      await db
        .collection(`tenants/${tenant}/users`)
        .doc(authContext.user?.uid)
        .set({
          ...profile,
          profileComplete: true,
        });
      authContext.setUserData((prevProfile: UserInfo) => ({ ...prevProfile, profileComplete: true }));
      setIsUpdating(false);

      if (userData?.withCouponCode) {
        history.push('/purchase');
      } else {
        history.push('/');
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.warn(error.message);
      setIsUpdating(false);
    }
  };

  const nextStep = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setCurrentStep(prevStep => prevStep + 1);
  };

  const backStep = () => {
    if (currentStep === 0) return;
    setCurrentStep(prevStep => prevStep - 1);
  };

  useEffect(() => {
    if (!userData?.email) {
      window.location.reload();
    }
  }, [userData]);

  useEffect(() => {
    if (currentStep === 0 && userData?.withCouponCode && window.location.hostname === 'app.tortija.de') {
      ReactPixel.init('5801591503252258');
      ReactPixel.pageView();
      ReactPixel.track('InitiateCheckout');
      TiktokPixel.init('CKRNE1BC77UAIQIC34Q0');
      TiktokPixel.pageView();
      TiktokPixel.track('InitiateCheckout', {});
    }
  }, [currentStep]);

  if (!userData?.email) {
    return null;
  }

  return (
    <div className={userData?.role === 5 ? styles.memberWrapper : styles.wrapper}>
      <Headline className="capitalize" centered level={3}>
        {t('Complete your profile')}
      </Headline>
      <div className={styles.content}>
        {currentStep === 0 ? (
          <FirstPersonalInformation />
        ) : currentStep === 1 ? (
          <SecondPersonalInformation />
        ) : currentStep === 2 ? (
          <ThirdPersonalInformation />
        ) : currentStep === 3 ? (
          <GoalStep />
        ) : currentStep === 4 ? (
          <FormOfNutrition />
        ) : currentStep === 5 ? (
          <EatingHabits />
        ) : currentStep === 6 ? (
          <Incompatibilities />
        ) : (
          <ShareProfile />
        )}
      </div>
      <div className={styles.buttonContainer}>
        <Button
          disabled={isUpdating || currentStep === 0}
          buttonStyle={isUpdating || currentStep === 0 ? 'dark' : 'white'}
          onClick={backStep}
        >
          {t('Back')}
        </Button>
        <Button
          isPending={isUpdating}
          disabled={isUpdating || !nextEnable}
          buttonStyle={isUpdating || !nextEnable ? 'dark' : 'primary'}
          onClick={currentStep === maxSteps ? onSubmit : nextStep}
        >
          {currentStep < maxSteps ? t('Next') : t('Submit')}
        </Button>
      </div>
    </div>
  );
}

export default ProfileWizard;

import React, { useState, useRef, useMemo, useContext, useEffect, useCallback } from 'react';
import {
  PlusIcon,
  XIcon,
  SearchIcon,
  CheckCircleIcon,
  TrashIcon,
  PencilIcon,
  HeartIcon,
  AdjustmentsIcon,
  RefreshIcon,
  BadgeCheckIcon,
} from '@heroicons/react/outline';
import Button from 'components/Button';
import CustomUserInput from 'components/CustomUserInput';
import TransitionContainer from 'components/TransitionContainer';
import FilterDrawer from 'pages/Nutrition/components/FilterDrawer';
import { useHistory, useLocation } from 'react-router-dom';
import Headline from 'components/Headline';
import IngredientSearchPopupItem from 'components/IngredientSearchPopupItem';
import SearchBox from 'components/SearchBox';
import axios from 'axios';
import { FilterContext } from 'providers/FilterProvider';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactLoading from 'react-loading';
import MakroCircles from 'components/MakroCircles';
import { difficultyGroup } from 'shared/constants/filter';
import Select from 'react-select';
import SwitchSelector from 'react-switch-selector';
import Overlay from 'components/Overlay';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import classNames from 'classnames';
import moment from 'moment';
import {
  getIngridientMacros,
  handleOpenClosePopups,
  addFavoriteIngredients,
  savePlanChangesToFirebase,
  addIngredientToQuickList,
  scrollToTop,
} from 'shared/functions/global';
import { intolerancesOption, formOfNutrition } from 'shared/constants/profile-wizard';
import Incompatibilities from 'pages/ProfileWizard/components/Incompatibilities';
import { defaultTextColor, defaultButtonTextColor } from 'layouts/Theme';
import styles from './style.module.scss';

type Props = {
  openerClass?: string;
  setOpenerClass?: any;
  recipeObject?: RecipesType[];
  updateRecipeState?: any;
  recipeStateValue?: any;
  setRecipeChanged?: any;
  mealType?: any;
  popupType?: string;
  updateDayId?: number;
  recipeChangeId?: any;
  recipeChangeFunction?: any;
  updateDayString?: any;
  isDashBoard?: boolean;
  barcodeFoodId?: string;
  setBarcodeFoodId?: Function;
  barcodePopupCloser?: Function;
  barcodeContentPopupClass?: Function;
  openGenerateMealPopup?: Function;
  setVisibleIncompabilityPopup?: Function;
  openRecipeOnLoad?: boolean;
  setOpenRecipeOnLoad?: Function;
  setRefreshDashboard?: any;
};

const IngredientSearchPopup: React.FC<Props> = ({
  openerClass = 'hidden',
  setOpenerClass,
  updateRecipeState,
  recipeStateValue,
  setRecipeChanged,
  mealType,
  popupType,
  updateDayId,
  recipeChangeId,
  recipeChangeFunction,
  updateDayString,
  isDashBoard = false,
  barcodeFoodId,
  setBarcodeFoodId,
  barcodePopupCloser,
  barcodeContentPopupClass,
  openGenerateMealPopup,
  setVisibleIncompabilityPopup,
  openRecipeOnLoad,
  setOpenRecipeOnLoad,
  setRefreshDashboard,
}) => {
  const { t } = useTranslation();

  const { userData, tenant, theme, tenantData } = useContext(AuthContext);
  const authContext = useContext(AuthContext);

  const { quickList, favoritesIngredientsList } = useContext(MainContext);

  const location = useLocation<LocationState>();
  const history = useHistory();

  const {
    initialNutrition,
    initialEatingHabits,
    initialIntolerances,
    myFavoritesValue,
    myRecipesValue,
    myCommunityRecipesValue,
    caloriesRange,
    carbohydratesRange,
    proteinRange,
    fatRange,
    ingredientsFilter,
    ingredientsNotIncludeFilter,
    initialDifficulty,
  } = useContext(FilterContext);
  const [currentSearchQuery, setCurrentSearchQuery] = useState('');
  const { recipesList, favoritesRecipesList } = useContext(MainContext);

  const { nutritionSearchValue, setNutritionSearchValue } = useContext(FilterContext);

  const [currentMealTypeValue, setCurrentMealTypeValue] = useState('breakfast');

  const [currentApiFoodObject, setCurrentApiFoodObject] = useState<any>(null);

  const [checkBoxQuickList, setCheckBoxQuickList] = useState<any>([]);

  const [currentEditIngridientPopupType, setCurrentEditIngridientPopupType] = useState('internal');

  const [ingredientFavoriteStatus, setIngredientFavoriteStatus] = useState(false);
  const [updateTriggerFavorites, setUpdateTriggerFavorites] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [currentLoadedRecipeIndex, setCurrentLoadedRecipeIndex] = useState(0);
  const [recipeAlreadyLoaded, setRecipeAlreadyLoaded] = useState(false);
  const [recipeLoadedUid, setRecipeLoadedUid] = useState('');

  const mealTypeValues: any = [
    { value: 'breakfast', label: 'Frühstück' },
    { value: 'lunch', label: 'Mittagessen' },
    { value: 'dinner', label: 'Abendessen' },
    { value: 'snacks', label: 'Snacks' },
  ];

  const perPage = 20;
  const [lastObjectPosition, setLastObjectPosition] = useState(20);
  const [loadedRecipes, setLoadedRecipes] = useState<any[]>([]);

  const loadRecipes = () => {
    setLoadedRecipes(currentRecipes => {
      return [...currentRecipes, ...recipesFilteredList.slice(lastObjectPosition, lastObjectPosition + perPage)];
    });

    setLastObjectPosition(currentValue => {
      return currentValue + perPage;
    });
  };

  const recipesFilteredList: RecipesType[] = useMemo(() => {
    if (!recipesList) return [];
    return recipesList.filter(
      element =>
        (initialDifficulty.length === 0 ||
          initialDifficulty.findIndex(
            item => element.difficulty_level === difficultyGroup.findIndex(difficulty => difficulty === item) + 1
          ) > -1) &&
        (initialNutrition.length === 0 || initialNutrition.findIndex(item => element[item.toLowerCase()]) > -1) &&
        (initialEatingHabits.length === 0 || initialEatingHabits.every(item => element[item.toLowerCase()]) === true) &&
        (initialIntolerances.length === 0 ||
          initialIntolerances.findIndex(item => element[item.toLowerCase()]) === -1) &&
        ((myRecipesValue === true && element.userRecipe === true) ||
          (myCommunityRecipesValue === true && element.communityRecipe === true) ||
          (myFavoritesValue === true &&
            favoritesRecipesList?.find(item => item.origId === element.uid) !== undefined) ||
          (myRecipesValue === false && myCommunityRecipesValue === false && myFavoritesValue === false)) &&
        parseFloat(element.kcal_total) >= caloriesRange.min &&
        parseFloat(element.kcal_total) <= (caloriesRange.max === 3000 ? 50000 : caloriesRange.max) &&
        parseFloat(element.carbohydrates_total) >= carbohydratesRange.min &&
        parseFloat(element.carbohydrates_total) <= (carbohydratesRange.max === 500 ? 50000 : carbohydratesRange.max) &&
        parseFloat(element.protein_total) >= proteinRange.min &&
        parseFloat(element.protein_total) <= (proteinRange.max === 150 ? 50000 : proteinRange.max) &&
        parseFloat(element.fat_total) >= fatRange.min &&
        parseFloat(element.fat_total) <= (fatRange.max === 250 ? 50000 : fatRange.max) &&
        element.name.toLowerCase().includes(currentSearchQuery.toLowerCase()) &&
        (ingredientsFilter.length === 0 ||
          ingredientsFilter.findIndex(
            (name: string) =>
              element.ingredients.findIndex((item: Ingredients) => item.name.toLowerCase() === name.toLowerCase()) ===
              -1
          ) === -1) &&
        (ingredientsNotIncludeFilter.length === 0 ||
          ingredientsNotIncludeFilter.findIndex(
            (name: string) =>
              element.ingredients.findIndex((item: Ingredients) => item.name.toLowerCase() === name.toLowerCase()) > -1
          ) === -1)
    );
  }, [
    caloriesRange.max,
    caloriesRange.min,
    carbohydratesRange.max,
    carbohydratesRange.min,
    currentSearchQuery,
    fatRange.max,
    fatRange.min,
    favoritesRecipesList,
    ingredientsFilter,
    ingredientsNotIncludeFilter,
    initialDifficulty,
    initialEatingHabits,
    initialIntolerances,
    initialNutrition,
    myFavoritesValue,
    myRecipesValue,
    myCommunityRecipesValue,
    proteinRange.max,
    proteinRange.min,
    recipesList,
  ]);

  const { accessTokenString } = useContext(MainContext);
  const [isClearFilter, setIsClearFilter] = useState(false);
  const [currentIngridientSearchApi, setCurrentIngridientSearchApi] = useState({ foods: { food: [{}] } as any });
  const [currentIngridientSearch, setCurrentIngridientSearch] = useState(false);
  const [currentSwitchIndex, setCurrentSwitchIndex] = useState(0);
  const [currentIngridientSearchAutocomplete, setCurrentIngridientSearchAutocomplete] = useState({
    suggestions: { suggestion: [''] },
  });
  const [directAddedIngridients, setDirectAddedIngridients] = useState<any[]>([]);
  const [currentAddRecipe, setCurrentAddRecipe] = useState<any>({
    ingredients: [],
    description: [],
    kcal_total: '0',
    carbohydrates_total: '0',
    protein_total: '0',
    fat_total: '0',
    vegetarian: false,
    ketogen: false,
    vegan: false,
  });
  const [currentEditIngridientAPIFoodId, setCurrentEditIngridientAPIFoodId] = useState('');
  const [currentEditIngridientType, setCurrentEditIngridientType] = useState('');
  const [currentEditIngridientPiece, setCurrentEditIngridientPiece] = useState('g');
  const [editPopupOverlayClass, setEditPopupOverlayClass] = useState('hidden');
  const [popupOverlayClass, setPopupOverlayClass] = useState('hidden');
  const [currentEditIngridientKcalValue, setCurrentEditIngridientKcalValue] = useState(0);
  const [currentEditIngridientCarbohydratesValue, setCurrentEditIngridientCarbohydratesValue] = useState(0);
  const [currentEditIngridientProteinValue, setCcurrentEditIngridientProteinValue] = useState(0);
  const [currentEditIngridientFatValue, setCurrentEditIngridientFatValue] = useState(0);
  const [currentEditIngridientKcalTotal, setCurrentEditIngridientKcalTotal] = useState(0);
  const [currentEditIngridientCarbohydratesTotal, setCurrentEditIngridientCarbohydratesTotal] = useState(0);
  const [currentEditIngridientProteinTotal, setCurrentEditIngridientProteinTotal] = useState(0);
  const [currentEditIngridientFatTotal, setCurrentEditIngridientFatTotal] = useState(0);
  const [currentEditIngridientPortionValues, setCurrentEditIngridientPortionValues] = useState<any>([]);
  const [currentEditIngridientDefaultPiece, setCurrentEditIngridientDefaultPiece] = useState('g');
  const [currentEditIngridientPreselectG, setCurrentEditIngridientPreselectG] = useState('');
  const [currentEditIngridientPreselectType, setCurrentEditIngridientPreselectType] = useState('');
  const [currentEditIngridientKcal100g, setCurrentEditIngridientKcal100g] = useState('');
  const [currentEditIngridientCarbohydrates100g, setCurrentEditIngridientCarbohydrates100g] = useState('');
  const [currentEditIngridientProtein100g, setCurrentEditIngridientProtein100g] = useState('');
  const [currentEditIngridientFat100g, setCurrentEditIngridientFat100g] = useState('');
  const [currentEditIngridientCategory, setCurrentEditIngridientCategory] = useState('');
  const [currentEditIngridientName, setCurrentEditIngridientName] = useState('');
  const [currentEditIngridientBrandName, setCurrentEditIngridientBrandName] = useState('');
  const [currentEditIngridientID, setCurrentEditIngridientID] = useState('1');
  const [currentEditInitialIngridientAmount, setCurrentEditInitialIngridientAmount] = useState('1');
  const [currentEditIngridientAmount, setCurrentEditIngridientAmount] = useState('1');
  const [currentEditIngridientImageUrl, setCurrentEditIngridientImageUrl] = useState('');
  const [currentEditIngridientMetricServingAmountValue, setCurrentEditIngridientMetricServingAmountValue] =
    useState('');

  const [addManualCaloriesClass, setAddManualCaloriesClass] = useState('hidden');
  const [manualDescription, setManualDescription] = useState('');
  const [manualKcal, setManualKcal] = useState(0);
  const [manualCarbohydrates, setManualCarbohydrates] = useState(0);
  const [manualProtein, setManualProtein] = useState(0);
  const [manualFat, setManualFat] = useState(0);
  const manualDescriptionRef = useRef<HTMLInputElement>(null);
  const manualCaloriesRef = useRef<HTMLInputElement>(null);
  const manualCarbohydratesRef = useRef<HTMLInputElement>(null);
  const manualProteinRef = useRef<HTMLInputElement>(null);
  const manualFatRef = useRef<HTMLInputElement>(null);
  const quickListContainerRef = useRef<HTMLDivElement>(null);

  const searchIngridientRef = useRef<HTMLInputElement>(null);
  const searchIngridientMobileRef = useRef<HTMLInputElement>(null);
  const editIngridientRef = useRef<HTMLInputElement>(null);
  const recipeContentRef = useRef<HTMLDivElement>(null);

  const currentDayItem = recipeStateValue?.dayEntries.filter((item: any) => item.id === updateDayId)[0];

  const recipesOption = [
    {
      label: t('Food'),
      value: 'food',
    },
    {
      label: t('Recipes'),
      value: 'recipes',
    },
  ];

  const recipesFilterOption = [
    {
      label: t('Recipes'),
      value: 'recipes',
    },
    {
      label: t('Food'),
      value: 'food',
    },
  ];
  const [isFilterOpen, setFilterOpen] = useState(false);

  const ingredientQuickOption = [
    {
      label: 'Zuletzt',
      value: 'last',
    },
    {
      label: 'Favoriten',
      value: 'favorites',
    },
  ];

  const filterOpen = () => {
    setFilterOpen(true);
    setIsClearFilter(false);
  };

  const [filterType, setFilterType] = useState(recipesOption[0].value);
  const [currentSection, setCurrentSection] = useState<any>(recipesOption[0].value);

  const [currentQuickListSection, setCurrentQuickListSection] = useState<any>(ingredientQuickOption[0].value);
  const [currentQuickListSwitchIndex, setCurrentQuickListSwitchIndex] = useState(0);

  const { ingredientList } = useContext(MainContext);

  const ingredientFilteredList = useMemo(
    () => ingredientList?.filter(element => element.name?.toLowerCase().includes(currentSearchQuery.toLowerCase())),
    [ingredientList, currentSearchQuery]
  );

  const clickQuickListCheckbox = (quickListId: number, ingredientData: any) => {
    let newColumns = [] as any;

    if (checkBoxQuickList.findIndex((item: any) => item.id === quickListId) === -1) {
      newColumns = [...checkBoxQuickList, { id: quickListId, data: { ...ingredientData } }];
    } else {
      newColumns = [...checkBoxQuickList.filter((item: any) => item.id !== quickListId)];
    }

    setCheckBoxQuickList(newColumns);
  };

  const triggerAddIngredientsToFavorites = async () => {
    if (currentEditIngridientPopupType === 'api') {
      let getIngridientServingData;
      let currentServingDescription;
      let ingridientKcal100g;
      let ingridientKH100g;
      let ingridientEW100g;
      let ingridientFT100g;
      let thisPieceValue = currentEditIngridientPiece;
      let thisEditIngredientAmount = currentEditIngridientAmount;

      if (thisEditIngredientAmount.toString().length === 0) {
        thisEditIngredientAmount = '0';
      }

      const { data } = currentApiFoodObject;

      if (Array.isArray(data.food.servings.serving)) {
        getIngridientServingData = data.food.servings.serving.filter((item: any) => item.is_default === '1');
        currentServingDescription = getIngridientServingData[0].serving_description.replace(/\s+/g, '');
      } else {
        getIngridientServingData = [data.food.servings.serving];
        currentServingDescription = data.food.servings.serving.serving_description.replace(/\s+/g, '');
      }

      if (currentEditIngridientPiece.includes('(')) {
        thisPieceValue = currentEditIngridientPiece.substr(0, currentEditIngridientPiece.indexOf('(') - 1);
      }

      if (getIngridientServingData[0].metric_serving_amount) {
        ingridientKcal100g = Math.round(
          (parseFloat(getIngridientServingData[0].calories) * 100) /
            parseFloat(getIngridientServingData[0].metric_serving_amount)
        );
        ingridientKH100g = Math.round(
          (parseFloat(getIngridientServingData[0].carbohydrate) * 100) /
            parseFloat(getIngridientServingData[0].metric_serving_amount)
        );
        ingridientEW100g = Math.round(
          (parseFloat(getIngridientServingData[0].protein) * 100) /
            parseFloat(getIngridientServingData[0].metric_serving_amount)
        );
        ingridientFT100g = Math.round(
          (parseFloat(getIngridientServingData[0].fat) * 100) /
            parseFloat(getIngridientServingData[0].metric_serving_amount)
        );
      } else {
        ingridientKcal100g = Math.round(parseFloat(getIngridientServingData[0].calories));
        ingridientKH100g = Math.round(parseFloat(getIngridientServingData[0].carbohydrate));
        ingridientEW100g = Math.round(parseFloat(getIngridientServingData[0].protein));
        ingridientFT100g = Math.round(parseFloat(getIngridientServingData[0].fat));
      }

      let thisFoodCategory = 'Sonstiges';
      if (data.food.food_sub_categories) {
        if (Array.isArray(data.food.food_sub_categories.food_sub_category)) {
          thisFoodCategory = data.food.food_sub_categories.food_sub_category[1].toString();
        } else {
          thisFoodCategory = data.food.food_sub_categories.food_sub_category;
        }
      }

      const thisObject = {
        id: Math.random(),
        food_id: currentEditIngridientAPIFoodId,
        name: currentEditIngridientName,
        amount: parseFloat(thisEditIngredientAmount),
        initial_amount: parseFloat(thisEditIngredientAmount),
        piece: thisPieceValue,
        kcal_100g: ingridientKcal100g,
        carbohydrates_100g: ingridientKH100g,
        protein_100g: ingridientEW100g,
        dateAdded: moment().unix(),
        fat_100g: ingridientFT100g,
        kcal_total: currentEditIngridientKcalTotal,
        carbohydrates_total: currentEditIngridientCarbohydratesTotal,
        protein_total: currentEditIngridientProteinTotal,
        fat_total: currentEditIngridientFatTotal,
        preselect_g:
          (getIngridientServingData[0].metric_serving_amount &&
            getIngridientServingData[0].metric_serving_amount.substr(
              0,
              getIngridientServingData[0].metric_serving_amount.indexOf('.')
            )) ||
          100,
        category: thisFoodCategory,
        serving_id: getIngridientServingData[0].serving_id,
        serving_unit:
          getIngridientServingData[0].metric_serving_unit !== undefined
            ? getIngridientServingData[0].metric_serving_unit
            : 'g',
        serving_data: data.food.servings,
      };

      await addFavoriteIngredients(
        thisObject,
        authContext.user?.uid,
        tenant,
        ingredientFavoriteStatus === false ? 'add' : 'delete'
      );

      setUpdateTriggerFavorites(updateTriggerFavorites + 1);
    } else {
      let thisIngridientObject = {
        id: Math.random().toString(),
        name: currentEditIngridientName,
        dateAdded: moment().unix(),
        amount: currentEditIngridientAmount.toString().length === 0 ? 0 : parseFloat(currentEditIngridientAmount),
        initial_amount:
          currentEditIngridientAmount.toString().length === 0 ? 0 : parseFloat(currentEditIngridientAmount),
        piece: currentEditIngridientPiece,
        kcal_100g: currentEditIngridientKcal100g,
        carbohydrates_100g: currentEditIngridientCarbohydrates100g,
        protein_100g: currentEditIngridientProtein100g,
        fat_100g: currentEditIngridientFat100g,
        default_piece: currentEditIngridientDefaultPiece,
        preselect_g: currentEditIngridientPreselectG,
        category: currentEditIngridientCategory,
        imageUrl: currentEditIngridientImageUrl,
        preselect_type: currentEditIngridientPreselectType,
        kcal_total: 0,
        carbohydrates_total: 0,
        protein_total: 0,
        fat_total: 0,
      };

      const thisKcalTotal = getIngridientMacros(thisIngridientObject, thisIngridientObject, 'kcal');
      const thisCarbohydratesTotal = getIngridientMacros(thisIngridientObject, thisIngridientObject, 'carbohydrates');
      const thisProteinTotal = getIngridientMacros(thisIngridientObject, thisIngridientObject, 'protein');
      const thisFatTotal = getIngridientMacros(thisIngridientObject, thisIngridientObject, 'fat');

      thisIngridientObject = {
        ...thisIngridientObject,
        kcal_total: thisKcalTotal,
        carbohydrates_total: thisCarbohydratesTotal,
        protein_total: thisProteinTotal,
        fat_total: thisFatTotal,
      };

      await addFavoriteIngredients(
        thisIngridientObject,
        authContext.user?.uid,
        tenant,
        ingredientFavoriteStatus === false ? 'add' : 'delete'
      );

      setUpdateTriggerFavorites(updateTriggerFavorites + 1);
    }
  };

  const getFavoriteIngredientStatus = () => {
    const getIngredientFromFavoritesList = favoritesIngredientsList?.filter(
      item => item.name === currentEditIngridientName
    )[0];

    if (getIngredientFromFavoritesList !== undefined) {
      setIngredientFavoriteStatus(true);
    } else {
      setIngredientFavoriteStatus(false);
    }
  };

  const closeMainPopup = () => {
    setCurrentLoadedRecipeIndex(0);
    setIsClearFilter(true);

    if (setRefreshDashboard !== undefined) {
      setRefreshDashboard(true);
    }

    setLoadedRecipes(
      recipesFilteredList.slice(0, 20).map((recipes: any) => {
        return {
          ...recipes,
        };
      })
    );
    setOpenerClass('hidden');
  };

  function addChangedIngridientAPI() {
    setIsLoading(true);
    let thisCurrentKcalComplete = 0;
    let thisCurrentKHComplete = 0;
    let thisCurrentEWComplete = 0;
    let thisCurrentFTComplete = 0;

    let getIngridientServingData;
    let currentServingDescription;
    let ingridientKcal100g;
    let ingridientKH100g;
    let ingridientEW100g;
    let ingridientFT100g;
    let thisPieceValue = currentEditIngridientPiece;
    let thisMealType = mealType;
    if (barcodeFoodId !== undefined) {
      if (barcodeFoodId.length > 0) {
        thisMealType = currentMealTypeValue;
      }
    }

    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    let thisCurrentPlanDayObject = {} as any;

    if (popupType === 'plan') {
      thisCurrentPlanDayObject = recipeStateValue?.dayEntries.filter((item: any) => item.id === updateDayId)[0];
    }

    try {
      const { data } = currentApiFoodObject;

      if (Array.isArray(data.food.servings.serving)) {
        getIngridientServingData = data.food.servings.serving.filter((item: any) => item.is_default === '1');
        currentServingDescription = getIngridientServingData[0].serving_description.replace(/\s+/g, '');
      } else {
        getIngridientServingData = [data.food.servings.serving];
        currentServingDescription = data.food.servings.serving.serving_description.replace(/\s+/g, '');
      }

      if (currentEditIngridientPiece.includes('(')) {
        thisPieceValue = currentEditIngridientPiece.substr(0, currentEditIngridientPiece.indexOf('(') - 1);
      }

      if (getIngridientServingData[0].metric_serving_amount) {
        ingridientKcal100g = Math.round(
          (parseFloat(getIngridientServingData[0].calories) * 100) /
            parseFloat(getIngridientServingData[0].metric_serving_amount)
        );
        ingridientKH100g = Math.round(
          (parseFloat(getIngridientServingData[0].carbohydrate) * 100) /
            parseFloat(getIngridientServingData[0].metric_serving_amount)
        );
        ingridientEW100g = Math.round(
          (parseFloat(getIngridientServingData[0].protein) * 100) /
            parseFloat(getIngridientServingData[0].metric_serving_amount)
        );
        ingridientFT100g = Math.round(
          (parseFloat(getIngridientServingData[0].fat) * 100) /
            parseFloat(getIngridientServingData[0].metric_serving_amount)
        );
      } else {
        ingridientKcal100g = Math.round(parseFloat(getIngridientServingData[0].calories));
        ingridientKH100g = Math.round(parseFloat(getIngridientServingData[0].carbohydrate));
        ingridientEW100g = Math.round(parseFloat(getIngridientServingData[0].protein));
        ingridientFT100g = Math.round(parseFloat(getIngridientServingData[0].fat));
      }

      if (popupType === 'plan') {
        thisCurrentKcalComplete = Math.round(
          parseFloat(thisCurrentPlanDayObject?.kcal_total!) + currentEditIngridientKcalTotal
        );

        thisCurrentKHComplete = Math.round(
          parseFloat(thisCurrentPlanDayObject?.carbohydrates_total!) + currentEditIngridientCarbohydratesTotal
        );

        thisCurrentEWComplete = Math.round(
          parseFloat(thisCurrentPlanDayObject?.protein_total!) + currentEditIngridientProteinTotal
        );

        thisCurrentFTComplete = Math.round(
          parseFloat(thisCurrentPlanDayObject?.fat_total!) + currentEditIngridientFatTotal
        );
      } else {
        thisCurrentKcalComplete = Math.round(
          parseFloat(recipeStateValue?.kcal_total!) + currentEditIngridientKcalTotal
        );

        thisCurrentKHComplete = Math.round(
          parseFloat(recipeStateValue?.carbohydrates_total!) + currentEditIngridientCarbohydratesTotal
        );

        thisCurrentEWComplete = Math.round(
          parseFloat(recipeStateValue?.protein_total!) + currentEditIngridientProteinTotal
        );

        thisCurrentFTComplete = Math.round(parseFloat(recipeStateValue?.fat_total!) + currentEditIngridientFatTotal);
      }

      let thisFoodCategory = 'Sonstiges';
      if (data.food.food_sub_categories) {
        if (Array.isArray(data.food.food_sub_categories.food_sub_category)) {
          thisFoodCategory = data.food.food_sub_categories.food_sub_category[1].toString();
        } else {
          thisFoodCategory = data.food.food_sub_categories.food_sub_category;
        }
      }

      const thisIngridientObject = {
        id: Math.random(),
        food_id: currentEditIngridientAPIFoodId,
        name: currentEditIngridientName,
        amount: parseFloat(thisEditIngredientAmount),
        initial_amount: parseFloat(thisEditIngredientAmount),
        piece: thisPieceValue,
        kcal_100g: ingridientKcal100g,
        carbohydrates_100g: ingridientKH100g,
        protein_100g: ingridientEW100g,
        fat_100g: ingridientFT100g,
        kcal_total: currentEditIngridientKcalTotal,
        carbohydrates_total: currentEditIngridientCarbohydratesTotal,
        protein_total: currentEditIngridientProteinTotal,
        fat_total: currentEditIngridientFatTotal,
        preselect_g:
          (getIngridientServingData[0].metric_serving_amount &&
            getIngridientServingData[0].metric_serving_amount.substr(
              0,
              getIngridientServingData[0].metric_serving_amount.indexOf('.')
            )) ||
          100,
        category: thisFoodCategory,
        serving_id: getIngridientServingData[0].serving_id,
        serving_unit:
          getIngridientServingData[0].metric_serving_unit !== undefined
            ? getIngridientServingData[0].metric_serving_unit
            : 'g',
        serving_data: data.food.servings,
      };

      let newColumns = {
        ...recipeStateValue,
        kcal_total: thisCurrentKcalComplete,
        carbohydrates_total: thisCurrentKHComplete,
        protein_total: thisCurrentEWComplete,
        fat_total: thisCurrentFTComplete,
      };

      if (popupType === 'plan') {
        newColumns = {
          ...recipeStateValue,
          dayEntries: recipeStateValue.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== updateDayId) return entries;
            return {
              ...entries,
              kcal_total: thisCurrentKcalComplete,
              carbohydrates_total: thisCurrentKHComplete,
              protein_total: thisCurrentEWComplete,
              fat_total: thisCurrentFTComplete,
              [thisMealType]: {
                ...entries[thisMealType],
                kcal_total: parseFloat(entries[thisMealType].kcal_total) + currentEditIngridientKcalTotal,
                carbohydrates_total:
                  parseFloat(entries[thisMealType].carbohydrates_total) + currentEditIngridientCarbohydratesTotal,
                protein_total: parseFloat(entries[thisMealType].protein_total) + currentEditIngridientProteinTotal,
                fat_total: parseFloat(entries[thisMealType].fat_total) + currentEditIngridientFatTotal,
                ingredients: [thisIngridientObject, ...entries[thisMealType].ingredients],
              },
            };
          }),
        };
      } else {
        newColumns = {
          ...newColumns,
          ingredients: [...recipeStateValue.ingredients, thisIngridientObject],
        };
      }

      setCurrentIngridientSearchAutocomplete({ suggestions: { suggestion: [''] } });
      if (!Number.isNaN(parseFloat(thisEditIngredientAmount))) {
        toast.success('Das Lebensmittel wurde erfolgreich hinzugefügt!');

        if (popupType === 'plan') {
          savePlanChangesToFirebase(
            userData,
            newColumns,
            newColumns.uid,
            authContext.user?.uid,
            isDashBoard,
            updateRecipeState,
            tenant
          );

          const thisQuickListObject = {
            ...thisIngridientObject,
            dateAdded: moment().unix(),
          };
          if (quickList !== undefined) {
            if (quickList.length > 0) {
              if (
                quickList[0].name === thisQuickListObject.name &&
                quickList[0].kcal_total === thisQuickListObject.kcal_total
              ) {
                <></>;
              } else {
                addIngredientToQuickList(thisQuickListObject, authContext.user?.uid, tenant);
              }
            } else {
              addIngredientToQuickList(thisQuickListObject, authContext.user?.uid, tenant);
            }
          }
          setCurrentSearchQuery('');
        } else {
          updateRecipeState(newColumns);
        }

        if (popupType !== 'plan') {
          setCurrentIngridientSearch(false);
          setCurrentSearchQuery('');
          setRecipeChanged(true);
          setDirectAddedIngridients([thisIngridientObject, ...directAddedIngridients]);
        }
      } else {
        toast.error('Die eigegebenen Daten enthielten Fehler!');
      }
      setIsLoading(false);
      handleOpenClosePopups(setEditPopupOverlayClass, setPopupOverlayClass, 'both');
      if (setRefreshDashboard !== undefined) {
        setRefreshDashboard(true);
      }
      if (setBarcodeFoodId !== undefined) {
        setBarcodeFoodId('');
        setCurrentMealTypeValue('breakfast');
      }
    } catch (err) {
      toast.error('Es ist leider etwas schief gelaufen. Bitte probiere es erneut!');
    }
  }

  const changeIngridientAmountAPI = (event: any) => {
    const thisCurrentInitialAmount = event.target.value;
    let thisCurrentAmount = event.target.value;
    let ingridientKcalNew: number;
    let ingridientKhNew: number;
    let ingridientEwNew: number;
    let ingridientFtNew: number;

    // Replace Comma with Point for calculating results
    thisCurrentAmount = thisCurrentAmount.replace(',', '.');

    // Replace all text characters
    thisCurrentAmount = thisCurrentAmount.replace(/[^.0-9]/g, '');

    if (Number.isNaN(parseFloat(thisCurrentAmount))) {
      thisCurrentAmount = 0;
    }

    if (thisCurrentAmount === '') {
      thisCurrentAmount = 0;
    }

    if (currentEditIngridientPiece !== 'g' && currentEditIngridientPiece !== 'ml') {
      ingridientKcalNew = Math.round(parseFloat(thisCurrentAmount) * currentEditIngridientKcalValue);

      ingridientKhNew = Math.round(parseFloat(thisCurrentAmount) * currentEditIngridientCarbohydratesValue);

      ingridientEwNew = Math.round(parseFloat(thisCurrentAmount) * currentEditIngridientProteinValue);

      ingridientFtNew = Math.round(parseFloat(thisCurrentAmount) * currentEditIngridientFatValue);
    } else if (currentEditIngridientMetricServingAmountValue !== '') {
      let thisMetricAmountValue = 1;

      if (
        parseFloat(
          currentEditIngridientMetricServingAmountValue.substr(
            0,
            currentEditIngridientMetricServingAmountValue.indexOf('.')
          )
        ) !== 0
      ) {
        thisMetricAmountValue = parseFloat(
          currentEditIngridientMetricServingAmountValue.substr(
            0,
            currentEditIngridientMetricServingAmountValue.indexOf('.')
          )
        );
      }

      ingridientKcalNew = Math.round(
        (parseFloat(thisCurrentAmount) / thisMetricAmountValue) * currentEditIngridientKcalValue
      );

      ingridientKhNew = Math.round(
        (parseFloat(thisCurrentAmount) / thisMetricAmountValue) * currentEditIngridientCarbohydratesValue
      );

      ingridientEwNew = Math.round(
        (parseFloat(thisCurrentAmount) / thisMetricAmountValue) * currentEditIngridientProteinValue
      );

      ingridientFtNew = Math.round(
        (parseFloat(thisCurrentAmount) / thisMetricAmountValue) * currentEditIngridientFatValue
      );
    } else {
      ingridientKcalNew = (parseFloat(thisCurrentAmount) / 100) * currentEditIngridientKcalValue;
      ingridientKhNew = (parseFloat(thisCurrentAmount) / 100) * currentEditIngridientCarbohydratesValue;
      ingridientEwNew = (parseFloat(thisCurrentAmount) / 100) * currentEditIngridientProteinValue;
      ingridientFtNew = (parseFloat(thisCurrentAmount) / 100) * currentEditIngridientFatValue;
    }

    if (thisCurrentAmount !== 0) {
      setCurrentEditIngridientAmount(thisCurrentAmount);
    } else {
      setCurrentEditIngridientAmount('');
    }
    setCurrentEditIngridientKcalTotal(Math.round(ingridientKcalNew));
    setCurrentEditIngridientCarbohydratesTotal(Math.round(ingridientKhNew));
    setCurrentEditIngridientProteinTotal(Math.round(ingridientEwNew));
    setCurrentEditIngridientFatTotal(Math.round(ingridientFtNew));
    // setCurrentEditIngridientMetricServingAmountValue('');
  };

  const changeIngridientAmount = (event: any) => {
    const thisCurrentInitialAmount = event.target.value;
    let thisCurrentAmount = event.target.value;
    let ingridientKcalNew;
    let ingridientKhNew;
    let ingridientEwNew;
    let ingridientFtNew;
    let getIngridientRecipeData = [] as any;
    let getIngridient = [] as any;

    // Replace Comma with Point for calculating results
    thisCurrentAmount = thisCurrentAmount.replace(',', '.');

    // Replace all text characters
    thisCurrentAmount = thisCurrentAmount.replace(/[^.0-9]/g, '');

    if (Number.isNaN(parseFloat(thisCurrentAmount))) {
      thisCurrentAmount = 0;
    }

    if (thisCurrentAmount === '') {
      thisCurrentAmount = 0;
    }

    if (currentEditIngridientType === 'notAdded') {
      getIngridientRecipeData = ingredientList?.filter((item: any) => item.name === currentEditIngridientName);
    } else {
      getIngridientRecipeData = currentAddRecipe?.ingredients.filter(
        (item: any) => item.id === currentEditIngridientID
      );
    }

    getIngridient = ingredientList?.filter((item: any) => item.name === currentEditIngridientName);

    if (currentEditIngridientPiece !== 'g' && currentEditIngridientPiece !== 'ml') {
      ingridientKcalNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].kcal_100g)
      );

      ingridientKhNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].carbohydrates_100g)
      );

      ingridientEwNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].protein_100g)
      );

      ingridientFtNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].fat_100g)
      );
    } else {
      ingridientKcalNew = Math.round((parseFloat(getIngridient[0].kcal_100g) / 100) * parseFloat(thisCurrentAmount));

      ingridientKhNew = Math.round(
        (parseFloat(getIngridient[0].carbohydrates_100g) / 100) * parseFloat(thisCurrentAmount)
      );

      ingridientEwNew = Math.round((parseFloat(getIngridient[0].protein_100g) / 100) * parseFloat(thisCurrentAmount));

      ingridientFtNew = Math.round((parseFloat(getIngridient[0].fat_100g) / 100) * parseFloat(thisCurrentAmount));
    }

    if (thisCurrentAmount !== 0) {
      setCurrentEditIngridientAmount(thisCurrentAmount);
    } else {
      setCurrentEditIngridientAmount('');
    }

    setCurrentEditIngridientKcalTotal(ingridientKcalNew);
    setCurrentEditIngridientCarbohydratesTotal(ingridientKhNew);
    setCurrentEditIngridientProteinTotal(ingridientEwNew);
    setCurrentEditIngridientFatTotal(ingridientFtNew);
  };

  function getPrimaryPieceValue(ingridientData: any) {
    let thisPieceValue = '';

    if (ingridientData.serving_data.serving && ingridientData.serving_data.serving instanceof Array) {
      ingridientData.serving_data.serving.map((serving: any, index: any) => {
        if (
          serving.measurement_description !== 'g' &&
          serving.measurement_description !== 'ml' &&
          serving.serving_description !== '100ml' &&
          serving.serving_description !== '100g' &&
          serving.is_default === '1' &&
          !serving.serving_description.includes('(')
        ) {
          thisPieceValue =
            (serving.serving_description.includes('1 ') &&
              serving.serving_description.substr(2, serving.serving_description.length)) ||
            `${serving.serving_description} `;

          if (
            serving.measurement_description !== 'g' &&
            serving.measurement_description !== 'ml' &&
            !serving.measurement_description.includes('g)') &&
            !serving.measurement_description.includes('ml)')
          ) {
            thisPieceValue = `${thisPieceValue} (${serving.metric_serving_amount.substr(
              0,
              serving.metric_serving_amount.indexOf('.')
            )} ${serving.metric_serving_unit})`;
          }
        }

        if (
          serving.measurement_description !== 'g' &&
          serving.measurement_description !== 'ml' &&
          serving.serving_description !== '100ml' &&
          serving.serving_description !== '100g' &&
          serving.is_default === '1' &&
          serving.serving_description.includes('(')
        ) {
          thisPieceValue =
            (serving.serving_description.includes('1 ') &&
              serving.serving_description.substr(2, serving.serving_description.indexOf('(') - 3)) ||
            `${serving.serving_description.substr(0, serving.serving_description.indexOf('(') - 3)} `;

          if (
            serving.measurement_description !== 'g' &&
            serving.measurement_description !== 'ml' &&
            !serving.measurement_description.includes('g)') &&
            !serving.measurement_description.includes('ml)')
          ) {
            thisPieceValue = `${thisPieceValue} (${serving.metric_serving_amount.substr(
              0,
              serving.metric_serving_amount.indexOf('.')
            )} ${serving.metric_serving_unit})`;
          }
        }

        return '';
      });

      return thisPieceValue;
    }

    if (
      ingridientData.serving_data.serving &&
      !(ingridientData.serving_data.serving instanceof Array) &&
      ingridientData.serving_data.serving.serving_description !== '100ml' &&
      ingridientData.serving_data.serving.serving_description !== '100g' &&
      !ingridientData.serving_data.serving.serving_description.includes('(')
    ) {
      thisPieceValue =
        (ingridientData.serving_data.serving.serving_description.includes('1 ') &&
          ingridientData.serving_data.serving.serving_description.substr(
            2,
            ingridientData.serving_data.serving.serving_description.length
          )) ||
        ingridientData.serving_data.serving.serving_description;

      if (
        ingridientData.serving_data.serving.measurement_description !== 'g' &&
        ingridientData.serving_data.serving.measurement_description !== 'ml' &&
        !ingridientData.serving_data.serving.measurement_description.includes('g)') &&
        !ingridientData.serving_data.serving.measurement_description.includes('ml)') &&
        ingridientData.serving_data.serving.metric_serving_amount
      ) {
        thisPieceValue = `${thisPieceValue} (${ingridientData.serving_data.serving.metric_serving_amount.substr(
          0,
          ingridientData.serving_data.serving.metric_serving_amount.indexOf('.')
        )} ${ingridientData.serving_data.serving.metric_serving_unit})`;
      }

      return thisPieceValue;
    }

    if (
      ingridientData.serving_data.serving &&
      !(ingridientData.serving_data.serving instanceof Array) &&
      ingridientData.serving_data.serving.serving_description !== '100ml' &&
      ingridientData.serving_data.serving.serving_description !== '100g' &&
      ingridientData.serving_data.serving.serving_description.includes('(')
    ) {
      thisPieceValue =
        (ingridientData.serving_data.serving.serving_description.includes('1 ') &&
          ingridientData.serving_data.serving.serving_description.substr(
            2,
            ingridientData.serving_data.serving.serving_description.indexOf('(') - 3
          )) ||
        ingridientData.serving_data.serving.serving_description.substr(
          0,
          ingridientData.serving_data.serving.serving_description.indexOf('(') - 3
        );

      if (
        ingridientData.serving_data.serving.measurement_description !== 'g' &&
        ingridientData.serving_data.serving.measurement_description !== 'ml' &&
        !ingridientData.serving_data.serving.measurement_description.includes('g)') &&
        !ingridientData.serving_data.serving.measurement_description.includes('ml)') &&
        ingridientData.serving_data.serving.metric_serving_amount
      ) {
        thisPieceValue = `${thisPieceValue} (${ingridientData.serving_data.serving.metric_serving_amount.substr(
          0,
          ingridientData.serving_data.serving.metric_serving_amount.indexOf('.')
        )} ${ingridientData.serving_data.serving.metric_serving_unit})`;
      }

      return thisPieceValue;
    }
    return thisPieceValue;
  }

  function addIngridientDirectAPI(
    food_id: string,
    amount?: any,
    ingridientKcalTotal?: any,
    ingridientCarbohydratesTotal?: any,
    ingridientProteinTotal?: any,
    ingridientFatTotal?: any
  ) {
    setIsLoading(true);
    let thisCurrentKcalComplete = 0;
    let thisCurrentKHComplete = 0;
    let thisCurrentEWComplete = 0;
    let thisCurrentFTComplete = 0;

    let getIngridientServingData: any;
    let currentServingDescription;
    let thisAmount;
    let ingridientKcal100g;
    let ingridientKH100g;
    let ingridientEW100g;
    let ingridientFT100g;

    let thisCurrentPlanDayObject = {} as any;

    if (popupType === 'plan') {
      thisCurrentPlanDayObject = recipeStateValue?.dayEntries.filter((item: any) => item.id === updateDayId)[0];
    }

    try {
      axios({
        url: `${process.env.REACT_APP_API_URL}/getIngridientApi`,
        method: 'post',
        data: {
          token: accessTokenString,
          food_id,
          language: userData?.country ?? 'DE',
        },
        headers: {
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      }).then(response => {
        const { data } = response;

        if (Array.isArray(data.food.servings.serving)) {
          getIngridientServingData = data.food.servings.serving.filter((item: any) => item.is_default === '1');
          currentServingDescription = getIngridientServingData[0].serving_description.replace(/\s+/g, '');
        } else {
          getIngridientServingData = [data.food.servings.serving];
          currentServingDescription = data.food.servings.serving.serving_description.replace(/\s+/g, '');
        }

        let thisPiece;
        if (getIngridientServingData[0].serving_description.includes('1 ')) {
          if (
            getIngridientServingData[0].serving_description.includes('g)') ||
            getIngridientServingData[0].serving_description.includes('ml)')
          ) {
            thisPiece = getIngridientServingData[0].serving_description.substr(
              2,
              getIngridientServingData[0].serving_description.indexOf(' (') - 2
            );
          } else {
            thisPiece = getIngridientServingData[0].serving_description.substr(
              2,
              getIngridientServingData[0].serving_description.length
            );
          }
        } else {
          thisPiece = getIngridientServingData[0].serving_description;
        }
        if (thisPiece.includes('(')) {
          thisPiece = thisPiece.substr(0, thisPiece.indexOf('(') - 1);
        }

        if (
          currentServingDescription === '100ml' ||
          currentServingDescription === '100g' ||
          getIngridientServingData[0].measurement_description === 'g' ||
          getIngridientServingData[0].measurement_description === 'ml'
        ) {
          if (
            getIngridientServingData[0].metric_serving_unit === 'ml' ||
            getIngridientServingData[0].measurement_description === 'ml'
          ) {
            thisPiece = 'ml';
          } else {
            thisPiece = 'g';
          }
        }

        if (
          currentServingDescription !== '100ml' &&
          currentServingDescription !== '100g' &&
          getIngridientServingData[0].measurement_description !== 'g' &&
          getIngridientServingData[0].measurement_description !== 'ml'
        ) {
          thisAmount = getIngridientServingData[0].number_of_units.substr(
            0,
            getIngridientServingData[0].number_of_units.indexOf('.')
          );
        } else {
          thisAmount = 100;
        }

        if (getIngridientServingData[0].metric_serving_amount) {
          ingridientKcal100g = Math.round(
            (parseFloat(getIngridientServingData[0].calories) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientKH100g = Math.round(
            (parseFloat(getIngridientServingData[0].carbohydrate) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientEW100g = Math.round(
            (parseFloat(getIngridientServingData[0].protein) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientFT100g = Math.round(
            (parseFloat(getIngridientServingData[0].fat) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
        } else {
          ingridientKcal100g = Math.round(parseFloat(getIngridientServingData[0].calories));
          ingridientKH100g = Math.round(parseFloat(getIngridientServingData[0].carbohydrate));
          ingridientEW100g = Math.round(parseFloat(getIngridientServingData[0].protein));
          ingridientFT100g = Math.round(parseFloat(getIngridientServingData[0].fat));
        }

        if (popupType === 'plan') {
          if (
            ingridientKcalTotal !== undefined &&
            ingridientCarbohydratesTotal !== undefined &&
            ingridientProteinTotal !== undefined &&
            ingridientFatTotal !== undefined
          ) {
            thisCurrentKcalComplete = Math.round(
              parseFloat(thisCurrentPlanDayObject?.kcal_total!) + Math.round(parseFloat(ingridientKcalTotal))
            );

            thisCurrentKHComplete = Math.round(
              parseFloat(thisCurrentPlanDayObject?.carbohydrates_total!) +
                Math.round(parseFloat(ingridientCarbohydratesTotal))
            );

            thisCurrentEWComplete = Math.round(
              parseFloat(thisCurrentPlanDayObject?.protein_total!) + Math.round(parseFloat(ingridientProteinTotal))
            );

            thisCurrentFTComplete = Math.round(
              parseFloat(thisCurrentPlanDayObject?.fat_total!) + Math.round(parseFloat(ingridientFatTotal))
            );
          } else {
            thisCurrentKcalComplete = Math.round(
              parseFloat(thisCurrentPlanDayObject?.kcal_total!) +
                Math.round(parseFloat(getIngridientServingData[0].calories))
            );

            thisCurrentKHComplete = Math.round(
              parseFloat(thisCurrentPlanDayObject?.carbohydrates_total!) +
                Math.round(parseFloat(getIngridientServingData[0].carbohydrate))
            );

            thisCurrentEWComplete = Math.round(
              parseFloat(thisCurrentPlanDayObject?.protein_total!) +
                Math.round(parseFloat(getIngridientServingData[0].protein))
            );

            thisCurrentFTComplete = Math.round(
              parseFloat(thisCurrentPlanDayObject?.fat_total!) + Math.round(parseFloat(getIngridientServingData[0].fat))
            );
          }
        } else if (
          ingridientKcalTotal !== undefined &&
          ingridientCarbohydratesTotal !== undefined &&
          ingridientProteinTotal !== undefined &&
          ingridientFatTotal !== undefined
        ) {
          thisCurrentKcalComplete = Math.round(
            parseFloat(recipeStateValue?.kcal_total!) + Math.round(parseFloat(ingridientKcalTotal))
          );

          thisCurrentKHComplete = Math.round(
            parseFloat(recipeStateValue?.carbohydrates_total!) + Math.round(parseFloat(ingridientCarbohydratesTotal))
          );

          thisCurrentEWComplete = Math.round(
            parseFloat(recipeStateValue?.protein_total!) + Math.round(parseFloat(ingridientProteinTotal))
          );

          thisCurrentFTComplete = Math.round(
            parseFloat(recipeStateValue?.fat_total!) + Math.round(parseFloat(ingridientFatTotal))
          );
        } else {
          thisCurrentKcalComplete = Math.round(
            parseFloat(recipeStateValue?.kcal_total!) + Math.round(parseFloat(getIngridientServingData[0].calories))
          );

          thisCurrentKHComplete = Math.round(
            parseFloat(recipeStateValue?.carbohydrates_total!) +
              Math.round(parseFloat(getIngridientServingData[0].carbohydrate))
          );

          thisCurrentEWComplete = Math.round(
            parseFloat(recipeStateValue?.protein_total!) + Math.round(parseFloat(getIngridientServingData[0].protein))
          );

          thisCurrentFTComplete = Math.round(
            parseFloat(recipeStateValue?.fat_total!) + Math.round(parseFloat(getIngridientServingData[0].fat))
          );
        }

        let thisFoodName = data.food.food_name;
        if (data.food.brand_name) {
          thisFoodName += ` (${data.food.brand_name})`;
        }

        let thisFoodCategory = 'Sonstiges';
        if (data.food.food_sub_categories) {
          if (Array.isArray(data.food.food_sub_categories.food_sub_category)) {
            thisFoodCategory = data.food.food_sub_categories.food_sub_category[1].toString();
          } else {
            thisFoodCategory = data.food.food_sub_categories.food_sub_category;
          }
        }

        let thisKcalTotal = getIngridientServingData[0].calories;
        let thisCarbohydratesTotal = getIngridientServingData[0].carbohydrate;
        let thisProteinTotal = getIngridientServingData[0].protein;
        let thisFatTotal = getIngridientServingData[0].fat;

        if (ingridientKcalTotal !== undefined) {
          thisKcalTotal = ingridientKcalTotal;
        }
        if (ingridientCarbohydratesTotal !== undefined) {
          thisCarbohydratesTotal = ingridientCarbohydratesTotal;
        }
        if (ingridientProteinTotal !== undefined) {
          thisProteinTotal = ingridientProteinTotal;
        }
        if (ingridientFatTotal !== undefined) {
          thisFatTotal = ingridientFatTotal;
        }

        const thisIngridientObject = {
          id: Math.random(),
          food_id,
          name: thisFoodName,
          amount: amount !== undefined ? parseFloat(amount) : parseFloat(thisAmount),
          initial_amount: parseFloat(thisAmount),
          piece: thisPiece,
          kcal_100g: ingridientKcal100g,
          carbohydrates_100g: ingridientKH100g,
          protein_100g: ingridientEW100g,
          fat_100g: ingridientFT100g,
          kcal_total: Math.round(parseFloat(thisKcalTotal)),
          carbohydrates_total: Math.round(parseFloat(thisCarbohydratesTotal)),
          protein_total: Math.round(parseFloat(thisProteinTotal)),
          fat_total: Math.round(parseFloat(thisFatTotal)),
          preselect_g:
            (getIngridientServingData[0].metric_serving_amount &&
              getIngridientServingData[0].metric_serving_amount.substr(
                0,
                getIngridientServingData[0].metric_serving_amount.indexOf('.')
              )) ||
            100,
          category: thisFoodCategory,
          serving_id: getIngridientServingData[0].serving_id,
          serving_unit:
            getIngridientServingData[0].metric_serving_unit !== undefined
              ? getIngridientServingData[0].metric_serving_unit
              : 'g',
          serving_data: data.food.servings,
        };

        let newColumns = {
          ...recipeStateValue,
          kcal_total: thisCurrentKcalComplete,
          carbohydrates_total: thisCurrentKHComplete,
          protein_total: thisCurrentEWComplete,
          fat_total: thisCurrentFTComplete,
        };

        if (popupType === 'plan') {
          newColumns = {
            ...recipeStateValue,
            dayEntries: recipeStateValue.dayEntries.map((entries: any) => {
              if (parseFloat(entries.id) !== updateDayId) return entries;
              return {
                ...entries,
                kcal_total: thisCurrentKcalComplete,
                carbohydrates_total: thisCurrentKHComplete,
                protein_total: thisCurrentEWComplete,
                fat_total: thisCurrentFTComplete,
                [mealType]: {
                  ...entries[mealType],
                  kcal_total: parseFloat(entries[mealType].kcal_total) + Math.round(parseFloat(thisKcalTotal)),
                  carbohydrates_total:
                    parseFloat(entries[mealType].carbohydrates_total) + Math.round(parseFloat(thisCarbohydratesTotal)),
                  protein_total: parseFloat(entries[mealType].protein_total) + Math.round(parseFloat(thisProteinTotal)),
                  fat_total: parseFloat(entries[mealType].fat_total) + Math.round(parseFloat(thisFatTotal)),
                  ingredients: [thisIngridientObject, ...entries[mealType].ingredients],
                },
              };
            }),
          };
        } else {
          newColumns = {
            ...newColumns,
            ingredients: [...recipeStateValue.ingredients, thisIngridientObject],
          };
        }

        if (popupType !== 'plan') {
          setCurrentIngridientSearch(false);
          setCurrentSearchQuery('');
        }

        setCurrentIngridientSearchAutocomplete({ suggestions: { suggestion: [''] } });
        if (!Number.isNaN(thisAmount)) {
          toast.success('Das Lebensmittel wurde erfolgreich hinzugefügt!');

          if (popupType === 'plan') {
            setOpenerClass('hidden');
            savePlanChangesToFirebase(
              userData,
              newColumns,
              newColumns.uid,
              authContext.user?.uid,
              isDashBoard,
              updateRecipeState,
              tenant
            );

            const thisQuickListObject = {
              ...thisIngridientObject,
              dateAdded: moment().unix(),
            };
            if (quickList !== undefined) {
              if (quickList.length > 0) {
                if (
                  quickList[0].name === thisQuickListObject.name &&
                  quickList[0].kcal_total === thisQuickListObject.kcal_total
                ) {
                  <></>;
                } else {
                  addIngredientToQuickList(thisQuickListObject, authContext.user?.uid, tenant);
                }
              } else {
                addIngredientToQuickList(thisQuickListObject, authContext.user?.uid, tenant);
              }
            }
            setCurrentSearchQuery('');
          } else {
            updateRecipeState(newColumns);
          }

          if (popupType !== 'plan') {
            setRecipeChanged(true);
            setDirectAddedIngridients([thisIngridientObject, ...directAddedIngridients]);
          }

          if (setRefreshDashboard !== undefined) {
            setRefreshDashboard(true);
          }
        } else {
          toast.error('Die eigegebenen Daten enthielten Fehler!');
        }
        setIsLoading(false);
      });
    } catch (err) {
      toast.error('Es ist leider etwas schief gelaufen. Bitte probiere es erneut!');
    }
  }

  function getAPIIngridientData(food_id: string, amount?: any) {
    setIsLoading(true);
    let getIngridientServingData;
    let currentServingDescription;
    let thisAmount;
    let ingridientKcal100g;
    let ingridientKH100g;
    let ingridientEW100g;
    let ingridientFT100g;

    try {
      axios({
        url: `${process.env.REACT_APP_API_URL}/getIngridientApi`,
        method: 'post',
        data: {
          token: accessTokenString,
          food_id,
          language: userData?.country ?? 'DE',
        },
        headers: {
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      }).then(response => {
        const { data } = response;

        setCurrentApiFoodObject(response);

        if (Array.isArray(data.food.servings.serving)) {
          getIngridientServingData = data.food.servings.serving.filter((item: any) => item.is_default === '1');
          currentServingDescription = getIngridientServingData[0].serving_description.replace(/\s+/g, '');
        } else {
          getIngridientServingData = [data.food.servings.serving];
          currentServingDescription = data.food.servings.serving.serving_description.replace(/\s+/g, '');
        }

        let thisPiece;
        if (getIngridientServingData[0].serving_description.includes('1 ')) {
          if (
            getIngridientServingData[0].serving_description.includes('g)') ||
            getIngridientServingData[0].serving_description.includes('ml)')
          ) {
            thisPiece = getIngridientServingData[0].serving_description.substr(
              2,
              getIngridientServingData[0].serving_description.indexOf(' (') - 2
            );
          } else {
            thisPiece = getIngridientServingData[0].serving_description.substr(
              2,
              getIngridientServingData[0].serving_description.length
            );
          }
        } else {
          thisPiece = getIngridientServingData[0].serving_description;
        }
        if (thisPiece.includes('(')) {
          thisPiece = thisPiece.substr(0, thisPiece.indexOf('(') - 1);
        }

        if (
          currentServingDescription === '100ml' ||
          currentServingDescription === '100g' ||
          getIngridientServingData[0].measurement_description === 'g' ||
          getIngridientServingData[0].measurement_description === 'ml'
        ) {
          if (
            getIngridientServingData[0].metric_serving_unit === 'ml' ||
            getIngridientServingData[0].measurement_description === 'ml'
          ) {
            thisPiece = 'ml';
          } else {
            thisPiece = 'g';
          }
        }

        if (
          currentServingDescription !== '100ml' &&
          currentServingDescription !== '100g' &&
          getIngridientServingData[0].measurement_description !== 'g' &&
          getIngridientServingData[0].measurement_description !== 'ml'
        ) {
          thisAmount = getIngridientServingData[0].number_of_units.substr(
            0,
            getIngridientServingData[0].number_of_units.indexOf('.')
          );
        } else {
          thisAmount = 100;
        }

        if (getIngridientServingData[0].metric_serving_amount) {
          ingridientKcal100g = Math.round(
            (parseFloat(getIngridientServingData[0].calories) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientKH100g = Math.round(
            (parseFloat(getIngridientServingData[0].carbohydrate) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientEW100g = Math.round(
            (parseFloat(getIngridientServingData[0].protein) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientFT100g = Math.round(
            (parseFloat(getIngridientServingData[0].fat) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
        } else {
          ingridientKcal100g = Math.round(parseFloat(getIngridientServingData[0].calories));
          ingridientKH100g = Math.round(parseFloat(getIngridientServingData[0].carbohydrate));
          ingridientEW100g = Math.round(parseFloat(getIngridientServingData[0].protein));
          ingridientFT100g = Math.round(parseFloat(getIngridientServingData[0].fat));
        }

        let thisFoodName = data.food.food_name;
        if (data.food.brand_name) {
          thisFoodName += ` (${data.food.brand_name})`;
        }

        let thisFoodCategory = 'Sonstiges';
        if (data.food.food_sub_categories) {
          if (Array.isArray(data.food.food_sub_categories.food_sub_category)) {
            thisFoodCategory = data.food.food_sub_categories.food_sub_category[1].toString();
          } else {
            thisFoodCategory = data.food.food_sub_categories.food_sub_category;
          }
        }

        const thisIngridientObject = {
          id: food_id,
          name: thisFoodName,
          brandName: data.food.brand_name,
          amount: amount !== undefined ? parseFloat(amount) : thisAmount,
          piece: thisPiece,
          newAdded: true,
          kcal_100g: ingridientKcal100g,
          carbohydrates_100g: ingridientKH100g,
          protein_100g: ingridientEW100g,
          fat_100g: ingridientFT100g,
          kcal_total: Math.round(parseFloat(getIngridientServingData[0].calories)),
          carbohydrates_total: Math.round(parseFloat(getIngridientServingData[0].carbohydrate)),
          protein_total: Math.round(parseFloat(getIngridientServingData[0].protein)),
          fat_total: Math.round(parseFloat(getIngridientServingData[0].fat)),
          preselect_g:
            (getIngridientServingData[0].metric_serving_amount &&
              getIngridientServingData[0].metric_serving_amount.substr(
                0,
                getIngridientServingData[0].metric_serving_amount.indexOf('.')
              )) ||
            100,
          category: thisFoodCategory,
          serving_id: getIngridientServingData[0].serving_id,
          serving_unit:
            getIngridientServingData[0].metric_serving_unit !== undefined
              ? getIngridientServingData[0].metric_serving_unit
              : 'g',
          serving_data: data.food.servings,
        };

        setCurrentEditIngridientAPIFoodId(food_id);
        setCurrentEditIngridientType('notAdded');
        handleEditIngridientPopupAPI(thisIngridientObject);
      });
    } catch (err) {
      toast.error('Es ist leider ein Fehler aufgetreten. Bitte versuche es erneut!');
    }
  }

  const addBatchQuickListIngredients = () => {
    let thisCurrentKcalComplete = 0;
    let thisCurrentKHComplete = 0;
    let thisCurrentEWComplete = 0;
    let thisCurrentFTComplete = 0;

    const thisIngridientObject = [] as any;

    checkBoxQuickList.forEach(function (key: any) {
      if (key.data.serving_data !== undefined) {
        const thisObject = {
          id: Math.random().toString(),
          food_id: key.data.food_id,
          name: key.data.name,
          amount: key.data.amount,
          initial_amount: key.data.initial_amount,
          piece: key.data.piece,
          kcal_100g: key.data.kcal_100g,
          carbohydrates_100g: key.data.carbohydrates_100g,
          protein_100g: key.data.protein_100g,
          fat_100g: key.data.fat_100g,
          preselect_g: key.data.preselect_g,
          category: key.data.category,
          kcal_total: key.data.kcal_total,
          carbohydrates_total: key.data.carbohydrates_total,
          protein_total: key.data.protein_total,
          fat_total: key.data.fat_total,
          serving_id: key.data.serving_id,
          serving_unit: key.data.serving_unit,
          serving_data: key.data.serving_data,
        };

        thisCurrentKcalComplete += parseFloat(key.data.kcal_total);
        thisCurrentKHComplete += parseFloat(key.data.carbohydrates_total);
        thisCurrentEWComplete += parseFloat(key.data.protein_total);
        thisCurrentFTComplete += parseFloat(key.data.fat_total);

        thisIngridientObject.push(thisObject);
      } else {
        const thisObject = {
          id: Math.random().toString(),
          name: key.data.name,
          amount: key.data.amount,
          initial_amount: key.data.initial_amount,
          piece: key.data.piece,
          kcal_100g: key.data.kcal_100g,
          carbohydrates_100g: key.data.carbohydrates_100g,
          protein_100g: key.data.protein_100g,
          fat_100g: key.data.fat_100g,
          default_piece: key.data.default_piece,
          preselect_g: key.data.preselect_g,
          category: key.data.category,
          imageUrl: key.data.imageUrl,
          preselect_type: key.data.preselect_type,
          kcal_total: key.data.kcal_total,
          carbohydrates_total: key.data.carbohydrates_total,
          protein_total: key.data.protein_total,
          fat_total: key.data.fat_total,
        };

        thisCurrentKcalComplete += parseFloat(key.data.kcal_total);
        thisCurrentKHComplete += parseFloat(key.data.carbohydrates_total);
        thisCurrentEWComplete += parseFloat(key.data.protein_total);
        thisCurrentFTComplete += parseFloat(key.data.fat_total);

        thisIngridientObject.push(thisObject);
      }
    });

    const newColumns = {
      ...recipeStateValue,
      dayEntries: recipeStateValue.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== updateDayId) return entries;
        return {
          ...entries,
          kcal_total: parseFloat(entries.kcal_total) + thisCurrentKcalComplete,
          carbohydrates_total: parseFloat(entries.carbohydrates_total) + thisCurrentKHComplete,
          protein_total: parseFloat(entries.protein_total) + thisCurrentEWComplete,
          fat_total: parseFloat(entries.fat_total) + thisCurrentFTComplete,
          [mealType]: {
            ...entries[mealType],
            kcal_total: parseFloat(entries[mealType].kcal_total) + thisCurrentKcalComplete,
            carbohydrates_total: parseFloat(entries[mealType].carbohydrates_total) + thisCurrentKHComplete,
            protein_total: parseFloat(entries[mealType].protein_total) + thisCurrentEWComplete,
            fat_total: parseFloat(entries[mealType].fat_total) + thisCurrentFTComplete,
            ingredients: [...thisIngridientObject, ...entries[mealType].ingredients],
          },
        };
      }),
    };

    setCheckBoxQuickList([]);
    savePlanChangesToFirebase(
      userData,
      newColumns,
      newColumns.uid,
      authContext.user?.uid,
      isDashBoard,
      updateRecipeState,
      tenant
    );

    if (setRefreshDashboard !== undefined) {
      setRefreshDashboard(true);
    }
    setOpenerClass('hidden');
  };

  // DIRECT ADD: Add a non API ingridient without setting amount and piece (Plus icon)
  function addIngridientDirectInternal(
    piece: string,
    amount: number,
    preselect_g: string,
    kcal_100g: string,
    carbohydrates_100g: string,
    protein_100g: string,
    fat_100g: string,
    name: string,
    category: string,
    imageUrl: string,
    preselect_type: string,
    default_piece: string,
    updateQuickList?: boolean
  ) {
    let thisCurrentKcalComplete = 0;
    let thisCurrentKHComplete = 0;
    let thisCurrentEWComplete = 0;
    let thisCurrentFTComplete = 0;
    let thisCurrentPlanDayObject = {} as any;

    if (popupType === 'plan') {
      thisCurrentPlanDayObject = recipeStateValue?.dayEntries.filter((item: any) => item.id === updateDayId)[0];
    }

    if (piece !== 'g' && piece !== 'ml') {
      if (popupType !== 'plan') {
        thisCurrentKcalComplete = Math.round(
          parseFloat(recipeStateValue?.kcal_total!) + ((amount * parseFloat(preselect_g)) / 100) * parseFloat(kcal_100g)
        );

        thisCurrentKHComplete = Math.round(
          parseFloat(recipeStateValue?.carbohydrates_total!) +
            ((amount * parseFloat(preselect_g)) / 100) * parseFloat(carbohydrates_100g)
        );

        thisCurrentEWComplete = Math.round(
          parseFloat(recipeStateValue?.protein_total!) +
            ((amount * parseFloat(preselect_g)) / 100) * parseFloat(protein_100g)
        );

        thisCurrentFTComplete = Math.round(
          parseFloat(recipeStateValue?.fat_total!) + ((amount * parseFloat(preselect_g)) / 100) * parseFloat(fat_100g)
        );
      } else {
        thisCurrentKcalComplete = Math.round(
          parseFloat(thisCurrentPlanDayObject?.kcal_total!) +
            ((amount * parseFloat(preselect_g)) / 100) * parseFloat(kcal_100g)
        );

        thisCurrentKHComplete = Math.round(
          parseFloat(thisCurrentPlanDayObject?.carbohydrates_total!) +
            ((amount * parseFloat(preselect_g)) / 100) * parseFloat(carbohydrates_100g)
        );

        thisCurrentEWComplete = Math.round(
          parseFloat(thisCurrentPlanDayObject?.protein_total!) +
            ((amount * parseFloat(preselect_g)) / 100) * parseFloat(protein_100g)
        );

        thisCurrentFTComplete = Math.round(
          parseFloat(thisCurrentPlanDayObject?.fat_total!) +
            ((amount * parseFloat(preselect_g)) / 100) * parseFloat(fat_100g)
        );
      }
    } else if (popupType !== 'plan') {
      thisCurrentKcalComplete = Math.round(
        parseFloat(recipeStateValue?.kcal_total!) + (amount / 100) * parseFloat(kcal_100g)
      );

      thisCurrentKHComplete = Math.round(
        parseFloat(recipeStateValue?.carbohydrates_total!) + (amount / 100) * parseFloat(carbohydrates_100g)
      );

      thisCurrentEWComplete = Math.round(
        parseFloat(recipeStateValue?.protein_total!) + (amount / 100) * parseFloat(protein_100g)
      );

      thisCurrentFTComplete = Math.round(
        parseFloat(recipeStateValue?.fat_total!) + (amount / 100) * parseFloat(fat_100g)
      );
    } else {
      thisCurrentKcalComplete = Math.round(
        parseFloat(thisCurrentPlanDayObject?.kcal_total!) + (amount / 100) * parseFloat(kcal_100g)
      );

      thisCurrentKHComplete = Math.round(
        parseFloat(thisCurrentPlanDayObject?.carbohydrates_total!) + (amount / 100) * parseFloat(carbohydrates_100g)
      );

      thisCurrentEWComplete = Math.round(
        parseFloat(thisCurrentPlanDayObject?.protein_total!) + (amount / 100) * parseFloat(protein_100g)
      );

      thisCurrentFTComplete = Math.round(
        parseFloat(thisCurrentPlanDayObject?.fat_total!) + (amount / 100) * parseFloat(fat_100g)
      );
    }

    const thisPiece = piece;

    let thisIngridientObject = {
      id: Math.random().toString(),
      name,
      amount,
      initial_amount: amount,
      piece: thisPiece,
      kcal_100g,
      carbohydrates_100g,
      protein_100g,
      fat_100g,
      default_piece,
      preselect_g,
      category,
      imageUrl,
      preselect_type,
      kcal_total: 0,
      carbohydrates_total: 0,
      protein_total: 0,
      fat_total: 0,
    };

    const thisKcalTotal = getIngridientMacros(thisIngridientObject, thisIngridientObject, 'kcal');
    const thisCarbohydratesTotal = getIngridientMacros(thisIngridientObject, thisIngridientObject, 'carbohydrates');
    const thisProteinTotal = getIngridientMacros(thisIngridientObject, thisIngridientObject, 'protein');
    const thisFatTotal = getIngridientMacros(thisIngridientObject, thisIngridientObject, 'fat');

    thisIngridientObject = {
      ...thisIngridientObject,
      kcal_total: thisKcalTotal,
      carbohydrates_total: thisCarbohydratesTotal,
      protein_total: thisProteinTotal,
      fat_total: thisFatTotal,
    };

    let thisRecipeData = recipeStateValue;

    let newColumns = {
      ...recipeStateValue,
      kcal_total: thisCurrentKcalComplete,
      carbohydrates_total: thisCurrentKHComplete,
      protein_total: thisCurrentEWComplete,
      fat_total: thisCurrentFTComplete,
    };

    if (popupType === 'plan') {
      newColumns = {
        ...recipeStateValue,
        dayEntries: recipeStateValue.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== updateDayId) return entries;
          return {
            ...entries,
            kcal_total: thisCurrentKcalComplete,
            carbohydrates_total: thisCurrentKHComplete,
            protein_total: thisCurrentEWComplete,
            fat_total: thisCurrentFTComplete,
            [mealType]: {
              ...entries[mealType],
              kcal_total: parseFloat(entries[mealType].kcal_total) + parseFloat(thisKcalTotal),
              carbohydrates_total:
                parseFloat(entries[mealType].carbohydrates_total) + parseFloat(thisCarbohydratesTotal),
              protein_total: parseFloat(entries[mealType].protein_total) + parseFloat(thisProteinTotal),
              fat_total: parseFloat(entries[mealType].fat_total) + parseFloat(thisFatTotal),
              ingredients: [thisIngridientObject, ...entries[mealType].ingredients],
            },
          };
        }),
      };
    } else {
      newColumns = {
        ...newColumns,
        ingredients: [...recipeStateValue.ingredients, thisIngridientObject],
      };
    }

    const checkIncompabilityArray = [] as any;
    const thisPlanKcalWithValuesLength = recipeStateValue.dayEntries.filter((item: any) => item.kcal_total > 0).length;

    if (popupType !== 'plan') {
      // check incombality options of ingredients
      for (let i = 0; i < newColumns.ingredients.length; i += 1) {
        const thisIngridientList = ingredientList as any;
        const getThisIngridient = thisIngridientList?.find((item: any) => item.name === newColumns.ingredients[i].name);

        intolerancesOption.forEach(function (key) {
          if (
            getThisIngridient !== undefined &&
            typeof getThisIngridient[key.toLowerCase()] !== undefined &&
            getThisIngridient[key.toLowerCase()] !== null
          ) {
            if (getThisIngridient[key.toLowerCase()] === true) {
              checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
            }
          }
        });
      }
    } else {
      const thisIngridientList = ingredientList as any;
      const getThisIngridient = thisIngridientList?.find((item: any) => item.name === name);

      intolerancesOption.forEach(function (key) {
        if (
          getThisIngridient !== undefined &&
          typeof getThisIngridient[key.toLowerCase()] !== undefined &&
          getThisIngridient[key.toLowerCase()] !== null
        ) {
          if (getThisIngridient[key.toLowerCase()] === true) {
            checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
          }
        }
      });

      // Copy newColumns to variable because this needs to be updated in the following sections
      thisRecipeData = { ...newColumns };

      formOfNutrition.forEach(function (key) {
        if (
          getThisIngridient !== undefined &&
          typeof getThisIngridient[key.toLowerCase()] !== undefined &&
          getThisIngridient[key.toLowerCase()] !== null
        ) {
          if (thisPlanKcalWithValuesLength === 0 && getThisIngridient[key.toLowerCase()] === true) {
            thisRecipeData = { ...thisRecipeData, [key.toLowerCase()]: true };
          } else if (getThisIngridient[key.toLowerCase()] === false) {
            thisRecipeData = { ...thisRecipeData, [key.toLowerCase()]: false };
          }
        }
      });
    }

    // Set elements true if incompatibility exists
    for (let g = 0; g < checkIncompabilityArray.length; g += 1) {
      thisRecipeData = { ...thisRecipeData, [checkIncompabilityArray[g].name]: true };
    }

    if (currentEditIngridientType === 'add') {
      handleOpenClosePopups(setEditPopupOverlayClass, '', 'close');
      handleOpenClosePopups(setPopupOverlayClass, '', 'close');
      if (setRefreshDashboard !== undefined) {
        setRefreshDashboard(true);
      }
    }
    if (popupType !== 'plan') {
      setCurrentIngridientSearch(false);
      setCurrentSearchQuery('');
    }

    // this.setState({ currentIngridientPieceIdentifier: '' });
    setCurrentIngridientSearchAutocomplete({ suggestions: { suggestion: [''] } });
    if (!Number.isNaN(amount)) {
      toast.success('Das Lebensmittel wurde erfolgreich hinzugefügt!');

      if (popupType === 'plan') {
        setOpenerClass('hidden');
        savePlanChangesToFirebase(
          userData,
          thisRecipeData,
          thisRecipeData.uid,
          authContext.user?.uid,
          isDashBoard,
          updateRecipeState,
          tenant
        );

        const thisQuickListObject = {
          ...thisIngridientObject,
          dateAdded: moment().unix(),
        };
        if (quickList !== undefined) {
          if (quickList.length > 0) {
            if (
              quickList[0].name === thisQuickListObject.name &&
              quickList[0].kcal_total === thisQuickListObject.kcal_total
            ) {
              <></>;
            } else {
              addIngredientToQuickList(thisQuickListObject, authContext.user?.uid, tenant);
            }
          } else {
            addIngredientToQuickList(thisQuickListObject, authContext.user?.uid, tenant);
          }
        }

        setCurrentSearchQuery('');
      } else {
        updateRecipeState(thisRecipeData);
      }

      if (popupType !== 'plan') {
        setDirectAddedIngridients([thisIngridientObject, ...directAddedIngridients]);
        setRecipeChanged(true);
      }
    } else {
      toast.error('Die eingegebenen Daten enthielten Fehler!');
    }

    if (currentEditIngridientType === 'notAdded') {
      handleOpenClosePopups(setEditPopupOverlayClass, setPopupOverlayClass, 'both');
      if (setRefreshDashboard !== undefined) {
        setRefreshDashboard(true);
      }
    } else if (setRefreshDashboard !== undefined) {
      setRefreshDashboard(true);
    }
  }

  // Delete item from direct added list and ingridient list
  function deleteDirectAddedIngridient(
    thisId: string,
    amount: number,
    piece: string,
    preselect_g: string,
    kcal_100g: string,
    carbohydrates_100g: string,
    protein_100g: string,
    fat_100g: string
  ) {
    let ingridientKcal;
    let ingridientKh;
    let ingridientEw;
    let ingridientFt;

    if (piece !== 'g' && piece !== 'ml') {
      ingridientKcal = Math.round(((amount * parseFloat(preselect_g)) / 100) * parseFloat(kcal_100g));
      ingridientKh = Math.round(((amount * parseFloat(preselect_g)) / 100) * parseFloat(carbohydrates_100g));
      ingridientEw = Math.round(((amount * parseFloat(preselect_g)) / 100) * parseFloat(protein_100g));
      ingridientFt = Math.round(((amount * parseFloat(preselect_g)) / 100) * parseFloat(fat_100g));
    } else {
      ingridientKcal = Math.round((parseFloat(kcal_100g) / 100) * amount);
      ingridientKh = Math.round((parseFloat(carbohydrates_100g) / 100) * amount);
      ingridientEw = Math.round((parseFloat(protein_100g) / 100) * amount);
      ingridientFt = Math.round((parseFloat(fat_100g) / 100) * amount);
    }

    const checkIncompabilityArray = [] as any;
    const checkFormOfNutritionArray = [] as any;
    let thisRecipeData = currentAddRecipe;

    const newColumns = {
      ...currentAddRecipe,
      kcal_total: Math.round(parseFloat(currentAddRecipe.kcal_total) - ingridientKcal),
      carbohydrates_total: `${Math.round(parseFloat(currentAddRecipe.carbohydrates_total) - ingridientKh)}`,
      protein_total: `${Math.round(parseFloat(currentAddRecipe.protein_total) - ingridientEw)}`,
      fat_total: `${Math.round(parseFloat(currentAddRecipe.fat_total) - ingridientFt)}`,
      ingredients: [...currentAddRecipe.ingredients.filter((item: any) => item.id !== thisId)],
    };

    // check incombality options of ingredients
    for (let i = 0; i < newColumns.ingredients.length; i += 1) {
      const thisIngridientList = ingredientList as any;
      const getThisIngridient = thisIngridientList?.find((item: any) => item.name === newColumns.ingredients[i].name);

      intolerancesOption.forEach(function (key) {
        if (
          getThisIngridient !== undefined &&
          typeof getThisIngridient[key.toLowerCase()] !== undefined &&
          getThisIngridient[key.toLowerCase()] !== null
        ) {
          if (getThisIngridient[key.toLowerCase()] === true) {
            checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
          }
        }
      });

      formOfNutrition.forEach(function (key) {
        if (
          getThisIngridient !== undefined &&
          typeof getThisIngridient[key.toLowerCase()] !== undefined &&
          getThisIngridient[key.toLowerCase()] !== null
        ) {
          if (getThisIngridient[key.toLowerCase()] === true) {
            checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
          } else {
            checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: false });
          }
        }
      });
    }

    const checkFormOfNutritionArrayMerged = [
      ...checkFormOfNutritionArray.filter((item: any) => item.status === true),
      ...checkFormOfNutritionArray.filter((item: any) => item.status === false),
    ];

    // Copy newColumns to variable because this needs to be updated in the following sections
    thisRecipeData = { ...newColumns };

    // First set all to false
    for (let f = 0; f < intolerancesOption.length; f += 1) {
      thisRecipeData = { ...thisRecipeData, [intolerancesOption[f].toLowerCase()]: false };
    }
    for (let h = 0; h < formOfNutrition.length; h += 1) {
      thisRecipeData = { ...thisRecipeData, [formOfNutrition[h].toLowerCase()]: false };
    }

    // Set elements true if incompatibility exists
    for (let g = 0; g < checkIncompabilityArray.length; g += 1) {
      thisRecipeData = { ...thisRecipeData, [checkIncompabilityArray[g].name]: true };
    }

    // Set elements true if form of nutrition exists
    for (let g = 0; g < checkFormOfNutritionArrayMerged.length; g += 1) {
      thisRecipeData = {
        ...thisRecipeData,
        [checkFormOfNutritionArrayMerged[g].name]: checkFormOfNutritionArrayMerged[g].status,
      };
    }

    setDirectAddedIngridients(directAddedIngridients.filter(item => item.id !== thisId));

    if (popupType === 'plan') {
      savePlanChangesToFirebase(
        userData,
        thisRecipeData,
        thisRecipeData.uid,
        authContext.user?.uid,
        isDashBoard,
        updateRecipeState,
        tenant
      );
    } else {
      updateRecipeState(thisRecipeData);
    }

    setRecipeChanged(true);
  }

  function clickIngridientChange() {
    if (editIngridientRef.current) {
      editIngridientRef.current.select();
    }
  }

  const changeIngridientExecuteAPI = () => {
    setIsLoading(true);
    let currentKcal: any = recipeStateValue?.kcal_total;
    let currentKH: any = recipeStateValue?.carbohydrates_total;
    let currentEW: any = recipeStateValue?.protein_total;
    let currentFT: any = recipeStateValue?.fat_total;
    let getIngridientRecipeData = [] as any;
    let thisPieceValue = currentEditIngridientPiece;

    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    getIngridientRecipeData = recipeStateValue.ingredients.filter((item: any) => item.id === currentEditIngridientID);

    currentKcal = Math.round(
      parseFloat(currentKcal) - parseFloat(getIngridientRecipeData[0].kcal_total) + currentEditIngridientKcalTotal
    );
    currentKH = Math.round(
      parseFloat(currentKH) -
        parseFloat(getIngridientRecipeData[0].carbohydrates_total) +
        currentEditIngridientCarbohydratesTotal
    );
    currentEW = Math.round(
      parseFloat(currentEW) - parseFloat(getIngridientRecipeData[0].protein_total) + currentEditIngridientProteinTotal
    );
    currentFT = Math.round(
      parseFloat(currentFT) - parseFloat(getIngridientRecipeData[0].fat_total) + currentEditIngridientFatTotal
    );

    if (currentEditIngridientPiece.includes('(')) {
      thisPieceValue = currentEditIngridientPiece.substr(0, currentEditIngridientPiece.indexOf('(') - 1);
    }

    const newColumns = {
      ...recipeStateValue,
      kcal_total: currentKcal,
      carbohydrates_total: currentKH,
      protein_total: currentEW,
      fat_total: currentFT,
      ingredients: recipeStateValue?.ingredients.map((ingridients: any) => {
        if (parseFloat(ingridients.id) !== parseFloat(currentEditIngridientID)) return ingridients;
        return {
          ...ingridients,
          amount: thisEditIngredientAmount,
          piece: thisPieceValue,
          kcal_total: currentEditIngridientKcalTotal,
          carbohydrates_total: currentEditIngridientCarbohydratesTotal,
          protein_total: currentEditIngridientProteinTotal,
          fat_total: currentEditIngridientFatTotal,
        };
      }),
    };

    const newColumnsDirectAdd = directAddedIngridients.map((ingridients: any) => {
      if (parseFloat(ingridients.id) !== parseFloat(currentEditIngridientID)) return ingridients;
      return {
        ...ingridients,
        amount: thisEditIngredientAmount,
        piece: thisPieceValue,
        kcal_total: currentEditIngridientKcalTotal,
        carbohydrates_total: currentEditIngridientCarbohydratesTotal,
        protein_total: currentEditIngridientProteinTotal,
        fat_total: currentEditIngridientFatTotal,
      };
    });

    setCurrentEditIngridientKcalValue(0);
    setCurrentEditIngridientCarbohydratesValue(0);
    setCcurrentEditIngridientProteinValue(0);
    setCurrentEditIngridientFatValue(0);
    if (!Number.isNaN(parseFloat(thisEditIngredientAmount))) {
      if (popupType === 'plan') {
        savePlanChangesToFirebase(
          userData,
          newColumns,
          newColumns.uid,
          authContext.user?.uid,
          isDashBoard,
          updateRecipeState,
          tenant
        );
      } else {
        updateRecipeState(newColumns);
      }

      setRecipeChanged(true);
      setDirectAddedIngridients(newColumnsDirectAdd);
    } else {
      toast.error('Die eigegebenen Daten enthielten Fehler!');
    }
    setIsLoading(false);
    handleOpenClosePopups(setEditPopupOverlayClass, setPopupOverlayClass, 'both');
    if (setRefreshDashboard !== undefined) {
      setRefreshDashboard(true);
    }
  };

  const changeIngridientExecute = () => {
    let currentCalculatedKcal;
    let currentCalculatedKH;
    let currentCalculatedEW;
    let currentCalculatedFT;
    let currentKcal: any = recipeStateValue?.kcal_total;
    let currentKH: any = recipeStateValue?.carbohydrates_total;
    let currentEW: any = recipeStateValue?.protein_total;
    let currentFT: any = recipeStateValue?.fat_total;
    let getIngridientRecipeData = [] as any;
    let getIngridient = [] as any;
    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    getIngridientRecipeData = recipeStateValue.ingredients.filter((item: any) => item.id === currentEditIngridientID);
    getIngridient = ingredientList?.filter((item: any) => item.name === currentEditIngridientName);

    if (getIngridientRecipeData[0].piece !== 'g' && getIngridientRecipeData[0].piece !== 'ml') {
      currentCalculatedKcal =
        ((parseFloat(getIngridientRecipeData[0].amount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
        parseFloat(getIngridient[0].kcal_100g);

      currentCalculatedKH =
        ((parseFloat(getIngridientRecipeData[0].amount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
        parseFloat(getIngridient[0].carbohydrates_100g);

      currentCalculatedEW =
        ((parseFloat(getIngridientRecipeData[0].amount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
        parseFloat(getIngridient[0].protein_100g);

      currentCalculatedFT =
        ((parseFloat(getIngridientRecipeData[0].amount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
        parseFloat(getIngridient[0].fat_100g);
    } else {
      currentCalculatedKcal =
        (parseFloat(getIngridientRecipeData[0].amount) / 100) * parseFloat(getIngridient[0].kcal_100g);

      currentCalculatedKH =
        (parseFloat(getIngridientRecipeData[0].amount) / 100) * parseFloat(getIngridient[0].carbohydrates_100g);

      currentCalculatedEW =
        (parseFloat(getIngridientRecipeData[0].amount) / 100) * parseFloat(getIngridient[0].protein_100g);

      currentCalculatedFT =
        (parseFloat(getIngridientRecipeData[0].amount) / 100) * parseFloat(getIngridient[0].fat_100g);
    }

    currentKcal = Math.round(parseFloat(currentKcal) - currentCalculatedKcal + currentEditIngridientKcalTotal);
    currentKH = Math.round(parseFloat(currentKH) - currentCalculatedKH + currentEditIngridientCarbohydratesTotal);
    currentEW = Math.round(parseFloat(currentEW) - currentCalculatedEW + currentEditIngridientProteinTotal);
    currentFT = Math.round(parseFloat(currentFT) - currentCalculatedFT + currentEditIngridientFatTotal);

    const newColumns = {
      ...recipeStateValue,
      kcal_total: currentKcal,
      carbohydrates_total: currentKH,
      protein_total: currentEW,
      fat_total: currentFT,
      ingredients: recipeStateValue?.ingredients.map((ingridients: any) => {
        if (parseFloat(ingridients.id) !== parseFloat(currentEditIngridientID)) return ingridients;
        return {
          ...ingridients,
          amount: thisEditIngredientAmount,
          piece: currentEditIngridientPiece,
        };
      }),
    };

    const newColumnsDirectAdd = directAddedIngridients.map((ingridients: any) => {
      if (parseFloat(ingridients.id) !== parseFloat(currentEditIngridientID)) return ingridients;
      return {
        ...ingridients,
        amount: thisEditIngredientAmount,
        amountBefore: ingridients.amount,
        piece: currentEditIngridientPiece,
      };
    });

    if (!Number.isNaN(parseFloat(thisEditIngredientAmount))) {
      if (popupType === 'plan') {
        savePlanChangesToFirebase(
          userData,
          newColumns,
          newColumns.uid,
          authContext.user?.uid,
          isDashBoard,
          updateRecipeState,
          tenant
        );
      } else {
        updateRecipeState(newColumns);
      }

      setRecipeChanged(true);
      setDirectAddedIngridients(newColumnsDirectAdd);
    } else {
      toast.error('Die eigegebenen Daten enthielten Fehler!');
    }
    handleOpenClosePopups(setEditPopupOverlayClass, setPopupOverlayClass, 'both');
    if (setRefreshDashboard !== undefined) {
      setRefreshDashboard(true);
    }
  };

  // Delete api item from direct added list and ingridient list
  function deleteDirectAddedIngridientAPI(
    thisId: string,
    kcal_total: string,
    carbohydrates_total: string,
    protein_total: string,
    fat_total: string
  ) {
    let thisCurrentKcalComplete = 0;
    let thisCurrentKHComplete = 0;
    let thisCurrentEWComplete = 0;
    let thisCurrentFTComplete = 0;

    thisCurrentKcalComplete = Math.round(parseFloat(currentAddRecipe.kcal_total) - parseFloat(kcal_total));
    thisCurrentKHComplete = Math.round(
      parseFloat(currentAddRecipe.carbohydrates_total) - parseFloat(carbohydrates_total)
    );
    thisCurrentEWComplete = Math.round(parseFloat(currentAddRecipe.protein_total) - parseFloat(protein_total));
    thisCurrentFTComplete = Math.round(parseFloat(currentAddRecipe.fat_total) - parseFloat(fat_total));

    const newColumns = {
      ...currentAddRecipe,
      kcal_total: thisCurrentKcalComplete,
      carbohydrates_total: thisCurrentKHComplete,
      protein_total: thisCurrentEWComplete,
      fat_total: thisCurrentFTComplete,
      ingredients: [...currentAddRecipe.ingredients.filter((item: any) => item.id !== thisId)],
    };

    setDirectAddedIngridients(directAddedIngridients.filter(item => item.id !== thisId));
    if (popupType === 'plan') {
      savePlanChangesToFirebase(
        userData,
        newColumns,
        newColumns.uid,
        authContext.user?.uid,
        isDashBoard,
        updateRecipeState,
        tenant
      );
    } else {
      updateRecipeState(newColumns);
    }

    setRecipeChanged(true);
  }

  const changeIngridientPieceAPI = (event: any) => {
    let thisCurrentPieceLabel = event.value;
    let ingridientKcalNew: number;
    let ingridientKhNew: number;
    let ingridientEwNew: number;
    let ingridientFtNew: number;

    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    if (thisCurrentPieceLabel !== 'g' && thisCurrentPieceLabel !== 'ml') {
      ingridientKcalNew = Math.round(parseFloat(thisEditIngredientAmount) * currentEditIngridientKcalValue);

      ingridientKhNew = Math.round(parseFloat(thisEditIngredientAmount) * currentEditIngridientCarbohydratesValue);

      ingridientEwNew = Math.round(parseFloat(thisEditIngredientAmount) * currentEditIngridientProteinValue);

      ingridientFtNew = Math.round(parseFloat(thisEditIngredientAmount) * currentEditIngridientFatValue);
    } else if (currentEditIngridientMetricServingAmountValue !== '') {
      let thisMetricAmountValue = 1;

      if (
        parseFloat(
          currentEditIngridientMetricServingAmountValue.substr(
            0,
            currentEditIngridientMetricServingAmountValue.indexOf('.')
          )
        ) !== 0
      ) {
        thisMetricAmountValue = parseFloat(
          currentEditIngridientMetricServingAmountValue.substr(
            0,
            currentEditIngridientMetricServingAmountValue.indexOf('.')
          )
        );
      }

      ingridientKcalNew = Math.round(
        (parseFloat(thisEditIngredientAmount) / thisMetricAmountValue) * currentEditIngridientKcalValue
      );

      ingridientKhNew = Math.round(
        (parseFloat(thisEditIngredientAmount) / thisMetricAmountValue) * currentEditIngridientCarbohydratesValue
      );

      ingridientEwNew = Math.round(
        (parseFloat(thisEditIngredientAmount) / thisMetricAmountValue) * currentEditIngridientProteinValue
      );

      ingridientFtNew = Math.round(
        (parseFloat(thisEditIngredientAmount) / thisMetricAmountValue) * currentEditIngridientFatValue
      );
    } else {
      ingridientKcalNew = (parseFloat(thisEditIngredientAmount) / 100) * currentEditIngridientKcalValue;
      ingridientKhNew = (parseFloat(thisEditIngredientAmount) / 100) * currentEditIngridientCarbohydratesValue;
      ingridientEwNew = (parseFloat(thisEditIngredientAmount) / 100) * currentEditIngridientProteinValue;
      ingridientFtNew = (parseFloat(thisEditIngredientAmount) / 100) * currentEditIngridientFatValue;
    }

    setCurrentEditIngridientKcalTotal(Math.round(ingridientKcalNew));
    setCurrentEditIngridientCarbohydratesTotal(Math.round(ingridientKhNew));
    setCurrentEditIngridientProteinTotal(Math.round(ingridientEwNew));
    setCurrentEditIngridientFatTotal(Math.round(ingridientFtNew));
    // setCurrentEditIngridientMetricServingAmountValue('');

    if (thisCurrentPieceLabel.includes('(')) {
      thisCurrentPieceLabel = thisCurrentPieceLabel.substr(0, thisCurrentPieceLabel.indexOf('(') - 1);
    }

    if (thisCurrentPieceLabel === 'Gramm') {
      setCurrentEditIngridientPiece('g');
    } else {
      setCurrentEditIngridientPiece(event.value);
    }
  };

  const closeEditPopup = () => {
    handleOpenClosePopups(setEditPopupOverlayClass, setOpenerClass, 'both');
    if (setBarcodeFoodId !== undefined) {
      setBarcodeFoodId('');
      setCurrentMealTypeValue('breakfast');
    }
  };

  const changeIngridientPiece = (event: any) => {
    const thisCurrentPiece = event.value;
    let thisCurrentPieceLabel = event.label;
    let ingridientKcalNew;
    let ingridientKhNew;
    let ingridientEwNew;
    let ingridientFtNew;
    let getIngridientRecipeData = [] as any;
    let getIngridient = [] as any;

    if (thisCurrentPieceLabel.includes('(')) {
      thisCurrentPieceLabel = thisCurrentPieceLabel.substr(0, thisCurrentPieceLabel.indexOf('(') - 1);
    }

    if (popupType === 'plan') {
      getIngridientRecipeData = recipeStateValue?.dayEntries.filter((item: any) => item.id === updateDayId);
      getIngridientRecipeData = getIngridientRecipeData[0][mealType].ingredients.filter(
        (item: any) => item.id === currentEditIngridientID
      );
    } else {
      getIngridientRecipeData = recipeStateValue?.ingredients.filter(
        (item: any) => item.id === currentEditIngridientID
      );
    }

    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    getIngridient = ingredientList?.filter((item: any) => item.name === currentEditIngridientName);

    if (thisCurrentPiece !== 'g' && thisCurrentPiece !== 'ml') {
      ingridientKcalNew = Math.round(
        ((parseFloat(thisEditIngredientAmount) * parseFloat(getIngridient[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].kcal_100g)
      );

      ingridientKhNew = Math.round(
        ((parseFloat(thisEditIngredientAmount) * parseFloat(getIngridient[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].carbohydrates_100g)
      );

      ingridientEwNew = Math.round(
        ((parseFloat(thisEditIngredientAmount) * parseFloat(getIngridient[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].protein_100g)
      );

      ingridientFtNew = Math.round(
        ((parseFloat(thisEditIngredientAmount) * parseFloat(getIngridient[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].fat_100g)
      );
    } else {
      ingridientKcalNew = Math.round(
        (parseFloat(getIngridient[0].kcal_100g) / 100) * parseFloat(thisEditIngredientAmount)
      );

      ingridientKhNew = Math.round(
        (parseFloat(getIngridient[0].carbohydrates_100g) / 100) * parseFloat(thisEditIngredientAmount)
      );

      ingridientEwNew = Math.round(
        (parseFloat(getIngridient[0].protein_100g) / 100) * parseFloat(thisEditIngredientAmount)
      );

      ingridientFtNew = Math.round(
        (parseFloat(getIngridient[0].fat_100g) / 100) * parseFloat(thisEditIngredientAmount)
      );
    }

    if (thisCurrentPieceLabel === 'Gramm') {
      setCurrentEditIngridientPiece('g');
    } else if (thisCurrentPieceLabel === 'Milliliter') {
      setCurrentEditIngridientPiece('ml');
    } else {
      setCurrentEditIngridientPiece(thisCurrentPieceLabel);
    }

    setCurrentEditIngridientKcalTotal(ingridientKcalNew);
    setCurrentEditIngridientCarbohydratesTotal(ingridientKhNew);
    setCurrentEditIngridientProteinTotal(ingridientEwNew);
    setCurrentEditIngridientFatTotal(ingridientFtNew);
  };

  function handleEditIngridientPopup(
    ingridientId: string,
    name: string,
    amount: string,
    piece: string,
    imageUrl: string,
    preselect_g: string,
    preselect_type: string,
    kcal_100g: string,
    carbohydrates_100g: string,
    protein_100g: string,
    fat_100g: string,
    default_piece: string,
    type: string,
    category: string
  ) {
    setCurrentEditIngridientKcalValue(0);
    setCurrentEditIngridientCarbohydratesValue(0);
    setCcurrentEditIngridientProteinValue(0);
    setCurrentEditIngridientFatValue(0);
    setOpenerClass('hidden');

    setCurrentEditIngridientBrandName('');

    setCurrentEditIngridientPopupType('internal');

    if (piece !== 'g' && piece !== 'ml') {
      setCurrentEditIngridientKcalTotal(
        Math.round(((parseFloat(amount) * parseFloat(preselect_g)) / 100) * parseFloat(kcal_100g))
      );
      setCurrentEditIngridientCarbohydratesTotal(
        Math.round(((parseFloat(amount) * parseFloat(preselect_g)) / 100) * parseFloat(carbohydrates_100g))
      );
      setCurrentEditIngridientProteinTotal(
        Math.round(((parseFloat(amount) * parseFloat(preselect_g)) / 100) * parseFloat(protein_100g))
      );
      setCurrentEditIngridientFatTotal(
        Math.round(((parseFloat(amount) * parseFloat(preselect_g)) / 100) * parseFloat(fat_100g))
      );
    } else {
      setCurrentEditIngridientKcalTotal(Math.round((parseFloat(amount) / 100) * parseFloat(kcal_100g)));
      setCurrentEditIngridientCarbohydratesTotal(
        Math.round((parseFloat(amount) / 100) * parseFloat(carbohydrates_100g))
      );
      setCurrentEditIngridientProteinTotal(Math.round((parseFloat(amount) / 100) * parseFloat(protein_100g)));
      setCurrentEditIngridientFatTotal(Math.round((parseFloat(amount) / 100) * parseFloat(fat_100g)));
    }

    const thisPortionValues: any = [
      { value: preselect_type.toLowerCase(), label: `${preselect_type} (${preselect_g}g)` },
      { value: 'g', label: 'Gramm' },
    ];

    const thisPortionValuesWithDefaultPiece: any = [
      { value: preselect_type.toLowerCase(), label: `${preselect_type} (${preselect_g}${default_piece})` },
      { value: default_piece === 'ml' ? 'ml' : 'g', label: default_piece === 'ml' ? 'Milliliter' : 'Gramm' },
    ];

    if (default_piece !== undefined) {
      setCurrentEditIngridientPortionValues(thisPortionValuesWithDefaultPiece);
      setCurrentEditIngridientDefaultPiece(default_piece);
    } else {
      setCurrentEditIngridientPortionValues(thisPortionValues);
    }

    setCurrentEditIngridientPreselectG(preselect_g);
    setCurrentEditIngridientPreselectType(preselect_type);
    setCurrentEditIngridientKcal100g(kcal_100g);
    setCurrentEditIngridientCarbohydrates100g(carbohydrates_100g);
    setCurrentEditIngridientProtein100g(protein_100g);
    setCurrentEditIngridientFat100g(fat_100g);
    setCurrentEditIngridientCategory(category);

    setCurrentEditIngridientName(name);
    setCurrentEditIngridientType(type);
    setCurrentEditIngridientID(ingridientId);
    setCurrentEditInitialIngridientAmount(amount);
    setCurrentEditIngridientAmount(amount);
    setCurrentEditIngridientPiece(piece);
    if (imageUrl !== undefined) {
      setCurrentEditIngridientImageUrl(imageUrl);
    }
    setEditPopupOverlayClass('block');
  }

  function handleEditIngridientPopupAPI(ingridientData: any, addType?: string) {
    let thisServingDataArray = [] as any;
    let getIngridientServingData: any;
    const thisIngridientData = ingridientData;

    setCurrentEditIngridientPopupType('api');

    thisServingDataArray = thisIngridientData.serving_data.serving;

    if (thisIngridientData.serving_data.serving instanceof Array) {
      if (thisIngridientData.piece === 'g' || thisIngridientData.piece === 'ml') {
        getIngridientServingData = thisServingDataArray.filter(
          (item: any) => item.serving_id === thisIngridientData.serving_id
        );
      } else {
        getIngridientServingData = thisServingDataArray.filter(
          (item: any) => item.serving_id === thisIngridientData.serving_id
        );
      }
    } else {
      getIngridientServingData = [thisIngridientData.serving_data.serving];
    }

    setCurrentEditIngridientKcalValue(parseFloat(getIngridientServingData[0].calories));
    setCurrentEditIngridientCarbohydratesValue(parseFloat(getIngridientServingData[0].carbohydrate));
    setCcurrentEditIngridientProteinValue(parseFloat(getIngridientServingData[0].protein));
    setCurrentEditIngridientFatValue(parseFloat(getIngridientServingData[0].fat));
    setOpenerClass('hidden');
    if (getIngridientServingData[0].metric_serving_amount) {
      setCurrentEditIngridientMetricServingAmountValue(getIngridientServingData[0].metric_serving_amount);
    }

    let thisPortionValues: any;
    let thisPieceValue = '';

    if (getPrimaryPieceValue(thisIngridientData).length > 0) {
      thisPortionValues = [
        {
          value: getPrimaryPieceValue(thisIngridientData)?.toLowerCase(),
          label: getPrimaryPieceValue(thisIngridientData),
        },
        { value: 'g', label: 'Gramm' },
      ];
      thisPieceValue = getPrimaryPieceValue(thisIngridientData);
    } else if (thisIngridientData.piece === 'ml') {
      thisPortionValues = [{ value: 'ml', label: 'ml' }];
      thisPieceValue = 'ml';
    } else {
      thisPortionValues = [{ value: 'g', label: 'Gramm' }];
      thisPieceValue = 'g';
    }

    if (thisIngridientData.piece !== 'g' && thisIngridientData.piece !== 'ml') {
      setCurrentEditIngridientKcalTotal(
        Math.round(
          ((parseFloat(thisIngridientData.amount) * parseFloat(thisIngridientData.preselect_g)) / 100) *
            parseFloat(thisIngridientData.kcal_100g)
        )
      );
      setCurrentEditIngridientCarbohydratesTotal(
        Math.round(
          ((parseFloat(thisIngridientData.amount) * parseFloat(thisIngridientData.preselect_g)) / 100) *
            parseFloat(thisIngridientData.carbohydrates_100g)
        )
      );
      setCurrentEditIngridientProteinTotal(
        Math.round(
          ((parseFloat(thisIngridientData.amount) * parseFloat(thisIngridientData.preselect_g)) / 100) *
            parseFloat(thisIngridientData.protein_100g)
        )
      );
      setCurrentEditIngridientFatTotal(
        Math.round(
          ((parseFloat(thisIngridientData.amount) * parseFloat(thisIngridientData.preselect_g)) / 100) *
            parseFloat(thisIngridientData.fat_100g)
        )
      );
    } else {
      setCurrentEditIngridientKcalTotal(
        Math.round((parseFloat(thisIngridientData.amount) / 100) * parseFloat(thisIngridientData.kcal_100g))
      );
      setCurrentEditIngridientCarbohydratesTotal(
        Math.round((parseFloat(thisIngridientData.amount) / 100) * parseFloat(thisIngridientData.carbohydrates_100g))
      );
      setCurrentEditIngridientProteinTotal(
        Math.round((parseFloat(thisIngridientData.amount) / 100) * parseFloat(thisIngridientData.protein_100g))
      );
      setCurrentEditIngridientFatTotal(
        Math.round((parseFloat(thisIngridientData.amount) / 100) * parseFloat(thisIngridientData.fat_100g))
      );
    }

    setCurrentEditIngridientPortionValues(thisPortionValues);
    if (thisIngridientData.piece === 'g' || thisIngridientData.piece === 'ml') {
      setCurrentEditIngridientPiece(thisIngridientData.piece);
    } else {
      setCurrentEditIngridientPiece(thisPieceValue);
    }
    setCurrentEditIngridientAmount(thisIngridientData.amount);
    setCurrentEditInitialIngridientAmount(thisIngridientData.amount);
    setCurrentEditIngridientName(thisIngridientData.name);
    if (thisIngridientData.brandName?.length > 0) {
      setCurrentEditIngridientBrandName(thisIngridientData.brandName);
    } else {
      setCurrentEditIngridientBrandName('');
    }
    setCurrentEditIngridientID(thisIngridientData.id);
    setCurrentEditIngridientImageUrl('');
    if (addType !== undefined) {
      setCurrentEditIngridientType(addType);
    } else if (!ingridientData.newAdded) {
      setCurrentEditIngridientType('edit');
    }

    setIsLoading(false);
    if (barcodePopupCloser !== undefined && barcodeContentPopupClass !== undefined) {
      barcodePopupCloser(false);
      barcodeContentPopupClass(true);
    }
    setEditPopupOverlayClass('block');
  }

  // Execute FatSecret ingridient search
  function ingridientApiSearch(query: string) {
    if (currentSection !== 'recipes') {
      // Hide ios keyboard
      (document.activeElement as HTMLElement).blur();
    }

    setCurrentSearchQuery(query);

    console.log(query);

    try {
      axios({
        url: `${process.env.REACT_APP_API_URL}/ingridientApiSearch`,
        method: 'post',
        data: {
          token: accessTokenString,
          query,
          language: userData?.country ?? 'DE',
        },
        headers: {
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      }).then(response => {
        setCurrentIngridientSearchApi(response.data);
        setCurrentIngridientSearch(true);
        setCurrentIngridientSearchAutocomplete({ suggestions: { suggestion: [''] } });
      });
    } catch (err) {
      console.log('error');
    }
  }

  // Get FatSecret Autocomplete results
  const ingridientAutoCompleteSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSearchQuery(event.target.value);
    if (currentSection === 'recipes') {
      ingridientApiSearch(event.target.value);
    } else {
      handleSearchChange();
    }
  };

  const cancelToken = useRef(null) as any;

  const handleSearchChange = async () => {
    // setCount(count + 1);
    if (cancelToken.current !== null) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }

    // Save the cancel token for the current request
    cancelToken.current = axios.CancelToken.source();

    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/ingridientAutoCompleteSearch`,
          {
            token: accessTokenString,
            query: currentSearchQuery,
            language: userData?.country ?? 'DE',
            headers: {
              'content-type': 'application/json',
              Accept: 'application/json',
            },
          },
          {
            cancelToken: cancelToken?.current?.token,
          }
        )
        .then(response => {
          if (response.data.suggestions !== undefined) {
            setCurrentIngridientSearchAutocomplete(response.data);
            setCurrentIngridientSearch(false);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // Execute FatSecret ingridient autocomplete search
  // OLD DEBOUNCE VERSION
  /* const ingridientAutoCompleteSearchExecute = _.debounce(() => {
    if (currentSearchQuery.length > 0) {
      try {
        axios({
          url: `${process.env.REACT_APP_API_URL}/ingridientAutoCompleteSearch`,
          method: 'post',
          data: {
            token: accessTokenString,
            query: currentSearchQuery,
          },
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        }).then(response => {
          if (response.data.suggestions !== undefined) {
            setCurrentIngridientSearchAutocomplete(response.data);
            setCurrentIngridientSearch(false);
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  }, 10); */

  function changeSwitch() {
    if (currentSwitchIndex === 0) {
      setCurrentLoadedRecipeIndex(0);

      setIsClearFilter(true);

      setLoadedRecipes(
        recipesFilteredList.slice(0, 20).map((recipes: any) => {
          return {
            ...recipes,
          };
        })
      );

      setCurrentSection('recipes');
      setCurrentSwitchIndex(1);
    } else {
      setCurrentSection('food');
      setCurrentSwitchIndex(0);
    }
    setCurrentSearchQuery('');
    setCurrentIngridientSearch(false);
    setCurrentIngridientSearchAutocomplete({ suggestions: { suggestion: [''] } });
    if (searchIngridientRef.current) {
      searchIngridientRef.current.value = '';
      // searchIngridientRef.current.focus();
    }
    if (searchIngridientMobileRef.current) {
      searchIngridientMobileRef.current.value = '';
      // searchIngridientMobileRef.current.focus();
    }
  }

  const changeQuickListSwitch = () => {
    if (currentQuickListSwitchIndex === 0) {
      setCurrentQuickListSwitchIndex(1);
      setCurrentQuickListSection('favorites');
    } else {
      setCurrentQuickListSwitchIndex(0);
      setCurrentQuickListSection('last');
    }

    setCheckBoxQuickList([]);
  };

  const isManualAddValid = useMemo(() => {
    if (
      manualDescription.length > 0 &&
      manualKcal > 0 &&
      manualCarbohydrates > 0 &&
      manualProtein > 0 &&
      manualFat > 0
    ) {
      return true;
    }
    return false;
  }, [manualDescription, manualKcal, manualCarbohydrates, manualProtein, manualFat]);

  const openManualCaloriesPopup = () => {
    if (manualDescriptionRef.current) {
      manualDescriptionRef.current.value = '';
    }
    if (manualCaloriesRef.current) {
      manualCaloriesRef.current.value = '';
    }
    if (manualCarbohydratesRef.current) {
      manualCarbohydratesRef.current.value = '';
    }
    if (manualProteinRef.current) {
      manualProteinRef.current.value = '';
    }
    if (manualFatRef.current) {
      manualFatRef.current.value = '';
    }

    setManualDescription('');
    setManualKcal(0);
    setManualCarbohydrates(0);
    setManualProtein(0);
    setManualFat(0);

    setOpenerClass('hidden');
    setAddManualCaloriesClass('block');
  };

  const addManualItem = () => {
    const thisIngridientObject = {
      id: Math.random(),
      description: manualDescription,
      kcal_total: manualKcal,
      carbohydrates_total: manualCarbohydrates,
      protein_total: manualProtein,
      fat_total: manualFat,
    };

    const newColumns = {
      ...recipeStateValue,
      dayEntries: recipeStateValue.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== updateDayId) return entries;
        return {
          ...entries,
          kcal_total: Math.round(parseFloat(entries.kcal_total) + manualKcal),
          carbohydrates_total: Math.round(parseFloat(entries.carbohydrates_total) + manualCarbohydrates),
          protein_total: Math.round(parseFloat(entries.protein_total) + manualProtein),
          fat_total: Math.round(parseFloat(entries.fat_total) + manualFat),
          [mealType]: {
            ...entries[mealType],
            kcal_total: parseFloat(entries[mealType].kcal_total) + Math.round(manualKcal),
            carbohydrates_total: parseFloat(entries[mealType].carbohydrates_total) + Math.round(manualCarbohydrates),
            protein_total: parseFloat(entries[mealType].protein_total) + Math.round(manualProtein),
            fat_total: parseFloat(entries[mealType].fat_total) + Math.round(manualFat),
            manualItems:
              entries[mealType].manualItems !== undefined
                ? [thisIngridientObject, ...entries[mealType].manualItems]
                : [thisIngridientObject],
          },
        };
      }),
    };

    savePlanChangesToFirebase(
      userData,
      newColumns,
      newColumns.uid,
      authContext.user?.uid,
      isDashBoard,
      updateRecipeState,
      tenant
    );
    // updateRecipeState(newColumns);
    setAddManualCaloriesClass('hidden');
    if (setRefreshDashboard !== undefined) {
      setRefreshDashboard(true);
    }
  };

  const changeManualDescription = (event: any) => {
    const thisCurrentValue = event.target.value;

    setManualDescription(thisCurrentValue);
  };

  const generateNewMeal = () => {
    setOpenerClass('hidden');
    if (openGenerateMealPopup !== undefined) {
      openGenerateMealPopup();
    }
  };

  const changeManualCalories = (event: any) => {
    const thisCurrentValue = event.target.value;

    setManualKcal(parseFloat(thisCurrentValue));
  };

  const changeManualCarbohydrates = (event: any) => {
    const thisCurrentValue = event.target.value;

    setManualCarbohydrates(parseFloat(thisCurrentValue));
  };

  const changeManualProtein = (event: any) => {
    const thisCurrentValue = event.target.value;

    setManualProtein(parseFloat(thisCurrentValue));
  };

  const changeManualFat = (event: any) => {
    const thisCurrentValue = event.target.value;

    setManualFat(parseFloat(thisCurrentValue));
  };

  const loadPreviousRecipes = () => {
    setLastObjectPosition(20);
    setLoadedRecipes(currentRecipes => {
      return [...recipesFilteredList.slice(0, 20)];
    });
    setCurrentLoadedRecipeIndex(0);
  };

  useEffect(() => {
    if (openerClass === 'block' && searchIngridientRef.current) {
      // searchIngridientRef.current.focus();
    }
    if (openerClass === 'block' && searchIngridientMobileRef.current) {
      // searchIngridientMobileRef.current.focus();
    }
    if (openerClass === 'block' && quickListContainerRef.current) {
      scrollToTop(quickListContainerRef);
    }

    setCurrentQuickListSwitchIndex(0);
    setCurrentQuickListSection('last');
    setCheckBoxQuickList([]);
  }, [openerClass]);

  useEffect(() => {
    if (updateTriggerFavorites > 0) {
      getFavoriteIngredientStatus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTriggerFavorites]);

  useEffect(() => {
    if (editPopupOverlayClass === 'block') {
      getFavoriteIngredientStatus();
    } else {
      setIngredientFavoriteStatus(false);
    }
  }, [editPopupOverlayClass]);

  useEffect(() => {
    if (editPopupOverlayClass === 'block' && editIngridientRef.current) {
      editIngridientRef.current.select();
    }
  }, [editPopupOverlayClass]);

  useEffect(() => {
    if (popupType === 'recipeChange' || openRecipeOnLoad) {
      setCurrentSection('recipes');
    } else {
      setCurrentSection('food');
    }

    if (openRecipeOnLoad) {
      setCurrentSwitchIndex(1);
    } else {
      setCurrentSwitchIndex(0);
    }

    if (setOpenRecipeOnLoad !== undefined) {
      setOpenRecipeOnLoad(false);
    }

    setCurrentSearchQuery('');
    setCurrentIngridientSearch(false);
    // setIsClearFilter(true);
    setCurrentIngridientSearchAutocomplete({ suggestions: { suggestion: [''] } });
    if (openerClass !== 'block') {
      if (searchIngridientRef.current) {
        searchIngridientRef.current.value = '';
      }
      if (searchIngridientMobileRef.current) {
        searchIngridientMobileRef.current.value = '';
      }
    }
  }, [openerClass]);

  useEffect(() => {
    if (barcodeFoodId !== undefined) {
      if (barcodeFoodId.length > 0) {
        if (barcodeFoodId === '0') {
          setIsLoading(false);
          if (barcodePopupCloser !== undefined && barcodeContentPopupClass !== undefined) {
            barcodePopupCloser(false);
            barcodeContentPopupClass(true);
          }
          toast.warn('Zu diesem Barcode wurde leider kein Lebensmittel gefunden!');
        } else {
          getAPIIngridientData(barcodeFoodId);
        }
      }
    }
  }, [barcodeFoodId]);

  useEffect(() => {
    if (recipesList) {
      setLoadedRecipes(
        recipesList.slice(0, 20).map((recipes: any) => {
          return {
            ...recipes,
          };
        })
      );
    }
  }, [recipesList]);

  useEffect(() => {
    setLastObjectPosition(20);

    if (
      location?.state?.scrollPos !== undefined &&
      location?.state?.recipeUid !== undefined &&
      recipesFilteredList.length > 20
    ) {
      let findIndex = recipesFilteredList.findIndex(x => x.uid === location.state.recipeUid);

      setRecipeLoadedUid(location.state.recipeUid);

      if (findIndex !== undefined) {
        if (findIndex === 0) {
          findIndex = 1;
        }

        setLastObjectPosition(findIndex + 19);

        setCurrentLoadedRecipeIndex(findIndex);
        setLoadedRecipes(
          recipesFilteredList.slice(findIndex - 1, findIndex + 19).map((recipes: any) => {
            return {
              ...recipes,
            };
          })
        );

        setRecipeAlreadyLoaded(true);
      } else {
        setLoadedRecipes(
          recipesFilteredList.slice(location.state.scrollPos - 1, location.state.scrollPos + 19).map((recipes: any) => {
            return {
              ...recipes,
            };
          })
        );
      }

      history.replace({ state: {} });
    } else if (recipeAlreadyLoaded) {
      // Get new recipe list if user enters search query because of rerender
      if (recipesFilteredList.length > 20) {
        const findIndex = recipesFilteredList.findIndex(x => x.uid === recipeLoadedUid);

        setCurrentLoadedRecipeIndex(findIndex);

        setLastObjectPosition(findIndex + 19);

        setLoadedRecipes(
          recipesFilteredList.slice(findIndex - 1, findIndex + 19).map((recipes: any) => {
            return {
              ...recipes,
            };
          })
        );
      } else {
        setLoadedRecipes(
          recipesFilteredList.slice(0, 20).map((recipes: any) => {
            return {
              ...recipes,
            };
          })
        );
      }
      setRecipeAlreadyLoaded(false);
    } else {
      setLoadedRecipes(
        recipesFilteredList.slice(0, 20).map((recipes: any) => {
          return {
            ...recipes,
          };
        })
      );
    }

    if (location.state?.searchQuery !== undefined) {
      setCurrentSearchQuery(location.state.searchQuery);
      if (searchIngridientRef.current) {
        searchIngridientRef.current.value = location.state.searchQuery;
      }
      if (searchIngridientMobileRef.current) {
        searchIngridientMobileRef.current.value = location.state.searchQuery;
      }
    }
  }, [recipesFilteredList]);

  return (
    <>
      <Overlay
        headline="Manuelle Kalorien"
        overlayClass={addManualCaloriesClass}
        setOverlayClass={setAddManualCaloriesClass}
        icon={<RefreshIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-20 font-extralight">
            Hier kannst du die manuellen Nährwerte deiner Mahlzeit eingeben und tracken!
          </div>

          <div className="flex gap-40 pt-4">
            <div className="font-light my-auto w-1/2">Beschreibung:</div>
            <div className="w-3/4 md:w-full">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  thisRef={manualDescriptionRef}
                  name="description"
                  onChange={e => changeManualDescription(e)}
                  textCenter={false}
                  type="text"
                  placeHolder="Beschreibung"
                />
              </div>
            </div>
          </div>
          <div className="flex gap-40 pt-4">
            <div className="font-light my-auto  w-3/4 md:w-1/2">Kalorien (kcal):</div>
            <div className="w-3/4 md:w-full">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  thisValue={manualKcal === 0 ? '' : manualKcal.toString()}
                  name="manualKcal"
                  onChange={e => changeManualCalories(e)}
                  type="number"
                  textCenter={false}
                  placeHolder="Kalorien"
                />
              </div>
            </div>
          </div>
          <div className="flex gap-40 pt-4">
            <div className="font-light my-auto  w-3/4 md:w-1/2">Kohlenhydrate (g):</div>
            <div className="w-3/4 md:w-full">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  thisValue={manualCarbohydrates === 0 ? '' : manualCarbohydrates.toString()}
                  name="manualCarbohydrates"
                  onChange={e => changeManualCarbohydrates(e)}
                  type="number"
                  textCenter={false}
                  placeHolder="Kohlenhydrate"
                />
              </div>
            </div>
          </div>
          <div className="flex gap-40 pt-4">
            <div className="font-light my-auto  w-3/4 md:w-1/2">Eiweiß (g):</div>
            <div className="w-3/4 md:w-full">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  thisValue={manualProtein === 0 ? '' : manualProtein.toString()}
                  name="manualProtein"
                  onChange={e => changeManualProtein(e)}
                  type="number"
                  textCenter={false}
                  placeHolder="Eiweiß"
                />
              </div>
            </div>
          </div>
          <div className="flex gap-40 pt-4">
            <div className="font-light my-auto w-3/4 md:w-1/2">Fett (g):</div>
            <div className="w-3/4 md:w-full">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  thisValue={manualFat === 0 ? '' : manualFat.toString()}
                  name="manualFat"
                  onChange={e => changeManualFat(e)}
                  type="number"
                  textCenter={false}
                  placeHolder="Fett"
                />
              </div>
            </div>
          </div>

          <div className="w-full pt-40 flex gap-30 pb-40">
            <Button disabled={!isManualAddValid} className="w-full" onClick={() => addManualItem()}>
              Hinzufügen
            </Button>
          </div>
        </div>
      </Overlay>

      <div className={editPopupOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <TransitionContainer isShown={editPopupOverlayClass === 'block'} isOverlay>
            <div className={styles.addIngridientPopup}>
              <div className="flex justify-between">
                <div className="hidden desktop:block w-1/2 pb-40">
                  <div className="font-extralight pt-20 pb-30 px-20 text-14">
                    Wenn du dieses Lebensmittel hinzufügst würden sich die Werte in deinem aktuellen Plan folgendermaßen
                    verändern:
                  </div>
                  <div className="bg-lightGray p-20 rounded-3xl ml-20">
                    <MakroCircles
                      kcal_value={
                        parseInt(currentDayItem?.kcal_total.toString(), 10) +
                        parseInt(currentEditIngridientKcalTotal.toString(), 10)
                      }
                      carbohydrates_value={
                        parseInt(currentDayItem?.carbohydrates_total.toString(), 10) +
                        parseInt(currentEditIngridientCarbohydratesTotal.toString(), 10)
                      }
                      protein_value={
                        parseInt(currentDayItem?.protein_total.toString(), 10) +
                        parseInt(currentEditIngridientProteinTotal.toString(), 10)
                      }
                      fat_value={
                        parseInt(currentDayItem?.fat_total.toString(), 10) +
                        parseInt(currentEditIngridientFatTotal.toString(), 10)
                      }
                      custom_kcal_value={
                        currentDayItem?.custom_kcal_total
                          ? currentDayItem?.custom_kcal_total
                          : recipeStateValue?.custom_kcal_total
                          ? recipeStateValue?.custom_kcal_total
                          : undefined
                      }
                      custom_carbohydrates_value={
                        currentDayItem?.custom_carbohydrates_total
                          ? currentDayItem?.custom_carbohydrates_total
                          : recipeStateValue?.custom_carbohydrates_total
                          ? recipeStateValue?.custom_carbohydrates_total
                          : undefined
                      }
                      custom_protein_value={
                        currentDayItem?.custom_protein_total
                          ? currentDayItem?.custom_protein_total
                          : recipeStateValue?.custom_protein_total
                          ? recipeStateValue?.custom_protein_total
                          : undefined
                      }
                      custom_fat_value={
                        currentDayItem?.custom_fat_total
                          ? currentDayItem?.custom_fat_total
                          : recipeStateValue?.custom_fat_total
                          ? recipeStateValue?.custom_fat_total
                          : undefined
                      }
                    />
                  </div>
                </div>
                <div className="w-full desktop:w-1/2">
                  <div className="flex justify-between pt-20 pl-20">
                    <div className="flex">
                      <div className="my-auto pr-10">
                        <PencilIcon width={25} height={25} className="text-accentColor mx-auto" />
                      </div>
                      <div className="text-xl my-auto font-light">Bearbeiten</div>
                    </div>
                    <div className="flex gap-20">
                      <div className="my-auto">
                        <HeartIcon
                          width={25}
                          height={25}
                          className="text-accentColor mx-auto cursor-pointer"
                          onClick={() => triggerAddIngredientsToFavorites()}
                          fill={(ingredientFavoriteStatus && '#C97132') || 'none'}
                        />
                      </div>
                      <div className="my-auto pr-20">
                        <XIcon
                          width={25}
                          height={25}
                          className="text-accentColor mx-auto cursor-pointer"
                          onClick={() => closeEditPopup()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.ingridientPopupContentEdit}>
                    <div className="pt-15 pl-20 pb-30 pr-15">
                      <div className="pt-20 pb-10">
                        {currentEditIngridientBrandName.length > 0 ? (
                          <>
                            <div className="font-bold text-18">
                              {currentEditIngridientName.substr(0, currentEditIngridientName.indexOf('(') - 1)}
                            </div>
                            <div className="font-light text-gray-400">{currentEditIngridientBrandName}</div>
                          </>
                        ) : (
                          <div className="font-bold text-18">{currentEditIngridientName}</div>
                        )}
                      </div>
                      <hr className="pb-15" />
                      {barcodeFoodId !== undefined && barcodeFoodId?.length > 0 && (
                        <div className="pt-20 flex gap-20">
                          <div className="font-extralight my-auto pb-10 w-130">Zu welcher Mahlzeit hinzufügen?</div>
                          <div className="w-1/2">
                            <div>
                              <Select
                                options={mealTypeValues}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                unstyled
                                value={mealTypeValues.filter((item: any) => item.value === currentMealTypeValue)}
                                onChange={e => setCurrentMealTypeValue(e?.value as string)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div>
                        <div className="flex gap-20 pt-4">
                          <div className="font-extralight my-auto w-130">Menge auswählen:</div>
                          <div>
                            {currentEditIngridientType === 'notAdded' && (
                              <div onClick={() => clickIngridientChange()} aria-hidden="true">
                                <CustomUserInput
                                  thisRef={editIngridientRef}
                                  name="amount"
                                  customPaddingY="10"
                                  customPaddingX="10"
                                  thisValue={currentEditIngridientAmount}
                                  submitFunction={() =>
                                    currentEditIngridientPopupType === 'api'
                                      ? addChangedIngridientAPI()
                                      : addIngridientDirectInternal(
                                          currentEditIngridientPiece,
                                          currentEditIngridientAmount.toString().length === 0
                                            ? 0
                                            : parseFloat(currentEditIngridientAmount),
                                          currentEditIngridientPreselectG,
                                          currentEditIngridientKcal100g,
                                          currentEditIngridientCarbohydrates100g,
                                          currentEditIngridientProtein100g,
                                          currentEditIngridientFat100g,
                                          currentEditIngridientName,
                                          currentEditIngridientCategory,
                                          currentEditIngridientImageUrl,
                                          currentEditIngridientPreselectType,
                                          currentEditIngridientDefaultPiece
                                        )
                                  }
                                  onChange={e =>
                                    currentEditIngridientPopupType === 'api'
                                      ? changeIngridientAmountAPI(e)
                                      : changeIngridientAmount(e)
                                  }
                                  width={4}
                                />
                              </div>
                            )}
                            {currentEditIngridientType !== 'notAdded' && (
                              <div onClick={() => clickIngridientChange()} aria-hidden="true">
                                <CustomUserInput
                                  thisValue={currentEditIngridientAmount}
                                  thisRef={editIngridientRef}
                                  name="amount"
                                  submitFunction={() =>
                                    currentEditIngridientPopupType === 'api'
                                      ? changeIngridientExecuteAPI()
                                      : changeIngridientExecute()
                                  }
                                  onChange={e =>
                                    currentEditIngridientPopupType === 'api'
                                      ? changeIngridientAmountAPI(e)
                                      : changeIngridientAmount(e)
                                  }
                                  width={
                                    currentEditIngridientAmount.toString().length === 0
                                      ? 1
                                      : currentEditIngridientAmount.toString().length
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="flex gap-20 pt-4">
                          <div className="font-extralight my-auto w-130">Einheit auswählen:</div>
                          <div className="flex-1">
                            <div>
                              <Select
                                options={currentEditIngridientPortionValues}
                                className="react-select-container-full"
                                classNamePrefix="react-select"
                                unstyled
                                onChange={e =>
                                  currentEditIngridientPopupType === 'api'
                                    ? changeIngridientPieceAPI(e)
                                    : changeIngridientPiece(e)
                                }
                                value={currentEditIngridientPortionValues.filter(
                                  (item: any) => item.value === currentEditIngridientPiece.toLowerCase()
                                )}
                                isSearchable={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pb-20 md:pb-40">
                      <div className="text-xl pl-20">Nährwertangabe</div>
                      <div className="pt-10 flex pl-20">
                        <div className={styles.nutritionBorderItemFirst}>
                          <div>
                            <div className="font-semibold text-base">{currentEditIngridientKcalTotal}</div>
                            <div className={styles.recipeLabel}>kcal</div>
                          </div>
                          <span className="divider" />
                        </div>
                        <div className={styles.nutritionBorderItem}>
                          <div className="font-semibold text-base">{currentEditIngridientCarbohydratesTotal}g</div>
                          <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                        </div>
                        <div className={styles.nutritionBorderItem}>
                          <div className="font-semibold text-base">{currentEditIngridientProteinTotal}g</div>
                          <div className={styles.recipeLabel}>{t('Protein')}</div>
                        </div>
                        <div className="text-center px-20">
                          <div className="font-semibold text-base">{currentEditIngridientFatTotal}g</div>
                          <div className={styles.recipeLabel}>{t('Fat')}</div>
                        </div>
                      </div>
                      {currentEditIngridientImageUrl.length > 0 && (
                        <>
                          <div className="pt-30 md:pt-40 pl-25">
                            <img
                              src={currentEditIngridientImageUrl}
                              alt="Ingridient"
                              className="rounded-2xl h-90 md:h-auto w-full object-cover"
                              loading="lazy"
                            />
                          </div>
                        </>
                      )}
                      <div className="pl-20 pt-30 md:pt-40 bottom-20">
                        {isLoading ? (
                          <div className="flex justify-center items-center pt-20">
                            <ReactLoading
                              type="bars"
                              width={20}
                              height={20}
                              color={theme?.textColor ?? defaultTextColor}
                            />
                          </div>
                        ) : (
                          <>
                            {currentEditIngridientType === 'add' && (
                              <Button
                                className="w-full"
                                onClick={() =>
                                  currentEditIngridientPopupType === 'api'
                                    ? changeIngridientExecuteAPI()
                                    : addIngridientDirectInternal(
                                        currentEditIngridientPiece,
                                        currentEditIngridientAmount.toString().length === 0
                                          ? 0
                                          : parseFloat(currentEditIngridientAmount),
                                        '100',
                                        '100',
                                        '100',
                                        '100',
                                        '100',
                                        currentEditIngridientName,
                                        '100',
                                        currentEditIngridientImageUrl,
                                        currentEditIngridientPiece,
                                        currentEditIngridientDefaultPiece
                                      )
                                }
                              >
                                {t('Save')}
                              </Button>
                            )}
                            {currentEditIngridientType === 'edit' && (
                              <Button
                                className="w-full"
                                onClick={() =>
                                  currentEditIngridientPopupType === 'api'
                                    ? changeIngridientExecuteAPI()
                                    : changeIngridientExecute()
                                }
                              >
                                {t('Save')}
                              </Button>
                            )}
                            {currentEditIngridientType === 'notAdded' && (
                              <Button
                                className="w-full"
                                onClick={() =>
                                  currentEditIngridientPopupType === 'api'
                                    ? addChangedIngridientAPI()
                                    : addIngridientDirectInternal(
                                        currentEditIngridientPiece,
                                        currentEditIngridientAmount.toString().length === 0
                                          ? 0
                                          : parseFloat(currentEditIngridientAmount),
                                        currentEditIngridientPreselectG,
                                        currentEditIngridientKcal100g,
                                        currentEditIngridientCarbohydrates100g,
                                        currentEditIngridientProtein100g,
                                        currentEditIngridientFat100g,
                                        currentEditIngridientName,
                                        currentEditIngridientCategory,
                                        currentEditIngridientImageUrl,
                                        currentEditIngridientPreselectType,
                                        currentEditIngridientDefaultPiece
                                      )
                                }
                              >
                                {t('Add')}
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TransitionContainer>
        </div>
      </div>

      <div className={openerClass}>
        <div className={styles.backgroundPopupLayer}>
          <TransitionContainer isShown={openerClass === 'block'} isOverlay>
            <div className={styles.popupWrapper}>
              {checkBoxQuickList.length > 0 && (
                <div className="absolute left-0 w-full bottom-0 z-50 pt-10 pb-10 bg-bgColor flex rounded-b-3xl justify-center">
                  <Button onClick={() => addBatchQuickListIngredients()} className="w-full mx-30">
                    Hinzufügen ({checkBoxQuickList.length})
                  </Button>
                </div>
              )}

              <div className="flex justify-between pt-20 pl-20">
                <div className="flex">
                  {popupType === 'recipeChange' ? (
                    <>
                      <div className="my-auto pr-10">
                        <RefreshIcon width={25} height={25} className="text-accentColor mx-auto" />
                      </div>
                      <div className="text-xl my-auto font-light">Rezept austauschen</div>
                    </>
                  ) : (
                    <>
                      <div className="my-auto pr-10">
                        <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
                      </div>
                      <div className="text-xl my-auto font-light">Neues Lebensmittel oder Rezept hinzufügen</div>
                    </>
                  )}
                </div>
                <div className="my-auto pr-20">
                  <XIcon
                    width={25}
                    height={25}
                    className="text-accentColor mx-auto cursor-pointer"
                    onClick={() => closeMainPopup()}
                  />
                </div>
              </div>
              <div className={styles.popupContent}>
                {popupType === 'plan' && (
                  <div className="pt-30 pl-20 pr-20">
                    <SwitchSelector
                      onChange={changeSwitch}
                      options={recipesOption}
                      initialSelectedIndex={0}
                      forcedSelectedIndex={currentSwitchIndex}
                      backgroundColor="#3D4045"
                      selectedFontColor={theme?.buttonTextColor ?? defaultButtonTextColor}
                      fontColor={theme?.textColor ?? defaultTextColor}
                      fontSize={20}
                      selectionIndicatorMargin={0}
                    />
                  </div>
                )}

                <div className=" pt-20 pl-20 pr-20 pb-20">
                  <div className="hidden md:block">
                    <form
                      onSubmit={e => {
                        e.preventDefault();
                        ingridientApiSearch(currentSearchQuery);
                      }}
                      className={styles.searchContainer}
                    >
                      <SearchBox
                        thisRef={searchIngridientRef}
                        searchValue={currentSearchQuery}
                        onChange={ingridientAutoCompleteSearch}
                        className={styles.searchDesktop}
                      />
                      {currentSection === 'recipes' && (
                        <div
                          role="button"
                          aria-hidden
                          className={classNames('flex items-center space-x-3', {})}
                          onClick={filterOpen}
                        >
                          <AdjustmentsIcon
                            width={28}
                            height={28}
                            aria-hidden="true"
                            className={classNames(styles.filterIcon, {})}
                          />
                          <Headline level={3}>{t('Filter')}</Headline>
                        </div>
                      )}
                    </form>
                  </div>
                  <div className="md:hidden flex space-x-3">
                    <form
                      onSubmit={e => {
                        e.preventDefault();
                        ingridientApiSearch(currentSearchQuery);
                      }}
                      className={styles.searchContainer}
                    >
                      <SearchBox
                        thisRef={searchIngridientMobileRef}
                        onChange={ingridientAutoCompleteSearch}
                        searchValue={currentSearchQuery}
                        className={styles.searchMobile}
                      />
                    </form>
                    {currentSection === 'recipes' && (
                      <div
                        role="button"
                        aria-hidden
                        className={classNames('flex items-center space-x-3', {})}
                        onClick={filterOpen}
                      >
                        <AdjustmentsIcon
                          width={28}
                          height={28}
                          aria-hidden="true"
                          className={classNames(styles.filterIcon, {})}
                        />
                        <Headline level={3}>{t('Filter')}</Headline>
                      </div>
                    )}
                  </div>
                </div>
                {currentSection === 'recipes' && (
                  <>
                    <div className="flex justify-between flex-wrap pb-10">
                      <div className="font-extralight pb-10 px-20 my-auto">{recipesFilteredList.length} Rezepte</div>
                      <div className="flex space-x-10">
                        {recipeStateValue?.uid !== undefined &&
                          (userData?.role === 2 ||
                            tenantData?.settings?.functions?.recipeGenerator === undefined ||
                            userData?.isAdminUser ||
                            userData?.isTrainerUser ||
                            userData?.isLocationAdminUser ||
                            localStorage.getItem('currentUserToken') ||
                            tenantData?.settings?.functions?.recipeGenerator === true) && (
                            <div className="pl-20 md:pl-0">
                              <Button
                                className="py-5 px-15 text-12"
                                onClick={() => openGenerateMealPopup !== undefined && generateNewMeal()}
                              >
                                Mahlzeit generieren
                              </Button>
                            </div>
                          )}
                        <div>
                          <Button className="py-5 px-15 text-12" onClick={() => openManualCaloriesPopup()}>
                            Manuelle Kalorien
                          </Button>
                        </div>
                      </div>
                    </div>
                    {currentLoadedRecipeIndex > 0 && (
                      <div className="pt-20 mx-20 pb-15">
                        <Button className="py-2 uppercase w-full md:w-auto" onClick={() => loadPreviousRecipes()}>
                          Vorherige Rezepte laden
                        </Button>
                      </div>
                    )}
                  </>
                )}
                {currentSection !== 'recipes' && (
                  <>
                    <div className="flex justify-between flex-wrap pb-20">
                      {currentSection !== 'recipes' && currentSearchQuery.length === 0 && quickList !== undefined && (
                        <>
                          {quickList.length > 0 && (
                            <div className="pl-20 my-auto font-semibold flex gap-10 w-1/2">
                              <SwitchSelector
                                onChange={changeQuickListSwitch}
                                options={ingredientQuickOption}
                                initialSelectedIndex={0}
                                forcedSelectedIndex={currentQuickListSwitchIndex}
                                backgroundColor="#3D4045"
                                selectedFontColor={theme?.buttonTextColor ?? defaultButtonTextColor}
                                fontColor={theme?.textColor ?? defaultTextColor}
                                fontSize={12}
                                selectionIndicatorMargin={0}
                              />
                            </div>
                          )}
                        </>
                      )}
                      <div className="px-20 my-auto">
                        <Button className="py-5 px-15 text-12" onClick={() => openManualCaloriesPopup()}>
                          Manuelle Kalorien
                        </Button>
                      </div>
                    </div>
                    {currentSection !== 'recipes' &&
                      currentSearchQuery.length === 0 &&
                      currentQuickListSection !== 'favorites' &&
                      !isLoading && (
                        <div className={styles.ingridientPopupContent} ref={quickListContainerRef}>
                          {quickList?.map((ingridient, index) => (
                            <div className="ml-20 flex" key={index}>
                              <div
                                className="pt-15"
                                onClick={() => clickQuickListCheckbox(index, ingridient)}
                                aria-hidden="true"
                              >
                                {checkBoxQuickList.findIndex((item: any) => item.id === index) !== -1 ? (
                                  <CheckCircleIcon
                                    width={25}
                                    height={25}
                                    className="h-20 w-20 mr-10 text-accentColor"
                                  />
                                ) : (
                                  <div className="rounded-full w-20 h-20 border-2 border-accentColor mr-10">
                                    <></>
                                  </div>
                                )}
                              </div>
                              <div
                                className={styles.contentItemQuick}
                                onClick={() =>
                                  ingridient.serving_data
                                    ? getAPIIngridientData(ingridient.food_id, ingridient.amount)
                                    : handleEditIngridientPopup(
                                        ingridient.id,
                                        ingridient.name,
                                        ingridient.amount,
                                        ingridient.piece,
                                        ingridient.imageUrl,
                                        ingridient.preselect_g,
                                        ingridient.preselect_type,
                                        ingridient.kcal_100g,
                                        ingridient.carbohydrates_100g,
                                        ingridient.protein_100g,
                                        ingridient.fat_100g,
                                        ingridient.default_piece,
                                        'notAdded',
                                        ingridient.category
                                      )
                                }
                                onKeyDown={() =>
                                  ingridient.serving_data
                                    ? getAPIIngridientData(ingridient.food_id, ingridient.amount)
                                    : handleEditIngridientPopup(
                                        ingridient.id,
                                        ingridient.name,
                                        ingridient.amount,
                                        ingridient.piece,
                                        ingridient.imageUrl,
                                        ingridient.preselect_g,
                                        ingridient.preselect_type,
                                        ingridient.kcal_100g,
                                        ingridient.carbohydrates_100g,
                                        ingridient.protein_100g,
                                        ingridient.fat_100g,
                                        ingridient.default_piece,
                                        'notAdded',
                                        ingridient.category
                                      )
                                }
                                aria-hidden="true"
                              >
                                <div className={styles.description}>
                                  <div className="pr-5">
                                    <div className="text-14 truncate w-200" title={ingridient.name}>
                                      {ingridient.name}{' '}
                                    </div>
                                    <div className="text-10 font-light">
                                      {ingridient.amount}
                                      {ingridient.piece.match(/^\d/) && 'x'} {ingridient.piece}{' '}
                                      {ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        `(${ingridient.amount * ingridient.preselect_g}`}
                                      {ingridient.serving_unit &&
                                        ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        `${ingridient.serving_unit})`}
                                      {!ingridient.serving_data &&
                                        ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        ingridient.default_piece === undefined &&
                                        'g)'}
                                      {!ingridient.serving_data &&
                                        ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        ingridient.default_piece !== undefined &&
                                        `${ingridient.default_piece})`}
                                      {ingridient.serving_data &&
                                        !ingridient.serving_unit &&
                                        ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        'g)'}
                                    </div>
                                  </div>
                                  <div className={styles.category}>
                                    <div className={styles.item}>
                                      <div className="text-12">
                                        {(ingridient.piece !== 'g' &&
                                          ingridient.piece !== 'ml' &&
                                          Math.round(
                                            ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) /
                                              100) *
                                              parseFloat(ingridient.kcal_100g)
                                          )) ||
                                          Math.round(
                                            (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.kcal_100g)
                                          )}
                                      </div>
                                      <div>kcal</div>
                                    </div>
                                    <div className={styles.itemSecondary}>
                                      <div className="text-12">
                                        {(ingridient.piece !== 'g' &&
                                          ingridient.piece !== 'ml' &&
                                          Math.round(
                                            ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) /
                                              100) *
                                              parseFloat(ingridient.carbohydrates_100g)
                                          )) ||
                                          Math.round(
                                            (parseFloat(ingridient.amount) / 100) *
                                              parseFloat(ingridient.carbohydrates_100g)
                                          )}
                                      </div>
                                      <div>KH</div>
                                    </div>
                                    <div className={styles.itemSecondary}>
                                      <div className="text-12">
                                        {(ingridient.piece !== 'g' &&
                                          ingridient.piece !== 'ml' &&
                                          Math.round(
                                            ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) /
                                              100) *
                                              parseFloat(ingridient.protein_100g)
                                          )) ||
                                          Math.round(
                                            (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.protein_100g)
                                          )}
                                      </div>
                                      <div>EW</div>
                                    </div>
                                    <div className={styles.itemSecondary}>
                                      <div className="text-12">
                                        {(ingridient.piece !== 'g' &&
                                          ingridient.piece !== 'ml' &&
                                          Math.round(
                                            ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) /
                                              100) *
                                              parseFloat(ingridient.fat_100g)
                                          )) ||
                                          Math.round(
                                            (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.fat_100g)
                                          )}
                                      </div>
                                      <div>Fett</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pl-5 pt-15">
                                {ingridient.serving_data && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      addIngridientDirectAPI(
                                        ingridient.food_id,
                                        ingridient.amount,
                                        (ingridient.piece !== 'g' &&
                                          ingridient.piece !== 'ml' &&
                                          Math.round(
                                            ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) /
                                              100) *
                                              parseFloat(ingridient.kcal_100g)
                                          )) ||
                                          Math.round(
                                            (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.kcal_100g)
                                          ),
                                        (ingridient.piece !== 'g' &&
                                          ingridient.piece !== 'ml' &&
                                          Math.round(
                                            ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) /
                                              100) *
                                              parseFloat(ingridient.carbohydrates_100g)
                                          )) ||
                                          Math.round(
                                            (parseFloat(ingridient.amount) / 100) *
                                              parseFloat(ingridient.carbohydrates_100g)
                                          ),
                                        (ingridient.piece !== 'g' &&
                                          ingridient.piece !== 'ml' &&
                                          Math.round(
                                            ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) /
                                              100) *
                                              parseFloat(ingridient.protein_100g)
                                          )) ||
                                          Math.round(
                                            (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.protein_100g)
                                          ),
                                        (ingridient.piece !== 'g' &&
                                          ingridient.piece !== 'ml' &&
                                          Math.round(
                                            ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) /
                                              100) *
                                              parseFloat(ingridient.fat_100g)
                                          )) ||
                                          Math.round(
                                            (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.fat_100g)
                                          )
                                      )
                                    }
                                  >
                                    <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
                                  </button>
                                )}
                                {!ingridient.serving_data && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      addIngridientDirectInternal(
                                        ingridient.piece,
                                        parseFloat(ingridient.amount),
                                        ingridient.preselect_g,
                                        ingridient.kcal_100g,
                                        ingridient.carbohydrates_100g,
                                        ingridient.protein_100g,
                                        ingridient.fat_100g,
                                        ingridient.name,
                                        ingridient.category,
                                        ingridient.imageUrl,
                                        ingridient.preselect_type !== undefined
                                          ? ingridient.preselect_type
                                          : ingridient.piece,
                                        ingridient.default_piece !== undefined ? ingridient.default_piece : 'g'
                                      )
                                    }
                                  >
                                    <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}

                    {currentQuickListSection === 'favorites' && currentSearchQuery.length === 0 && !isLoading && (
                      <div className={styles.ingridientPopupContent} ref={quickListContainerRef}>
                        {favoritesIngredientsList?.map((ingridient, index) => (
                          <div className="ml-20 flex" key={index}>
                            <div
                              className="pt-15"
                              onClick={() => clickQuickListCheckbox(index, ingridient)}
                              aria-hidden="true"
                            >
                              {checkBoxQuickList.findIndex((item: any) => item.id === index) !== -1 ? (
                                <CheckCircleIcon width={25} height={25} className="h-20 w-20 mr-10 text-accentColor" />
                              ) : (
                                <div className="rounded-full w-20 h-20 border-2 border-accentColor mr-10">
                                  <></>
                                </div>
                              )}
                            </div>
                            <div
                              className={styles.contentItemQuick}
                              onClick={() =>
                                ingridient.serving_data
                                  ? getAPIIngridientData(ingridient.food_id, ingridient.amount)
                                  : handleEditIngridientPopup(
                                      ingridient.id,
                                      ingridient.name,
                                      ingridient.amount,
                                      ingridient.piece,
                                      ingridient.imageUrl,
                                      ingridient.preselect_g,
                                      ingridient.preselect_type,
                                      ingridient.kcal_100g,
                                      ingridient.carbohydrates_100g,
                                      ingridient.protein_100g,
                                      ingridient.fat_100g,
                                      ingridient.default_piece,
                                      'notAdded',
                                      ingridient.category
                                    )
                              }
                              onKeyDown={() =>
                                ingridient.serving_data
                                  ? getAPIIngridientData(ingridient.food_id, ingridient.amount)
                                  : handleEditIngridientPopup(
                                      ingridient.id,
                                      ingridient.name,
                                      ingridient.amount,
                                      ingridient.piece,
                                      ingridient.imageUrl,
                                      ingridient.preselect_g,
                                      ingridient.preselect_type,
                                      ingridient.kcal_100g,
                                      ingridient.carbohydrates_100g,
                                      ingridient.protein_100g,
                                      ingridient.fat_100g,
                                      ingridient.default_piece,
                                      'notAdded',
                                      ingridient.category
                                    )
                              }
                              aria-hidden="true"
                            >
                              <div className={styles.description}>
                                <div className="pr-5">
                                  <div className="text-14 truncate w-200" title={ingridient.name}>
                                    {ingridient.name}{' '}
                                  </div>
                                  <div className="text-10 font-light">
                                    {ingridient.amount}
                                    {ingridient.piece.match(/^\d/) && 'x'} {ingridient.piece}{' '}
                                    {ingridient.piece !== 'g' &&
                                      ingridient.piece !== 'ml' &&
                                      `(${ingridient.amount * ingridient.preselect_g}`}
                                    {ingridient.serving_unit &&
                                      ingridient.piece !== 'g' &&
                                      ingridient.piece !== 'ml' &&
                                      `${ingridient.serving_unit})`}
                                    {!ingridient.serving_data &&
                                      ingridient.piece !== 'g' &&
                                      ingridient.piece !== 'ml' &&
                                      ingridient.default_piece === undefined &&
                                      'g)'}
                                    {!ingridient.serving_data &&
                                      ingridient.piece !== 'g' &&
                                      ingridient.piece !== 'ml' &&
                                      ingridient.default_piece !== undefined &&
                                      `${ingridient.default_piece})`}
                                    {ingridient.serving_data &&
                                      !ingridient.serving_unit &&
                                      ingridient.piece !== 'g' &&
                                      ingridient.piece !== 'ml' &&
                                      'g)'}
                                  </div>
                                </div>
                                <div className={styles.category}>
                                  <div className={styles.item}>
                                    <div className="text-12">
                                      {(ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        Math.round(
                                          ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) / 100) *
                                            parseFloat(ingridient.kcal_100g)
                                        )) ||
                                        Math.round(
                                          (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.kcal_100g)
                                        )}
                                    </div>
                                    <div>kcal</div>
                                  </div>
                                  <div className={styles.itemSecondary}>
                                    <div className="text-12">
                                      {(ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        Math.round(
                                          ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) / 100) *
                                            parseFloat(ingridient.carbohydrates_100g)
                                        )) ||
                                        Math.round(
                                          (parseFloat(ingridient.amount) / 100) *
                                            parseFloat(ingridient.carbohydrates_100g)
                                        )}
                                    </div>
                                    <div>KH</div>
                                  </div>
                                  <div className={styles.itemSecondary}>
                                    <div className="text-12">
                                      {(ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        Math.round(
                                          ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) / 100) *
                                            parseFloat(ingridient.protein_100g)
                                        )) ||
                                        Math.round(
                                          (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.protein_100g)
                                        )}
                                    </div>
                                    <div>EW</div>
                                  </div>
                                  <div className={styles.itemSecondary}>
                                    <div className="text-12">
                                      {(ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        Math.round(
                                          ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) / 100) *
                                            parseFloat(ingridient.fat_100g)
                                        )) ||
                                        Math.round(
                                          (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.fat_100g)
                                        )}
                                    </div>
                                    <div>Fett</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="pl-5 pt-15">
                              {ingridient.serving_data && (
                                <button
                                  type="button"
                                  onClick={() =>
                                    addIngridientDirectAPI(
                                      ingridient.food_id,
                                      ingridient.amount,
                                      (ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        Math.round(
                                          ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) / 100) *
                                            parseFloat(ingridient.kcal_100g)
                                        )) ||
                                        Math.round(
                                          (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.kcal_100g)
                                        ),
                                      (ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        Math.round(
                                          ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) / 100) *
                                            parseFloat(ingridient.carbohydrates_100g)
                                        )) ||
                                        Math.round(
                                          (parseFloat(ingridient.amount) / 100) *
                                            parseFloat(ingridient.carbohydrates_100g)
                                        ),
                                      (ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        Math.round(
                                          ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) / 100) *
                                            parseFloat(ingridient.protein_100g)
                                        )) ||
                                        Math.round(
                                          (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.protein_100g)
                                        ),
                                      (ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        Math.round(
                                          ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) / 100) *
                                            parseFloat(ingridient.fat_100g)
                                        )) ||
                                        Math.round(
                                          (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.fat_100g)
                                        )
                                    )
                                  }
                                >
                                  <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
                                </button>
                              )}
                              {!ingridient.serving_data && (
                                <button
                                  type="button"
                                  onClick={() =>
                                    addIngridientDirectInternal(
                                      ingridient.piece,
                                      parseFloat(ingridient.amount),
                                      ingridient.preselect_g,
                                      ingridient.kcal_100g,
                                      ingridient.carbohydrates_100g,
                                      ingridient.protein_100g,
                                      ingridient.fat_100g,
                                      ingridient.name,
                                      ingridient.category,
                                      ingridient.imageUrl,
                                      ingridient.preselect_type !== undefined
                                        ? ingridient.preselect_type
                                        : ingridient.piece,
                                      ingridient.default_piece !== undefined ? ingridient.default_piece : 'g'
                                    )
                                  }
                                >
                                  <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                        {favoritesIngredientsList?.length === 0 && (
                          <div className="pl-20 font-extralight">
                            Wenn du ein Lebensmittel anklickst, kannst du dieses durch klicken auf das "Herz-Icon" zu
                            deinen Favoriten hinzufügen und dann hier direkt verwenden!
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
                <div className="pt-0 pl-20 pb-80 md:pb-40">
                  {currentIngridientSearchAutocomplete.suggestions !== null &&
                    Array.isArray(currentIngridientSearchAutocomplete.suggestions.suggestion) &&
                    currentSection !== 'recipes' &&
                    !currentIngridientSearch &&
                    currentSearchQuery.length > 0 &&
                    currentIngridientSearchAutocomplete.suggestions.suggestion.map((plans, index) => (
                      <div
                        key={index}
                        onClick={() => ingridientApiSearch(plans)}
                        onKeyDown={() => ingridientApiSearch(plans)}
                        aria-hidden="true"
                      >
                        {plans.length > 1 && (
                          <div className="flex border-b py-7 border-blackSemiDark hover:bg-lightGray hover:bg-opacity-20 cursor-pointer mr-30">
                            <div className="pl-10 pr-15 my-auto">
                              <SearchIcon width={15} height={15} className="text-accentColor" />
                            </div>
                            <div key={index}>
                              <button type="button">{plans}</button>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}

                  {currentIngridientSearchAutocomplete.suggestions !== null &&
                    !Array.isArray(currentIngridientSearchAutocomplete.suggestions.suggestion) &&
                    currentSearchQuery.length > 0 &&
                    !currentIngridientSearch &&
                    currentSection !== 'recipes' && (
                      <div className="flex border-b py-7 border-blackSemiDark hover:bg-lightGray hover:bg-opacity-20 cursor-pointer mr-30">
                        <div className="pl-10 pr-15 my-auto">
                          <SearchIcon width={15} height={15} className="text-accentColor" />
                        </div>
                        <div>
                          <button
                            type="button"
                            onClick={() =>
                              ingridientApiSearch(currentIngridientSearchAutocomplete.suggestions.suggestion.toString())
                            }
                          >
                            {currentIngridientSearchAutocomplete.suggestions.suggestion}
                          </button>
                        </div>
                      </div>
                    )}
                  <div className={((currentSearchQuery.length > 0 || popupType === 'plan') && 'hidden') || 'visible'}>
                    {directAddedIngridients.length > 0 && (
                      <div className="pb-10 font-light text-base">Aktuell hinzugefügt:</div>
                    )}
                    {directAddedIngridients.length > 0 &&
                      directAddedIngridients.map((ingridient, index) => {
                        return (
                          <>
                            <div className="pr-20 flex" key={index}>
                              <div
                                className={styles.contentItem}
                                onClick={() =>
                                  ingridient.serving_data
                                    ? handleEditIngridientPopupAPI(ingridient)
                                    : handleEditIngridientPopup(
                                        ingridient.id,
                                        ingridient.name,
                                        ingridient.amount,
                                        ingridient.piece,
                                        ingridient.imageUrl,
                                        ingridient.preselect_g,
                                        ingridient.preselect_type,
                                        ingridient.kcal_100g,
                                        ingridient.carbohydrates_100g,
                                        ingridient.protein_100g,
                                        ingridient.fat_100g,
                                        ingridient.default_piece,
                                        'edit',
                                        ingridient.category
                                      )
                                }
                                onKeyDown={() =>
                                  ingridient.serving_data
                                    ? handleEditIngridientPopupAPI(ingridient)
                                    : handleEditIngridientPopup(
                                        ingridient.id,
                                        ingridient.name,
                                        ingridient.amount,
                                        ingridient.piece,
                                        ingridient.imageUrl,
                                        ingridient.preselect_g,
                                        ingridient.preselect_type,
                                        ingridient.kcal_100g,
                                        ingridient.carbohydrates_100g,
                                        ingridient.protein_100g,
                                        ingridient.fat_100g,
                                        ingridient.default_piece,
                                        'edit',
                                        ingridient.category
                                      )
                                }
                                aria-hidden="true"
                              >
                                <div className={styles.imageWrapper}>
                                  <img
                                    src={
                                      ingridient.imageUrl
                                        ? ingridient.imageUrl
                                        : theme?.logo !== undefined && userData?.role !== 2
                                        ? theme.logo
                                        : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fdefault_ingridient.png?alt=media&token=decc7814-19a3-41c9-9949-a965bbfa0ee2'
                                    }
                                    alt=""
                                    width={45}
                                    height={45}
                                    loading="lazy"
                                    className={styles.image}
                                  />
                                </div>
                                <div className={styles.description}>
                                  <div className="pr-5">
                                    <div className="text-14 truncate w-100" title={ingridient.name}>
                                      {ingridient.name}{' '}
                                    </div>
                                    <div className="text-10 font-light">
                                      {ingridient.amount}
                                      {ingridient.piece.match(/^\d/) && 'x'} {ingridient.piece}{' '}
                                      {ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        `(${ingridient.amount * ingridient.preselect_g}`}
                                      {ingridient.serving_unit &&
                                        ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        `${ingridient.serving_unit})`}
                                      {!ingridient.serving_data &&
                                        ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        ingridient.default_piece === undefined &&
                                        'g)'}
                                      {!ingridient.serving_data &&
                                        ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        ingridient.default_piece !== undefined &&
                                        `${ingridient.default_piece})`}
                                      {ingridient.serving_data &&
                                        !ingridient.serving_unit &&
                                        ingridient.piece !== 'g' &&
                                        ingridient.piece !== 'ml' &&
                                        'g)'}
                                    </div>
                                  </div>
                                  <div className={styles.category}>
                                    <div className={styles.item}>
                                      <div className="text-12">
                                        {(ingridient.piece !== 'g' &&
                                          ingridient.piece !== 'ml' &&
                                          Math.round(
                                            ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) /
                                              100) *
                                              parseFloat(ingridient.kcal_100g)
                                          )) ||
                                          Math.round(
                                            (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.kcal_100g)
                                          )}
                                      </div>
                                      <div>kcal</div>
                                    </div>
                                    <div className={styles.itemSecondary}>
                                      <div className="text-12">
                                        {(ingridient.piece !== 'g' &&
                                          ingridient.piece !== 'ml' &&
                                          Math.round(
                                            ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) /
                                              100) *
                                              parseFloat(ingridient.carbohydrates_100g)
                                          )) ||
                                          Math.round(
                                            (parseFloat(ingridient.amount) / 100) *
                                              parseFloat(ingridient.carbohydrates_100g)
                                          )}
                                      </div>
                                      <div>KH</div>
                                    </div>
                                    <div className={styles.itemSecondary}>
                                      <div className="text-12">
                                        {(ingridient.piece !== 'g' &&
                                          ingridient.piece !== 'ml' &&
                                          Math.round(
                                            ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) /
                                              100) *
                                              parseFloat(ingridient.protein_100g)
                                          )) ||
                                          Math.round(
                                            (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.protein_100g)
                                          )}
                                      </div>
                                      <div>EW</div>
                                    </div>
                                    <div className={styles.itemSecondary}>
                                      <div className="text-12">
                                        {(ingridient.piece !== 'g' &&
                                          ingridient.piece !== 'ml' &&
                                          Math.round(
                                            ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) /
                                              100) *
                                              parseFloat(ingridient.fat_100g)
                                          )) ||
                                          Math.round(
                                            (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.fat_100g)
                                          )}
                                      </div>
                                      <div>Fett</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pl-5 pt-15">
                                {ingridient.serving_data && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      deleteDirectAddedIngridientAPI(
                                        ingridient.id,
                                        ingridient.kcal_total,
                                        ingridient.carbohydrates_total,
                                        ingridient.protein_total,
                                        ingridient.fat_total
                                      )
                                    }
                                  >
                                    <TrashIcon width={25} height={25} className="text-accentColor mx-auto" />
                                  </button>
                                )}
                                {!ingridient.serving_data && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      deleteDirectAddedIngridient(
                                        ingridient.id,
                                        ingridient.amount,
                                        ingridient.piece,
                                        ingridient.preselect_g,
                                        ingridient.kcal_100g,
                                        ingridient.carbohydrates_100g,
                                        ingridient.protein_100g,
                                        ingridient.fat_100g
                                      )
                                    }
                                  >
                                    <TrashIcon width={25} height={25} className="text-accentColor mx-auto" />
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    {popupType === 'plan' && (
                      <div className={(currentSearchQuery.length > 0 && 'hidden') || 'visible'}>
                        <div className="pt-20 pr-20">
                          <Button className="w-full" onClick={() => setOpenerClass('hidden')}>
                            {t('Finish')}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={styles.ingridientPopupContent} id="recipeScrollContainer">
                    {currentSection === 'recipes' ? (
                      <>
                        {loadedRecipes.length > 0 && (
                          <InfiniteScroll
                            dataLength={loadedRecipes.length} // This is important field to render the next data
                            next={() => loadRecipes()}
                            scrollableTarget={document.getElementById('recipeScrollContainer')}
                            hasMore={lastObjectPosition < recipesFilteredList.length}
                            loader={<h4>Loading...</h4>}
                          >
                            {loadedRecipes.map((recipe: RecipesType, index: number) => (
                              <div key={index}>
                                <IngredientSearchPopupItem
                                  itemObject={recipe}
                                  updateDayId={updateDayId}
                                  mealType={mealType}
                                  planState={updateRecipeState}
                                  planStateValue={recipeStateValue}
                                  popupType={popupType}
                                  recipeChangeFunction={recipeChangeFunction}
                                  updateDayString={updateDayString}
                                  popupCloseFunction={setOpenerClass}
                                  isDashBoard={isDashBoard}
                                  setVisibleIncompabilityPopup={setVisibleIncompabilityPopup}
                                  recipeId={index}
                                  searchQuery={currentSearchQuery}
                                />
                              </div>
                            ))}
                          </InfiniteScroll>
                        )}
                      </>
                    ) : (
                      <>
                        {currentIngridientSearch &&
                          ingredientFilteredList?.length === 0 &&
                          currentIngridientSearchApi.foods.food === undefined && (
                            <div className="font-extralight px-20">
                              <p>Es wurden leider keine Lebensmittel zu deinem Suchbegriff gefunden.</p>
                              <p className="pt-10">Bitte versuche es mit einem anderen Suchbegriff.</p>
                            </div>
                          )}
                        {isLoading ? (
                          <div className="flex justify-center items-center pt-20">
                            <ReactLoading
                              type="bars"
                              width={20}
                              height={20}
                              color={theme?.textColor ?? defaultTextColor}
                            />
                          </div>
                        ) : (
                          <>
                            {currentIngridientSearch &&
                              ingredientFilteredList &&
                              currentSection !== 'recipes' &&
                              ingredientFilteredList?.length > 0 &&
                              ingredientFilteredList?.map((item, index) => (
                                <div className="flex" key={index}>
                                  <div
                                    className={styles.contentItem}
                                    onClick={() =>
                                      handleEditIngridientPopup(
                                        Math.random().toString(),
                                        item.name,
                                        item.preselect_amount,
                                        item.preselect_type,
                                        item.imageUrl,
                                        item.preselect_g,
                                        item.preselect_type,
                                        item.kcal_100g,
                                        item.carbohydrates_100g,
                                        item.protein_100g,
                                        item.fat_100g,
                                        item.default_piece,
                                        'notAdded',
                                        item.category
                                      )
                                    }
                                    onKeyDown={() =>
                                      handleEditIngridientPopup(
                                        Math.random().toString(),
                                        item.name,
                                        item.preselect_amount,
                                        item.preselect_type,
                                        item.imageUrl,
                                        item.preselect_g,
                                        item.preselect_type,
                                        item.kcal_100g,
                                        item.carbohydrates_100g,
                                        item.protein_100g,
                                        item.fat_100g,
                                        item.default_piece,
                                        'notAdded',
                                        item.category
                                      )
                                    }
                                    aria-hidden="true"
                                  >
                                    {(item.userIngridient === undefined || item.userIngridient === false) &&
                                    item.communityIngridient === undefined ? (
                                      <div className="absolute right-15 top-5 flex gap-5">
                                        <div>
                                          <BadgeCheckIcon width={15} height={15} className="text-calories" />
                                        </div>
                                        {userData?.role === 2 ? (
                                          <div className="font-light text-10">Verified by Tortija</div>
                                        ) : (
                                          <div className="font-light text-10">Verified</div>
                                        )}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    {(item.userIngridient !== undefined || item.userIngridient === true) &&
                                    item.communityIngridient === undefined ? (
                                      <div className="absolute right-15 top-5 flex gap-5">
                                        <div>
                                          <BadgeCheckIcon width={15} height={15} className="text-calories" />
                                        </div>
                                        <div className="font-light text-10">Eigenes Lebensmittel</div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    {item.communityIngridient !== undefined && item.communityIngridient === true ? (
                                      <div className="absolute right-15 top-5 flex gap-5">
                                        <div>
                                          <BadgeCheckIcon width={15} height={15} className="text-calories" />
                                        </div>
                                        <div className="font-light text-10">Community Lebensmittel</div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    <div className={styles.imageWrapper}>
                                      <img
                                        src={
                                          item.imageUrl
                                            ? item.imageUrl
                                            : theme?.logo !== undefined && userData?.role !== 2
                                            ? theme.logo
                                            : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fdefault_ingridient.png?alt=media&token=decc7814-19a3-41c9-9949-a965bbfa0ee2'
                                        }
                                        alt=""
                                        width={45}
                                        height={45}
                                        loading="lazy"
                                        className={styles.image}
                                      />
                                    </div>
                                    <div className={styles.description}>
                                      <div className="pr-5">
                                        <div className={styles.itemTitle} title={item.name}>
                                          {item.name}
                                        </div>

                                        <div className="text-10 font-light">
                                          {item.preselect_amount} {item.preselect_type}{' '}
                                          {item.preselect_type !== 'g' &&
                                            item.preselect_type !== 'ml' &&
                                            item.default_piece === undefined &&
                                            `(${parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)}g)`}
                                          {item.preselect_type !== 'g' &&
                                            item.preselect_type !== 'ml' &&
                                            item.default_piece !== undefined &&
                                            `(${parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)}${
                                              item.default_piece
                                            })`}
                                        </div>
                                      </div>
                                      <div className={styles.category}>
                                        <div className={styles.item}>
                                          <div className="text-12">
                                            {Math.round(
                                              ((parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)) /
                                                100) *
                                                parseFloat(item.kcal_100g)
                                            )}
                                          </div>
                                          <div>kcal</div>
                                        </div>
                                        <div className={styles.itemSecondary}>
                                          <div className="text-12">
                                            {Math.round(
                                              ((parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)) /
                                                100) *
                                                parseFloat(item.carbohydrates_100g)
                                            )}
                                          </div>
                                          <div>KH</div>
                                        </div>
                                        <div className={styles.itemSecondary}>
                                          <div className="text-12">
                                            {Math.round(
                                              ((parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)) /
                                                100) *
                                                parseFloat(item.protein_100g)
                                            )}
                                          </div>
                                          <div>EW</div>
                                        </div>
                                        <div className={styles.itemSecondary}>
                                          <div className="text-12">
                                            {Math.round(
                                              ((parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)) /
                                                100) *
                                                parseFloat(item.fat_100g)
                                            )}
                                          </div>
                                          <div>Fett</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pl-5 my-auto">
                                    <PlusIcon
                                      width={25}
                                      height={25}
                                      className="text-accentColor cursor-pointer"
                                      onClick={() =>
                                        addIngridientDirectInternal(
                                          item.preselect_type,
                                          parseFloat(item.preselect_amount),
                                          item.preselect_g,
                                          item.kcal_100g,
                                          item.carbohydrates_100g,
                                          item.protein_100g,
                                          item.fat_100g,
                                          item.name,
                                          item.category,
                                          item.imageUrl,
                                          item.preselect_type,
                                          item.default_piece !== undefined ? item.default_piece : 'g'
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                            {currentIngridientSearch &&
                              currentSection !== 'recipes' &&
                              currentIngridientSearchApi.foods?.food?.length > 0 &&
                              currentIngridientSearchApi.foods.food.map((plans: any) => {
                                return (
                                  <>
                                    <div className="flex">
                                      <div
                                        className={styles.contentItem}
                                        onClick={() => getAPIIngridientData(plans.food_id)}
                                        onKeyDown={() => getAPIIngridientData(plans.food_id)}
                                        aria-hidden="true"
                                      >
                                        <div className={styles.imageWrapper}>
                                          <img
                                            src="https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fdefault_ingridient.png?alt=media&token=decc7814-19a3-41c9-9949-a965bbfa0ee2"
                                            alt=""
                                            width={45}
                                            height={45}
                                            loading="lazy"
                                            className={styles.image}
                                          />
                                        </div>
                                        <div className={styles.description}>
                                          <div className="pr-5">
                                            <div className={styles.itemTitle} title={plans.food_name}>
                                              {plans.food_name}{' '}
                                              {plans.brand_name !== undefined && `(${plans.brand_name})`}
                                            </div>
                                            <div className="text-10 font-light">
                                              {plans.food_description.substr(
                                                4,
                                                plans.food_description.indexOf('-') - 4
                                              )}
                                            </div>
                                          </div>
                                          <div className={styles.category}>
                                            <div className={styles.item}>
                                              <div className="text-12">
                                                {plans.food_description.match('Kalorien: (.*)kcal')[1]}
                                              </div>
                                              <div>kcal</div>
                                            </div>
                                            <div className={styles.itemSecondary}>
                                              <div className="text-12">
                                                {Math.round(
                                                  parseFloat(plans.food_description.match('Kohlh: (.*)g')[1])
                                                )}
                                              </div>
                                              <div>KH</div>
                                            </div>
                                            <div className={styles.itemSecondary}>
                                              <div className="text-12">
                                                {Math.round(parseFloat(plans.food_description.match('Eiw: (.*)g')[1]))}
                                              </div>
                                              <div>EW</div>
                                            </div>
                                            <div className={styles.itemSecondary}>
                                              <div className="text-12">
                                                {Math.round(parseFloat(plans.food_description.match('Fett: (.*)g')[1]))}
                                              </div>
                                              <div>Fett</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="pl-5 my-auto">
                                        <PlusIcon
                                          width={25}
                                          height={25}
                                          className="text-accentColor cursor-pointer"
                                          onClick={() => addIngridientDirectAPI(plans.food_id)}
                                        />
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </TransitionContainer>
        </div>
      </div>
      <FilterDrawer
        isFilterOpen={isFilterOpen}
        closeFilterOpen={setFilterOpen}
        filterType={filterType}
        changeFilterType={setFilterType}
        recipesOption={recipesFilterOption}
        clearFilter={isClearFilter}
        planStateDayValue={currentDayItem}
        planStateValue={recipeStateValue}
      />
    </>
  );
};

export default IngredientSearchPopup;

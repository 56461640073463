import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import firebase from 'services/firebase';
import Button from 'components/Button';
import Input from 'components/Input';
import { emailChecker } from 'services/validations';

import { toast } from 'react-toast';
import Headline from 'components/Headline';
import styles from './styles.module.scss';

const ResetPassword: React.FC = () => {
  const userDomain = window.location.hostname;
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isSending, setIsSending] = useState(false);
  const [isCustomDomain, setIsCustomDomain] = useState(false);
  const [tenantUid, setTenantUid] = useState('');
  const [customData, setCustomData] = useState<any>(null);

  useEffect(() => {
    // search for customDomain inside tenants which is equal to userDomain
    firebase
      .firestore()
      .collection('customDomain-infos')
      .where('customDomain', '==', userDomain)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          const tenantInfo = querySnapshot.docs[0].data();
          const thisTenantUid = querySnapshot.docs[0].id;
          setIsCustomDomain(true);
          setTenantUid(thisTenantUid);
          setCustomData(tenantInfo);
        }
      });
  }, []);

  const onSubmit = (data: any) => {
    setIsSending(true);

    try {
      if (isCustomDomain === false) {
        axios({
          url: `${process.env.REACT_APP_API_URL}/sendForgotPasswordMailExecute`,
          method: 'post',
          data: {
            email: data.email,
          },
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        }).then(
          response => {
            if (response.data === 'Success') {
              toast.success(t('Wir haben dir eine Email geschickt!'));
            } else {
              toast.error('Es ist leider etwas schief gelaufen!');
            }

            setIsSending(false);
          },
          error => {
            toast.error('Es ist leider etwas schief gelaufen!');
            console.log(error);
            setIsSending(false);
          }
        );
      } else {
        axios({
          url: `${process.env.REACT_APP_API_URL}/sendForgotPasswordMailB2BExecute`,
          method: 'post',
          data: {
            email: data.email,
            tenantName: customData.company.companyName ?? '',
            tenantUid,
            companyStreet: customData.company.street ?? '',
            companyStreetNumber: customData.company.streetNumber ?? '',
            companyPostalCode: customData.company.zip ?? '',
            companyCity: customData.company.city ?? '',
            companyEmail:
              customData?.settings?.invitationMail?.mailFrom !== undefined &&
              customData?.settings?.invitationMail?.mailFrom?.length > 0
                ? customData?.settings?.invitationMail?.mailFrom
                : customData.company.email ?? '',
            companyPhone: customData.company.phone ?? '',
            mailFrom:
              customData?.settings?.invitationMail?.mailFrom !== undefined &&
              customData?.settings?.invitationMail?.mailFrom?.length > 0
                ? customData?.settings?.invitationMail?.mailFrom
                : 'info@tortija.de',
            tenantTitleName:
              customData?.settings?.invitationMail?.mailName !== undefined &&
              customData?.settings?.invitationMail?.mailName?.length > 0
                ? customData?.settings?.invitationMail?.mailName
                : customData.company.companyName ?? '',
            bgColor: customData?.theme?.accentColor ?? '#C97132',
            textColor: customData?.theme?.buttonTextColor ?? '#FFFFFF',
          },
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        }).then(
          response => {
            if (response.data === 'Success') {
              toast.success(t('Wir haben dir eine Email geschickt!'));
            } else {
              toast.error('Es ist leider etwas schief gelaufen!');
            }

            setIsSending(false);
          },
          error => {
            toast.error('Es ist leider etwas schief gelaufen!');
            console.log(error);
            setIsSending(false);
          }
        );
      }
    } catch (error) {
      toast.error('Es ist leider etwas schief gelaufen!');
    }
  };

  return (
    <div className={styles.wrapper}>
      <Headline level={1} className="text-center">
        {t('Reset password')}
      </Headline>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="email"
          type="email"
          label={t('Email')}
          autoComplete="email"
          required
          register={register('email', {
            validate: (value: string) => {
              if (!value.trim()) {
                return t('Email Address is required').toString();
              }
              return emailChecker(value) || t('Please enter valid email address').toString();
            },
          })}
          error={errors.email}
        />
        <div className={styles.comment}>
          <span>{t('Back to')}</span>
          <Link to="/auth/login" className={styles.link}>
            {t('Sign in')}
          </Link>
        </div>

        <Button disabled={isSending} isPending={isSending} className={styles.button}>
          <span className={styles.text}>{t('Send me a reset link')}</span>
        </Button>
      </form>
    </div>
  );
};

export default ResetPassword;

import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import firebase from 'firebase';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import moment from 'moment';
import { toast } from 'react-toast';
import Icon from 'components/Icon';
import { getIsWebView } from 'shared/functions/global';
import { AuthContext } from 'providers/AuthProvider';
import Container from 'components/Container';

import styles from './styles.module.scss';

const PurchaseStatus = ({ clientSecret }: { clientSecret: string | null }) => {
  const db = firebase.firestore();
  const elements = useElements();
  const history = useHistory();
  const stripe = useStripe();
  const { userData, user, tenant } = useContext(AuthContext);
  const [status, setStatus] = useState('');
  const [subscriptionId, setSubscribtionId] = useState('');
  const [paymentIntendId, setPaymentIntendId] = useState('');

  const type = new URLSearchParams(window.location.search).get('type');
  const thisCustomCouponCode = new URLSearchParams(window.location.search).get('customCoupon') || '';
  const thisCustomPlanId = new URLSearchParams(window.location.search).get('customPlanId') || '';
  const thisCustomPrice = new URLSearchParams(window.location.search).get('customPrice') || '';

  const useIAP = getIsWebView();

  const handleOnSuccess = async () => {
    if (!stripe || !elements) {
      return;
    }

    if (thisCustomPlanId === undefined || thisCustomCouponCode === undefined || thisCustomPrice === undefined) {
      toast.error('Es ist leider etwas schief gelaufen. Melde dich bitte bei uns!');
      return;
    }

    try {
      await db
        .collection(`tenants/${tenant}/users`)
        .doc(user?.uid)
        .update({
          membership: {
            id: subscriptionId,
            activated: true,
            payerId: paymentIntendId,
            nextDate: moment().unix(),
            paymentMethod: 'stripe',
            rabattCode: thisCustomCouponCode.length > 0 ? thisCustomCouponCode : '',
            type,
          },
        });
      if (
        thisCustomCouponCode.length > 0 &&
        thisCustomCouponCode !== 'extended' &&
        thisCustomCouponCode !== 'T50TJ' &&
        thisCustomCouponCode !== 'hazel' &&
        thisCustomCouponCode !== 'TT30J' &&
        thisCustomCouponCode !== '40TTJ' &&
        thisCustomCouponCode !== 'TTJ20'
      ) {
        try {
          await db.collection('coupons').doc(thisCustomCouponCode).update({
            coupon: thisCustomCouponCode,
            used: true,
          });
        } catch (error) {
          console.log(error);
        }
      }
      // Send payment mail to us
      try {
        await axios({
          url: `${process.env.REACT_APP_API_URL}/sendPaymentMail`,
          method: 'post',
          data: {
            paymentType: `Stripe - ${type}`,
          },
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        }).then(
          response => {
            console.log(response);
          },
          error => {
            console.log(error);
          }
        );
        await axios({
          url: `${process.env.REACT_APP_API_URL}/sendPaymentMailToUser`,
          method: 'post',
          data: {
            email: userData?.email,
          },
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        }).then(
          response => {
            console.log(response);
          },
          error => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }

      // Track Social Media Pixel
      if (
        (userData?.trackGoogleAnalytics === true || userData?.withCouponCode) &&
        window.location.hostname === 'app.tortija.de'
      ) {
        ReactPixel.track('Purchase', {
          value: thisCustomPrice,
          currency: 'EUR',
        });

        TiktokPixel.track('CompletePayment', {
          content_type: 'product',
          quantity: 1,
          content_id: '1',
          currency: 'EUR',
          description: 'Abo Abschluss',
          value: thisCustomPrice,
        });
      }

      if (useIAP) {
        history.push('/');
      } else {
        history.push('/store-landingpage');
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    if (status === 'succeeded' && subscriptionId && paymentIntendId) {
      toast.success('Deine Zahlung wurde erfolgreich durchgeführt. Vielen Dank!');
      handleOnSuccess();
    }
    if (status === 'processing' && subscriptionId && paymentIntendId) {
      toast.success('Deine Zahlung wurde erfolgreich durchgeführt. Vielen Dank!');
      handleOnSuccess();
    }
    if (status === 'requires_payment_method') {
      toast.error('Deine Zahlung ist fehlgeschlagen. Bitte versuche es mit einer anderen Zahlungsmethode.');
      history.push(`/purchase/payment/${type}`);
    }
  }, [status, subscriptionId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, 15000);
    return () => clearTimeout(timer);
  }, []);

  if (stripe) {
    stripe.retrievePaymentIntent(clientSecret!).then(({ paymentIntent }) => {
      if (paymentIntent?.id !== undefined) {
        setPaymentIntendId(paymentIntent.id);
      }
      setStatus(paymentIntent?.status!);
      setSubscribtionId(userData?.membership?.id!);
    });
  }

  return (
    <Container>
      <div className="fixed h-screen w-screen left-0 top-0 bg-black z-50 flex flex-col items-center justify-center">
        <Icon name="logo" width={200} />
        <div className="font-bold pt-30">
          <div className="text-center">Deine Zahlung wird bearbeitet...</div>
          <div className="pt-10 text-center">Dieser Vorgang kann unter Umständen auch etwas länger dauern.</div>
          <div className="pt-10 text-center">Bitte schließe dieses Fenster nicht. Du wirst gleich weitergeleitet.</div>
        </div>
      </div>
    </Container>
  );
};

export default PurchaseStatus;

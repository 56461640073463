/* eslint-disable react/no-danger */
import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import axios from 'axios';
import moment from 'moment';
import firebase from 'services/firebase';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { defaultTextColor } from 'layouts/Theme';
import { getNextSubscriptionDate } from 'shared/functions/global';
import { BookOpenIcon } from '@heroicons/react/outline';
import LogoImg from 'assets/img/svg/logo.svg';
import ButtonBack from 'components/ButtonBack';
import Overlay from 'components/Overlay';
import Button from 'components/Button';
import styles from './styles.module.scss';

const CustomerBillingCancel: React.FC = () => {
  const { theme, tenantData, userData, tenant, user } = useContext(AuthContext);
  const [pageLoadedAndVerified, setPageLoadedAndVerified] = useState(false);
  const [pageNotValid, setPageNotValid] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [openLegalPopupClass, setOpenLegalPopupClass] = useState('hidden');
  const [legalType, setLegalType] = useState('');
  const logo = theme?.logo ?? LogoImg;

  useEffect(() => {
    if (
      userData?.membership?.stripeCustomerId &&
      tenantData?.stripeConnect?.stripeConnectAccountId &&
      userData?.membership?.id &&
      userData?.membership?.id.length > 0
    ) {
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('success');
      if (myParam === 'true') {
        setIsCanceled(true);
        cancelComplete();
      }

      if (userData?.membership?.type === 'canceled') {
        setIsCanceled(true);
      }

      setPageLoadedAndVerified(true);
    } else {
      setPageNotValid(true);
    }
  }, []);

  const cancelComplete = async () => {
    const getNextDate = getNextSubscriptionDate('month', moment.unix(userData?.created?.seconds).format('YYYY-MM-DD'));

    if (userData?.membership?.type !== 'canceled') {
      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(user?.uid)
        .update({
          membership: {
            id: userData?.membership?.id ?? '',
            activated: false,
            payerId: userData?.membership?.payerId ?? '',
            nextDate: getNextDate,
            paymentMethod: 'stripeConnect',
            stripeCustomerId: userData?.membership?.stripeCustomerId ?? '',
            type: 'canceled',
          },
        });

      await firebase.firestore().collection(`stripeConnectDelete`).doc().set({
        uid: user?.uid,
        nextDate: getNextDate,
        tenant,
      });
    }
  };

  const getRedirectUrl = async () => {
    setIsPending(true);
    const res = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/createStripeConnectCustomerPortalSession`, {
      customerId: userData?.membership?.stripeCustomerId,
      accountId: tenantData?.stripeConnect?.stripeConnectAccountId,
      returnUrl: `${
        tenantData?.customDomain !== undefined ? `https://${tenantData.customDomain}` : 'https://business.tortija.de'
      }/connect-cancel`,
      configurationId: tenantData?.stripeConnect?.stripeConnectCustomerPortalId,
      subscriptionId: userData?.membership?.id,
      completionUrl: `${
        tenantData?.customDomain !== undefined ? `https://${tenantData.customDomain}` : 'https://business.tortija.de'
      }/connect-cancel?success=true`,
    });

    window.location.href = res.data;
  };

  const openLegalInfo = (thisLegalType: string) => {
    setLegalType(thisLegalType);
    setOpenLegalPopupClass('block');
  };

  return (
    <>
      <Overlay
        overlayClass={openLegalPopupClass}
        setOverlayClass={setOpenLegalPopupClass}
        headline={
          legalType === 'imprint'
            ? 'Impressum'
            : legalType === 'dataPrivacy'
            ? 'Datenschutzerklärung'
            : 'Nutzungsbedingungen'
        }
        icon={<BookOpenIcon width={25} height={25} className="text-accentColor mx-auto" />}
        fullSize
      >
        <div className="pb-50">
          <div className="pt-30 font-extralight text-base">
            {tenantData?.legal !== undefined && (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    legalType === 'imprint'
                      ? tenantData?.legal?.imprintText!.replace(/(?:\r\n|\r|\n)/g, '<br />')
                      : legalType === 'dataPrivacy'
                      ? tenantData?.legal?.privacyPolicyText!.replace(/(?:\r\n|\r|\n)/g, '<br />')
                      : tenantData?.legal?.termsOfUseText!.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                }}
              />
            )}
          </div>
        </div>
      </Overlay>

      {pageNotValid ? (
        <div className="h-full w-full md:flex block">
          <div className={styles.loading}>
            <div className="font-bold text-35">Diese Seite ist aktuell nicht verfügbar!</div>
            <div>Bitte logge dich vorher in deinem Account ein und versuche es danach erneut!</div>
          </div>
        </div>
      ) : pageLoadedAndVerified ? (
        <>
          <div className="bg-bgColor mb-100 mx-20 text-textColor m-auto mt-11 flex flex-col items-center">
            <div className="pb-30">
              <Link
                to={{
                  pathname: '/',
                }}
              >
                {' '}
                <ButtonBack
                  text="Zurück zum Dashboard"
                  className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                />
              </Link>
            </div>
            <img width={160} src={logo} alt="Logo" />
            <h1 className="font-bold pt-60 text-35 desktop:w-1/2 desktop:px-20 text-center">Kündigung</h1>
            {isCanceled ? (
              <div className="text-center">
                <div className="pt-10">Du hast dein Abonnement gekündigt.</div>
                <div className="pt-10">
                  Dieses endet am{' '}
                  {userData?.membership?.nextDate !== undefined &&
                    moment.unix(userData?.membership?.nextDate).format('DD.MM.YYYY')}{' '}
                  und dein Account wird dann automatisch gelöscht .
                </div>
              </div>
            ) : (
              <>
                <div className="pt-20">
                  Wenn du dein Abonnement kündigen möchtest, klicke bitte auf folgenden Button:
                </div>
                <Button className="mt-30" isPending={isPending} disabled={isPending} onClick={() => getRedirectUrl()}>
                  Abonnement kündigen
                </Button>
              </>
            )}

            <div className="pt-60 flex flex-wrap gap-15 desktop:space-x-30">
              <div>
                {tenantData?.legal !== undefined ? (
                  <>
                    {tenantData.customDomain !== undefined && tenantData.customDomain === window.location.hostname ? (
                      <>
                        {tenantData?.legal?.imprint && tenantData?.legal?.noWebsite === false ? (
                          <Link
                            to={{
                              pathname: tenantData?.legal?.imprint ?? 'https://whitelabel.tortija.de/impressum/',
                            }}
                            target="_blank"
                            className={styles.link}
                          >
                            Impressum
                          </Link>
                        ) : tenantData?.legal?.imprintText ? (
                          <>
                            <div className="cursor-pointer">
                              <div className={styles.link} onClick={() => openLegalInfo('imprint')} aria-hidden="true">
                                Impressum
                              </div>
                            </div>
                          </>
                        ) : (
                          <Link
                            to={{
                              pathname: tenantData?.legal?.imprint ?? 'https://whitelabel.tortija.de/impressum/',
                            }}
                            target="_blank"
                            className={styles.link}
                          >
                            Impressum
                          </Link>
                        )}
                      </>
                    ) : (
                      <>
                        {tenantData?.legal?.imprint && tenantData?.legal?.noWebsite === false ? (
                          <Link
                            to={{
                              pathname: tenantData?.legal?.imprint ?? 'https://whitelabel.tortija.de/impressum/',
                            }}
                            target="_blank"
                            className={styles.link}
                          >
                            Impressum
                          </Link>
                        ) : tenantData?.legal?.imprintText ? (
                          <>
                            <div className="cursor-pointer">
                              <div className={styles.link} onClick={() => openLegalInfo('imprint')} aria-hidden="true">
                                Impressum
                              </div>
                            </div>
                          </>
                        ) : (
                          <Link
                            to={{
                              pathname: tenantData?.legal?.imprint ?? 'https://whitelabel.tortija.de/impressum/',
                            }}
                            target="_blank"
                            className={styles.link}
                          >
                            Impressum
                          </Link>
                        )}
                      </>
                    )}
                  </>
                ) : window.location.hostname === 'business.tortija.de' ? (
                  <Link
                    to={{ pathname: 'https://whitelabel.tortija.de/impressum/' }}
                    target="_blank"
                    className={styles.link}
                  >
                    Impressum
                  </Link>
                ) : (
                  <Link to={{ pathname: 'https://www.tortija.de/impressum/' }} target="_blank" className={styles.link}>
                    Impressum
                  </Link>
                )}
              </div>
              <div>
                {tenantData?.legal !== undefined ? (
                  <>
                    {tenantData.customDomain !== undefined && tenantData.customDomain === window.location.hostname ? (
                      <>
                        {tenantData?.legal?.privacyPolicy && tenantData?.legal?.noWebsite === false ? (
                          <Link
                            to={{
                              pathname:
                                tenantData?.legal?.privacyPolicy ?? 'https://whitelabel.tortija.de/datenschutz/',
                            }}
                            target="_blank"
                            className={styles.link}
                          >
                            Datenschutz
                          </Link>
                        ) : tenantData?.legal?.privacyPolicyText ? (
                          <>
                            <div className="cursor-pointer">
                              <div
                                className={styles.link}
                                onClick={() => openLegalInfo('dataPrivacy')}
                                aria-hidden="true"
                              >
                                Datenschutz
                              </div>
                            </div>
                          </>
                        ) : (
                          <Link
                            to={{
                              pathname:
                                tenantData?.legal?.privacyPolicy ?? 'https://whitelabel.tortija.de/datenschutz/',
                            }}
                            target="_blank"
                            className={styles.link}
                          >
                            Datenschutz
                          </Link>
                        )}
                      </>
                    ) : (
                      <>
                        {tenantData?.legal?.privacyPolicy && tenantData?.legal?.noWebsite === false ? (
                          <Link
                            to={{
                              pathname:
                                tenantData?.legal?.privacyPolicy ?? 'https://whitelabel.tortija.de/datenschutz/',
                            }}
                            target="_blank"
                            className={styles.link}
                          >
                            Datenschutz
                          </Link>
                        ) : tenantData?.legal?.privacyPolicyText ? (
                          <>
                            <div className="cursor-pointer">
                              <div
                                className={styles.link}
                                onClick={() => openLegalInfo('dataPrivacy')}
                                aria-hidden="true"
                              >
                                Datenschutz
                              </div>
                            </div>
                          </>
                        ) : (
                          <Link
                            to={{
                              pathname:
                                tenantData?.legal?.privacyPolicy ?? 'https://whitelabel.tortija.de/datenschutz/',
                            }}
                            target="_blank"
                            className={styles.link}
                          >
                            Datenschutz
                          </Link>
                        )}
                      </>
                    )}
                  </>
                ) : window.location.hostname === 'business.tortija.de' ? (
                  <Link
                    to={{ pathname: 'https://whitelabel.tortija.de/datenschutz/' }}
                    target="_blank"
                    className={styles.link}
                  >
                    Datenschutz
                  </Link>
                ) : (
                  <Link
                    to={{ pathname: 'https://www.tortija.de/datenschutz/' }}
                    target="_blank"
                    className={styles.link}
                  >
                    Datenschutz
                  </Link>
                )}
              </div>
              <div>
                {tenantData?.legal !== undefined ? (
                  <>
                    {tenantData.customDomain !== undefined && tenantData.customDomain === window.location.hostname ? (
                      <>
                        {tenantData?.legal?.termsOfService && tenantData?.legal?.noWebsite === false ? (
                          <Link
                            to={{
                              pathname: tenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                            }}
                            target="_blank"
                            className={styles.link}
                          >
                            Nutzungsbedingungen
                          </Link>
                        ) : tenantData?.legal?.termsOfUseText ? (
                          <>
                            <div className="cursor-pointer">
                              <div className={styles.link} onClick={() => openLegalInfo('agb')} aria-hidden="true">
                                Nutzungsbedingungen
                              </div>
                            </div>
                          </>
                        ) : (
                          <Link
                            to={{
                              pathname: tenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                            }}
                            target="_blank"
                            className={styles.link}
                          >
                            Nutzungsbedingungen
                          </Link>
                        )}
                      </>
                    ) : (
                      <>
                        {tenantData?.legal?.termsOfService && tenantData?.legal?.noWebsite === false ? (
                          <Link
                            to={{
                              pathname: tenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                            }}
                            target="_blank"
                            className={styles.link}
                          >
                            Nutzungsbedingungen
                          </Link>
                        ) : tenantData?.legal?.termsOfUseText ? (
                          <>
                            <div className="cursor-pointer">
                              <div className={styles.link} onClick={() => openLegalInfo('agb')} aria-hidden="true">
                                Nutzungsbedingungen
                              </div>
                            </div>
                          </>
                        ) : (
                          <Link
                            to={{
                              pathname: tenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                            }}
                            target="_blank"
                            className={styles.link}
                          >
                            Nutzungsbedingungen
                          </Link>
                        )}
                      </>
                    )}
                  </>
                ) : window.location.hostname === 'business.tortija.de' ? (
                  <Link to={{ pathname: 'https://whitelabel.tortija.de/agb/' }} target="_blank" className={styles.link}>
                    Nutzungsbedingungen
                  </Link>
                ) : (
                  <Link to={{ pathname: 'https://www.tortija.de/agb/' }} target="_blank" className={styles.link}>
                    Nutzungsbedingungen
                  </Link>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="h-full w-full md:flex block">
          <div className={styles.loading}>
            <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerBillingCancel;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toast';
import Button from 'components/Button';
import Input from 'components/Input';
import firebase from 'services/firebase';
import { emailChecker, passwordChecker } from 'services/validations';
import { AuthContext } from 'providers/AuthProvider';
import Preloader from 'components/Preloader';
import { gymCollection } from 'shared/constants/global';
import Headline from 'components/Headline';
import Checkbox from 'components/Checkbox';
import { getMobileOperatingSystem } from 'shared/functions/global';
import styles from './styles.module.scss';

type ParamsType = {
  id: string;
};

const Register: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<ParamsType>();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const history = useHistory();

  const password = useRef({});
  password.current = watch('password', '');

  const { tenantData, setTenantData, setTheme } = useContext(AuthContext);

  const authContext = useContext(AuthContext);

  const [isRegistering, setIsRegistering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [gymData, setGymData] = useState<GymType>();

  const [openLegalPopupClass, setOpenLegalPopupClass] = useState('hidden');
  const [legalType, setLegalType] = useState('');

  const userDomain = window.location.hostname;

  // Get entry code object
  const getCouponValidFirebaseObject = async (code: string, thisCodeType: string, thisCodeUid: string) => {
    if (thisCodeType === 'multi') {
      const docData = await firebase.firestore().collection('entryCodes').where('uid', '==', thisCodeUid).get();

      let getDocument = {};
      if (docData.empty) {
        return 0;
      }

      const getDocumentData = docData.docs.map(doc => {
        getDocument = { ...doc.data(), count: parseFloat(doc.data().count) + 1 };

        return 0;
      });

      return getDocument;
    }
    const docData = await firebase.firestore().collection('entryCodes').where('code', '==', code).get();

    let getDocument = {};
    if (docData.empty) {
      return 0;
    }

    const getDocumentData = docData.docs.map(doc => {
      getDocument = { ...doc.data(), count: parseFloat(doc.data().count) + 1 };

      return 0;
    });

    return getDocument;
  };

  const getGymData = async () => {
    setIsLoading(true);
    try {
      if (id) {
        const data = await gymCollection.doc(id).get();
        setGymData({ ...(data.data() as GymType), id: data.id });
      }
      setIsLoading(false);
    } catch (error: any) {
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGymData();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    // search for customDomain inside tenants which is equal to userDomain
    firebase
      .firestore()
      .collection('customDomain-infos')
      .where('customDomain', '==', userDomain)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          const tenantInfo = querySnapshot.docs[0].data();
          setTheme(tenantInfo?.theme as TenantTheme);
          setTenantData(tenantInfo as TenantInfo);
        }
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data: any) => {
    setIsRegistering(true);

    let creationDate = firebase.firestore.FieldValue.serverTimestamp();
    let isValid = true;
    let thisCodeType = 'single';
    let thisCodeUid = '';
    let thisCodeDeleteUid = '';

    // If registration with code: Check if code is valid
    if (id === 'code') {
      let numberOfDays = 0;

      let getDurationDays = 0;
      const docData = await firebase
        .firestore()
        .collection('entryCodes')
        .where('code', '==', data.registrationCode)
        .get();

      const getDocument = docData.docs.map(doc => {
        if (doc.data().codeType === 'multi') {
          thisCodeType = 'multi';
          thisCodeUid = doc.data().parentUid;
          thisCodeDeleteUid = doc.data().uid;
          getDurationDays = doc.data().durationDays;
        } else if (doc.data().count < doc.data().amount) {
          getDurationDays = doc.data().durationDays;
        }

        return 0;
      });

      if (getDurationDays === 0) {
        isValid = false;
      } else {
        numberOfDays = getDurationDays - 5;
      }

      // Change creation date for the number of days in entry code object
      const createdAt = firebase.firestore.Timestamp.now().toDate();
      createdAt.setDate(createdAt.getDate() + numberOfDays);
      creationDate = firebase.firestore.Timestamp.fromDate(createdAt);
    }

    if (isValid) {
      try {
        let thisRegistrationCode = '';

        if (thisCodeType === 'multi') {
          thisRegistrationCode = thisCodeUid;
        } else if (id === 'code') {
          thisRegistrationCode = data.registrationCode;
        }

        const db = firebase.firestore();
        const userIsInvitedData = await db.collection('user-invitations').where('email', '==', data.email).get();
        const userIsInvited = !userIsInvitedData.empty ? userIsInvitedData.docs.map(item => item.data())[0] : undefined;

        if (tenantData?.customDomain !== undefined) {
          if (window.location.hostname === tenantData.customDomain && userIsInvited?.tenant === undefined) {
            toast.error(
              'Wir haben keine Einladung unter dieser E-Mail Adresse gefunden. Bitte kontaktiere deinen Ansprechpartner!'
            );
            setIsRegistering(false);
            return;
          }
        }

        if (window.location.hostname === 'business.tortija.de' && userIsInvited?.tenant === undefined) {
          toast.error(
            'Wir haben keine Einladung unter dieser E-Mail Adresse gefunden. Bitte kontaktiere deinen Ansprechpartner!'
          );
          setIsRegistering(false);
          return;
        }

        if (window.location.hostname === 'demo.tortija.de' && userIsInvited?.tenant === undefined) {
          toast.error(
            'Wir haben keine Einladung unter dieser E-Mail Adresse gefunden. Bitte kontaktiere deinen Ansprechpartner!'
          );
          setIsRegistering(false);
          return;
        }

        firebase
          .auth()
          .createUserWithEmailAndPassword(data.email, data.password)
          .then(async userCredential => {
            if (userIsInvited?.tenant) {
              await userCredential.user?.updateProfile({
                displayName: userIsInvited?.tenant,
              });
            }

            const collection = userIsInvited?.tenant
              ? `tenants/${userIsInvited?.tenant}/users`
              : `tenants/${process.env.REACT_APP_FALLBACK_TENANT!}/users`;

            await db
              .collection(collection)
              .doc(userCredential.user?.uid)
              .set({
                email: data.email,
                fullName: data.fullName,
                gymID: gymData?.id ?? '',
                role: userIsInvited?.role ?? 2,
                ...(userIsInvited?.location !== undefined &&
                  userIsInvited?.location !== null && { tenantLocation: userIsInvited.location }),
                ...(userIsInvited?.trainerRef !== undefined &&
                  userIsInvited?.trainerRef !== null && {
                    trainer: {
                      fullName: userIsInvited?.trainer?.fullName ?? '',
                      uid: userIsInvited?.trainer?.uid ?? '',
                    },
                    trainerRef: userIsInvited?.trainerRef,
                  }),
                userAgent: getMobileOperatingSystem(),
                profileComplete: false,
                cookieBannerType: 'initial',
                created: creationDate,
                entryCode: thisRegistrationCode,
                withCouponCode: id === 'coupon',
                membership: {
                  id: '',
                  activated: true,
                  payerId: '',
                  paymentMethod: '',
                  type: 'free',
                },
              });

            if (userIsInvited) {
              await db.collection('user-invitations').doc(userIsInvitedData.docs[0].id).delete();
            }

            if (id === 'code') {
              // If registration with code. Update count
              const thisDocument = (await getCouponValidFirebaseObject(
                data.registrationCode,
                thisCodeType,
                thisCodeUid
              )) as any;

              await firebase.firestore().collection('entryCodes').doc(thisDocument.uid).update(thisDocument);

              if (thisCodeType === 'multi') {
                await firebase.firestore().collection('entryCodes').doc(thisCodeDeleteUid).delete();
              }
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            authContext.setUser(userCredential.user as firebase.User);
            setIsRegistering(false);
            if (userIsInvited?.role === 3) {
              history.push('/');
              return;
            }
            if (userIsInvited?.role === 4) {
              history.push('/member');
              return;
            }
            history.push('/');
          })
          .catch(error => {
            console.error(error.message);
            toast.error(t(error.message));
            setIsRegistering(false);
          });
      } catch (error: any) {
        console.error(error.message);
        toast.error(t(error.message));
        setIsRegistering(false);
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.warn('Der eingegebene Code war falsch oder ist bereits abgelaufen!');
      setIsRegistering(false);
    }
  };

  const openMobileLegalInfo = (thisLegalType: string) => {
    setLegalType(thisLegalType);
    setOpenLegalPopupClass('block');
  };

  if (isLoading) {
    return <Preloader />;
  }

  return (
    /* eslint-disable react/no-danger */
    <>
      {openLegalPopupClass === 'block' ? (
        <div className="px-30 pb-40">
          <div className="absolute top-80 left-30">
            <Button onClick={() => setOpenLegalPopupClass('hidden')}>Zurück</Button>
          </div>
          <div className="pt-150">
            {legalType === 'agb' ? (
              <div>
                <div className="font-bold pb-20 text-20">Nutzungsbedingungen</div>
                {tenantData?.legal?.termsOfUseText && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tenantData?.legal?.termsOfUseText.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                    }}
                  />
                )}
              </div>
            ) : (
              <div>
                <div className="font-bold pb-20 text-20">Datenschutzerklärung</div>
                {tenantData?.legal?.privacyPolicyText && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tenantData?.legal?.privacyPolicyText.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <Headline level={1} className="text-center">
            {t('Registration')}
          </Headline>

          {id && id !== 'code' && id !== 'coupon' && (
            <div className={styles.gym}>
              <h3 className={styles.name}>{gymData?.title}</h3>
              <img src={gymData?.logoImage} alt="" width={300} height={300} className={styles.qrcode} />
            </div>
          )}

          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <Input
              name="fullName"
              type="text"
              label={t('Name')}
              autoComplete="given-name"
              required
              register={register('fullName', {
                validate: (value: string) => {
                  if (!value.trim()) {
                    return t('Please enter your full name').toString();
                  }
                  return true;
                },
              })}
              error={errors.fullName}
            />
            <Input
              name="email"
              type="email"
              label={t('Email')}
              autoComplete="email"
              required
              register={register('email', {
                validate: (value: string) => {
                  if (!value.trim()) {
                    return t('Email Address is required').toString();
                  }
                  return emailChecker(value) || t('Please enter valid email address').toString();
                },
              })}
              error={errors.email}
            />
            <Input
              name="password"
              type="password"
              label={t('Password')}
              autoComplete=""
              required
              register={register('password', {
                minLength: {
                  value: 8,
                  message: 'Gebe mindestens 1 Großbuchstabe, 1 Zahl und 8 Zeichen ein.',
                },
                required: t('Please enter your password').toString(),
                validate: (value: string) =>
                  passwordChecker(value) || t('Password must contain at least 1 number').toString(),
              })}
              error={errors.password}
            />
            <Input
              name="confirm-password"
              type="password"
              label={t('Confirm password')}
              autoComplete=""
              register={register('confirm-password', {
                required: t('Please confirm your password').toString(),
                validate: (value: string) =>
                  value === password.current || t('Password and Confirm password do not match').toString(),
              })}
              error={errors['confirm-password']}
            />
            {id && id === 'code' && (
              <div>
                <div className="font-extralight">Dein persönlicher Zugangscode</div>
                <Input
                  name="registrationCode"
                  type="text"
                  label="Zugangscode"
                  autoComplete=""
                  required
                  register={register('registrationCode', {
                    validate: (value: string) => {
                      if (!value.trim()) {
                        return 'Bitte gebe deinen Zugangscode ein';
                      }
                      return true;
                    },
                  })}
                  error={errors.registrationCode}
                />
              </div>
            )}

            <div className={styles.commentLegal}>
              <div>
                {tenantData?.legal !== undefined ? (
                  <>
                    {tenantData.customDomain !== undefined && tenantData.customDomain === window.location.hostname ? (
                      <>
                        {tenantData?.legal?.privacyPolicy && tenantData?.legal?.noWebsite === false ? (
                          <Link
                            to={{
                              pathname:
                                tenantData?.legal?.privacyPolicy ?? 'https://whitelabel.tortija.de/datenschutz/',
                            }}
                            target="_blank"
                            className={styles.link}
                          >
                            Datenschutzerklärung
                          </Link>
                        ) : tenantData?.legal?.privacyPolicyText ? (
                          <>
                            <div className="desktop:hidden">
                              <div
                                className={styles.link}
                                onClick={() => openMobileLegalInfo('dataPrivacy')}
                                aria-hidden="true"
                              >
                                Datenschutzerklärung
                              </div>
                            </div>
                            <div className="hidden desktop:block">
                              <Link
                                to={{
                                  pathname: '/legal/dataprivacy',
                                }}
                                target="_blank"
                                className={styles.link}
                              >
                                Datenschutzerklärung
                              </Link>
                            </div>
                          </>
                        ) : (
                          <Link
                            to={{
                              pathname:
                                tenantData?.legal?.privacyPolicy ?? 'https://whitelabel.tortija.de/datenschutz/',
                            }}
                            target="_blank"
                            className={styles.link}
                          >
                            Datenschutzerklärung
                          </Link>
                        )}
                      </>
                    ) : (
                      <Link
                        to={{ pathname: 'https://whitelabel.tortija.de/datenschutz/' }}
                        target="_blank"
                        className={styles.link}
                      >
                        Datenschutzerklärung
                      </Link>
                    )}
                  </>
                ) : window.location.hostname === 'business.tortija.de' ? (
                  <Link
                    to={{ pathname: 'https://whitelabel.tortija.de/datenschutz/' }}
                    target="_blank"
                    className={styles.link}
                  >
                    Datenschutzerklärung
                  </Link>
                ) : (
                  <Link
                    to={{ pathname: 'https://www.tortija.de/datenschutz/' }}
                    target="_blank"
                    className={styles.link}
                  >
                    Datenschutzerklärung
                  </Link>
                )}
              </div>
              <div>
                {tenantData?.legal !== undefined ? (
                  <>
                    {tenantData.customDomain !== undefined && tenantData.customDomain === window.location.hostname ? (
                      <>
                        {tenantData?.legal?.termsOfService && tenantData?.legal?.noWebsite === false ? (
                          <Link
                            to={{
                              pathname: tenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                            }}
                            target="_blank"
                            className={styles.link}
                          >
                            Nutzungsbedingungen
                          </Link>
                        ) : tenantData?.legal?.termsOfUseText ? (
                          <>
                            <div className="desktop:hidden">
                              <div
                                className={styles.link}
                                onClick={() => openMobileLegalInfo('agb')}
                                aria-hidden="true"
                              >
                                Nutzungsbedingungen
                              </div>
                            </div>
                            <div className="hidden desktop:block">
                              <Link
                                to={{
                                  pathname: '/legal/agb',
                                }}
                                target="_blank"
                                className={styles.link}
                              >
                                Nutzungsbedingungen
                              </Link>
                            </div>
                          </>
                        ) : (
                          <Link
                            to={{
                              pathname: tenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                            }}
                            target="_blank"
                            className={styles.link}
                          >
                            Nutzungsbedingungen
                          </Link>
                        )}
                      </>
                    ) : (
                      <Link
                        to={{ pathname: 'https://whitelabel.tortija.de/agb/' }}
                        target="_blank"
                        className={styles.link}
                      >
                        Nutzungsbedingungen
                      </Link>
                    )}
                  </>
                ) : window.location.hostname === 'business.tortija.de' ? (
                  <Link to={{ pathname: 'https://whitelabel.tortija.de/agb/' }} target="_blank" className={styles.link}>
                    Nutzungsbedingungen
                  </Link>
                ) : (
                  <Link to={{ pathname: 'https://www.tortija.de/agb/' }} target="_blank" className={styles.link}>
                    Nutzungsbedingungen
                  </Link>
                )}
              </div>
            </div>

            <Checkbox
              label="Ich akzeptiere die Nutzungsbedingungen und Datenschutzerklärung."
              name="data-protection"
              register={register('data-protection', {
                validate: (value: string) => {
                  if (!value) {
                    return t('Please accept data protection').toString();
                  }
                  return true;
                },
              })}
              error={errors['data-protection']}
            />

            {window.location.hostname === 'app.tortija.de' && id !== 'coupon' ? (
              <>
                <Button disabled={isRegistering} isPending={isRegistering} className={styles.button}>
                  <span className={styles.text}>{t('Try it for free for 7 days')}</span>
                </Button>
                <div className="font-extralight text-12 text-center mt-10">
                  Du musst keine Zahlungsinformationen eingeben!
                </div>
              </>
            ) : (
              <Button disabled={isRegistering} isPending={isRegistering} className={styles.button}>
                <span className={styles.text}>{t('Register')}</span>
              </Button>
            )}

            <div className={styles.comment}>
              <span>{t('Do you already have an account?')}</span>
              <Link to="/auth/choose-login" className={styles.link}>
                {t('Sign in here!')}
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default Register;

import React, { useContext } from 'react';

import { AuthContext } from 'providers/AuthProvider';
import Headline from 'components/Headline';
import SwitchButton from 'components/SwitchButton';

import styles from './styles.module.scss';

const ShareProfile = () => {
  const authContext = useContext(AuthContext);

  const setIncompatibilities = (status: boolean, item: string): void => {
    authContext.setUserData((prevProfile: UserInfo) => ({
      ...prevProfile,
      shareProfileType: status ? 'share' : 'anonym',
    }));
  };

  return (
    <>
      {!localStorage.getItem('currentUserToken') && (
        <div className={styles.wrapper}>
          <Headline className="capitalize" centered level={1}>
            Freigabe deines Profils
          </Headline>
          <div className={styles.content}>
            <div className="pt-20">
              Es gibt die Möglichkeit (z.B. wenn du mit einem Ernährungsberater oder Personal Coach zusammen arbeitest)
              das du dein Profil freigibst.
            </div>
            <div className="pt-10">
              In diesem Fall kann dein Ansprechpartner in dein Profil und in deine Ernährung reinschauen und Änderungen
              vornehmen.
            </div>
            <div className="pt-10">
              Möchtest du diese Funktion für deinen Ansprechpartner freigeben oder anonym bleiben?
            </div>
          </div>
          <SwitchButton
            label="Profil freigeben"
            isBackground
            enabled={authContext.userData?.shareProfileType === 'share'}
            onChange={setIncompatibilities}
          />
        </div>
      )}
    </>
  );
};

export default ShareProfile;

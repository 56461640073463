import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import styles from './style.module.scss';

type Props = {
  updateFunction: any;
  isMobile: boolean;
  ratingDayType: any;
};

const DayRating: React.FC<Props> = ({ updateFunction, isMobile, ratingDayType }) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <div className="px-15 md:px-0 mt-15 font-extralight">Wie würdest du diesen Tag bewerten?</div>
        <div className="flex justify-between mx-20 my-20">
          <div
            className={`rounded-2xl ${
              ratingDayType === 'sad' ? 'border-accentColor' : 'border-transparent'
            } border-2 p-5 cursor-pointer md:hover:border-accentColor`}
            onClick={() => updateFunction('sad')}
            onKeyDown={() => updateFunction('sad')}
            aria-hidden="true"
          >
            {isMobile ? (
              <Icon name="emojiSad" className="text-accentColor" width={25} height={25} />
            ) : (
              <Icon name="emojiSad" className="text-accentColor" width={40} height={40} />
            )}
          </div>
          <div
            className={`rounded-2xl ${
              ratingDayType === 'neutral' ? 'border-accentColor' : 'border-transparent'
            } border-2 p-5 cursor-pointer md:hover:border-accentColor`}
            onClick={() => updateFunction('neutral')}
            onKeyDown={() => updateFunction('neutral')}
            aria-hidden="true"
          >
            {isMobile ? (
              <Icon name="emojiNeutral" className="text-accentColor" width={25} height={25} />
            ) : (
              <Icon name="emojiNeutral" className="text-accentColor" width={40} height={40} />
            )}
          </div>
          <div
            className={`rounded-2xl ${
              ratingDayType === 'happy' ? 'border-accentColor' : 'border-transparent'
            } border-2 p-5 cursor-pointer md:hover:border-accentColor`}
            onClick={() => updateFunction('happy')}
            onKeyDown={() => updateFunction('happy')}
            aria-hidden="true"
          >
            {isMobile ? (
              <Icon name="emojiHappy" className="text-accentColor" width={25} height={25} />
            ) : (
              <Icon name="emojiHappy" className="text-accentColor" width={40} height={40} />
            )}
          </div>
          <div
            className={`rounded-2xl ${
              ratingDayType === 'heart' ? 'border-accentColor' : 'border-transparent'
            } border-2 p-5 cursor-pointer md:hover:border-accentColor`}
            onClick={() => updateFunction('heart')}
            onKeyDown={() => updateFunction('heart')}
            aria-hidden="true"
          >
            {isMobile ? (
              <Icon name="emojiHeart" className="text-accentColor" width={25} height={25} />
            ) : (
              <Icon name="emojiHeart" className="text-accentColor" width={40} height={40} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DayRating;

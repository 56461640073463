import React, { useContext } from 'react';
import axios from 'axios';
import firebase from 'firebase';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getNextSubscriptionDate } from 'shared/functions/global';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import Headline from 'components/Headline';
import TransitionContainer from 'components/TransitionContainer';
import { AuthContext } from 'providers/AuthProvider';
import { toast } from 'react-toast';
import styles from './styles.module.scss';

type Props = {
  isShown: boolean;
  title: string;
  goBack?: React.MouseEventHandler<HTMLButtonElement>;
};

const Subscription: React.FC<Props> = ({ isShown, title, goBack = () => {} }) => {
  const { t } = useTranslation();
  const db = firebase.firestore();
  const { userData, user, tenant } = useContext(AuthContext);
  const useIAP = userData?.membership?.paymentMethod === 'iOS' || userData?.membership?.paymentMethod === 'android';

  const unsubscribe = async () => {
    let getPeriod = 'month';
    if (userData?.membership?.type === 'pro12') {
      getPeriod = 'year';
    }

    let getNextDate = 0;
    if (userData?.membership?.nextDate !== undefined) {
      getNextDate = getNextSubscriptionDate(
        getPeriod,
        moment.unix(userData?.membership?.nextDate).format('YYYY-MM-DD')
      );
    }

    try {
      if (userData?.membership?.paymentMethod === 'paypal') {
        await axios.post(
          `${process.env.REACT_APP_PAYPAL_SUBSCRIPTION_API ?? ''}/${userData?.membership?.id ?? ''}/cancel`,
          {
            reason: 'Not satisfied with the service',
          },
          {
            auth: {
              username: process.env.REACT_APP_PAYPAL_CLIENTID ?? '',
              password: process.env.REACT_APP_PAYPAL_SECRET ?? '',
            },
          }
        );
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/cancel-subscription`, {
          subscriptionId: userData?.membership?.id,
        });
      }
      await db
        .collection(`tenants/${tenant}/users`)
        .doc(user?.uid)
        .update({
          membership: {
            id: '',
            activated: false,
            payerId: '',
            nextDate: getNextDate,
            paymentMethod: '',
            type: 'canceled',
          },
        });

      toast.success('Dein Abonnement wurde erfolgreich gekündigt!');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <TransitionContainer isShown={isShown}>
      <div className={styles.header}>
        <Headline level={1} className="mb-20">
          {t(title)}
        </Headline>
        <Headline level={4} displayBackBtn goBack={goBack}>
          {t('Return')}
        </Headline>
      </div>
      <div className={styles.content}>
        <div>
          {userData?.membership?.type === 'free' && userData?.role === 2 ? (
            <div>
              <div>Aktuell hast du noch kein Abonnement.</div>
              <div>Starte jetzt und klicke auf den Button um dein Premium Zugang freizuschalten!</div>
              <div className="pt-20">
                <Link to="/purchase">
                  <Button>Jetzt Mitglied werden</Button>
                </Link>
              </div>
            </div>
          ) : userData?.membership?.type === 'canceled' ? (
            <div>
              <div>Du hast dein Abonnement gekündigt!</div>
              <div>
                Dein aktuelles Abonnement läuft noch bis zum{' '}
                {moment.unix(userData?.membership?.nextDate).format('DD.MM.YYYY')}!
              </div>
            </div>
          ) : (
            <div>
              <div>
                Dein Abonnement ist <strong>aktiv</strong>!
              </div>
            </div>
          )}
        </div>
        {userData?.membership?.type === 'pro' || userData?.membership?.type === 'pro12' ? (
          <p>
            {t('Payment type')} :{' '}
            {userData?.membership?.type === 'pro' ? 'Monatliches Abonnement' : 'Jährliches Abonnement'}
          </p>
        ) : (
          <></>
        )}
        {!useIAP && (userData?.membership?.type === 'pro' || userData?.membership?.type === 'pro12') ? (
          userData?.membership?.activated ? (
            <Button className="w-1/2" onClick={unsubscribe}>
              {t('Unsubscribe')}
            </Button>
          ) : (
            <p>{t('Unsubscribed')}</p>
          )
        ) : null}

        {useIAP && (userData?.membership?.type === 'pro' || userData?.membership?.type === 'pro12') && (
          <p>{t('mobileUnsubscribeDescription')}</p>
        )}
      </div>
    </TransitionContainer>
  );
};

export default Subscription;

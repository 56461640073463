import React, { useContext, useMemo, useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Card from 'components/Card';
import { useTranslation } from 'react-i18next';
import { MainContext } from 'providers/MainProvider';
import { HeartIcon, ChevronRightIcon, AdjustmentsIcon } from '@heroicons/react/outline';
import moment from 'moment';
import HashTagBadge from 'pages/Plans/components/HashTagBadge';
import Skeleton from 'components/skeleton';
import SearchBox from 'components/SearchBox';
import { scrollToElement } from 'shared/functions/global';
import ButtonBack from 'components/ButtonBack';
import { AuthContext } from 'providers/AuthProvider';

import { FilterContext } from 'providers/FilterProvider';
import styles from './styles.module.scss';

type Props = {
  type?: string;
  copyPlanFunction?: any;
  openNewPlan?: Function;
  openFilter?: Function;
  initialPlanSection?: string;
  initialPlanCalories?: string;
};

const PlanSection: React.FC<Props> = ({
  type,
  copyPlanFunction,
  openNewPlan,
  openFilter,
  initialPlanCalories,
  initialPlanSection,
}) => {
  const { t } = useTranslation();

  const { planList, favoritesPlansList, userPlanList } = useContext(MainContext);
  const authContext = useContext(AuthContext);
  const { userData, theme, tenantData } = useContext(AuthContext);

  const samplePlanArray = [
    {
      label: 'Flexitarische Beispielpläne',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fexample_1.jpeg?alt=media&token=d48b84be-17df-4395-ab08-8f3344d432c5',
      type: 'flexitarian',
      typeLabel: 'Flexitarische',
    },
    {
      label: 'Vegane Beispielpläne',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fexample_4.jpeg?alt=media&token=77894a70-1e16-4a02-a827-353303413c7e',
      type: 'vegan',
      typeLabel: 'Vegane',
    },
    {
      label: 'Vegetarische Beispielpläne',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fexample_5.jpeg?alt=media&token=32b916dc-34ea-4a2b-8c0e-841f1e1d1476',
      type: 'vegetarian',
      typeLabel: 'Vegetarische',
    },
    {
      label: 'Ketogene Beispielpläne',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fexample_2.jpeg?alt=media&token=a354aac2-1db7-4c81-b418-cb111286a17e',
      type: 'ketogen',
      typeLabel: 'Ketogene',
    },
  ];

  const samplePlanCategoriesArray = [
    {
      label: '1250 - 1750 Kcal',
      imageUrl:
        userData?.role === 2
          ? 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
          : theme?.logo !== undefined
          ? theme.logo
          : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097',
      type: '1250_1750',
    },
    {
      label: '2000 - 2500 Kcal',
      imageUrl:
        userData?.role === 2
          ? 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
          : theme?.logo !== undefined
          ? theme.logo
          : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097',
      type: '2000_2500',
    },
    {
      label: '2750 - 3250 Kcal',
      imageUrl:
        userData?.role === 2
          ? 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
          : theme?.logo !== undefined
          ? theme.logo
          : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097',
      type: '2750_3250',
    },
    {
      label: '3500 - 4000 Kcal',
      imageUrl:
        userData?.role === 2
          ? 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
          : theme?.logo !== undefined
          ? theme.logo
          : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097',
      type: '3500_4000',
    },
  ];

  const [currentSection, setCurrentSection] = useState('start');
  const [currentSampleType, setCurrentSampleType] = useState('');
  const [currentSampleTypeLabel, setCurrentSampleTypeLabel] = useState('');
  const [currentSampleCalories, setCurrentSampleCalories] = useState('');

  const examplePlanHeadline = useRef<HTMLDivElement>(null);

  const history = useHistory();

  const {
    initialNutritionPlan,
    initialIntolerancesPlan,
    caloriesRangePlan,
    planSearchValue,
    setPlanSearchValue,
    changeCaloriesRangePlan,
    changeNutritionPlan,
  } = useContext(FilterContext);

  const planFilteredList: PlanType[] = useMemo(
    () =>
      planList
        ? planList.filter(
            element =>
              element.examplePlanNutrition === currentSampleType &&
              element.examplePlanCalories === currentSampleCalories &&
              element.examplePlan &&
              element.planType !== 'manual'
          )
        : [],
    [planList, currentSampleCalories, currentSampleType]
  );

  const userPlanFilteredList: PlanType[] = useMemo(
    () =>
      userPlanList
        ? userPlanList.filter(
            element =>
              (element.name.toLowerCase().includes(planSearchValue.toLowerCase()) ||
                element.kcal_total?.toString().includes(planSearchValue.toLowerCase())) &&
              (initialNutritionPlan.length === 0 ||
                initialNutritionPlan.findIndex(item => element[item.toLowerCase()]) > -1) &&
              (initialIntolerancesPlan.length === 0 ||
                initialIntolerancesPlan.findIndex(item => element[item.toLowerCase()]) === -1) &&
              element.planType !== 'manual'
          )
        : [],
    [userPlanList, planSearchValue, initialNutritionPlan, initialIntolerancesPlan]
  );

  const userPlanNoManualList: PlanType[] = useMemo(
    () => (userPlanList ? userPlanList.filter(element => element.planType !== 'manual') : []),
    [userPlanList]
  );

  function goToDetailPage(uid: any) {
    const thisScrollPos = document.getElementById('mainContainer')?.scrollTop;

    history.push({
      pathname: `/plans/${uid}`,
      state: {
        from: 'plans',
        scrollPos: thisScrollPos,
        planExampleCategory: currentSampleType,
        planExampleCalories: currentSampleCalories,
      },
    });
  }

  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlanSearchValue(event.target.value);
  };

  const filterOpen = () => {
    if (openFilter !== undefined) {
      openFilter(true);
    }
  };

  const jumpBack = (section: string) => {
    setCurrentSection(section);

    const userAgent = navigator.userAgent || navigator.vendor;

    if (!/iPad|iPhone|iPod/.test(userAgent)) {
      scrollToElement(examplePlanHeadline);
    }
  };

  const openPlanCategories = (categoryType: string, categoryLabel: string) => {
    setCurrentSampleType(categoryType);
    setCurrentSampleTypeLabel(categoryLabel);
    setCurrentSection('category');
    scrollToElement(examplePlanHeadline);
  };

  const openSamplePlans = (calories: string) => {
    setCurrentSampleCalories(calories);
    setCurrentSection('plans');
    scrollToElement(examplePlanHeadline);
  };

  function getPlanAverageValues(planObject: any) {
    const test = planObject?.dayEntries.filter((item: any) => item.kcal_total > 0);

    const calorieTotal = test.reduce(
      (totalCalories: number, dayObject: any) => totalCalories + parseFloat(dayObject.kcal_total),
      0
    );

    const averageValue = Math.round(parseFloat(calorieTotal) / test.length);

    if (calorieTotal === 0) {
      return 0;
    }
    return averageValue;
  }

  useEffect(() => {
    if (initialPlanCalories !== undefined && initialPlanSection !== undefined) {
      if (initialPlanCalories.length > 0 && initialPlanSection.length > 0) {
        setCurrentSampleTypeLabel(samplePlanArray.filter(element => element.type === initialPlanSection)[0].typeLabel);
        setCurrentSection('plans');
        setCurrentSampleType(initialPlanSection);
        setCurrentSampleCalories(initialPlanCalories);
        setTimeout(() => {
          scrollToElement(examplePlanHeadline);
        }, 1);
      }
    }
  }, [initialPlanCalories, initialPlanSection]);

  return (
    <>
      <div className="flex justify-between pt-20">
        <div className="font-extralight text-2xl">Deine Pläne</div>
      </div>
      {userPlanNoManualList?.length === 0 ? (
        <div className="font-extralight text-16 pt-10">
          <div>Aktuell hast du noch keinen Plan erstellt!</div>
          {(userData?.role === 2 ||
            userData?.isAdminUser ||
            userData?.isTrainerUser ||
            userData?.isLocationAdminUser ||
            localStorage.getItem('currentUserToken') ||
            tenantData?.settings?.functions?.tortijaExamplePlans === undefined ||
            tenantData?.settings?.functions?.tortijaExamplePlans === true) && (
            <div className="pt-5">
              Du kannst dir in den <span className="font-semibold">Beispielplänen</span> einen Plan aussuchen und diesen
              starten oder erstelle dir hier deinen ersten Plan:
            </div>
          )}
          <div
            className="flex cursor-pointer pt-5"
            onClick={() => openNewPlan !== undefined && openNewPlan('block')}
            onKeyDown={() => openNewPlan !== undefined && openNewPlan('block')}
            aria-hidden="true"
          >
            <div className="font-extralight text-20 hover:underline">Neuen Plan erstellen</div>
            <div className="my-auto">
              <ChevronRightIcon width={20} height={20} className="text-accentColor" />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="hidden xl:flex pt-15 pb-20" id="searchFilter">
            <div className="flex-1">
              <SearchBox onChange={changeSearch} searchValue={planSearchValue} inputType="search" />
            </div>
            <div className="my-auto pl-15 ">
              <div className="border-transparent border-2 hover:border-accentColor rounded-full p-5">
                <AdjustmentsIcon
                  width={28}
                  height={28}
                  onClick={filterOpen}
                  className="text-accentColor cursor-pointer transform rotate-90"
                />
              </div>
            </div>
          </div>
          <div className="font-extralight text-14 pt-10 pb-20">
            {userPlanFilteredList.length} {userPlanFilteredList.length === 1 ? 'Plan' : 'Pläne'} gefunden
          </div>
          <div className={type === 'copyPlan' ? styles.wrapperCopy : styles.wrapper}>
            {/* skeleton loading */}
            {!userPlanList
              ? Array.from(Array(15).keys()).map((_, index) => (
                  <Card className="mx-auto" key={`skeleton${index}`} isLoading>
                    <Skeleton className={styles.skeletonTitle} translucent />
                  </Card>
                ))
              : userPlanFilteredList.map((plan: PlanType, index: number) =>
                  type !== undefined && type === 'copyPlan' ? (
                    <div
                      onClick={() => copyPlanFunction(plan)}
                      onMouseDown={() => copyPlanFunction(plan)}
                      aria-hidden="true"
                      className="cursor-pointer"
                    >
                      <Card
                        image={plan.imageUrl}
                        className="mx-auto border-transparent border-2 hover:border-accentColor h-full relative"
                      >
                        {plan.examplePlan === false && (
                          <div className="absolute top-10 left-10 bg-accentColor text-buttonTextColor text-10 font-bold rounded-full px-10 py-1">
                            <div>Eigener Plan</div>
                          </div>
                        )}
                        <div className="flex justify-between">
                          <div className="pr-15">
                            <div className={styles.title}>{plan.name}</div>
                          </div>
                          {favoritesPlansList?.find(item => item.origId === plan.uid) !== undefined && (
                            <div className="pt-1">
                              <HeartIcon width={20} height={20} className="text-accentColor" fill="#C97132" />
                            </div>
                          )}
                        </div>
                        <HashTagBadge planObject={plan} isPlanOverview />
                        <div className="text-xs font-light pt-15">
                          <span className="font-semibold">Laufzeit:</span> {plan?.dayEntries.length} Tage
                        </div>
                        {userData?.role === 1 || plan.examplePlan === false ? (
                          <div className="text-xs font-light pt-10">
                            <span className="font-semibold">Zeitraum:</span>{' '}
                            {moment.unix(plan?.startDate.seconds).utc().format('DD[.]MM[.]YY')} -{' '}
                            {moment.unix(plan?.endDate.seconds).utc().format('DD[.]MM[.]YY')}
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="text-xs font-light pt-10 pb-10">
                          <span className="font-semibold">Kaloriendurchschnitt: </span>
                          {getPlanAverageValues(plan)} kcal
                        </div>
                      </Card>
                    </div>
                  ) : (
                    <div
                      onClick={() => goToDetailPage(plan?.uid)}
                      onKeyDown={() => goToDetailPage(plan?.uid)}
                      aria-hidden="true"
                      key={index}
                      className="cursor-pointer"
                    >
                      <Card
                        image={plan.imageUrl}
                        className="mx-auto border-transparent border-2 hover:border-accentColor h-full relative"
                      >
                        {plan.examplePlan === false && (
                          <div className="absolute top-10 left-10 bg-accentColor text-buttonTextColor text-10 font-bold rounded-full px-10 py-1">
                            <div>Eigener Plan</div>
                          </div>
                        )}
                        <div className="flex justify-between">
                          <div className="pr-15">
                            <div className={styles.title}>{plan.name}</div>
                          </div>
                          {favoritesPlansList?.find(item => item.origId === plan.uid) !== undefined && (
                            <div className="pt-1">
                              <HeartIcon width={20} height={20} className="text-accentColor" fill="#C97132" />
                            </div>
                          )}
                        </div>
                        <HashTagBadge planObject={plan} isPlanOverview />
                        <div className="text-xs font-light pt-15">
                          <span className="font-semibold">Laufzeit:</span> {plan?.dayEntries.length} Tage
                        </div>
                        {userData?.role === 1 || plan.examplePlan === false ? (
                          <div className="text-xs font-light pt-10">
                            <span className="font-semibold">Zeitraum:</span>{' '}
                            {moment.unix(plan?.startDate.seconds).utc().format('DD[.]MM[.]YY')} -{' '}
                            {moment.unix(plan?.endDate.seconds).utc().format('DD[.]MM[.]YY')}
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="text-xs font-light pt-10 pb-10">
                          <span className="font-semibold">Kaloriendurchschnitt: </span>
                          {getPlanAverageValues(plan)} kcal
                        </div>
                      </Card>
                    </div>
                  )
                )}
          </div>
        </>
      )}

      {(userData?.role === 2 ||
        userData?.isAdminUser ||
        userData?.isTrainerUser ||
        userData?.isLocationAdminUser ||
        localStorage.getItem('currentUserToken') ||
        tenantData?.settings?.functions?.tortijaExamplePlans === undefined ||
        tenantData?.settings?.functions?.tortijaExamplePlans === true) && (
        <div>
          <div className="flex justify-between pt-40" ref={examplePlanHeadline}>
            <div className="font-extralight text-2xl">Beispielpläne</div>
          </div>
          <div id="samplePlans">
            {currentSection === 'category' && (
              <div
                onClick={() => jumpBack('start')}
                onKeyDown={() => jumpBack('start')}
                aria-hidden="true"
                className="py-20"
              >
                <ButtonBack
                  text="Zurück"
                  className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                />
              </div>
            )}
            {currentSection === 'plans' && (
              <div
                onClick={() => jumpBack('category')}
                onKeyDown={() => jumpBack('category')}
                aria-hidden="true"
                className="py-20"
              >
                <ButtonBack
                  text="Zurück"
                  className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                />
              </div>
            )}
            {currentSection === 'start' ? (
              <div className="pt-20">
                <div className={type === 'copyPlan' ? styles.wrapperCopy : styles.wrapper}>
                  {samplePlanArray.map((element: any, index: number) => (
                    <div
                      key={index}
                      onClick={() => openPlanCategories(element.type, element.typeLabel)}
                      onMouseDown={() => openPlanCategories(element.type, element.typeLabel)}
                      aria-hidden="true"
                      className="cursor-pointer"
                    >
                      <Card
                        image={element.imageUrl}
                        className="mx-auto border-transparent border-2 hover:border-accentColor h-full relative"
                      >
                        <div className="flex justify-between">
                          <div className="pr-15">
                            <div className={styles.title}>{element.label}</div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  ))}
                </div>
              </div>
            ) : currentSection === 'category' ? (
              <div>
                <div className="capitalize text-25 font-bold pb-15">{currentSampleTypeLabel} Beispielpläne</div>
                <div className={type === 'copyPlan' ? styles.wrapperCopy : styles.wrapper}>
                  {samplePlanCategoriesArray.map((element: any, index: number) => (
                    <div
                      key={index}
                      onClick={() => openSamplePlans(element.type)}
                      onMouseDown={() => openSamplePlans(element.type)}
                      aria-hidden="true"
                      className="cursor-pointer"
                    >
                      <Card
                        image={element.imageUrl}
                        className="mx-auto border-transparent border-2 hover:border-accentColor h-full relative"
                      >
                        <div className="flex justify-between">
                          <div className="pr-15">
                            <div className="font-bold">Kalorien:</div>
                            <div>{element.label}</div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  ))}
                </div>
              </div>
            ) : currentSection === 'plans' ? (
              <>
                <div className="capitalize text-25 font-bold">{currentSampleTypeLabel} Beispielpläne</div>
                <div className="pb-15">{currentSampleCalories.replaceAll('_', ' - ')} Kcal</div>
                <div className="font-extralight text-14 pt-10 pb-20">
                  {planFilteredList.length} {planFilteredList.length === 1 ? 'Plan' : 'Pläne'} gefunden
                </div>
                <div className={type === 'copyPlan' ? styles.wrapperCopy : styles.wrapper}>
                  {/* skeleton loading */}
                  {!planList
                    ? Array.from(Array(15).keys()).map((_, index) => (
                        <Card className="mx-auto" key={`skeleton${index}`} isLoading>
                          <Skeleton className={styles.skeletonTitle} translucent />
                        </Card>
                      ))
                    : planFilteredList.map((plan: PlanType, index: number) =>
                        type !== undefined && type === 'copyPlan' ? (
                          <div
                            onClick={() => copyPlanFunction(plan)}
                            onMouseDown={() => copyPlanFunction(plan)}
                            aria-hidden="true"
                            className="cursor-pointer"
                          >
                            <Card
                              image={plan.imageUrl}
                              className="mx-auto border-transparent border-2 hover:border-accentColor h-full relative"
                            >
                              {plan.examplePlan === false && (
                                <div className="absolute top-10 left-10 bg-accentColor text-buttonTextColor text-10 font-bold rounded-full px-10 py-1">
                                  <div>Eigener Plan</div>
                                </div>
                              )}
                              <div className="flex justify-between">
                                <div className="pr-15">
                                  <div className={styles.title}>{plan.name}</div>
                                </div>
                                {favoritesPlansList?.find(item => item.origId === plan.uid) !== undefined && (
                                  <div className="pt-1">
                                    <HeartIcon width={20} height={20} className="text-accentColor" fill="#C97132" />
                                  </div>
                                )}
                              </div>
                              <HashTagBadge planObject={plan} isPlanOverview />
                              <div className="text-xs font-light pt-15">
                                <span className="font-semibold">Laufzeit:</span> {plan?.dayEntries.length} Tage
                              </div>
                              {userData?.role === 1 || plan.examplePlan === false ? (
                                <div className="text-xs font-light pt-10">
                                  <span className="font-semibold">Zeitraum:</span>{' '}
                                  {moment.unix(plan?.startDate.seconds).utc().format('DD[.]MM[.]YY')} -{' '}
                                  {moment.unix(plan?.endDate.seconds).utc().format('DD[.]MM[.]YY')}
                                </div>
                              ) : (
                                <></>
                              )}
                              <div className="text-xs font-light pt-10 pb-10">
                                <span className="font-semibold">Kaloriendurchschnitt: </span>
                                {getPlanAverageValues(plan)} kcal
                              </div>
                            </Card>
                          </div>
                        ) : (
                          <div
                            onClick={() => goToDetailPage(plan?.uid)}
                            onKeyDown={() => goToDetailPage(plan?.uid)}
                            aria-hidden="true"
                            key={index}
                            className="cursor-pointer"
                          >
                            <Card
                              image={plan.imageUrl}
                              className="mx-auto border-transparent border-2 hover:border-accentColor h-full relative"
                            >
                              {plan.examplePlan === false && (
                                <div className="absolute top-10 left-10 bg-accentColor text-buttonTextColor text-10 font-bold rounded-full px-10 py-1">
                                  <div>Eigener Plan</div>
                                </div>
                              )}
                              <div className="flex justify-between">
                                <div className="pr-15">
                                  <div className={styles.title}>{plan.name}</div>
                                </div>
                                {favoritesPlansList?.find(item => item.origId === plan.uid) !== undefined && (
                                  <div className="pt-1">
                                    <HeartIcon width={20} height={20} className="text-accentColor" fill="#C97132" />
                                  </div>
                                )}
                              </div>
                              <HashTagBadge planObject={plan} isPlanOverview />
                              <div className="text-xs font-light pt-15">
                                <span className="font-semibold">Laufzeit:</span> {plan?.dayEntries.length} Tage
                              </div>
                              {userData?.role === 1 || plan.examplePlan === false ? (
                                <div className="text-xs font-light pt-10">
                                  <span className="font-semibold">Zeitraum:</span>{' '}
                                  {moment.unix(plan?.startDate.seconds).utc().format('DD[.]MM[.]YY')} -{' '}
                                  {moment.unix(plan?.endDate.seconds).utc().format('DD[.]MM[.]YY')}
                                </div>
                              ) : (
                                <></>
                              )}
                              <div className="text-xs font-light pt-10 pb-10">
                                <span className="font-semibold">Kaloriendurchschnitt: </span>
                                {getPlanAverageValues(plan)} kcal
                              </div>
                            </Card>
                          </div>
                        )
                      )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PlanSection;

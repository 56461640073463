import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import firebase from 'services/firebase';
import LogoImg from 'assets/img/svg/logo.svg';
import Button from 'components/Button';
import { AuthContext } from 'providers/AuthProvider';
import styles from './styles.module.scss';

const StartPage: React.FC = () => {
  const userDomain = window.location.hostname;
  const { t } = useTranslation();
  const history = useHistory();
  const { theme, setTheme } = useContext(AuthContext);
  const logo = theme?.logo ?? LogoImg;
  const [isLoading, setIsLoading] = useState(true);

  const getStart = () => {
    history.push('/auth/choose-login');
  };

  useEffect(() => {
    // search for customDomain inside tenants which is equal to userDomain
    firebase
      .firestore()
      .collection('customDomain-infos')
      .where('customDomain', '==', userDomain)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          const tenantInfo = querySnapshot.docs[0].data();
          setTheme(tenantInfo?.theme as TenantTheme);
        }
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.image}>
        <img width={160} height={140} src={logo} alt="" />
        {window.location.hostname === 'business.tortija.de' && (
          <div className="text-center font-extralight pt-5">Business</div>
        )}
      </div>
      <p className={styles.text}>{t('Heartily Welcome!')}</p>
      <Button onClick={getStart}>
        <span className="uppercase">{t('Get start now')}</span>
      </Button>
    </div>
  );
};

export default StartPage;

import React, { useContext, useEffect, useRef } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import SwitchButton from 'components/SwitchButton';
import Headline from 'components/Headline';
import { GoalDescription, stepLists, yourGoal } from 'shared/constants/profile-wizard';
import CustomInput from 'components/Input/custom-input';

type Props = {
  isProfile?: boolean;
};

export default function GoalStep({ isProfile = false }: Props) {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const { tenantData } = useContext(AuthContext);
  const elRef = useRef<HTMLDivElement>(null);
  const caloriesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    authContext.setUserData((prevProfile: UserInfo) => ({ ...prevProfile, calories: event.target.value }));
  };

  const setGoal = (status: boolean, item: string): void => {
    let prevGoal = authContext.userData?.goal ?? '';
    if (status) {
      prevGoal = item;
    } else {
      prevGoal = '';
    }

    authContext.setUserData((prevProfile: UserInfo) => ({
      ...prevProfile,
      goal: prevGoal,
    }));
  };

  useEffect(() => {
    authContext.setUserData((prevProfile: UserInfo) => ({
      ...prevProfile,
      goal: prevProfile?.goal ?? '',
    }));
    if (!isProfile) {
      elRef.current!.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={
        authContext.userData?.role === 2 ||
        authContext.userData?.isAdminUser ||
        authContext.userData?.isTrainerUser ||
        authContext.userData?.isLocationAdminUser ||
        isProfile === false ||
        localStorage.getItem('currentUserToken') ||
        tenantData?.settings?.functions?.profileGoal === undefined ||
        tenantData?.settings?.functions?.profileGoal === true
          ? 'space-y-20'
          : 'space-y-20 opacity-25'
      }
    >
      <div ref={elRef}>
        <Headline className="capitalize" centered level={1}>
          {t(stepLists[1].title)}
        </Headline>
      </div>
      {yourGoal.map((item: string, index: number) => (
        <div key={index}>
          <SwitchButton
            label={item}
            isBackground
            isDisabled={
              !(
                authContext.userData?.role === 2 ||
                authContext.userData?.isAdminUser ||
                authContext.userData?.isTrainerUser ||
                authContext.userData?.isLocationAdminUser ||
                isProfile === false ||
                localStorage.getItem('currentUserToken') ||
                tenantData?.settings?.functions?.profileGoal === undefined ||
                tenantData?.settings?.functions?.profileGoal === true
              )
            }
            enabled={authContext.userData?.goal !== undefined && authContext.userData.goal === item}
            onChange={setGoal}
          />
          <Headline className="mt-2" centered level={6}>
            {t(GoalDescription[index])}
          </Headline>
        </div>
      ))}
      <CustomInput
        name="calories"
        type="number"
        label="Dein ausgerechneter Kalorienbedarf"
        value={authContext.userData?.calories}
        onChange={caloriesChange}
      />
    </div>
  );
}

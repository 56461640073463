import React, { ReactNode } from 'react';
import TrialContainer from 'components/TrialContainer';
import styles from './style.module.scss';

type HeaderProps = {
  children: ReactNode;
  showTrialContainer?: boolean;
};

const Header: React.FC<HeaderProps> = ({ children, showTrialContainer = false }) => (
  <header className={styles.header}>
    {showTrialContainer && <TrialContainer />}
    <div className={styles.wrapper}>{children}</div>
  </header>
);

export default Header;

import React, { useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import { FilterContext } from 'providers/FilterProvider';
import Skeleton from 'components/skeleton';
import { toast } from 'react-toast';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './styles.module.scss';

type Props = {
  ingridientState?: any;
  ingridientStateValue?: any;
  editPopup?: any;
  editIngridientObject?: any;
};

const IngredientSection: React.FC<Props> = ({
  ingridientState,
  ingridientStateValue,
  editPopup,
  editIngridientObject,
}) => {
  const { ingredientList } = useContext(MainContext);
  const { nutritionSearchValue } = useContext(FilterContext);
  const [ingridientSectionLength, setIngridientSectionLength] = useState(0);

  const authContext = useContext(AuthContext);
  const { userData, theme } = useContext(AuthContext);

  let thisIngridientList = ingredientList ?? [];

  if (ingridientStateValue.length > 0) {
    thisIngridientList = ingridientStateValue;
  }

  const ingredientFilteredList = useMemo(
    () =>
      ingredientList !== undefined
        ? ingredientList.filter(element => element.name.toLowerCase().includes(nutritionSearchValue.toLowerCase()))
        : [],
    [nutritionSearchValue, ingredientList]
  );

  if (thisIngridientList.length > 0 && thisIngridientList.length !== ingridientSectionLength) {
    setIngridientSectionLength(thisIngridientList.length);
    ingridientState(ingredientList);
  }

  function editIngredient(uid: string) {
    const thisIngredientObject = ingredientList?.filter(element => element.uid === uid) as any;

    if (
      userData?.role === 1 ||
      thisIngredientObject[0].userIngridient === true ||
      (thisIngredientObject[0].communityIngridient === true &&
        (userData?.isAdminUser === true || userData?.isTrainerUser === true || userData?.isLocationAdminUser === true))
    ) {
      editPopup('block');
      editIngridientObject(thisIngredientObject);
    } else {
      toast.warn('Du kannst nur deine eigenen Lebensmittel bearbeiten!');
    }
  }

  return (
    <>
      <div className="font-extralight">{ingredientFilteredList.length} Lebensmittel gefunden</div>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.item}>KCAL</div>
          <div className={styles.item}>KH</div>
          <div className={styles.item}>EW</div>
          <div className={styles.item}>FETT</div>
        </div>
        {/* skeleton loading */}
        {!ingredientList
          ? Array.from(Array(15).keys()).map((_, index) => (
              <div className={styles.contentItem} key={index}>
                <Skeleton className={classNames(styles.imageWrapper, styles.skeletonImage)} translucent />
                <div className={styles.description}>
                  <Skeleton className={styles.skeletonTitle} translucent />
                  <div className={styles.category}>
                    <Skeleton className={styles.skeletonValue} translucent />
                    <Skeleton className={styles.skeletonValue} translucent />
                    <Skeleton className={styles.skeletonValue} translucent />
                    <Skeleton className={styles.skeletonValue} translucent />
                  </div>
                </div>
              </div>
            ))
          : ingredientFilteredList.map((item, index) => (
              <div
                className={styles.contentItem}
                key={index}
                {...(userData?.role === 1
                  ? {
                      onClick: () => {
                        editIngredient(item.uid);
                      },
                    }
                  : item.userIngridient === true
                  ? {
                      onClick: () => {
                        editIngredient(item.uid);
                      },
                    }
                  : item.communityIngridient === true &&
                    (userData?.isAdminUser === true ||
                      userData?.isTrainerUser === true ||
                      userData?.isLocationAdminUser)
                  ? {
                      onClick: () => {
                        editIngredient(item.uid);
                      },
                    }
                  : {})}
                {...(userData?.role === 1
                  ? {
                      onKeyDown: () => {
                        editIngredient(item.uid);
                      },
                    }
                  : item.userIngridient === true
                  ? {
                      onKeyDown: () => {
                        editIngredient(item.uid);
                      },
                    }
                  : item.communityIngridient === true &&
                    (userData?.isAdminUser === true ||
                      userData?.isTrainerUser === true ||
                      userData?.isLocationAdminUser)
                  ? {
                      onKeyDown: () => {
                        editIngredient(item.uid);
                      },
                    }
                  : {})}
                aria-hidden="true"
              >
                <div className={styles.imageWrapper}>
                  <LazyLoadImage
                    alt=""
                    height={75}
                    width={75}
                    src={
                      item.imageUrl.length > 0
                        ? item.imageUrl
                        : theme?.logo !== undefined && userData?.role !== 2
                        ? theme.logo
                        : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
                    }
                    className={styles.image}
                  />
                </div>
                <div className={styles.description}>
                  <div>
                    {item?.userIngridient === true ? (
                      <div className="flex pt-0">
                        <div className="text-10 text-accentColorDark">Eigenes Lebensmittel</div>
                      </div>
                    ) : item?.communityIngridient === true ? (
                      <div className="flex pt-0">
                        <div className="text-10 text-accentColorDark">Community Lebensmittel</div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="text-14">{item.name}</div>
                  </div>
                  <div className={styles.category}>
                    <div className={styles.item}>{Math.round(parseFloat(item.kcal_100g))}</div>
                    <div className={styles.item}>{Math.round(parseFloat(item.carbohydrates_100g))}</div>
                    <div className={styles.item}>{Math.round(parseFloat(item.protein_100g))}</div>
                    <div className={styles.item}>{Math.round(parseFloat(item.fat_100g))}</div>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </>
  );
};

export default IngredientSection;

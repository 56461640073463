import React, { useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useHistory } from 'react-router-dom';

const LoginWithToken = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const userToken = urlParams.get('userToken');
  const redirectTo = urlParams.get('redirectTo');
  const history = useHistory();

  useEffect(() => {
    const loginWithToken = async () => {
      try {
        await firebase.auth().signInWithCustomToken(userToken as string);
        if (redirectTo) {
          history.push(redirectTo);
          return;
        }
        history.push('/');
      } catch (error) {
        console.error('Error logging in:', error);
        history.push('/');
      }
    };

    if (userToken) {
      loginWithToken();
    }
  }, [userToken, history]);

  return <div>Logging in...</div>;
};

export default LoginWithToken;

import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import firebase from 'firebase';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { useHistory, useParams } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import moment from 'moment';
import { getIsWebView } from 'shared/functions/global';
import { AuthContext } from 'providers/AuthProvider';
import { planID } from 'shared/constants/global';
import styles from './styles.module.scss';

type ParamType = {
  type: MembershipType;
};

type PaymentType = {
  customPlanId: string;
  customCouponCode: string | undefined;
  customPrice: number;
  stripeSubscriptionId: string;
  stripePaymentIntendId: string;
};

const ButtonWrapper: React.FC<PaymentType> = ({
  customPlanId,
  customCouponCode,
  customPrice,
  stripeSubscriptionId,
  stripePaymentIntendId,
}) => {
  const db = firebase.firestore();
  const history = useHistory();
  const { type } = useParams<ParamType>();
  const { user, userData, tenant } = useContext(AuthContext);
  const [componentCustomPlanId, setComponentCustomPlanId] = useState(customPlanId);

  const useIAP = getIsWebView();

  useEffect(() => {
    setComponentCustomPlanId(customPlanId);
  }, [customPlanId]);

  const createSubscription = (_: any, actions: any) => {
    return actions.subscription
      .create({
        plan_id: componentCustomPlanId.length > 0 ? componentCustomPlanId : planID[type],
      })
      .then((orderId: string) => {
        return orderId;
      });
  };

  const deleteStripeSubscription = async () => {
    // Delete Stripe Subscription
    await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/cancel-subscription`, {
      subscriptionId: stripeSubscriptionId,
    });
  };

  const deleteStripePaymentIntend = async () => {
    // Delete Stripe Subscription
    await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/cancel-paymentIntend`, {
      invoiceId: stripePaymentIntendId,
    });
  };

  const onApprove = (data: any, actions: any) => {
    return actions.subscription.get().then((res: any) => {
      db.collection(`tenants/${tenant}/users`)
        .doc(user?.uid)
        .update({
          membership: {
            id: res.id,
            activated: true,
            nextDate: moment().unix(),
            payerId: res.subscriber.payer_id,
            paymentMethod: 'paypal',
            rabattCode: customCouponCode !== undefined ? customCouponCode : '',
            type,
          },
        });

      if (
        customCouponCode !== undefined &&
        customCouponCode !== 'extended' &&
        customCouponCode !== 'T50TJ' &&
        customCouponCode !== 'hazel' &&
        customCouponCode !== 'TT30J' &&
        customCouponCode !== '40TTJ' &&
        customCouponCode !== 'TTJ20'
      ) {
        try {
          db.collection('coupons').doc(customCouponCode).update({
            coupon: customCouponCode,
            used: true,
          });
        } catch (error) {
          console.log(error);
        }
      }

      // Delete Stripe Subscription
      try {
        deleteStripeSubscription();
      } catch (error) {
        console.log(error);
      }

      // Delete Stripe Payment Intend
      try {
        deleteStripePaymentIntend();
      } catch (error) {
        console.log(error);
      }

      // Send payment mail to us
      try {
        axios({
          url: `${process.env.REACT_APP_API_URL}/sendPaymentMail`,
          method: 'post',
          data: {
            paymentType: `Paypal - ${type}`,
          },
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        }).then(
          response => {
            console.log(response);
            axios({
              url: `${process.env.REACT_APP_API_URL}/sendPaymentMailToUser`,
              method: 'post',
              data: {
                email: user?.email,
              },
              headers: {
                'content-type': 'application/json',
                Accept: 'application/json',
              },
            }).then(
              response2 => {
                console.log(response2);
              },
              error => {
                console.log(error);
              }
            );
          },
          error => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }

      // Track Social Media Pixel
      if (
        (userData?.trackGoogleAnalytics === true || userData?.withCouponCode) &&
        window.location.hostname === 'app.tortija.de'
      ) {
        ReactPixel.track('Purchase', {
          value: customPrice,
          currency: 'EUR',
        });

        TiktokPixel.track('CompletePayment', {
          content_type: 'product',
          quantity: 1,
          content_id: '1',
          currency: 'EUR',
          description: 'Abo Abschluss',
          value: customPrice,
        });
      }

      if (useIAP) {
        history.push('/');
      } else {
        history.push('/store-landingpage');
      }
    });
  };

  const onError = (error: any) => {
    console.log('error: ', error);
  };

  return (
    <>
      <PayPalButtons
        createSubscription={createSubscription}
        style={{
          label: 'buynow',
          shape: 'pill',
          color: 'silver',
        }}
        className={styles.buttonWrapper}
        onApprove={onApprove}
        onError={onError}
        forceReRender={[componentCustomPlanId]}
      />
    </>
  );
};

export default ButtonWrapper;

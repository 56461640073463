import React, { useContext, useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import LogoImg from 'assets/img/svg/logo.svg';
import Button from 'components/Button';
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  PlusIcon,
  RefreshIcon,
  XIcon,
  ChevronDownIcon,
  BanIcon,
  ChartPieIcon,
  DotsVerticalIcon,
  CheckCircleIcon,
  SwitchHorizontalIcon,
  CalendarIcon,
  EmojiHappyIcon,
  HeartIcon,
  DocumentDuplicateIcon,
  ExternalLinkIcon,
  PencilIcon,
  UserCircleIcon,
  TrashIcon,
  CollectionIcon,
} from '@heroicons/react/outline';
import Input from 'components/Input';
import axios from 'axios';
import Icon from 'components/Icon';
import firebase from 'services/firebase';
import { AuthContext } from 'providers/AuthProvider';
import { emailChecker } from 'services/validations';
import styles from './styles.module.scss';

const LoginZapier: React.FC = () => {
  const { authenticated } = useContext(AuthContext);

  useEffect(() => {
    if (authenticated && loginStatus === 'logOut') {
      setLoginStatus('logIn');
    }
  }, [authenticated]);

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const logo = LogoImg;

  const password = useRef({});
  const location = useLocation();
  password.current = watch('password', '');

  const authContext = useContext(AuthContext);
  const [isLogging, setIsLogging] = useState(false);
  const [loginStatus, setLoginStatus] = useState('logOut');

  const onSubmit = async (data: any) => {
    setIsLogging(true);
    try {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(data.email, data.password);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      authContext.setUser(userCredential.user as firebase.User);

      await axios({
        url: `${process.env.REACT_APP_API_URL}/zapierAuth`,
        method: 'post',
        data: {
          uid: authContext.user?.uid,
        },
        headers: {
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      }).then(
        response => {
          if (response.data.token) {
            console.log(response.data.token);
            const queryParameters = new URLSearchParams(location.search);

            const form = document.createElement('form');
            form.method = 'get';
            form.action = queryParameters.get('redirect_uri') ?? '';

            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = 'state';
            hiddenField.value = queryParameters.get('state') ?? '';

            const hiddenField2 = document.createElement('input');
            hiddenField2.type = 'hidden';
            hiddenField2.name = 'code';
            hiddenField2.value = response.data.token ?? '';

            form.appendChild(hiddenField);
            form.appendChild(hiddenField2);

            document.body.appendChild(form);
            form.submit();
          } else {
            toast.error('Die eingegebenen Daten sind nicht korrekt!');
          }
        },
        error => {
          toast.error('Die eingegebenen Daten sind nicht korrekt!');
          console.log(error);
        }
      );

      setIsLogging(false);
      setLoginStatus('logIn');
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.warn(error.message);
      setIsLogging(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className="flex space-x-30 justify-center">
        <div>
          <img className="" width={100} height={100} src={logo} alt="" />
        </div>
        <div className="my-auto">
          <SwitchHorizontalIcon width={50} height={50} className="text-white mx-auto cursor-pointer" />
        </div>
        <div>
          <Icon name="zapier" width={80} />
        </div>
      </div>
      <div className="text-center pt-20 font-bold text-25">Verbinden Sie Ihren Tortija Account mit Zapier!</div>

      <div className="flex justify-center">
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-20">
            <Input
              name="email"
              type="email"
              label={t('Email')}
              autoComplete="email"
              required
              register={register('email', {
                validate: (value: string) => {
                  if (!value.trim()) {
                    return t('Email Address is required').toString();
                  }
                  return emailChecker(value) || t('Please enter valid email address').toString();
                },
              })}
              error={errors.email}
            />
            <Input
              name="password"
              type="password"
              label={t('Password')}
              autoComplete="password"
              required
              register={register('password', {
                required: t('Please enter your password').toString(),
                validate: (value: string) => {
                  if (!value.trim()) {
                    return t('Please enter your password').toString();
                  }
                  return true;
                },
              })}
              error={errors.password}
            />
          </div>

          <Button disabled={isLogging} isPending={isLogging} className={styles.button}>
            <span className={styles.text}>Anmelden</span>
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LoginZapier;

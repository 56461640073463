import React, { useContext } from 'react';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'providers/AuthProvider';
import styles from './style.module.scss';

type Props = {
  label?: string;
  subLabel?: string;
  enabled?: boolean;
  isBackground?: boolean;
  isCapitalize?: boolean;
  isDisabled?: boolean;
  className?: string;
  notContainer?: boolean;
  onChange?: (status: boolean, item: string) => void;
};

const SwitchButton: React.FC<Props> = ({
  label = '',
  subLabel = '',
  className,
  isBackground = false,
  notContainer = false,
  enabled = false,
  isDisabled = false,
  isCapitalize = true,
  onChange = () => {},
}) => {
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);

  const onChangeEvent = (value: boolean) => {
    onChange(value, label ?? '');
  };

  return (
    <Switch.Group>
      <div
        className={classNames(
          styles.wrapper,
          { [styles.notContainer]: !notContainer },
          { [styles.isBackground]: isBackground },
          className
        )}
      >
        <Switch.Label className={isCapitalize ? styles.label : styles.alternativeLabel}>
          {subLabel !== undefined && subLabel.length > 0 ? (
            <div>
              <div className="text-notActiveButtonTextColor">{t(label.toString())}</div>
              <div className={styles.subLabel}>{subLabel}</div>
            </div>
          ) : (
            <div className={isBackground ? 'text-notActiveButtonTextColor' : ''}>{t(label.toString())}</div>
          )}
        </Switch.Label>
        <Switch disabled={isDisabled} checked={enabled} onChange={onChangeEvent} className={styles.switch}>
          <span aria-hidden="true" className={styles.switchContainer} />
          <span
            aria-hidden="true"
            className={classNames(
              userData?.role === 2 ? styles.switcher : styles.switcherB2B,
              { [styles.enable]: enabled },
              { [styles.disable]: !enabled }
            )}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
};

export default SwitchButton;

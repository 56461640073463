import React, { useContext, useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useHistory, useParams, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  PencilIcon,
  PlusIcon,
  TrashIcon,
  XIcon,
  DocumentDuplicateIcon,
  CalendarIcon,
  RefreshIcon,
  ExclamationIcon,
  SwitchHorizontalIcon,
  ChartPieIcon,
} from '@heroicons/react/outline';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import ReactLoading from 'react-loading';
import _ from 'lodash';
import ButtonBack from 'components/ButtonBack';
import IncompatibilityOverlay from 'components/IncompabilityOverlay';
import Button from 'components/Button';
import GenerateAlghorithmus from 'components/GenerateAlghorithmus';
import SwitchButton from 'components/SwitchButton';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import { formOfNutrition, intolerancesOption } from 'shared/constants/profile-wizard';
import MakroCircles from 'components/MakroCircles';
import firebase from 'services/firebase';
import IngredientSearchPopup from 'components/IngredientSearchPopup';
import { toast } from 'react-toast';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import 'moment/locale/de';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import Checkbox from 'components/Checkbox';
import Select from 'react-select';
import {
  handleOpenClosePopups,
  scrollToElement,
  savePlanChangesToFirebase,
  updateTourToFirebase,
} from 'shared/functions/global';
import { defaultTextColor } from 'layouts/Theme';
import PopupMealItem from './components/PopupMealItem';
import NewRecipeStep1 from '../../Nutrition/components/NewRecipeStep1';
import NewRecipeStep2 from '../../Nutrition/components/NewRecipeStep2';
import NewRecipeStep3 from '../../Nutrition/components/NewRecipeStep3';
import NewRecipeStep4 from '../../Nutrition/components/NewRecipeStep4';
import NewRecipeStep5 from '../../Nutrition/components/NewRecipeStep5';
import NewRecipeStep6 from '../../Nutrition/components/NewRecipeStep6';
import ClosePopup from '../../Nutrition/components/ClosePopup';
import EditMealCard from './components/EditMealCard';

import styles from './styles.module.scss';

registerLocale('de', de);

type ParamsType = {
  id: string;
};

const PlanEdit: React.FC = () => {
  const { id } = useParams<ParamsType>();
  const { t } = useTranslation();

  const moment = extendMoment(Moment);
  const location = useLocation<LocationState>();
  const [currentDayId, setCurrentDayId] = useState(1);
  const plan = useContext(MainContext).planList?.find(item => item.uid === id);
  const favoritesPlansList = useContext(MainContext).favoritesPlansList?.find(item => item.origId === id);
  const authContext = useContext(AuthContext);
  const { userData, tenant, theme, tenantData } = useContext(AuthContext);
  const { ingredientList, planList, userPlanList } = useContext(MainContext);
  const [currentPlan, setCurrentPlan] = useState(plan) as any;
  const currentDayItem = currentPlan?.dayEntries.filter((item: any) => item.id === currentDayId)[0];
  const { tempRecipeObject } = useContext(MainContext);

  const [excludeDateArray, setExcludeDateArray] = useState<any>([]);

  const tourRef = useRef<HTMLDivElement>(null);
  const tourRefMobile = useRef<HTMLDivElement>(null);

  const [currentCopyMealItem, setCurrentCopyMealItem] = useState({
    kcal_total: 0,
    carbohydrates_total: 0,
    protein_total: 0,
    fat_total: 0,
    recipes: [],
    ingredients: [],
  });

  const history = useHistory();

  const [planFavorite, setPlanFavorite] = useState(false);

  const [currentMakroChangeCarbohydratesPercent, setCurrentMakroChangeCarbohydratesPercent] = useState('');
  const [currentMakroChangeProteinPercent, setCurrentMakroChangeProteinPercent] = useState('');
  const [currentMakroChangeFatPercent, setCurrentMakroChangeFatPercent] = useState('');

  const [visibleIncompatibilityPopup, setVisibleIncompatibilityPopup] = useState(false);

  const [planGenerateType, setPlanGenerateType] = useState('day');

  const [planDateArray, setPlanDateArray] = useState([]) as any;

  const [popupDynamicAddCopyClass, setPopupDynamicAddCopyClass] = useState('hidden');
  const [startDateIndexPage, setStartDateIndexPage] = useState(null);
  const changeCopyDateIndexPage = (dates: any) => {
    setStartDateIndexPage(dates);
  };
  const CustomInputIndexPage = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <div className="flex w-full">
          <div className="flex-1">
            <input {...props} />
          </div>
          <div className="ml-10 my-auto">
            <CalendarIcon width={25} height={25} className="text-accentColor mx-auto cursor-pointer" />
          </div>
        </div>
      </label>
    );
  };

  const CustomInputChangeDuration = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <div className="flex w-full">
          <div className="flex-1">
            <input {...props} />
          </div>
          <div className="ml-10 my-auto">
            <CalendarIcon width={25} height={25} className="text-accentColor mx-auto cursor-pointer" />
          </div>
        </div>
      </label>
    );
  };
  const [currentCopyMealTypeValueIndexPage, setCurrentCopyMealTypeValueIndexPage] = useState('breakfast');

  const [editFormOfNutritionOverlayClass, setEditFormOfNutritionOverlayClass] = useState('hidden');

  const [showIncombalitiesOverlayClass, setShowIncombalitiesOverlayClass] = useState('hidden');

  const [moveRecipePopupClass, setMoveRecipePopupClass] = useState('hidden');

  const [copyRecipePopupClass, setCopyRecipePopupClass] = useState('hidden');

  const [changePlanDurationPopupClass, setChangePlanDurationPopupClass] = useState('hidden');

  const [openRecipeOnload, setOpenRecipeOnload] = useState(false);

  const [currentCarbohydratesPercent, setCurrentCarbohydratesPercent] = useState('');
  const [currentProteinPercent, setCurrentProteinPercent] = useState('');
  const [currentFatPercent, setCurrentFatPercent] = useState('');

  const [changeCaloriesNeed, setChangeCaloriesNeed] = useState(false);

  const [currentStep, setCurrentStep] = useState('1');
  const [currentAddRecipe, setCurrentAddRecipe] = useState<any>({
    ingredients: [],
    description: [],
    kcal_total: '0',
    carbohydrates_total: '0',
    protein_total: '0',
    fat_total: '0',
    vegetarian: false,
    ketogen: false,
    vegan: false,
  });
  const mealTypeValues: any = [
    { value: 'breakfast', label: 'Frühstück' },
    { value: 'lunch', label: 'Mittagessen' },
    { value: 'dinner', label: 'Abendessen' },
    { value: 'snacks', label: 'Snacks' },
  ];
  const copyTypeValues: any = [
    { value: 'date', label: 'Auf ein bestimmtes Datum kopieren' },
    { value: 'all', label: 'In jeden Tag kopieren' },
  ];
  const makroTypes: any = [
    { value: 'standard', label: 'Standard', carbohydrates: '50', protein: '25', fat: '25' },
    { value: 'ketogen', label: 'Ketogen', carbohydrates: '5', protein: '30', fat: '65' },
    { value: 'user', label: 'Benutzerdefiniert' },
  ];
  const [currentMoveMealTypeValue, setCurrentMoveMealTypeValue] = useState('breakfast');
  const [newRecipeOverlayClass, setNewRecipeOverlayClass] = useState('hidden');
  const [closeRecipeOverlayClass, setCloseRecipeOverlayClass] = useState('hidden');
  const [ingredientSearchPopupClass, setIngredientSearchPopupClass] = useState('hidden');
  const [moveIngredientPopupClass, setMoveIngredientPopupClass] = useState('hidden');
  const [copyIngredientPopupClass, setCopyIngredientPopupClass] = useState('hidden');
  const [copyDayPopupClass, setCopyDayPopupClass] = useState('hidden');
  const [recipeChangePopupClass, setRecipeChangePopupClass] = useState('hidden');
  const [userMacroPopupClass, setUserMacroPopupClass] = useState('hidden');
  const [userMacroDayPopupClass, setUserMacroDayPopupClass] = useState('hidden');
  const [clearDayPopupClass, setClearDayPopupClass] = useState('hidden');
  const [generateDayPopupClass, setGenerateDayPopupClass] = useState('hidden');

  const [currentPlanName, setCurrentPlanName] = useState(currentPlan?.name);

  const [currentMealType, setCurrentMealType] = useState('breakfast');
  const [currentMealLabel, setCurrentMealLabel] = useState('Frühstück');
  const [currentChangeItemId, setCurrentChangeItemId] = useState(0);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const changeCopyDate = (dates: any) => {
    setStartDate(dates);
  };
  const CustomCalendarInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <div className="flex w-full">
          <div className="flex-1">
            <input {...props} />
          </div>
          <div className="ml-10 my-auto">
            <CalendarIcon width={25} height={25} className="text-accentColor mx-auto cursor-pointer" />
          </div>
        </div>
      </label>
    );
  };
  const [currentCopyTypeValue, setCurrentCopyTypeValue] = useState('date');
  const [currentCopyMealTypeValue, setCurrentCopyMealTypeValue] = useState('breakfast');

  const [currentDayBreakfastImage, setCurrentDayBreakfastImage] = useState('');
  const [currentDayBreakfastMealLabel, setCurrentDayBreakfastMealLabel] = useState('');
  const [currentDayBreakfastMealCounter, setCurrentDayBreakfastMealCounter] = useState(0);
  const [currentDayBreakfastType, setCurrentDayBreakfastType] = useState('add');
  const [currentDayBreakfastKcalTotal, setCurrentDayBreakfastKcalTotal] = useState(0);
  const [currentDayBreakfastCarbohydratesTotal, setCurrentDayBreakfastCarbohydratesTotal] = useState(0);
  const [currentDayBreakfastProteinTotal, setCurrentDayBreakfastProteinTotal] = useState(0);
  const [currentDayBreakfastFatTotal, setCurrentDayBreakfastFatTotal] = useState(0);

  const [currentDayLunchImage, setCurrentDayLunchImage] = useState('');
  const [currentDayLunchMealLabel, setCurrentDayLunchMealLabel] = useState('');
  const [currentDayLunchMealCounter, setCurrentDayLunchMealCounter] = useState(0);
  const [currentDayLunchType, setCurrentDayLunchType] = useState('add');
  const [currentDayLunchKcalTotal, setCurrentDayLunchKcalTotal] = useState(0);
  const [currentDayLunchCarbohydratesTotal, setCurrentDayLunchCarbohydratesTotal] = useState(0);
  const [currentDayLunchProteinTotal, setCurrentDayLunchProteinTotal] = useState(0);
  const [currentDayLunchFatTotal, setCurrentDayLunchFatTotal] = useState(0);

  const [currentDayDinnerImage, setCurrentDayDinnerImage] = useState('');
  const [currentDayDinnerMealLabel, setCurrentDayDinnerMealLabel] = useState('');
  const [currentDayDinnerMealCounter, setCurrentDayDinnerMealCounter] = useState(0);
  const [currentDayDinnerType, setCurrentDayDinnerType] = useState('add');
  const [currentDayDinnerKcalTotal, setCurrentDayDinnerKcalTotal] = useState(0);
  const [currentDayDinnerCarbohydratesTotal, setCurrentDayDinnerCarbohydratesTotal] = useState(0);
  const [currentDayDinnerProteinTotal, setCurrentDayDinnerProteinTotal] = useState(0);
  const [currentDayDinnerFatTotal, setCurrentDayDinnerFatTotal] = useState(0);

  const [currentDaySnacksImage, setCurrentDaySnacksImage] = useState('');
  const [currentDaySnacksMealLabel, setCurrentDaySnacksMealLabel] = useState('');
  const [currentDaySnacksMealCounter, setCurrentDaySnacksMealCounter] = useState(0);
  const [currentDaySnacksType, setCurrentDaySnacksType] = useState('add');
  const [currentDaySnacksKcalTotal, setCurrentDaySnacksKcalTotal] = useState(0);
  const [currentDaySnacksCarbohydratesTotal, setCurrentDaySnacksCarbohydratesTotal] = useState(0);
  const [currentDaySnacksProteinTotal, setCurrentDaySnacksProteinTotal] = useState(0);
  const [currentDaySnacksFatTotal, setCurrentDaySnacksFatTotal] = useState(0);

  const [viewMealPopupClass, setViewMealPopupClass] = useState('hidden');

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollContainerMacroCircles = useRef<HTMLDivElement>(null);
  const recipePopupContentRef = useRef<HTMLDivElement>(null);
  const changeMakroCarbohydrateRef = useRef<HTMLInputElement>(null);
  const changeMakroProteinRef = useRef<HTMLInputElement>(null);
  const changeMakroFatRef = useRef<HTMLInputElement>(null);
  const changeMakroCaloriesRef = useRef<HTMLInputElement>(null);
  const changeMakroCaloriesDayRef = useRef<HTMLInputElement>(null);
  const sliderRef = useRef<any>(null);
  const sliderRefMobile = useRef<any>(null);
  const sliderContainerRef = useRef<HTMLDivElement>(null);
  const sliderContainerRefMobile = useRef<HTMLDivElement>(null);

  const [currentCopyTypeValueIndexPage, setCurrentCopyTypeValueIndexPage] = useState('date');
  const copyTypeValuesIndexPage: any = [
    { value: 'date', label: 'Auf ein bestimmtes Datum kopieren' },
    { value: 'all', label: 'In jeden Tag kopieren' },
  ];
  const mealTypeValuesIndexPage: any = [
    { value: 'breakfast', label: 'Frühstück' },
    { value: 'lunch', label: 'Mittagessen' },
    { value: 'dinner', label: 'Abendessen' },
    { value: 'snacks', label: 'Snacks' },
  ];

  const [currentMakroChangeCalories, setCurrentMakroChangeCalories] = useState<any>(0);
  const [currentMakroChangeCarbohydrates, setCurrentMakroChangeCarbohydrates] = useState<any>(0);
  const [currentMakroChangeProtein, setCurrentMakroChangeProtein] = useState<any>(0);
  const [currentMakroChangeFat, setCurrentMakroChangeFat] = useState<any>(0);
  const [currentMakroChangeType, setCurrentMakroChangeType] = useState('standard');

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    centerMode: true,
    dotsClass: 'slick-dots text-textColor',
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          centerMode: false,
          adaptiveHeight: true,
        },
      },
    ],
  };

  const mainLeftColumnRef = useRef<HTMLDivElement>(null);

  const changePlanDay = (dayId: number) => {
    setCurrentDayId(dayId);

    getMealImage('breakfast', dayId);
    getMealImage('lunch', dayId);
    getMealImage('dinner', dayId);
    getMealImage('snacks', dayId);

    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }

    if (sliderRefMobile.current) {
      sliderRefMobile.current.slickGoTo(0);
    }
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
    },
    scrollTo: true,
    classes: 'shepherd-theme-dark',
    useModalOverlay: true,
  };

  function TourButton() {
    const tour = useContext(ShepherdTourContext);

    return (
      <div
        className="hidden"
        ref={tourRef}
        onClick={() => userData?.role === 2 && tour?.start()}
        onKeyDown={() => userData?.role === 2 && tour?.start()}
        aria-hidden="true"
      >
        <div>Test</div>
      </div>
    );
  }

  function TourButtonMobile() {
    const tourMobile = useContext(ShepherdTourContext);

    return (
      <div
        className="hidden"
        ref={tourRefMobile}
        onClick={() => userData?.role === 2 && tourMobile?.start()}
        onKeyDown={() => userData?.role === 2 && tourMobile?.start()}
        aria-hidden="true"
      >
        <div>Test</div>
      </div>
    );
  }

  function copyMeal() {
    const getYear = moment(startDateIndexPage).format('YYYY');
    const getMonth = moment(startDateIndexPage).format('MM');
    const getDay = moment(startDateIndexPage).format('DD');
    const mergedDate = getYear + getMonth + getDay;
    let planExists = false;
    let thisCopyDayId = 0;

    let thisCopyTypeValue = currentCopyMealTypeValueIndexPage;
    if (thisCopyTypeValue === undefined || thisCopyTypeValue.length === 0) {
      thisCopyTypeValue = 'breakfast';
    }

    if (currentCopyTypeValueIndexPage === 'date') {
      const dayExistsInPlan = currentPlan?.dayEntries?.filter((item: any) => {
        const getItemYear = moment.unix(item.date?.seconds).utc().format('YYYY');
        const getItemMonth = moment.unix(item.date?.seconds).utc().format('MM');
        const getItemDay = moment.unix(item.date?.seconds).utc().format('DD');
        const mergedItemDate = getItemYear + getItemMonth + getItemDay;

        if (mergedItemDate === mergedDate) {
          planExists = true;
          thisCopyDayId = item.id;
          return true;
        }
        return false;
      });

      if (planExists && currentPlan !== undefined) {
        handleOpenClosePopups(setPopupDynamicAddCopyClass, '', 'close');

        const thisGoalElement = currentPlan?.dayEntries.filter((item: any) => item.id === thisCopyDayId)[0];
        const thisKcalTotalGoalElement = thisGoalElement[currentCopyMealTypeValueIndexPage].kcal_total;
        const thisCarbohydratesTotalGoalElement =
          thisGoalElement[currentCopyMealTypeValueIndexPage].carbohydrates_total;
        const thisProteinTotalGoalElement = thisGoalElement[currentCopyMealTypeValueIndexPage].protein_total;
        const thisFatTotalGoalElement = thisGoalElement[currentCopyMealTypeValueIndexPage].fat_total;

        let thisCalculatedKcal = 0;
        let thisCalculatedCarbohydrates = 0;
        let thisCalculatedProtein = 0;
        let thisCalculatedFat = 0;

        if (parseFloat(thisGoalElement.kcal_total) - parseFloat(thisKcalTotalGoalElement) > 0) {
          thisCalculatedKcal = parseFloat(thisGoalElement.kcal_total) - parseFloat(thisKcalTotalGoalElement);
        }

        if (parseFloat(thisGoalElement.carbohydrates_total) - parseFloat(thisCarbohydratesTotalGoalElement) > 0) {
          thisCalculatedCarbohydrates =
            parseFloat(thisGoalElement.carbohydrates_total) - parseFloat(thisCarbohydratesTotalGoalElement);
        }

        if (parseFloat(thisGoalElement.protein_total) - parseFloat(thisProteinTotalGoalElement) > 0) {
          thisCalculatedProtein = parseFloat(thisGoalElement.protein_total) - parseFloat(thisProteinTotalGoalElement);
        }

        if (parseFloat(thisGoalElement.fat_total) - parseFloat(thisFatTotalGoalElement) > 0) {
          thisCalculatedFat = parseFloat(thisGoalElement.fat_total) - parseFloat(thisFatTotalGoalElement);
        }

        const newColumnsAdd = {
          ...currentPlan,
          dayEntries: currentPlan.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== thisCopyDayId) return entries;
            return {
              ...entries,
              kcal_total: thisCalculatedKcal + currentCopyMealItem.kcal_total,
              carbohydrates_total: thisCalculatedCarbohydrates + currentCopyMealItem.carbohydrates_total,
              protein_total: thisCalculatedProtein + currentCopyMealItem.protein_total,
              fat_total: thisCalculatedFat + currentCopyMealItem.fat_total,
              [currentCopyMealTypeValueIndexPage]: {
                ...currentCopyMealItem,
                label: entries[currentCopyMealTypeValueIndexPage].label,
              },
            };
          }),
        };

        toast.success('Die Mahlzeit wurde erfolgreich kopiert!');
        savePlanChangesToFirebase(
          userData,
          newColumnsAdd,
          newColumnsAdd.uid,
          authContext.user?.uid,
          false,
          setCurrentPlan,
          tenant
        );
        // setCurrentPlan(newColumnsAdd);
        scrollToElement(scrollContainerMacroCircles);
        setCurrentCopyMealTypeValueIndexPage('breakfast');
        setCurrentCopyTypeValueIndexPage('date');
      } else {
        toast.error('Dieses Datum existiert nicht in dem Plan. Bitte wähle ein anderes Datum!');
      }
    } else if (currentCopyTypeValueIndexPage === 'all') {
      handleOpenClosePopups(setPopupDynamicAddCopyClass, '', 'close');

      const newColumnsAdd = {
        ...currentPlan,
        dayEntries: currentPlan?.dayEntries.map((entries: any) => {
          let thisCalculatedKcal = 0;
          let thisCalculatedCarbohydrates = 0;
          let thisCalculatedProtein = 0;
          let thisCalculatedFat = 0;

          if (parseFloat(entries.kcal_total) - parseFloat(entries[currentCopyMealTypeValueIndexPage].kcal_total) > 0) {
            thisCalculatedKcal =
              parseFloat(entries.kcal_total) - parseFloat(entries[currentCopyMealTypeValueIndexPage].kcal_total);
          }

          if (
            parseFloat(entries.carbohydrates_total) -
              parseFloat(entries[currentCopyMealTypeValueIndexPage].carbohydrates_total) >
            0
          ) {
            thisCalculatedCarbohydrates =
              parseFloat(entries.carbohydrates_total) -
              parseFloat(entries[currentCopyMealTypeValueIndexPage].carbohydrates_total);
          }

          if (
            parseFloat(entries.protein_total) - parseFloat(entries[currentCopyMealTypeValueIndexPage].protein_total) >
            0
          ) {
            thisCalculatedProtein =
              parseFloat(entries.protein_total) - parseFloat(entries[currentCopyMealTypeValueIndexPage].protein_total);
          }

          if (parseFloat(entries.fat_total) - parseFloat(entries[currentCopyMealTypeValueIndexPage].fat_total) > 0) {
            thisCalculatedFat =
              parseFloat(entries.fat_total) - parseFloat(entries[currentCopyMealTypeValueIndexPage].fat_total);
          }
          if (parseFloat(entries.id) === currentDayId) return entries;
          return {
            ...entries,
            kcal_total: thisCalculatedKcal + currentCopyMealItem.kcal_total,
            carbohydrates_total: thisCalculatedCarbohydrates + currentCopyMealItem.carbohydrates_total,
            protein_total: thisCalculatedProtein + currentCopyMealItem.protein_total,
            fat_total: thisCalculatedFat + currentCopyMealItem.fat_total,
            [currentCopyMealTypeValueIndexPage]: {
              ...currentCopyMealItem,
            },
          };
        }),
      };

      toast.success('Das Rezept wurde erfolgreich in jeden Tag kopiert!');
      savePlanChangesToFirebase(
        userData,
        newColumnsAdd,
        newColumnsAdd.uid,
        authContext.user?.uid,
        false,
        setCurrentPlan,
        tenant
      );
      // setCurrentPlan(newColumnsAdd);
      setCurrentCopyMealTypeValueIndexPage('breakfast');
      setCurrentCopyTypeValueIndexPage('date');
    }
  }

  function copyRecipe() {
    const getYear = moment(startDate).format('YYYY');
    const getMonth = moment(startDate).format('MM');
    const getDay = moment(startDate).format('DD');
    const mergedDate = getYear + getMonth + getDay;
    let planExists = false;
    let thisCopyDayId = 0;

    let thisCopyTypeValue = currentCopyMealTypeValue;
    if (thisCopyTypeValue === undefined || thisCopyTypeValue.length === 0) {
      thisCopyTypeValue = 'breakfast';
    }

    if (currentCopyTypeValue === 'date') {
      const dayExistsInPlan = currentPlan?.dayEntries?.filter((item: any) => {
        const getItemYear = moment.unix(item.date?.seconds).utc().format('YYYY');
        const getItemMonth = moment.unix(item.date?.seconds).utc().format('MM');
        const getItemDay = moment.unix(item.date?.seconds).utc().format('DD');
        const mergedItemDate = getItemYear + getItemMonth + getItemDay;

        if (mergedItemDate === mergedDate) {
          planExists = true;
          thisCopyDayId = item.id;
          return true;
        }
        return false;
      });

      if (planExists) {
        handleOpenClosePopups(setCopyRecipePopupClass, '', 'close');
        const thisItemType = currentDayItem?.[currentMealType].recipes.filter(
          (item: any) => item.id === currentChangeItemId
        )[0];

        const newColumnsAdd = {
          ...currentPlan,
          dayEntries: currentPlan.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== thisCopyDayId) return entries;
            return {
              ...entries,
              kcal_total: parseFloat(entries.kcal_total) + parseFloat(thisItemType.kcal_total),
              carbohydrates_total:
                parseFloat(entries.carbohydrates_total) + parseFloat(thisItemType.carbohydrates_total),
              protein_total: parseFloat(entries.protein_total) + parseFloat(thisItemType.protein_total),
              fat_total: parseFloat(entries.fat_total) + parseFloat(thisItemType.fat_total),
              [thisCopyTypeValue]: {
                ...entries[thisCopyTypeValue],
                kcal_total: parseFloat(entries[thisCopyTypeValue].kcal_total) + parseFloat(thisItemType.kcal_total),
                carbohydrates_total:
                  parseFloat(entries[thisCopyTypeValue].carbohydrates_total) +
                  parseFloat(thisItemType.carbohydrates_total),
                protein_total:
                  parseFloat(entries[thisCopyTypeValue].protein_total) + parseFloat(thisItemType.protein_total),
                fat_total: parseFloat(entries[thisCopyTypeValue].fat_total) + parseFloat(thisItemType.fat_total),
                recipes: [...entries[thisCopyTypeValue].recipes, thisItemType],
              },
            };
          }),
        };

        const newColumnsSetNewIds = {
          ...newColumnsAdd,
          dayEntries: newColumnsAdd.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== thisCopyDayId) return entries;
            return {
              ...entries,
              [thisCopyTypeValue]: {
                ...entries[thisCopyTypeValue],
                recipes: entries[thisCopyTypeValue].recipes.map((item: any, index: any) => {
                  return {
                    ...item,
                    id: index,
                  };
                }),
              },
            };
          }),
        };

        toast.success('Das Rezept wurde erfolgreich kopiert!');
        savePlanChangesToFirebase(
          userData,
          newColumnsSetNewIds,
          newColumnsSetNewIds.uid,
          authContext.user?.uid,
          false,
          setCurrentPlan,
          tenant
        );
        // setCurrentPlan(newColumnsSetNewIds);
        setCurrentCopyMealTypeValue('breakfast');
        setCurrentCopyTypeValue('date');
      } else {
        toast.error('Dieses Datum existiert nicht in dem Plan. Bitte wähle ein anderes Datum!');
      }
    } else if (currentCopyTypeValue === 'all') {
      handleOpenClosePopups(setCopyRecipePopupClass, '', 'close');
      const thisItemType = currentDayItem?.[currentMealType].recipes.filter(
        (item: any) => item.id === currentChangeItemId
      )[0];

      const newColumnsAdd = {
        ...currentPlan,
        dayEntries: currentPlan.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) === currentDayId) return entries;
          return {
            ...entries,
            kcal_total: parseFloat(entries.kcal_total) + parseFloat(thisItemType.kcal_total),
            carbohydrates_total: parseFloat(entries.carbohydrates_total) + parseFloat(thisItemType.carbohydrates_total),
            protein_total: parseFloat(entries.protein_total) + parseFloat(thisItemType.protein_total),
            fat_total: parseFloat(entries.fat_total) + parseFloat(thisItemType.fat_total),
            [thisCopyTypeValue]: {
              ...entries[thisCopyTypeValue],
              kcal_total: parseFloat(entries[thisCopyTypeValue].kcal_total) + parseFloat(thisItemType.kcal_total),
              carbohydrates_total:
                parseFloat(entries[thisCopyTypeValue].carbohydrates_total) +
                parseFloat(thisItemType.carbohydrates_total),
              protein_total:
                parseFloat(entries[thisCopyTypeValue].protein_total) + parseFloat(thisItemType.protein_total),
              fat_total: parseFloat(entries[thisCopyTypeValue].fat_total) + parseFloat(thisItemType.fat_total),
              recipes: [...entries[thisCopyTypeValue].recipes, thisItemType],
            },
          };
        }),
      };

      const newColumnsSetNewIds = {
        ...newColumnsAdd,
        dayEntries: newColumnsAdd.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) === currentDayId) return entries;
          return {
            ...entries,
            [thisCopyTypeValue]: {
              ...entries[thisCopyTypeValue],
              recipes: entries[thisCopyTypeValue].recipes.map((item: any, index: any) => {
                return {
                  ...item,
                  id: index,
                };
              }),
            },
          };
        }),
      };

      toast.success('Das Rezept wurde erfolgreich in jeden Tag kopiert!');
      savePlanChangesToFirebase(
        userData,
        newColumnsSetNewIds,
        newColumnsSetNewIds.uid,
        authContext.user?.uid,
        false,
        setCurrentPlan,
        tenant
      );
      // setCurrentPlan(newColumnsSetNewIds);
      setCurrentCopyMealTypeValue('breakfast');
      setCurrentCopyTypeValue('date');
    }
  }

  function moveRecipe() {
    handleOpenClosePopups(setMoveRecipePopupClass, '', 'close');

    let thisMoveTypeValue = currentMoveMealTypeValue;
    if (thisMoveTypeValue === undefined || thisMoveTypeValue.length === 0) {
      thisMoveTypeValue = 'breakfast';
    }

    const thisItemType = currentDayItem?.[currentMealType].recipes.filter(
      (item: any) => item.id === currentChangeItemId
    )[0];

    if (currentMealType !== thisMoveTypeValue && thisItemType !== undefined) {
      const newColumns = {
        ...currentPlan,
        dayEntries: currentPlan?.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== currentDayId) return entries;
          return {
            ...entries,
            [currentMealType]: {
              ...entries[currentMealType],
              kcal_total:
                entries[currentMealType].kcal_total - thisItemType.kcal_total < 0
                  ? 0
                  : entries[currentMealType].kcal_total - thisItemType.kcal_total,
              carbohydrates_total:
                entries[currentMealType].carbohydrates_total - thisItemType.carbohydrates_total < 0
                  ? 0
                  : entries[currentMealType].carbohydrates_total - thisItemType.carbohydrates_total,
              protein_total:
                entries[currentMealType].protein_total - thisItemType.protein_total < 0
                  ? 0
                  : entries[currentMealType].protein_total - thisItemType.protein_total,
              fat_total:
                entries[currentMealType].fat_total - thisItemType.fat_total < 0
                  ? 0
                  : entries[currentMealType].fat_total - thisItemType.fat_total,
              recipes: [
                ...entries[currentMealType].recipes.filter(
                  (item: any) => parseFloat(item.id) !== parseFloat(thisItemType.id)
                ),
              ],
            },
          };
        }),
      };

      const newColumnsAdd = {
        ...newColumns,
        dayEntries: newColumns.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== currentDayId) return entries;
          return {
            ...entries,
            [thisMoveTypeValue]: {
              ...entries[thisMoveTypeValue],
              kcal_total: parseFloat(entries[thisMoveTypeValue].kcal_total) + parseFloat(thisItemType.kcal_total),
              carbohydrates_total:
                parseFloat(entries[thisMoveTypeValue].carbohydrates_total) +
                parseFloat(thisItemType.carbohydrates_total),
              protein_total:
                parseFloat(entries[thisMoveTypeValue].protein_total) + parseFloat(thisItemType.protein_total),
              fat_total: parseFloat(entries[thisMoveTypeValue].fat_total) + parseFloat(thisItemType.fat_total),
              recipes: [...entries[thisMoveTypeValue].recipes, thisItemType],
            },
          };
        }),
      };

      const newColumnsSetNewIds = {
        ...newColumnsAdd,
        dayEntries: newColumnsAdd.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== currentDayId) return entries;
          return {
            ...entries,
            [thisMoveTypeValue]: {
              ...entries[thisMoveTypeValue],
              recipes: entries[thisMoveTypeValue].recipes.map((item: any, index: any) => {
                return {
                  ...item,
                  id: index,
                };
              }),
            },
          };
        }),
      };

      toast.success('Das Rezept wurde erfolgreich verschoben!');
      savePlanChangesToFirebase(
        userData,
        newColumnsSetNewIds,
        newColumnsSetNewIds.uid,
        authContext.user?.uid,
        false,
        setCurrentPlan,
        tenant
      );
      // setCurrentPlan(newColumnsSetNewIds);
      setCurrentMoveMealTypeValue('breakfast');
    } else {
      toast.error('Bitte wähle eine andere Mahlzeit aus');
    }
  }

  function closeRecipePopup() {
    setNewRecipeOverlayClass('hidden');
    setCloseRecipeOverlayClass('block');
  }

  function closeRecipe() {
    setCloseRecipeOverlayClass('hidden');
  }

  function reOpenRecipe() {
    setCloseRecipeOverlayClass('hidden');
    setNewRecipeOverlayClass('block');
  }

  const getMealImage = (type: string, dayId: number) => {
    if (type === 'breakfast') {
      const thisBreakfastItem = currentPlan?.dayEntries.filter((item: any) => item.id === dayId)[0].breakfast;

      let thisCounter = 0;
      if (thisBreakfastItem) {
        if (thisBreakfastItem.recipes.length > 0 || thisBreakfastItem.ingredients.length > 0) {
          setCurrentDayBreakfastType('show');
          if (thisBreakfastItem.recipes.length > 0) {
            setCurrentDayBreakfastImage(thisBreakfastItem.recipes[0].imageUrl);
            setCurrentDayBreakfastMealLabel(thisBreakfastItem.recipes[0].name);
            thisCounter += thisBreakfastItem.recipes.length;
          }
          if (thisBreakfastItem.ingredients.length > 0) {
            thisCounter += thisBreakfastItem.ingredients.length;
          }
          if (thisBreakfastItem.ingredients.length > 0 && thisBreakfastItem.recipes.length === 0) {
            setCurrentDayBreakfastImage(thisBreakfastItem.ingredients[0].imageUrl);
            setCurrentDayBreakfastMealLabel(thisBreakfastItem.ingredients[0].name);
          }
        } else {
          setCurrentDayBreakfastImage('');
          setCurrentDayBreakfastMealLabel('');
          setCurrentDayBreakfastType('add');
        }

        setCurrentDayBreakfastKcalTotal(thisBreakfastItem.kcal_total);
        setCurrentDayBreakfastCarbohydratesTotal(thisBreakfastItem.carbohydrates_total);
        setCurrentDayBreakfastProteinTotal(thisBreakfastItem.protein_total);
        setCurrentDayBreakfastFatTotal(thisBreakfastItem.fat_total);
        setCurrentDayBreakfastMealCounter(thisCounter - 1);
      }
    }

    if (type === 'lunch') {
      const thisLunchItem = currentPlan?.dayEntries.filter((item: any) => item.id === dayId)[0].lunch;

      let thisCounter = 0;
      if (thisLunchItem) {
        if (thisLunchItem.recipes.length > 0 || thisLunchItem.ingredients.length > 0) {
          setCurrentDayLunchType('show');
          if (thisLunchItem.recipes.length > 0) {
            setCurrentDayLunchImage(thisLunchItem.recipes[0].imageUrl);
            setCurrentDayLunchMealLabel(thisLunchItem.recipes[0].name);
            thisCounter += thisLunchItem.recipes.length;
          }
          if (thisLunchItem.ingredients.length > 0) {
            thisCounter += thisLunchItem.ingredients.length;
          }
          if (thisLunchItem.ingredients.length > 0 && thisLunchItem.recipes.length === 0) {
            setCurrentDayLunchImage(thisLunchItem.ingredients[0].imageUrl);
            setCurrentDayLunchMealLabel(thisLunchItem.ingredients[0].name);
          }
        } else {
          setCurrentDayLunchImage('');
          setCurrentDayLunchMealLabel('');
          setCurrentDayLunchType('add');
        }

        setCurrentDayLunchKcalTotal(thisLunchItem.kcal_total);
        setCurrentDayLunchCarbohydratesTotal(thisLunchItem.carbohydrates_total);
        setCurrentDayLunchProteinTotal(thisLunchItem.protein_total);
        setCurrentDayLunchFatTotal(thisLunchItem.fat_total);
        setCurrentDayLunchMealCounter(thisCounter - 1);
      }
    }

    if (type === 'dinner') {
      const thisDinnerItem = currentPlan?.dayEntries.filter((item: any) => item.id === dayId)[0].dinner!;

      let thisCounter = 0;
      if (thisDinnerItem) {
        if (thisDinnerItem.recipes.length > 0 || thisDinnerItem.ingredients.length > 0) {
          setCurrentDayDinnerType('show');
          if (thisDinnerItem.recipes.length > 0) {
            setCurrentDayDinnerImage(thisDinnerItem.recipes[0].imageUrl);
            setCurrentDayDinnerMealLabel(thisDinnerItem.recipes[0].name);
            thisCounter += thisDinnerItem.recipes.length;
          }
          if (thisDinnerItem.ingredients.length > 0) {
            thisCounter += thisDinnerItem.ingredients.length;
          }
          if (thisDinnerItem.ingredients.length > 0 && thisDinnerItem.recipes.length === 0) {
            setCurrentDayDinnerImage(thisDinnerItem.ingredients[0].imageUrl);
            setCurrentDayDinnerMealLabel(thisDinnerItem.ingredients[0].name);
          }
        } else {
          setCurrentDayDinnerImage('');
          setCurrentDayDinnerMealLabel('');
          setCurrentDayDinnerType('add');
        }

        setCurrentDayDinnerKcalTotal(thisDinnerItem.kcal_total);
        setCurrentDayDinnerCarbohydratesTotal(thisDinnerItem.carbohydrates_total);
        setCurrentDayDinnerProteinTotal(thisDinnerItem.protein_total);
        setCurrentDayDinnerFatTotal(thisDinnerItem.fat_total);
        setCurrentDayDinnerMealCounter(thisCounter - 1);
      }
    }

    if (type === 'snacks') {
      const thisSnacksItem = currentPlan?.dayEntries.filter((item: any) => item.id === dayId)[0].snacks;

      let thisCounter = 0;
      if (thisSnacksItem) {
        if (thisSnacksItem.recipes.length > 0 || thisSnacksItem.ingredients.length > 0) {
          setCurrentDaySnacksType('show');
          if (thisSnacksItem.recipes.length > 0) {
            setCurrentDaySnacksImage(thisSnacksItem.recipes[0].imageUrl);
            setCurrentDaySnacksMealLabel(thisSnacksItem.recipes[0].name);
            thisCounter += thisSnacksItem.recipes.length;
          }
          if (thisSnacksItem.ingredients.length > 0) {
            thisCounter += thisSnacksItem.ingredients.length;
          }
          if (thisSnacksItem.ingredients.length > 0 && thisSnacksItem.recipes.length === 0) {
            setCurrentDaySnacksImage(thisSnacksItem.ingredients[0].imageUrl);
            setCurrentDaySnacksMealLabel(thisSnacksItem.ingredients[0].name);
          }
        } else {
          setCurrentDaySnacksImage('');
          setCurrentDaySnacksMealLabel('');
          setCurrentDaySnacksType('add');
        }

        setCurrentDaySnacksKcalTotal(thisSnacksItem.kcal_total);
        setCurrentDaySnacksCarbohydratesTotal(thisSnacksItem.carbohydrates_total);
        setCurrentDaySnacksProteinTotal(thisSnacksItem.protein_total);
        setCurrentDaySnacksFatTotal(thisSnacksItem.fat_total);
        setCurrentDaySnacksMealCounter(thisCounter - 1);
      }
    }
  };

  function getDateRange(startRangeDate: any, endRangeDate: any) {
    const day1 = moment(endRangeDate);
    const day2 = moment(startRangeDate);
    const result = [];

    while (day1.date() !== day2.date()) {
      day2.add(1, 'day');
      result.push(moment(day2).toDate());
    }

    return result;
  }

  const openMealPopup = (mealType: string, mealLabel: string) => {
    setViewMealPopupClass('block');
    setCurrentMealType(mealType);
    setCurrentMealLabel(mealLabel);
  };

  const changeHandler = (event: any) => {
    const newColumns = {
      ...currentPlan,
      name: event.target.value,
    };

    savePlanChangesToFirebase(userData, newColumns, newColumns.uid, authContext.user?.uid, false, undefined, tenant);
  };

  const debouncedChangeHandler = useMemo(() => _.debounce(changeHandler, 1000), [currentPlan]);

  // Change plan name
  const updatePlanName = (event: any) => {
    debouncedChangeHandler(event);
  };

  function openIngredientSearchPopup() {
    setIngredientSearchPopupClass('block');
  }

  // Change form of nutrition items
  const setFormOfNutritions = (status: boolean, item: string): void => {
    const newColumns = {
      ...currentPlan,
      [item.toLowerCase()]: status,
    };

    savePlanChangesToFirebase(
      userData,
      newColumns,
      newColumns.uid,
      authContext.user?.uid,
      false,
      setCurrentPlan,
      tenant
    );
    // setCurrentPlan(newColumns);
  };

  function moveIngredient() {
    handleOpenClosePopups(setMoveIngredientPopupClass, '', 'close');

    let thisMoveTypeValue = currentMoveMealTypeValue;
    if (thisMoveTypeValue === undefined || thisMoveTypeValue.length === 0) {
      thisMoveTypeValue = 'breakfast';
    }

    const thisItemType = currentDayItem?.[currentMealType].ingredients.filter(
      (item: any) => item.id === currentChangeItemId
    )[0];

    if (currentMealType !== thisMoveTypeValue && thisItemType !== undefined) {
      const newColumns = {
        ...currentPlan,
        dayEntries: currentPlan?.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== currentDayId) return entries;
          return {
            ...entries,
            [currentMealType]: {
              ...entries[currentMealType],
              kcal_total:
                entries[currentMealType].kcal_total - thisItemType.kcal_total < 0
                  ? 0
                  : entries[currentMealType].kcal_total - thisItemType.kcal_total,
              carbohydrates_total:
                entries[currentMealType].carbohydrates_total - thisItemType.carbohydrates_total < 0
                  ? 0
                  : entries[currentMealType].carbohydrates_total - thisItemType.carbohydrates_total,
              protein_total:
                entries[currentMealType].protein_total - thisItemType.protein_total < 0
                  ? 0
                  : entries[currentMealType].protein_total - thisItemType.protein_total,
              fat_total:
                entries[currentMealType].fat_total - thisItemType.fat_total < 0
                  ? 0
                  : entries[currentMealType].fat_total - thisItemType.fat_total,
              ingredients: [
                ...entries[currentMealType].ingredients.filter(
                  (item: any) => parseFloat(item.id) !== parseFloat(thisItemType.id)
                ),
              ],
            },
          };
        }),
      };

      const newColumnsAdd = {
        ...newColumns,
        dayEntries: newColumns.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== currentDayId) return entries;
          return {
            ...entries,
            [thisMoveTypeValue]: {
              ...entries[thisMoveTypeValue],
              kcal_total: parseFloat(entries[thisMoveTypeValue].kcal_total) + parseFloat(thisItemType.kcal_total),
              carbohydrates_total:
                parseFloat(entries[thisMoveTypeValue].carbohydrates_total) +
                parseFloat(thisItemType.carbohydrates_total),
              protein_total:
                parseFloat(entries[thisMoveTypeValue].protein_total) + parseFloat(thisItemType.protein_total),
              fat_total: parseFloat(entries[thisMoveTypeValue].fat_total) + parseFloat(thisItemType.fat_total),
              ingredients: [...entries[thisMoveTypeValue].ingredients, thisItemType],
            },
          };
        }),
      };

      const newColumnsSetNewIds = {
        ...newColumnsAdd,
        dayEntries: newColumnsAdd.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== currentDayId) return entries;
          return {
            ...entries,
            [thisMoveTypeValue]: {
              ...entries[thisMoveTypeValue],
              ingredients: entries[thisMoveTypeValue].ingredients.map((item: any, index: any) => {
                return {
                  ...item,
                  id: index.toString(),
                };
              }),
            },
          };
        }),
      };

      toast.success('Das Lebensmittel wurde erfolgreich verschoben!');
      savePlanChangesToFirebase(
        userData,
        newColumnsSetNewIds,
        newColumnsSetNewIds.uid,
        authContext.user?.uid,
        false,
        setCurrentPlan,
        tenant
      );
      // setCurrentPlan(newColumnsSetNewIds);
      setCurrentMoveMealTypeValue('breakfast');
    } else {
      toast.error('Bitte wähle eine andere Mahlzeit aus');
    }
  }

  function copyDay() {
    const getYear = moment(startDate).format('YYYY');
    const getMonth = moment(startDate).format('MM');
    const getDay = moment(startDate).format('DD');
    const mergedDate = getYear + getMonth + getDay;
    let planExists = false;
    let thisCopyDayId = 0;

    if (currentCopyTypeValue === 'date') {
      const dayExistsInPlan = currentPlan?.dayEntries?.filter((item: any) => {
        const getItemYear = moment.unix(item.date?.seconds).utc().format('YYYY');
        const getItemMonth = moment.unix(item.date?.seconds).utc().format('MM');
        const getItemDay = moment.unix(item.date?.seconds).utc().format('DD');
        const mergedItemDate = getItemYear + getItemMonth + getItemDay;

        if (mergedItemDate === mergedDate) {
          planExists = true;
          thisCopyDayId = item.id;
          return true;
        }
        return false;
      });

      if (planExists) {
        handleOpenClosePopups(setCopyDayPopupClass, '', 'close');

        const newColumnsAdd = {
          ...currentPlan,
          dayEntries: currentPlan.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== thisCopyDayId) return entries;

            const { notice, waterCounter, dayRating, ...rest } = currentDayItem;

            return {
              ...rest,
              date: {
                seconds: entries.date.seconds,
              },
            };
          }),
        };

        const newColumnsSetNewIds = {
          ...newColumnsAdd,
          dayEntries: newColumnsAdd.dayEntries.map((entries: any, index: number) => {
            return {
              ...entries,
              id: index + 1,
              name: `Tag ${index + 1}`,
            };
          }),
        };

        toast.success('Tag wurde erfolgreich kopiert!');
        savePlanChangesToFirebase(
          userData,
          newColumnsSetNewIds,
          newColumnsSetNewIds.uid,
          authContext.user?.uid,
          false,
          setCurrentPlan,
          tenant
        );
        // setCurrentPlan(newColumnsSetNewIds);
        setCurrentCopyMealTypeValue('breakfast');
        setCurrentCopyTypeValue('date');
      } else {
        toast.error('Dieses Datum existiert nicht in dem Plan. Bitte wähle ein anderes Datum!');
      }
    } else if (currentCopyTypeValue === 'all') {
      handleOpenClosePopups(setCopyDayPopupClass, '', 'close');
      const thisItemType = currentDayItem?.[currentMealType].ingredients.filter(
        (item: any) => item.id === currentChangeItemId
      )[0];

      const newColumnsAdd = {
        ...currentPlan,
        dayEntries: currentPlan.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) === currentDayId) return entries;

          const { notice, waterCounter, dayRating, ...rest } = currentDayItem;

          return {
            ...rest,
            date: {
              seconds: entries.date.seconds,
            },
          };
        }),
      };

      const newColumnsSetNewIds = {
        ...newColumnsAdd,
        dayEntries: newColumnsAdd.dayEntries.map((entries: any, index: number) => {
          return {
            ...entries,
            id: index + 1,
            name: `Tag ${index + 1}`,
          };
        }),
      };

      toast.success('Tag wurde erfolgreich in jeden Tag kopiert!');
      savePlanChangesToFirebase(
        userData,
        newColumnsSetNewIds,
        newColumnsSetNewIds.uid,
        authContext.user?.uid,
        false,
        setCurrentPlan,
        tenant
      );
      // setCurrentPlan(newColumnsSetNewIds);
      setCurrentCopyMealTypeValue('breakfast');
      setCurrentCopyTypeValue('date');
    }
  }

  function copyIngredient() {
    const getYear = moment(startDate).format('YYYY');
    const getMonth = moment(startDate).format('MM');
    const getDay = moment(startDate).format('DD');
    const mergedDate = getYear + getMonth + getDay;
    let planExists = false;
    let thisCopyDayId = 0;

    let thisCopyTypeValue = currentCopyMealTypeValue;
    if (thisCopyTypeValue === undefined || thisCopyTypeValue.length === 0) {
      thisCopyTypeValue = 'breakfast';
    }

    if (currentCopyTypeValue === 'date') {
      const dayExistsInPlan = currentPlan?.dayEntries?.filter((item: any) => {
        const getItemYear = moment.unix(item.date?.seconds).utc().format('YYYY');
        const getItemMonth = moment.unix(item.date?.seconds).utc().format('MM');
        const getItemDay = moment.unix(item.date?.seconds).utc().format('DD');
        const mergedItemDate = getItemYear + getItemMonth + getItemDay;

        if (mergedItemDate === mergedDate) {
          planExists = true;
          thisCopyDayId = item.id;
          return true;
        }
        return false;
      });

      if (planExists) {
        handleOpenClosePopups(setCopyIngredientPopupClass, '', 'close');
        const thisItemType = currentDayItem?.[currentMealType].ingredients.filter(
          (item: any) => item.id === currentChangeItemId
        )[0];

        const newColumnsAdd = {
          ...currentPlan,
          dayEntries: currentPlan.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== thisCopyDayId) return entries;
            return {
              ...entries,
              kcal_total: parseFloat(entries.kcal_total) + parseFloat(thisItemType.kcal_total),
              carbohydrates_total:
                parseFloat(entries.carbohydrates_total) + parseFloat(thisItemType.carbohydrates_total),
              protein_total: parseFloat(entries.protein_total) + parseFloat(thisItemType.protein_total),
              fat_total: parseFloat(entries.fat_total) + parseFloat(thisItemType.fat_total),
              [thisCopyTypeValue]: {
                ...entries[thisCopyTypeValue],
                kcal_total: parseFloat(entries[thisCopyTypeValue].kcal_total) + parseFloat(thisItemType.kcal_total),
                carbohydrates_total:
                  parseFloat(entries[thisCopyTypeValue].carbohydrates_total) +
                  parseFloat(thisItemType.carbohydrates_total),
                protein_total:
                  parseFloat(entries[thisCopyTypeValue].protein_total) + parseFloat(thisItemType.protein_total),
                fat_total: parseFloat(entries[thisCopyTypeValue].fat_total) + parseFloat(thisItemType.fat_total),
                ingredients: [...entries[thisCopyTypeValue].ingredients, thisItemType],
              },
            };
          }),
        };

        const newColumnsSetNewIds = {
          ...newColumnsAdd,
          dayEntries: newColumnsAdd.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== thisCopyDayId) return entries;
            return {
              ...entries,
              [thisCopyTypeValue]: {
                ...entries[thisCopyTypeValue],
                ingredients: entries[thisCopyTypeValue].ingredients.map((item: any, index: any) => {
                  return {
                    ...item,
                    id: index,
                  };
                }),
              },
            };
          }),
        };

        toast.success('Das Lebensmittel wurde erfolgreich kopiert!');
        savePlanChangesToFirebase(
          userData,
          newColumnsSetNewIds,
          newColumnsSetNewIds.uid,
          authContext.user?.uid,
          false,
          setCurrentPlan,
          tenant
        );
        // setCurrentPlan(newColumnsSetNewIds);
        setCurrentCopyMealTypeValue('breakfast');
        setCurrentCopyTypeValue('date');
      } else {
        toast.error('Dieses Datum existiert nicht in dem Plan. Bitte wähle ein anderes Datum!');
      }
    } else if (currentCopyTypeValue === 'all') {
      handleOpenClosePopups(setCopyIngredientPopupClass, '', 'close');
      const thisItemType = currentDayItem?.[currentMealType].ingredients.filter(
        (item: any) => item.id === currentChangeItemId
      )[0];

      const newColumnsAdd = {
        ...currentPlan,
        dayEntries: currentPlan.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) === currentDayId) return entries;
          return {
            ...entries,
            kcal_total: parseFloat(entries.kcal_total) + parseFloat(thisItemType.kcal_total),
            carbohydrates_total: parseFloat(entries.carbohydrates_total) + parseFloat(thisItemType.carbohydrates_total),
            protein_total: parseFloat(entries.protein_total) + parseFloat(thisItemType.protein_total),
            fat_total: parseFloat(entries.fat_total) + parseFloat(thisItemType.fat_total),
            [thisCopyTypeValue]: {
              ...entries[thisCopyTypeValue],
              kcal_total: parseFloat(entries[thisCopyTypeValue].kcal_total) + parseFloat(thisItemType.kcal_total),
              carbohydrates_total:
                parseFloat(entries[thisCopyTypeValue].carbohydrates_total) +
                parseFloat(thisItemType.carbohydrates_total),
              protein_total:
                parseFloat(entries[thisCopyTypeValue].protein_total) + parseFloat(thisItemType.protein_total),
              fat_total: parseFloat(entries[thisCopyTypeValue].fat_total) + parseFloat(thisItemType.fat_total),
              ingredients: [...entries[thisCopyTypeValue].ingredients, thisItemType],
            },
          };
        }),
      };

      const newColumnsSetNewIds = {
        ...newColumnsAdd,
        dayEntries: newColumnsAdd.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) === currentDayId) return entries;
          return {
            ...entries,
            [thisCopyTypeValue]: {
              ...entries[thisCopyTypeValue],
              ingredients: entries[thisCopyTypeValue].ingredients.map((item: any, index: any) => {
                return {
                  ...item,
                  id: index,
                };
              }),
            },
          };
        }),
      };

      toast.success('Das Lebensmittel wurde erfolgreich in jeden Tag kopiert!');
      savePlanChangesToFirebase(
        userData,
        newColumnsSetNewIds,
        newColumnsSetNewIds.uid,
        authContext.user?.uid,
        false,
        setCurrentPlan,
        tenant
      );
      // setCurrentPlan(newColumnsSetNewIds);
      setCurrentCopyMealTypeValue('breakfast');
      setCurrentCopyTypeValue('date');
    }
  }

  function changeRecipe(newRecipeObject: any) {
    const thisItemType = currentDayItem?.[currentMealType].recipes.filter(
      (item: any) => item.id === currentChangeItemId
    )[0];

    const thisNewObject = {
      ...newRecipeObject,
      id: thisItemType.id,
      amount: 1,
      piece: 'Portion',
    };

    const newColumns = {
      ...currentPlan,
      dayEntries: currentPlan.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== currentDayId) return entries;
        return {
          ...entries,
          kcal_total:
            (parseFloat(entries.kcal_total) - parseFloat(thisItemType.kcal_total) < 0
              ? 0
              : parseFloat(entries.kcal_total) - parseFloat(thisItemType.kcal_total)) +
            parseFloat(newRecipeObject.kcal_total),
          carbohydrates_total:
            (parseFloat(entries.carbohydrates_total) - parseFloat(thisItemType.carbohydrates_total) < 0
              ? 0
              : parseFloat(entries.carbohydrates_total) - parseFloat(thisItemType.carbohydrates_total)) +
            parseFloat(newRecipeObject.carbohydrates_total),
          protein_total:
            (parseFloat(entries.protein_total) - parseFloat(thisItemType.protein_total) < 0
              ? 0
              : parseFloat(entries.protein_total) - parseFloat(thisItemType.protein_total)) +
            parseFloat(newRecipeObject.protein_total),
          fat_total:
            (parseFloat(entries.fat_total) - parseFloat(thisItemType.fat_total) < 0
              ? 0
              : parseFloat(entries.fat_total) - parseFloat(thisItemType.fat_total)) +
            parseFloat(newRecipeObject.fat_total),
          [currentMealType]: {
            ...entries[currentMealType],
            kcal_total:
              (parseFloat(entries[currentMealType].kcal_total) - parseFloat(thisItemType.kcal_total) < 0
                ? 0
                : parseFloat(entries[currentMealType].kcal_total) - parseFloat(thisItemType.kcal_total)) +
              parseFloat(newRecipeObject.kcal_total),
            carbohydrates_total:
              (parseFloat(entries[currentMealType].carbohydrates_total) - parseFloat(thisItemType.carbohydrates_total) <
              0
                ? 0
                : parseFloat(entries[currentMealType].carbohydrates_total) -
                  parseFloat(thisItemType.carbohydrates_total)) + parseFloat(newRecipeObject.carbohydrates_total),
            protein_total:
              (parseFloat(entries[currentMealType].protein_total) - parseFloat(thisItemType.protein_total) < 0
                ? 0
                : parseFloat(entries[currentMealType].protein_total) - parseFloat(thisItemType.protein_total)) +
              parseFloat(newRecipeObject.protein_total),
            fat_total:
              (parseFloat(entries[currentMealType].fat_total) - parseFloat(thisItemType.fat_total) < 0
                ? 0
                : parseFloat(entries[currentMealType].fat_total) - parseFloat(thisItemType.fat_total)) +
              parseFloat(newRecipeObject.fat_total),
            recipes: [
              ...entries[currentMealType].recipes.filter((item: any) => item.id !== currentChangeItemId),
              thisNewObject,
            ],
          },
        };
      }),
    };

    const newColumnsSetNewIds = {
      ...newColumns,
      dayEntries: newColumns.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== currentDayId) return entries;
        return {
          ...entries,
          [currentMealType]: {
            ...entries[currentMealType],
            recipes: entries[currentMealType].recipes
              .sort((a: any, b: any) => a.id - b.id)
              .map((item: any) => {
                return {
                  ...item,
                };
              }),
          },
        };
      }),
    };

    const checkIncompabilityArray = [] as any;
    const checkFormOfNutritionArray = [] as any;

    intolerancesOption.forEach(function (key) {
      newColumnsSetNewIds.dayEntries.forEach(function (dayKey: any) {
        // Check recipes
        const checkBreakfastRecipes = dayKey.breakfast.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkLunchRecipes = dayKey.lunch.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkDinnerRecipes = dayKey.dinner.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkSnacksRecipes = dayKey.snacks.recipes?.filter((item: any) => item[key.toLowerCase()] === true);

        if (
          checkBreakfastRecipes.length > 0 ||
          checkLunchRecipes.length > 0 ||
          checkDinnerRecipes.length > 0 ||
          checkSnacksRecipes.length > 0
        ) {
          checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
        } else {
          // Check ingredients
          dayKey.breakfast.ingredients?.forEach(function (ingredientKey: any) {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.lunch.ingredients?.forEach(function (ingredientKey: any) {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.dinner.ingredients?.forEach(function (ingredientKey: any) {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.snacks.ingredients?.forEach(function (ingredientKey: any) {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });
        }
      });
    });

    formOfNutrition.forEach(function (key) {
      newColumnsSetNewIds.dayEntries.forEach(function (dayKey: any) {
        // Check recipes
        const checkBreakfastRecipes = dayKey.breakfast.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkLunchRecipes = dayKey.lunch.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkDinnerRecipes = dayKey.dinner.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkSnacksRecipes = dayKey.snacks.recipes?.filter((item: any) => item[key.toLowerCase()] === true);

        if (
          checkBreakfastRecipes.length > 0 ||
          checkLunchRecipes.length > 0 ||
          checkDinnerRecipes.length > 0 ||
          checkSnacksRecipes.length > 0
        ) {
          checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
        } else {
          // Check ingredients
          dayKey.breakfast.ingredients?.forEach(function (ingredientKey: any) {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.lunch.ingredients?.forEach(function (ingredientKey: any) {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.dinner.ingredients?.forEach(function (ingredientKey: any) {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.snacks.ingredients?.forEach(function (ingredientKey: any) {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });
        }
      });
    });

    // Copy newColumns to variable because this needs to be updated in the following sections
    let thisRecipeData = { ...newColumnsSetNewIds };

    // First set all to false
    for (let f = 0; f < intolerancesOption.length; f += 1) {
      thisRecipeData = { ...thisRecipeData, [intolerancesOption[f].toLowerCase()]: false };
    }
    for (let h = 0; h < formOfNutrition.length; h += 1) {
      thisRecipeData = { ...thisRecipeData, [formOfNutrition[h].toLowerCase()]: false };
    }

    // Set elements true if incompatibility exists
    for (let g = 0; g < checkIncompabilityArray.length; g += 1) {
      thisRecipeData = { ...thisRecipeData, [checkIncompabilityArray[g].name]: true };
    }

    // Set elements true if form of nutrition exists
    for (let g = 0; g < checkFormOfNutritionArray.length; g += 1) {
      thisRecipeData = { ...thisRecipeData, [checkFormOfNutritionArray[g].name]: true };
    }

    if (userData?.incompatibilityAccept === undefined && userData?.age !== undefined) {
      setVisibleIncompatibilityPopup(true);
    }

    savePlanChangesToFirebase(
      userData,
      thisRecipeData,
      thisRecipeData.uid,
      authContext.user?.uid,
      false,
      setCurrentPlan,
      tenant
    );
    // setCurrentPlan(newColumnsSetNewIds);
    setRecipeChangePopupClass('hidden');
  }

  const updateMakroType = (value: any) => {
    let thisCalculatedCarbohydrates = 0;
    let thisCalculatedProtein = 0;
    let thisCalculatedFat = 0;
    const thisCarbohydratesPercent = makroTypes.filter((st: any) => st.value === value.value)[0].carbohydrates;
    const thisProteinPercent = makroTypes.filter((st: any) => st.value === value.value)[0].protein;
    const thisFatPercent = makroTypes.filter((st: any) => st.value === value.value)[0].fat;

    if (value.value === 'user') {
      if (changeMakroCarbohydrateRef.current) {
        changeMakroCarbohydrateRef.current.value = '50';
      }
      if (changeMakroProteinRef.current) {
        changeMakroProteinRef.current.value = '25';
      }
      if (changeMakroFatRef.current) {
        changeMakroFatRef.current.value = '25';
      }

      thisCalculatedCarbohydrates = Math.round((currentMakroChangeCalories * 50) / 100 / 4.1);
      thisCalculatedProtein = Math.round((currentMakroChangeCalories * 25) / 100 / 4.1);
      thisCalculatedFat = Math.round((currentMakroChangeCalories * 25) / 100 / 9.3);

      setCurrentMakroChangeCarbohydratesPercent('50');
      setCurrentMakroChangeProteinPercent('25');
      setCurrentMakroChangeFatPercent('25');

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    } else {
      if (changeMakroCarbohydrateRef.current) {
        changeMakroCarbohydrateRef.current.value = thisCarbohydratesPercent;
      }
      if (changeMakroProteinRef.current) {
        changeMakroProteinRef.current.value = thisProteinPercent;
      }
      if (changeMakroFatRef.current) {
        changeMakroFatRef.current.value = thisFatPercent;
      }

      thisCalculatedCarbohydrates = Math.round(
        (currentMakroChangeCalories * parseFloat(thisCarbohydratesPercent)) / 100 / 4.1
      );
      thisCalculatedProtein = Math.round((currentMakroChangeCalories * parseFloat(thisProteinPercent)) / 100 / 4.1);
      thisCalculatedFat = Math.round((currentMakroChangeCalories * parseFloat(thisFatPercent)) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    }

    setCurrentMakroChangeType(value.value);
  };

  const updateMakroCalories = (event: any) => {
    let thisCalculatedCarbohydrates = 0;
    let thisCalculatedProtein = 0;
    let thisCalculatedFat = 0;
    const thisCarbohydratesPercent = makroTypes.filter((st: any) => st.value === currentMakroChangeType)[0]
      .carbohydrates;
    const thisProteinPercent = makroTypes.filter((st: any) => st.value === currentMakroChangeType)[0].protein;
    const thisFatPercent = makroTypes.filter((st: any) => st.value === currentMakroChangeType)[0].fat;

    if (currentMakroChangeType === 'standard') {
      thisCalculatedCarbohydrates = Math.round((event.target.value * 50) / 100 / 4.1);
      thisCalculatedProtein = Math.round((event.target.value * 25) / 100 / 4.1);
      thisCalculatedFat = Math.round((event.target.value * 25) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    } else if (currentMakroChangeType === 'user') {
      thisCalculatedCarbohydrates = Math.round(
        (event.target.value * parseFloat(currentMakroChangeCarbohydratesPercent)) / 100 / 4.1
      );
      thisCalculatedProtein = Math.round(
        (event.target.value * parseFloat(currentMakroChangeProteinPercent)) / 100 / 4.1
      );
      thisCalculatedFat = Math.round((event.target.value * parseFloat(currentMakroChangeFatPercent)) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    } else {
      thisCalculatedCarbohydrates = Math.round((event.target.value * parseFloat(thisCarbohydratesPercent)) / 100 / 4.1);
      thisCalculatedProtein = Math.round((event.target.value * parseFloat(thisProteinPercent)) / 100 / 4.1);
      thisCalculatedFat = Math.round((event.target.value * parseFloat(thisFatPercent)) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    }

    setCurrentMakroChangeCalories(event.target.value);
  };

  const openMakroChangePopup = (changeType: string) => {
    setChangeCaloriesNeed(false);
    setCurrentMakroChangeCalories(0);

    if (currentDayItem?.custom_carbohydrates_total !== undefined) {
      if (currentDayItem?.custom_makro_type === 'user') {
        setCurrentCarbohydratesPercent(
          currentDayItem?.custom_carbohydrates_percent !== undefined
            ? currentDayItem?.custom_carbohydrates_percent
            : '50'
        );
        setCurrentProteinPercent(
          currentDayItem?.custom_protein_percent !== undefined ? currentDayItem?.custom_protein_percent : '25'
        );
        setCurrentFatPercent(
          currentDayItem?.custom_fat_percent !== undefined ? currentDayItem?.custom_fat_percent : '25'
        );
      } else {
        setCurrentCarbohydratesPercent(
          makroTypes.filter((st: any) => st.value === currentDayItem?.custom_makro_type)[0].carbohydrates
        );
        setCurrentProteinPercent(
          makroTypes.filter((st: any) => st.value === currentDayItem?.custom_makro_type)[0].protein
        );
        setCurrentFatPercent(makroTypes.filter((st: any) => st.value === currentDayItem?.custom_makro_type)[0].fat);
      }
      setCurrentMakroChangeCarbohydrates(currentDayItem?.custom_carbohydrates_total);
      setCurrentMakroChangeProtein(currentDayItem?.custom_protein_total);
      setCurrentMakroChangeFat(currentDayItem?.custom_fat_total);
      // setCurrentMakroChangeCalories(currentDayItem?.custom_kcal_total);

      /* if (changeMakroCaloriesRef.current) {
        changeMakroCaloriesRef.current.value = currentDayItem?.custom_kcal_total.toString();
        setTimeout(() => {
          if (changeMakroCaloriesRef.current) {
            changeMakroCaloriesRef.current.focus();
          }
        }, 1);
      } */
    } else if (currentPlan?.custom_carbohydrates_total) {
      if (currentPlan?.custom_makro_type === 'user') {
        setCurrentCarbohydratesPercent(currentPlan?.custom_carbohydrates_percent!);
        setCurrentProteinPercent(currentPlan?.custom_protein_percent!);
        setCurrentFatPercent(currentPlan?.custom_fat_percent!);
      } else {
        setCurrentCarbohydratesPercent(
          makroTypes.filter((st: any) => st.value === currentPlan?.custom_makro_type)[0].carbohydrates
        );
        setCurrentProteinPercent(
          makroTypes.filter((st: any) => st.value === currentPlan?.custom_makro_type)[0].protein
        );
        setCurrentFatPercent(makroTypes.filter((st: any) => st.value === currentPlan?.custom_makro_type)[0].fat);
      }
      setCurrentMakroChangeCarbohydrates(currentPlan?.custom_carbohydrates_total);
      setCurrentMakroChangeProtein(currentPlan?.custom_protein_total);
      setCurrentMakroChangeFat(currentPlan?.custom_fat_total);
      // setCurrentMakroChangeCalories(currentPlan?.custom_kcal_total);
      /* if (changeMakroCaloriesRef.current && changeType === 'plan') {
        changeMakroCaloriesRef.current.value = currentPlan?.custom_kcal_total.toString();
        setTimeout(() => {
          if (changeMakroCaloriesRef.current) {
            changeMakroCaloriesRef.current.focus();
          }
        }, 1);
      } */
    } else {
      setCurrentMakroChangeCarbohydrates(userData?.carbohydrates_total);
      setCurrentMakroChangeProtein(userData?.protein_total);
      setCurrentMakroChangeFat(userData?.fat_total);

      if (userData?.custom_nutrition_type !== undefined) {
        if (userData?.custom_nutrition_type === 'user') {
          setCurrentCarbohydratesPercent(userData?.custom_carbohydrates_percent!);
          setCurrentProteinPercent(userData?.custom_protein_percent!);
          setCurrentFatPercent(userData?.custom_fat_percent!);
        } else {
          setCurrentCarbohydratesPercent(
            makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].carbohydrates
          );
          setCurrentProteinPercent(
            makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].protein
          );
          setCurrentFatPercent(makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].fat);
        }
      } else {
        setCurrentCarbohydratesPercent('50');
        setCurrentProteinPercent('25');
        setCurrentFatPercent('25');
      }
    }

    if (currentDayItem?.custom_makro_type) {
      const thisCarbohydratesPercent = makroTypes.filter((st: any) => st.value === currentDayItem?.custom_makro_type)[0]
        .carbohydrates;
      const thisProteinPercent = makroTypes.filter((st: any) => st.value === currentDayItem?.custom_makro_type)[0]
        .protein;
      const thisFatPercent = makroTypes.filter((st: any) => st.value === currentDayItem?.custom_makro_type)[0].fat;

      if (currentDayItem?.custom_makro_type === 'user') {
        if (changeMakroCarbohydrateRef.current) {
          changeMakroCarbohydrateRef.current.value = currentDayItem?.custom_carbohydrates_percent;
        }
        if (changeMakroProteinRef.current) {
          changeMakroProteinRef.current.value = currentDayItem?.custom_protein_percent;
        }
        if (changeMakroFatRef.current) {
          changeMakroFatRef.current.value = currentDayItem?.custom_fat_percent;
        }
      } else {
        if (changeMakroCarbohydrateRef.current) {
          changeMakroCarbohydrateRef.current.value = thisCarbohydratesPercent;
        }
        if (changeMakroProteinRef.current) {
          changeMakroProteinRef.current.value = thisProteinPercent;
        }
        if (changeMakroFatRef.current) {
          changeMakroFatRef.current.value = thisFatPercent;
        }
      }
    } else if (currentPlan?.custom_makro_type) {
      const thisCarbohydratesPercent = makroTypes.filter((st: any) => st.value === currentPlan?.custom_makro_type)[0]
        .carbohydrates;
      const thisProteinPercent = makroTypes.filter((st: any) => st.value === currentPlan?.custom_makro_type)[0].protein;
      const thisFatPercent = makroTypes.filter((st: any) => st.value === currentPlan?.custom_makro_type)[0].fat;

      if (currentPlan?.custom_makro_type === 'user') {
        if (changeMakroCarbohydrateRef.current) {
          changeMakroCarbohydrateRef.current.value = currentPlan?.custom_carbohydrates_percent;
        }
        if (changeMakroProteinRef.current) {
          changeMakroProteinRef.current.value = currentPlan?.custom_protein_percent;
        }
        if (changeMakroFatRef.current) {
          changeMakroFatRef.current.value = currentPlan?.custom_fat_percent;
        }
      } else {
        if (changeMakroCarbohydrateRef.current) {
          changeMakroCarbohydrateRef.current.value = thisCarbohydratesPercent;
        }
        if (changeMakroProteinRef.current) {
          changeMakroProteinRef.current.value = thisProteinPercent;
        }
        if (changeMakroFatRef.current) {
          changeMakroFatRef.current.value = thisFatPercent;
        }
      }
    } else if (userData?.custom_nutrition_type !== undefined && userData?.custom_carbohydrates_percent !== undefined) {
      const thisCarbohydratesPercent = makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0]
        .carbohydrates;
      const thisProteinPercent = makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0]
        .protein;
      const thisFatPercent = makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].fat;

      if (userData?.custom_nutrition_type === 'user') {
        if (changeMakroCarbohydrateRef.current) {
          changeMakroCarbohydrateRef.current.value = userData?.custom_carbohydrates_percent;
        }
        if (changeMakroProteinRef.current && userData?.custom_protein_percent !== undefined) {
          changeMakroProteinRef.current.value = userData?.custom_protein_percent;
        }
        if (changeMakroFatRef.current && userData?.custom_fat_percent !== undefined) {
          changeMakroFatRef.current.value = userData?.custom_fat_percent;
        }
      } else {
        if (changeMakroCarbohydrateRef.current) {
          changeMakroCarbohydrateRef.current.value = thisCarbohydratesPercent;
        }
        if (changeMakroProteinRef.current) {
          changeMakroProteinRef.current.value = thisProteinPercent;
        }
        if (changeMakroFatRef.current) {
          changeMakroFatRef.current.value = thisFatPercent;
        }
      }
    } else {
      if (changeMakroCarbohydrateRef.current) {
        changeMakroCarbohydrateRef.current.value = '50';
      }
      if (changeMakroProteinRef.current) {
        changeMakroProteinRef.current.value = '25';
      }
      if (changeMakroFatRef.current) {
        changeMakroFatRef.current.value = '25';
      }
    }

    if (currentDayItem?.custom_makro_type) {
      // setCurrentMakroChangeType(currentDayItem?.custom_makro_type);

      if (currentDayItem?.custom_carbohydrates_percent !== undefined && currentDayItem?.custom_makro_type === 'user') {
        setCurrentMakroChangeCarbohydratesPercent(currentDayItem?.custom_carbohydrates_percent);
      } else {
        setCurrentMakroChangeCarbohydratesPercent(
          makroTypes.filter((st: any) => st.value === currentDayItem?.custom_makro_type)[0].carbohydrates
        );
      }
      if (currentDayItem?.custom_protein_percent !== undefined && currentDayItem?.custom_makro_type === 'user') {
        setCurrentMakroChangeProteinPercent(currentDayItem?.custom_protein_percent);
      } else {
        setCurrentMakroChangeProteinPercent(
          makroTypes.filter((st: any) => st.value === currentDayItem?.custom_makro_type)[0].protein
        );
      }
      if (currentDayItem?.custom_fat_percent !== undefined && currentDayItem?.custom_makro_type === 'user') {
        setCurrentMakroChangeFatPercent(currentDayItem?.custom_fat_percent);
      } else {
        setCurrentMakroChangeFatPercent(
          makroTypes.filter((st: any) => st.value === currentDayItem?.custom_makro_type)[0].fat
        );
      }
    } else if (currentPlan?.custom_makro_type) {
      // setCurrentMakroChangeType(currentPlan?.custom_makro_type);

      if (currentPlan?.custom_carbohydrates_percent !== undefined && currentPlan?.custom_makro_type === 'user') {
        setCurrentMakroChangeCarbohydratesPercent(currentPlan?.custom_carbohydrates_percent);
      } else {
        setCurrentMakroChangeCarbohydratesPercent(
          makroTypes.filter((st: any) => st.value === currentPlan?.custom_makro_type)[0].carbohydrates
        );
      }
      if (currentPlan?.custom_protein_percent !== undefined && currentPlan?.custom_makro_type === 'user') {
        setCurrentMakroChangeProteinPercent(currentPlan?.custom_protein_percent);
      } else {
        setCurrentMakroChangeProteinPercent(
          makroTypes.filter((st: any) => st.value === currentPlan?.custom_makro_type)[0].protein
        );
      }
      if (currentPlan?.custom_fat_percent !== undefined && currentPlan?.custom_makro_type === 'user') {
        setCurrentMakroChangeFatPercent(currentPlan?.custom_fat_percent);
      } else {
        setCurrentMakroChangeFatPercent(
          makroTypes.filter((st: any) => st.value === currentPlan?.custom_makro_type)[0].fat
        );
      }
    } else if (userData?.custom_nutrition_type !== undefined) {
      // setCurrentMakroChangeType(userData?.custom_nutrition_type);
      if (userData?.custom_carbohydrates_percent !== undefined && userData?.custom_nutrition_type === 'user') {
        setCurrentMakroChangeCarbohydratesPercent(userData?.custom_carbohydrates_percent);
      } else {
        setCurrentMakroChangeCarbohydratesPercent(
          makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].carbohydrates
        );
      }
      if (userData?.custom_protein_percent !== undefined && userData?.custom_nutrition_type === 'user') {
        setCurrentMakroChangeProteinPercent(userData?.custom_protein_percent);
      } else {
        setCurrentMakroChangeProteinPercent(
          makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].protein
        );
      }
      if (userData?.custom_fat_percent !== undefined && userData?.custom_nutrition_type === 'user') {
        setCurrentMakroChangeFatPercent(userData?.custom_fat_percent);
      } else {
        setCurrentMakroChangeFatPercent(
          makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].fat
        );
      }
    } else {
      setCurrentMakroChangeCarbohydratesPercent('50');
      setCurrentMakroChangeProteinPercent('25');
      setCurrentMakroChangeFatPercent('25');
    }

    setUserMacroPopupClass('block');
  };

  const changeUserFat = (event: any) => {
    let thisValue = event.target.value;
    let thisCalculatedFat = 0;

    if (thisValue === '') {
      thisValue = 0;
    }

    thisCalculatedFat = Math.round((currentMakroChangeCalories * parseFloat(thisValue)) / 100 / 9.3);

    setCurrentMakroChangeFatPercent(thisValue);
    setCurrentMakroChangeFat(thisCalculatedFat);
  };

  const changeUserProtein = (event: any) => {
    let thisValue = event.target.value;
    let thisCalculatedProtein = 0;

    if (thisValue === '') {
      thisValue = 0;
    }

    thisCalculatedProtein = Math.round((currentMakroChangeCalories * parseFloat(thisValue)) / 100 / 4.1);

    setCurrentMakroChangeProteinPercent(thisValue);
    setCurrentMakroChangeProtein(thisCalculatedProtein);
  };

  const changeUserCarbohydrates = (event: any) => {
    let thisValue = event.target.value;
    let thisCalculatedCarbohydrates = 0;

    if (thisValue === '') {
      thisValue = 0;
    }

    thisCalculatedCarbohydrates = Math.round((currentMakroChangeCalories * parseFloat(thisValue)) / 100 / 4.1);

    setCurrentMakroChangeCarbohydratesPercent(thisValue);
    setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
  };

  function saveMakroChange() {
    let thisCarbohydratesPercent = '';
    let thisProteinPercent = '';
    let thisFatPercent = '';

    if (currentMakroChangeCarbohydratesPercent !== '') {
      thisCarbohydratesPercent = currentMakroChangeCarbohydratesPercent;
    } else {
      thisCarbohydratesPercent = '50';
    }

    if (currentMakroChangeProteinPercent !== '') {
      thisProteinPercent = currentMakroChangeProteinPercent;
    } else {
      thisProteinPercent = '25';
    }

    if (currentMakroChangeFatPercent !== '') {
      thisFatPercent = currentMakroChangeFatPercent;
    } else {
      thisFatPercent = '25';
    }

    if (changeCaloriesNeed && parseFloat(currentMakroChangeCalories) < 1000) {
      toast.error('Bitte gebe mindestens 1000 Kalorien als Bedarf ein!');
    } else if (
      currentMakroChangeType === 'user' &&
      parseFloat(thisCarbohydratesPercent) + parseFloat(thisProteinPercent) + parseFloat(thisFatPercent) > 100
    ) {
      toast.error('Du hast bei der Verteilung mehr als 100% angegeben. Bitte prüfe deine Eingaben!');
    } else if (
      currentMakroChangeType === 'user' &&
      parseFloat(thisCarbohydratesPercent) + parseFloat(thisProteinPercent) + parseFloat(thisFatPercent) < 100
    ) {
      toast.error('Du hast bei der Verteilung weniger als 100% angegeben. Bitte prüfe deine Eingaben!');
    } else {
      const newColumns = {
        ...currentPlan,
        custom_carbohydrates_total: currentMakroChangeCarbohydrates,
        custom_protein_total: currentMakroChangeProtein,
        custom_fat_total: currentMakroChangeFat,
        custom_kcal_total: currentMakroChangeCalories,
        custom_makro_type: currentMakroChangeType,
        custom_carbohydrates_percent: currentMakroChangeCarbohydratesPercent,
        custom_protein_percent: currentMakroChangeProteinPercent,
        custom_fat_percent: currentMakroChangeFatPercent,
      };

      savePlanChangesToFirebase(
        userData,
        newColumns,
        newColumns.uid,
        authContext.user?.uid,
        false,
        setCurrentPlan,
        tenant
      );
      // setCurrentPlan(newColumns);
      setUserMacroPopupClass('hidden');
      toast.success('Dein Bedarf wurde erfolgreich angepasst!');
    }
  }

  function saveMakroDayChange() {
    let thisCarbohydratesPercent = '';
    let thisProteinPercent = '';
    let thisFatPercent = '';

    if (currentMakroChangeCarbohydratesPercent !== '') {
      thisCarbohydratesPercent = currentMakroChangeCarbohydratesPercent;
    } else {
      thisCarbohydratesPercent = '50';
    }

    if (currentMakroChangeProteinPercent !== '') {
      thisProteinPercent = currentMakroChangeProteinPercent;
    } else {
      thisProteinPercent = '25';
    }

    if (currentMakroChangeFatPercent !== '') {
      thisFatPercent = currentMakroChangeFatPercent;
    } else {
      thisFatPercent = '25';
    }

    if (changeCaloriesNeed && parseFloat(currentMakroChangeCalories) < 1000) {
      toast.error('Bitte gebe mindestens 1000 Kalorien als Bedarf ein!');
    } else if (
      currentMakroChangeType === 'user' &&
      parseFloat(thisCarbohydratesPercent) + parseFloat(thisProteinPercent) + parseFloat(thisFatPercent) > 100
    ) {
      toast.error('Du hast bei der Verteilung mehr als 100% angegeben. Bitte prüfe deine Eingaben!');
    } else if (
      currentMakroChangeType === 'user' &&
      parseFloat(thisCarbohydratesPercent) + parseFloat(thisProteinPercent) + parseFloat(thisFatPercent) < 100
    ) {
      toast.error('Du hast bei der Verteilung weniger als 100% angegeben. Bitte prüfe deine Eingaben!');
    } else {
      const newColumns = {
        ...currentPlan,
        dayEntries: currentPlan.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== currentDayId) return entries;
          return {
            ...entries,
            custom_kcal_total: currentMakroChangeCalories,
            custom_carbohydrates_total: currentMakroChangeCarbohydrates,
            custom_protein_total: currentMakroChangeProtein,
            custom_fat_total: currentMakroChangeFat,
            custom_makro_type: currentMakroChangeType,
            custom_carbohydrates_percent: currentMakroChangeCarbohydratesPercent,
            custom_protein_percent: currentMakroChangeProteinPercent,
            custom_fat_percent: currentMakroChangeFatPercent,
          };
        }),
      };

      savePlanChangesToFirebase(
        userData,
        newColumns,
        newColumns.uid,
        authContext.user?.uid,
        false,
        setCurrentPlan,
        tenant
      );
      // setCurrentPlan(newColumns);
      setUserMacroPopupClass('hidden');
      toast.success('Dein Bedarf wurde erfolgreich angepasst!');
    }
  }

  function keyPress(event: any, submitFunction: any) {
    if (event.charCode === 13) {
      event.preventDefault();
      if (submitFunction !== undefined) {
        submitFunction();
      }
    }
  }

  const savePlanToFavorites = async () => {
    const newColumns = {
      uid: plan?.uid,
      name: plan?.name,
      origId: plan?.uid,
    };

    if (planFavorite) {
      try {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(authContext.user?.uid)
          .collection('favorites_plans')
          .doc(favoritesPlansList?.uid)
          .delete();

        setPlanFavorite(false);
        toast.success('Der Plan wurde erfolgreich von deinen Favoriten entfernt!');
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } else {
      try {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(authContext.user?.uid)
          .collection('favorites_plans')
          .doc()
          .set(newColumns as object);

        setPlanFavorite(true);
        toast.success('Der Plan wurde erfolgreich zu deinen Favoriten gespeichert!');
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    }
  };

  const clearDay = () => {
    const newColumns = {
      ...currentPlan,
      dayEntries: currentPlan.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== currentDayId) return entries;

        const { notice, waterCounter, dayRating, ...rest } = entries;

        return {
          ...rest,
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
          breakfast: {
            ...entries.breakfast,
            kcal_total: 0,
            carbohydrates_total: 0,
            protein_total: 0,
            fat_total: 0,
            recipes: [],
            ingredients: [],
          },
          lunch: {
            ...entries.lunch,
            kcal_total: 0,
            carbohydrates_total: 0,
            protein_total: 0,
            fat_total: 0,
            recipes: [],
            ingredients: [],
          },
          dinner: {
            ...entries.dinner,
            kcal_total: 0,
            carbohydrates_total: 0,
            protein_total: 0,
            fat_total: 0,
            recipes: [],
            ingredients: [],
          },
          snacks: {
            ...entries.snacks,
            kcal_total: 0,
            carbohydrates_total: 0,
            protein_total: 0,
            fat_total: 0,
            recipes: [],
            ingredients: [],
          },
        };
      }),
    };

    setClearDayPopupClass('hidden');
    toast.success('Tag wurde erfolgreich geleert!');
    savePlanChangesToFirebase(
      userData,
      newColumns,
      newColumns.uid,
      authContext.user?.uid,
      false,
      setCurrentPlan,
      tenant
    );
    // setCurrentPlan(newColumns);
  };

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const closeChangeDurationPopup = () => {
    setStartDate(null);
    setEndDate(null);
    handleOpenClosePopups(setChangePlanDurationPopupClass, '', 'close');
  };

  const openGeneratePopup = (generateType: string) => {
    setPlanGenerateType(generateType);
    handleOpenClosePopups('', setGenerateDayPopupClass, 'open');
  };

  const changePlanDuration = () => {
    if (startDate === null || endDate === null) {
      toast.error('Bitte wählen Sie ein Start und Enddatum aus!');
      return false;
    }

    if (plan?.dayEntries) {
      let thisDayEntriesObject = [] as any;
      const thisPlanStartDate = moment.unix(moment(startDate).subtract(-1, 'days').unix()).utc().startOf('day');
      const thisPlanEndDate = moment.unix(moment(endDate).subtract(-1, 'days').unix()).utc().startOf('day');

      const dayEntriesLength = thisPlanEndDate.diff(thisPlanStartDate, 'days') + 1;
      const planDayEntriesLength = currentPlan.dayEntries.length as number;

      for (let g = 0; g < dayEntriesLength; g += 1) {
        if (currentPlan?.dayEntries[g] !== undefined) {
          const thisDayEntriesObjectEntry = {
            ...currentPlan?.dayEntries[g],
            date: {
              seconds: moment(startDate)
                .subtract(-(g + 1), 'days')
                .unix(),
            },
          };

          thisDayEntriesObject = [...thisDayEntriesObject, thisDayEntriesObjectEntry];
        }
      }

      let newColumns = {
        ...plan,
        startDate: {
          ...currentPlan?.startDate,
          seconds: moment(startDate).subtract(-1, 'days').unix(),
        },
        endDate: {
          ...currentPlan?.endDate,
          seconds: moment(startDate).subtract(-dayEntriesLength, 'days').unix(),
        },
        dayEntries: [...thisDayEntriesObject],
      };

      if (endDate !== null && startDate !== null) {
        if (dayEntriesLength > planDayEntriesLength) {
          for (let g = planDayEntriesLength; g < dayEntriesLength; g += 1) {
            const thisObject = {
              breakfast: {
                ingredients: [],
                recipes: [],
                kcal_total: 0,
                carbohydrates_total: 0,
                protein_total: 0,
                fat_total: 0,
                label: 'Frühstück',
              },
              lunch: {
                ingredients: [],
                recipes: [],
                kcal_total: 0,
                carbohydrates_total: 0,
                protein_total: 0,
                fat_total: 0,
                label: 'Mittagessen',
              },
              dinner: {
                ingredients: [],
                recipes: [],
                kcal_total: 0,
                carbohydrates_total: 0,
                protein_total: 0,
                fat_total: 0,
                label: 'Abendessen',
              },
              snacks: {
                ingredients: [],
                recipes: [],
                kcal_total: 0,
                carbohydrates_total: 0,
                protein_total: 0,
                fat_total: 0,
                label: 'Snacks',
              },
              kcal_total: 0,
              carbohydrates_total: 0,
              protein_total: 0,
              fat_total: 0,
              id: g + 1,
              name: `Tag ${g + 1}`,
              date: {
                seconds: moment.unix(newColumns.startDate.seconds).utc().subtract(-g, 'days').unix(),
              },
            };

            newColumns = {
              ...newColumns,
              dayEntries: [...newColumns.dayEntries, thisObject],
            };
          }
        }
      }

      setStartDate(null);
      setEndDate(null);

      let isPlanValid = true;

      if (userData?.role === 1) {
        planList?.forEach(function (key) {
          const thisCheckPlanStartDate = moment.unix(key.startDate.seconds).utc().format('DD-MM-YY');
          const thisCheckPlanEndDate = moment.unix(key.endDate.seconds).utc().format('DD-MM-YY');

          const thisConvertedPlanStartDate = moment(thisCheckPlanStartDate, 'DD-MM-YY');
          const thisConvertedPlanEndDate = moment(thisCheckPlanEndDate, 'DD-MM-YY');

          const thisUserStartDate = moment(startDate).format('YYYY-MM-DD');
          const thisUserEndDate = moment(endDate).format('YYYY-MM-DD');

          const thisPlanRange = moment.range(thisConvertedPlanStartDate, thisConvertedPlanEndDate);
          const thisUserRange = moment.range(moment(thisUserStartDate), moment(thisUserEndDate));

          if (thisUserRange.overlaps(thisPlanRange, { adjacent: true }) === true) {
            isPlanValid = false;
          }
        });
      } else {
        userPlanList?.forEach(function (key) {
          const thisCheckPlanStartDate = moment.unix(key.startDate.seconds).utc().format('DD-MM-YY');
          const thisCheckPlanEndDate = moment.unix(key.endDate.seconds).utc().format('DD-MM-YY');

          const thisConvertedPlanStartDate = moment(thisCheckPlanStartDate, 'DD-MM-YY');
          const thisConvertedPlanEndDate = moment(thisCheckPlanEndDate, 'DD-MM-YY');

          const thisUserStartDate = moment(startDate).format('YYYY-MM-DD');
          const thisUserEndDate = moment(endDate).format('YYYY-MM-DD');

          const thisPlanRange = moment.range(thisConvertedPlanStartDate, thisConvertedPlanEndDate);
          const thisUserRange = moment.range(moment(thisUserStartDate), moment(thisUserEndDate));

          if (thisUserRange.overlaps(thisPlanRange, { adjacent: true }) === true) {
            isPlanValid = false;
          }
        });
      }

      if (isPlanValid) {
        setChangePlanDurationPopupClass('hidden');
        // setCurrentPlan(newColumns);
        savePlanChangesToFirebase(
          userData,
          newColumns,
          newColumns.uid,
          authContext.user?.uid,
          false,
          setCurrentPlan,
          tenant
        );
        toast.success('Die Laufzeit von dem Plan wurde erfolgreich geändert!');
      } else {
        toast.error('In diesem Zeitraum hast du schon einen anderen Plan. Bitte wähle ein anderes Datum');
      }
    } else {
      toast.error('Es ist etwas schief gelaufen!');
    }

    return false;
  };

  useEffect(() => {
    if (currentDayItem?.kcal_total !== undefined) {
      getMealImage('breakfast', currentDayId);
      getMealImage('lunch', currentDayId);
      getMealImage('dinner', currentDayId);
      getMealImage('snacks', currentDayId);

      if (
        location.state?.scrollPos !== undefined &&
        location.state?.from === 'nutrition-detail' &&
        location.state?.mealLabel !== undefined
      ) {
        setOpenRecipeOnload(true);
        setIngredientSearchPopupClass('block');
        setCurrentMealType(location.state?.mealLabel);
        setCurrentMealLabel(location.state?.mealLabel);
      } else {
        setOpenRecipeOnload(false);
      }

      if (typeof location.state !== undefined && location.state !== null && location.state?.mealLabel !== undefined) {
        if (location.state.mealLabel === 'breakfast') {
          if (sliderRef.current) {
            sliderRef.current.slickGoTo(0);
          }
          if (sliderRefMobile.current) {
            sliderRefMobile.current.slickGoTo(0);
          }
        } else if (location.state.mealLabel === 'lunch') {
          if (sliderRef.current) {
            sliderRef.current.slickGoTo(1);
          }
          if (sliderRefMobile.current) {
            sliderRefMobile.current.slickGoTo(1);
          }
        } else if (location.state.mealLabel === 'dinner') {
          if (sliderRef.current) {
            sliderRef.current.slickGoTo(2);
          }
          if (sliderRefMobile.current) {
            sliderRefMobile.current.slickGoTo(2);
          }
        } else {
          if (sliderRef.current) {
            sliderRef.current.slickGoTo(3);
          }
          if (sliderRefMobile.current) {
            sliderRefMobile.current.slickGoTo(3);
          }
        }

        const userAgent = navigator.userAgent || navigator.vendor;

        setTimeout(() => {
          if (sliderContainerRef.current && !/iPad|iPhone|iPod/.test(userAgent)) {
            sliderContainerRef.current.scrollIntoView();
          }
        }, 1);

        setTimeout(() => {
          if (sliderContainerRefMobile.current && !/iPad|iPhone|iPod/.test(userAgent)) {
            sliderContainerRefMobile.current.scrollIntoView();
          }
        }, 10);
      }

      if (planDateArray.length === 0) {
        setPlanDateArray(
          getDateRange(
            moment.unix(currentPlan?.startDate.seconds).utc().format('YYYY-MM-DD'),
            moment.unix(currentPlan?.endDate.seconds).utc().format('YYYY-MM-DD')
          )
        );
      }

      if (
        typeof location.state !== undefined &&
        location.state !== null &&
        location.state?.from === 'recipe-detail-change'
      ) {
        const thisDayItem = currentPlan?.dayEntries.filter((item: any) => item.id === location.state.planDayId)[0];

        const thisItemType = thisDayItem?.[location.state.mealLabel].recipes.filter(
          (item: any) => item.id === location.state?.recipeChangeId
        )[0];

        const newColumns = {
          ...currentPlan,
          dayEntries: currentPlan.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== location.state.planDayId) return entries;
            return {
              ...entries,
              kcal_total:
                (parseFloat(entries.kcal_total) - parseFloat(thisItemType.kcal_total) < 0
                  ? 0
                  : parseFloat(entries.kcal_total) - parseFloat(thisItemType.kcal_total)) +
                parseFloat(tempRecipeObject.kcal_total),
              carbohydrates_total:
                (parseFloat(entries.carbohydrates_total) - parseFloat(thisItemType.carbohydrates_total) < 0
                  ? 0
                  : parseFloat(entries.carbohydrates_total) - parseFloat(thisItemType.carbohydrates_total)) +
                parseFloat(tempRecipeObject.carbohydrates_total),
              protein_total:
                (parseFloat(entries.protein_total) - parseFloat(thisItemType.protein_total) < 0
                  ? 0
                  : parseFloat(entries.protein_total) - parseFloat(thisItemType.protein_total)) +
                parseFloat(tempRecipeObject.protein_total),
              fat_total:
                (parseFloat(entries.fat_total) - parseFloat(thisItemType.fat_total) < 0
                  ? 0
                  : parseFloat(entries.fat_total) - parseFloat(thisItemType.fat_total)) +
                parseFloat(tempRecipeObject.fat_total),
              [location.state.mealLabel]: {
                ...entries[location.state.mealLabel],
                kcal_total:
                  (parseFloat(entries[location.state.mealLabel].kcal_total) - parseFloat(thisItemType.kcal_total) < 0
                    ? 0
                    : parseFloat(entries[location.state.mealLabel].kcal_total) - parseFloat(thisItemType.kcal_total)) +
                  parseFloat(tempRecipeObject.kcal_total),
                carbohydrates_total:
                  (parseFloat(entries[location.state.mealLabel].carbohydrates_total) -
                    parseFloat(thisItemType.carbohydrates_total) <
                  0
                    ? 0
                    : parseFloat(entries[location.state.mealLabel].carbohydrates_total) -
                      parseFloat(thisItemType.carbohydrates_total)) + parseFloat(tempRecipeObject.carbohydrates_total),
                protein_total:
                  (parseFloat(entries[location.state.mealLabel].protein_total) -
                    parseFloat(thisItemType.protein_total) <
                  0
                    ? 0
                    : parseFloat(entries[location.state.mealLabel].protein_total) -
                      parseFloat(thisItemType.protein_total)) + parseFloat(tempRecipeObject.protein_total),
                fat_total:
                  (parseFloat(entries[location.state.mealLabel].fat_total) - parseFloat(thisItemType.fat_total) < 0
                    ? 0
                    : parseFloat(entries[location.state.mealLabel].fat_total) - parseFloat(thisItemType.fat_total)) +
                  parseFloat(tempRecipeObject.fat_total),
                recipes: [
                  ...entries[location.state.mealLabel].recipes.filter(
                    (item: any) => item.id !== location.state?.recipeChangeId
                  ),
                  tempRecipeObject,
                ],
              },
            };
          }),
        };

        const newColumnsSetNewIds = {
          ...newColumns,
          dayEntries: newColumns.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== location.state.planDayId) return entries;
            return {
              ...entries,
              [location.state.mealLabel]: {
                ...entries[location.state.mealLabel],
                recipes: entries[location.state.mealLabel].recipes
                  .sort((a: any, b: any) => a.id - b.id)
                  .map((item: any) => {
                    return {
                      ...item,
                    };
                  }),
              },
            };
          }),
        };

        const checkIncompabilityArray = [] as any;
        const checkFormOfNutritionArray = [] as any;

        intolerancesOption.forEach(function (key) {
          if (
            typeof tempRecipeObject[key.toLowerCase()] !== undefined &&
            tempRecipeObject[key.toLowerCase()] !== null
          ) {
            if (tempRecipeObject[key.toLowerCase()] === true) {
              checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
            }
          }
        });

        formOfNutrition.forEach(function (key) {
          if (
            typeof tempRecipeObject[key.toLowerCase()] !== undefined &&
            tempRecipeObject[key.toLowerCase()] !== null
          ) {
            if (tempRecipeObject[key.toLowerCase()] === true) {
              checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
            }
          }
        });

        // Copy newColumns to variable because this needs to be updated in the following sections
        let thisRecipeData = { ...newColumnsSetNewIds };

        // Set elements true if incompatibility exists
        for (let g = 0; g < checkIncompabilityArray.length; g += 1) {
          thisRecipeData = { ...thisRecipeData, [checkIncompabilityArray[g].name]: true };
        }

        // Set elements true if incompatibility exists
        for (let g = 0; g < checkFormOfNutritionArray.length; g += 1) {
          thisRecipeData = { ...thisRecipeData, [checkFormOfNutritionArray[g].name]: true };
        }

        if (thisItemType !== undefined) {
          setCurrentDayId(location.state.planDayId);
          savePlanChangesToFirebase(
            userData,
            thisRecipeData,
            thisRecipeData.uid,
            authContext.user?.uid,
            false,
            undefined,
            tenant
          );
          toast.success('Das Rezept wurde erfolgreich geändert!');
        } else {
          toast.error('Es ist leider etwas schief gelaufen!');
        }
        history.replace({ state: {} });
      }

      if (typeof location.state !== undefined && location.state !== null && location.state?.from === 'recipe-detail') {
        const newColumns = {
          ...currentPlan,
          dayEntries: currentPlan.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== location.state.planDayId) return entries;
            return {
              ...entries,
              kcal_total: parseFloat(entries.kcal_total) + parseFloat(tempRecipeObject.kcal_total),
              carbohydrates_total:
                parseFloat(entries.carbohydrates_total) + parseFloat(tempRecipeObject.carbohydrates_total),
              protein_total: parseFloat(entries.protein_total) + parseFloat(tempRecipeObject.protein_total),
              fat_total: parseFloat(entries.fat_total) + parseFloat(tempRecipeObject.fat_total),
              [location.state.mealLabel]: {
                ...entries[location.state.mealLabel],
                kcal_total:
                  parseFloat(entries[location.state.mealLabel].kcal_total) + parseFloat(tempRecipeObject.kcal_total),
                carbohydrates_total:
                  parseFloat(entries[location.state.mealLabel].carbohydrates_total) +
                  parseFloat(tempRecipeObject.carbohydrates_total),
                protein_total:
                  parseFloat(entries[location.state.mealLabel].protein_total) +
                  parseFloat(tempRecipeObject.protein_total),
                fat_total:
                  parseFloat(entries[location.state.mealLabel].fat_total) + parseFloat(tempRecipeObject.fat_total),
                recipes: [tempRecipeObject, ...entries[location.state.mealLabel].recipes],
              },
            };
          }),
        };

        const newColumnsSetNewIds = {
          ...newColumns,
          dayEntries: newColumns.dayEntries.map((entries: any) => {
            if (parseFloat(entries.id) !== location.state.planDayId) return entries;
            return {
              ...entries,
              [location.state.mealLabel]: {
                ...entries[location.state.mealLabel],
                recipes: entries[location.state.mealLabel].recipes.map((item: any, index: any) => {
                  return {
                    ...item,
                    id: index.toString(),
                  };
                }),
              },
            };
          }),
        };

        const checkIncompabilityArray = [] as any;
        const checkFormOfNutritionArray = [] as any;

        intolerancesOption.forEach(function (key) {
          if (
            typeof tempRecipeObject[key.toLowerCase()] !== undefined &&
            tempRecipeObject[key.toLowerCase()] !== null
          ) {
            if (tempRecipeObject[key.toLowerCase()] === true) {
              checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
            }
          }
        });

        formOfNutrition.forEach(function (key) {
          if (
            typeof tempRecipeObject[key.toLowerCase()] !== undefined &&
            tempRecipeObject[key.toLowerCase()] !== null
          ) {
            if (tempRecipeObject[key.toLowerCase()] === true) {
              checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
            }
          }
        });

        // Copy newColumns to variable because this needs to be updated in the following sections
        let thisRecipeData = { ...newColumnsSetNewIds };

        // Set elements true if incompatibility exists
        for (let g = 0; g < checkIncompabilityArray.length; g += 1) {
          thisRecipeData = { ...thisRecipeData, [checkIncompabilityArray[g].name]: true };
        }

        // Set elements true if form of nutrition exists
        for (let g = 0; g < checkFormOfNutritionArray.length; g += 1) {
          thisRecipeData = { ...thisRecipeData, [checkFormOfNutritionArray[g].name]: true };
        }

        setCurrentDayId(location.state.planDayId);
        savePlanChangesToFirebase(
          userData,
          thisRecipeData,
          thisRecipeData.uid,
          authContext.user?.uid,
          false,
          undefined,
          tenant
        );
        toast.success('Das Rezept wurde erfolgreich hinzugefügt!');
        history.replace({ state: {} });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentPlan(plan);

    if (
      typeof location.state !== undefined &&
      location.state !== null &&
      location.state?.planDayId !== undefined &&
      (location.state?.from === 'plan-detail' || location.state?.from === 'nutrition-detail')
    ) {
      setCurrentDayId(location.state.planDayId);
      history.replace(window.location.pathname, null);
    }
  }, [plan, location.state]);

  useEffect(() => {
    if (favoritesPlansList !== undefined) {
      setPlanFavorite(true);
    }
    setTimeout(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollIntoView();
      }
    }, 1);
  }, [favoritesPlansList]);

  useEffect(() => {
    const excludeDatesStaticArray = [] as any;

    if (userData?.role === 1) {
      planList?.forEach(key => {
        key.dayEntries?.forEach(day => {
          excludeDatesStaticArray.push(
            moment
              .unix(day.date.seconds)
              .utc()
              .subtract(+1, 'days')
              .toDate()
          );
        });
      });
    } else {
      userPlanList?.forEach(key => {
        key.dayEntries?.forEach(day => {
          excludeDatesStaticArray.push(
            moment
              .unix(day.date.seconds)
              .utc()
              .subtract(+1, 'days')
              .toDate()
          );
        });
      });
    }

    setExcludeDateArray(excludeDatesStaticArray);
  }, [userPlanList, planList]);

  useEffect(() => {
    if (currentDayItem?.kcal_total !== undefined) {
      setTimeout(() => {
        if (document.getElementById('generateDay')?.offsetParent !== null) {
          if (tourRef.current && userData?.cookieBannerType !== 'initial' && planList !== undefined) {
            if (userData?.tour?.plansEdit === undefined) {
              tourRef.current.click();
            }
          }
        }

        if (document.getElementById('generateDayMobile')?.offsetParent !== null) {
          if (tourRefMobile.current && userData?.cookieBannerType !== 'initial' && planList !== undefined) {
            if (userData?.tour?.plansEdit === undefined) {
              tourRefMobile.current.click();
            }
          }
        }
      }, 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDayItem]);

  useEffect(() => {
    setCurrentMakroChangeType('');
    setCurrentMakroChangeType('standard');
    setCurrentMakroChangeCarbohydrates(0);
    setCurrentMakroChangeProtein(0);
    setCurrentMakroChangeFat(0);
  }, [changeCaloriesNeed]);

  return (
    <>
      <IngredientSearchPopup
        mealType={currentMealType}
        popupType="plan"
        openerClass={ingredientSearchPopupClass}
        setOpenerClass={setIngredientSearchPopupClass}
        updateRecipeState={setCurrentPlan}
        recipeStateValue={currentPlan}
        updateDayId={currentDayId}
        openGenerateMealPopup={() => openGeneratePopup('meal')}
        updateDayString={moment(moment.unix(currentDayItem?.date.seconds).utc())
          .locale('de')
          .startOf('day')
          .format('DD.MM.YY')}
        setVisibleIncompabilityPopup={setVisibleIncompatibilityPopup}
        openRecipeOnLoad={openRecipeOnload}
        setOpenRecipeOnLoad={setOpenRecipeOnload}
      />

      <IngredientSearchPopup
        mealType={currentMealType}
        popupType="recipeChange"
        openerClass={recipeChangePopupClass}
        setOpenerClass={setRecipeChangePopupClass}
        updateRecipeState={setCurrentPlan}
        recipeStateValue={currentPlan}
        updateDayId={currentDayId}
        openGenerateMealPopup={() => openGeneratePopup('meal')}
        recipeChangeId={currentChangeItemId}
        recipeChangeFunction={changeRecipe}
        setVisibleIncompabilityPopup={setVisibleIncompatibilityPopup}
        openRecipeOnLoad={openRecipeOnload}
        setOpenRecipeOnLoad={setOpenRecipeOnload}
      />

      <GenerateAlghorithmus
        currentDayItem={currentDayItem}
        currentPlan={currentPlan}
        currentDayId={currentDayId}
        setCurrentPlan={setCurrentPlan}
        generateDayPopupClass={generateDayPopupClass}
        setGenerateDayPopupClass={setGenerateDayPopupClass}
        currentMealType={currentMealType}
        planGenerateType={planGenerateType}
        planSettings={currentPlan?.settings !== undefined ? currentPlan?.settings : undefined}
        setVisibleIncompabilityPopup={setVisibleIncompatibilityPopup}
      />

      <div className={moveRecipePopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <SwitchHorizontalIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Rezept verschieben</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setMoveRecipePopupClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-30 font-extralight text-base pl-20 pr-15 pb-80">
                <div>Bitte wählen Sie aus wohin dieses Rezept verschoben werden soll!</div>
                <div>
                  <div className="flex gap-20 pt-20">
                    <div className="font-light my-auto w-130">Mahlzeit auswählen:</div>
                    <div className="flex-1">
                      <div>
                        <Select
                          options={mealTypeValues}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          unstyled
                          value={mealTypeValues.filter((item: any) => item.value === currentMoveMealTypeValue)}
                          onChange={e => setCurrentMoveMealTypeValue(e?.value)}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pl-20 pr-10 pb-40">
                <Button className="w-full" onClick={() => moveRecipe()}>
                  Verschieben
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={clearDayPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <RefreshIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Tag leeren</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setClearDayPopupClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-30 font-extralight text-base pl-20 pr-15">
                <div>Wollen Sie diesen Tag wirklich leeren?</div>
                <div>Alle gespeicherten Daten von diesem Tag werden dabei gelöscht!</div>
              </div>
              <div className="pt-30 pl-20 flex gap-30 pb-40">
                <div>
                  <Button className="w-full" onClick={() => clearDay()}>
                    Tag leeren
                  </Button>
                </div>
                <div>
                  <Button className="w-full" onClick={() => handleOpenClosePopups(setClearDayPopupClass, '', 'close')}>
                    Abbrechen
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={changePlanDurationPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <CalendarIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Laufzeit ändern</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => closeChangeDurationPopup()}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className={styles.editPictureIngridientPopupContent}>
                <div className="mx-20 pt-20 pb-40 font-extralight">
                  <div>
                    Aktuell läuft dein Plan vom{' '}
                    <span className="font-bold">
                      {moment.unix(plan?.startDate.seconds).utc().format('DD[.]MM[.]YY')} -{' '}
                      {moment.unix(plan?.endDate.seconds).utc().format('DD[.]MM[.]YY')}
                    </span>
                    .
                  </div>

                  <div className="pt-30">Bitte wähle einen neuen Zeitraum aus:</div>
                  <div className="flex">
                    <div className="w-full md:w-3/4 font-light left text-xs border-opacity-30">
                      <DatePicker
                        className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        locale="de"
                        excludeDates={excludeDateArray}
                        selectsRange
                        dateFormat="dd.MM.yyyy"
                        withPortal
                        customInput={React.createElement(React.forwardRef(CustomInputChangeDuration))}
                        calendarStartDay={1}
                        onFocus={e => e.target.blur()}
                      />
                    </div>
                  </div>
                </div>
                <div className="pl-20 pr-10 pb-40">
                  <Button className="w-full" onClick={() => changePlanDuration()}>
                    Laufzeit ändern
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={moveIngredientPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <SwitchHorizontalIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Lebensmittel verschieben</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setMoveIngredientPopupClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-30 font-extralight text-base pl-20 pr-15 pb-80">
                <div>Bitte wählen Sie aus wohin dieses Lebensmittel verschoben werden soll!</div>
                <div>
                  <div className="flex gap-20 pt-20">
                    <div className="font-light my-auto w-130">Mahlzeit auswählen:</div>
                    <div className="flex-1">
                      <div>
                        <Select
                          options={mealTypeValues}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          unstyled
                          value={mealTypeValues.filter((item: any) => item.value === currentMoveMealTypeValue)}
                          onChange={e => setCurrentMoveMealTypeValue(e?.value)}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pl-20 pr-10 pb-40">
                <Button className="w-full" onClick={() => moveIngredient()}>
                  Verschieben
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={userMacroPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <ChartPieIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Bedarf anpassen</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setUserMacroPopupClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-30 font-extralight text-base pl-20 pr-15 pb-40">
                <div>Dein aktueller Bedarf aus deinen Profilwerten:</div>
                <div className="pt-10 flex md:pl-20">
                  <div className={styles.nutritionBorderItemFirst}>
                    <div>
                      <div className="font-semibold text-base">
                        {currentDayItem?.custom_kcal_total !== undefined
                          ? currentDayItem?.custom_kcal_total
                          : currentPlan?.custom_kcal_total !== undefined
                          ? currentPlan?.custom_kcal_total
                          : Math.round(parseFloat(userData!.calories))}
                      </div>
                      <div className={styles.recipeLabel}>Kalorien</div>
                    </div>
                    <span className="divider" />
                  </div>
                  <div className={styles.nutritionBorderItem}>
                    <div className="font-semibold text-base">
                      {currentDayItem?.custom_carbohydrates_total !== undefined
                        ? currentDayItem?.custom_carbohydrates_total
                        : currentPlan?.custom_carbohydrates_total !== undefined
                        ? currentPlan?.custom_carbohydrates_total
                        : userData?.carbohydrates_total}
                      g
                    </div>
                    <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                  </div>
                  <div className={styles.nutritionBorderItem}>
                    <div className="font-semibold text-base">
                      {currentDayItem?.custom_protein_total !== undefined
                        ? currentDayItem?.custom_protein_total
                        : currentPlan?.custom_protein_total !== undefined
                        ? currentPlan?.custom_protein_total
                        : userData?.protein_total}
                      g
                    </div>
                    <div className={styles.recipeLabel}>{t('Protein')}</div>
                  </div>
                  <div className="text-center px-20">
                    <div className="font-semibold text-base">
                      {currentDayItem?.custom_fat_total !== undefined
                        ? currentDayItem?.custom_fat_total
                        : currentPlan?.custom_fat_total !== undefined
                        ? currentPlan?.custom_fat_total
                        : userData?.fat_total}
                      g
                    </div>
                    <div className={styles.recipeLabel}>{t('Fat')}</div>
                  </div>
                </div>
                <div className="pt-30">Deine aktuelle Makronährstoff Verteilung:</div>
                <div className="flex">
                  <div className={styles.makroBorderFirst}>
                    <span className="font-semibold">{currentCarbohydratesPercent}%</span> Kohlenhydrate
                  </div>
                  <div className={styles.makroBorder}>
                    <span className="font-semibold">{currentProteinPercent}%</span> Eiweiß
                  </div>
                  <div className="px-15 text-12">
                    <span className="font-semibold">{currentFatPercent}%</span> Fett
                  </div>
                </div>

                {changeCaloriesNeed ? (
                  <>
                    <div className="pt-20">
                      <hr />
                    </div>
                    <div className="pt-10 flex justify-between">
                      <div className="pt-10 text-20 font-semibold">Neuer Bedarf</div>
                      <div className="pt-10 my-auto">
                        <Button onClick={() => setChangeCaloriesNeed(false)} buttonStyle="white" className="py-5">
                          Abbrechen
                        </Button>
                      </div>
                    </div>

                    <div className="pt-4">
                      <div className="block font-extralight pb-5">
                        Wenn du für diesen Plan einen anderen Bedarf nutzen möchtest, trage hier bitte deine Kalorien
                        ein:
                      </div>
                      <div className="w-120 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2">
                        <input
                          id="calories"
                          name="calories"
                          type="number"
                          onFocus={setBodyClassForMobileSafari}
                          onBlur={removeBodyClassForMobileSafari}
                          className="w-100 my-auto appearance-none block text-18 font-semibold placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor text-center"
                          onChange={updateMakroCalories}
                          ref={changeMakroCaloriesRef}
                          onKeyPress={e => keyPress(e, saveMakroChange)}
                        />
                      </div>
                    </div>
                    <div className="font-semibold pt-30">Makronährstoff Verteilung</div>
                    <div className="flex w-300 gap-40 pt-2">
                      <div className="flex-1">
                        <Select
                          name="makroType"
                          className="react-select-container-full"
                          classNamePrefix="react-select"
                          unstyled
                          options={makroTypes}
                          value={
                            currentMakroChangeType !== ''
                              ? makroTypes.filter((st: any) => st.value === currentMakroChangeType)
                              : makroTypes[0]
                          }
                          onChange={updateMakroType}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                    <div className="flex gap-20 md:gap-40 pt-20">
                      <div>
                        <div className="block text-sm font-regular mb-1">Kohlenhydrate</div>
                        <div className="w-80 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                          <input
                            id="carbohydrates"
                            name="carbohydrates"
                            type="number"
                            onFocus={setBodyClassForMobileSafari}
                            onBlur={removeBodyClassForMobileSafari}
                            disabled={currentMakroChangeType !== 'user'}
                            onChange={changeUserCarbohydrates}
                            defaultValue="50"
                            className="w-40 my-auto appearance-none block bg-transparent text-18 pl-5 font-semibold focus:outline-none bg-opacity-20 text-textColor "
                            ref={changeMakroCarbohydrateRef}
                          />
                          <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                            %
                          </span>
                        </div>
                        <div className="w-80 block text-sm font-extralight text-center pt-5">
                          {currentMakroChangeCarbohydrates}g
                        </div>
                      </div>

                      <div>
                        <div className="block text-sm font-regular mb-1">Eiweiß</div>
                        <div className="w-80 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                          <input
                            id="protein"
                            name="protein"
                            type="number"
                            onFocus={setBodyClassForMobileSafari}
                            onBlur={removeBodyClassForMobileSafari}
                            disabled={currentMakroChangeType !== 'user'}
                            onChange={changeUserProtein}
                            defaultValue="25"
                            className="w-40 my-auto appearance-none block text-18 pl-5 bg-transparent font-semibold focus:outline-none bg-opacity-20 text-textColor "
                            ref={changeMakroProteinRef}
                          />
                          <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                            %
                          </span>
                        </div>
                        <div className="w-80 block text-sm font-extralight text-center pt-5">
                          {currentMakroChangeProtein}g
                        </div>
                      </div>

                      <div>
                        <div className="block text-sm font-regular mb-1">Fett</div>
                        <div className="w-80 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                          <input
                            id="fat"
                            name="fat"
                            type="number"
                            onFocus={setBodyClassForMobileSafari}
                            onBlur={removeBodyClassForMobileSafari}
                            disabled={currentMakroChangeType !== 'user'}
                            onChange={changeUserFat}
                            defaultValue="25"
                            className="w-40 my-auto appearance-none block text-18 pl-5 bg-transparent font-semibold focus:outline-none bg-opacity-20 text-textColor "
                            ref={changeMakroFatRef}
                          />
                          <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                            %
                          </span>
                        </div>
                        <div className="w-80 block text-sm font-extralight text-center pt-5">
                          {currentMakroChangeFat}g
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="pt-30 pb-40">
                    <Button onClick={() => setChangeCaloriesNeed(true)}>Bedarf anpassen</Button>
                  </div>
                )}
              </div>

              {currentDayItem?.custom_carbohydrates_total !== undefined && changeCaloriesNeed ? (
                <>
                  <div className="pl-20 pr-10 pb-40">
                    <Button className="w-full" onClick={() => saveMakroDayChange()}>
                      Bedarf für diesen Tag ändern
                    </Button>
                  </div>
                </>
              ) : currentPlan?.custom_carbohydrates_total && changeCaloriesNeed ? (
                <div className="flex">
                  <div className="pl-20 pr-10 pb-40">
                    <Button className="w-full" onClick={() => saveMakroDayChange()}>
                      Bedarf für diesen Tag ändern
                    </Button>
                  </div>
                  <div className="pl-20 pr-10 pb-40">
                    <Button className="w-full" onClick={() => saveMakroChange()}>
                      Bedarf für diesen Plan ändern
                    </Button>
                  </div>
                </div>
              ) : (
                changeCaloriesNeed && (
                  <div className="flex">
                    <div className="pl-20 pr-10 pb-40">
                      <Button className="w-full" onClick={() => saveMakroDayChange()}>
                        Bedarf für diesen Tag ändern
                      </Button>
                    </div>
                    <div className="pl-20 pr-10 pb-40">
                      <Button className="w-full" onClick={() => saveMakroChange()}>
                        Bedarf für diesen Plan ändern
                      </Button>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={userMacroDayPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <ChartPieIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Bedarf für diesen Tag anpassen</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setUserMacroDayPopupClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-30 font-extralight text-base pl-20 pr-15 pb-80">
                <div>Dein aktueller Bedarf für diesen Tag:</div>
                <div className="pt-10 flex md:pl-20">
                  <div className={styles.nutritionBorderItemFirst}>
                    <div>
                      <div className="font-semibold text-base">
                        {currentDayItem?.custom_kcal_total !== undefined
                          ? currentDayItem?.custom_kcal_total
                          : currentPlan?.custom_kcal_total !== undefined
                          ? currentPlan?.custom_kcal_total
                          : Math.round(parseFloat(userData!.calories))}
                      </div>
                      <div className={styles.recipeLabel}>Kalorien</div>
                    </div>
                    <span className="divider" />
                  </div>
                  <div className={styles.nutritionBorderItem}>
                    <div className="font-semibold text-base">
                      {currentDayItem?.custom_carbohydrates_total !== undefined
                        ? currentDayItem?.custom_carbohydrates_total
                        : currentPlan?.custom_carbohydrates_total !== undefined
                        ? currentPlan?.custom_carbohydrates_total
                        : userData?.carbohydrates_total}
                      g
                    </div>
                    <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                  </div>
                  <div className={styles.nutritionBorderItem}>
                    <div className="font-semibold text-base">
                      {currentDayItem?.custom_protein_total !== undefined
                        ? currentDayItem?.custom_protein_total
                        : currentPlan?.custom_protein_total !== undefined
                        ? currentPlan?.custom_protein_total
                        : userData?.protein_total}
                      g
                    </div>
                    <div className={styles.recipeLabel}>{t('Protein')}</div>
                  </div>
                  <div className="text-center px-20">
                    <div className="font-semibold text-base">
                      {currentDayItem?.custom_fat_total !== undefined
                        ? currentDayItem?.custom_fat_total
                        : currentPlan?.custom_fat_total !== undefined
                        ? currentPlan?.custom_fat_total
                        : userData?.fat_total}
                      g
                    </div>
                    <div className={styles.recipeLabel}>{t('Fat')}</div>
                  </div>
                </div>
                <div className="pt-30 text-20 font-semibold">Neuer Bedarf</div>

                <div className="pt-4">
                  <div className="block font-extralight pb-5">
                    Bitte trage hier deinen gewünschten Kalorienbedarf für diesen Tag ein:
                  </div>
                  <div className="w-120 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2">
                    <input
                      id="calories"
                      name="calories"
                      type="number"
                      onFocus={setBodyClassForMobileSafari}
                      onBlur={removeBodyClassForMobileSafari}
                      defaultValue={currentMakroChangeCalories}
                      className="w-100 my-auto appearance-none block text-18 font-semibold placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor text-center"
                      onChange={updateMakroCalories}
                      ref={changeMakroCaloriesDayRef}
                      onKeyPress={e => keyPress(e, saveMakroDayChange)}
                    />
                  </div>
                </div>
                <div className="flex gap-20 md:gap-40 pt-20">
                  <div>
                    <div className="block text-sm font-regular mb-1">Kohlenhydrate</div>
                    <div className="w-80 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                      <input
                        id="carbohydrates"
                        name="carbohydrates"
                        type="number"
                        onFocus={setBodyClassForMobileSafari}
                        onBlur={removeBodyClassForMobileSafari}
                        disabled
                        defaultValue="50"
                        className="w-40 my-auto appearance-none block text-18 pl-5 font-semibold placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor "
                      />
                      <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                        %
                      </span>
                    </div>
                    <div className="w-80 block text-sm font-extralight text-center pt-5">
                      {currentMakroChangeCarbohydrates}g
                    </div>
                  </div>

                  <div>
                    <div className="block text-sm font-regular mb-1">Eiweiß</div>
                    <div className="w-80 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                      <input
                        id="protein"
                        name="protein"
                        type="number"
                        onFocus={setBodyClassForMobileSafari}
                        onBlur={removeBodyClassForMobileSafari}
                        disabled
                        defaultValue="25"
                        className="w-40 my-auto appearance-none block text-18 pl-5 font-semibold placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor "
                      />
                      <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                        %
                      </span>
                    </div>
                    <div className="w-80 block text-sm font-extralight text-center pt-5">
                      {currentMakroChangeProtein}g
                    </div>
                  </div>

                  <div>
                    <div className="block text-sm font-regular mb-1">Fett</div>
                    <div className="w-80 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                      <input
                        id="fat"
                        name="fat"
                        type="number"
                        onFocus={setBodyClassForMobileSafari}
                        onBlur={removeBodyClassForMobileSafari}
                        disabled
                        defaultValue="25"
                        className="w-40 my-auto appearance-none block text-18 pl-5 font-semibold placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor "
                      />
                      <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                        %
                      </span>
                    </div>
                    <div className="w-80 block text-sm font-extralight text-center pt-5">{currentMakroChangeFat}g</div>
                  </div>
                </div>
              </div>
              <div className="pl-20 pr-10 pb-40">
                <Button className="w-full" onClick={() => saveMakroDayChange()}>
                  Bedarf ändern
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={copyRecipePopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <DocumentDuplicateIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Rezept kopieren</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setCopyRecipePopupClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-20 font-extralight text-base pl-20 pr-15 pb-80">
                <div className="pb-15">
                  <div className="pb-10">Wie möchtest du das Rezept kopieren?</div>
                  <Select
                    options={copyTypeValues}
                    className="react-select-container-full"
                    classNamePrefix="react-select"
                    unstyled
                    value={copyTypeValues.filter((item: any) => item.value === currentCopyTypeValue)}
                    onChange={e => setCurrentCopyTypeValue(e?.value)}
                    isSearchable={false}
                  />
                </div>
                <div className="flex gap-20 pt-4">
                  <div className="font-light my-auto w-130">Aktuelles Datum:</div>
                  <div className="font-light">
                    {moment.unix(currentDayItem?.date.seconds).utc().format('DD[.]MM[.]YY')}
                  </div>
                </div>
                {currentCopyTypeValue === 'date' && (
                  <>
                    <div>
                      <div className="flex gap-20 pt-4">
                        <div className="font-light my-auto w-130">Zu welchem Datum?</div>
                        <div className="flex-1">
                          <div>
                            <DatePicker
                              className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                              dateFormat="dd.MM.yyyy"
                              locale="de"
                              selected={startDate}
                              onChange={changeCopyDate}
                              customInput={React.createElement(React.forwardRef(CustomCalendarInput))}
                              calendarStartDay={1}
                              onFocus={e => e.target.blur()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {currentCopyTypeValue === 'days' && (
                  <>
                    <div>
                      <div className="text-14">
                        Wähle die Wochentage aus und das Rezept wird zu diesen Tagen kopiert!
                      </div>
                      <div className="flex flex-wrap gap-10 pt-10">
                        <Checkbox label="Montag" name="monday" register="t" />
                        <Checkbox label="Dienstag" name="monday" register="t" />
                        <Checkbox label="Mittwoch" name="monday" register="t" />
                        <Checkbox label="Donnerstag" name="monday" register="t" />
                        <Checkbox label="Freitag" name="monday" register="t" />
                        <Checkbox label="Samstag" name="monday" register="t" />
                        <Checkbox label="Sonntag" name="monday" register="t" />
                      </div>
                    </div>
                  </>
                )}
                <div>
                  <div className="flex gap-20 pt-4">
                    <div className="font-light my-auto w-130">Zu welcher Mahlzeit?</div>
                    <div className="flex-1">
                      <div>
                        <Select
                          options={mealTypeValues}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          unstyled
                          value={mealTypeValues.filter((item: any) => item.value === currentCopyMealTypeValue)}
                          onChange={e => setCurrentCopyMealTypeValue(e?.value)}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pl-20 pr-10 pb-40">
                <Button className="w-full" onClick={() => copyRecipe()}>
                  Kopieren
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={copyIngredientPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <DocumentDuplicateIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Lebensmittel kopieren</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setCopyIngredientPopupClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-20 font-extralight text-base pl-20 pr-15 pb-80">
                <div className="pb-15">
                  <div className="pb-10">Wie möchtest du das Lebensmittel kopieren?</div>
                  <Select
                    options={copyTypeValues}
                    className="react-select-container-full"
                    classNamePrefix="react-select"
                    unstyled
                    value={copyTypeValues.filter((item: any) => item.value === currentCopyTypeValue)}
                    onChange={e => setCurrentCopyTypeValue(e?.value)}
                    isSearchable={false}
                  />
                </div>
                <div className="flex gap-20 pt-4">
                  <div className="font-light my-auto w-130">Aktuelles Datum:</div>
                  <div className="font-light">
                    {moment.unix(currentDayItem?.date.seconds).utc().format('DD[.]MM[.]YY')}
                  </div>
                </div>
                {currentCopyTypeValue === 'date' && (
                  <>
                    <div>
                      <div className="flex gap-20 pt-4">
                        <div className="font-light my-auto w-130">Zu welchem Datum?</div>
                        <div className="flex-1">
                          <div>
                            <DatePicker
                              className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                              dateFormat="dd.MM.yyyy"
                              selected={startDate}
                              locale="de"
                              onChange={changeCopyDate}
                              customInput={React.createElement(React.forwardRef(CustomCalendarInput))}
                              calendarStartDay={1}
                              onFocus={e => e.target.blur()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {currentCopyTypeValue === 'days' && (
                  <>
                    <div>
                      <div className="text-14">
                        Wähle die Wochentage aus und das Rezept wird zu diesen Tagen kopiert!
                      </div>
                      <div className="flex flex-wrap gap-10 pt-10">
                        <Checkbox label="Montag" name="monday" register="t" />
                        <Checkbox label="Dienstag" name="monday" register="t" />
                        <Checkbox label="Mittwoch" name="monday" register="t" />
                        <Checkbox label="Donnerstag" name="monday" register="t" />
                        <Checkbox label="Freitag" name="monday" register="t" />
                        <Checkbox label="Samstag" name="monday" register="t" />
                        <Checkbox label="Sonntag" name="monday" register="t" />
                      </div>
                    </div>
                  </>
                )}
                <div>
                  <div className="flex gap-20 pt-4">
                    <div className="font-light my-auto w-130">Zu welcher Mahlzeit?</div>
                    <div className="flex-1">
                      <div>
                        <Select
                          options={mealTypeValues}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          unstyled
                          value={mealTypeValues.filter((item: any) => item.value === currentCopyMealTypeValue)}
                          onChange={e => setCurrentCopyMealTypeValue(e?.value)}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pl-20 pr-10 pb-40">
                <Button className="w-full" onClick={() => copyIngredient()}>
                  Kopieren
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={copyDayPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <DocumentDuplicateIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Tag kopieren</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setCopyDayPopupClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-20 font-extralight text-base pl-20 pr-15 pb-80">
                <div className="pb-15">
                  <div className="pb-10">Wie möchtest du diesen Tag kopieren?</div>
                  <Select
                    options={copyTypeValues}
                    className="react-select-container-full"
                    classNamePrefix="react-select"
                    unstyled
                    value={copyTypeValues.filter((item: any) => item.value === currentCopyTypeValue)}
                    onChange={e => setCurrentCopyTypeValue(e?.value)}
                    isSearchable={false}
                  />
                </div>
                {currentCopyTypeValue === 'date' && (
                  <>
                    <div>
                      <div className="flex gap-20 pt-4">
                        <div className="font-light my-auto w-130">Aktuelles Datum:</div>
                        <div className="font-light">
                          {moment.unix(currentDayItem?.date.seconds).utc().format('DD[.]MM[.]YY')}
                        </div>
                      </div>
                      <div className="flex gap-20 pt-4">
                        <div className="font-light my-auto w-130">Zu welchem Datum?</div>
                        <div className="flex-1">
                          <div>
                            <DatePicker
                              className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                              dateFormat="dd.MM.yyyy"
                              selected={startDate}
                              locale="de"
                              onChange={changeCopyDate}
                              customInput={React.createElement(React.forwardRef(CustomCalendarInput))}
                              calendarStartDay={1}
                              onFocus={e => e.target.blur()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="pl-20 pr-10 pb-40">
                <Button className="w-full" onClick={() => copyDay()}>
                  Kopieren
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={popupDynamicAddCopyClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <DocumentDuplicateIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Mahlzeit kopieren</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setPopupDynamicAddCopyClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-15 pl-20 pb-30 font-extralight">
                <div className="pb-15">
                  <div className="pb-10">Wie möchtest du diese Mahlzeit kopieren?</div>
                  <Select
                    options={copyTypeValuesIndexPage}
                    className="react-select-container-full"
                    classNamePrefix="react-select"
                    unstyled
                    value={copyTypeValuesIndexPage.filter((item: any) => item.value === currentCopyTypeValueIndexPage)}
                    onChange={e => setCurrentCopyTypeValueIndexPage(e?.value)}
                    isSearchable={false}
                  />
                </div>
                <div className="flex gap-20 pt-4">
                  <div className="font-light my-auto w-130">Aktuelles Datum:</div>
                  <div className="font-light">
                    {moment.unix(currentDayItem?.date.seconds).utc().format('DD[.]MM[.]YY')}
                  </div>
                </div>
                {currentCopyTypeValueIndexPage === 'date' && (
                  <>
                    <div>
                      <div className="flex gap-20 pt-4">
                        <div className="font-light my-auto w-130">Zu welchem Datum?</div>
                        <div className="flex-1 z-60">
                          <div>
                            <DatePicker
                              className="w-full appearance-none block py-1 px-2  rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                              dateFormat="dd.MM.yyyy"
                              locale="de"
                              selected={startDateIndexPage}
                              onChange={changeCopyDateIndexPage}
                              customInput={React.createElement(React.forwardRef(CustomInputIndexPage))}
                              calendarStartDay={1}
                              onFocus={e => e.target.blur()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {currentCopyTypeValueIndexPage === 'days' && (
                  <>
                    <div>
                      <div className="text-14">
                        Wähle die Wochentage aus und das Rezept wird zu diesen Tagen kopiert!
                      </div>
                      <div className="flex flex-wrap gap-10 pt-10">
                        <Checkbox label="Montag" name="monday" register="t" />
                        <Checkbox label="Dienstag" name="monday" register="t" />
                        <Checkbox label="Mittwoch" name="monday" register="t" />
                        <Checkbox label="Donnerstag" name="monday" register="t" />
                        <Checkbox label="Freitag" name="monday" register="t" />
                        <Checkbox label="Samstag" name="monday" register="t" />
                        <Checkbox label="Sonntag" name="monday" register="t" />
                      </div>
                    </div>
                  </>
                )}
                <div>
                  <div className="flex gap-20 pt-4">
                    <div className="font-light my-auto w-130">Zu welcher Mahlzeit?</div>
                    <div className="flex-1">
                      <div>
                        <Select
                          options={mealTypeValuesIndexPage}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          unstyled
                          value={mealTypeValuesIndexPage.filter(
                            (item: any) => item.value === currentCopyMealTypeValueIndexPage
                          )}
                          onChange={e => setCurrentCopyMealTypeValueIndexPage(e?.value)}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-20">Hier werden die Rezepte und Lebensmittel von deiner Mahlzeit angezeigt.</div>
                <div className="pt-15">
                  {currentCopyMealItem?.ingredients.map((item: any, index: number) => (
                    <div key={index}>
                      <PopupMealItem
                        itemObject={item}
                        planState={setCurrentCopyMealItem}
                        planStateValue={currentCopyMealItem}
                        type="Lebensmittel"
                      />
                    </div>
                  ))}
                  {currentCopyMealItem?.recipes.map((item: any, index: number) => (
                    <div key={index}>
                      <PopupMealItem
                        itemObject={item}
                        planState={setCurrentCopyMealItem}
                        planStateValue={currentCopyMealItem}
                        type="Rezept"
                      />
                    </div>
                  ))}
                </div>
                <div>
                  <strong>Hinweis: </strong>Wenn du diese Mahlzeit an eine andere Stelle kopierst werden die bisherigen
                  Rezepte und Lebensmittel an dem neuen Ort ersetzt!
                </div>
                <div className="pt-40">
                  <Button className="w-full" onClick={() => copyMeal()}>
                    Kopieren
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={newRecipeOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editIngridientPopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Neues Rezept hinzufügen</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => closeRecipePopup()}
                />
              </div>
            </div>
            <div className={styles.addRecipePopupContent} ref={recipePopupContentRef}>
              <div className="pt-15 pb-30 px-20">
                {currentStep === '1' && (
                  <NewRecipeStep1
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    recipeState={setCurrentAddRecipe}
                    recipeStateValue={currentAddRecipe}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentStep === '2' && (
                  <NewRecipeStep2
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    recipeState={setCurrentAddRecipe}
                    recipeStateValue={currentAddRecipe}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentStep === '3' && (
                  <NewRecipeStep3
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    recipeState={setCurrentAddRecipe}
                    recipeStateValue={currentAddRecipe}
                    mainPopup={setNewRecipeOverlayClass}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentStep === '4' && (
                  <NewRecipeStep4
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    recipeState={setCurrentAddRecipe}
                    recipeStateValue={currentAddRecipe}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentStep === '5' && (
                  <NewRecipeStep5
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    recipeState={setCurrentAddRecipe}
                    recipeStateValue={currentAddRecipe}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentStep === '6' && (
                  <NewRecipeStep6
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    recipeState={setCurrentAddRecipe}
                    recipeStateValue={currentAddRecipe}
                    mainPopup={setNewRecipeOverlayClass}
                    popupContainerRef={recipePopupContentRef}
                    isPlanRecipe
                    planState={setCurrentPlan}
                    planStateValue={currentPlan}
                    planDayId={currentDayId}
                    planMealType={currentMealType}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={closeRecipeOverlayClass}>
        <ClosePopup closeFunction={closeRecipe} reOpenFunction={reOpenRecipe} />
      </div>

      <div className={editFormOfNutritionOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <PencilIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Ernährungsform bearbeiten</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setEditFormOfNutritionOverlayClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-30 font-extralight text-base pl-20 pr-15 pb-30">
                <div>Folgende Ernährungsformen passen zu diesem Plan.</div>
                <div className="pt-1">Du kannst diese bei Bedarf anpassen (Mehrfachauswahl möglich).</div>
                <div className="pt-40">
                  {formOfNutrition.map((item: string, index: number) => (
                    <>
                      <SwitchButton
                        key={index}
                        label={item}
                        enabled={
                          currentPlan?.name !== undefined && typeof currentPlan[item.toLowerCase()] !== undefined
                            ? Boolean(currentPlan[item.toLowerCase()])
                            : false
                        }
                        isBackground={index % 2 === 0}
                        onChange={setFormOfNutritions}
                      />
                    </>
                  ))}
                </div>
              </div>
              <div className="pl-20 pr-10 pb-40">
                <Button
                  className="w-full"
                  onClick={() => handleOpenClosePopups(setEditFormOfNutritionOverlayClass, '', 'close')}
                >
                  {t('Finish')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={showIncombalitiesOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <ExclamationIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Unverträglichkeiten</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setShowIncombalitiesOverlayClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-30 font-extralight text-base pl-20 pr-15 pb-30">
                <div>Folgende Unverträglichkeiten haben wir in diesem Plan erkannt (ohne Gewähr):</div>
                <div className="pt-40">
                  {intolerancesOption.map((item: string, index: number) => (
                    <>
                      {currentPlan?.name !== undefined &&
                        typeof currentPlan[item.toLowerCase()] !== undefined &&
                        Boolean(currentPlan[item.toLowerCase()]) && (
                          <SwitchButton
                            key={index}
                            label={item}
                            enabled={
                              currentPlan?.name !== undefined && typeof currentPlan[item.toLowerCase()] !== undefined
                                ? Boolean(currentPlan[item.toLowerCase()])
                                : false
                            }
                            isBackground={index % 2 === 0}
                          />
                        )}
                    </>
                  ))}
                </div>
              </div>
              <div className="pl-20 pr-10 pb-40">
                <Button
                  className="w-full"
                  onClick={() => handleOpenClosePopups(setShowIncombalitiesOverlayClass, '', 'close')}
                >
                  {t('Close')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {currentDayItem?.kcal_total !== undefined && (
        <>
          <ShepherdTour
            steps={[
              {
                id: 'intro',
                attachTo: { element: '#generateDay', on: 'top' },
                buttons: [
                  {
                    classes: 'shepherd-button-secondary',
                    text: 'Beenden',
                    type: 'cancel',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Weiter',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Tag neu generieren!',
                text: [
                  '<p><strong>Keine Idee was du kochen sollst oder wie du deinen Tag sinnvoll planen kannst?</strong></p><p>Kein Problem! Klicke auf diesen Button und lass dir deinen Tag passend zu deinen Nährwerten von unserem Algorithmus vorschlagen.</p>',
                ],
                when: {
                  destroy() {
                    updateTourToFirebase(userData, 'plansEdit', authContext.user?.uid!, tenant);
                    setTimeout(() => {
                      document.getElementById('mainContainer')?.scrollIntoView();
                    }, 1);
                  },
                },
              },
              {
                id: 'intro',
                attachTo: { element: '#dayEntries', on: 'left' },
                buttons: [
                  {
                    classes: 'shepherd-button-secondary',
                    text: 'Beenden',
                    type: 'cancel',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Weiter',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Tagesansicht!',
                text: [
                  '<p>Hier siehst du alle Tage von deinem Plan.</p><p>Du kannst die Tage anklicken und siehst in diesem Abschnitt auch die bereits geplanten Werte.</p>',
                ],
              },
              {
                id: 'intro',
                attachTo: { element: '#addItem', on: 'left' },
                buttons: [
                  {
                    classes: 'shepherd-button-secondary',
                    text: 'Beenden',
                    type: 'cancel',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Weiter',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Neues Rezept oder Lebensmittel!',
                text: [
                  '<p>Durch Klick auf das + Icon kannst du bei jeder Mahlzeit neue Rezepte oder Lebensmittel hinzufügen.</p><p>Nutze auch gerne im Bereich Rezepte die Funktion Mahlzeit generieren.</p><p>Hier kannst du dir, passend zu deinen Nährwerten, Rezepte vorschlagen lassen.</p>',
                ],
              },
              {
                id: 'intro',
                attachTo: { element: '#actionItem', on: 'left' },
                buttons: [
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Zurück',
                    type: 'back',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Beenden',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Weitere Funktionen!',
                text: [
                  '<p>In unserem Aktions-Menü findest du weitere interessante Funktionen.</p><p>Du kannst z.B. komplette Mahlzeiten kopieren oder neu generieren lassen.</p><p>Es gibt ein weiteres Aktions-Menü wenn du ein Rezept oder Lebensmittel hinzugefügt hast. Schaue auch dort gerne einmal rein.</p>',
                ],
              },
            ]}
            tourOptions={tourOptions}
          >
            <TourButton />
          </ShepherdTour>

          <ShepherdTour
            steps={[
              {
                id: 'intro',
                attachTo: { element: '#generateDayMobile', on: 'bottom' },
                buttons: [
                  {
                    classes: 'shepherd-button-secondary',
                    text: 'Beenden',
                    type: 'cancel',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Weiter',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Tag neu generieren!',
                text: [
                  '<p><strong>Keine Idee was du kochen sollst oder wie du deinen Tag sinnvoll planen kannst?</strong></p><p>Kein Problem! Klicke auf diesen Button und lass dir deinen Tag passend zu deinen Nährwerten von unserem Algorithmus vorschlagen.</p>',
                ],
                when: {
                  destroy() {
                    updateTourToFirebase(userData, 'plansEdit', authContext.user?.uid!, tenant);
                    setTimeout(() => {
                      document.getElementById('mainContainer')?.scrollIntoView();
                    }, 1);
                  },
                },
              },
              {
                id: 'intro',
                attachTo: { element: '#dayEntriesMobile', on: 'bottom' },
                buttons: [
                  {
                    classes: 'shepherd-button-secondary',
                    text: 'Beenden',
                    type: 'cancel',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Weiter',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Tagesansicht!',
                text: [
                  '<p>Hier siehst du alle Tage von deinem Plan.</p><p>Du kannst die Tage anklicken und siehst in diesem Abschnitt auch die bereits geplanten Werte.</p>',
                ],
              },
              {
                id: 'intro',
                attachTo: { element: '#mobileSlider', on: 'bottom' },
                buttons: [
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Zurück',
                    type: 'back',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Beenden',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Mahlzeiten bearbeiten!',
                text: [
                  '<p>Hier kannst du für jede Mahlzeit über das + Icon neue Lebensmittel oder Rezepte hinzufügen.</p><p> Über das Aktionsmenü (3 vertikale Punkte Icon) werden dir weitere sinnvolle Funktionen angezeigt.</p>',
                ],
              },
            ]}
            tourOptions={tourOptions}
          >
            <TourButtonMobile />
          </ShepherdTour>
        </>
      )}

      <IncompatibilityOverlay visible={visibleIncompatibilityPopup} setVisible={setVisibleIncompatibilityPopup} />

      {currentDayItem?.kcal_total === undefined ? (
        <div className="h-full w-full md:flex hidden">
          <div className={styles.loading}>
            <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
          </div>
        </div>
      ) : (
        <div className="md:block hidden max-w-7xl">
          <div>
            <div className="w-32 pt-60">
              <div>
                <Link
                  to={{
                    pathname: `/plans/${id}`,
                    state: {
                      from: 'plan-edit',
                      planDayId: currentDayId,
                    },
                  }}
                >
                  <ButtonBack
                    text={t('Return')}
                    className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                  />
                </Link>
              </div>
            </div>
            <div className="flex pt-40 pb-10">
              <input
                name="planName"
                className="w-1/2 appearance-none block border border-opacity-30 border-textColor px-15 py-1 rounded-xl text-30 font-semibold placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor"
                type="text"
                onChange={updatePlanName}
                onFocus={setBodyClassForMobileSafari}
                onBlur={removeBodyClassForMobileSafari}
                defaultValue={currentPlan?.name}
                autoComplete="off"
              />
            </div>
          </div>
          {plan?.examplePlan === true && userData?.role === 1 && (
            <div>
              <div className="flex">
                <div className="pt-10 font-extralight">
                  <span className="font-semibold">Laufzeit:</span>{' '}
                  {moment.unix(currentPlan?.startDate.seconds).utc().format('DD[.]MM[.]YY')} -{' '}
                  {moment.unix(currentPlan?.endDate.seconds).utc().format('DD[.]MM[.]YY')}
                </div>
                <div className="z-10 cursor-pointer my-auto ml-10 pt-2">
                  <PencilIcon
                    width={18}
                    height={18}
                    className="text-accentColor"
                    onClick={() => setChangePlanDurationPopupClass('block')}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="flex gap-20 pr-20">
            <div className={styles.desktopColumn}>
              <div className="flex justify-between flex-wrap pr-20">
                {plan?.examplePlan === true ? (
                  <div className="pt-10 font-extralight">
                    {userData?.role === 2 ? <>Tortija-Beispielplan</> : <>Beispielplan</>}
                  </div>
                ) : (
                  <div className="flex">
                    <div className="pt-10 font-extralight">
                      <span className="font-semibold">Laufzeit:</span>{' '}
                      {moment.unix(currentPlan?.startDate.seconds).utc().format('DD[.]MM[.]YY')} -{' '}
                      {moment.unix(currentPlan?.endDate.seconds).utc().format('DD[.]MM[.]YY')}
                    </div>
                    <div className="z-10 cursor-pointer my-auto ml-10 pt-2">
                      <PencilIcon
                        width={18}
                        height={18}
                        className="text-accentColor"
                        onClick={() => setChangePlanDurationPopupClass('block')}
                      />
                    </div>
                  </div>
                )}
                {(userData?.role === 2 ||
                  tenantData?.settings?.functions?.recipeGenerator === undefined ||
                  userData?.isAdminUser ||
                  userData?.isTrainerUser ||
                  userData?.isLocationAdminUser ||
                  localStorage.getItem('currentUserToken') ||
                  tenantData?.settings?.functions?.recipeGenerator === true) && (
                  <div className="relative" id="generateDay">
                    <div>
                      <Button className="text-12 py-2 pr-40" onClick={() => openGeneratePopup('day')}>
                        Tag neu generieren
                      </Button>
                    </div>
                    <div className="absolute right-15 top-10">
                      <RefreshIcon width={17} height={17} className="text-buttonTextColor" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.desktopColumn}>
              <div className=" justify-between flex-wrap pl-20 hidden large:flex">
                <div className="font-semibold text-20">Tagesübersicht</div>
                <div className="flex flex-wrap gap-20">
                  <div className="relative">
                    <Button className="text-12 py-2 pr-40" onClick={() => setCopyDayPopupClass('block')}>
                      Tag kopieren
                    </Button>
                    <div className="absolute right-15 top-10">
                      <DocumentDuplicateIcon width={17} height={17} className="text-buttonTextColor" />
                    </div>
                  </div>
                  <div className="relative">
                    <Button className="text-12 py-2 pr-40" onClick={() => setClearDayPopupClass('block')}>
                      Tag leeren
                    </Button>
                    <div className="absolute right-15 top-10">
                      <TrashIcon width={17} height={17} className="text-buttonTextColor" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="block large:hidden">
            <div className=" justify-between flex-wrap pt-30 flex">
              <div className="font-semibold text-20">Tagesübersicht</div>
              <div className="flex flex-wrap gap-20">
                <div className="relative">
                  <Button className="text-12 py-2 pr-40" onClick={() => setCopyDayPopupClass('block')}>
                    Tag kopieren
                  </Button>
                  <div className="absolute right-15 top-10">
                    <DocumentDuplicateIcon width={17} height={17} className="text-buttonTextColor" />
                  </div>
                </div>
                <div className="relative">
                  <Button className="text-12 py-2 pr-40" onClick={() => setClearDayPopupClass('block')}>
                    Tag leeren
                  </Button>
                  <div className="absolute right-15 top-10">
                    <TrashIcon width={17} height={17} className="text-buttonTextColor" />
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-20 ml-20 pb-15 flex gap-15 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-600">
              {currentPlan?.dayEntries &&
                currentPlan?.dayEntries.map((item: any, index: number) => (
                  <>
                    <div
                      key={index}
                      onClick={() => changePlanDay(item.id)}
                      onKeyDown={() => changePlanDay(item.id)}
                      aria-hidden="true"
                      className="cursor-pointer"
                    >
                      <div
                        className={
                          currentDayId === index + 1
                            ? 'bg-accentColor text-buttonTextColor rounded-full  w-50 h-50 pt-5 border-transparent border-2 hover:border-accentColor'
                            : 'bg-lightGray rounded-full  w-50 h-50 pt-5 border-transparent border-2 hover:border-accentColor'
                        }
                      >
                        {currentPlan?.examplePlan && userData?.role !== 1 ? (
                          <>
                            <div className="font-thin text-10 text-center">Tag</div>
                            <div className="text-17 font-light text-center">{index + 1}</div>
                          </>
                        ) : (
                          <>
                            <div className="font-thin text-10 text-center">
                              {moment(moment.unix(currentPlan?.startDate.seconds).utc())
                                .locale('de')
                                .subtract(-index, 'days')
                                .startOf('day')
                                .format('dd')}
                            </div>
                            <div className="text-17 font-light text-center">
                              {moment(moment.unix(currentPlan?.startDate.seconds).utc())
                                .subtract(-index, 'days')
                                .startOf('day')
                                .format('D')}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ))}
            </div>
            <div className="rounded-3xl bg-lightGray mt-20 mx-6 pb-40">
              <div className="font-extralight pt-20 pb-10 px-20 text-14">
                Dieser Tag würde deinen aktuellen Bedarf folgendermaßen decken
              </div>

              <MakroCircles
                editFunction={openMakroChangePopup}
                kcal_value={currentDayItem?.kcal_total}
                carbohydrates_value={currentDayItem?.carbohydrates_total}
                protein_value={currentDayItem?.protein_total}
                fat_value={currentDayItem?.fat_total}
                custom_kcal_value={
                  currentDayItem?.custom_kcal_total
                    ? currentDayItem?.custom_kcal_total
                    : currentPlan?.custom_kcal_total
                    ? currentPlan?.custom_kcal_total
                    : undefined
                }
                custom_carbohydrates_value={
                  currentDayItem?.custom_carbohydrates_total
                    ? currentDayItem?.custom_carbohydrates_total
                    : currentPlan?.custom_carbohydrates_total
                    ? currentPlan?.custom_carbohydrates_total
                    : undefined
                }
                custom_protein_value={
                  currentDayItem?.custom_protein_total
                    ? currentDayItem?.custom_protein_total
                    : currentPlan?.custom_protein_total
                    ? currentPlan?.custom_protein_total
                    : undefined
                }
                custom_fat_value={
                  currentDayItem?.custom_fat_total
                    ? currentDayItem?.custom_fat_total
                    : currentPlan?.custom_fat_total
                    ? currentPlan?.custom_fat_total
                    : undefined
                }
              />
            </div>
          </div>
          <div className=" flex-wrap hidden large:flex">
            <div className={styles.desktopColumn}>
              <div className="pt-20 pb-20">
                <MakroCircles
                  onlyCircle
                  editFunction={openMakroChangePopup}
                  kcal_value={currentDayItem?.kcal_total}
                  custom_kcal_value={
                    currentDayItem?.custom_kcal_total
                      ? currentDayItem?.custom_kcal_total
                      : currentPlan?.custom_kcal_total
                      ? currentPlan?.custom_kcal_total
                      : undefined
                  }
                />
              </div>
            </div>
            <div className={styles.desktopColumnOverflow} id="dayEntries">
              <div>
                <div className="overflow-x-auto scrollbar-thin ml-40 scrollbar-thumb-gray-600 pt-20 flex gap-15 pb-20">
                  {currentPlan?.dayEntries &&
                    currentPlan?.dayEntries.map((item: any, index: number) => (
                      <>
                        <div
                          key={index}
                          onClick={() => changePlanDay(item.id)}
                          onKeyDown={() => changePlanDay(item.id)}
                          aria-hidden="true"
                          className="cursor-pointer"
                        >
                          <div
                            className={
                              currentDayId === index + 1
                                ? 'bg-accentColor text-buttonTextColor rounded-full  w-50 h-50 pt-5 border-transparent border-2 hover:border-accentColor'
                                : 'bg-lightGray rounded-full  w-50 h-50 pt-5 border-transparent border-2 hover:border-accentColor'
                            }
                          >
                            {currentPlan?.examplePlan && userData?.role !== 1 ? (
                              <>
                                <div className="font-thin text-10 text-center">Tag</div>
                                <div className="text-17 font-light text-center">{index + 1}</div>
                              </>
                            ) : (
                              <>
                                <div className="font-thin text-10 text-center">
                                  {moment(moment.unix(currentPlan?.startDate.seconds).utc())
                                    .locale('de')
                                    .subtract(-index, 'days')
                                    .startOf('day')
                                    .format('dd')}
                                </div>
                                <div className="text-17 font-light text-center">
                                  {moment(moment.unix(currentPlan?.startDate.seconds).utc())
                                    .subtract(-index, 'days')
                                    .startOf('day')
                                    .format('D')}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                </div>
                <div className="rounded-3xl bg-lightGray mt-20 pb-40 ml-40" ref={scrollContainerMacroCircles}>
                  <div className="font-extralight pt-20 pb-10 px-20 text-14">
                    Dieser Tag würde deinen aktuellen Bedarf folgendermaßen decken
                  </div>
                  <div className="">
                    <MakroCircles
                      carbohydrates_value={currentDayItem?.carbohydrates_total}
                      protein_value={currentDayItem?.protein_total}
                      fat_value={currentDayItem?.fat_total}
                      custom_kcal_value={
                        currentDayItem?.custom_kcal_total
                          ? currentDayItem?.custom_kcal_total
                          : currentPlan?.custom_kcal_total
                          ? currentPlan?.custom_kcal_total
                          : undefined
                      }
                      custom_carbohydrates_value={
                        currentDayItem?.custom_carbohydrates_total
                          ? currentDayItem?.custom_carbohydrates_total
                          : currentPlan?.custom_carbohydrates_total
                          ? currentPlan?.custom_carbohydrates_total
                          : undefined
                      }
                      custom_protein_value={
                        currentDayItem?.custom_protein_total
                          ? currentDayItem?.custom_protein_total
                          : currentPlan?.custom_protein_total
                          ? currentPlan?.custom_protein_total
                          : undefined
                      }
                      custom_fat_value={
                        currentDayItem?.custom_fat_total
                          ? currentDayItem?.custom_fat_total
                          : currentPlan?.custom_fat_total
                          ? currentPlan?.custom_fat_total
                          : undefined
                      }
                      onlyHorizontalCharts
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="hidden md:block px-0 pt-60 pb-40 max-w-7xl">
        {currentDayItem?.kcal_total !== undefined && (
          <>
            <div ref={sliderContainerRef}>
              <div>
                <EditMealCard
                  mealType="breakfast"
                  planObject={currentDayItem}
                  planState={setCurrentPlan}
                  planStateValue={currentPlan}
                  dayId={currentDayId}
                  planDateArray={planDateArray}
                  copyItemState={setCurrentCopyMealItem}
                  popupDynamicAddCopyClass={setPopupDynamicAddCopyClass}
                  scrollRef={scrollContainerMacroCircles}
                  addItemState={setCurrentAddRecipe}
                  newRecipeOverlayClass={setNewRecipeOverlayClass}
                  currentRecipeStep={setCurrentStep}
                  openIngredientSearchPopupClass={setIngredientSearchPopupClass}
                  setMealType={setCurrentMealType}
                  moveIngredientPopupClass={setMoveIngredientPopupClass}
                  moveRecipePopupClass={setMoveRecipePopupClass}
                  copyRecipePopupClass={setCopyRecipePopupClass}
                  setCurrentChangeItemId={setCurrentChangeItemId}
                  copyIngredientPopupClass={setCopyIngredientPopupClass}
                  openRecipePopupClass={setRecipeChangePopupClass}
                  openGenerateMealPopupClass={openGeneratePopup}
                />
                <EditMealCard
                  mealType="lunch"
                  planObject={currentDayItem}
                  planState={setCurrentPlan}
                  planStateValue={currentPlan}
                  dayId={currentDayId}
                  planDateArray={planDateArray}
                  copyItemState={setCurrentCopyMealItem}
                  popupDynamicAddCopyClass={setPopupDynamicAddCopyClass}
                  scrollRef={scrollContainerMacroCircles}
                  addItemState={setCurrentAddRecipe}
                  newRecipeOverlayClass={setNewRecipeOverlayClass}
                  currentRecipeStep={setCurrentStep}
                  openIngredientSearchPopupClass={setIngredientSearchPopupClass}
                  setMealType={setCurrentMealType}
                  moveIngredientPopupClass={setMoveIngredientPopupClass}
                  copyIngredientPopupClass={setCopyIngredientPopupClass}
                  moveRecipePopupClass={setMoveRecipePopupClass}
                  copyRecipePopupClass={setCopyRecipePopupClass}
                  setCurrentChangeItemId={setCurrentChangeItemId}
                  openRecipePopupClass={setRecipeChangePopupClass}
                  openGenerateMealPopupClass={openGeneratePopup}
                />
                <EditMealCard
                  mealType="dinner"
                  planObject={currentDayItem}
                  planState={setCurrentPlan}
                  planStateValue={currentPlan}
                  dayId={currentDayId}
                  planDateArray={planDateArray}
                  copyItemState={setCurrentCopyMealItem}
                  popupDynamicAddCopyClass={setPopupDynamicAddCopyClass}
                  scrollRef={scrollContainerMacroCircles}
                  addItemState={setCurrentAddRecipe}
                  newRecipeOverlayClass={setNewRecipeOverlayClass}
                  currentRecipeStep={setCurrentStep}
                  openIngredientSearchPopupClass={setIngredientSearchPopupClass}
                  setMealType={setCurrentMealType}
                  moveIngredientPopupClass={setMoveIngredientPopupClass}
                  moveRecipePopupClass={setMoveRecipePopupClass}
                  copyRecipePopupClass={setCopyRecipePopupClass}
                  setCurrentChangeItemId={setCurrentChangeItemId}
                  copyIngredientPopupClass={setCopyIngredientPopupClass}
                  openRecipePopupClass={setRecipeChangePopupClass}
                  openGenerateMealPopupClass={openGeneratePopup}
                />
                <EditMealCard
                  mealType="snacks"
                  planObject={currentDayItem}
                  planState={setCurrentPlan}
                  planStateValue={currentPlan}
                  dayId={currentDayId}
                  planDateArray={planDateArray}
                  copyItemState={setCurrentCopyMealItem}
                  popupDynamicAddCopyClass={setPopupDynamicAddCopyClass}
                  scrollRef={scrollContainerMacroCircles}
                  addItemState={setCurrentAddRecipe}
                  newRecipeOverlayClass={setNewRecipeOverlayClass}
                  currentRecipeStep={setCurrentStep}
                  openIngredientSearchPopupClass={setIngredientSearchPopupClass}
                  setMealType={setCurrentMealType}
                  moveIngredientPopupClass={setMoveIngredientPopupClass}
                  moveRecipePopupClass={setMoveRecipePopupClass}
                  copyRecipePopupClass={setCopyRecipePopupClass}
                  setCurrentChangeItemId={setCurrentChangeItemId}
                  copyIngredientPopupClass={setCopyIngredientPopupClass}
                  openRecipePopupClass={setRecipeChangePopupClass}
                  openGenerateMealPopupClass={openGeneratePopup}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {currentDayItem?.kcal_total === undefined ? (
        <div className="h-full w-full flex">
          <div className={styles.loading}>
            <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
          </div>
        </div>
      ) : (
        <div className={styles.pageWrapper} ref={scrollContainerRef}>
          <div className={styles.recipeImageContainer} style={{ backgroundImage: `url(${plan?.imageUrl})` }}>
            <div className="flex justify-between">
              <div className="z-10 w-32 pt-50 md:pt-4 pl-4">
                <div className={styles.backBtnBg}>
                  <Link
                    to={{
                      pathname: `/plans/${id}`,
                      state: {
                        from: 'plan-edit',
                        planDayId: currentDayId,
                      },
                    }}
                  >
                    <ButtonBack text={t('Return')} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-20 mt-30">
            <input
              name="planName"
              className="w-full appearance-none block border border-opacity-30 border-textColor px-15 py-1 rounded-xl text-30 font-semibold placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor"
              type="text"
              onFocus={setBodyClassForMobileSafari}
              onBlur={removeBodyClassForMobileSafari}
              onChange={updatePlanName}
              defaultValue={currentPlan?.name}
              autoComplete="off"
            />
          </div>

          {plan?.examplePlan === true ? (
            <div className="pt-10 mx-20 font-extralight">
              {userData?.role === 2 ? <>Tortija-Beispielplan</> : <>Beispielplan</>}
            </div>
          ) : (
            <div className="flex mx-20">
              <div className="pt-20 font-extralight">
                <span className="font-semibold">Laufzeit:</span>{' '}
                {moment.unix(currentPlan?.startDate.seconds).utc().format('DD[.]MM[.]YY')} -{' '}
                {moment.unix(currentPlan?.endDate.seconds).utc().format('DD[.]MM[.]YY')}
              </div>
              <div className="z-10 cursor-pointer my-auto ml-10 pt-15">
                <PencilIcon
                  width={18}
                  height={18}
                  className="text-accentColor"
                  onClick={() => setChangePlanDurationPopupClass('block')}
                />
              </div>
            </div>
          )}
          {plan?.examplePlan === true && userData?.role === 1 && (
            <div className="mx-20">
              <div className="flex">
                <div className="pt-10 font-extralight">
                  <span className="font-semibold">Laufzeit:</span>{' '}
                  {moment.unix(currentPlan?.startDate.seconds).utc().format('DD[.]MM[.]YY')} -{' '}
                  {moment.unix(currentPlan?.endDate.seconds).utc().format('DD[.]MM[.]YY')}
                </div>
                <div className="z-10 cursor-pointer my-auto ml-10 pt-2">
                  <PencilIcon
                    width={18}
                    height={18}
                    className="text-accentColor"
                    onClick={() => setChangePlanDurationPopupClass('block')}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="pt-30 font-semibold px-6 text-20">Tagesübersicht</div>
          <div className="pt-15 flex gap-15 pb-15 mx-20 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-600">
            {currentPlan?.dayEntries &&
              currentPlan?.dayEntries.map((item: any, index: number) => (
                <>
                  <div
                    key={index}
                    onClick={() => changePlanDay(item.id)}
                    onKeyDown={() => changePlanDay(item.id)}
                    aria-hidden="true"
                    className="cursor-pointer"
                  >
                    <div
                      className={
                        currentDayId === index + 1
                          ? 'bg-accentColor text-buttonTextColor rounded-full  w-50 h-50 pt-5 border-transparent border-2 hover:border-accentColor'
                          : 'bg-lightGray rounded-full  w-50 h-50 pt-5 border-transparent border-2 hover:border-accentColor'
                      }
                    >
                      {currentPlan?.examplePlan && userData?.role !== 1 ? (
                        <>
                          <div className="font-thin text-10 text-center">Tag</div>
                          <div className="text-17 font-light text-center">{index + 1}</div>
                        </>
                      ) : (
                        <>
                          <div className="font-thin text-10 text-center">
                            {moment(moment.unix(currentPlan?.startDate.seconds).utc())
                              .locale('de')
                              .subtract(-index, 'days')
                              .startOf('day')
                              .format('dd')}
                          </div>
                          <div className="text-17 font-light text-center">
                            {moment(moment.unix(currentPlan?.startDate.seconds).utc())
                              .subtract(-index, 'days')
                              .startOf('day')
                              .format('D')}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ))}
          </div>
          <div className="pt-20 flex flex-wrap gap-20 ml-20">
            <div className="relative">
              <Button className="text-12 py-2 pr-40" onClick={() => setCopyDayPopupClass('block')}>
                Tag kopieren
              </Button>
              <div className="absolute right-15 top-10">
                <DocumentDuplicateIcon width={17} height={17} className="text-buttonTextColor" />
              </div>
            </div>
            <div className="relative">
              <Button className="text-12 py-2 pr-40" onClick={() => setClearDayPopupClass('block')}>
                Tag leeren
              </Button>
              <div className="absolute right-15 top-10">
                <TrashIcon width={17} height={17} className="text-buttonTextColor" />
              </div>
            </div>
          </div>
          <div className="rounded-3xl bg-lightGray mt-20 mx-6 pb-40" id="dayEntriesMobile">
            <div className="font-extralight pt-20 pb-10 px-20 text-14">
              Dieser Tag würde deinen aktuellen Bedarf folgendermaßen decken
            </div>
            {/*
          <div className="px-20 pb-20">
            <Button className="text-12 py-2" onClick={() => openMakroChangePopup('plan')}>
              Bedarf für diesen Plan anpassen
            </Button>
          </div>
          <div className="px-20 pb-20">
            <Button className="text-12 py-2" onClick={() => openMakroChangePopup('day')}>
              Bedarf für diesen Tag anpassen
            </Button>
          </div>
          */}
            <MakroCircles
              editFunction={openMakroChangePopup}
              kcal_value={currentDayItem?.kcal_total}
              carbohydrates_value={currentDayItem?.carbohydrates_total}
              protein_value={currentDayItem?.protein_total}
              fat_value={currentDayItem?.fat_total}
              custom_kcal_value={
                currentDayItem?.custom_kcal_total
                  ? currentDayItem?.custom_kcal_total
                  : currentPlan?.custom_kcal_total
                  ? currentPlan?.custom_kcal_total
                  : undefined
              }
              custom_carbohydrates_value={
                currentDayItem?.custom_carbohydrates_total
                  ? currentDayItem?.custom_carbohydrates_total
                  : currentPlan?.custom_carbohydrates_total
                  ? currentPlan?.custom_carbohydrates_total
                  : undefined
              }
              custom_protein_value={
                currentDayItem?.custom_protein_total
                  ? currentDayItem?.custom_protein_total
                  : currentPlan?.custom_protein_total
                  ? currentPlan?.custom_protein_total
                  : undefined
              }
              custom_fat_value={
                currentDayItem?.custom_fat_total
                  ? currentDayItem?.custom_fat_total
                  : currentPlan?.custom_fat_total
                  ? currentPlan?.custom_fat_total
                  : undefined
              }
            />
          </div>
          {(userData?.role === 2 ||
            tenantData?.settings?.functions?.recipeGenerator === undefined ||
            userData?.isAdminUser ||
            userData?.isTrainerUser ||
            userData?.isLocationAdminUser ||
            localStorage.getItem('currentUserToken') ||
            tenantData?.settings?.functions?.recipeGenerator === true) && (
            <div className="pt-40 mx-20 flex">
              <div className="relative" id="generateDayMobile">
                <div>
                  <Button className="text-12 py-2 pr-40" onClick={() => openGeneratePopup('day')}>
                    Tag neu generieren
                  </Button>
                </div>
                <div className="absolute right-15 top-10">
                  <RefreshIcon width={17} height={17} className="text-buttonTextColor" />
                </div>
              </div>
            </div>
          )}
          <div className="px-20 pt-20 pb-40">
            {currentDayItem?.kcal_total !== undefined && (
              <>
                <div ref={sliderContainerRefMobile} id="mobileSlider">
                  <Slider {...settings} ref={sliderRefMobile}>
                    <EditMealCard
                      mealType="breakfast"
                      planObject={currentDayItem}
                      planState={setCurrentPlan}
                      planStateValue={currentPlan}
                      dayId={currentDayId}
                      planDateArray={planDateArray}
                      copyItemState={setCurrentCopyMealItem}
                      popupDynamicAddCopyClass={setPopupDynamicAddCopyClass}
                      scrollRef={scrollContainerMacroCircles}
                      addItemState={setCurrentAddRecipe}
                      newRecipeOverlayClass={setNewRecipeOverlayClass}
                      currentRecipeStep={setCurrentStep}
                      openIngredientSearchPopupClass={setIngredientSearchPopupClass}
                      setMealType={setCurrentMealType}
                      moveIngredientPopupClass={setMoveIngredientPopupClass}
                      copyIngredientPopupClass={setCopyIngredientPopupClass}
                      moveRecipePopupClass={setMoveRecipePopupClass}
                      copyRecipePopupClass={setCopyRecipePopupClass}
                      setCurrentChangeItemId={setCurrentChangeItemId}
                      openRecipePopupClass={setRecipeChangePopupClass}
                      openGenerateMealPopupClass={openGeneratePopup}
                    />
                    <EditMealCard
                      mealType="lunch"
                      planObject={currentDayItem}
                      planState={setCurrentPlan}
                      planStateValue={currentPlan}
                      dayId={currentDayId}
                      planDateArray={planDateArray}
                      copyItemState={setCurrentCopyMealItem}
                      popupDynamicAddCopyClass={setPopupDynamicAddCopyClass}
                      scrollRef={scrollContainerMacroCircles}
                      addItemState={setCurrentAddRecipe}
                      newRecipeOverlayClass={setNewRecipeOverlayClass}
                      currentRecipeStep={setCurrentStep}
                      openIngredientSearchPopupClass={setIngredientSearchPopupClass}
                      setMealType={setCurrentMealType}
                      moveIngredientPopupClass={setMoveIngredientPopupClass}
                      copyIngredientPopupClass={setCopyIngredientPopupClass}
                      moveRecipePopupClass={setMoveRecipePopupClass}
                      copyRecipePopupClass={setCopyRecipePopupClass}
                      setCurrentChangeItemId={setCurrentChangeItemId}
                      openRecipePopupClass={setRecipeChangePopupClass}
                      openGenerateMealPopupClass={openGeneratePopup}
                    />
                    <EditMealCard
                      mealType="dinner"
                      planObject={currentDayItem}
                      planState={setCurrentPlan}
                      planStateValue={currentPlan}
                      dayId={currentDayId}
                      planDateArray={planDateArray}
                      copyItemState={setCurrentCopyMealItem}
                      popupDynamicAddCopyClass={setPopupDynamicAddCopyClass}
                      scrollRef={scrollContainerMacroCircles}
                      addItemState={setCurrentAddRecipe}
                      newRecipeOverlayClass={setNewRecipeOverlayClass}
                      currentRecipeStep={setCurrentStep}
                      openIngredientSearchPopupClass={setIngredientSearchPopupClass}
                      setMealType={setCurrentMealType}
                      moveIngredientPopupClass={setMoveIngredientPopupClass}
                      copyIngredientPopupClass={setCopyIngredientPopupClass}
                      moveRecipePopupClass={setMoveRecipePopupClass}
                      copyRecipePopupClass={setCopyRecipePopupClass}
                      setCurrentChangeItemId={setCurrentChangeItemId}
                      openRecipePopupClass={setRecipeChangePopupClass}
                      openGenerateMealPopupClass={openGeneratePopup}
                    />
                    <EditMealCard
                      mealType="snacks"
                      planObject={currentDayItem}
                      planState={setCurrentPlan}
                      planStateValue={currentPlan}
                      dayId={currentDayId}
                      planDateArray={planDateArray}
                      copyItemState={setCurrentCopyMealItem}
                      popupDynamicAddCopyClass={setPopupDynamicAddCopyClass}
                      scrollRef={scrollContainerMacroCircles}
                      addItemState={setCurrentAddRecipe}
                      newRecipeOverlayClass={setNewRecipeOverlayClass}
                      currentRecipeStep={setCurrentStep}
                      openIngredientSearchPopupClass={setIngredientSearchPopupClass}
                      setMealType={setCurrentMealType}
                      moveIngredientPopupClass={setMoveIngredientPopupClass}
                      copyIngredientPopupClass={setCopyIngredientPopupClass}
                      moveRecipePopupClass={setMoveRecipePopupClass}
                      copyRecipePopupClass={setCopyRecipePopupClass}
                      setCurrentChangeItemId={setCurrentChangeItemId}
                      openRecipePopupClass={setRecipeChangePopupClass}
                      openGenerateMealPopupClass={openGeneratePopup}
                    />
                  </Slider>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PlanEdit;

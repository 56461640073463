import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';
import { AuthContext } from 'providers/AuthProvider';
import { MainContext } from 'providers/MainProvider';
import { savePurchaseListToFirebase } from 'shared/functions/global';
import Button from 'components/Button';
import RecipeListSubItem from '../RecipeListSubItem';
import styles from './styles.module.scss';

type Props = {
  thisId?: number;
  recipeItem?: any;
};

const RecipeListItem: React.FC<Props> = ({ thisId, recipeItem }) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  const authContext = useContext(AuthContext);
  const { userData, tenant } = useContext(AuthContext);
  const { purchaseList } = useContext(MainContext);

  const addAllRecipesToPurchaseList = () => {
    if (purchaseList) {
      const thisNewIngredientObjectArray = [] as any;
      const thisNewRecipeObjectArray = [] as any;

      const thisRecipeBreakfastObject =
        recipeItem?.breakfast?.recipes.length > 0 &&
        recipeItem?.breakfast?.recipes?.map((recipe: any) => {
          thisNewRecipeObjectArray.push({
            name: recipe.name,
            id: recipe.id,
          });
          return 0;
        });

      const thisRecipeLunchObject =
        recipeItem?.lunch?.recipes.length > 0 &&
        recipeItem?.lunch?.recipes?.map((recipe: any) => {
          thisNewRecipeObjectArray.push({
            name: recipe.name,
            id: recipe.id,
          });
          return 0;
        });

      const thisRecipeDinnerObject =
        recipeItem?.dinner?.recipes.length > 0 &&
        recipeItem?.dinner?.recipes?.map((recipe: any) => {
          thisNewRecipeObjectArray.push({
            name: recipe.name,
            id: recipe.id,
          });
          return 0;
        });

      const thisRecipeSnacksObject =
        recipeItem?.snacks?.recipes.length > 0 &&
        recipeItem?.snacks?.recipes?.map((recipe: any) => {
          thisNewRecipeObjectArray.push({
            name: recipe.name,
            id: recipe.id,
          });
          return 0;
        });

      const thisNewIngredientBreakfastObject =
        recipeItem?.breakfast?.recipes.length > 0 &&
        recipeItem?.breakfast?.recipes?.map((recipe: any) => {
          recipe.ingredients?.map((ingredients: any) => {
            thisNewIngredientObjectArray.push({
              ...ingredients,
              amount:
                ingredients.piece !== 'g' && ingredients.piece !== 'ml'
                  ? parseFloat(ingredients.amount) * parseFloat(ingredients.preselect_g)
                  : parseFloat(ingredients.amount),
              recipeId: recipe.id,
              deleted: false,
            });
            return 0;
          });
          return 0;
        });

      const thisNewIngredientLunchObject =
        recipeItem?.lunch?.recipes.length > 0 &&
        recipeItem?.lunch?.recipes?.map((recipe: any) => {
          recipe.ingredients?.map((ingredients: any) => {
            thisNewIngredientObjectArray.push({
              ...ingredients,
              amount:
                ingredients.piece !== 'g' && ingredients.piece !== 'ml'
                  ? parseFloat(ingredients.amount) * parseFloat(ingredients.preselect_g)
                  : parseFloat(ingredients.amount),
              recipeId: recipe.id,
              deleted: false,
            });
            return 0;
          });
          return 0;
        });

      const thisNewIngredientDinnerObject =
        recipeItem?.dinner?.recipes.length > 0 &&
        recipeItem?.dinner?.recipes?.map((recipe: any) => {
          recipe.ingredients?.map((ingredients: any) => {
            thisNewIngredientObjectArray.push({
              ...ingredients,
              amount:
                ingredients.piece !== 'g' && ingredients.piece !== 'ml'
                  ? parseFloat(ingredients.amount) * parseFloat(ingredients.preselect_g)
                  : parseFloat(ingredients.amount),
              recipeId: recipe.id,
              deleted: false,
            });
            return 0;
          });
          return 0;
        });

      const thisNewIngredientSnacksObject =
        recipeItem?.snacks?.recipes.length > 0 &&
        recipeItem?.snacks?.recipes?.map((recipe: any) => {
          recipe.ingredients?.map((ingredients: any) => {
            thisNewIngredientObjectArray.push({
              ...ingredients,
              amount:
                ingredients.piece !== 'g' && ingredients.piece !== 'ml'
                  ? parseFloat(ingredients.amount) * parseFloat(ingredients.preselect_g)
                  : parseFloat(ingredients.amount),
              recipeId: recipe.id,
              deleted: false,
            });
            return 0;
          });
          return 0;
        });

      const thisIngredientObject = [...purchaseList[0].ingredients, ...thisNewIngredientObjectArray];

      const newColumns = {
        ...purchaseList[0],
        date: moment().utc().unix(),
        listType: 'recipe',
        recipes:
          purchaseList.length > 0
            ? [...thisNewRecipeObjectArray, ...purchaseList[0].recipes]
            : [...thisNewRecipeObjectArray],
        ingredients: purchaseList.length > 0 ? thisIngredientObject : [],
      };

      savePurchaseListToFirebase(userData, newColumns, authContext.user?.uid, tenant);
    }
  };

  return (
    <>
      <div className="rounded-3xl h-full  mb-10 p-20 shadow-2xl bg-lightGray">
        <div className="flex py-2 flex-wrap justify-between">
          <div className="text-20 font-semibold pb-10">
            {moment.unix(recipeItem?.date?.seconds).utc().format('DD.MM.YYYY')}
          </div>
          <div>
            <Button className="text-12 py-2 " onClick={() => addAllRecipesToPurchaseList()}>
              Alle hinzufügen
            </Button>
          </div>
        </div>
        {recipeItem?.breakfast?.recipes.length > 0 &&
          recipeItem?.breakfast?.recipes?.map((recipe: any, index: number) => (
            <div key={index}>
              <RecipeListSubItem recipeItem={recipe} />
            </div>
          ))}
        {recipeItem?.lunch?.recipes.length > 0 &&
          recipeItem?.lunch?.recipes?.map((recipe: any, index: number) => (
            <div key={index}>
              <RecipeListSubItem recipeItem={recipe} />
            </div>
          ))}
        {recipeItem?.dinner?.recipes.length > 0 &&
          recipeItem?.dinner?.recipes?.map((recipe: any, index: number) => (
            <div key={index}>
              <RecipeListSubItem recipeItem={recipe} />
            </div>
          ))}
        {recipeItem?.snacks?.recipes.length > 0 &&
          recipeItem?.snacks?.recipes?.map((recipe: any, index: number) => (
            <div key={index}>
              <RecipeListSubItem recipeItem={recipe} />
            </div>
          ))}
        <RecipeListSubItem />
      </div>
    </>
  );
};

export default RecipeListItem;

import React, { useContext } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { ChevronDownIcon } from '@heroicons/react/solid';
import SwitchButton from 'components/SwitchButton';

type Props = {
  functionsContentClass: string;
  functionsChevronClass: string;
  setFunctionsChevronClass: any;
  setFunctionsContentClass: any;
  setMacroChevronClass: any;
  setMacroContentClass: any;
  setLinkListChevronClass: any;
  setLinkListContentClass: any;
  setLocationsContentClass: any;
  setLocationsChevronClass: any;
  setInvitationContentClass: any;
  setInvitationChevronClass: any;
};

const SettingsFunctions: React.FC<Props> = ({
  functionsContentClass,
  setFunctionsChevronClass,
  setMacroChevronClass,
  setMacroContentClass,
  setLinkListChevronClass,
  setFunctionsContentClass,
  functionsChevronClass,
  setLinkListContentClass,
  setLocationsContentClass,
  setLocationsChevronClass,
  setInvitationChevronClass,
  setInvitationContentClass,
}) => {
  const { tenantData, setTenantData } = useContext(AuthContext);

  // Toggle Functions area
  const toggleFunctions = (): void => {
    if (functionsContentClass === 'hidden') {
      setFunctionsContentClass('block');
      setFunctionsChevronClass('text-accentColor transition duration-300 transform rotate-180');
    } else {
      setFunctionsContentClass('hidden');
      setFunctionsChevronClass('text-accentColor transition duration-300 transform rotate-360');
    }

    setMacroChevronClass('text-accentColor');
    setMacroContentClass('hidden');

    setLinkListChevronClass('text-accentColor');
    setLinkListContentClass('hidden');

    setLocationsChevronClass('text-accentColor');
    setLocationsContentClass('hidden');

    setInvitationChevronClass('text-accentColor');
    setInvitationContentClass('hidden');
  };

  const changeFunctions = (status: boolean, item: string): void => {
    const getKey = functionsArray.filter((arrayItem: any) => arrayItem.label === item)[0].value;

    const newObjectTenantData = {
      ...tenantData,
      settings: {
        ...(tenantData?.settings ?? ''),
        functions: {
          ...(tenantData?.settings?.functions ?? ''),
          [getKey]: status,
        },
      },
    } as any;

    setTenantData(newObjectTenantData);
  };

  const functionsArray = [
    {
      label: 'Rezepte Generator',
      subLabel: 'Soll die Funktion "Tag und Mahlzeit neu generieren" aktiv sein?',
      value: 'recipeGenerator',
    },
    {
      label: 'Tortija Rezepte',
      subLabel: 'Sollen die Tortija Rezepte ausgespielt werden?',
      value: 'tortijaRecipes',
    },
    {
      label: 'Tortija Beispielpläne',
      subLabel: 'Sollen die Tortija Beispielpläne ausgespielt werden?',
      value: 'tortijaExamplePlans',
    },
    {
      label: 'Tagesbewertung',
      subLabel: 'Soll die Tagesbewertung im Dashboard ausgepielt werden?',
      value: 'dayRate',
    },
    {
      label: 'Notizen',
      subLabel: 'Sollen die Notizen im Dashboard ausgepielt werden?',
      value: 'notices',
    },
    {
      label: 'Wasser-Tracker',
      subLabel: 'Soll der Wasser-Tracker im Dashboard ausgepielt werden?',
      value: 'waterTracker',
    },
    {
      label: 'Trainer - Mitglieder andere Standorte',
      subLabel: 'Darf der Trainer auch Mitglieder von anderen Standorten sehen?',
      value: 'trainerLocation',
    },
    {
      label: 'Einbindung Health Daten',
      subLabel: 'Sollen die Health Daten ausgelesen werden?',
      value: 'healthData',
    },
    {
      label: 'Kalorienbedarf anpassen',
      subLabel: 'Darf der Kunde seinen Kalorienbedarf selbstständig ändern?',
      value: 'caloriesGoal',
    },
    {
      label: 'Profil - Ziel ändern',
      subLabel: 'Darf der Kunde sein Ziel im Profil ändern?',
      value: 'profileGoal',
    },
    {
      label: 'Profil - Ernährungsform ändern',
      subLabel: 'Darf der Kunde seine Ernährungsform im Profil ändern?',
      value: 'profileEatForm',
    },
    {
      label: 'Profil - Essverhalten ändern',
      subLabel: 'Darf der Kunde sein Essverhalten im Profil ändern?',
      value: 'profileEatingHabits',
    },
    {
      label: 'Profil - Unverträglichkeiten ändern',
      subLabel: 'Darf der Kunde seine Unverträglichkeiten im Profil ändern?',
      value: 'profileIncompatibilities',
    },
  ] as any;

  return (
    <>
      <div className="mt-30 rounded-3xl bg-lightGray p-20">
        <div className="flex space-x-10 cursor-pointer" onClick={() => toggleFunctions()} aria-hidden="true">
          <div className="font-bold text-20">Funktionen</div>
          <div>
            <ChevronDownIcon className={functionsChevronClass} height={30} width={30} />
          </div>
        </div>
        <div className={functionsContentClass}>
          <div className="font-extralight pt-5 pb-20">
            An dieser Stelle können Sie einstellen, welche Funktionen bei Ihren Kunden aktiviert sind.
          </div>
          <div className="w-full xl:w-3/4 macBookPro:w-1/2">
            {functionsArray.map((item: any, index: number) => (
              <SwitchButton
                key={index}
                className="border border-accentColor mb-15 rounded-2xl"
                label={item.label}
                onChange={changeFunctions}
                subLabel={item.subLabel}
                enabled={
                  tenantData?.settings?.functions?.[item.value as keyof SettingsFunctions] === undefined ||
                  tenantData?.settings?.functions?.[item.value as keyof SettingsFunctions] === true
                }
                isBackground
                isCapitalize={false}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsFunctions;

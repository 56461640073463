import React, { useContext } from 'react';
import TransitionContainer from 'components/TransitionContainer';
import Headline from 'components/Headline';
import Button from 'components/Button';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'providers/AuthProvider';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

type Props = {
  isShown: boolean;
  title: string;
  goBack?: React.MouseEventHandler<HTMLButtonElement>;
};

const MyClub: React.FC<Props> = ({ isShown, title, goBack = () => {} }) => {
  const { t } = useTranslation();
  const { companyData, tenantData } = useContext(AuthContext);

  return (
    <TransitionContainer isShown={isShown}>
      <div>
        <div className={styles.header}>
          <Headline level={1} className="mb-20">
            {t(title)}
          </Headline>
          <Headline level={4} displayBackBtn goBack={goBack}>
            {t('Return')}
          </Headline>
        </div>
        <div className="pt-20">
          <div className="font-extralight">Hier findest du die Daten von deinem zugeordneten Club / Firma.</div>
          {tenantData?.theme?.logo !== undefined && (
            <div className="pt-40">
              <img className="h-50" src={tenantData?.theme?.logo} alt="Logo" />
            </div>
          )}
          <div className="flex pt-20">
            <div className="font-bold text-25">{companyData?.companyName}</div>
          </div>
          <div>
            {companyData?.street} {companyData?.streetNumber}
          </div>
          <div>
            {companyData?.zip} {companyData?.city}
          </div>
          {companyData?.website !== undefined && companyData?.website.length > 0 ? (
            <div className="pt-30">
              <Link to={{ pathname: companyData.website }} target="_blank">
                <Button buttonStyle="text">Zur Website</Button>
              </Link>
            </div>
          ) : (
            <></>
          )}
          {companyData?.street !== undefined &&
            companyData?.streetNumber !== undefined &&
            companyData?.zip !== undefined &&
            companyData?.city !== undefined && (
              <div className="pt-15">
                <Link
                  to={{
                    pathname: `https://maps.google.com/?q=${companyData?.street} ${companyData?.streetNumber} ${companyData?.zip} ${companyData?.city}`,
                  }}
                  target="_blank"
                >
                  <Button buttonStyle="text">Adresse auf Google Maps anschauen</Button>
                </Link>
              </div>
            )}
          {tenantData?.settings?.linkList !== undefined &&
            tenantData?.settings?.linkList.length > 0 &&
            (tenantData?.settings?.linkListPosition === undefined ||
              tenantData?.settings?.linkListPosition === 'club') && (
              <div className="pt-0 desktop:pt-60 md:pt-80">
                <div className="font-extralight text-25 pb-15 pt-20">Links</div>
                {tenantData?.settings?.linkList?.map((item: any, index: number) => (
                  <Link
                    to={{
                      pathname: item.url,
                    }}
                    target="_blank"
                    key={index}
                    className="cursor-pointer"
                  >
                    <div className="rounded-2xl bg-lightGray mb-15 p-20 border-transparent border-2 hover:border-accentColor max-w-quarter">
                      <div className="flex space-x-10 flex-wrap">
                        <div className="font-bold max-w-quarter">{item.name}</div>
                        <div className="cursor-pointer">
                          <div className="my-auto">
                            <ExternalLinkIcon className="text-accentColor" height={20} width={20} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
        </div>
      </div>
    </TransitionContainer>
  );
};

export default MyClub;

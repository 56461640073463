import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AuthContext } from 'providers/AuthProvider';
import Headline from 'components/Headline';
import Icon from 'components/Icon';
import { remainTrialDay } from 'shared/functions/global';
import Button from 'components/Button';

type Props = {};

const TrialContainer: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);
  const remainDay = remainTrialDay(userData?.created?.seconds);

  if (userData?.membership?.type === 'free' && userData?.role === 2) {
    return (
      <>
        <div className="rounded-3xl bg-lightGray w-full pl-20 pt-15 justify-between md:flex-wrap pb-15 pr-20 mt-30 mb-0 flex gap-10">
          <Headline className="my-auto flex gap-10 font-extralight text-12 md:text-14" level={5}>
            <Icon name="stopWatch" width={18} height={18} className="text-white my-auto" />
            {remainDay === 0 ? <>Testzeitraum endet heute</> : <> {t('Your trial ends', { day: remainDay })}</>}
          </Headline>
          <div className="w-1/2 md:w-auto my-auto">
            <Link to="/purchase">
              <Button className="py-1 text-12">Werde Mitglied</Button>
            </Link>
          </div>
        </div>
      </>
    );
  }
  if (moment(moment().unix()).isAfter(userData?.membership?.nextDate) && userData?.membership?.type === 'canceled') {
    return (
      <>
        <div className="rounded-3xl bg-lightGray w-full pl-20 pt-15 justify-between md:flex-wrap pb-15 pr-20 mt-30 mb-0 flex gap-10">
          <Headline className="my-auto flex gap-10 font-extralight text-12 md:text-14" level={5}>
            <Icon name="stopWatch" width={18} height={18} className="text-white my-auto" />
            Schade, du hast dein Abo gekündigt!
          </Headline>
          <div className="w-1/2 md:w-auto my-auto">
            <Link to="/purchase">
              <Button className="py-1 text-12">Werde erneut Mitglied</Button>
            </Link>
          </div>
        </div>
      </>
    );
  }

  return null;
};

export default TrialContainer;

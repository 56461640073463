import React, { useContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AdjustmentsIcon, RefreshIcon, XIcon } from '@heroicons/react/outline';
import Drawer from 'components/Drawer';
import Headline from 'components/Headline';
import ButtonGroup from 'components/ButtonGroup';
import MultiRangeSlider from 'components/MultiRangeSlider';
import { intolerancesOption } from 'shared/constants/profile-wizard';
import { FilterContext } from 'providers/FilterProvider';
import { defaultCaloriesRange } from 'shared/constants/filter';
import styles from './styles.module.scss';

type Props = {
  isFilterOpen?: boolean;
  closeFilterOpen: (value: boolean) => void;
};

const formOfNutritionPlan: string[] = ['Flexitarian', 'Vegan', 'Vegetarian', 'Ketogen'];

const FilterDrawerPlan: React.FC<Props> = ({ isFilterOpen = false, closeFilterOpen }) => {
  const { t } = useTranslation();
  const {
    initialNutritionPlan,
    changeNutritionPlan,
    initialIntolerancesPlan,
    changeIncompatibilitiesPlan,
    caloriesRangePlan,
    changeCaloriesRangePlan,
  } = useContext(FilterContext);

  const caloriesMinRef = useRef<HTMLDivElement>(null);
  const caloriesMaxRef = useRef<HTMLDivElement>(null);

  const [initialCaloriesMin, setInitialCaloriesMin] = useState(caloriesRangePlan.min);
  const [initialCaloriesMax, setInitialCaloriesMax] = useState(0);

  const selectCaloriesRange = (thisCaloriesValueMin: any, thisCaloriesValueMax: any) => {
    if (typeof thisCaloriesValueMax === 'number') {
      if (Number.isNaN(parseFloat(thisCaloriesValueMin.currentTarget.textContent))) {
        setInitialCaloriesMin(0);
        changeCaloriesRangePlan({
          min: 0,
          max: thisCaloriesValueMax,
        });
      } else {
        changeCaloriesRangePlan({
          min: parseFloat(thisCaloriesValueMin.currentTarget.textContent),
          max: thisCaloriesValueMax,
        });
      }
    }
    if (typeof thisCaloriesValueMin === 'number') {
      if (Number.isNaN(parseFloat(thisCaloriesValueMax.currentTarget.textContent))) {
        setInitialCaloriesMax(0);
        changeCaloriesRangePlan({
          min: thisCaloriesValueMin,
          max: defaultCaloriesRange.max,
        });
      } else {
        changeCaloriesRangePlan({
          min: thisCaloriesValueMin,
          max: parseFloat(thisCaloriesValueMax.currentTarget.textContent),
        });
      }
    }
  };

  const resetFilter = () => {
    changeIncompatibilitiesPlan([]);
    changeNutritionPlan([]);

    if (caloriesMinRef.current) {
      caloriesMinRef.current.innerHTML = defaultCaloriesRange.min.toString();
    }
    if (caloriesMaxRef.current) {
      caloriesMaxRef.current.innerHTML = defaultCaloriesRange.max.toString();
    }

    changeCaloriesRangePlan({
      min: defaultCaloriesRange.min,
      max: defaultCaloriesRange.max,
    });
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  useEffect(() => {
    setInitialCaloriesMin(caloriesRangePlan.min);
  }, [caloriesRangePlan.min]);

  return (
    <Drawer isOpen={isFilterOpen} closeDrawer={closeFilterOpen}>
      <div className="relative">
        <div className={styles.header}>
          <AdjustmentsIcon width={28} height={28} className={styles.filterIcon} />
          <Headline level={3}>{t('Filter')}</Headline>
        </div>
        <div className="absolute right-10 top-10">
          <XIcon
            onClick={() => closeFilterOpen(false)}
            width={25}
            height={25}
            className="text-accentColor mx-auto cursor-pointer"
          />
        </div>
        <div
          className="pb-20 flex gap-5 flex-wrap cursor-pointer"
          onClick={() => resetFilter()}
          onMouseDown={() => resetFilter()}
          aria-hidden="true"
        >
          <div className="my-auto">
            <RefreshIcon width={20} height={20} className="text-accentColor mx-auto cursor-pointer" />
          </div>
          <div className="hover:underline">Alles zurücksetzen</div>
        </div>
        <div className={styles.wrapper}>
          <>
            <div className="hidden">
              <div className="my-auto text-18 font-semibold">Kalorien</div>
              <div className="flex gap-50 flex-wrap justify-between pt-5">
                <div className="flex-1">
                  <div className="font-extralight pb-1">Von:</div>
                  <div className="w-full font-light left text-xs border-opacity-30">
                    <span
                      className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                      role="textbox"
                      contentEditable
                      onFocus={setBodyClassForMobileSafari}
                      onBlur={removeBodyClassForMobileSafari}
                      suppressContentEditableWarning
                      onInput={e => selectCaloriesRange(e, caloriesRangePlan.max)}
                      ref={caloriesMinRef}
                    >
                      {initialCaloriesMin === 0 ? '' : initialCaloriesMin}
                    </span>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="font-extralight pb-1">Bis:</div>
                  <div className="font-light left text-xs border-opacity-30 w-full">
                    <span
                      className="appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                      role="textbox"
                      contentEditable
                      suppressContentEditableWarning
                      onInput={e => selectCaloriesRange(caloriesRangePlan.min, e)}
                      ref={caloriesMaxRef}
                    >
                      {initialCaloriesMax === 0 ? '' : initialCaloriesMax}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <ButtonGroup
              options={formOfNutritionPlan}
              initialOption={initialNutritionPlan}
              label="Form of nutrition"
              itemsClassName="grid-cols-3"
              changeSelectedItem={changeNutritionPlan}
            />
            <hr />
            <ButtonGroup
              options={intolerancesOption}
              initialOption={initialIntolerancesPlan}
              label="Intolerances"
              itemsClassName="grid-cols-3"
              changeSelectedItem={changeIncompatibilitiesPlan}
            />
          </>
        </div>
      </div>
    </Drawer>
  );
};

export default FilterDrawerPlan;

import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { CreditCardIcon } from '@heroicons/react/outline';
import firebase from 'firebase';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import { toast } from 'react-toast';
import ButtonBack from 'components/ButtonBack';
import Checkbox from 'components/Checkbox';
import Container from 'components/Container';
import Headline from 'components/Headline';
import Icon from 'components/Icon';
import { AuthContext } from 'providers/AuthProvider';
import { monthlyPlanList, stripePlanID, yearlyPlanList } from 'shared/constants/global';
import { checkCouponValidFirebase, getIsWebView } from 'shared/functions/global';
import { defaultTextColor } from 'layouts/Theme';
import Button from 'components/Button';
import CheckoutForm from '../checkout';
import ButtonWrapper from './components/buttonWrapper';

import styles from './styles.module.scss';

type ParamType = {
  type: MembershipType;
};

const PaymentDetails: React.FC = () => {
  const { t } = useTranslation();
  const db = firebase.firestore();
  const { type } = useParams<ParamType>();

  let stripePromise: any;
  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLICKEY ?? '');
    }
    return stripePromise;
  };

  const [isCouponCodeChecked, setIsCouponCodeChecked] = useState(false);
  const [isCouponCodeValid, setIsCouponCodeValid] = useState(false);
  const [couponPrice, setCouponPrice] = useState(0);
  const [stripeCouponPlanId, setStripeCouponPlanId] = useState('');
  const [paypalCouponPlanId, setPaypalCouponPlanId] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [couponRabatt, setCouponRabatt] = useState(0);
  const [couponErrorText, setCouponErrorText] = useState('hidden');
  const [isCheckPending, setIsCheckPending] = useState(false);
  const [isSepaCC, setIsSepaCC] = useState(false);
  const [isSepaCCPending, setIsSepaCCPending] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState<any>(null);
  const [stripeSubscriptionId, setStripeSubscriptionId] = useState('');
  const [stripePaymentIntendId, setStripePaymentIntendId] = useState('');
  const { userData, user, tenant, theme } = useContext(AuthContext);
  const [loadingIAP, setLoadingIAP] = useState(false);
  const [iapError, setIAPError] = useState(false);
  const [iapErrorMessage, setIapErrorMessage] = useState('');

  const urlCouponCode = new URLSearchParams(window.location.search).get('coupon');

  const history = useHistory();

  let planDetail: MembershipPlanType;

  const CouponPlansYearly = [
    {
      coupon: process.env.REACT_APP_COUPON_10,
      stripePlanId: process.env.REACT_APP_STRIPE_PRO12_10_PERCENT_PLANID,
      paypalPlanId: process.env.REACT_APP_PAYPAL_PRO12_10_PERCENT_PLANID,
      price: 89,
    },
    {
      coupon: process.env.REACT_APP_COUPON_20,
      stripePlanId: process.env.REACT_APP_STRIPE_PRO12_20_PERCENT_PLANID,
      paypalPlanId: process.env.REACT_APP_PAYPAL_PRO12_20_PERCENT_PLANID,
      price: 79,
    },
    {
      coupon: process.env.REACT_APP_COUPON_30,
      stripePlanId: process.env.REACT_APP_STRIPE_PRO12_30_PERCENT_PLANID,
      paypalPlanId: process.env.REACT_APP_PAYPAL_PRO12_30_PERCENT_PLANID,
      price: 69,
    },
    {
      coupon: process.env.REACT_APP_COUPON_40,
      stripePlanId: process.env.REACT_APP_STRIPE_PRO12_40_PERCENT_PLANID,
      paypalPlanId: process.env.REACT_APP_PAYPAL_PRO12_40_PERCENT_PLANID,
      price: 59,
    },
    {
      coupon: process.env.REACT_APP_COUPON_50,
      stripePlanId: process.env.REACT_APP_STRIPE_PRO12_50_PERCENT_PLANID,
      paypalPlanId: process.env.REACT_APP_PAYPAL_PRO12_50_PERCENT_PLANID,
      price: 49,
    },
  ];

  switch (type) {
    case 'pro':
      planDetail = { ...monthlyPlanList[0] };
      break;
    case 'pro12':
      planDetail = { ...yearlyPlanList[0] };
      break;
    default:
      planDetail = { ...monthlyPlanList[0] };
      break;
  }

  const backToPurchase = () => {
    if (paymentIntent !== null) {
      deleteStripePaymentIntend();
      deleteStripeSubscription();
    }

    history.push('/purchase');
  };

  const deleteStripeSubscription = async () => {
    // Delete Stripe Subscription
    await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/cancel-subscription`, {
      subscriptionId: stripeSubscriptionId,
    });
  };

  const deleteStripePaymentIntend = async () => {
    // Delete Stripe Subscription
    await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/cancel-paymentIntend`, {
      invoiceId: stripePaymentIntendId,
    });
  };

  const checkCouponCode = async (thisCouponCode: any, stripeCouponCode: any) => {
    setIsCheckPending(true);
    const checkCode = await checkCouponValidFirebase(thisCouponCode);

    setCouponRabatt(checkCode);
    setCouponCode(thisCouponCode);

    if (checkCode === 10) {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setStripeCouponPlanId(CouponPlansYearly[0].stripePlanId as string);
      setPaypalCouponPlanId(CouponPlansYearly[0].paypalPlanId as string);
      setCouponPrice(89);
      stripeCouponCode.push(CouponPlansYearly[0].stripePlanId);
    } else if (checkCode === 20) {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setStripeCouponPlanId(CouponPlansYearly[1].stripePlanId as string);
      setPaypalCouponPlanId(CouponPlansYearly[1].paypalPlanId as string);
      setCouponPrice(79);
      stripeCouponCode.push(CouponPlansYearly[1].stripePlanId);
    } else if (checkCode === 30) {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setStripeCouponPlanId(CouponPlansYearly[2].stripePlanId as string);
      setPaypalCouponPlanId(CouponPlansYearly[2].paypalPlanId as string);
      setCouponPrice(69);
      stripeCouponCode.push(CouponPlansYearly[2].stripePlanId);
    } else if (thisCouponCode === 'extended') {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setStripeCouponPlanId(CouponPlansYearly[1].stripePlanId as string);
      setPaypalCouponPlanId(CouponPlansYearly[1].paypalPlanId as string);
      setCouponPrice(79);
      stripeCouponCode.push(CouponPlansYearly[1].stripePlanId);
    } else if (thisCouponCode === 'T50TJ') {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setStripeCouponPlanId(CouponPlansYearly[4].stripePlanId as string);
      setPaypalCouponPlanId(CouponPlansYearly[4].paypalPlanId as string);
      setCouponPrice(49);
      stripeCouponCode.push(CouponPlansYearly[4].stripePlanId);
    } else if (thisCouponCode === 'hazel') {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setStripeCouponPlanId(CouponPlansYearly[4].stripePlanId as string);
      setPaypalCouponPlanId(CouponPlansYearly[4].paypalPlanId as string);
      setCouponPrice(49);
      stripeCouponCode.push(CouponPlansYearly[4].stripePlanId);
    } else if (thisCouponCode === 'TT30J') {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setStripeCouponPlanId(CouponPlansYearly[2].stripePlanId as string);
      setPaypalCouponPlanId(CouponPlansYearly[2].paypalPlanId as string);
      setCouponPrice(69);
      stripeCouponCode.push(CouponPlansYearly[2].stripePlanId);
    } else if (thisCouponCode === '40TTJ') {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setStripeCouponPlanId(CouponPlansYearly[3].stripePlanId as string);
      setPaypalCouponPlanId(CouponPlansYearly[3].paypalPlanId as string);
      setCouponPrice(59);
      stripeCouponCode.push(CouponPlansYearly[3].stripePlanId);
    } else if (thisCouponCode === 'TTJ20') {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setStripeCouponPlanId(CouponPlansYearly[1].stripePlanId as string);
      setPaypalCouponPlanId(CouponPlansYearly[1].paypalPlanId as string);
      setCouponPrice(79);
      stripeCouponCode.push(CouponPlansYearly[1].stripePlanId);
    } else {
      setCouponErrorText('block');
    }

    setIsCheckPending(false);
  };

  const activateSepaCC = () => {
    const createPaymentIntent = async () => {
      setIsSepaCCPending(true);
      const thisStripeCouponCode = [] as any;

      await checkCouponCode(urlCouponCode, thisStripeCouponCode);
      const res = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/subscribe`, {
        user: { email: userData?.email ?? '', name: userData?.fullName ?? '' },
        planID: thisStripeCouponCode[0] !== undefined ? thisStripeCouponCode[0] : stripePlanID[type],
      });
      setPaymentIntent(res.data);
      setStripeSubscriptionId(res.data.subscriptionId);
      setStripePaymentIntendId(res.data.clientSecret?.id);
      setIsSepaCC(true);
      setIsSepaCCPending(false);
      await db
        .collection(`tenants/${tenant}/users`)
        .doc(user?.uid)
        .update({
          membership: {
            ...userData?.membership,
            id: res.data.subscriptionId,
          },
        });
    };

    if (isSepaCC === false) {
      createPaymentIntent();
    } else {
      if (paymentIntent !== null) {
        deleteStripeSubscription();
        setPaymentIntent(null);
      }
      setIsSepaCC(false);
    }
  };

  const triggerInAppPurchase = () => {
    setLoadingIAP(true);
    // iapYearly | iapMonthly
    const mobileAbo = type === 'pro' ? 'iapMonthly' : 'iapYearly';
    // @ts-ignore
    window?.ReactNativeWebView?.postMessage(JSON.stringify({ abo: mobileAbo, uid: user?.uid }));
  };

  const handleInAppPurchaseSuccess = () => {
    setLoadingIAP(false);
    toast.success('Deine Zahlung wurde erfolgreich durchgeführt. Vielen Dank!');
    history.push('/');
  };

  const handleMessageFromRN = (message: any) => {
    if (message) {
      try {
        const parsedMessage = JSON.parse(message.data);
        if (parsedMessage) {
          if (parsedMessage?.error) {
            setIAPError(true);
            setIapErrorMessage(parsedMessage?.message);
            setLoadingIAP(false);
          } else if (parsedMessage?.success) {
            handleInAppPurchaseSuccess();
          }
        }
      } catch (error) {
        console.error('Invalid JSON:', error);
      }
    }
  };

  useEffect(() => {
    const checkCouponCodeValid = async () => {
      const thisStripeCouponCode = [] as any;

      await checkCouponCode(urlCouponCode, thisStripeCouponCode);
    };

    if (urlCouponCode === 'extended') {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setStripeCouponPlanId(CouponPlansYearly[1].stripePlanId as string);
      setPaypalCouponPlanId(CouponPlansYearly[1].paypalPlanId as string);
      setCouponPrice(79);
    } else {
      checkCouponCodeValid();
    }

    if (userData?.withCouponCode && window.location.hostname === 'app.tortija.de') {
      ReactPixel.track('AddToCart');
      TiktokPixel.track('AddToCart', {
        content_type: 'product',
        quantity: 1,
        content_name: 'Jahresabo',
        content_id: '1',
        currency: 'EUR',
        value: 49,
      });
    }
  }, []);

  useEffect(() => {
    const messageListener = window.addEventListener('message', handleMessageFromRN, true);
    return messageListener;
  }, []);

  return (
    <Container>
      <div className={styles.wrapper}>
        <div className="pb-10 w-full">
          <div onClick={() => backToPurchase()} onKeyDown={() => backToPurchase()} aria-hidden="true">
            {' '}
            <ButtonBack
              text="Zurück"
              className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
            />
          </div>
        </div>
        <Icon name="logo" width={200} />
        <Headline className="mt-15" level={1}>
          {t(planDetail.name)}
        </Headline>

        {getIsWebView() ? (
          <div className="w-full mt-10">
            <Button
              onClick={() => triggerInAppPurchase()}
              isPending={loadingIAP}
              className="w-full bg-accentColor text-white mt-30"
            >
              Jetzt kaufen
            </Button>
            {loadingIAP && (
              <div className="flex justify-center items-center pt-20">
                Bitte warte einen Moment und schließe die App bitte nicht...
              </div>
            )}
            {iapError && (
              <div className="text-red-500 text-center mt-10">
                <div>Es ist ein Fehler aufgetreten. Bitte versuche es erneut.</div>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="font-extralight">Bitte wähle im unteren Bereich deine gewünschte Zahlungsmethode aus.</div>
            <div className="w-full mt-10">
              <fieldset className="border border-1 text-textColor ">
                <legend className="text-center px-20 uppercase text-20 text-textColor">100% sichere Zahlung</legend>
                <div className="flex gap-10 flex-wrap justify-center py-20 bg-lightGray mx-10 my-10">
                  <div className="my-auto">
                    <Icon name="paypal" className="" width={52} />
                  </div>
                  <div className="my-auto">
                    <Icon name="stripe" width={38} />
                  </div>
                  <div className="my-auto">
                    <Icon name="mastercard" width={38} />
                  </div>
                  <div className="my-auto">
                    <Icon name="visa" width={38} />
                  </div>
                  <div className="my-auto">
                    <Icon name="amex" width={38} />
                  </div>
                  <div className="my-auto">
                    <Icon name="maestro" width={38} />
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="w-full pt-20 text-20">
              <span className="font-bold">Preis:</span>{' '}
              {couponPrice > 0 ? (
                <>
                  <span className={styles.strikethrough}>{t(planDetail.price)}€</span>{' '}
                  <span className="">{couponPrice}€ </span>
                </>
              ) : (
                <span className="">{t(planDetail.price)}€ </span>
              )}
              pro {type === 'pro' ? 'Monat' : 'Jahr'}
            </div>

            <PayPalScriptProvider
              options={{
                'client-id': process.env.REACT_APP_PAYPAL_CLIENTID ?? '',
                components: 'buttons',
                currency: 'EUR',
                intent: 'subscription',
                vault: true,
                'disable-funding': 'card',
              }}
            >
              <ButtonWrapper
                customPlanId={paypalCouponPlanId}
                customCouponCode={couponCode}
                customPrice={
                  couponPrice > 0
                    ? couponPrice
                    : parseFloat(parseFloat(t(planDetail.price).replace(',', '.')).toFixed(2))
                }
                stripeSubscriptionId={stripeSubscriptionId}
                stripePaymentIntendId={stripePaymentIntendId}
              />
            </PayPalScriptProvider>
            <div className="flex w-full mt-20">
              <div className="flex-1 my-auto mx-20">
                <hr className=" text-textColor my-auto" />
              </div>
              <div className="">oder</div>
              <div className="flex-1 my-auto mx-20">
                <hr />
              </div>
            </div>
            <div className="pt-30 pb-20 w-full flex justify-between">
              <div className="my-auto">
                <Checkbox
                  onClick={() => activateSepaCC()}
                  label="Kreditkarte / SEPA Lastschrift?"
                  name="sepaCC"
                  register="t"
                />
              </div>
              <div className="flex">
                <div className="my-auto">
                  <CreditCardIcon width={25} height={25} className="text-textColor" />
                </div>
                <div>
                  <Icon name="sepa" className="" width={40} />
                </div>
              </div>
            </div>
            {isSepaCCPending && (
              <div className="flex justify-center items-center pt-20">
                <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
              </div>
            )}
            {isSepaCC && (
              <Elements
                key={stripeCouponPlanId}
                stripe={getStripe()}
                options={{
                  clientSecret: paymentIntent?.clientSecret.payment_intent?.client_secret,
                  appearance: {
                    theme: 'night',
                    labels: 'floating',
                  },
                  locale: 'de',
                  loader: 'always',
                }}
              >
                <CheckoutForm
                  customPlanId={stripeCouponPlanId}
                  customCouponCode={urlCouponCode}
                  customPrice={
                    couponPrice > 0
                      ? couponPrice
                      : parseFloat(parseFloat(t(planDetail.price).replace(',', '.')).toFixed(2))
                  }
                />
              </Elements>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default PaymentDetails;

import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toast';
import { AuthContext } from 'providers/AuthProvider';
import CustomInput from 'components/Input/custom-input';
import firebase from 'services/firebase';
import Button from 'components/Button';
import axios from 'axios';
import InBodyOverview from './components/InBodyOverview';

type Props = {};

const AnalyzeInBody: React.FC<Props> = () => {
  const { tenant, userData } = useContext(AuthContext);
  const authContext = useContext(AuthContext);
  const [currentMobileNumber, setCurrentMobileNumber] = useState('');
  const [submitPending, setSubmitPending] = useState(false);

  const { handleSubmit } = useForm();

  const [currentDay, setCurrentDay] = useState('');
  const [currentMonth, setCurrentMonth] = useState('');
  const [currentYear, setCurrentYear] = useState('');

  const changeMobileNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentMobileNumber(event.target.value);
  };

  const changeDay = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentDay(event.target.value);
  };

  const changeMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentMonth(event.target.value);
  };

  const changeYear = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentYear(event.target.value);
  };

  const onSubmit = async (data: any) => {
    setSubmitPending(true);
    let isUpdateValid = true;
    let thisDay = currentDay;
    let thisMonth = currentMonth;
    const thisYear = currentYear;

    let isRequestValid = false;
    let inBodyApiKey = '';
    let inBodyAccount = '';
    let inBodyGymId = '';

    if (userData?.tenantLocation !== undefined) {
      const checkApiKeyObject = await firebase
        .firestore()
        .collection('apiKeys')
        .where('locationId', '==', userData?.tenantLocation)
        .get()
        .then((querySnapshot: any) => {
          if (querySnapshot.size > 0) {
            const apiKeyObject = querySnapshot.docs[0].data();
            if (apiKeyObject.company === 'Inbody' && apiKeyObject.tenant === tenant) {
              isRequestValid = true;
              inBodyApiKey = apiKeyObject.inBodyApiKey;
              inBodyAccount = apiKeyObject.inBodyAccount;
              inBodyGymId = apiKeyObject.gymId;
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      const checkApiKeyObject = await firebase
        .firestore()
        .collection('apiKeys')
        .where('tenant', '==', tenant)
        .get()
        .then((querySnapshot: any) => {
          if (querySnapshot.size > 0) {
            const apiKeyObject = querySnapshot.docs[0].data();
            if (apiKeyObject.company === 'Inbody' && apiKeyObject.tenant === tenant) {
              isRequestValid = true;
              inBodyApiKey = apiKeyObject.inBodyApiKey;
              inBodyAccount = apiKeyObject.inBodyAccount;
              inBodyGymId = apiKeyObject.gymId;
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    }

    // Check birthday values
    if (currentDay.length > 2 || Number.isNaN(currentDay) || parseFloat(currentDay) > 31) {
      isUpdateValid = false;
    }

    if (parseFloat(currentDay) < 10 && currentDay.length === 1) {
      thisDay = `0${thisDay}`;
    }

    if (currentMonth.length > 2 || Number.isNaN(currentMonth) || parseFloat(currentMonth) > 12) {
      isUpdateValid = false;
    }

    if (parseFloat(currentMonth) < 10 && currentMonth.length === 1) {
      thisMonth = `0${thisMonth}`;
    }

    if (currentYear.length > 4 || Number.isNaN(currentYear) || currentYear.length < 4) {
      isUpdateValid = false;
    }

    const setBirthDay = `${thisYear}-${thisMonth}-${thisDay}`;

    // Check mobile number
    const regExp = new RegExp('^0[0-9]*$');
    const isValid = regExp.test(currentMobileNumber);

    if (isValid === false) {
      isUpdateValid = false;
    }

    if (userData && isUpdateValid === true) {
      const newObject = {
        ...userData,
        inBody: {
          id: Math.random(),
          birthDay: setBirthDay,
          mobileNumber: currentMobileNumber,
        },
      };

      try {
        if (isRequestValid && inBodyAccount.length > 0 && inBodyApiKey.length > 0) {
          await firebase.firestore().collection(`tenants/${tenant}/users`).doc(authContext.user?.uid).update(newObject);

          const options = {
            method: 'POST',
            url: 'https://api.tortija.de/api/inBodyWebhook',
            headers: { 'content-type': 'application/json' },
            data: {
              action: 'insert',
              inBodyApiKey,
              inBodyAccount,
              gymId: inBodyGymId,
              mobileNumber: currentMobileNumber,
            },
            json: true,
            withCredentials: false,
          } as any;

          await axios
            .request(options)
            .then(async response => {
              if (
                response.data.responseData !== undefined &&
                (response.data.responseData === 'success' || response.data.responseData === 'fail')
              ) {
                authContext.setUserData({
                  ...userData,
                  inBody: {
                    id: Math.random().toString(),
                    birthDay: setBirthDay,
                    mobileNumber: currentMobileNumber,
                  },
                });

                if (response.data.responseData === 'fail') {
                  const optionsUpdate = {
                    method: 'POST',
                    url: 'https://api.tortija.de/api/inBodyWebhook',
                    headers: { 'content-type': 'application/json' },
                    data: {
                      action: 'update',
                      inBodyApiKey,
                      inBodyAccount,
                      gymId: inBodyGymId,
                      mobileNumber: currentMobileNumber,
                    },
                    json: true,
                    withCredentials: false,
                  } as any;

                  await axios.request(optionsUpdate).then(responseUpdate => {
                    const test = responseUpdate.data.resonseData;
                  });
                }

                const newSuccessObject = {
                  ...userData,
                  inBody: {
                    id: Math.random(),
                    birthDay: setBirthDay,
                    mobileNumber: currentMobileNumber,
                    registerComplete: true,
                  },
                };

                await firebase
                  .firestore()
                  .collection(`tenants/${tenant}/users`)
                  .doc(authContext.user?.uid)
                  .update(newSuccessObject);

                toast.success('Die Registrierung war erfolgreich!');
              } else {
                const newErrorObject = {
                  ...userData,
                  inBody: {},
                };

                await firebase
                  .firestore()
                  .collection(`tenants/${tenant}/users`)
                  .doc(authContext.user?.uid)
                  .update(newErrorObject);

                toast.error('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut!');
              }
            })
            .catch(async err => {
              console.log(err);
              const newErrorObject = {
                ...userData,
                inBody: {},
              };

              await firebase
                .firestore()
                .collection(`tenants/${tenant}/users`)
                .doc(authContext.user?.uid)
                .update(newErrorObject);

              toast.error('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut!');
            });
        } else {
          toast.error('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut!');
        }
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut!');
      }
    } else {
      toast.error('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut!');
    }

    setSubmitPending(false);
  };

  return (
    <>
      {userData?.inBody?.registerComplete === undefined ||
      userData?.inBody?.registerComplete === false ||
      submitPending ? (
        <div>
          <div className="pt-30">
            <div className="font-bold text-20">Willkommen beim InBody Körperanalyse-Feature!</div>
            <div className="font-extralight pt-20 w-full desktop:w-3/4">
              <p className="pb-15">
                Wir freuen uns, Ihnen mitteilen zu können, dass wir Ihnen die Möglichkeit bieten, das kostenlose InBody
                Körperanalyse-Feature zu nutzen. Mit dieser Funktion können Sie detaillierte Messungen von Ihrem Körper
                durchführen, um Ihre Fortschritte auf dem Weg zu Ihren Gesundheits- und Fitnesszielen zu verfolgen.
              </p>
              <p className="pb-15">
                Um das kostenlose InBody Körperanalyse-Feature zu aktivieren, klicken Sie bitte auf den unteren Button
                um den Registrierungsprozess zu starten. Wir benötigen lediglich Ihre Mobile Telefonnummer und Ihr
                Geburtsdatum damit wir die Messungen zuordnen können.
              </p>
              <p>
                Sobald Sie Ihre Daten eingegeben haben, werden die Testergebnisse von Ihren Messungen automatisch an
                dieser Stelle angezeigt.
              </p>
            </div>
            <form className="w-full large:w-1/2 pt-30" onSubmit={handleSubmit(onSubmit)}>
              <CustomInput
                name="mobileNumber"
                type="number"
                label="Mobilnummer (Format: 0172000000)"
                placeholder="Mobilnummer"
                className="pb-20"
                onChange={changeMobileNumber}
              />
              <div className="pb-10 font-bold">Geburtsdatum</div>
              <div className="flex space-x-20">
                <CustomInput
                  onChange={changeDay}
                  name="mobileNumber"
                  type="number"
                  label="Tag"
                  placeholder="DD"
                  className="pb-20"
                />
                <CustomInput
                  onChange={changeMonth}
                  name="mobileNumber"
                  type="number"
                  label="Monat"
                  placeholder="MM"
                  className="pb-20"
                />
                <CustomInput
                  onChange={changeYear}
                  name="mobileNumber"
                  type="number"
                  label="Jahr"
                  placeholder="YYYY"
                  className="pb-20"
                />
              </div>

              <div className="pt-20 pb-40">
                {submitPending && (
                  <div className="font-bold pb-10">
                    Die Einrichtung wird abgeschlossen. Bitte schließe dieses Fenster nicht!
                  </div>
                )}
                <Button className="w-full desktop:w-auto" isPending={submitPending} disabled={submitPending}>
                  InBody Körperanalyse-Feature aktivieren
                </Button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <InBodyOverview />
      )}
    </>
  );
};

export default AnalyzeInBody;

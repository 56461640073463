import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';

import Button from 'components/Button';
import styles from '../payment/styles.module.scss';

type ParamType = {
  type: MembershipType;
};

const CheckoutForm = (customPlanId: any) => {
  const elements = useElements();
  const stripe = useStripe();
  const { t } = useTranslation();
  const { type } = useParams<ParamType>();
  const [isPending, setIsPending] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const thisCustomPlanId = customPlanId.customPlanId;
    const thisCustomCouponCode = customPlanId.customCouponCode;
    const thisCustomPrice = customPlanId.customPrice;

    if (thisCustomPlanId === undefined || thisCustomCouponCode === undefined || thisCustomPrice === undefined) {
      toast.error('Es ist leider etwas schief gelaufen. Melde dich bitte bei uns!');
      return;
    }

    setIsPending(true);

    try {
      const confirmPaymentRes = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${process.env.REACT_APP_BASE_URL}/purchase/success?type=${type}&customCoupon=${thisCustomCouponCode}&customPrice=${thisCustomPrice}&customPlanId=${thisCustomPlanId}`,
        },
      });

      console.log(confirmPaymentRes);

      if (confirmPaymentRes.error) {
        toast.error('Es ist leider etwas schief gelaufen. Melde dich bitte bei uns!');
        setIsPending(false);
        return;
      }
    } catch (error) {
      console.log('error: ', error);
    }

    setIsPending(false);
  };

  return (
    <form className={styles.stripeWrapper} onSubmit={handleSubmit}>
      <PaymentElement />
      <Button type="submit" className="w-full rounded-full py-15" isPending={isPending} disabled={isPending}>
        {t('Buy')}
      </Button>
    </form>
  );
};

export default CheckoutForm;

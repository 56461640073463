import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import PurchaseConnectStatus from './components/status';

const PurchaseSuccessConnectWrapper = () => {
  let stripePromise: any;
  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLICKEY ?? '',
        { stripeAccount: localStorage.getItem('accountId')! } ?? ''
      );
    }
    return stripePromise;
  };

  const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
  const setupClientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret');

  return (
    <Elements
      stripe={getStripe()}
      options={{
        clientSecret: setupClientSecret !== null && setupClientSecret.length > 0 ? setupClientSecret : clientSecret!,
        appearance: {
          theme: 'night',
          labels: 'floating',
        },
        locale: 'de',
        loader: 'always',
      }}
    >
      <PurchaseConnectStatus
        clientSecret={setupClientSecret !== null && setupClientSecret.length > 0 ? setupClientSecret : clientSecret}
      />
    </Elements>
  );
};

export default PurchaseSuccessConnectWrapper;

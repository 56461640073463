import React, { ReactNode } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { handleOpenClosePopups } from 'shared/functions/global';
import TransitionContainer from 'components/TransitionContainer';
import styles from './style.module.scss';

type Props = {
  overlayClass?: string;
  setOverlayClass?: Function;
  icon: any;
  headline: string;
  children: ReactNode;
  topPadding?: boolean;
  fullSize?: boolean;
  withoutCloseButton?: boolean;
};

const Overlay: React.FC<Props> = ({
  overlayClass,
  setOverlayClass,
  children,
  icon,
  headline,
  topPadding,
  fullSize,
  withoutCloseButton = false,
}) => {
  return (
    <div className={overlayClass}>
      <div
        className={
          fullSize !== undefined
            ? styles.backgroundPopupLayerFullSize
            : topPadding !== undefined
            ? styles.backgroundPopupLayerTopPadding
            : styles.backgroundPopupLayer
        }
      >
        <TransitionContainer isShown={overlayClass === 'block'} isOverlay>
          <div className={fullSize !== undefined ? styles.overlayPopupFullSize : styles.overlayPopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">{icon}</div>
                <div className="text-xl my-auto font-light">{headline}</div>
              </div>
              {withoutCloseButton === undefined ||
                (withoutCloseButton === false && (
                  <div className="my-auto pr-20">
                    <XIcon
                      width={25}
                      height={25}
                      className="text-accentColor mx-auto cursor-pointer"
                      onClick={() => handleOpenClosePopups(setOverlayClass, '', 'close')}
                    />
                  </div>
                ))}
            </div>
            <div className={styles.overlayContent}>
              <div className="px-20">{children}</div>
            </div>
          </div>
        </TransitionContainer>
      </div>
    </div>
  );
};

export default Overlay;

import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  lowerLimit: string;
  upperLimit: string;
  value?: string;
  bodyStrength?: {
    left: number;
    right: number;
  };
  isBalance?: boolean;
};

const CheckDiagram: React.FC<Props> = ({ lowerLimit, upperLimit, value = '', bodyStrength, isBalance }) => {
  const getStatus = (lowLim: number, upLim: number, val: number) => {
    if (bodyStrength) {
      if (bodyStrength.left < lowLim && bodyStrength.right < lowLim) {
        return 'under';
      }
      if (bodyStrength.left > upLim && bodyStrength.right > upLim) {
        return 'over';
      }
    }
    if (val < lowLim) {
      return 'under';
    }
    if (val > upLim) {
      return 'over';
    }
    return 'normal';
  };

  const status = getStatus(parseFloat(lowerLimit), parseFloat(upperLimit), parseFloat(value));

  if (isBalance) {
    return (
      <div className={styles.checkDiagram}>
        <div className={styles.check}>
          <div
            className={classNames(styles.circle, { [styles.active]: status === 'under' })}
            data-pdf-circle
            data-pdf-active={status === 'under'}
          />
          <p className={styles.name}>Balanciert</p>
        </div>

        <div className={styles.check}>
          <div
            className={classNames(styles.circle, { [styles.active]: status === 'over' })}
            data-pdf-circle
            data-pdf-active={status === 'over'}
          />
          <p className={styles.name}>Unbalanciert</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.checkDiagram}>
      <div className={styles.check}>
        <div
          className={classNames(styles.circle, { [styles.active]: status === 'under' })}
          data-pdf-circle
          data-pdf-active={status === 'under'}
        />
        <p className={styles.name}>Unter</p>
      </div>

      <div className={styles.check}>
        <div
          className={classNames(styles.circle, { [styles.active]: status === 'normal' })}
          data-pdf-circle
          data-pdf-active={status === 'normal'}
        />
        <p className={styles.name}>Normal</p>
      </div>

      <div className={styles.check}>
        <div
          className={classNames(styles.circle, { [styles.active]: status === 'over' })}
          data-pdf-circle
          data-pdf-active={status === 'over'}
        />
        <p className={styles.name}>Über</p>
      </div>
    </div>
  );
};

export default CheckDiagram;

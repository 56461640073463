import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  naming?: boolean;
  scale?: number[];
  startValue?: number;
  endValue?: number;
  summandValue?: number;
  lowerLimit: number;
  upperLimit: number;
  percentage: number;
};

const Diagram: React.FC<Props> = ({
  naming = false,
  scale = [],
  startValue = 0,
  endValue = 100,
  summandValue = 10,
  lowerLimit,
  upperLimit,
  percentage,
}) => {
  const generateScale = () => {
    if (scale && scale.length > 0) return scale;
    const generatedScale = [];
    for (let value = startValue; value <= endValue; value += summandValue) {
      generatedScale.push(value);
    }
    return generatedScale;
  };

  const finalScale = generateScale();

  const calculateBarWidth = () => {
    let widthPercentage = 0;

    if (percentage <= finalScale[0]) {
      return 0;
    }
    if (percentage >= finalScale[finalScale.length - 1]) {
      return 100;
    }

    let found = false;

    finalScale.forEach((currentValue, index) => {
      if (!found && index < finalScale.length - 1) {
        const nextValue = finalScale[index + 1];

        if (percentage >= currentValue && percentage < nextValue) {
          const segmentRelativePosition = (percentage - currentValue) / (nextValue - currentValue);
          const segmentWidth = 100 / (finalScale.length - 1);
          widthPercentage = (index + segmentRelativePosition) * segmentWidth;
          found = true;
        }
      }
    });

    return widthPercentage;
  };

  return (
    <div>
      {naming && (
        <div className={styles.naming}>
          <p className={styles.small}>Unter</p>
          <p className={styles.small}>Normal</p>
          <p className={styles.big}>Über</p>
        </div>
      )}
      <div className={styles.scale}>
        {finalScale.map((item, index) => (
          <div
            className={classNames(styles.marker, { [styles.orange]: item === lowerLimit || item === upperLimit })}
            key={index}
          />
        ))}
      </div>

      <div className={styles.barWrapper}>
        <div
          className={classNames(styles.bar, { [styles.orange]: lowerLimit === upperLimit })}
          style={{ width: `${calculateBarWidth()}%` }}
        />
        {lowerLimit !== upperLimit && (
          <div className={styles.barHighlight}>
            <div style={{ width: `${calculateBarWidth()}%` }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Diagram;

/* eslint-disable react/no-danger */
import React, { useContext, useEffect } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import AppleLogo from 'assets/img/apple_logo_small.png';
import Button from 'components/Button';
import firebase from 'services/firebase';
import { Link } from 'react-router-dom';
import GoogleLogo from 'assets/img/google_logo_small.png';
import LogoImg from 'assets/img/svg/logo.svg';

const StoreLandingPage: React.FC = () => {
  const userDomain = window.location.hostname;
  const { tenantData, theme, setTheme } = useContext(AuthContext);
  const logo = theme?.logo ?? LogoImg;

  useEffect(() => {
    // search for customDomain inside tenants which is equal to userDomain
    firebase
      .firestore()
      .collection('customDomain-infos')
      .where('customDomain', '==', userDomain)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          const tenantInfo = querySnapshot.docs[0].data();
          setTheme(tenantInfo?.theme as TenantTheme);
        }
      });
  }, []);

  return (
    <div className="bg-bgColor text-textColor p-40">
      <img className="w-220 mx-auto" width={160} height={140} src={logo} alt="Logo" />
      <div className="hidden md:block">
        <div className="mt-50 text-center font-extralight">Bitte klicke auf den Button um zu starten!</div>
        <div className="mt-20">
          <Link
            to={{
              pathname: '/',
            }}
          >
            <Button className="mx-auto">Jetzt loslegen</Button>
          </Link>
        </div>
      </div>
      <div className="block md:hidden text-center">
        <div className="font-bold text-30 pt-30">Wähle deine Plattform und lade die App herunter!</div>
        <div className="font-extralight pt-20">
          Bitte klicke das Icon von der passenden Plattform die zu deinem Smartphone passt und lade dir die App
          herunter!
        </div>
        <div className="w-full pt-30">
          <div className="bg-white rounded-3xl py-15">
            <Link
              to={{
                pathname: 'https://apps.apple.com/de/app/tortija-ern%C3%A4hrungspl%C3%A4ne/id6444732767',
              }}
              target="_blank"
            >
              <img className="w-180 mx-auto" width={160} height={140} src={AppleLogo} alt="Logo" />
            </Link>
          </div>
          <div className="mt-20 bg-white rounded-3xl py-15">
            <Link
              to={{
                pathname: 'https://play.google.com/store/apps/details?id=de.tortija.app.twa',
              }}
              target="_blank"
            >
              <img className="w-180 mx-auto" width={160} height={140} src={GoogleLogo} alt="Logo" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreLandingPage;

import React, { useEffect, useState } from 'react';
import Table from 'components/Table';
import Select from 'react-select';
import firebase from 'services/firebase';
import { OptionType } from 'react-switch-selector/dist/SwitchSelector';
import moment from 'moment';
import Headline from 'components/Headline';
import styles from './styles.module.scss';

const skeletonTable: TableDataType[][] = new Array(3).fill([
  { type: 'text', value: '' },
  { type: 'text', value: '' },
  { type: 'image', value: '' },
  { type: 'qr', value: '' },
]);

export default function B2BOverview() {
  const header = ['B2B Kunde', 'Aktive User', 'Letzter Login Admin', 'Abrechnungstag', 'Monatliche Kosten'];

  const [couponData, setCouponData] = useState<TableDataType[][]>([]);
  const [isPending, setIsPending] = useState(false);
  const [sortParameter, setSortParameter] = useState('loginDate');
  const [sortDirection, setSortDirection] = useState<any>('desc');
  const [currentTenantsTotal, setCurrentTenantsTotal] = useState(0);
  const [currentMonthlyCostsTotal, setCurrentMonthlyCostsTotal] = useState(0);

  const sortTypes: OptionType[] = [
    { value: 'loginDate', label: 'Login Datum' },
    { value: 'name', label: 'B2B Name' },
    { value: 'user', label: 'Aktive User' },
    { value: 'costs', label: 'Kosten' },
  ];

  const sortDirectionTypes: OptionType[] = [
    { value: 'desc', label: 'Absteigend' },
    { value: 'asc', label: 'Aufsteigend' },
  ];

  const getCouponData = async () => {
    setIsPending(true);

    let tenantCollection = null;

    if (sortParameter === 'loginDate') {
      tenantCollection = firebase.firestore().collection('tenants').orderBy('createdAt', sortDirection);
    } else if (sortParameter === 'name') {
      tenantCollection = firebase.firestore().collection('tenants').orderBy('company.companyName', sortDirection);
    } else if (sortParameter === 'user') {
      tenantCollection = firebase.firestore().collection('tenants').orderBy('billing.userCount', sortDirection);
    } else if (sortParameter === 'costs') {
      tenantCollection = firebase.firestore().collection('tenants').orderBy('billing.monthlyCosts', sortDirection);
    } else {
      tenantCollection = firebase.firestore().collection('tenants').orderBy('createdAt', sortDirection);
    }

    const tenantDocs = await tenantCollection.get();

    let thisMonthlyCostsTotal = 0;
    let thisTotalTenants = 0;

    const dataPromises: Promise<TableDataType[]>[] = tenantDocs.docs.map(async tenantDoc => {
      const tenantData = tenantDoc.data();
      const usersCollection = tenantDoc.ref.collection('users');
      const usersQuerySnapshot = await usersCollection.where('role', '==', 3).get();
      const userDoc = usersQuerySnapshot.docs[0]; // Assuming there's only one user with role = 3, adjust as needed
      thisMonthlyCostsTotal += tenantData.billing?.monthlyCosts ?? 0;
      thisTotalTenants += 1;

      return [
        { type: 'text', value: tenantData.company?.companyName ?? '' },
        { type: 'text', value: tenantData.billing?.userCount ?? '' },
        {
          type: 'text',
          value:
            userDoc && userDoc.data().lastLoginDate
              ? moment(userDoc.data().lastLoginDate?.toDate()).format('DD[.]MM[.]YY') ?? ''
              : '',
        },
        {
          type: 'text',
          value: tenantData.createdAt ? moment(tenantData.createdAt?.toDate()).format('DD') ?? '' : '',
        },
        { type: 'text', value: `${(tenantData?.billing?.monthlyCosts / 100).toString().replace('.', ',')} €` },
      ];
    });

    const data = await Promise.all(dataPromises);
    setCurrentMonthlyCostsTotal(thisMonthlyCostsTotal);
    setCurrentTenantsTotal(thisTotalTenants);
    setCouponData(data);
    setIsPending(false);
  };

  const updateSortParameter = (value: any) => {
    setSortParameter(value.value as string);
  };

  const updateSortDirection = (value: any) => {
    setSortDirection(value.value as string);
  };

  useEffect(() => {
    getCouponData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCouponData();
    // eslint-disable-next-line
  }, [sortParameter, sortDirection]);

  return (
    <div className={styles.wrapper}>
      <Headline level={1} classLevel={3} className={styles.header}>
        B2B Übersicht
      </Headline>
      <div className="flex gap-20 flex-wrap">
        <div>
          <div className="pt-20 pb-10 flex gap-10">
            <div className="my-auto desktop:w-150">Sortieren nach:</div>
            <div className="w-250">
              <Select
                name="sort"
                className="react-select-container-full"
                classNamePrefix="react-select"
                unstyled
                options={sortTypes}
                value={sortParameter !== '' ? sortTypes.filter(st => st.value === sortParameter) : sortTypes[0]}
                onChange={updateSortParameter}
              />
            </div>
          </div>
          <div className="pt-20 pb-30 flex gap-10">
            <div className="my-auto desktop:w-150">Reihenfolge:</div>
            <div className="w-250">
              <Select
                name="sort"
                className="react-select-container-full"
                classNamePrefix="react-select"
                unstyled
                options={sortDirectionTypes}
                value={
                  sortDirection !== ''
                    ? sortDirectionTypes.filter(st => st.value === sortDirection)
                    : sortDirectionTypes[0]
                }
                onChange={updateSortDirection}
              />
            </div>
          </div>
        </div>
        <div className="bg-lightGray p-15 rounded-20 mb-20">
          <div>
            <div className="flex gap-10 px-20">
              <div className="my-auto">Monatliche Einnahmen:</div>
              <div className="font-bold text-20">{(currentMonthlyCostsTotal / 100).toString().replace('.', ',')} €</div>
            </div>
            <div className="pt-10 flex gap-10 px-20">
              <div className="my-auto">Anzahl B2B Kunden:</div>
              <div className="font-bold text-20">{currentTenantsTotal}</div>
            </div>
          </div>
        </div>
      </div>
      {isPending || !couponData ? (
        <Table header={Array.from(new Array(4))} body={skeletonTable} className="w-full" isSkeleton />
      ) : (
        <Table header={header} body={couponData} className="w-full" />
      )}
    </div>
  );
}

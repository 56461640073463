import React, { useState, useContext } from 'react';
import firebase from 'firebase';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toast';
import { useTranslation } from 'react-i18next';
import CustomInput from 'components/Input/custom-input';
import TransitionContainer from 'components/TransitionContainer';
import Headline from 'components/Headline';
import { AuthContext } from 'providers/AuthProvider';
import Button from 'components/Button';

import styles from './styles.module.scss';

type Props = {
  isShown: boolean;
  title: string;
  goBack?: React.MouseEventHandler<HTMLButtonElement>;
};

const ChangePassword: React.FC<Props> = ({ isShown, title, goBack = () => {} }) => {
  const { t } = useTranslation();
  const { handleSubmit } = useForm();

  const { userData } = useContext(AuthContext);

  const [isUpdating, setIsUpdating] = useState(false);

  const [passwordChange, setPasswordChange] = useState('');
  const [passwordRepeatChange, setPasswordRepeatChange] = useState('');
  const [passwordCurrentChange, setPasswordCurrentChange] = useState('');

  const passwordChangeFunction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordChange(event.target.value);
  };

  const passwordCurrentChangeFunction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordCurrentChange(event.target.value);
  };

  const passwordRepeatChangeFunction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordRepeatChange(event.target.value);
  };

  const onSubmit = async (data: any) => {
    setIsUpdating(true);
    try {
      if (passwordChange === passwordRepeatChange && userData !== undefined) {
        const user = firebase.auth().currentUser;

        const credential = firebase.auth.EmailAuthProvider.credential(userData.email, passwordCurrentChange);

        await user?.reauthenticateWithCredential(credential).then(() => {
          user
            ?.updatePassword(passwordChange)
            .then(() => {
              toast.success('Dein Passwort wurde erfolgreich geändert!');
              setIsUpdating(false);
            })
            .catch(error => {
              toast.success('Das hat leider nicht geklappt!');
              setIsUpdating(false);
            });
        });
      } else {
        setIsUpdating(false);
        toast.error('Bitte gebe in beiden Feldern das selbe Passwort ein!');
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.warn(error.message);
      setIsUpdating(false);
    }
  };

  return (
    <TransitionContainer isShown={isShown}>
      <div className={styles.header}>
        <Headline level={1} className="mb-20">
          {t(title)}
        </Headline>
        <div className={styles.buttons}>
          <Headline level={4} displayBackBtn goBack={goBack}>
            {t('Return')}
          </Headline>
          <Button
            isPending={isUpdating}
            disabled={isUpdating}
            buttonStyle={isUpdating ? 'dark' : 'primary'}
            onClick={onSubmit}
            className="w-200"
          >
            {t('Change')}
          </Button>
        </div>
      </div>
      <div className="font-extralight pb-20">
        Wenn Sie das aktuelle Passwort ändern möchten, geben Sie bitte in den unteren Feldern ein neues Passwort ein!
      </div>
      <div className={styles.content}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <CustomInput
            name="currentPassword"
            type="password"
            label="Aktuelles Passwort"
            placeholder="Aktuelles Passwort"
            className="pb-20"
            onChange={passwordCurrentChangeFunction}
          />
          <CustomInput
            name="newPassword"
            type="password"
            placeholder="Neues Passwort"
            label="Neues Passwort"
            className="pb-20"
            onChange={passwordChangeFunction}
          />
          <CustomInput
            name="repeatPassword"
            type="password"
            placeholder="Neues Passwort wiederholen"
            label="Neues Passwort wiederholen"
            className="pb-20"
            onChange={passwordRepeatChangeFunction}
          />
        </form>
      </div>
    </TransitionContainer>
  );
};

export default ChangePassword;

import React, { useContext, useState } from 'react';
import { SketchPicker } from 'react-color';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import firebase from 'services/firebase';
import { InformationCircleIcon, XIcon } from '@heroicons/react/outline';
import { AuthContext } from 'providers/AuthProvider';
import Button from 'components/Button';
import LogoImg from 'assets/img/svg/logo.svg';
import PhoneImg from 'assets/img/phone.png';
import BGDesktopImg from 'assets/img/desktop/index-bg.png';

import {
  defaultAccentColor,
  defaultBgColor,
  defaultSecondaryBgColor,
  defaultLightGray,
  defaultButtonTextColor,
  defaultNotActiveButtonTextColor,
  defaultTextColor,
} from 'layouts/Theme';

export default function EditCompanyThemeForm({
  onSubmit,
  onReset,
}: {
  onSubmit: (updatedTheme?: TenantTheme | undefined) => void;
  onReset: () => void;
}) {
  const { theme, setTheme, tenant, tenantData } = useContext(AuthContext);
  const [isBgColorPickerOpen, setIsBgColorPickerOpen] = useState(false);
  const [isAccentColorPickerOpen, setIsAccentColorPickerOpen] = useState(false);
  const [isTextColorPickerOpen, setIsTextColorPickerOpen] = useState(false);
  const [isButtonTextColorPickerOpen, setIsButtonTextColorPickerOpen] = useState(false);
  const [isNotActiveButtonTextColorPickerOpen, setIsNotActiveButtonTextColorPickerOpen] = useState(false);
  const [isSecondaryBgColorPickerOpen, setSecondaryBgColorPickerOpen] = useState(false);
  const [randomNumber, setRandomNumber] = useState(Math.random());
  const [logoImages, setLogoImages] = useState([]);

  const [initialBgColor, setInitialBgColor] = useState(theme?.bgColor ?? defaultBgColor);
  const [initialAccentColor, setInitialAccentColor] = useState(theme?.accentColor ?? defaultAccentColor);
  const [initialTextColor, setInitialTextColor] = useState(theme?.textColor ?? defaultTextColor);
  const [initialButtonTextColor, setInitialButtonTextColor] = useState(
    theme?.buttonTextColor ?? defaultButtonTextColor
  );
  const [initialSecondaryBgColor, setInitialSecondaryBgColor] = useState(
    theme?.secondaryBgColor ?? defaultSecondaryBgColor
  );
  const [initialNotActiveButtonTextColor, setInitialNotActiveButtonTextColor] = useState(
    theme?.notActiveButtonTextColor ?? defaultNotActiveButtonTextColor
  );

  const [currentBgColor, setCurrentBgColor] = useState(theme?.bgColor ?? defaultBgColor);
  const [currentAccentColor, setCurrentAccentColor] = useState(theme?.accentColor ?? defaultAccentColor);
  const [currentTextColor, setCurrentTextColor] = useState(theme?.textColor ?? defaultTextColor);
  const [currentButtonTextColor, setCurrentButtonTextColor] = useState(
    theme?.buttonTextColor ?? defaultButtonTextColor
  );
  const [currentSecondaryBgColor, setCurrentSecondaryBgColor] = useState(
    theme?.secondaryBgColor ?? defaultSecondaryBgColor
  );
  const [currentNotActiveButtonTextColor, setCurrentNotActiveButtonTextColor] = useState(
    theme?.notActiveButtonTextColor ?? defaultNotActiveButtonTextColor
  );

  const [bgColorInfoClass, setBgColorInfoClass] = useState('hidden');
  const [textColorInfoClass, setTextColorInfoClass] = useState('hidden');
  const [accentColorInfoClass, setAccentColorInfoClass] = useState('hidden');
  const [buttonTextColorInfoClass, setButtonTextColorInfoClass] = useState('hidden');
  const [notActiveButtonTextColorInfoClass, setNotActiveButtonTextColorInfoClass] = useState('hidden');
  const [secondaryBgColorInfoClass, setSecondaryBgColorInfoClass] = useState('hidden');
  const [bgImageInfoClass, setBgImageInfoClass] = useState('hidden');

  const bgColorChange = ({ hex }: { hex: string }) => {
    setCurrentBgColor(hex);
    setTheme((prev: TenantTheme) => ({ ...prev, bgColor: hex }));
  };

  const accentColorChange = ({ hex }: { hex: string }) => {
    setCurrentAccentColor(hex);
    setTheme((prev: TenantTheme) => ({ ...prev, accentColor: hex }));
  };

  const textColorChange = ({ hex }: { hex: string }) => {
    setCurrentTextColor(hex);
    setTheme((prev: TenantTheme) => ({ ...prev, textColor: hex }));
  };

  const buttonTextColorChange = ({ hex }: { hex: string }) => {
    setCurrentButtonTextColor(hex);
    setTheme((prev: TenantTheme) => ({ ...prev, buttonTextColor: hex }));
  };

  const notActiveButtonTextColorChange = ({ hex }: { hex: string }) => {
    setCurrentNotActiveButtonTextColor(hex);
    setTheme((prev: TenantTheme) => ({ ...prev, notActiveButtonTextColor: hex }));
  };

  const secondaryBgColorChange = ({ hex }: { hex: string }) => {
    setCurrentSecondaryBgColor(hex);
    setTheme((prev: TenantTheme) => ({ ...prev, secondaryBgColor: hex }));
  };

  const onDropLogo = async (pictureFiles: ImageListType, _addUpdateIndex: number[] | undefined) => {
    if (!pictureFiles.length || !pictureFiles[0].file) {
      return;
    }
    const res = await firebase
      .storage()
      .ref(`tenants/${tenant}/${pictureFiles[0].file.name}`)
      .put(pictureFiles[0].file);

    const imageUrl = await res.ref.getDownloadURL();

    setTheme((prev: TenantTheme) => ({ ...prev, logo: imageUrl }));
    onSubmit({ ...theme, logo: imageUrl });
    setRandomNumber(Math.random());
  };

  const onDropBg = async (pictureFiles: ImageListType, _addUpdateIndex: number[] | undefined) => {
    if (!pictureFiles.length || !pictureFiles[0].file) {
      return;
    }
    const res = await firebase
      .storage()
      .ref(`tenants/${tenant}/${pictureFiles[0].file.name}`)
      .put(pictureFiles[0].file);
    const imageUrl = await res.ref.getDownloadURL();

    setTheme((prev: TenantTheme) => ({ ...prev, bgImage: imageUrl }));
    onSubmit({ ...theme, bgImage: imageUrl });
  };

  const handleResetAll = () => {
    setTheme({});

    setInitialBgColor(defaultBgColor);
    setInitialSecondaryBgColor(defaultSecondaryBgColor);
    setInitialAccentColor(defaultAccentColor);
    setInitialTextColor(defaultTextColor);
    setInitialButtonTextColor(defaultButtonTextColor);
    setInitialNotActiveButtonTextColor(defaultNotActiveButtonTextColor);

    setTimeout(() => {
      onReset();
      setRandomNumber(Math.random());
    }, 500);
  };

  const handleResetBgColor = () => {
    setTheme((prev: TenantTheme) => ({ ...prev, bgColor: defaultBgColor }));
    onSubmit({ ...theme, bgColor: defaultBgColor });
    setInitialBgColor(defaultBgColor);
    setRandomNumber(Math.random());
  };

  const handleResetSecondaryBgColor = () => {
    setTheme((prev: TenantTheme) => ({ ...prev, secondaryBgColor: defaultSecondaryBgColor }));
    onSubmit({ ...theme, secondaryBgColor: defaultSecondaryBgColor });
    setInitialSecondaryBgColor(defaultSecondaryBgColor);
    setRandomNumber(Math.random());
  };

  const handleResetAccentColor = () => {
    setTheme((prev: TenantTheme) => ({ ...prev, accentColor: defaultAccentColor }));
    onSubmit({ ...theme, accentColor: defaultAccentColor });
    setInitialAccentColor(defaultAccentColor);
    setRandomNumber(Math.random());
  };

  const handleResetTextColor = () => {
    setTheme((prev: TenantTheme) => ({ ...prev, textColor: defaultTextColor }));
    onSubmit({ ...theme, textColor: defaultTextColor });
    setInitialTextColor(defaultTextColor);
    setRandomNumber(Math.random());
  };

  const handleResetButtonTextColor = () => {
    setTheme((prev: TenantTheme) => ({ ...prev, buttonTextColor: defaultButtonTextColor }));
    onSubmit({ ...theme, buttonTextColor: defaultButtonTextColor });
    setInitialButtonTextColor(defaultButtonTextColor);
    setRandomNumber(Math.random());
  };

  const handleResetNotActiveButtonTextColor = () => {
    setTheme((prev: TenantTheme) => ({ ...prev, notActiveButtonTextColor: defaultNotActiveButtonTextColor }));
    onSubmit({ ...theme, notActiveButtonTextColor: defaultNotActiveButtonTextColor });
    setInitialNotActiveButtonTextColor(defaultNotActiveButtonTextColor);
    setRandomNumber(Math.random());
  };

  const handleNotActiveButtonTextColorChange = () => {
    if (isNotActiveButtonTextColorPickerOpen) {
      onSubmit();
      setRandomNumber(Math.random());
      setInitialNotActiveButtonTextColor(currentNotActiveButtonTextColor);
    }
    setIsNotActiveButtonTextColorPickerOpen(!isNotActiveButtonTextColorPickerOpen);
  };

  const handleAccentColorChange = () => {
    if (isAccentColorPickerOpen) {
      onSubmit();
      setRandomNumber(Math.random());
      setInitialAccentColor(currentAccentColor);
    }
    setIsAccentColorPickerOpen(!isAccentColorPickerOpen);
  };

  const handleBgColorChange = () => {
    if (isBgColorPickerOpen) {
      onSubmit();
      setRandomNumber(Math.random());
      setInitialBgColor(currentBgColor);
    }
    setIsBgColorPickerOpen(!isBgColorPickerOpen);
  };

  const handleTextColorChange = () => {
    if (isTextColorPickerOpen) {
      onSubmit();
      setRandomNumber(Math.random());
      setInitialTextColor(currentTextColor);
    }
    setIsTextColorPickerOpen(!isTextColorPickerOpen);
  };

  const handleButtonTextChange = () => {
    if (isButtonTextColorPickerOpen) {
      onSubmit();
      setRandomNumber(Math.random());
      setInitialButtonTextColor(currentButtonTextColor);
    }
    setIsButtonTextColorPickerOpen(!isButtonTextColorPickerOpen);
  };

  const handleSecondaryBgColorChange = () => {
    if (isSecondaryBgColorPickerOpen) {
      onSubmit();
      setRandomNumber(Math.random());
      setInitialSecondaryBgColor(currentSecondaryBgColor);
    }
    setSecondaryBgColorPickerOpen(!isSecondaryBgColorPickerOpen);
  };

  const abortBgChange = () => {
    setIsBgColorPickerOpen(false);
    setTheme((prev: TenantTheme) => ({ ...prev, bgColor: initialBgColor }));
  };

  const abortSecondaryBgColor = () => {
    setSecondaryBgColorPickerOpen(false);
    setTheme((prev: TenantTheme) => ({ ...prev, secondaryBgColor: initialSecondaryBgColor }));
  };

  const abortAccentColor = () => {
    setIsAccentColorPickerOpen(false);
    setTheme((prev: TenantTheme) => ({ ...prev, accentColor: initialAccentColor }));
  };

  const abortTextColor = () => {
    setIsTextColorPickerOpen(false);
    setTheme((prev: TenantTheme) => ({ ...prev, textColor: initialTextColor }));
  };

  const abortButtonTextColor = () => {
    setIsButtonTextColorPickerOpen(false);
    setTheme((prev: TenantTheme) => ({ ...prev, buttonTextColor: initialButtonTextColor }));
  };

  const abortNotActiveButtonTextColor = () => {
    setIsNotActiveButtonTextColorPickerOpen(false);
    setTheme((prev: TenantTheme) => ({ ...prev, notActiveButtonTextColor: initialNotActiveButtonTextColor }));
  };

  const openInfoBox = (infoType: string) => {
    if (infoType === 'bgColor') {
      setBgColorInfoClass('block');
      setTextColorInfoClass('hidden');
      setAccentColorInfoClass('hidden');
      setButtonTextColorInfoClass('hidden');
      setSecondaryBgColorInfoClass('hidden');
      setNotActiveButtonTextColorInfoClass('hidden');
      setBgImageInfoClass('hidden');
    } else if (infoType === 'textColor') {
      setBgColorInfoClass('hidden');
      setTextColorInfoClass('block');
      setAccentColorInfoClass('hidden');
      setButtonTextColorInfoClass('hidden');
      setSecondaryBgColorInfoClass('hidden');
      setNotActiveButtonTextColorInfoClass('hidden');
      setBgImageInfoClass('hidden');
    } else if (infoType === 'accentColor') {
      setBgColorInfoClass('hidden');
      setTextColorInfoClass('hidden');
      setAccentColorInfoClass('block');
      setButtonTextColorInfoClass('hidden');
      setSecondaryBgColorInfoClass('hidden');
      setNotActiveButtonTextColorInfoClass('hidden');
      setBgImageInfoClass('hidden');
    } else if (infoType === 'buttonTextColor') {
      setBgColorInfoClass('hidden');
      setTextColorInfoClass('hidden');
      setAccentColorInfoClass('hidden');
      setButtonTextColorInfoClass('block');
      setSecondaryBgColorInfoClass('hidden');
      setNotActiveButtonTextColorInfoClass('hidden');
      setBgImageInfoClass('hidden');
    } else if (infoType === 'secondaryBgColor') {
      setBgColorInfoClass('hidden');
      setTextColorInfoClass('hidden');
      setAccentColorInfoClass('hidden');
      setButtonTextColorInfoClass('hidden');
      setSecondaryBgColorInfoClass('block');
      setNotActiveButtonTextColorInfoClass('hidden');
      setBgImageInfoClass('hidden');
    } else if (infoType === 'bgImage') {
      setBgColorInfoClass('hidden');
      setTextColorInfoClass('hidden');
      setAccentColorInfoClass('hidden');
      setButtonTextColorInfoClass('hidden');
      setSecondaryBgColorInfoClass('hidden');
      setNotActiveButtonTextColorInfoClass('hidden');
      setBgImageInfoClass('block');
    } else if (infoType === 'notActiveButtonTextColor') {
      setBgColorInfoClass('hidden');
      setTextColorInfoClass('hidden');
      setAccentColorInfoClass('hidden');
      setButtonTextColorInfoClass('hidden');
      setSecondaryBgColorInfoClass('hidden');
      setBgImageInfoClass('hidden');
      setNotActiveButtonTextColorInfoClass('block');
    }
  };

  return (
    <div className="grid tablet:grid-cols-2 gap-30">
      <div>
        <div className="relative my-30 text-white rounded-20 p-20" style={{ backgroundColor: `${defaultLightGray}` }}>
          <label
            className="block font-bold mb-1 cursor-pointer"
            onClick={() => openInfoBox('bgColor')}
            onKeyDown={() => openInfoBox('bgColor')}
            aria-hidden="true"
          >
            Hintergrundfarbe{' '}
            <span className="inline-block my-auto cursor-pointer">
              <InformationCircleIcon width={20} height={15} className="text-white" />
            </span>
          </label>
          <div className={bgColorInfoClass}>
            <div
              className="absolute border border-white z-100 rounded-20 py-20 px-20 font-extralight w-200 md:w-500"
              style={{ backgroundColor: `${defaultLightGray}` }}
            >
              <div className="flex justify-end w-full pb-10">
                <XIcon
                  width={20}
                  height={20}
                  className="text-white cursor-pointer"
                  onClick={() => setBgColorInfoClass('hidden')}
                />
              </div>
              <div className="text-white">Diese Farbanpassung ändert folgende Elemente:</div>
              <ul className="text-white list-disc px-30 pt-10 pb-10">
                <li>Hintergrundfarbe der kompletten Anwendung</li>
                <li>Hintergrundfarbe von Popup-Fenstern</li>
                <li>Hintergrundfarbe der Balken bei Kalorien- und Wasserbedarf (nicht benutzte Balken)</li>
              </ul>
            </div>
          </div>
          <div className="flex items-center pt-10">
            <div
              className="rounded-full w-30 h-30 border border-black"
              style={{ backgroundColor: `${theme?.bgColor ?? defaultBgColor}` }}
            >
              <></>
            </div>
            <div className="mx-20 w-60">
              <span className="border-b border-white">{theme?.bgColor ?? defaultBgColor}</span>
            </div>

            <Button onClick={handleBgColorChange} className="relative py-10">
              anpassen
            </Button>
          </div>
          {isBgColorPickerOpen && (
            <div className="absolute left-0 top-20 z-20 bg-lightGray p-15 rounded-20">
              <SketchPicker color={currentBgColor} onChange={bgColorChange} />
              <div className="flex justify-between mt-15">
                <button type="button" onClick={() => abortBgChange()}>
                  abbrechen
                </button>
                <Button onClick={handleBgColorChange}>speichern</Button>
              </div>
            </div>
          )}
          <Button buttonStyle="text" onClick={handleResetBgColor} className="mt-10 pt-15 text-white">
            Wert zurücksetzen
          </Button>
        </div>

        <div className="relative my-30  text-white rounded-20 p-20" style={{ backgroundColor: `${defaultLightGray}` }}>
          <label
            className="block font-bold cursor-pointer"
            onClick={() => openInfoBox('textColor')}
            onKeyDown={() => openInfoBox('textColor')}
            aria-hidden="true"
          >
            Textfarbe{' '}
            <span className="inline-block my-auto cursor-pointer">
              <InformationCircleIcon width={20} height={15} className="text-white" />
            </span>
          </label>
          <div className={textColorInfoClass}>
            <div
              className="absolute border border-white z-100 rounded-20 py-20 px-20 font-extralight w-200 md:w-500"
              style={{ backgroundColor: `${defaultLightGray}` }}
            >
              <div className="flex justify-end w-full pb-10">
                <XIcon
                  width={20}
                  height={20}
                  className="text-white cursor-pointer"
                  onClick={() => setTextColorInfoClass('hidden')}
                />
              </div>
              <div className="text-white">Diese Farbanpassung ändert folgende Elemente:</div>
              <ul className="text-white list-disc px-30 pt-10 pb-10">
                <li>Farbe für Text-Inhalte in der kompletten Anwendung</li>
              </ul>
            </div>
          </div>
          <div className="flex items-center pt-10">
            <div
              className="rounded-full w-30 h-30 border border-black"
              style={{ backgroundColor: `${theme?.textColor ?? defaultTextColor}` }}
            >
              <></>
            </div>
            <div className="mx-20 w-60">
              <span className="border-b border-white">{theme?.textColor ?? defaultTextColor}</span>
            </div>

            <Button onClick={handleTextColorChange} className="relative py-10">
              anpassen
            </Button>
          </div>
          {isTextColorPickerOpen && (
            <div className="absolute left-0 top-20 z-20 bg-lightGray p-15 rounded-20">
              <SketchPicker color={currentTextColor} onChange={textColorChange} />
              <div className="flex justify-between mt-15">
                <button type="button" onClick={() => abortTextColor()}>
                  abbrechen
                </button>
                <Button onClick={handleTextColorChange}>speichern</Button>
              </div>
            </div>
          )}
          <Button buttonStyle="text" onClick={handleResetTextColor} className="mt-10 pt-15 text-white">
            Wert zurücksetzen
          </Button>
        </div>

        <div className="relative my-30  text-white rounded-20 p-20" style={{ backgroundColor: `${defaultLightGray}` }}>
          <label
            className="block font-bold cursor-pointer"
            onClick={() => openInfoBox('secondaryBgColor')}
            onKeyDown={() => openInfoBox('secondaryBgColor')}
            aria-hidden="true"
          >
            Sekundäre Hintergrundfarbe{' '}
            <span className="inline-block my-auto cursor-pointer">
              <InformationCircleIcon width={20} height={15} className="text-white" />
            </span>
          </label>
          <div className={secondaryBgColorInfoClass}>
            <div
              className="absolute border border-white z-100 rounded-20 py-20 px-20 font-extralight w-200 md:w-500"
              style={{ backgroundColor: `${defaultLightGray}` }}
            >
              <div className="flex justify-end w-full pb-10">
                <XIcon
                  width={20}
                  height={20}
                  className="text-white cursor-pointer"
                  onClick={() => setSecondaryBgColorInfoClass('hidden')}
                />
              </div>
              <div className="text-white">Diese Farbanpassung ändert folgende Elemente:</div>
              <ul className="text-white list-disc px-30 pt-10 pb-10">
                <li>Hintergrundfarbe von den Buttons im Navigations-Menü und im Profil</li>
              </ul>
            </div>
          </div>
          <div className="flex items-center pt-10">
            <div
              className="rounded-full w-30 h-30 border border-black"
              style={{ backgroundColor: `${theme?.secondaryBgColor ?? defaultSecondaryBgColor}` }}
            >
              <></>
            </div>
            <div className="mx-20 w-60">
              <span className="border-b border-white">{theme?.secondaryBgColor ?? defaultSecondaryBgColor}</span>
            </div>

            <Button onClick={handleSecondaryBgColorChange} className="relative py-10">
              anpassen
            </Button>
          </div>
          {isSecondaryBgColorPickerOpen && (
            <div className="absolute left-0 top-20 z-20 bg-lightGray p-15 rounded-20">
              <SketchPicker color={currentSecondaryBgColor} onChange={secondaryBgColorChange} />
              <div className="flex justify-between mt-15">
                <button type="button" onClick={() => abortSecondaryBgColor()}>
                  abbrechen
                </button>
                <Button onClick={handleSecondaryBgColorChange}>speichern</Button>
              </div>
            </div>
          )}
          <Button buttonStyle="text" onClick={handleResetSecondaryBgColor} className="mt-10 pt-15 text-white">
            Wert zurücksetzen
          </Button>
        </div>

        <div className="relative my-30 text-white rounded-20 p-20" style={{ backgroundColor: `${defaultLightGray}` }}>
          <label
            className="block font-bold cursor-pointer"
            onClick={() => openInfoBox('accentColor')}
            onKeyDown={() => openInfoBox('accentColor')}
            aria-hidden="true"
          >
            Hervorhebungen & Buttons{' '}
            <span className="inline-block my-auto cursor-pointer">
              <InformationCircleIcon width={20} height={15} className="text-white" />
            </span>
          </label>
          <div className={accentColorInfoClass}>
            <div
              className="absolute border border-white z-100 rounded-20 py-20 px-20 font-extralight w-200 md:w-500"
              style={{ backgroundColor: `${defaultLightGray}` }}
            >
              <div className="flex justify-end w-full pb-10">
                <XIcon
                  width={20}
                  height={20}
                  className="text-white cursor-pointer"
                  onClick={() => setAccentColorInfoClass('hidden')}
                />
              </div>
              <div className="text-white">
                Die Farbe wird als "Akzent-Farbe" an vielen Stellen der Anwendung benutzt.
              </div>
              <div className="text-white">Diese Farbanpassung ändert folgende Elemente:</div>
              <ul className="text-white list-disc px-30 pt-10 pb-10">
                <li>Hintergrundfarbe bei aktuell angeklickten Buttons im Navigations-Menü und im Profil</li>
                <li>Farbe für Rahmen</li>
                <li>Hintergrundfarbe der Balken bei Kalorien- und Wasserbedarf (benutzte Balken)</li>
                <li>Hintergrundfarbe für normale Buttons</li>
                <li>Hintergrundfarbe für aktive Buttons im Filter</li>
                <li>Farbe für aktive Schalter</li>
              </ul>
            </div>
          </div>
          <div className="flex items-center pt-10">
            <div
              className="rounded-full w-30 h-30 border border-black"
              style={{ backgroundColor: `${theme?.accentColor ?? defaultAccentColor}` }}
            >
              <></>
            </div>
            <div className="mx-20 w-60">
              <span className="border-b border-white">{theme?.accentColor ?? defaultAccentColor}</span>
            </div>

            <Button onClick={handleAccentColorChange} className="relative py-10">
              anpassen
            </Button>
          </div>
          {isAccentColorPickerOpen && (
            <div className="absolute left-0 top-20 z-20 bg-lightGray p-15 rounded-20">
              <SketchPicker color={currentAccentColor} onChange={accentColorChange} />
              <div className="flex justify-between mt-15">
                <button type="button" onClick={() => abortAccentColor()}>
                  abbrechen
                </button>
                <Button onClick={handleAccentColorChange}>speichern</Button>
              </div>
            </div>
          )}
          <Button buttonStyle="text" onClick={handleResetAccentColor} className="mt-10 pt-15 text-white">
            Wert zurücksetzen
          </Button>
        </div>

        <div className="relative my-30 text-white rounded-20 p-20" style={{ backgroundColor: `${defaultLightGray}` }}>
          <label
            className="block font-bold cursor-pointer"
            onClick={() => openInfoBox('buttonTextColor')}
            onKeyDown={() => openInfoBox('buttonTextColor')}
            aria-hidden="true"
          >
            Textfarbe Buttons und angeklickter Menüelemente{' '}
            <span className="inline-block my-auto cursor-pointer">
              <InformationCircleIcon width={20} height={15} className="text-white" />
            </span>
          </label>
          <div className={buttonTextColorInfoClass}>
            <div
              className="absolute border border-white z-100 rounded-20 py-20 px-20 font-extralight w-200 md:w-500"
              style={{ backgroundColor: `${defaultLightGray}` }}
            >
              <div className="flex justify-end w-full pb-10">
                <XIcon
                  width={20}
                  height={20}
                  className="text-white cursor-pointer"
                  onClick={() => setButtonTextColorInfoClass('hidden')}
                />
              </div>
              <div className="text-white">Diese Farbanpassung ändert folgende Elemente:</div>
              <ul className="text-white list-disc px-30 pt-10 pb-10">
                <li>Textfarbe für aktiv angeklickte Menüelemente</li>
                <li>Textfarbe für Buttons</li>
              </ul>
            </div>
          </div>
          <div className="flex items-center pt-10">
            <div
              className="rounded-full w-30 h-30 border border-black"
              style={{ backgroundColor: `${theme?.buttonTextColor ?? defaultButtonTextColor}` }}
            >
              <></>
            </div>
            <div className="mx-20 w-60">
              <span className="border-b border-white">{theme?.buttonTextColor ?? defaultButtonTextColor}</span>
            </div>

            <Button onClick={handleButtonTextChange} className="relative py-10">
              anpassen
            </Button>
          </div>
          {isButtonTextColorPickerOpen && (
            <div className="absolute left-0 top-20 z-20 bg-lightGray p-15 rounded-20">
              <SketchPicker color={currentButtonTextColor} onChange={buttonTextColorChange} />
              <div className="flex justify-between mt-15">
                <button type="button" onClick={() => abortButtonTextColor()}>
                  abbrechen
                </button>
                <Button onClick={handleButtonTextChange}>speichern</Button>
              </div>
            </div>
          )}
          <Button buttonStyle="text" onClick={handleResetButtonTextColor} className="mt-10 pt-15 text-white">
            Wert zurücksetzen
          </Button>
        </div>

        <div className="relative my-30 text-white rounded-20 p-20" style={{ backgroundColor: `${defaultLightGray}` }}>
          <label
            className="block font-bold cursor-pointer"
            onClick={() => openInfoBox('notActiveButtonTextColor')}
            onKeyDown={() => openInfoBox('notActiveButtonTextColor')}
            aria-hidden="true"
          >
            Textfarbe nicht angeklickter Menüelemente{' '}
            <span className="inline-block my-auto cursor-pointer">
              <InformationCircleIcon width={20} height={15} className="text-white" />
            </span>
          </label>
          <div className={notActiveButtonTextColorInfoClass}>
            <div
              className="absolute border border-white z-100 rounded-20 py-20 px-20 font-extralight w-200 md:w-500"
              style={{ backgroundColor: `${defaultLightGray}` }}
            >
              <div className="flex justify-end w-full pb-10">
                <XIcon
                  width={20}
                  height={20}
                  className="text-white cursor-pointer"
                  onClick={() => setNotActiveButtonTextColorInfoClass('hidden')}
                />
              </div>
              <div className="text-white">Diese Farbanpassung ändert folgende Elemente:</div>
              <ul className="text-white list-disc px-30 pt-10 pb-10">
                <li>Textfarbe für nicht angeklickte Menüelemente</li>
              </ul>
            </div>
          </div>
          <div className="flex items-center pt-10">
            <div
              className="rounded-full w-30 h-30 border border-black"
              style={{ backgroundColor: `${theme?.notActiveButtonTextColor ?? defaultNotActiveButtonTextColor}` }}
            >
              <></>
            </div>
            <div className="mx-20 w-60">
              <span className="border-b border-white">
                {theme?.notActiveButtonTextColor ?? defaultNotActiveButtonTextColor}
              </span>
            </div>

            <Button onClick={handleNotActiveButtonTextColorChange} className="relative py-10">
              anpassen
            </Button>
          </div>
          {isNotActiveButtonTextColorPickerOpen && (
            <div className="absolute left-0 top-20 z-20 bg-lightGray p-15 rounded-20">
              <SketchPicker color={currentNotActiveButtonTextColor} onChange={notActiveButtonTextColorChange} />
              <div className="flex justify-between mt-15">
                <button type="button" onClick={() => abortNotActiveButtonTextColor()}>
                  abbrechen
                </button>
                <Button onClick={handleNotActiveButtonTextColorChange}>speichern</Button>
              </div>
            </div>
          )}
          <Button buttonStyle="text" onClick={handleResetNotActiveButtonTextColor} className="mt-10 pt-15 text-white">
            Wert zurücksetzen
          </Button>
        </div>

        <div className="mt-30 w-full text-white rounded-20 p-20" style={{ backgroundColor: `${defaultLightGray}` }}>
          <label className="block font-bold pb-10">Logo (120x100)</label>
          <p>Maximale Dateigröße: 5mb</p>
          <p className="mb-10">Format: jpg, gif, png, jpeg, svg</p>
          <div className="flex items-start gap-x-10 flex-wrap">
            <ImageUploading
              value={logoImages}
              onChange={onDropLogo}
              dataURLKey="data_url"
              maxFileSize={5242880}
              acceptType={['jpg', 'gif', 'png', 'gif', 'jpeg', 'svg']}
            >
              {({ onImageUpload, dragProps }) => (
                <div className="upload__image-wrapper mb-20">
                  <button
                    type="button"
                    onClick={onImageUpload}
                    {...dragProps}
                    className="h-200 w-200 border-dashed border-white border"
                  >
                    Click or Drop here
                  </button>
                </div>
              )}
            </ImageUploading>
            <img className="w-120 object-contain my-auto" loading="lazy" src={theme?.logo ?? LogoImg} alt="Logo" />
          </div>
        </div>

        <div
          className="relative mt-30 w-full text-white rounded-20 p-20"
          style={{ backgroundColor: `${defaultLightGray}` }}
        >
          <label
            className="block font-bold pb-10 cursor-pointer"
            onClick={() => openInfoBox('bgImage')}
            onKeyDown={() => openInfoBox('bgImage')}
            aria-hidden="true"
          >
            Hintergrundbild (1100x900){' '}
            <span className="inline-block my-auto cursor-pointer ">
              <InformationCircleIcon width={20} height={15} className="text-white" />
            </span>
          </label>
          <div className={bgImageInfoClass}>
            <div
              className="absolute border border-white z-100 rounded-20 py-20 px-20 font-extralight"
              style={{ backgroundColor: `${defaultLightGray}` }}
            >
              <div className="flex justify-end w-full pb-10">
                <XIcon
                  width={20}
                  height={20}
                  className="text-white cursor-pointer"
                  onClick={() => setBgImageInfoClass('hidden')}
                />
              </div>
              <div className="text-white">
                Das Hintergrundbild wird deinem eingeladenen Mitglied angezeigt, wenn er sich nach deiner Einladung
                registriert hat und seine persönlichen Daten eingibt.
              </div>
            </div>
          </div>
          <p>Maximale Dateigröße: 5mb</p>
          <p className="mb-10">Format: jpg, gif, png, jpeg, svg</p>

          <div className="flex items-start gap-x-10 flex-wrap">
            <ImageUploading
              value={logoImages}
              onChange={onDropBg}
              dataURLKey="data_url"
              maxFileSize={5242880}
              acceptType={['jpg', 'gif', 'png', 'gif', 'jpeg', 'svg']}
            >
              {({ onImageUpload, dragProps }) => (
                <div className="upload__image-wrapper mb-20">
                  <button
                    type="button"
                    onClick={onImageUpload}
                    {...dragProps}
                    className="h-200 w-200 border-dashed border-white border"
                  >
                    Click or Drop here
                  </button>
                </div>
              )}
            </ImageUploading>
            <img className="w-120 object-contain my-auto" src={theme?.bgImage ?? BGDesktopImg} alt="Hintergrundbild" />
          </div>
        </div>

        <Button onClick={handleResetAll} className="mt-50 py-10">
          Alle Werte zurücksetzen
        </Button>
      </div>
      <div className="col-md-4">
        <div className="absolute">
          <img src={PhoneImg} alt="mobilePhone" className="z-10 absolute" />
          <div className="relative px-50 pt-30">
            <iframe
              src={
                window.location.hostname === 'localhost'
                  ? 'http://localhost:3002/company?iframe=true'
                  : `https://${window.location.hostname}/company?iframe=true`
              }
              key={randomNumber}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="mobile"
              className="relative w-325 h-675"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

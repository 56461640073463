import React, { useContext } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { updateCookieBannerType } from 'shared/functions/global';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import Button from 'components/Button';

type Props = {
  show?: boolean;
};

const CookieBanner: React.FC<Props> = ({ show = false }) => {
  const authContext = useContext(AuthContext);
  const { userData, tenant } = useContext(AuthContext);

  const onlyTechnical = () => {
    updateCookieBannerType(userData, 'technical', false, authContext.user?.uid, tenant);
  };

  const acceptAll = () => {
    if (userData?.cookieBannerType === 'initial' && window.location.hostname === 'app.tortija.de') {
      ReactPixel.track('CompleteRegistration');
      TiktokPixel.track('CompleteRegistration', {});
    }
    updateCookieBannerType(userData, 'all', true, authContext.user?.uid, tenant);
  };

  return (
    <>
      {userData?.cookieBannerType === undefined || userData?.cookieBannerType === 'initial' || show === true ? (
        <div>
          {show === true ? (
            <div>
              <div>
                Wir nutzen Cookies auf unserer Website, um Funtionen für soziale Medien anbieten zu können und die
                Zugriffe auf unserer Website zu analyiseren. Du kannst entweder nur die technisch notwendigen Cookies
                akzeptieren oder alle Cookies akzeptieren. In deinem Profil unter Einstellungen kannst du diese
                Einstellung jederzeit ändern!
              </div>
              <div className="flex gap-20 flex-wrap pt-15">
                <div>
                  <Button className="py-5 text-14" onClick={() => acceptAll()}>
                    Alle akzeptieren
                  </Button>
                </div>
                <div>
                  <Button buttonStyle="white" className="py-5 text-14" onClick={() => onlyTechnical()}>
                    Nur technisch notwendige akzeptieren
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="absolute z-100 bottom-0 w-screen bg-bgColor px-30 py-20 font-extralight text-14">
              <div>
                Wir nutzen Cookies auf unserer Website, um Funtionen für soziale Medien anbieten zu können und die
                Zugriffe auf unserer Website zu analyiseren. Du kannst entweder nur die technisch notwendigen Cookies
                akzeptieren oder alle Cookies akzeptieren. In deinem Profil unter Einstellungen kannst du diese
                Einstellung jederzeit ändern!
              </div>
              <div className="flex gap-20 flex-wrap pt-15">
                <div>
                  <Button className="py-5 text-14" onClick={() => acceptAll()}>
                    Alle akzeptieren
                  </Button>
                </div>
                <div>
                  <Button buttonStyle="white" className="py-5 text-14" onClick={() => onlyTechnical()}>
                    Nur technisch notwendige akzeptieren
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CookieBanner;

import React, { useContext, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import CustomSelect from 'components/CustomSelect';
import Headline from 'components/Headline';
import { AuthContext } from 'providers/AuthProvider';
import { countryOption } from 'shared/constants/profile-wizard';

type Props = {
  isProfile?: boolean;
};

export default function ThirdPersonalInformation({ isProfile = false }: Props) {
  const authContext = useContext(AuthContext);

  const countryChange = (value: any) => {
    authContext.setUserData((prevProfile: UserInfo) => ({ ...prevProfile, country: value.value }));
  };

  const getLanguageKey = authContext.userData?.country ?? 'DE';

  const defaultCountryValue = useMemo(
    () => countryOption.filter((item: any) => item.value === getLanguageKey),
    [getLanguageKey]
  );

  return (
    <div className="space-y-20 font-extralight">
      <Headline className="capitalize" centered level={1}>
        Lebensmitteldatenbank
      </Headline>
      <div>Wähle dein Land aus, damit wir dir die passende Lebensmitteldatenbank zur Verfügung stellen können.</div>

      <CustomSelect
        name="country"
        dropDownOption={countryOption}
        label="Sprache / Land"
        onChange={countryChange}
        defaultValue={defaultCountryValue}
        className="w-full"
      />
    </div>
  );
}

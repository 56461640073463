import React, { useContext, useEffect, useState, useRef } from 'react';
import Headline from 'components/Headline';
import { useTranslation } from 'react-i18next';
import { AdjustmentsIcon, PlusIcon, XIcon, PencilIcon } from '@heroicons/react/outline';
import SwitchSelector from 'react-switch-selector';
import { scrollToTop, updateTourToFirebase } from 'shared/functions/global';
import Header from 'components/Header';
import SearchBox from 'components/SearchBox';
import classNames from 'classnames';
import { FilterContext } from 'providers/FilterProvider';
import { AuthContext } from 'providers/AuthProvider';
import { useLocation } from 'react-router-dom';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import { defaultButtonTextColor, defaultTextColor } from 'layouts/Theme';
import TransitionContainer from 'components/TransitionContainer';
import ClosePopup from './components/ClosePopup';
import RecipeSection from './components/RecipeSection';
import FilterDrawer from './components/FilterDrawer';
import IngridientForm from './components/IngridientForm';
import IngredientSection from './components/IngredientSection';
import NewRecipeStep1 from './components/NewRecipeStep1';
import NewRecipeStep2 from './components/NewRecipeStep2';
import NewRecipeStep3 from './components/NewRecipeStep3';
import NewRecipeStep4 from './components/NewRecipeStep4';
import NewRecipeStep5 from './components/NewRecipeStep5';
import NewRecipeStep6 from './components/NewRecipeStep6';
import 'react-image-crop/dist/ReactCrop.css';
import styles from './styles.module.scss';

const Nutrition: React.FC = () => {
  const { t } = useTranslation();
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isCommunityIngredient, setIsCommunityIngredient] = useState(false);
  const { nutritionSearchValue, setNutritionSearchValue } = useContext(FilterContext);
  const authContext = useContext(AuthContext);
  const { userData, tenant, theme } = useContext(AuthContext);

  const tourRef = useRef<HTMLDivElement>(null);
  const tourRefMobile = useRef<HTMLDivElement>(null);

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
    },
    scrollTo: true,
    classes: 'shepherd-theme-dark',
    useModalOverlay: true,
  };

  function TourButton() {
    const tour = useContext(ShepherdTourContext);

    return (
      <div
        className="hidden"
        ref={tourRef}
        onClick={() => userData?.role === 2 && tour?.start()}
        onKeyDown={() => userData?.role === 2 && tour?.start()}
        aria-hidden="true"
      >
        <div>Test</div>
      </div>
    );
  }

  function TourButtonMobile() {
    const tourMobile = useContext(ShepherdTourContext);

    return (
      <div
        className="hidden"
        ref={tourRefMobile}
        onClick={() => userData?.role === 2 && tourMobile?.start()}
        onKeyDown={() => userData?.role === 2 && tourMobile?.start()}
        aria-hidden="true"
      >
        <div>Test</div>
      </div>
    );
  }

  const [currentAddRecipe, setCurrentAddRecipe] = useState<any>({
    ingredients: [],
    description: [],
    kcal_total: '0',
    carbohydrates_total: '0',
    protein_total: '0',
    fat_total: '0',
    vegetarian: false,
    ketogen: false,
    vegan: false,
  });

  const recipesOption = [
    {
      label: t('Recipes'),
      value: 'recipes',
    },
    {
      label: t('Food'),
      value: 'food',
    },
  ];

  const filterOpen = () => {
    setFilterOpen(true);
  };

  const [filterType, setFilterType] = useState(recipesOption[0].value);
  const [currentSection, setCurrentSection] = useState<any>(recipesOption[0].value);

  const [currentIngridientList, setCurrentIngridientList] = useState<any>([]);

  const [currentEditIngridientObject, setCurrentEditIngridientObject] = useState<any>({});

  const [currentStep, setCurrentStep] = useState('1');

  const [nutritionLoaded, setNutritionLoaded] = useState(false);

  const [newIngridientOverlayClass, setNewIngridientOverlayClass] = useState('hidden');
  const [editIngridientOverlayClass, setEditIngridientOverlayClass] = useState('hidden');
  const [newRecipeOverlayClass, setNewRecipeOverlayClass] = useState('hidden');
  const [closeRecipeOverlayClass, setCloseRecipeOverlayClass] = useState('hidden');
  const [closeIngredientOverlayClass, setCloseIngredientOverlayClass] = useState('hidden');

  const ingredientPopupContentRef = useRef<HTMLDivElement>(null);
  const recipePopupContentRef = useRef<HTMLDivElement>(null);

  const location = useLocation<LocationState>();

  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNutritionSearchValue(event.target.value);
  };

  function closeRecipePopup() {
    setNewRecipeOverlayClass('hidden');
    setCloseRecipeOverlayClass('block');
  }

  function closeRecipe() {
    setCloseRecipeOverlayClass('hidden');
  }

  function reOpenRecipe() {
    setCloseRecipeOverlayClass('hidden');
    setNewRecipeOverlayClass('block');
  }

  function closeIngredientPopup() {
    setCloseIngredientOverlayClass('block');
  }

  function closeIngredient() {
    setNewIngridientOverlayClass('hidden');
    setCloseIngredientOverlayClass('hidden');
  }

  function reOpenIngredient() {
    setCloseIngredientOverlayClass('hidden');
  }

  function handleCreateNewItem() {
    if (currentSection === 'recipes') {
      setCurrentStep('1');
      setCurrentAddRecipe({
        ingredients: [],
        description: [],
        kcal_total: '0',
        carbohydrates_total: '0',
        protein_total: '0',
        fat_total: '0',
        flexitarian: true,
        vegetarian: false,
        ketogen: false,
        vegan: false,
      });

      setNewRecipeOverlayClass('block');
    } else {
      setIsCommunityIngredient(false);
      setNewIngridientOverlayClass('block');
      scrollToTop(ingredientPopupContentRef);
    }
  }

  function handleCreateNewCommunityItem() {
    if (currentSection === 'recipes') {
      setCurrentStep('1');
      setCurrentAddRecipe({
        ingredients: [],
        description: [],
        kcal_total: '0',
        carbohydrates_total: '0',
        protein_total: '0',
        fat_total: '0',
        flexitarian: true,
        vegetarian: false,
        ketogen: false,
        vegan: false,
        communityRecipe: true,
      });

      setNewRecipeOverlayClass('block');
    } else {
      setIsCommunityIngredient(true);
      setNewIngridientOverlayClass('block');
      scrollToTop(ingredientPopupContentRef);
    }
  }

  useEffect(() => {
    let thisScrollPos = 0;

    if (location.state?.scrollPos !== undefined) {
      thisScrollPos = location.state.scrollPos;
      setTimeout(() => {
        // document.getElementById('mainContainer')?.scrollTo(0, thisScrollPos);
      }, 1);
    }

    setTimeout(() => {
      if (document.getElementById('searchMobile')?.offsetParent === null) {
        if (tourRef.current && (userData?.cookieBannerType !== 'initial' || userData?.role === 5)) {
          if (userData?.tour?.nutrition === undefined) {
            tourRef.current.click();
          }
        }
      }

      if (document.getElementById('searchMobile')?.offsetParent !== null) {
        if (tourRefMobile.current && (userData?.cookieBannerType !== 'initial' || userData?.role === 5)) {
          if (userData?.tour?.nutrition === undefined) {
            tourRefMobile.current.click();
          }
        }
      }

      setNutritionLoaded(true);
    }, 1);
  }, []);

  return (
    <>
      <div className={newIngridientOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editIngridientPopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Neues Lebensmittel hinzufügen</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => closeIngredientPopup()}
                />
              </div>
            </div>
            <div className={styles.editIngridientPopupContent} ref={ingredientPopupContentRef}>
              <div className="pt-15  pb-30">
                {newIngridientOverlayClass === 'block' && currentSection !== 'recipes' && (
                  <IngridientForm
                    overlayClassFunc={setNewIngridientOverlayClass}
                    ingridientState={setCurrentIngridientList}
                    ingridientStateValue={currentIngridientList}
                    communityIngredient={isCommunityIngredient}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={editIngridientOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editIngridientPopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <PencilIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Lebensmittel bearbeiten</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => setEditIngridientOverlayClass('hidden')}
                />
              </div>
            </div>
            <div className={styles.editIngridientPopupContent} ref={ingredientPopupContentRef}>
              <div className="pt-15  pb-30">
                {editIngridientOverlayClass === 'block' && currentSection !== 'recipes' && (
                  <IngridientForm
                    overlayClassFunc={setEditIngridientOverlayClass}
                    ingridientState={setCurrentIngridientList}
                    ingridientStateValue={currentIngridientList}
                    type="edit"
                    editIngridientObject={currentEditIngridientObject}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={closeRecipeOverlayClass}>
        <ClosePopup closeFunction={closeRecipe} reOpenFunction={reOpenRecipe} />
      </div>
      <div className={closeIngredientOverlayClass}>
        <ClosePopup closeFunction={closeIngredient} reOpenFunction={reOpenIngredient} />
      </div>
      <div className={newRecipeOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editIngridientPopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Neues Rezept hinzufügen</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => closeRecipePopup()}
                />
              </div>
            </div>
            <div className={styles.addRecipePopupContent} ref={recipePopupContentRef}>
              <div className="pt-15 pb-30 px-20">
                {currentStep === '1' && (
                  <NewRecipeStep1
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    recipeState={setCurrentAddRecipe}
                    recipeStateValue={currentAddRecipe}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentStep === '2' && (
                  <NewRecipeStep2
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    recipeState={setCurrentAddRecipe}
                    recipeStateValue={currentAddRecipe}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentStep === '3' && (
                  <NewRecipeStep3
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    recipeState={setCurrentAddRecipe}
                    recipeStateValue={currentAddRecipe}
                    mainPopup={setNewRecipeOverlayClass}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentStep === '4' && (
                  <NewRecipeStep4
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    recipeState={setCurrentAddRecipe}
                    recipeStateValue={currentAddRecipe}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentStep === '5' && (
                  <NewRecipeStep5
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    recipeState={setCurrentAddRecipe}
                    recipeStateValue={currentAddRecipe}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentStep === '6' && (
                  <NewRecipeStep6
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    recipeState={setCurrentAddRecipe}
                    recipeStateValue={currentAddRecipe}
                    mainPopup={setNewRecipeOverlayClass}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ShepherdTour
        steps={[
          {
            id: 'intro',
            attachTo: { element: '#search', on: 'top' },
            buttons: [
              {
                classes: 'shepherd-button-secondary',
                text: 'Beenden',
                type: 'cancel',
              },
              {
                classes: 'shepherd-button-primary',
                text: 'Weiter',
                type: 'next',
              },
            ],
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            scrollTo: true,
            cancelIcon: {
              enabled: true,
            },
            title: 'Suche und Filter!',
            text: [
              '<p>Hier kannst du die Rezepte durchsuchen und filtern.</p><p>Beim Filter hast du umfangreiche Einstellungsmöglichkeiten um genau das Rezept zu finden was du suchst.</p><p><strong>Wusstest du schon?</strong></p><p>Du kannst jedes Rezept indivduell nach deinen Bedürfnissen anpassen und z.B. Lebensmittel löschen, Mengenangaben ändern oder neue Lebensmittel hinzufügen.</p>',
            ],
            when: {
              destroy() {
                updateTourToFirebase(userData, 'nutrition', authContext.user?.uid!, tenant);
                setTimeout(() => {
                  document.getElementById('mainContainer')?.scrollIntoView();
                }, 1);
              },
            },
          },
          {
            id: 'intro',
            attachTo: { element: '#newRecipe', on: 'top' },
            buttons: [
              {
                classes: 'shepherd-button-primary',
                text: 'Zurück',
                type: 'back',
              },
              {
                classes: 'shepherd-button-primary',
                text: 'Beenden',
                type: 'next',
              },
            ],
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            scrollTo: false,
            cancelIcon: {
              enabled: true,
            },
            title: 'Eigene Rezepte hinzufügen!',
            text: [
              '<p>Hier kannst du deine eigenen Rezepte hinzufügen und dann im Anschluss auch in deine Ernährungsplänen oder zum tracken verwenden.</p>',
            ],
            when: {
              show() {
                document.getElementById('mainContainer')?.scrollIntoView();
              },
            },
          },
        ]}
        tourOptions={tourOptions}
      >
        <TourButton />
      </ShepherdTour>

      <ShepherdTour
        steps={[
          {
            id: 'intro',
            attachTo: { element: '#searchMobile', on: 'top' },
            buttons: [
              {
                classes: 'shepherd-button-secondary',
                text: 'Beenden',
                type: 'cancel',
              },
              {
                classes: 'shepherd-button-primary',
                text: 'Weiter',
                type: 'next',
              },
            ],
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            scrollTo: true,
            cancelIcon: {
              enabled: true,
            },
            title: 'Suche und Filter!',
            text: [
              '<p>Hier kannst du die Rezepte durchsuchen und filtern.</p><p>Beim Filter hast du umfangreiche Einstellungsmöglichkeiten um genau das Rezept zu finden was du suchst.</p><p><strong>Wusstest du schon?</strong></p><p>Du kannst jedes Rezept indivduell nach deinen Bedürfnissen anpassen und z.B. Lebensmittel löschen, Mengenangaben ändern oder neue Lebensmittel hinzufügen.</p>',
            ],
            when: {
              destroy() {
                updateTourToFirebase(userData, 'nutrition', authContext.user?.uid!, tenant);
                setTimeout(() => {
                  document.getElementById('mainContainer')?.scrollIntoView();
                }, 1);
              },
            },
          },
          {
            id: 'intro',
            attachTo: { element: '#newRecipe', on: 'top' },
            buttons: [
              {
                classes: 'shepherd-button-primary',
                text: 'Zurück',
                type: 'back',
              },
              {
                classes: 'shepherd-button-primary',
                text: 'Beenden',
                type: 'next',
              },
            ],
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            scrollTo: false,
            cancelIcon: {
              enabled: true,
            },
            title: 'Eigene Rezepte hinzufügen!',
            text: [
              '<p>Hier kannst du deine eigenen Rezepte hinzufügen und dann im Anschluss auch in deine Ernährungsplänen oder zum tracken verwenden.</p>',
            ],
            when: {
              show() {
                document.getElementById('mainContainer')?.scrollIntoView();
              },
            },
          },
        ]}
        tourOptions={tourOptions}
      >
        <TourButtonMobile />
      </ShepherdTour>

      <TransitionContainer isShown={nutritionLoaded}>
        <Header>
          <div className="flex gap-10 flex-wrap w-full">
            <div className="flex-1 hidden tablet:block">
              <Headline level={1} className="">
                {t('Nutrition')}
              </Headline>
            </div>
            <div>
              <div className={styles.headlineWrapper}>
                <div className={styles.searchContainer} id="search">
                  <Headline level={1} className="tablet:hidden">
                    {t('Nutrition')}
                  </Headline>
                  <SearchBox
                    onChange={changeSearch}
                    className={styles.searchDesktop}
                    searchValue={nutritionSearchValue}
                    inputType="search"
                  />
                  <div
                    role="button"
                    aria-hidden
                    className={classNames(
                      'flex items-center space-x-3 rounded-2xl px-15 border-transparent border-2 hover:border-accentColor',
                      {
                        invisible: currentSection === 'food',
                      }
                    )}
                    onClick={filterOpen}
                  >
                    <AdjustmentsIcon
                      width={28}
                      height={28}
                      aria-hidden="true"
                      className={classNames(styles.filterIcon, {
                        invisible: currentSection === 'food',
                      })}
                    />
                    <Headline level={3}>{t('Filter')}</Headline>
                  </div>
                </div>
                <div className="block tablet:hidden w-full mb-30" id="searchMobile">
                  <SearchBox onChange={changeSearch} searchValue={nutritionSearchValue} inputType="search" />
                </div>
                <div className={styles.switchContainer}>
                  <SwitchSelector
                    onChange={setCurrentSection}
                    options={recipesOption}
                    initialSelectedIndex={0}
                    fontSize={20}
                    selectionIndicatorMargin={0}
                    selectedFontColor={theme?.buttonTextColor ?? defaultButtonTextColor}
                    fontColor={theme?.textColor ?? defaultTextColor}
                  />
                </div>
              </div>
            </div>
            <div className="flex-1" />
          </div>

          <div className="hidden">
            <div className={styles.headerInner}>
              <div className={styles.headlineWrapper}>
                <Headline level={1}>{t('Nutrition')}</Headline>
                <div className={styles.searchContainer}>
                  <SearchBox
                    onChange={changeSearch}
                    className={styles.searchDesktop}
                    searchValue={nutritionSearchValue}
                    inputType="search"
                  />
                  <div
                    role="button"
                    aria-hidden
                    className={classNames(
                      'flex items-center space-x-3 rounded-2xl px-15 border-transparent border-2 hover:border-accentColor',
                      {
                        invisible: currentSection === 'food',
                      }
                    )}
                    onClick={filterOpen}
                  >
                    <AdjustmentsIcon
                      width={28}
                      height={28}
                      aria-hidden="true"
                      className={classNames(styles.filterIcon, {
                        invisible: currentSection === 'food',
                      })}
                    />
                    <Headline level={3}>{t('Filter')}</Headline>
                  </div>
                </div>
              </div>
              <SearchBox
                onChange={changeSearch}
                searchValue={nutritionSearchValue}
                className={styles.searchMobile}
                inputType="search"
              />
              <div className={styles.switchContainer}>
                <SwitchSelector
                  onChange={setCurrentSection}
                  options={recipesOption}
                  initialSelectedIndex={0}
                  fontSize={20}
                  selectionIndicatorMargin={0}
                  selectedFontColor={theme?.buttonTextColor ?? defaultButtonTextColor}
                  fontColor={theme?.textColor ?? defaultTextColor}
                />
              </div>
            </div>
          </div>
        </Header>

        <div className={styles.container}>
          <div className={styles.contentHeader}>
            <Headline level={3}>{t('Browse')}</Headline>
            <div>
              <div>
                <div
                  className="flex my-auto p-7 rounded-lg cursor-pointer border-transparent border-2 hover:border-accentColor"
                  onClick={() => handleCreateNewItem()}
                  onKeyDown={() => handleCreateNewItem()}
                  aria-hidden="true"
                  id="newRecipe"
                >
                  {currentSection === 'recipes' ? (
                    <div className="text-sm font-light my-auto">Neues Rezept</div>
                  ) : (
                    <div className="text-sm font-light my-auto">Neues Lebensmittel</div>
                  )}
                  <div className="my-auto pl-5">
                    <PlusIcon width={25} height={25} className="text-accentColor cursor-pointer" />
                  </div>
                </div>
                <div onClick={() => {}} aria-hidden="true" className="cursor-pointer hidden">
                  <PlusIcon width={28} height={28} className="text-accentColor" onClick={() => handleCreateNewItem()} />
                </div>
              </div>
              {(userData?.isAdminUser || userData?.isTrainerUser || userData?.isLocationAdminUser) && (
                <>
                  <div
                    className="flex my-auto p-7 rounded-lg cursor-pointer border-transparent border-2 hover:border-accentColor"
                    onClick={() => handleCreateNewCommunityItem()}
                    onKeyDown={() => handleCreateNewCommunityItem()}
                    aria-hidden="true"
                    id="newRecipe"
                  >
                    {currentSection === 'recipes' ? (
                      <div className="text-sm font-light my-auto">Neues Community Rezept</div>
                    ) : (
                      <div className="text-sm font-light my-auto">Neues Community Lebensmittel</div>
                    )}
                    <div className="my-auto pl-5">
                      <PlusIcon width={25} height={25} className="text-accentColor cursor-pointer" />
                    </div>
                  </div>
                  <div onClick={() => {}} aria-hidden="true" className="cursor-pointer hidden">
                    <PlusIcon
                      width={28}
                      height={28}
                      className="text-accentColor"
                      onClick={() => handleCreateNewCommunityItem()}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {currentSection === 'recipes' && document.readyState === 'complete' ? (
            <RecipeSection />
          ) : (
            <IngredientSection
              ingridientState={setCurrentIngridientList}
              ingridientStateValue={currentIngridientList}
              editPopup={setEditIngridientOverlayClass}
              editIngridientObject={setCurrentEditIngridientObject}
            />
          )}
        </div>
      </TransitionContainer>
      <FilterDrawer
        isFilterOpen={isFilterOpen}
        closeFilterOpen={setFilterOpen}
        filterType={filterType}
        changeFilterType={setFilterType}
        recipesOption={recipesOption}
      />
    </>
  );
};

export default Nutrition;

import React, { useContext, useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Label,
} from 'recharts';
import { toast } from 'react-toast';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type Props = {
  planStateValue?: any;
  thisWeekIndex?: number;
  withClick?: boolean;
  updateDayId?: string;
  currentDate?: any;
  changeDayFunction?: any;
  switchSection?: any;
  switchIndex?: any;
};

const DashBoardWeekOverview: React.FC<Props> = ({
  planStateValue,
  thisWeekIndex = 0,
  withClick = false,
  updateDayId,
  currentDate,
  changeDayFunction,
  switchSection,
  switchIndex,
}) => {
  const { t } = useTranslation();

  const [currentWeekDate, setCurrentWeekDate] = useState(currentDate);
  const { userPlanList } = useContext(MainContext);

  const { userData, theme } = useContext(AuthContext);
  const history = useHistory();

  const [currentWeekDataObject, setCurrentWeekDataObject] = useState([
    {
      name: 'Mo.',
      longLabel: 'Montag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Di.',
      longLabel: 'Dienstag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Mi.',
      longLabel: 'Mittwoch',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Do.',
      longLabel: 'Donnerstag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Fr.',
      longLabel: 'Freitag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Sa.',
      longLabel: 'Samstag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'So.',
      longLabel: 'Sonntag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
  ]);

  const CustomTooltip = ({ active, payload, label }: { active: any; label: any; payload: any }) => {
    if (active) {
      return (
        <div className="bg-lightGray text-textColor px-30 py-30">
          {currentWeekDataObject?.filter((item: any) => item.name === label)[0].date !== '' ? (
            <>
              <div className="font-bold">
                {currentWeekDataObject?.filter((item: any) => item.name === label)[0].longLabel}
              </div>
              <div className="font-bold pb-10">
                {currentWeekDataObject?.filter((item: any) => item.name === label)[0].date}
              </div>
              <div>
                <p className="label text-calories"> {`${payload[0].name} : ${payload[0].value} %`} </p>
              </div>
              <div>
                <p className="label text-carbohydrates"> {`${payload[1].name} : ${payload[1].value} %`} </p>
              </div>
              <div>
                <p className="label text-protein"> {`${payload[2].name} : ${payload[2].value} %`} </p>
              </div>
              <div>
                <p className="label text-fat"> {`${payload[3].name} : ${payload[3].value} %`} </p>
              </div>
            </>
          ) : (
            <div>Für diesen Tag sind keine Daten im Plan vorhanden!</div>
          )}
        </div>
      );
    }

    return null;
  };

  const weekForward = () => {
    const thisNewDate = moment(currentWeekDate).endOf('week').subtract(-6, 'days');

    setCurrentWeekDate(thisNewDate);
    updateWeekValues(thisNewDate);
  };

  const weekBackward = () => {
    const thisNewDate = moment(currentWeekDate)
      .startOf('week')
      .subtract(+6, 'days');

    setCurrentWeekDate(thisNewDate);
    updateWeekValues(thisNewDate);
  };

  // Get Makro Percentage Values
  const getMakroPercentages = (type: string, custom_value: any, value: any) => {
    let thisCalculatedValue = 0;

    if (custom_value) {
      thisCalculatedValue = Math.round((100 * value) / custom_value);
    } else if (type === 'kcal') {
      thisCalculatedValue = Math.round((100 * value) / parseFloat(userData!.calories));
    } else if (type === 'carbohydrates') {
      thisCalculatedValue = Math.round((100 * value) / userData!.carbohydrates_total);
    } else if (type === 'protein') {
      thisCalculatedValue = Math.round((100 * value) / userData!.protein_total);
    } else if (type === 'fat') {
      thisCalculatedValue = Math.round((100 * value) / userData!.fat_total);
    }

    return thisCalculatedValue;
  };

  const checkCurrentPlanExists = (date: any, index: number, filteredList: any, newWeekObject: any) => {
    filteredList?.map((plan: PlanType) => plan.startDate && getCurrentPlanData(plan, date, index, newWeekObject));
  };

  const getCurrentPlanData = (plan: PlanType, date: any, index: number, newWeekObject: any) => {
    const thisStartDate = moment.unix(plan?.startDate.seconds).utc().format('YYYY-MM-DD');
    const thisEndDate = moment.unix(plan.endDate.seconds).utc().format('YYYY-MM-DD');

    const newDayItem = plan?.dayEntries.filter(
      (item: any) => moment.unix(item.date.seconds).utc().format('YYYYMMDD') === date.format('YYYYMMDD')
    )[0];

    if (
      moment(date.format('YYYY-MM-DD')).isBetween(thisStartDate, thisEndDate, undefined, '(]') ||
      moment(date.format('YYYY-MM-DD')).isBetween(thisStartDate, thisEndDate, undefined, '[)') ||
      moment(date.format('YYYY-MM-DD')).isBetween(thisStartDate, thisEndDate, undefined, '[]')
    ) {
      const getThisWeekName = moment.unix(newDayItem.date.seconds).utc().format('dddd');
      const getThisShortWeekName = moment.unix(newDayItem.date.seconds).utc().format('dd');

      newWeekObject.push({
        name: `${getThisShortWeekName}.`,
        longLabel: getThisWeekName,
        date: moment(moment.unix(newDayItem?.date.seconds).utc()).locale('de').format('DD[.]MM[.]YYYY'),
        dayId: newDayItem?.id,
        Kalorien: getMakroPercentages(
          'kcal',
          newDayItem?.custom_kcal_total
            ? newDayItem?.custom_kcal_total
            : plan?.custom_kcal_total
            ? plan?.custom_kcal_total
            : undefined,
          newDayItem?.kcal_total
        ),
        Kohlenhydrate: getMakroPercentages(
          'carbohydrates',
          newDayItem?.custom_carbohydrates_total
            ? newDayItem?.custom_carbohydrates_total
            : plan?.custom_carbohydrates_total
            ? plan?.custom_carbohydrates_total
            : undefined,
          newDayItem?.carbohydrates_total
        ),
        Eiweiß: getMakroPercentages(
          'protein',
          newDayItem?.custom_protein_total
            ? newDayItem?.custom_protein_total
            : plan?.custom_protein_total
            ? plan?.custom_protein_total
            : undefined,
          newDayItem?.protein_total
        ),
        Fett: getMakroPercentages(
          'fat',
          newDayItem?.custom_fat_total
            ? newDayItem?.custom_fat_total
            : plan?.custom_fat_total
            ? plan?.custom_fat_total
            : undefined,
          newDayItem?.fat_total
        ),
      });
    }
  };

  const updateWeekValues = (date: any) => {
    const thisStartDate = moment(date.format('YYYY-MM-DD')).startOf('week').utc().format('YYYY-MM-DD');
    const thisEndDate = moment(date.format('YYYY-MM-DD')).endOf('week').utc().format('YYYY-MM-DD');

    const filteredPlanList = userPlanList?.filter(
      element =>
        moment(moment.unix(element.startDate.seconds).utc().format('YYYY-MM-DD')).isBetween(
          thisStartDate,
          thisEndDate,
          undefined,
          '(]'
        ) ||
        moment(moment.unix(element.endDate.seconds).utc().format('YYYY-MM-DD')).isBetween(
          thisStartDate,
          thisEndDate,
          undefined,
          '(]'
        )
    );

    const newWeekObject = [] as any;

    for (let i = 0; i < 7; i += 1) {
      const thisDate = moment(date).startOf('week').subtract(-i, 'days');
      const getThisWeekName = moment(thisDate).format('dddd');
      const getThisShortWeekName = moment(thisDate).format('dd');
      const arrayLenghtBefore = newWeekObject.length;

      checkCurrentPlanExists(thisDate, i, filteredPlanList, newWeekObject);
      if (newWeekObject.length === arrayLenghtBefore) {
        newWeekObject.push({
          date: '',
          dayId: i,
          Kalorien: 0,
          Kohlenhydrate: 0,
          Eiweiß: 0,
          Fett: 0,
          name: `${getThisShortWeekName}.`,
          longLabel: getThisWeekName,
        });
      }
    }

    setCurrentWeekDataObject(newWeekObject);
  };

  useEffect(() => {
    if (userPlanList) {
      updateWeekValues(currentDate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pt-40">
      <div className="pl-20 pr-30 pb-10 flex flex-wrap justify-between">
        <div className="font-bold text-20">Wochenstatistik</div>
        <div className="flex">
          <div className="pt-1">
            <ChevronLeftIcon
              width={20}
              height={20}
              className="text-accentColor mx-auto cursor-pointer"
              onClick={() => weekBackward()}
            />
          </div>
          <div className="px-15">
            {moment(currentWeekDate).startOf('week').format('DD[.]MM[.]')} -{' '}
            {moment(currentWeekDate).endOf('week').format('DD[.]MM[.]')}
          </div>
          <div className="pt-1">
            <ChevronRightIcon
              width={20}
              height={20}
              className="text-accentColor mx-auto cursor-pointer"
              onClick={() => weekForward()}
            />
          </div>
        </div>
      </div>
      {withClick ? (
        <ResponsiveContainer width="100%" aspect={4.0 / 3.0}>
          <BarChart
            data={currentWeekDataObject}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 0,
            }}
            style={{ cursor: 'pointer' }}
            onClick={data => {
              if (data && data.activePayload && data.activePayload.length > 0) {
                if (changeDayFunction !== undefined && switchIndex !== undefined && switchSection !== undefined) {
                  if (
                    data.activePayload[0]?.payload?.date !== '' &&
                    data.activePayload[0]?.payload?.date !== undefined
                  ) {
                    changeDayFunction(moment(data.activePayload[0]?.payload?.date, 'DD.MM.YYYY').toDate());
                    switchIndex(0);
                    switchSection('day');
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              }
              return false;
            }}
            barCategoryGap="20%"
          >
            <XAxis
              dataKey="name"
              tick={{ fill: theme?.textColor ?? '#ffffff' }}
              tickLine={false}
              padding={{ right: 40, left: 20 }}
            />
            <Tooltip content={<CustomTooltip active={undefined} payload={undefined} label={undefined} />} />
            <ReferenceLine
              y={100}
              stroke={theme?.textColor ?? '#ffffff'}
              strokeWidth={2}
              alwaysShow
              strokeDasharray="3 3"
            >
              <Label
                value="100%"
                offset={6}
                position="insideBottomRight"
                fill={theme?.textColor ?? '#ffffff'}
                fontWeight="bold"
                fontSize="16"
              />
            </ReferenceLine>
            <Bar dataKey="Kalorien" fill="#01A2E0" />
            <Bar dataKey="Kohlenhydrate" fill="#DE4C39" />
            <Bar dataKey="Eiweiß" fill="#8DC703" />
            <Bar dataKey="Fett" fill="#FBA500" />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer width="95%" height={300}>
          <BarChart
            data={currentWeekDataObject}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 0,
            }}
            barCategoryGap="20%"
          >
            <XAxis
              dataKey="name"
              tick={{ fill: theme?.textColor ?? '#ffffff' }}
              tickLine={false}
              padding={{ right: 40, left: 20 }}
            />
            <Tooltip content={<CustomTooltip active={undefined} payload={undefined} label={undefined} />} />
            <ReferenceLine
              y={100}
              stroke={theme?.textColor ?? '#ffffff'}
              strokeWidth={2}
              alwaysShow
              strokeDasharray="3 3"
            >
              <Label
                value="100%"
                offset={6}
                position="insideBottomRight"
                fill={theme?.textColor ?? '#ffffff'}
                fontWeight="bold"
                fontSize="16"
              />
            </ReferenceLine>
            <Bar dataKey="Kalorien" fill="#01A2E0" />
            <Bar dataKey="Kohlenhydrate" fill="#DE4C39" />
            <Bar dataKey="Eiweiß" fill="#8DC703" />
            <Bar dataKey="Fett" fill="#FBA500" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default DashBoardWeekOverview;

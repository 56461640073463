import React, { useContext } from 'react';
import Card from 'components/Card';
import { AuthContext } from 'providers/AuthProvider';
import { PlusIcon } from '@heroicons/react/outline';
import styles from './styles.module.scss';

type Props = {
  label: string;
  mealLabel?: string;
  mealCounter?: number;
  type?: string;
  kcal_value?: number;
  carbohydrates_value: number;
  protein_value: number;
  fat_value?: number;
  imageUrl?: string;
  onClick?: any;
  mealType?: string;
  openIngredientSearchPopupClass?: any;
  mealAndIngredientCount?: number;
};

const DashboardMealCard: React.FC<Props> = ({
  label,
  mealLabel = '',
  mealCounter = 0,
  kcal_value = 0,
  carbohydrates_value = 0,
  protein_value = 0,
  fat_value = 0,
  mealAndIngredientCount = 0,
  imageUrl = '',
  type = 'add',
  onClick,
  mealType,
  openIngredientSearchPopupClass,
}) => {
  const openDirectAddPopup = (event: any) => {
    event.stopPropagation();
    openIngredientSearchPopupClass(mealType);
  };
  const { theme } = useContext(AuthContext);

  return (
    <>
      {type === 'add' || mealAndIngredientCount === 0 ? (
        <div
          onClick={() => openIngredientSearchPopupClass(mealType)}
          onKeyDown={() => openIngredientSearchPopupClass(mealType)}
          aria-hidden="true"
        >
          <Card isWithoutImage className="border-transparent border-2 hover:border-accentColor cursor-pointer h-220">
            <div className="absolute top-10 left-20 opacity-100 font-semibold text-17">{label}</div>
            <div className={styles.addIcon}>
              <div className="pt-20">
                <PlusIcon width={42} height={42} className="text-accentColor cursor-pointer mx-auto" />
              </div>
              <div className="text-center font-semibold text-18 px-20 pt-15">Hinzufügen</div>
            </div>
          </Card>
        </div>
      ) : (
        <div onClick={() => onClick(mealType, label)} onKeyDown={() => onClick(mealType, label)} aria-hidden="true">
          <Card
            isOverlay
            image={imageUrl.length > 0 ? imageUrl : theme?.logo ? theme?.logo : '/static/media/logo.1cff5e99.svg'}
            className="border-transparent border-2 hover:border-accentColor cursor-pointer"
          >
            <div className="absolute top-10 opacity-100 font-semibold text-17 text-buttonTextColor">{label}</div>
            <div className="absolute top-10 right-20 opacity-100 z-1">
              <PlusIcon
                width={30}
                height={30}
                className="text-white cursor-pointer mx-auto"
                onClick={e => openDirectAddPopup(e)}
              />
            </div>
            <div className="absolute bottom-10 w-full">
              <div className="flex font-semibold text-12 pb-2 w-3/4">
                <div className="text-ellipsis overflow-hidden truncate text-buttonTextColor">{mealLabel}</div>
                {mealCounter > 0 && <div className="pl-1 text-buttonTextColor">+{mealCounter}</div>}
              </div>
              <div className="flex justify-between pr-40">
                <div>
                  <div className="font-medium text-12 text-buttonTextColor">{kcal_value}</div>
                  <div className="font-medium text-8 text-buttonTextColor">KCAL</div>
                </div>
                <div>
                  <div className="font-medium text-12 text-buttonTextColor">{carbohydrates_value}</div>
                  <div className="font-medium text-8 text-buttonTextColor">KH</div>
                </div>
                <div>
                  <div className="font-medium text-12 text-buttonTextColor">{protein_value}</div>
                  <div className="font-medium text-8 text-buttonTextColor">EW</div>
                </div>
                <div>
                  <div className="font-medium text-12 text-buttonTextColor">{fat_value}</div>
                  <div className="font-medium text-8 text-buttonTextColor">FETT</div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default DashboardMealCard;

import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TransitionContainer from 'components/TransitionContainer';
import Headline from 'components/Headline';
import ReactLoading from 'react-loading';
import {
  ChartBarIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  TrashIcon,
  PencilIcon,
  CalendarIcon,
} from '@heroicons/react/outline';
import moment from 'moment';
import { toast } from 'react-toast';
import { useHistory } from 'react-router-dom';
import Overlay from 'components/Overlay';
import { ResponsiveContainer, ComposedChart, Line, Area, XAxis, YAxis, Tooltip } from 'recharts';
import {
  updateAnalyzeToFirebase,
  addAnalyzeToFirebase,
  deleteAnalyzeItemToFirebase,
  updateAnalyzeItemToFirebase,
  generateRandomUid,
  deleteAnalyzeToFirebase,
} from 'shared/functions/global';
import Button from 'components/Button';
import { AuthContext } from 'providers/AuthProvider';
import DatePicker from 'react-datepicker';
import { defaultTextColor } from 'layouts/Theme';

import styles from './styles.module.scss';

type Props = {
  isShown: boolean;
  title: string;
  goBack?: React.MouseEventHandler<HTMLButtonElement>;
  setIsAnalyze?: Function;
  setAddNewItemFunction?: Function;
};

const Analyze: React.FC<Props> = ({ isShown, title, setIsAnalyze, setAddNewItemFunction, goBack = () => {} }) => {
  const { t } = useTranslation();
  const [currentSection, setCurrentSection] = useState('start');
  const [currentAnalyzeType, setCurrentAnalyzeType] = useState('');
  const [currentAnalyzePiece, setCurrentAnalyzePiece] = useState('');
  const [addNewValue, setAddNewValue] = useState(false);
  const [isAnalyzeLoaded, setIsAnalyzeLoaded] = useState(false);
  const [currentAnalyzeIdentifier, setCurrentAnalyzeIdentifier] = useState('');
  const [currentAnalyzeNewValue, setCurrentAnalyzeNewValue] = useState(0);
  const [currentAnalyzeIsCustom, setCurrentAnalyzeIsCustom] = useState(false);

  const [startDate, setStartDate] = useState(null);

  const [showBMIPopupClass, setShowBMIPopupClass] = useState('hidden');
  const [currentBMI, setCurrentBMI] = useState(0);

  const [deleteItemPopupClass, setDeleteItemPopupClass] = useState('hidden');
  const [deleteItemDateString, setDeleteItemDateString] = useState('');

  const [editItemPopupClass, setEditItemPopupClass] = useState('hidden');
  const [editItemValue, setEditItemValue] = useState('');
  const [editItemName, setEditItemName] = useState('');
  const changeEditValueRef = useRef<HTMLInputElement>(null);

  const [jumpToDetailpage, setJumpToDetailpage] = useState(false);

  const { userData, tenant, theme, tenantData } = useContext(AuthContext);
  const authContext = useContext(AuthContext);

  const addNewValueRef = useRef<HTMLInputElement>(null);

  const history = useHistory();

  const updateEditValue = (event: any) => {
    setEditItemValue(event.target.value);
  };

  const CustomCalendarInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <div className="flex w-full">
          <div className="ml-10 my-auto">
            <CalendarIcon width={25} height={25} className="text-accentColor mx-auto cursor-pointer" />
          </div>
        </div>
      </label>
    );
  };

  const openEditPopup = (dateString: string, itemValue: number, itemName: string) => {
    setEditItemName(itemName);
    setEditItemPopupClass('block');
    setDeleteItemDateString(dateString);
    if (changeEditValueRef.current) {
      changeEditValueRef.current.value = itemValue.toString();
    }
  };

  function keyPress(event: any, submitFunction: any) {
    if (event.charCode === 13) {
      event.preventDefault();
      if (submitFunction !== undefined) {
        submitFunction();
      }
    }
  }

  const updateEditValueExecute = () => {
    const newColumns = currentDataObject
      .slice()
      .sort((a: any, b: any) => parseFloat(b.date.replaceAll('-', '')) - parseFloat(a.date.replaceAll('-', '')))
      .map((entries: any) => {
        if (entries.date !== deleteItemDateString) return entries;
        return {
          ...entries,
          value: Math.round(parseFloat(editItemValue) * 100) / 100,
        };
      });

    const newColumnsGraph = currentDataObject
      .slice()
      .sort((a: any, b: any) => parseFloat(a.date.replaceAll('-', '')) - parseFloat(b.date.replaceAll('-', '')))
      .map((entries: any) => {
        if (entries.date !== deleteItemDateString) return entries;
        return {
          ...entries,
          value: Math.round(parseFloat(editItemValue) * 100) / 100,
        };
      });

    if (parseFloat(editItemValue) > 0) {
      setCurrentDataObject(newColumnsGraph);
      setCurrentHistoryDataObject(newColumns);

      setJumpToDetailpage(true);

      updateAnalyzeItemToFirebase(userData, currentAnalyzeIdentifier, authContext.user?.uid, newColumns, tenant);

      setEditItemPopupClass('hidden');
    } else {
      toast.error('Bitte gebe einen Wert ein!');
    }
  };

  const openInBodyAnalyze = () => {
    history.push('/profile/inbody');
  };

  const clickAnalyzeDetail = (label: string, piece: string, identifier: string) => {
    const thisItem = userData?.analyze?.items.filter(element => element.name === identifier);
    const thisItem2 = userData?.analyze?.items.filter(element => element.name === identifier);

    if (setIsAnalyze !== undefined) {
      setCurrentAnalyzeType(label);
      setCurrentAnalyzePiece(piece);
      setCurrentAnalyzeIdentifier(identifier);
      setAddNewValue(false);

      if (thisItem !== undefined && thisItem2 !== undefined) {
        if (thisItem[0]?.isCustom !== undefined) {
          setCurrentAnalyzeIsCustom(thisItem[0]?.isCustom);
        } else {
          setCurrentAnalyzeIsCustom(false);
        }

        if (thisItem[0]?.itemValues.length > 0 && thisItem2[0]?.itemValues.length > 0) {
          setIsAnalyze(true);
          setCurrentHistoryDataObject(
            thisItem2[0].itemValues
              .slice()
              .sort((a: any, b: any) => parseFloat(b.date.replaceAll('-', '')) - parseFloat(a.date.replaceAll('-', '')))
          );

          setCurrentDataObject(
            thisItem[0].itemValues
              .slice()
              .sort((a: any, b: any) => parseFloat(a.date.replaceAll('-', '')) - parseFloat(b.date.replaceAll('-', '')))
          );

          setCurrentSection('detail');
        } else {
          setCurrentSection('noValue');
        }
      } else {
        setCurrentAnalyzeIsCustom(false);
        setCurrentSection('noValue');
      }
    }
  };

  const addNewValueBtn = () => {
    setStartDate(null);
    setAddNewValue(true);
    setTimeout(() => {
      if (addNewValueRef.current) {
        addNewValueRef.current.select();
      }
    }, 1);
  };

  const backToStart = () => {
    setCurrentSection('start');
    if (setIsAnalyze !== undefined) {
      setIsAnalyze(false);
    }
  };

  const [currentHistoryDataObject, setCurrentHistoryDataObject] = useState<any>([]);

  const [currentDataObject, setCurrentDataObject] = useState<any>([
    {
      name: '10. Dez. 16',
      value: 120.5,
    },
    {
      name: 'Page B',
      value: 119.5,
    },
    {
      name: 'Page C',
      value: 119,
    },
    {
      name: 'Page D',
      value: 120,
    },
    {
      name: 'Page E',
      value: 119.5,
    },
    {
      name: 'Page F',
      value: 118.5,
    },
    {
      name: 'Page G',
      value: 118,
    },
    {
      name: 'Page H',
      value: 117.5,
    },
    {
      name: 'Page I',
      value: 117,
    },
    {
      name: 'Page J',
      value: 116.5,
    },
    {
      name: 'Page K',
      value: 116,
    },
    {
      name: '10. Dez. 22',
      value: 110,
    },
  ]);

  const CustomTooltip = ({ active, payload, label }: { active: any; label: any; payload: any }) => {
    if (active) {
      return (
        <div className="bg-lightGray text-textColor px-30 py-30">
          <div className="font-semibold">{label}</div>
          <div className="text-accentColor font-bold text-30">
            {payload[0]?.value?.toString().replaceAll('.', ',')} {currentAnalyzeType !== 'BMI' && currentAnalyzePiece}
          </div>
        </div>
      );
    }

    return null;
  };

  const saveInitialValue = () => {
    const generateName = generateRandomUid();

    let thisName = currentAnalyzeIdentifier;

    if (thisName.length === 0) {
      thisName = generateName;
    }

    if (startDate !== null) {
      if (currentAnalyzeNewValue > 0) {
        addAnalyzeToFirebase(
          userData,
          authContext.user?.uid,
          currentAnalyzeIsCustom,
          currentAnalyzeType,
          thisName,
          currentAnalyzePiece,
          moment(startDate).locale('de').format('DD. MMM YYYY'),
          currentAnalyzeNewValue,
          moment(startDate).format('YYYY-MM-DD'),
          true,
          tenant
        );

        setAddNewValue(false);
        setJumpToDetailpage(true);
      } else {
        toast.error('Bitte gebe einen Wert ein!');
      }
    } else if (currentAnalyzeNewValue > 0) {
      addAnalyzeToFirebase(
        userData,
        authContext.user?.uid,
        currentAnalyzeIsCustom,
        currentAnalyzeType,
        thisName,
        currentAnalyzePiece,
        moment().locale('de').format('DD. MMM YYYY'),
        currentAnalyzeNewValue,
        moment().format('YYYY-MM-DD'),
        true,
        tenant
      );

      setAddNewValue(false);
      setJumpToDetailpage(true);
    } else {
      toast.error('Bitte gebe einen Wert ein!');
    }
  };

  const changeAnalyzeDate = (dates: any) => {
    setStartDate(dates);
  };

  const calculateBMI = () => {
    if (userData?.bodyWeight !== undefined && userData?.bodySize) {
      const getBodySizeCM = parseFloat(userData?.bodySize) / 100;
      const getBodySize = getBodySizeCM * getBodySizeCM;
      const getBmi = parseFloat(userData?.bodyWeight) / getBodySize;
      const getBmiFixed = parseFloat(getBmi.toFixed(1));

      addAnalyzeToFirebase(
        userData,
        authContext.user?.uid,
        false,
        'BMI',
        'bmi',
        'kg/m²',
        moment().locale('de').format('DD. MMM YYYY'),
        getBmiFixed,
        moment().format('YYYY-MM-DD'),
        true,
        tenant
      );

      setCurrentBMI(getBmiFixed);
      setShowBMIPopupClass('block');
      setJumpToDetailpage(true);
    } else {
      toast.error('Es ist kein Gewicht in deinem Profil vorhanden. Bitte prüfe deine Profilangaben!');
    }
  };

  const calculateNewBMI = () => {
    if (userData?.bodyWeight !== undefined && userData?.bodySize) {
      const isValueOnDayExists = userData?.analyze?.items
        ?.filter(element => element.name === 'bmi')[0]
        .itemValues?.filter(item => item.date === moment().format('YYYY-MM-DD'));

      const getBodySizeCM = parseFloat(userData?.bodySize) / 100;
      const getBodySize = getBodySizeCM * getBodySizeCM;
      const getBmi = parseFloat(userData?.bodyWeight) / getBodySize;
      const getBmiFixed = parseFloat(getBmi.toFixed(1));

      if (isValueOnDayExists?.length === 0) {
        setCurrentBMI(getBmiFixed);
        setShowBMIPopupClass('block');
      }

      saveNewValue('bmi', getBmiFixed);
      setJumpToDetailpage(true);
    } else {
      toast.error('Es ist kein Gewicht in deinem Profil vorhanden. Bitte prüfe deine Profilangaben!');
    }
  };

  const saveNewValue = (name: string, newValue: number) => {
    let isValueOnDayExists = userData?.analyze?.items
      ?.filter(element => element.name === name)[0]
      .itemValues?.filter(item => item.date === moment().format('YYYY-MM-DD'));

    if (startDate !== null) {
      isValueOnDayExists = userData?.analyze?.items
        ?.filter(element => element.name === name)[0]
        .itemValues?.filter(item => item.date === moment(startDate).format('YYYY-MM-DD'));
    }

    if (newValue > 0) {
      if (isValueOnDayExists?.length === 0) {
        if (startDate !== null) {
          updateAnalyzeToFirebase(
            userData,
            name,
            authContext.user?.uid,
            moment(startDate).locale('de').format('DD. MMM YYYY'),
            newValue,
            moment(startDate).format('YYYY-MM-DD'),
            tenant
          );
        } else {
          updateAnalyzeToFirebase(
            userData,
            name,
            authContext.user?.uid,
            moment().locale('de').format('DD. MMM YYYY'),
            newValue,
            moment().format('YYYY-MM-DD'),
            tenant
          );
        }
      } else {
        toast.error('An diesem Tag hast du schon einen Wert eingetragen!');
      }

      setJumpToDetailpage(true);

      setAddNewValue(false);
    } else {
      toast.error('Bitte gebe einen Wert ein!');
    }
  };

  const deleteAnalyze = () => {
    if (setIsAnalyze !== undefined) {
      setIsAnalyze(false);
    }

    setCurrentSection('start');
    deleteAnalyzeToFirebase(userData, authContext.user?.uid, currentAnalyzeIdentifier, tenant);
  };

  const deleteItem = (dateString: string) => {
    setDeleteItemPopupClass('block');
    setDeleteItemDateString(dateString);
  };

  const deleteItemExecute = () => {
    const newColumns = [
      ...currentDataObject
        .filter((item: any) => item.date !== deleteItemDateString)
        .slice()
        .sort((a: any, b: any) => parseFloat(b.date.replaceAll('-', '')) - parseFloat(a.date.replaceAll('-', ''))),
    ];

    const newColumnsGraph = [
      ...currentDataObject
        .filter((item: any) => item.date !== deleteItemDateString)
        .slice()
        .sort((a: any, b: any) => parseFloat(a.date.replaceAll('-', '')) - parseFloat(b.date.replaceAll('-', ''))),
    ];

    setCurrentDataObject([newColumnsGraph]);
    setCurrentHistoryDataObject(newColumns);

    if (newColumns.length === 0) {
      deleteAnalyzeItemToFirebase(userData, currentAnalyzeIdentifier, authContext.user?.uid, tenant);
    } else {
      updateAnalyzeItemToFirebase(userData, currentAnalyzeIdentifier, authContext.user?.uid, newColumns, tenant);
    }

    setDeleteItemPopupClass('hidden');
    setJumpToDetailpage(true);
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  useEffect(() => {
    if (!isAnalyzeLoaded && userData?.age !== undefined) {
      setIsAnalyzeLoaded(true);
    }

    if (jumpToDetailpage === true) {
      clickAnalyzeDetail(currentAnalyzeType, currentAnalyzePiece, currentAnalyzeIdentifier);
      setJumpToDetailpage(false);
    }
  }, [userData, isAnalyzeLoaded]);

  useEffect(() => {
    if (isShown) {
      setCurrentSection('start');
    }
  }, [isShown]);

  return (
    <>
      <Overlay
        overlayClass={deleteItemPopupClass}
        setOverlayClass={setDeleteItemPopupClass}
        headline="Eintrag löschen"
        icon={<TrashIcon width={25} height={25} className="text-accentColor mx-auto" />}
        topPadding
      >
        <div className="pb-60">
          <div className="pt-30 font-extralight text-base">
            <div>Möchtest du diesen Eintrag wirklich löschen?</div>
            <div className="pt-10">Diese Aktion kann nicht rückgängig gemacht werden.</div>
          </div>
          <div className="pt-30 flex justify-between flex-wrap">
            <Button onClick={() => deleteItemExecute()}>Löschen</Button>
            <Button onClick={() => setDeleteItemPopupClass('hidden')}>Abbrechen</Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={showBMIPopupClass}
        setOverlayClass={setShowBMIPopupClass}
        headline="Dein aktueller BMI"
        icon={<TrashIcon width={25} height={25} className="text-accentColor mx-auto" />}
        topPadding
      >
        <div className="pb-60">
          <div className="pt-30 font-extralight text-base">
            <div>Anhand deiner Körpergröße und deines Gewichts aus deinem Profil ist dein aktueller BMI Wert:</div>
            <div className="pt-40 font-bold text-40 text-center">{currentBMI.toString().replaceAll('.', ',')}</div>
          </div>
          <div className="pt-60 w-full">
            <Button onClick={() => setShowBMIPopupClass('hidden')} className="w-full">
              Fertig
            </Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={editItemPopupClass}
        setOverlayClass={setEditItemPopupClass}
        headline="Eintrag bearbeiten"
        icon={<PencilIcon width={25} height={25} className="text-accentColor mx-auto" />}
        topPadding
      >
        <div className="pb-60">
          <div className="pt-30 font-extralight text-base">
            <div>Trage in dem unteren Feld bitte einen neuen Wert ein.</div>
            <div className="pt-10">
              Du bearbeitest folgenden Eintrag: <strong>{editItemName}</strong>
            </div>
            <div className="mt-20 w-120 rounded-md border-solid border border-accentColor border-opacity-30 py-2 px-2">
              <input
                id="calories"
                name="calories"
                type="number"
                onFocus={setBodyClassForMobileSafari}
                onBlur={removeBodyClassForMobileSafari}
                className="w-100 bg-transparent my-auto appearance-none block text-18 font-semibold placeholder-gray-400 focus:outline-none text-textColor text-center"
                onChange={updateEditValue}
                ref={changeEditValueRef}
                onKeyPress={e => keyPress(e, updateEditValueExecute)}
              />
            </div>
          </div>
          <div className="pt-30 flex justify-between flex-wrap">
            <Button onClick={() => updateEditValueExecute()}>Speichern</Button>
            <Button onClick={() => setEditItemPopupClass('hidden')}>Abbrechen</Button>
          </div>
        </div>
      </Overlay>

      <TransitionContainer isShown={isShown}>
        {isAnalyzeLoaded ? (
          <>
            {currentSection === 'start' ? (
              <>
                <div className={styles.header}>
                  <Headline level={1} className="pt-50 md:pt-0 mb-20">
                    {t(title)}
                  </Headline>

                  <div className="pb-20">
                    <div className="flex flex-wrap gap-20 justify-between">
                      <Headline level={4} displayBackBtn goBack={goBack}>
                        {t('Return')}
                      </Headline>
                      <div>
                        <Button onClick={() => setAddNewItemFunction !== undefined && setAddNewItemFunction()}>
                          Eigene Analyse erstellen
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                {tenantData?.features?.inBody !== undefined && tenantData?.features?.inBody === true && (
                  <div
                    onClick={openInBodyAnalyze}
                    aria-hidden="true"
                    className="relative cursor-pointer border-transparent border-2 hover:border-accentColor px-20 py-16 h-full flex justify-center items-center text-center rounded-20 filter text-17 leading-20 font-bold bg-lightGray text-textColor bg-opacity-20"
                  >
                    <div>
                      <ChartBarIcon width={55} height={55} className="text-accentColor mx-auto mb-20" />
                      <Headline level={4} className={styles.label}>
                        InBody Körperanalyse
                      </Headline>
                    </div>
                  </div>
                )}
                <div className={styles.content}>
                  {userData?.analyze?.items
                    ?.filter(element => element.isCustom === true)
                    .map((item, index) => (
                      <div
                        key={index}
                        className={styles.button}
                        onClick={() => clickAnalyzeDetail(item.label, item.piece, item.name)}
                        onKeyDown={() => clickAnalyzeDetail(item.label, item.piece, item.name)}
                        aria-hidden="true"
                      >
                        <div className="pt-20">
                          <ChartBarIcon width={55} height={55} className={styles.icon} />
                          <Headline level={4} className={styles.label}>
                            {item.label}
                          </Headline>
                        </div>
                        <div className="absolute top-10 left-10 bg-accentColor text-10 font-bold rounded-full px-10 py-1 text-buttonTextColor">
                          <div>Eigene Analyse</div>
                        </div>
                      </div>
                    ))}
                  <div
                    className={styles.button}
                    onClick={() => clickAnalyzeDetail('Gewicht', 'KG', 'weight')}
                    onKeyDown={() => clickAnalyzeDetail('Gewicht', 'KG', 'weight')}
                    aria-hidden="true"
                  >
                    <div>
                      <ChartBarIcon width={55} height={55} className={styles.icon} />
                      <Headline level={4} className={styles.label}>
                        Gewicht
                      </Headline>
                    </div>
                  </div>
                  <div
                    className={styles.button}
                    onClick={() => clickAnalyzeDetail('Körperfettanteil', '%', 'bodyFat')}
                    onKeyDown={() => clickAnalyzeDetail('Körperfettanteil', '%', 'bodyFat')}
                    aria-hidden="true"
                  >
                    <div>
                      <ChartBarIcon width={55} height={55} className={styles.icon} />
                      <Headline level={4} className={styles.label}>
                        Körperfettanteil
                      </Headline>
                    </div>
                  </div>
                  <div
                    className={styles.button}
                    onClick={() => clickAnalyzeDetail('Bauchumfang', 'CM', 'waist')}
                    onKeyDown={() => clickAnalyzeDetail('Bauchumfang', 'CM', 'waist')}
                    aria-hidden="true"
                  >
                    <div>
                      <ChartBarIcon width={55} height={55} className={styles.icon} />
                      <Headline level={4} className={styles.label}>
                        Bauchumfang
                      </Headline>
                    </div>
                  </div>
                  <div
                    className={styles.button}
                    onClick={() => clickAnalyzeDetail('Muskelmasse', 'KG', 'muscleMass')}
                    onKeyDown={() => clickAnalyzeDetail('Muskelmasse', 'KG', 'muscleMass')}
                    aria-hidden="true"
                  >
                    <div>
                      <ChartBarIcon width={55} height={55} className={styles.icon} />
                      <Headline level={4} className={styles.label}>
                        Muskelmasse
                      </Headline>
                    </div>
                  </div>
                  <div
                    className={styles.button}
                    onClick={() => clickAnalyzeDetail('BMI', 'kg/m²', 'bmi')}
                    onKeyDown={() => clickAnalyzeDetail('BMI', 'kg/m²', 'bmi')}
                    aria-hidden="true"
                  >
                    <div>
                      <ChartBarIcon width={55} height={55} className={styles.icon} />
                      <Headline level={4} className={styles.label}>
                        BMI
                      </Headline>
                    </div>
                  </div>
                  <div
                    className={styles.button}
                    onClick={() => clickAnalyzeDetail('Blutzucker', 'mg/dl', 'bloodSugar')}
                    onKeyDown={() => clickAnalyzeDetail('Blutzucker', 'mg/dl', 'bloodSugar')}
                    aria-hidden="true"
                  >
                    <div>
                      <ChartBarIcon width={55} height={55} className={styles.icon} />
                      <Headline level={4} className={styles.label}>
                        Blutzucker
                      </Headline>
                    </div>
                  </div>
                </div>
              </>
            ) : currentSection === 'noValue' ? (
              <div>
                <div className="flex justify-between">
                  <Headline level={4} displayBackBtn goBack={backToStart}>
                    {t('Return')}
                  </Headline>
                  {currentAnalyzeIsCustom === true && (
                    <div>
                      <Button onClick={() => deleteAnalyze()} className="py-5 text-12">
                        Analyse löschen
                      </Button>
                    </div>
                  )}
                </div>

                <div className="font-bold text-25 pt-30">Analyse - {currentAnalyzeType}</div>
                <div className="font-extralight pt-20">Du hast noch keine Werte eingetragen.</div>
                <div className="font-extralight pt-10">
                  Trage jetzt hier deinen ersten Wert ein und starte damit deine Analyse!
                </div>
                {addNewValue ? (
                  <div>
                    <div className="flex space-x-10">
                      <div className="font-semibold pb-15 pt-10">Datum:</div>
                      <div className="font-extralight pb-15 pt-10">
                        {startDate === null ? (
                          <div>Heute, {moment().format('DD.MM.YYYY')}</div>
                        ) : (
                          <div>{moment(startDate).format('DD.MM.YYYY')}</div>
                        )}
                      </div>
                      <div className="pt-10">
                        <DatePicker
                          className="my-auto"
                          dateFormat="dd.MM.yyyy"
                          selected={startDate}
                          locale="de"
                          withPortal
                          onChange={changeAnalyzeDate}
                          calendarStartDay={1}
                          customInput={
                            <PencilIcon width={20} height={20} className="text-accentColor cursor-pointer my-auto" />
                          }
                          onFocus={e => e.target.blur()}
                        />
                      </div>
                    </div>
                    <div className="md:flex block gap-10">
                      <div className="flex py-2">
                        <div className="rounded-md border-solid border border-textColor border-opacity-30 py-2">
                          <input
                            id="calories"
                            name="calories"
                            onFocus={setBodyClassForMobileSafari}
                            onBlur={removeBodyClassForMobileSafari}
                            onChange={e => setCurrentAnalyzeNewValue(parseFloat(e.target.value))}
                            type="number"
                            ref={addNewValueRef}
                            className="w-100 appearance-none block text-18 font-semibold placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor text-center"
                          />
                        </div>
                        <div className="pl-10 font-semibold my-auto">{currentAnalyzePiece}</div>
                      </div>
                      <div className="pt-20 md:pt-0 md:pl-20 my-auto">
                        <Button
                          onClick={() =>
                            currentAnalyzeIsCustom
                              ? saveNewValue(currentAnalyzeIdentifier, currentAnalyzeNewValue)
                              : saveInitialValue()
                          }
                          className="py-10"
                        >
                          Speichern
                        </Button>
                      </div>
                      <div className="pt-10 md:pt-0 my-auto">
                        <Button onClick={() => setAddNewValue(false)} className="py-10" buttonStyle="white">
                          Abbrechen
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {currentAnalyzeIdentifier === 'bmi' ? (
                      <div className="flex justify-between">
                        <div className="mt-20">
                          <Button onClick={() => calculateBMI()}>BMI automatisch berechnen</Button>
                        </div>
                        <div className="mt-20">
                          <Button buttonStyle="dark" onClick={() => addNewValueBtn()}>
                            BMI manuell eintragen
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="mt-20">
                        <Button onClick={() => addNewValueBtn()}>Ersten Wert eintragen</Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="flex justify-between">
                  <Headline level={4} displayBackBtn goBack={backToStart}>
                    {t('Return')}
                  </Headline>
                  {currentAnalyzeIsCustom === true && (
                    <div>
                      <Button onClick={() => deleteAnalyze()} className="py-5 text-12">
                        Analyse löschen
                      </Button>
                    </div>
                  )}
                </div>
                <div className="flex justify-between">
                  <div>
                    <div className="flex gap-20">
                      <div className="font-bold text-25 pt-20">{currentAnalyzeType}</div>
                    </div>
                    <div className="text-accentColor font-bold text-30">
                      {currentDataObject[currentDataObject.length - 1]?.value?.toString().replaceAll('.', ',')}{' '}
                      {currentAnalyzeType !== 'BMI' && currentAnalyzePiece}
                    </div>
                  </div>
                  <div className="my-auto flex gap-5">
                    <div className="my-auto">
                      {currentDataObject[currentDataObject.length - 1]?.value - currentDataObject[0]?.value > 0 ? (
                        <ArrowSmUpIcon width={30} height={30} className="text-textColor" />
                      ) : currentDataObject[currentDataObject.length - 1]?.value - currentDataObject[0]?.value === 0 ? (
                        <>+-</>
                      ) : (
                        <ArrowSmDownIcon width={30} height={30} className="text-textColor" />
                      )}
                    </div>
                    <div className="my-auto font-bold text-20 text-accentColor">
                      {currentDataObject[currentDataObject.length - 1]?.value - currentDataObject[0]?.value > 0 && '+'}{' '}
                      {(currentDataObject[currentDataObject.length - 1]?.value - currentDataObject[0]?.value)
                        .toFixed(2)
                        .replace(/\.00$/, '')}{' '}
                      {currentAnalyzeType !== 'BMI' && currentAnalyzePiece}
                    </div>
                  </div>
                </div>

                <div className="w-full h-1/2">
                  <ResponsiveContainer aspect={2.5}>
                    <ComposedChart
                      width={800}
                      height={300}
                      data={currentDataObject}
                      margin={{ top: 25, right: 30, left: 20, bottom: 5 }}
                    >
                      <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#129a74" stopOpacity={0.1} />
                          <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
                        </linearGradient>
                      </defs>
                      <XAxis
                        dataKey="name"
                        ticks={
                          currentDataObject.length > 1
                            ? [currentDataObject[0]?.name, currentDataObject[currentDataObject.length - 1]?.name]
                            : [currentDataObject[0]?.name]
                        }
                      />
                      <YAxis tickCount={5} allowDecimals domain={['dataMin', 'dataMax']} />
                      <Tooltip content={<CustomTooltip active={undefined} payload={undefined} label={undefined} />} />

                      <Line
                        type="monotone"
                        strokeLinecap="round"
                        strokeWidth={4}
                        dataKey="value"
                        stroke="#C97132"
                        dot={{ stroke: 'white', strokeWidth: 2, fill: 'white' }}
                      />
                      <Area type="monotone" stroke="#C97132" dataKey="value" strokeWidth={0} fill="url(#colorUv)" />
                    </ComposedChart>
                  </ResponsiveContainer>
                  {addNewValue ? (
                    <div>
                      <div className="flex space-x-10">
                        <div className="font-semibold pb-15 pt-10">Datum:</div>
                        <div className="font-extralight pb-15 pt-10">
                          {startDate === null ? (
                            <div>Heute, {moment().format('DD.MM.YYYY')}</div>
                          ) : (
                            <div>{moment(startDate).format('DD.MM.YYYY')}</div>
                          )}
                        </div>
                        <div className="pt-10">
                          <DatePicker
                            className="my-auto"
                            dateFormat="dd.MM.yyyy"
                            selected={startDate}
                            locale="de"
                            withPortal
                            onChange={changeAnalyzeDate}
                            calendarStartDay={1}
                            customInput={
                              <PencilIcon width={20} height={20} className="text-accentColor cursor-pointer my-auto" />
                            }
                            onFocus={e => e.target.blur()}
                          />
                        </div>
                      </div>
                      <div className="md:flex block gap-10">
                        <div className="flex py-2">
                          <div className="rounded-md border-solid border border-textColor border-opacity-30 py-2">
                            <input
                              id="calories"
                              name="calories"
                              onFocus={setBodyClassForMobileSafari}
                              onBlur={removeBodyClassForMobileSafari}
                              onChange={e => setCurrentAnalyzeNewValue(parseFloat(e.target.value))}
                              type="number"
                              ref={addNewValueRef}
                              className="w-100 appearance-none block text-18 font-semibold placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor text-center"
                            />
                          </div>
                          <div className="pl-10 font-semibold my-auto">{currentAnalyzePiece}</div>
                        </div>
                        <div className="pt-20 md:pt-0 md:pl-20 my-auto">
                          <Button
                            onClick={() => saveNewValue(currentAnalyzeIdentifier, currentAnalyzeNewValue)}
                            className="py-10"
                          >
                            Speichern
                          </Button>
                        </div>
                        <div className="pt-10 md:pt-0 my-auto">
                          <Button onClick={() => setAddNewValue(false)} className="py-10" buttonStyle="white">
                            Abbrechen
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="flex space-x-20">
                        {currentAnalyzeIdentifier === 'bmi' && (
                          <div className="mt-20">
                            <Button onClick={() => calculateNewBMI()}>BMI neu berechnen</Button>
                          </div>
                        )}
                        <div className="mt-20">
                          {currentAnalyzeIdentifier === 'bmi' ? (
                            <Button buttonStyle="white" onClick={() => addNewValueBtn()}>
                              Wert manuell eintragen
                            </Button>
                          ) : (
                            <Button onClick={() => addNewValueBtn()}>Neuen Wert eintragen</Button>
                          )}
                        </div>
                      </div>
                      <div className="pt-30 pb-10 font-bold text-16">Verlauf</div>
                      {currentHistoryDataObject.map((item: any, index: number) => (
                        <div
                          className="rounded-3xl h-full mb-20 p-20 bg-lightGray w-full desktop:w-1/2 relative"
                          key={index}
                        >
                          <div className="flex justify-between">
                            <div>{item.name}</div>
                            <div className="flex space-x-20">
                              <div className="font-extralight pr-20">
                                {item.value?.toString().replaceAll('.', ',')}{' '}
                                {currentAnalyzeType !== 'BMI' && currentAnalyzePiece.toLocaleLowerCase()}
                              </div>
                              <PencilIcon
                                onClick={() => openEditPopup(item.date, item.value, item.name)}
                                width={25}
                                height={25}
                                className="text-accentColor cursor-pointer"
                              />
                              <TrashIcon
                                onClick={() => deleteItem(item.date)}
                                width={25}
                                height={25}
                                className="text-accentColor cursor-pointer"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <div className="h-full w-full md:flex block">
            <div className={styles.loading}>
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            </div>
          </div>
        )}
      </TransitionContainer>
    </>
  );
};

export default Analyze;

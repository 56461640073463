import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CompanyProfile from 'pages/Company';
import CompanyBilling from 'pages/Company/components/CompanyBilling';
import EditCompanyProfile from 'pages/Company/components/EditCompanyProfile';
import EditCompanyTheme from 'pages/Company/components/EditCompanyTheme';
import CompanyPayment from 'pages/Company/components/CompanyPayment';
import CustomerBilling from 'pages/Company/components/CustomerBilling';

const CompanyRoutes: React.FC = () => (
  <Switch>
    <Route exact path="/company" component={CompanyProfile} />
    <Route exact path="/company/profile" component={EditCompanyProfile} />
    <Route exact path="/company/design" component={EditCompanyTheme} />
    <Route exact path="/company/billing" component={CompanyBilling} />
    <Route exact path="/company/payment" component={CompanyPayment} />
    <Route exact path="/company/customer-billing" component={CustomerBilling} />
  </Switch>
);

export default CompanyRoutes;

import React, { useContext, useEffect } from 'react';
import firebase from 'services/firebase';
import { toast } from 'react-toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Headline from 'components/Headline';
import { AuthContext } from 'providers/AuthProvider';
import EditCompanyThemeForm from './components/EditCompanyThemeForm';

import styles from './styles.module.scss';

type Props = {};

const EditCompanyTheme: React.FC<Props> = () => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { tenant, theme, userData } = useContext(AuthContext);
  const db = firebase.firestore();
  const history = useHistory();

  const onSubmit = async (updatedTheme: TenantTheme | undefined) => {
    try {
      const newTheme = updatedTheme ?? theme;
      await db.collection('tenants').doc(tenant).update({
        theme: newTheme,
      });
      toast.success('Das Design wurde erfolgreich gespeichert!');
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.warn(error.message);
    }
  };

  const onReset = async () => {
    try {
      await db.collection('tenants').doc(tenant).update({
        theme: null,
      });
      toast.success('Das Design wurde erfolgreich zurückgesetzt!');
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.warn(error.message);
    }
  };

  useEffect(() => {
    // delete field firstLogin from userProfile
    if (userData?.firstLogin) {
      db.collection(`tenants/${tenant}/users`).doc(authContext.user?.uid).update({
        firstLogin: false,
      });
    }
    // eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('mainContainer')?.scrollTo(0, 0);
    }, 1);
  }, []);

  return (
    <>
      <div className={styles.header}>
        <Headline level={1} displayBackBtn goBack={() => history.push('/company')}>
          {t('Design')}
        </Headline>
      </div>
      <p>Auf dieser Seite können Sie das Design von Ihrer App anpassen.</p>
      <p>
        Die folgenden Einstellungen wirken sich auf das Gesamtdesign Deiner App aus und sind sofort nach dem Speichern
        sichtbar!
      </p>
      <div className={styles.content}>
        <EditCompanyThemeForm onSubmit={onSubmit} onReset={onReset} />
      </div>
    </>
  );
};

export default EditCompanyTheme;

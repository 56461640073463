import React, { useState, useRef, useContext, useEffect, useCallback, useMemo } from 'react';
import {
  TrashIcon,
  DotsVerticalIcon,
  XIcon,
  DocumentDuplicateIcon,
  RefreshIcon,
  CogIcon,
  SwitchHorizontalIcon,
  PencilIcon,
} from '@heroicons/react/outline';
import CustomUserInput from 'components/CustomUserInput';
import { MainContext } from 'providers/MainProvider';
import _ from 'lodash';
import { AuthContext } from 'providers/AuthProvider';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { handleOpenClosePopups, scrollToElement, savePlanChangesToFirebase } from 'shared/functions/global';
import { useHistory } from 'react-router-dom';
import { intolerancesOption, formOfNutrition } from 'shared/constants/profile-wizard';
import { toast } from 'react-toast';
import { defaultTextColor } from 'layouts/Theme';
import Button from 'components/Button';
import styles from './styles.module.scss';

type Props = {
  itemObject?: any;
  type?: string;
  planState?: any;
  planStateValue?: any;
  dayId?: number;
  mealType?: any;
};

const EditManualItem: React.FC<Props> = ({ itemObject, type, planState, planStateValue, dayId, mealType }) => {
  const { userData, tenant, theme } = useContext(AuthContext);
  const authContext = useContext(AuthContext);

  const [isLoadingKcal, setIsLoadingKcal] = useState(false);
  const [isLoadingCarbohydrates, setIsLoadingCarbohydrates] = useState(false);
  const [isLoadingProtein, setIsLoadingProtein] = useState(false);
  const [isLoadingFat, setIsLoadingFat] = useState(false);

  const [currentTypedKcalAmount, setCurrentTypedKcalAmount] = useState(itemObject?.kcal_total);
  const [currentTypedCarbohydratesAmount, setCurrentTypedCarbohydratesAmount] = useState(
    itemObject?.carbohydrates_total
  );
  const [currentTypedProteinAmount, setCurrentTypedProteinAmount] = useState(itemObject?.protein_total);
  const [currentTypedFatAmount, setCurrentTypedFatAmount] = useState(itemObject?.fat_total);

  const editKcalRef = useRef<HTMLInputElement>(null);
  const editCarbohydratesRef = useRef<HTMLInputElement>(null);
  const editProteinRef = useRef<HTMLInputElement>(null);
  const editFatRef = useRef<HTMLInputElement>(null);

  const deleteItem = () => {
    const newColumns = {
      ...planStateValue,
      dayEntries: planStateValue?.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== dayId) return entries;
        return {
          ...entries,
          kcal_total: entries.kcal_total - itemObject.kcal_total < 0 ? 0 : entries.kcal_total - itemObject.kcal_total,
          carbohydrates_total:
            entries.carbohydrates_total - itemObject.carbohydrates_total < 0
              ? 0
              : entries.carbohydrates_total - itemObject.carbohydrates_total,
          protein_total:
            entries.protein_total - itemObject.protein_total < 0 ? 0 : entries.protein_total - itemObject.protein_total,
          fat_total: entries.fat_total - itemObject.fat_total < 0 ? 0 : entries.fat_total - itemObject.fat_total,
          [mealType as string]: {
            ...entries[mealType as string],
            kcal_total:
              entries[mealType as string].kcal_total - itemObject.kcal_total < 0
                ? 0
                : entries[mealType as string].kcal_total - itemObject.kcal_total,
            carbohydrates_total:
              entries[mealType as string].carbohydrates_total - itemObject.carbohydrates_total < 0
                ? 0
                : entries[mealType as string].carbohydrates_total - itemObject.carbohydrates_total,
            protein_total:
              entries[mealType as string].protein_total - itemObject.protein_total < 0
                ? 0
                : entries[mealType as string].protein_total - itemObject.protein_total,
            fat_total:
              entries[mealType as string].fat_total - itemObject.fat_total < 0
                ? 0
                : entries[mealType as string].fat_total - itemObject.fat_total,
            manualItems: [
              ...entries[mealType as string].manualItems.filter(
                (item: any) => parseFloat(item.id) !== parseFloat(itemObject.id)
              ),
            ],
          },
        };
      }),
    };

    savePlanChangesToFirebase(userData, newColumns, newColumns.uid, authContext.user?.uid, false, planState, tenant);
    // planState(newColumns);
  };

  const clickKcal = () => {
    if (editKcalRef.current) {
      editKcalRef.current.select();
    }
  };

  const clickCarbohydrates = () => {
    if (editCarbohydratesRef.current) {
      editCarbohydratesRef.current.select();
    }
  };

  const clickProtein = () => {
    if (editProteinRef.current) {
      editProteinRef.current.select();
    }
  };

  const clickFat = () => {
    if (editFatRef.current) {
      editFatRef.current.select();
    }
  };

  function changeAmountValue(event: any, updateType: string) {
    if (updateType === 'kcal_total') {
      setCurrentTypedKcalAmount(event.target.value);
      setIsLoadingCarbohydrates(true);
      setIsLoadingProtein(true);
      setIsLoadingFat(true);
    } else if (updateType === 'carbohydrates_total') {
      setCurrentTypedCarbohydratesAmount(event.target.value);
      setIsLoadingKcal(true);
      setIsLoadingProtein(true);
      setIsLoadingFat(true);
    } else if (updateType === 'protein_total') {
      setCurrentTypedProteinAmount(event.target.value);
      setIsLoadingKcal(true);
      setIsLoadingCarbohydrates(true);
      setIsLoadingFat(true);
    } else if (updateType === 'fat_total') {
      setCurrentTypedFatAmount(event.target.value);
      setIsLoadingKcal(true);
      setIsLoadingCarbohydrates(true);
      setIsLoadingProtein(true);
    }

    debouncedChangeHandler(event, updateType);
  }

  const changeHandler = (event: any, changeType: string) => {
    editAmount(event, type, itemObject?.piece, changeType);
  };

  const debouncedChangeHandler = useMemo(() => _.debounce(changeHandler, 1000), [planStateValue]);

  const editAmount = (event: any, updateType: string | undefined, thisRecipePiece: string, changedType: string) => {
    const thisNewValue = parseFloat(event.target.value);

    const newColumns = {
      ...planStateValue,
      dayEntries: planStateValue?.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== dayId) return entries;
        return {
          ...entries,
          [changedType]: entries[changedType] - itemObject[changedType] + thisNewValue,
          [mealType as string]: {
            ...entries[mealType as string],
            [changedType]: entries[mealType as string][changedType] - itemObject[changedType] + thisNewValue,
            manualItems: entries[mealType].manualItems.map((item: any) => {
              if (parseFloat(item.id) !== parseFloat(itemObject.id)) return item;
              return {
                ...item,
                [changedType]: thisNewValue,
              };
            }),
          },
        };
      }),
    };

    savePlanChangesToFirebase(userData, newColumns, newColumns.uid, authContext.user?.uid, false, planState, tenant);
    // planState(newColumns);
    setIsLoadingKcal(false);
    setIsLoadingCarbohydrates(false);
    setIsLoadingProtein(false);
    setIsLoadingFat(false);
  };

  return (
    <>
      <div className="rounded-2xl bg-lightGray bg-opacity-10 py-20 px-20 mb-20 border-transparent border-2 hover:border-accentColor">
        <div className="flex justify-between">
          <div>
            <div className="font-semibold text-17 pr-20 pb-20">
              <div className="flex">
                <div className="rounded-3xl bg-calories py-1 mb-1 px-10 font-bold text-10 text-textColor">Manuell</div>
              </div>
              <div className="pt-1">{itemObject?.description}</div>
            </div>
          </div>

          <div className="my-auto">
            <div className="grid grid-cols-2 md:grid-cols-5 gap-10 pt-10 justify-end">
              <div>
                {isLoadingKcal ? (
                  <ReactLoading
                    type="bars"
                    width={20}
                    height={20}
                    color={theme?.textColor ?? defaultTextColor}
                    className="mx-auto"
                  />
                ) : (
                  <CustomUserInput
                    lightBackground
                    thisValue={currentTypedKcalAmount.length > 0 ? currentTypedKcalAmount : itemObject?.kcal_total}
                    name="kcal"
                    thisRef={editKcalRef}
                    onClick={clickKcal}
                    width={
                      currentTypedKcalAmount.length > 0
                        ? currentTypedKcalAmount.length
                        : itemObject?.kcal_total.toString().length
                    }
                    onChange={e => changeAmountValue(e, 'kcal_total')}
                  />
                )}

                <div className="font-extralight pt-1 text-8 text-center">KCAL</div>
              </div>
              <div>
                {isLoadingCarbohydrates ? (
                  <ReactLoading
                    type="bars"
                    width={20}
                    height={20}
                    color={theme?.textColor ?? defaultTextColor}
                    className="mx-auto"
                  />
                ) : (
                  <CustomUserInput
                    lightBackground
                    thisValue={
                      currentTypedCarbohydratesAmount.length > 0
                        ? currentTypedCarbohydratesAmount
                        : itemObject?.carbohydrates_total
                    }
                    name="carbohydrates"
                    thisRef={editCarbohydratesRef}
                    onClick={clickCarbohydrates}
                    width={
                      currentTypedCarbohydratesAmount.length > 0
                        ? currentTypedCarbohydratesAmount.length
                        : itemObject?.carbohydrates_total.toString().length
                    }
                    onChange={e => changeAmountValue(e, 'carbohydrates_total')}
                  />
                )}

                <div className="font-extralight pt-1 text-8 text-center">KH</div>
              </div>
              <div>
                {isLoadingProtein ? (
                  <ReactLoading
                    type="bars"
                    width={20}
                    height={20}
                    color={theme?.textColor ?? defaultTextColor}
                    className="mx-auto"
                  />
                ) : (
                  <CustomUserInput
                    lightBackground
                    thisValue={
                      currentTypedProteinAmount.length > 0 ? currentTypedProteinAmount : itemObject?.protein_total
                    }
                    name="protein"
                    thisRef={editProteinRef}
                    onClick={clickProtein}
                    width={
                      currentTypedProteinAmount.length > 0
                        ? currentTypedProteinAmount.length
                        : itemObject?.protein_total.toString().length
                    }
                    onChange={e => changeAmountValue(e, 'protein_total')}
                  />
                )}
                <div className="font-extralight pt-1 text-8 text-center">EW</div>
              </div>
              <div>
                {isLoadingFat ? (
                  <ReactLoading
                    type="bars"
                    width={20}
                    height={20}
                    color={theme?.textColor ?? defaultTextColor}
                    className="mx-auto"
                  />
                ) : (
                  <CustomUserInput
                    lightBackground
                    thisValue={currentTypedFatAmount.length > 0 ? currentTypedFatAmount : itemObject?.fat_total}
                    name="fat"
                    thisRef={editFatRef}
                    onClick={clickFat}
                    width={
                      currentTypedFatAmount.length > 0
                        ? currentTypedFatAmount.length
                        : itemObject?.fat_total.toString().length
                    }
                    onChange={e => changeAmountValue(e, 'fat_total')}
                  />
                )}

                <div className="font-extralight pt-1 text-8 text-center">FETT</div>
              </div>
              <div className="pt-1">
                <div className="hidden md:block ml-1 justify-end z-10">
                  <TrashIcon
                    width={23}
                    height={23}
                    className="text-accentColor cursor-pointer"
                    onClick={() => deleteItem()}
                  />
                </div>
              </div>
            </div>
            <div className="md:hidden">
              <Button className="text-12 py-5" onClick={() => deleteItem()}>
                Löschen
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditManualItem;

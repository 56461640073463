import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import CustomInput from 'components/Input/custom-input';
import { AuthContext } from 'providers/AuthProvider';
import Headline from 'components/Headline';
import CustomSelect from 'components/CustomSelect';
import Checkbox from 'components/Checkbox';

export default function EditCompanyProfileForm() {
  const { t } = useTranslation();
  const { companyData, setCompanyData, tenantData, setTenantData } = useContext(AuthContext);

  const companyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyData((prev: CompanyInfo) => ({ ...prev, companyName: event.target.value }));
  };

  const steetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyData((prev: CompanyInfo) => ({ ...prev, street: event.target.value }));
  };

  const streetNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyData((prev: CompanyInfo) => ({ ...prev, streetNumber: event.target.value }));
  };

  const zipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyData((prev: CompanyInfo) => ({ ...prev, zip: event.target.value }));
  };

  const cityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyData((prev: CompanyInfo) => ({ ...prev, city: event.target.value }));
  };

  const countryChange = (val: any) => {
    setCompanyData((prev: CompanyInfo) => ({ ...prev, country: val.value }));
  };

  const phoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyData((prev: CompanyInfo) => ({ ...prev, phone: event.target.value }));
  };

  const emailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyData((prev: CompanyInfo) => ({ ...prev, email: event.target.value }));
  };

  const vatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyData((prev: CompanyInfo) => ({ ...prev, vat: event.target.value }));
  };

  const websiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyData((prev: CompanyInfo) => ({ ...prev, website: event.target.value }));
  };

  const termsOfServiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTenantData((prev: TenantInfo) => ({
      ...prev,
      legal: { ...prev.legal, termsOfService: event.target.value },
    }));
  };

  const privacyPolicyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTenantData((prev: TenantInfo) => ({
      ...prev,
      legal: { ...prev.legal, privacyPolicy: event.target.value },
    }));
  };

  const imprintChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTenantData((prev: TenantInfo) => ({
      ...prev,
      legal: { ...prev.legal, imprint: event.target.value },
    }));
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  const countryOptions: any = [
    { value: 'DE', label: 'Deutschland' },
    { value: 'AT', label: 'Österreich' },
    { value: 'CH', label: 'Schweiz' },
  ];

  return (
    <div className="space-y-20 max-w-2xl">
      <Headline level={4}>{t('Angaben zum Unternehmen')}</Headline>
      <div className="flex gap-x-4">
        <CustomInput
          name="companyName"
          type="text"
          label={t('Firma')}
          defaultValue={companyData?.companyName}
          onChange={companyNameChange}
          className="w-2/3"
        />
      </div>

      <div className="flex gap-x-4">
        <CustomInput
          name="vat"
          type="text"
          label={t('Umsatzsteuer-Identnummer')}
          defaultValue={companyData?.vat}
          onChange={vatChange}
          className="w-1/3"
        />
      </div>

      <Headline level={4} className="pt-30">
        {t('Rechnungsanschrift und Kontaktdaten für den Rechnungsversand')}
      </Headline>
      <div className="flex gap-x-4">
        <CustomInput
          name="street"
          type="text"
          label={t('Straße')}
          defaultValue={companyData?.street}
          onChange={steetChange}
          className="w-2/3"
        />

        <CustomInput
          name="streetNumber"
          type="text"
          label={t('Hausnummer')}
          defaultValue={companyData?.streetNumber}
          onChange={streetNumberChange}
          className="w-1/3"
        />
      </div>

      <div className="flex gap-x-4">
        <CustomInput
          name="zip"
          type="text"
          label={t('PLZ')}
          defaultValue={companyData?.zip}
          onChange={zipChange}
          className="w-1/3"
        />

        <CustomInput
          name="city"
          type="text"
          label={t('Ort')}
          defaultValue={companyData?.city}
          onChange={cityChange}
          className="w-2/3"
        />
      </div>

      <div className="flex gap-x-4">
        <CustomSelect
          name="country"
          dropDownOption={countryOptions}
          label={t('Land')}
          isSearchable
          onChange={countryChange}
          defaultValue={countryOptions.filter((item: any) => item.value === companyData?.country)}
          className="w-200"
          labelClassName="text-sm text-textColor opacity-50 font-regular"
        />
      </div>

      <div className="flex gap-x-4">
        <CustomInput
          name="phone"
          type="text"
          label={t('Telefon')}
          defaultValue={companyData?.phone}
          onChange={phoneChange}
          className="w-1/2"
        />

        <CustomInput
          name="email"
          type="text"
          label={t('E-Mail')}
          defaultValue={companyData?.email}
          onChange={emailChange}
          className="w-1/2"
        />
      </div>

      <CustomInput
        name="website"
        type="text"
        label={t('Webseite')}
        defaultValue={companyData?.website}
        onChange={websiteChange}
      />

      <Headline level={4} className="pt-30">
        {t('Legal')}
      </Headline>

      <Checkbox
        label={t('Ich habe keine Webseite')}
        name="legal.noWebsite"
        register={{
          defaultChecked: tenantData?.legal?.noWebsite,
          onChange: (e: { target: { checked: any } }) =>
            setTenantData((prev: TenantInfo) => ({
              ...prev,
              legal: { ...prev.legal, noWebsite: e.target.checked },
            })),
        }}
      />

      {!tenantData?.legal?.noWebsite ? (
        <>
          <CustomInput
            name="legal.imprint"
            type="text"
            label={t('Impressum')}
            defaultValue={tenantData?.legal?.imprint}
            onChange={imprintChange}
          />

          <CustomInput
            name="legal.privacyPolicy"
            type="text"
            label={t('Datenschutz')}
            defaultValue={tenantData?.legal?.privacyPolicy}
            onChange={privacyPolicyChange}
          />

          <CustomInput
            name="legal.termsOfService"
            type="text"
            label={t('Nutzungsbedingungen')}
            defaultValue={tenantData?.legal?.termsOfService}
            onChange={termsOfServiceChange}
          />
        </>
      ) : (
        <>
          <label className="block text-sm font-regular text-textColor opacity-50 mb-1">{t('Impressum')}</label>
          <textarea
            defaultValue={tenantData?.legal?.imprintText ?? ''}
            className="w-full h-100 text-textColor bg-lightGray rounded-20 p-15"
            onFocus={setBodyClassForMobileSafari}
            onBlur={removeBodyClassForMobileSafari}
            onChange={e =>
              setTenantData((prev: TenantInfo) => ({
                ...prev,
                legal: { ...prev.legal, imprintText: e.target.value },
              }))
            }
          />

          <label className="block text-sm font-regular text-textColor opacity-50 mb-1">{t('Datenschutz')}</label>
          <textarea
            defaultValue={tenantData?.legal?.privacyPolicyText ?? ''}
            className="w-full h-100 text-textColor bg-lightGray rounded-20 p-15"
            onFocus={setBodyClassForMobileSafari}
            onBlur={removeBodyClassForMobileSafari}
            onChange={e =>
              setTenantData((prev: TenantInfo) => ({
                ...prev,
                legal: { ...prev.legal, privacyPolicyText: e.target.value },
              }))
            }
          />
          <label className="block text-sm font-regular text-textColor opacity-50 mb-1">
            {t('Nutzungsbedingungen')}
          </label>
          <textarea
            defaultValue={tenantData?.legal?.termsOfUseText ?? ''}
            className="w-full h-100 text-textColor bg-lightGray rounded-20 p-15"
            onFocus={setBodyClassForMobileSafari}
            onBlur={removeBodyClassForMobileSafari}
            onChange={e =>
              setTenantData((prev: TenantInfo) => ({
                ...prev,
                legal: { ...prev.legal, termsOfUseText: e.target.value },
              }))
            }
          />
        </>
      )}
    </div>
  );
}

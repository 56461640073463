import React, { useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusIcon, TrashIcon, PencilIcon } from '@heroicons/react/solid';
import firebase from 'services/firebase';
import Headline from 'components/Headline';
import Overlay from 'components/Overlay';
import { AuthContext } from 'providers/AuthProvider';
import Button from 'components/Button';
import CustomUserInput from 'components/CustomUserInput';
import { toast } from 'react-toast';
import SettingsFunctions from './components/SettingsFunctions';
import SettingsMacros from './components/SettingsMacros';
import SettingsLinkList from './components/SettingsLinkList';
import SettingsLocations from './components/SettingsLocations';
import SettingsInvitation from './components/SettingsInvitation';
import styles from './styles.module.scss';

type Props = {};

const CompanySettings: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { tenantData, tenant, setTenantData } = useContext(AuthContext);
  const [macroChevronClass, setMacroChevronClass] = useState('text-accentColor');
  const [macroContentClass, setMacroContentClass] = useState('hidden');

  const [changesLoading, setChangesLoading] = useState(false);

  const [deleteLoading, setDeleteLoading] = useState(false);

  const [editLoading, setEditLoading] = useState(false);

  const [locationLoading, setLocationLoading] = useState(false);

  const [invitationChevronClass, setInvitationChevronClass] = useState('text-accentColor');
  const [invitationContentClass, setInvitationContentClass] = useState('hidden');

  const [linkListChevronClass, setLinkListChevronClass] = useState('text-accentColor');
  const [linkListContentClass, setLinkListContentClass] = useState('hidden');

  const [locationsChevronClass, setLocationsChevronClass] = useState('text-accentColor');
  const [locationsContentClass, setLocationsContentClass] = useState('hidden');

  const [functionsChevronClass, setFunctionsChevronClass] = useState('text-accentColor');
  const [functionsContentClass, setFunctionsContentClass] = useState('hidden');

  const [deleteLinkPopupClass, setDeleteLinkPopupClass] = useState('hidden');

  const [addNewLinkPopupClass, setAddNewLinkPopupClass] = useState('hidden');
  const [editLinkPopupClass, setEditLinkPopupClass] = useState('hidden');

  const [addNewLocationPopupClass, setAddNewLocationPopupClass] = useState('hidden');
  const [editLocationPopupClass, setEditLocationPopupClass] = useState('hidden');

  const [currentLinkSwitchPositionString, setCurrentLinkSwitchPositionString] = useState('nothing');

  const [currentEditLinkName, setCurrentEditLinkName] = useState('');
  const [currentEditLinkUrl, setCurrentEditLinkUrl] = useState('');
  const [currentEditLinkID, setCurrentEditLinkID] = useState(0);
  const [currentEditLinkSortNumber, setCurrentEditLinkSortNumber] = useState(0);

  const [currentMakroChangeCarbohydratesPercent, setCurrentMakroChangeCarbohydratesPercent] = useState<any>(
    tenantData?.settings?.makros?.carbohydrates_percent ?? '50'
  );
  const [currentMakroChangeProteinPercent, setCurrentMakroChangeProteinPercent] = useState<any>(
    tenantData?.settings?.makros?.protein_percent ?? '25'
  );
  const [currentMakroChangeFatPercent, setCurrentMakroChangeFatPercent] = useState<any>(
    tenantData?.settings?.makros?.fat_percent ?? '25'
  );

  const [currentAddLinkName, setCurrentAddLinkName] = useState('');
  const [currentAddLinkURL, setCurrentAddLinkURL] = useState('');
  const [currentAddLinkSortNumber, setCurrentAddLinkSortNumber] = useState(0);

  const [currentDeleteLinkId, setCurrentDeleteLinkId] = useState(0);

  const addNewLocationNameRef = useRef<HTMLInputElement>(null);
  const addNewLocationUrlRef = useRef<HTMLInputElement>(null);

  const [currentAddLocationName, setCurrentAddLocationName] = useState('');
  const [currentEditLocationName, setCurrentEditLocationName] = useState('');
  const [currentEditLocationID, setCurrentEditLocationID] = useState(0);
  const [currentDeleteLocationId, setCurrentDeleteLocationId] = useState(0);
  const [deleteLocationPopupClass, setDeleteLocationPopupClass] = useState('hidden');

  const [currentInvitationMailFrom, setCurrentInvitationMailFrom] = useState(
    tenantData?.settings?.invitationMail?.mailFrom ?? ''
  );
  const [currentInvitationMailSubject, setCurrentInvitationMailSubject] = useState(
    tenantData?.settings?.invitationMail?.mailSubject ?? ''
  );
  const [currentInvitationMailText, setCurrentInvitationMailText] = useState(
    tenantData?.settings?.invitationMail?.mailText ?? ''
  );
  const [currentInvitationMailName, setCurrentInvitationMailName] = useState(
    tenantData?.settings?.invitationMail?.mailName ?? ''
  );

  const addNewLinkNameRef = useRef<HTMLInputElement>(null);
  const addNewLinkUrlRef = useRef<HTMLInputElement>(null);
  const addNewLinkSortNumberRef = useRef<HTMLInputElement>(null);

  const startDeleteLinkItem = (linkId: number) => {
    setCurrentDeleteLinkId(linkId);
    setDeleteLinkPopupClass('block');
  };

  const startDeleteLocationItem = (linkId: number) => {
    setCurrentDeleteLocationId(linkId);
    setDeleteLocationPopupClass('block');
  };

  const openNewLocationsPopup = () => {
    if (addNewLocationNameRef.current) {
      addNewLocationNameRef.current.value = '';
    }

    if (addNewLocationUrlRef.current) {
      addNewLocationUrlRef.current.value = '';
    }

    setAddNewLocationPopupClass('block');
  };

  const openNewLinkPopup = () => {
    if (addNewLinkNameRef.current) {
      addNewLinkNameRef.current.value = '';
    }

    if (addNewLinkUrlRef.current) {
      addNewLinkUrlRef.current.value = '';
    }

    setAddNewLinkPopupClass('block');
  };

  const changeLinkPosition = (typeClass: any): void => {
    const newObjectTenantData = {
      ...tenantData,
      settings: {
        ...(tenantData?.settings ?? ''),
        linkListPosition: typeClass,
      },
    } as any;

    setTenantData(newObjectTenantData);
    setCurrentLinkSwitchPositionString(typeClass);
  };

  const openEditLinkPopup = (linkId: number, linkName: string, linkUrl: string, sortNumber: number) => {
    setCurrentEditLinkName(linkName);
    setCurrentEditLinkUrl(linkUrl);
    setCurrentEditLinkID(linkId);
    setCurrentEditLinkSortNumber(sortNumber);

    setEditLinkPopupClass('block');
  };

  const openEditLocationPopup = (locationId: number, locationName: string) => {
    setCurrentEditLocationName(locationName);
    setCurrentEditLocationID(locationId);

    setEditLocationPopupClass('block');
  };

  const changeLinkName = (event: any) => {
    const thisCurrentValue = event.target.value;

    setCurrentAddLinkName(thisCurrentValue);
    setCurrentEditLinkName(thisCurrentValue);
  };

  const changeLocationName = (event: any) => {
    const thisCurrentValue = event.target.value;

    setCurrentAddLocationName(thisCurrentValue);
    setCurrentEditLocationName(thisCurrentValue);
  };

  const changeLinkUrl = (event: any) => {
    const thisCurrentValue = event.target.value;

    setCurrentAddLinkURL(thisCurrentValue);
    setCurrentEditLinkUrl(thisCurrentValue);
  };

  const changeLinkSortNumber = (event: any) => {
    const thisCurrentValue = event.target.value;

    setCurrentEditLinkSortNumber(parseFloat(thisCurrentValue));
    setCurrentAddLinkSortNumber(parseFloat(thisCurrentValue));
  };

  const addNewLinkToFirebase = async () => {
    const linkId = Math.random();

    if (currentAddLinkName.length === 0 || currentAddLinkURL.length === 0) {
      toast.error('Bitte prüfen Sie Ihre Eingaben und geben Sie mindestens einen Namen und eine Adresse ein!');
    } else {
      let thisUrl = currentAddLinkURL;

      if (!currentAddLinkURL.startsWith('https://') && !currentAddLinkURL.startsWith('http://')) {
        thisUrl = `https://${currentAddLinkURL}`;
      }

      const newObject = {
        ...(tenantData?.settings ?? ''),
        linkList: [
          ...((tenantData?.settings?.linkList as []) ?? ''),
          {
            id: linkId,
            name: currentAddLinkName,
            url: thisUrl,
            sortNumber: currentAddLinkSortNumber,
          },
        ],
      };

      const newObjectTenantData = {
        ...tenantData,
        settings: {
          ...(tenantData?.settings ?? ''),
          linkList: [
            ...((tenantData?.settings?.linkList as []) ?? ''),
            {
              id: linkId,
              name: currentAddLinkName,
              url: thisUrl,
              sortNumber: currentAddLinkSortNumber,
            },
          ],
        },
      } as any;

      try {
        await firebase
          .firestore()
          .collection('tenants')
          .doc(tenant)
          .update({
            settings: { ...newObject },
          });

        toast.success('Der Link wurde erfolgreich angelegt');
        setAddNewLinkPopupClass('hidden');
        setTenantData(newObjectTenantData);
      } catch (error: any) {
        toast.error(
          'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
        );

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      }
    }
  };

  const addNewLocationToFirebase = async () => {
    const locationId = Math.random();

    setLocationLoading(true);
    if (currentAddLocationName.length === 0) {
      toast.error('Bitte prüfen Sie Ihre Eingaben und geben Sie mindestens einen Namen ein!');
    } else {
      const newObject = {
        ...(tenantData?.settings ?? ''),
        locations: [
          ...((tenantData?.settings?.locations as []) ?? ''),
          {
            id: locationId,
            name: currentAddLocationName,
          },
        ],
      };

      const newObjectTenantData = {
        ...tenantData,
        settings: {
          ...(tenantData?.settings ?? ''),
          locations: [
            ...((tenantData?.settings?.locations as []) ?? ''),
            {
              id: locationId,
              name: currentAddLocationName,
            },
          ],
        },
      } as any;

      try {
        await firebase
          .firestore()
          .collection('tenants')
          .doc(tenant)
          .update({
            settings: { ...newObject },
          });

        toast.success('Der Standort wurde erfolgreich angelegt');
        setAddNewLocationPopupClass('hidden');
        setTenantData(newObjectTenantData);
        setLocationLoading(false);
      } catch (error: any) {
        toast.error(
          'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
        );
        setLocationLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      }
    }
  };

  const changeLinkToFirebase = async () => {
    setEditLoading(true);
    if (currentEditLinkName.length === 0 || currentEditLinkUrl.length === 0) {
      toast.error('Bitte prüfen Sie Ihre Eingaben und geben Sie mindestens einen Namen und eine Adresse ein!');
    } else {
      let thisUrl = currentEditLinkUrl;

      if (!currentEditLinkUrl.startsWith('https://') && !currentEditLinkUrl.startsWith('http://')) {
        thisUrl = `https://${currentEditLinkUrl}`;
      }

      const newObject = {
        ...(tenantData?.settings ?? ''),
        linkList: tenantData?.settings?.linkList?.map((entries: any) => {
          if (parseFloat(entries.id) !== currentEditLinkID) return entries;
          return {
            ...entries,
            name: currentEditLinkName,
            url: thisUrl,
            sortNumber: currentEditLinkSortNumber,
          };
        }),
      };

      const newObjectTenantData = {
        ...tenantData,
        settings: {
          ...(tenantData?.settings ?? ''),
          linkList: tenantData?.settings?.linkList?.map((entries: any) => {
            if (parseFloat(entries.id) !== currentEditLinkID) return entries;
            return {
              ...entries,
              name: currentEditLinkName,
              url: thisUrl,
              sortNumber: currentEditLinkSortNumber,
            };
          }),
        },
      } as any;

      try {
        await firebase
          .firestore()
          .collection('tenants')
          .doc(tenant)
          .update({
            settings: { ...newObject },
          });

        toast.success('Der Link wurde erfolgreich geändert!');
        setEditLinkPopupClass('hidden');
        setTenantData(newObjectTenantData);
        setEditLoading(false);
      } catch (error: any) {
        toast.error(
          'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
        );
        setEditLoading(false);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      }
    }
  };

  const changeLocationToFirebase = async () => {
    setEditLoading(true);
    if (currentEditLocationName.length === 0) {
      toast.error('Bitte prüfen Sie Ihre Eingaben und geben Sie mindestens einen Namen ein!');
    } else {
      const newObject = {
        ...(tenantData?.settings ?? ''),
        locations: tenantData?.settings?.locations?.map((entries: any) => {
          if (parseFloat(entries.id) !== currentEditLocationID) return entries;
          return {
            ...entries,
            name: currentEditLocationName,
          };
        }),
      };

      const newObjectTenantData = {
        ...tenantData,
        settings: {
          ...(tenantData?.settings ?? ''),
          locations: tenantData?.settings?.locations?.map((entries: any) => {
            if (parseFloat(entries.id) !== currentEditLocationID) return entries;
            return {
              ...entries,
              name: currentEditLocationName,
            };
          }),
        },
      } as any;

      try {
        await firebase
          .firestore()
          .collection('tenants')
          .doc(tenant)
          .update({
            settings: { ...newObject },
          });

        toast.success('Der Standort wurde erfolgreich geändert!');
        setEditLocationPopupClass('hidden');
        setTenantData(newObjectTenantData);
        setEditLoading(false);
      } catch (error: any) {
        toast.error(
          'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
        );
        setEditLoading(false);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      }
    }
  };

  const deleteLinkToFirebase = async () => {
    setDeleteLoading(true);
    const newObject = {
      ...(tenantData?.settings ?? ''),
      linkList: [
        ...((tenantData?.settings?.linkList as []) ?? '').filter((item: any) => item.id !== currentDeleteLinkId),
      ],
    };

    const newObjectTenantData = {
      ...tenantData,
      settings: {
        ...(tenantData?.settings ?? ''),
        linkList: [
          ...((tenantData?.settings?.linkList as []) ?? '').filter((item: any) => item.id !== currentDeleteLinkId),
        ],
      },
    } as any;

    try {
      await firebase
        .firestore()
        .collection('tenants')
        .doc(tenant)
        .update({
          settings: { ...newObject },
        });

      toast.success('Der Link wurde erfolgreich gelöscht!');
      setDeleteLinkPopupClass('hidden');
      setTenantData(newObjectTenantData);
      setDeleteLoading(false);
    } catch (error: any) {
      toast.error(
        'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
      );
      setDeleteLoading(false);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    }
  };

  const deleteLocationToFirebase = async () => {
    setDeleteLoading(true);
    const newObject = {
      ...(tenantData?.settings ?? ''),
      locations: [
        ...((tenantData?.settings?.locations as []) ?? '').filter((item: any) => item.id !== currentDeleteLocationId),
      ],
    };

    const newObjectTenantData = {
      ...tenantData,
      settings: {
        ...(tenantData?.settings ?? ''),
        locations: [
          ...((tenantData?.settings?.locations as []) ?? '').filter((item: any) => item.id !== currentDeleteLocationId),
        ],
      },
    } as any;

    try {
      await firebase
        .firestore()
        .collection('tenants')
        .doc(tenant)
        .update({
          settings: { ...newObject },
        });

      toast.success('Der Standort wurde erfolgreich gelöscht!');
      setDeleteLocationPopupClass('hidden');
      setTenantData(newObjectTenantData);
      setDeleteLoading(false);
    } catch (error: any) {
      toast.error(
        'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
      );
      setDeleteLoading(false);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    }
  };

  const saveChangesToFirebase = async () => {
    setChangesLoading(true);
    if (
      tenantData?.settings === undefined &&
      currentInvitationMailFrom.length === 0 &&
      currentInvitationMailSubject.length === 0 &&
      currentInvitationMailText.length === 0 &&
      currentInvitationMailName.length === 0
    ) {
      setChangesLoading(false);
      toast.error('Es wurden keine Änderungen vorgenommen!');
    } else if (tenantData?.settings?.makros !== undefined) {
      if (
        parseFloat(currentMakroChangeCarbohydratesPercent) +
          parseFloat(currentMakroChangeProteinPercent) +
          parseFloat(currentMakroChangeFatPercent) >
        100
      ) {
        setChangesLoading(false);
        toast.error(
          'Sie haben bei der Makronährstoff-Verteilung mehr als 100% angegeben. Bitte prüfen Sie die Eingaben!'
        );
      } else if (
        parseFloat(currentMakroChangeCarbohydratesPercent) +
          parseFloat(currentMakroChangeProteinPercent) +
          parseFloat(currentMakroChangeFatPercent) <
        100
      ) {
        setChangesLoading(false);
        toast.error(
          'Sie haben bei der Makronährstoff-Verteilung weniger als 100% angegeben. Bitte prüfen Sie die Eingaben!'
        );
      } else {
        try {
          await firebase
            .firestore()
            .collection('tenants')
            .doc(tenant)
            .update({
              settings: {
                ...tenantData?.settings,
                linkListPosition:
                  currentLinkSwitchPositionString !== 'nothing'
                    ? currentLinkSwitchPositionString
                    : tenantData?.settings?.linkListPosition
                    ? tenantData?.settings?.linkListPosition
                    : 'club',
                invitationMail: {
                  mailFrom: currentInvitationMailFrom.length > 0 ? currentInvitationMailFrom : '',
                  mailSubject: currentInvitationMailSubject.length > 0 ? currentInvitationMailSubject : '',
                  mailText: currentInvitationMailText.length > 0 ? currentInvitationMailText : '',
                  mailName: currentInvitationMailName.length > 0 ? currentInvitationMailName : '',
                },
              },
            });

          const newObjectTenantData = {
            ...tenantData,
            settings: {
              ...(tenantData?.settings ?? ''),
              linkListPosition:
                currentLinkSwitchPositionString !== 'nothing'
                  ? currentLinkSwitchPositionString
                  : tenantData?.settings?.linkListPosition
                  ? tenantData?.settings?.linkListPosition
                  : 'club',
              invitationMail: {
                mailFrom: currentInvitationMailFrom.length > 0 ? currentInvitationMailFrom : '',
                mailSubject: currentInvitationMailSubject.length > 0 ? currentInvitationMailSubject : '',
                mailText: currentInvitationMailText.length > 0 ? currentInvitationMailText : '',
                mailName: currentInvitationMailName.length > 0 ? currentInvitationMailName : '',
              },
            },
          } as any;

          setTenantData(newObjectTenantData);
          setChangesLoading(false);
          toast.success('Änderungen wurden erfolgreich gespeichert!');
        } catch (error: any) {
          setChangesLoading(false);
          toast.error(
            'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
          );

          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        }
      }
    } else {
      try {
        await firebase
          .firestore()
          .collection('tenants')
          .doc(tenant)
          .update({
            settings: {
              ...tenantData?.settings,
              linkListPosition:
                currentLinkSwitchPositionString !== 'nothing'
                  ? currentLinkSwitchPositionString
                  : tenantData?.settings?.linkListPosition
                  ? tenantData?.settings?.linkListPosition
                  : 'club',
              invitationMail: {
                mailFrom: currentInvitationMailFrom.length > 0 ? currentInvitationMailFrom : '',
                mailSubject: currentInvitationMailSubject.length > 0 ? currentInvitationMailSubject : '',
                mailText: currentInvitationMailText.length > 0 ? currentInvitationMailText : '',
                mailName: currentInvitationMailName.length > 0 ? currentInvitationMailName : '',
              },
            },
          });

        const newObjectTenantData = {
          ...tenantData,
          settings: {
            ...(tenantData?.settings ?? ''),
            linkListPosition:
              currentLinkSwitchPositionString !== 'nothing'
                ? currentLinkSwitchPositionString
                : tenantData?.settings?.linkListPosition
                ? tenantData?.settings?.linkListPosition
                : 'club',
            invitationMail: {
              mailFrom: currentInvitationMailFrom.length > 0 ? currentInvitationMailFrom : '',
              mailSubject: currentInvitationMailSubject.length > 0 ? currentInvitationMailSubject : '',
              mailText: currentInvitationMailText.length > 0 ? currentInvitationMailText : '',
              mailName: currentInvitationMailName.length > 0 ? currentInvitationMailName : '',
            },
          },
        } as any;

        setTenantData(newObjectTenantData);
        setChangesLoading(false);
        toast.success('Änderungen wurden erfolgreich gespeichert!');
      } catch (error: any) {
        setChangesLoading(false);
        toast.error(
          'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
        );

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      }
    }
  };

  return (
    <>
      <Overlay
        overlayClass={deleteLinkPopupClass}
        setOverlayClass={setDeleteLinkPopupClass}
        headline="Eintrag löschen"
        icon={<TrashIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div className="pb-60">
          <div className="pt-30 font-extralight text-base">
            <div>Möchtest du diesen Link wirklich löschen?</div>
            <div className="pt-10">Diese Aktion kann nicht rückgängig gemacht werden.</div>
          </div>
          <div className="pt-30 flex justify-between flex-wrap">
            <Button isPending={deleteLoading} disabled={deleteLoading} onClick={() => deleteLinkToFirebase()}>
              Löschen
            </Button>
            <Button onClick={() => setDeleteLinkPopupClass('hidden')}>Abbrechen</Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={deleteLocationPopupClass}
        setOverlayClass={setDeleteLocationPopupClass}
        headline="Standort löschen"
        icon={<TrashIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div className="pb-60">
          <div className="pt-30 font-extralight text-base">
            <div>Möchtest du diesen Standort wirklich löschen?</div>
            <div className="pt-10">Diese Aktion kann nicht rückgängig gemacht werden.</div>
          </div>
          <div className="pt-30 flex justify-between flex-wrap">
            <Button isPending={deleteLoading} disabled={deleteLoading} onClick={() => deleteLocationToFirebase()}>
              Löschen
            </Button>
            <Button onClick={() => setDeleteLocationPopupClass('hidden')}>Abbrechen</Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={addNewLinkPopupClass}
        setOverlayClass={setAddNewLinkPopupClass}
        headline="Neuen Link erstellen"
        icon={<PlusIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div className="pb-60 pt-20">
          <div className="pt-4">
            <div className="font-light my-auto w-150">Name:</div>
            <div className="w-full pt-10">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  thisRef={addNewLinkNameRef}
                  name="name"
                  textCenter={false}
                  type="text"
                  placeHolder="Name"
                  onChange={e => changeLinkName(e)}
                />
              </div>
            </div>
          </div>
          <div className="pt-30">
            <div className="font-light my-auto w-180">Adresse (URL):</div>
            <div className="pt-10 w-full">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  thisRef={addNewLinkUrlRef}
                  name="url"
                  textCenter={false}
                  type="text"
                  placeHolder="Adresse (URL)"
                  onChange={e => changeLinkUrl(e)}
                />
              </div>
            </div>
          </div>
          <div className="pt-30">
            <div className="font-light my-auto">Nummer zur Sortierung (optional):</div>
            <div className="pt-10 w-full">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  name="sortNumber"
                  thisRef={addNewLinkSortNumberRef}
                  textCenter={false}
                  type="number"
                  placeHolder="Sortierungsnummer"
                  onChange={e => changeLinkSortNumber(e)}
                />
              </div>
            </div>
          </div>
          <div className="pt-40 w-full">
            <Button onClick={() => addNewLinkToFirebase()} className="w-full">
              Neuen Link erstellen
            </Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={addNewLocationPopupClass}
        setOverlayClass={setAddNewLocationPopupClass}
        headline="Neuen Standort erstellen"
        icon={<PlusIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div className="pb-60 pt-20">
          <div className="pt-4">
            <div className="font-light my-auto w-150">Name:</div>
            <div className="w-full pt-10">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  thisRef={addNewLocationNameRef}
                  name="name"
                  textCenter={false}
                  type="text"
                  placeHolder="Name"
                  onChange={e => changeLocationName(e)}
                />
              </div>
            </div>
          </div>
          <div className="pt-40 w-full">
            <Button
              isPending={locationLoading}
              disabled={locationLoading}
              onClick={() => addNewLocationToFirebase()}
              className="w-full"
            >
              Neuen Standort erstellen
            </Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={editLinkPopupClass}
        setOverlayClass={setEditLinkPopupClass}
        headline="Link bearbeiten"
        icon={<PencilIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div className="pb-60 pt-20">
          <div className="pt-4">
            <div className="font-light my-auto w-150">Name:</div>
            <div className="w-full pt-10">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  name="name"
                  textCenter={false}
                  thisValue={currentEditLinkName}
                  type="text"
                  placeHolder="Name"
                  onChange={e => changeLinkName(e)}
                />
              </div>
            </div>
          </div>
          <div className="pt-30">
            <div className="font-light my-auto w-180">Adresse (URL):</div>
            <div className="pt-10 w-full">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  name="url"
                  textCenter={false}
                  thisValue={currentEditLinkUrl}
                  type="text"
                  placeHolder="Adresse (URL)"
                  onChange={e => changeLinkUrl(e)}
                />
              </div>
            </div>
          </div>
          <div className="pt-30">
            <div className="font-light my-auto">Nummer zur Sortierung (optional):</div>
            <div className="pt-10 w-full">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  name="sortNumber"
                  textCenter={false}
                  thisValue={currentEditLinkSortNumber.toString()}
                  type="number"
                  placeHolder="Sortierungsnummer"
                  onChange={e => changeLinkSortNumber(e)}
                />
              </div>
            </div>
          </div>
          <div className="pt-40 w-full">
            <Button
              isPending={editLoading}
              disabled={editLoading}
              onClick={() => changeLinkToFirebase()}
              className="w-full"
            >
              Speichern
            </Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={editLocationPopupClass}
        setOverlayClass={setEditLocationPopupClass}
        headline="Standort bearbeiten"
        icon={<PencilIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div className="pb-60 pt-20">
          <div className="pt-4">
            <div className="font-light my-auto w-150">Name:</div>
            <div className="w-full pt-10">
              <div className="font-light left text-xs border-opacity-30 w-full">
                <CustomUserInput
                  name="name"
                  textCenter={false}
                  thisValue={currentEditLocationName}
                  type="text"
                  placeHolder="Name"
                  onChange={e => changeLocationName(e)}
                />
              </div>
            </div>
          </div>

          <div className="pt-40 w-full">
            <Button
              isPending={editLoading}
              disabled={editLoading}
              onClick={() => changeLocationToFirebase()}
              className="w-full"
            >
              Speichern
            </Button>
          </div>
        </div>
      </Overlay>

      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.buttons}>
            <Headline level={1}>{t('Settings')}</Headline>
            <div className="flex gap-20 my-auto pt-10">
              <div className="p-2">
                <Button isPending={changesLoading} disabled={changesLoading} onClick={() => saveChangesToFirebase()}>
                  Änderungen speichern
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div>Auf dieser Seite können Sie die Anwendung für Ihre Kunden individuell einstellen.</div>
        <SettingsFunctions
          functionsContentClass={functionsContentClass}
          functionsChevronClass={functionsChevronClass}
          setFunctionsChevronClass={setFunctionsChevronClass}
          setFunctionsContentClass={setFunctionsContentClass}
          setMacroChevronClass={setMacroChevronClass}
          setMacroContentClass={setMacroContentClass}
          setLinkListChevronClass={setLinkListChevronClass}
          setLinkListContentClass={setLinkListContentClass}
          setLocationsChevronClass={setLocationsChevronClass}
          setLocationsContentClass={setLocationsContentClass}
          setInvitationChevronClass={setInvitationChevronClass}
          setInvitationContentClass={setInvitationContentClass}
        />

        <SettingsInvitation
          invitationContentClass={invitationContentClass}
          invitationChevronClass={invitationChevronClass}
          setFunctionsChevronClass={setFunctionsChevronClass}
          setFunctionsContentClass={setFunctionsContentClass}
          setMacroChevronClass={setMacroChevronClass}
          setMacroContentClass={setMacroContentClass}
          setLinkListChevronClass={setLinkListChevronClass}
          setLinkListContentClass={setLinkListContentClass}
          setLocationsChevronClass={setLocationsChevronClass}
          setLocationsContentClass={setLocationsContentClass}
          setInvitationChevronClass={setInvitationChevronClass}
          setInvitationContentClass={setInvitationContentClass}
          setSubject={setCurrentInvitationMailSubject}
          setMailFrom={setCurrentInvitationMailFrom}
          setMailText={setCurrentInvitationMailText}
          setMailName={setCurrentInvitationMailName}
          subject={currentInvitationMailSubject}
          mailFrom={currentInvitationMailFrom}
          mailText={currentInvitationMailText}
          mailName={currentInvitationMailName}
        />

        <SettingsMacros
          macroContentClass={macroContentClass}
          macroChevronClass={macroChevronClass}
          setFunctionsChevronClass={setFunctionsChevronClass}
          setFunctionsContentClass={setFunctionsContentClass}
          setMacroChevronClass={setMacroChevronClass}
          setMacroContentClass={setMacroContentClass}
          setLinkListChevronClass={setLinkListChevronClass}
          setLinkListContentClass={setLinkListContentClass}
          setLocationsChevronClass={setLocationsChevronClass}
          setLocationsContentClass={setLocationsContentClass}
          setCurrentMakroChangeCarbohydratesPercent={setCurrentMakroChangeCarbohydratesPercent}
          setCurrentMakroChangeProteinPercent={setCurrentMakroChangeProteinPercent}
          setCurrentMakroChangeFatPercent={setCurrentMakroChangeFatPercent}
          setInvitationChevronClass={setInvitationChevronClass}
          setInvitationContentClass={setInvitationContentClass}
        />

        <SettingsLinkList
          linkListContentClass={linkListContentClass}
          linkListChevronClass={linkListChevronClass}
          setFunctionsChevronClass={setFunctionsChevronClass}
          setFunctionsContentClass={setFunctionsContentClass}
          setMacroChevronClass={setMacroChevronClass}
          setMacroContentClass={setMacroContentClass}
          setLinkListChevronClass={setLinkListChevronClass}
          setLinkListContentClass={setLinkListContentClass}
          setLocationsChevronClass={setLocationsChevronClass}
          setLocationsContentClass={setLocationsContentClass}
          changeLinkPosition={changeLinkPosition}
          openNewLinkPopup={openNewLinkPopup}
          openEditLinkPopup={openEditLinkPopup}
          startDeleteLinkItem={startDeleteLinkItem}
          setInvitationChevronClass={setInvitationChevronClass}
          setInvitationContentClass={setInvitationContentClass}
        />

        <SettingsLocations
          locationsContentClass={locationsContentClass}
          locationsChevronClass={locationsChevronClass}
          setFunctionsChevronClass={setFunctionsChevronClass}
          setFunctionsContentClass={setFunctionsContentClass}
          setMacroChevronClass={setMacroChevronClass}
          setMacroContentClass={setMacroContentClass}
          setLinkListChevronClass={setLinkListChevronClass}
          setLinkListContentClass={setLinkListContentClass}
          setLocationsChevronClass={setLocationsChevronClass}
          setLocationsContentClass={setLocationsContentClass}
          openNewLocationsPopup={openNewLocationsPopup}
          startDeleteLocationItem={startDeleteLocationItem}
          openEditLocationPopup={openEditLocationPopup}
          setInvitationChevronClass={setInvitationChevronClass}
          setInvitationContentClass={setInvitationContentClass}
        />
      </div>
    </>
  );
};

export default CompanySettings;

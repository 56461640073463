import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import PurchaseStatus from './components/status';

const PurchaseSuccessWrapper = () => {
  let stripePromise: any;
  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLICKEY ?? '');
    }
    return stripePromise;
  };

  const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

  return (
    <Elements
      stripe={getStripe()}
      options={{
        clientSecret: clientSecret!,
        appearance: {
          theme: 'night',
          labels: 'floating',
        },
        locale: 'de',
        loader: 'always',
      }}
    >
      <PurchaseStatus clientSecret={clientSecret} />
    </Elements>
  );
};

export default PurchaseSuccessWrapper;

/* eslint-disable react/no-danger */
import React, { useContext } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import LogoImg from 'assets/img/svg/logo.svg';

const Agb: React.FC = () => {
  const { tenantData, theme } = useContext(AuthContext);
  const logo = theme?.logo ?? LogoImg;

  return (
    <div className="bg-bgColor text-textColor p-40">
      <img className="w-120" width={160} height={140} src={logo} alt="Logo" />
      <div className="font-bold text-20 pt-40">Nutzungsbedingungen</div>
      {tenantData?.legal?.termsOfUseText && (
        <>
          <div
            className="pt-30"
            dangerouslySetInnerHTML={{
              __html: tenantData?.legal?.termsOfUseText.replace(/(?:\r\n|\r|\n)/g, '<br />'),
            }}
          />
        </>
      )}
    </div>
  );
};

export default Agb;

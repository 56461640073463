import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Preloader from 'components/Preloader';
import { AuthContext } from 'providers/AuthProvider';

export default function RoleRoutes({ component: RouteComponent, path, neededRole, ...rest }: any) {
  const { userData } = useContext(AuthContext);
  if (!userData) {
    return <Preloader />;
  }
  return (
    <Route
      {...rest}
      render={routeProps =>
        neededRole.includes(userData.role) ? <RouteComponent {...routeProps} /> : <Redirect to="/" />
      }
    />
  );
}

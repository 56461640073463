import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { FieldError } from 'react-hook-form';
import styles from './style.module.scss';

type Props = {
  className?: string;
  label: string;
  name: string;
  register: any;
  error?: FieldError;
  onClick?: Function;
};

const Checkbox = forwardRef(({ label, name, className = '', register = {}, error, onClick }: Props, ref) => (
  <>
    <div className={styles.wrapper}>
      <input
        id={name}
        name={name}
        type="checkbox"
        onClick={onClick}
        ref={ref}
        className={classNames(styles.checkbox, className)}
        {...register}
      />
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
    </div>
    {error ? (
      <div>
        <p className="text-xs text-red-500">{error.message}</p>
      </div>
    ) : null}
  </>
));

export default Checkbox;

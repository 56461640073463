import React, { useState, useEffect, useRef, useContext } from 'react';
import { toast } from 'react-toast';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { scrollToTop } from 'shared/functions/global';
import { CalendarIcon, AdjustmentsIcon } from '@heroicons/react/outline';
import SearchBox from 'components/SearchBox';
import { FilterContext } from 'providers/FilterProvider';
import FilterDrawerPlan from '../FilterDrawerPlan';
import PlanSection from '../PlanSection';
import RecipeStepSwitch from '../../../Nutrition/components/RecipeStepSwitch';

type Props = {
  className?: string;
  currentStep?: any;
  currentStepValue?: string;
  planState?: any;
  planStateValue?: any;
  popupContainerRef?: any;
};

const CopyPlanStep1: React.FC<Props> = ({
  currentStep,
  currentStepValue,
  planState,
  planStateValue,
  popupContainerRef,
}) => {
  const [visible, setVisible] = useState('block');
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [currentSearchQuery, setCurrentSearchQuery] = useState('');
  const [currentName, setCurrentName] = useState(planStateValue?.name);
  const [startDate, setStartDate] = useState(null);
  const [initialIntolerances, changeIncompatibilities] = useState<string[]>([]);
  const [caloriesRange, changeCaloriesRange] = useState({ min: 1000, max: 4500 });
  const [initialNutrition, changeNutrition] = useState<string[]>([]);
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const { t } = useTranslation();

  const { planSearchValue, setPlanSearchValue } = useContext(FilterContext);

  const editPlanNameRef = useRef<HTMLDivElement>(null);

  function jumpToNextStep() {
    let isValid = true;

    if (currentName === '' || currentName === undefined) {
      isValid = false;
      toast.warn(t('Select Name'));
    }

    if (isValid) {
      const newColumns = {
        ...planStateValue,
        name: currentName,
        startDate: {
          seconds: moment(startDate).subtract(-1, 'days').unix(),
        },
        endDate: {
          seconds: moment(endDate).subtract(-1, 'days').unix(),
        },
      };

      planState(newColumns);
      setVisible('hidden');
      currentStep('3');
    }
  }

  function returnToPreviousStep() {
    setVisible('hidden');
    currentStep('1');
  }

  const changeNutritionValue = (value: string[] | string) => {
    changeNutrition(value as string[]);
  };

  const changeIntolerancesValue = (value: string[] | string) => {
    changeIncompatibilities(value as string[]);
  };

  const selectCaloriesRange = ([min, max]: number[]) => {
    if (caloriesRange !== { min, max }) {
      changeCaloriesRange({ min, max });
    }
  };

  const filterOpen = () => {
    setFilterOpen(true);
  };

  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlanSearchValue(event.target.value);
  };

  const clickOnPlan = (planObject: any) => {
    planState(planObject);
    currentStep('copy2');
  };

  useEffect(() => {
    if (currentStepValue !== 'copy') {
      setVisible('hidden');
    }

    if (editPlanNameRef.current && visible === 'block') {
      editPlanNameRef.current.focus();
      scrollToTop(popupContainerRef);
    }
  }, [currentStepValue, planStateValue, visible, popupContainerRef]);

  const changeName = (event: any) => {
    const thisCurrentName = event.currentTarget.textContent;

    setCurrentName(thisCurrentName);
  };

  const CustomInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <div className="flex w-full">
          <div className="flex-1">
            <input {...props} />
          </div>
          <div className="ml-10 my-auto">
            <CalendarIcon width={25} height={25} className="text-accentColor mx-auto cursor-pointer" />
          </div>
        </div>
      </label>
    );
  };

  return (
    <>
      <div className={visible}>
        <div>
          <div className="pt-20 font-extralight text-base">
            Bitte wähle aus der unteren Liste den Plan aus, den du kopieren möchtest.
          </div>
          <div className="font-extralight text-base pt-5">
            Du kannst auch den Filter benutzen um den passenden Plan zu finden.
          </div>
        </div>
        <div className="pt-20 pb-80">
          <PlanSection type="copyPlan" copyPlanFunction={clickOnPlan} />
        </div>
      </div>
      <RecipeStepSwitch
        returnFunction={returnToPreviousStep}
        nextFunction={jumpToNextStep}
        currentStepValue="1"
        totalSteps={2}
      />
      <FilterDrawerPlan isFilterOpen={isFilterOpen} closeFilterOpen={setFilterOpen} />
    </>
  );
};

export default CopyPlanStep1;

import React, { useState, useContext } from 'react';
import { ExclamationIcon } from '@heroicons/react/outline';
import Button from 'components/Button';
import { AuthContext } from 'providers/AuthProvider';
import Checkbox from 'components/Checkbox';
import { updateIncompabilityNotice } from 'shared/functions/global';
import styles from './style.module.scss';

type Props = {
  visible: boolean;
  setVisible: Function;
};

const IncompatibilityOverlay: React.FC<Props> = ({ visible, setVisible }) => {
  const [activate, setActivate] = useState(false);

  const { userData, tenant } = useContext(AuthContext);
  const authContext = useContext(AuthContext);

  const closePopup = () => {
    if (activate) {
      updateIncompabilityNotice(userData, authContext.user?.uid, tenant);
    }

    setVisible(false);
  };

  const clickActivate = () => {
    if (activate) {
      setActivate(false);
    } else {
      setActivate(true);
    }
  };

  return (
    <>
      {visible ? (
        <div>
          <div className={styles.backgroundPopupLayer}>
            <div className={styles.overlayPopup}>
              <div className="flex justify-between pt-20 pl-20">
                <div className="flex">
                  <div className="my-auto pr-10">
                    <ExclamationIcon width={25} height={25} className="text-accentColor mx-auto cursor-pointer" />
                  </div>
                  <div className="text-xl my-auto font-light">Unverträglichkeiten prüfen</div>
                </div>
              </div>
              <div className={styles.overlayContent}>
                <div className="px-20">
                  <div className="pt-20 font-bold">Hinweis:</div>
                  <div className="pt-10 font-extralight">
                    Beim Erstellen unserer Rezepte achten wir sehr genau auf die Kennzeichnung der Unverträglichkeiten.
                  </div>
                  <div className="pt-10 font-extralight">
                    Trotz aller Prüfungen können uns auch mal Fehler passieren, daher ist die Angabe der
                    Unverträglichkeiten ohne Gewähr.
                  </div>
                  <div className="pt-10 font-extralight">
                    Bitte überprüfe vor der Zubereitung bzw. dem Verzehr nochmal die Zutatenliste auf bekannte
                    Unverträglichkeiten!
                  </div>
                  <div className="pt-20">
                    <Checkbox
                      onClick={() => clickActivate()}
                      label="Diesen Hinweis in Zukunft nicht mehr anzeigen?"
                      name="activate"
                      register="t"
                    />
                  </div>
                  <div className="w-full pt-40 flex gap-30 pb-40">
                    <Button className="w-full" onClick={() => closePopup()}>
                      Akzeptieren und schließen
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default IncompatibilityOverlay;

import React, { useContext, useState } from 'react';
import { CardElement, IbanElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';

import firebase from 'services/firebase';
import { AuthContext } from 'providers/AuthProvider';
import Button from 'components/Button';
import Headline from 'components/Headline';

type Props = {};

const PaymentInformation: React.FC<Props> = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { tenantData, tenant } = useContext(AuthContext);
  const [paymentInfoError, setPaymentInfoError] = useState<string | null>(null);
  const [type, setType] = useState<'card' | 'iban' | 'invoice'>('card');
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [setupIntent, setSetupIntent] = useState<string | null>(null);
  const db = firebase.firestore();

  const handleOnChange = ({ error }: { error: any }) => {
    if (error) {
      setPaymentInfoError(error.message);
    } else {
      setPaymentInfoError('');
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (paymentInfoError) {
      return;
    }

    setIsLoading(true);
    await db.collection('tenants').doc(tenant).update({ 'billing.paymentMethod': 'pending' });

    const ibanElement = elements?.getElement(IbanElement);
    const cardElement = elements?.getElement(CardElement);

    if (type === 'iban' && setupIntent !== null) {
      const {
        // @ts-ignore
        setupIntent: { payment_method: paymentMethod },
      } = await stripe.confirmSepaDebitSetup(setupIntent, {
        payment_method: {
          sepa_debit: ibanElement!,
          billing_details: {
            name: tenantData?.company.companyName ?? '',
            email: tenantData?.company.email ?? '',
          },
        },
      });

      if (paymentMethod) {
        await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/updatePaymentMethod`, {
          paymentMethodId: paymentMethod,
          customerId: tenantData?.customerId,
          subscriptionId: tenantData?.subscriptionId,
          tenant,
        });
        setFormIsSubmitted(true);
      }
    } else if (type === 'card') {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement!,
        billing_details: {
          name: tenantData?.company.companyName ?? '',
          email: tenantData?.company.email ?? '',
        },
      });

      if (!error) {
        await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/updatePaymentMethod`, {
          paymentMethodId: paymentMethod?.id,
          customerId: tenantData?.customerId,
          subscriptionId: tenantData?.subscriptionId,
          tenant,
        });
        setFormIsSubmitted(true);
      }
    } else {
      await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/updatePaymentMethod`, {
        customerId: tenantData?.customerId,
        paymentMethodId: 'invoice',
        subscriptionId: tenantData?.subscriptionId,
        tenant,
      });
      setFormIsSubmitted(true);
    }
    setIsLoading(false);
  };

  const handleActivateCard = () => {
    setType('card');
  };

  const handleActivateIban = async () => {
    setType('iban');
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/getSetupIntent`, {
      customerId: tenantData?.customerId,
    });
    setSetupIntent(data);
  };

  const handleActivateInvoice = () => {
    setType('invoice');
  };

  return (
    <div>
      <Headline level={2} className="mb-15">
        Zahlungsinformationen
      </Headline>
      <div className="grid grid-cols-1 tablet:grid-cols-2 gap-20">
        {tenantData?.billing?.userCount && (
          <div className="bg-lightGray p-15 rounded-20">
            <label className="block text-18 font-regular opacity-50 mb-1">Aktuelle Zahlungsmethode</label>
            {tenantData?.billing?.paymentMethod === 'pending' ? (
              <>
                <Headline level={3}>
                  Die Zahlungsmethode wird gerade aktualisiert. Bitte prüfen Sie den Status in einigen Minuten nochmal.
                </Headline>
              </>
            ) : (
              <>
                {tenantData?.billing?.paymentMethod?.sepa_debit?.last4 ? (
                  <>
                    <Headline level={3}>SEPA</Headline>
                    {tenantData?.billing?.paymentMethod?.sepa_debit?.country}...
                    {tenantData?.billing?.paymentMethod?.sepa_debit?.last4}
                  </>
                ) : tenantData?.billing?.paymentMethod?.card?.last4 ? (
                  <>
                    <Headline level={3}>Kreditkarte</Headline>
                    {tenantData?.billing?.paymentMethod?.card?.brand}
                    <br />
                    ...{tenantData?.billing?.paymentMethod?.card?.last4}
                  </>
                ) : (
                  <>
                    <Headline level={3}>Rechnung</Headline>
                  </>
                )}
              </>
            )}
          </div>
        )}
        <div className="bg-lightGray p-15 rounded-20">
          <label className="block text-18 font-regular opacity-50 mb-1">Neue Zahlungsmethode</label>
          {formIsSubmitted ? (
            <div className="bg-lightGray p-15 mb-15 rounded-20">
              <div className="text-green-500">Zahlungsinformationen wurden erfolgreich gespeichert.</div>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="flex mb-10">
                <Button
                  onClick={() => handleActivateCard()}
                  buttonStyle={type === 'card' ? 'primary' : 'white'}
                  className="mr-10"
                  type="button"
                >
                  Kreditkarte
                </Button>
                <Button
                  onClick={() => handleActivateIban()}
                  buttonStyle={type === 'iban' ? 'primary' : 'white'}
                  type="button"
                >
                  SEPA
                </Button>
                <Button
                  onClick={() => handleActivateInvoice()}
                  buttonStyle={type === 'invoice' ? 'primary' : 'white'}
                  type="button"
                >
                  Rechnung
                </Button>
              </div>
              {type === 'card' && (
                <label className="w-300 block mt-30">
                  Kreditkarte
                  <CardElement className="bg-white p-10" onChange={handleOnChange} />
                </label>
              )}
              {type === 'iban' && setupIntent && (
                <>
                  <label className="block w-300 mt-30">
                    IBAN
                    <IbanElement
                      className="bg-white p-10"
                      options={{ supportedCountries: ['SEPA'] }}
                      onChange={handleOnChange}
                    />
                  </label>
                  <div>
                    <small>
                      Durch Angabe Ihrer Zahlungsinformationen und der Bestätigung der vorliegenden Zahlung ermächtigen
                      Sie (A) Tortija GmbH & Co. KG und Stripe, unseren Zahlungsdienstleister, Ihrem Kreditinstitut
                      Anweisungen zur Belastung Ihres Kontos zu erteilen, und (B) Ihr Kreditinstitut, Ihr Konto gemäß
                      diesen Anweisungen zu belasten. Im Rahmen Ihrer Rechte haben Sie, entsprechend den
                      Vertragsbedingungen mit Ihrem Kreditinstitut, Anspruch auf eine Rückerstattung von Ihrem
                      Kreditinstitut. Eine Rückerstattung muss innerhalb von 8 Wochen ab dem Tag, an dem Ihr Konto
                      belastet wurde, geltend gemacht werden. Eine Erläuterung Ihrer Rechte können Sie von Ihrem
                      Kreditinstitut anfordern. Sie erklären sich einverstanden, Benachrichtigungen über künftige
                      Belastungen bis spätestens 2 Tage vor dem Buchungsdatum zu erhalten.
                    </small>
                  </div>
                </>
              )}
              {paymentInfoError && <div className="text-red-500">{paymentInfoError}</div>}
              <Button type="submit" disabled={!stripe} isPending={isLoading} className="mt-40">
                Speichern
              </Button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentInformation;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Gym from 'pages/Admin/Gym';
import Coupons from 'pages/Admin/Coupons';
import AddGym from 'pages/Admin/Gym/add';
import EntryCodes from 'pages/Admin/EntryCodes';
import GymStatus from 'pages/Admin/Gym/status';
import B2BOverview from 'pages/Admin/B2BOverview';
import B2COverview from 'pages/Admin/B2COverview';

const AdminRoutes: React.FC = () => (
  <Switch>
    <Route exact path="/admin/gym" component={Gym} />
    <Route exact path="/admin/gym/add" component={AddGym} />
    <Route exact path="/admin/gym/status" component={GymStatus} />
    <Route exact path="/admin/coupons" component={Coupons} />
    <Route exact path="/admin/entrycodes" component={EntryCodes} />
    <Route exact path="/admin/b2boverview" component={B2BOverview} />
    <Route exact path="/admin/b2coverview" component={B2COverview} />
  </Switch>
);

export default AdminRoutes;

/* eslint-disable react/no-danger */
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import ReactLoading from 'react-loading';
import { useForm } from 'react-hook-form';
import firebase from 'services/firebase';
import LogoImg from 'assets/img/svg/logo.svg';
import { passwordChecker } from 'services/validations';
import Input from 'components/Input';
import { useTranslation } from 'react-i18next';
import { defaultTextColor } from 'layouts/Theme';
import { toast } from 'react-toast';
import Button from 'components/Button';
import styles from './style.module.scss';

const CustomResetPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const { tenantData, theme, setTheme, setTenantData } = useContext(AuthContext);
  const logo = theme?.logo ?? LogoImg;
  const [pageLoaded, setPageLoaded] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [currentMail, setCurrentMail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const params = new URL(window.location.href).searchParams;
  const actionCode = params.get('oobCode');
  const urlTenant = params.get('tenant');

  const checkPassCode = async () => {
    let thisCustomDomain = '';
    // search for customDomain inside tenants which is equal to userDomain
    await firebase
      .firestore()
      .collection('customDomain-infos')
      .doc(urlTenant!)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.exists) {
          const tenantInfo = querySnapshot.data();
          setTheme(tenantInfo?.theme as TenantTheme);
          setTenantData(tenantInfo as TenantInfo);
          if (tenantInfo?.customDomain !== undefined) {
            thisCustomDomain = tenantInfo?.customDomain;
          }
        }
      });

    if (actionCode !== null && actionCode?.length > 0) {
      await firebase
        .auth()
        .verifyPasswordResetCode(actionCode)
        .then(email => {
          setCurrentMail(email);

          if (thisCustomDomain.length > 0 && urlTenant !== null && window.location.hostname !== thisCustomDomain) {
            window.location.href = `http://${thisCustomDomain}/password-reset?tenant=${urlTenant}&oobCode=${actionCode}`;
          } else {
            setPageLoaded(true);
          }
        })
        .catch(error => {
          setErrorMessage('Der Link ist leider ungültig. Bitte versuche es erneut!');
        });
    } else {
      setErrorMessage('Der Link ist leider ungültig. Bitte versuche es erneut!');
    }
  };

  const openSignInLink = () => {
    if (urlTenant === 'tortija') {
      window.location.href = 'https://app.tortija.de/auth/login';
    } else if (tenantData?.customDomain !== undefined) {
      window.location.href = `https://${tenantData?.customDomain}/auth/login`;
    } else {
      window.location.href = 'https://business.tortija.de/auth/login';
    }
  };

  useEffect(() => {
    checkPassCode();
  }, []);

  const handleResetPassword = async (data: any) => {
    setIsPending(true);
    if (actionCode !== null && actionCode?.length > 0) {
      // Verify the password reset code is valid.
      await firebase
        .auth()
        .verifyPasswordResetCode(actionCode)
        .then(async email => {
          await firebase
            .auth()
            .confirmPasswordReset(actionCode, data.newPassword)
            .then(resp => {
              setSuccessMessage('Dein Passwort wurde erfolgreich zurückgesetzt!');
              toast.success('Dein Passwort wurde erfolgreich zurückgesetzt!');
              setIsPending(false);
            })
            .catch(error => {
              console.log(error);
              setIsPending(false);
              toast.error(
                'Es ist leider etwas schief gelaufen1. Bitte versuche es später erneut oder kontaktiere uns!'
              );
            });
        })
        .catch(error => {
          console.log(error);
          setIsPending(false);
          toast.error('Es ist leider etwas schief gelaufen2. Bitte versuche es später erneut oder kontaktiere uns!');
        });
    } else {
      setIsPending(false);
      toast.error('Es ist leider etwas schief gelaufen3. Bitte versuche es später erneut oder kontaktiere uns!');
    }
  };

  return (
    <div>
      {successMessage.length > 0 ? (
        <div className="bg-bgColor text-textColor p-40">
          <img className="w-220 mx-auto" width={160} height={140} src={logo} alt="Logo" />
          <div className="mt-50 text-center font-bold text-25 text-textColor">{successMessage}</div>
          <div className="mt-20 text-center font-extralight text-25 text-textColor">
            Wenn du über deinen Laptop / PC arbeitest kannst du direkt auf den Button klicken und dich dort mit deinem
            neuen Passwort anmelden!
          </div>
          <div className="mt-20 text-center font-extralight text-25 text-textColor">
            Wenn du über die App arbeitest öffne diese bitte und melde dich dort mit deinem neuen Passwort an!
          </div>
          <div className="flex mt-20 flex-col items-center">
            <div className="mb-20 mt-40 w-full desktop:w-1/4">
              <Button className="w-full" onClick={() => openSignInLink()}>
                Anmelden
              </Button>
            </div>
          </div>
        </div>
      ) : errorMessage.length > 0 ? (
        <div className="bg-bgColor text-textColor p-40">
          <img className="w-220 mx-auto" width={160} height={140} src={logo} alt="Logo" />
          <div className="bg-bgColor mt-50 text-center font-bold text-25 text-textColor">{errorMessage}</div>
        </div>
      ) : pageLoaded ? (
        <div className="bg-bgColor text-textColor p-40">
          <img className="w-220 mx-auto" width={160} height={140} src={logo} alt="Logo" />
          <div className="mt-50 text-center font-bold text-25 text-textColor">Passwort zurücksetzen</div>
          <div className="text-center text-textColor font-extralight">
            für: <span className="font-bold">{currentMail}</span>
          </div>
          <div className="w-full">
            <div className="mt-50 text-center text-textColor font-extralight">Bitte vergebe ein neues Passwort!</div>
            <form className={styles.form} onSubmit={handleSubmit(handleResetPassword)}>
              <div className="flex flex-col items-center">
                <Input
                  name="newPassword"
                  className="mb-20 mt-10 w-full desktop:w-1/4"
                  type="password"
                  label="Neues Passwort"
                  required
                  register={register('newPassword', {
                    minLength: {
                      value: 8,
                      message: 'Gebe mindestens 1 Großbuchstabe, 1 Zahl und 8 Zeichen ein.',
                    },
                    required: t('Please enter your password').toString(),
                    validate: (value: string) =>
                      passwordChecker(value) || t('Password must contain at least 1 number').toString(),
                  })}
                  error={errors.password}
                />
                <div className="mb-20 mt-40 w-full desktop:w-1/4">
                  <Button isPending={isPending} className="w-full">
                    Speichern
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="h-full w-full md:flex block">
          <div className={styles.loading}>
            <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomResetPasswordPage;

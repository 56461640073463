import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import axios from 'axios';
import firebase from 'services/firebase';
import { AuthContext } from 'providers/AuthProvider';
import { useHistory } from 'react-router-dom';

import Headline from 'components/Headline';
import PaymentInformation from './PaymentInformation';

import styles from './styles.module.scss';

type Props = {};

let stripePromise: any;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLICKEY || '');
  }
  return stripePromise;
};

const CompanyPayment: React.FC<Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { tenant, setTenantData } = useContext(AuthContext);
  const [loadingInvoices, setLoadingInvoices] = useState(true);

  const updateTenant = async () => {
    const tenantInfo = (await firebase.firestore().collection(`tenants`).doc(tenant).get()).data();
    setTenantData(tenantInfo as TenantInfo);
    setLoadingInvoices(false);
  };

  useEffect(() => {
    const fetchInvoices = () => {
      const data = {
        tenant,
      };

      axios({
        url: `${process.env.REACT_APP_API_URL ?? ''}/fetchTenantInvoicesFromStripe`,
        method: 'post',
        data,
        headers: {
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      }).then(response => {
        updateTenant();
      });
    };

    fetchInvoices();

    setTimeout(() => {
      document.getElementById('mainContainer')?.scrollTo(0, 0);
    }, 1);
  }, []);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.buttons}>
          <Headline level={1} displayBackBtn goBack={() => history.push('/company')}>
            {t('Zahlungsmethode ändern')}
          </Headline>
        </div>
      </div>
      <p className="mb-50">
        Auf dieser Seite können Sie Ihre aktuelle Zahlungsmethode einsehen und diese auch bei Bedarf anpassen. Wenn Sie
        die Zahlungsmethode anpassen möchten wird die neue Methode bei dem nächsten Abrechnungszeitraum angewendet.
      </p>
      {loadingInvoices ? (
        <div>Die aktuellen Zahlungsinformationen werden geladen...</div>
      ) : (
        <div className={styles.content}>
          <Elements
            stripe={getStripe()}
            options={{
              appearance: {
                theme: 'night',
                labels: 'floating',
              },
              locale: 'de',
              loader: 'always',
            }}
          >
            <PaymentInformation />
          </Elements>
        </div>
      )}
    </>
  );
};

export default CompanyPayment;

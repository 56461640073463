import React, { Fragment, useContext, useState } from 'react';
import classNames from 'classnames';
import QRCode from 'react-qr-code';
import { utils, writeFile } from 'xlsx';
import moment from 'moment';
import { CheckIcon, XIcon, ChevronDownIcon, ChevronUpIcon, PencilIcon } from '@heroicons/react/outline';
import Select from 'react-select';
import Loading from 'react-loading';
import Button from 'components/Button';
import firebase from 'services/firebase';
import { Link } from 'react-router-dom';
import Skeleton from 'components/skeleton';
import axios from 'axios';
import { toast } from 'react-toast';
import { ROLE_COMPANY_ADMIN, ROLE_LOCATION_ADMIN, ROLE_TRAINER, companyRoleOptions } from 'shared/constants/global';
import { AuthContext } from 'providers/AuthProvider';
import { defaultTextColor } from 'layouts/Theme';

import styles from './style.module.scss';

type Props = {
  header: string[];
  body: TableDataType[][];
  className?: string;
  isSkeleton?: boolean;
  sortFunction?: Function;
  customHeader?: any;
  stickyHeader?: boolean;
  notOverflow?: boolean;
};

const downloadCodesXls = async (parentUid: string) => {
  const downloadArray = [] as any;

  const data = await firebase.firestore().collection('entryCodes').where('parentUid', '==', parentUid).get();
  const parentData = await firebase.firestore().collection('entryCodes').where('uid', '==', parentUid).get();

  data.docs.map(item => {
    downloadArray.push({ Code: item.data().code });
    return [];
  });

  const getCompanyName = parentData.docs[0].data().companyName;

  const customHeader1 = ['Firma:', getCompanyName];
  const customHeader2 = ['E-Mail:', parentData.docs[0].data().email];
  const customHeader3 = ['Erstellungsdatum:', moment.unix(parentData.docs[0].data().date).utc().format('DD.MM.YYYY')];
  const customHeader4 = ['Laufzeit (Tage):', parentData.docs[0].data().durationDays];
  const customHeader5 = ['Menge:', parentData.docs[0].data().amount];
  const customHeaderSpace = ['', '', '', ''];
  const customHeaderCode = ['Codes:', '', '', ''];

  const worksheet = utils.json_to_sheet([]);

  utils.sheet_add_aoa(worksheet, [
    customHeader1,
    customHeader2,
    customHeader3,
    customHeader4,
    customHeader5,
    customHeaderSpace,
    customHeaderSpace,
    customHeaderCode,
  ]);

  utils.sheet_add_json(worksheet, downloadArray, {
    skipHeader: true,
    origin: -1,
  });

  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'Codes');
  writeFile(workbook, `${getCompanyName}-Zugangscodes.xlsx`);
};

const Table: React.FC<Props> = ({
  header,
  body,
  className,
  isSkeleton = false,
  sortFunction,
  customHeader,
  stickyHeader,
  notOverflow,
}) => {
  const { theme, companyData, tenantData, userData } = useContext(AuthContext);

  const [isPending, setIsPending] = useState(false);
  const [currentMail, setCurrentMail] = useState('');
  const [currentSortItemName, setCurrentSortItemName] = useState('status');
  const [currentSortDirection, setCurrentSortDirection] = useState('asc');

  const executeClickFunction = (executeFunction: any, parameter: string) => {
    executeFunction(parameter);
  };

  const sortByFunction = (item: any) => {
    let sortDirection = 'asc';
    setCurrentSortItemName(item.sortId);

    if (currentSortItemName === item.sortId && currentSortDirection === 'asc') {
      sortDirection = 'desc';
      setCurrentSortDirection('desc');
    } else {
      setCurrentSortDirection('asc');
    }

    if (sortFunction !== undefined) {
      sortFunction(item.sortId, sortDirection, item.isDate);
    }
  };

  const getLocationValue = (userItem: any) => {
    if (userItem.value === undefined || tenantData?.settings?.locations === undefined) {
      return 'hide';
    }

    const getValue = tenantData?.settings?.locations?.filter(item => item.id === userItem.value)[0];

    if (getValue !== undefined) {
      return getValue.name;
    }
    return '';
  };

  const resendMail = async (item: any) => {
    setIsPending(true);
    setCurrentMail(item.email);

    let invitationMailText = `Hallo ${item.fullName},\n\ndu wurdest zu der Ernährungssoftware von ${
      companyData?.companyName
    } eingeladen.\n\nBitte klicke auf den folgenden Link, um weitere Informationen zu erhalten:\n${
      window.location.hostname === 'demo.tortija.de'
        ? 'https://whitelabel.tortija.de/demo/'
        : tenantData?.invitationLink !== undefined
        ? tenantData?.invitationLink
        : 'https://whitelabel.tortija.de/einladung/'
    }\n\nViel Spaß mit unserer Ernährungssoftware!`;

    if (
      tenantData?.settings?.invitationMail?.mailText !== undefined &&
      tenantData?.settings?.invitationMail?.mailText?.length > 0
    ) {
      invitationMailText = `Hallo ${item.fullName},\n\n${tenantData?.settings?.invitationMail?.mailText}`;
    }

    await axios({
      url: `${process.env.REACT_APP_API_URL}/sendTextMail`,
      method: 'post',
      data: {
        mailSubject:
          tenantData?.settings?.invitationMail?.mailSubject !== undefined &&
          tenantData?.settings?.invitationMail?.mailSubject?.length > 0
            ? tenantData?.settings?.invitationMail?.mailSubject
            : `${companyData?.companyName} Einladung`,
        mailTo: item.email,
        mailText: invitationMailText,
        mailFrom:
          tenantData?.settings?.invitationMail?.mailFrom !== undefined &&
          tenantData?.settings?.invitationMail?.mailFrom?.length > 0
            ? tenantData?.settings?.invitationMail?.mailFrom
            : 'info@tortija.de',
        mailName:
          tenantData?.settings?.invitationMail?.mailName !== undefined &&
          tenantData?.settings?.invitationMail?.mailName?.length > 0
            ? tenantData?.settings?.invitationMail?.mailName
            : '',
      },
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    }).then(
      response => {
        console.log(response);
        toast.success('Die Einladung wurde erfolgreich verschickt!');
        setIsPending(false);
      },
      error => {
        toast.error('Es ist leider etwas schief gelaufen!');
        console.log(error);
        setIsPending(false);
      }
    );
  };

  if (isSkeleton) {
    return (
      <div className={classNames(className, notOverflow ? styles.wrapperNoOverflow : styles.wrapper)}>
        <table className={styles.table}>
          <thead className={styles.header}>
            <tr>
              {header.map((item, hIndex) => (
                <th scope="col" key={hIndex} className={styles.cell}>
                  &nbsp;
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={styles.body}>
            {body.map((row, rIndex) => (
              <tr key={rIndex} className={classNames(styles.row, { 'bg-bgColor bg-opacity-80': rIndex % 2 === 1 })}>
                {row.map((item, iIndex) => (
                  <td className={styles.cell} key={iIndex}>
                    {item.type === 'text' ? (
                      <Skeleton className={styles.skeletonText} />
                    ) : item.type === 'image' ? (
                      <Skeleton className={styles.skeletonImage} />
                    ) : item.type === 'qr' ? (
                      <Skeleton className={styles.skeletonImage} />
                    ) : null}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  if (!body) {
    return null;
  }
  return (
    <div className={classNames(className, notOverflow ? styles.wrapperNoOverflow : styles.wrapper)} id="customerTable">
      <table className={styles.table}>
        <thead className={styles.header}>
          <tr>
            {sortFunction !== undefined && customHeader !== undefined ? (
              <>
                {customHeader.map((item: any, index: number) => (
                  <th
                    scope="col"
                    key={`${item}-${index}`}
                    className={index === 0 && stickyHeader ? styles.cell2 : styles.cell}
                  >
                    {item.label.length > 0 && item.isSortable ? (
                      <div
                        className="flex space-x-5 justify-center cursor-pointer"
                        onClick={() => sortByFunction(item)}
                        aria-hidden="true"
                      >
                        <div className={currentSortItemName === item.sortId ? 'font-bold text-16' : ''}>
                          {item.label}
                        </div>
                        <div className="my-auto">
                          {currentSortItemName === item.sortId && currentSortDirection === 'asc' ? (
                            <ChevronDownIcon width={10} height={10} className="text-accentColor mx-auto" />
                          ) : (
                            <ChevronUpIcon width={10} height={10} className="text-accentColor mx-auto" />
                          )}
                        </div>
                      </div>
                    ) : item.label.length > 0 && !item.isSortable ? (
                      <div>{item.label}</div>
                    ) : (
                      <div />
                    )}
                  </th>
                ))}
              </>
            ) : (
              <>
                {header.map((item, index) => (
                  <th scope="col" key={`${item}-${index}`} className={styles.cell}>
                    {item}
                  </th>
                ))}
              </>
            )}
          </tr>
        </thead>
        <tbody className={styles.body}>
          {body.map((row, rIndex) => (
            <tr key={rIndex} className={classNames(styles.row, { 'bg-bgColor bg-opacity-80': rIndex % 2 === 1 })}>
              {row.map((item, iIndex) => (
                <td className={iIndex === 0 && stickyHeader ? styles.cell2 : styles.cell} key={`${rIndex}-${iIndex}`}>
                  {item.type === 'stripeConnect' ? (
                    <div>
                      <Link
                        to={{
                          pathname: `https://dashboard.stripe.com/${
                            tenantData?.stripeConnect?.stripeConnectAccountId ?? ''
                          }/subscriptions/${item?.currentUser?.membership?.id}`,
                        }}
                        target="_blank"
                      >
                        <div className="bg-accentColor text-buttonTextColor py-5 px-10 rounded-3xl text-12 font-extralight mb-5 cursor-pointer">
                          Tortija Billing
                        </div>
                      </Link>
                      <div>
                        <Select
                          name={`trainer-${iIndex}`}
                          options={
                            companyRoleOptions.map((roleItem: any) => ({
                              value: roleItem?.value,
                              label: roleItem?.label,
                            })) ?? []
                          }
                          unstyled
                          className="react-select-container min-w-full"
                          classNamePrefix="react-select"
                          isSearchable
                          onChange={(val: any) => item?.onChange({ roleId: val?.value, user: item?.currentUser })}
                          key={`${item?.value}-${iIndex}`}
                          value={
                            companyRoleOptions
                              ?.filter((roleSubItem: any) => roleSubItem?.label === item?.value)
                              .map((roleSubItemb: any) => ({
                                value: roleSubItemb?.value,
                                label: roleSubItemb?.label,
                              }))[0]
                          }
                        />
                      </div>
                    </div>
                  ) : item.type === 'nameWithAccountDisable' ? (
                    <div>
                      <div>{item.value}</div>
                      <div
                        onClick={() => executeClickFunction(item.clickFunction, item?.currentUser)}
                        aria-hidden="true"
                        className="mt-5 bg-accentColor text-buttonTextColor py-1 px-10 rounded-3xl text-12 font-extralight mb-5 cursor-pointer"
                      >
                        Account sperren
                      </div>
                    </div>
                  ) : item.type === 'nameWithAccountReactivate' ? (
                    <div>
                      <div className="text-blackSemiDark">{item.value}</div>
                      <div
                        onClick={() => executeClickFunction(item.clickFunction, item?.currentUser)}
                        aria-hidden="true"
                        className="mt-5 bg-secondaryBgColor text-notActiveButtonTextColor py-1 px-10 rounded-3xl text-12 font-extralight mb-5 cursor-pointer"
                      >
                        Account reaktivieren
                      </div>
                    </div>
                  ) : item.type === 'text' ? (
                    item.value
                  ) : item.type === 'text-disabled' ? (
                    <div>
                      <div className="text-blackSemiDark">{item.value}</div>
                    </div>
                  ) : item.type === 'roleChange' ? (
                    <Select
                      name={`trainer-${iIndex}`}
                      options={
                        companyRoleOptions.map((roleItem: any) => ({
                          value: roleItem?.value,
                          label: roleItem?.label,
                        })) ?? []
                      }
                      unstyled
                      className="react-select-container min-w-full"
                      classNamePrefix="react-select"
                      isSearchable
                      onChange={(val: any) => item?.onChange({ roleId: val?.value, user: item?.currentUser })}
                      key={`${item?.value}-${iIndex}`}
                      value={
                        companyRoleOptions
                          ?.filter((roleSubItem: any) => roleSubItem?.label === item?.value)
                          .map((roleSubItemb: any) => ({
                            value: roleSubItemb?.value,
                            label: roleSubItemb?.label,
                          }))[0]
                      }
                    />
                  ) : item.type === 'trainer' ? (
                    <>
                      {item.currentUser &&
                        item.currentUser?.role !== ROLE_COMPANY_ADMIN &&
                        item.currentUser?.role !== ROLE_TRAINER &&
                        item.currentUser?.role !== ROLE_LOCATION_ADMIN &&
                        item.currentUser?.isAdminUser !== true &&
                        item.currentUser?.isLocationAdminUser !== true &&
                        item.currentUser?.isTrainerUser !== true && (
                          <>
                            {item.currentUser?.trainer?.newTrainer ? (
                              <Loading
                                type="bars"
                                width={20}
                                height={20}
                                color={theme?.textColor ?? defaultTextColor}
                              />
                            ) : (
                              <Select
                                name={`trainer-${iIndex}`}
                                options={
                                  item?.trainer?.map((trainerItem: any) => ({
                                    value: trainerItem?.uid,
                                    label: trainerItem?.fullName,
                                  })) ?? []
                                }
                                unstyled
                                className="react-select-container min-w-full"
                                classNamePrefix="react-select"
                                isSearchable
                                onChange={(val: any) =>
                                  item?.onChange({ trainer: val?.value, user: item?.currentUser })
                                }
                                key={`${item?.value}-${iIndex}`}
                                value={
                                  item?.trainer
                                    ?.filter((trainera: any) => trainera?.fullName?.trim() === item?.value?.trim())
                                    .map((trainerb: any) => ({
                                      value: trainerb?.uid,
                                      label: trainerb?.fullName,
                                    }))[0]
                                }
                              />
                            )}
                          </>
                        )}
                    </>
                  ) : item.type === 'location' ? (
                    <>
                      {item.currentUser?.isAdminUser ? (
                        <></>
                      ) : userData?.role === ROLE_TRAINER &&
                        item.currentUser?.uid !== firebase.auth().currentUser?.uid &&
                        (item.currentUser?.role === ROLE_TRAINER || item.currentUser?.isTrainerUser) ? (
                        <div>{getLocationValue(item)}</div>
                      ) : item.currentUser && item.currentUser?.role !== ROLE_COMPANY_ADMIN ? (
                        <Select
                          name={`location-${iIndex}`}
                          options={
                            tenantData?.settings?.locations?.map((locationItem: any) => ({
                              value: locationItem?.id,
                              label: locationItem?.name,
                            })) ?? []
                          }
                          unstyled
                          className="react-select-container min-w-full"
                          classNamePrefix="react-select"
                          isSearchable
                          onChange={(val: any) =>
                            item?.onChange({ locationNumber: val?.value, user: item?.currentUser })
                          }
                          key={`${item?.value}-${iIndex}`}
                          value={
                            tenantData?.settings?.locations
                              ?.filter((locationa: any) => locationa?.id === item?.value)
                              .map((locationb: any) => ({
                                value: locationb?.id,
                                label: locationb?.name,
                              }))[0]
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : item.type === 'image' ? (
                    <img src={item?.value?.toString()} alt="" height={100} width={100} className={styles.image} />
                  ) : item.type === 'qr' ? (
                    <div className={styles.qrcode}>
                      {item?.value?.toString() && <QRCode value={item?.value?.toString()} level="M" size={150} />}
                    </div>
                  ) : item.type === 'entryCode' ? (
                    <div>
                      <Button className="text-12 py-2" onClick={() => downloadCodesXls(item.value.toString())}>
                        Download Codes
                      </Button>
                    </div>
                  ) : item.type === 'boolean' ? (
                    <div>
                      {item.value === 'true' ? (
                        <CheckIcon width={25} height={25} className="text-accentColor mx-auto" />
                      ) : (
                        <XIcon width={25} height={25} className="text-accentColor mx-auto" />
                      )}
                    </div>
                  ) : item.type === 'actionLoading' ? (
                    <div>
                      <Loading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
                    </div>
                  ) : item.type === 'deleteByDate' ? (
                    <div>
                      {item.actions?.map((action, aIndex) => (
                        <div key={aIndex}>
                          {action.disabled ? (
                            <div>
                              <div className="text-14">Lösch Datum:</div>
                              <div className="text-12 flex gap-5 justify-center">
                                <div>{moment.unix(item?.value?.deleteDate).format('DD.MM.YYYY')}</div>
                              </div>
                            </div>
                          ) : (
                            <div
                              onClick={() => action.onClick({ user: item?.value })}
                              aria-hidden="true"
                              className="cursor-pointer"
                            >
                              <div className="text-14">Lösch Datum:</div>
                              <div className="text-12 flex gap-5 justify-center">
                                <div>{moment.unix(item?.value?.deleteDate).format('DD.MM.YYYY')}</div>
                                <div className="my-auto">
                                  <PencilIcon width={15} height={15} className="text-accentColor mx-auto" />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}{' '}
                    </div>
                  ) : item.type === 'actionResendMail' ? (
                    <>
                      {isPending && item.value.email === currentMail ? (
                        <Loading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
                      ) : (
                        <div className="cursor-pointer" onClick={() => resendMail(item.value)} aria-hidden="true">
                          <div className="font-extralight text-12 underline">Einladung</div>
                          <div className="font-extralight text-12 underline">erneut senden</div>
                        </div>
                      )}
                    </>
                  ) : item.type === 'action' ? (
                    <div>
                      {item.actions?.map((action, aIndex) => (
                        <Fragment key={aIndex}>
                          {!action.disabled && (
                            <button type="button" onClick={() => action.onClick({ user: item?.value })}>
                              <action.icon width={25} height={25} className="text-accentColor mx-auto" />
                            </button>
                          )}
                        </Fragment>
                      ))}{' '}
                    </div>
                  ) : null}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

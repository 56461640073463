import React, { useContext, useState, useEffect } from 'react';
import firebase from 'services/firebase';
import { toast } from 'react-toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Headline from 'components/Headline';
import Button from 'components/Button';
import { AuthContext } from 'providers/AuthProvider';
import EditCompanyProfileForm from './components/EditCompanyProfileForm';

import styles from './styles.module.scss';

type Props = {};

const EditCompanyProfile: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { tenant, companyData, tenantData } = useContext(AuthContext);
  const db = firebase.firestore();
  const [isUpdating, setIsUpdating] = useState(false);
  const history = useHistory();

  const onSubmit = async () => {
    setIsUpdating(true);
    try {
      await db.collection('tenants').doc(tenant).update({
        company: companyData,
        legal: tenantData?.legal,
      });
      toast.success('Dein Profil wurde erfolgreich gespeichert!');
      setIsUpdating(false);
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.warn(error.message);
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('mainContainer')?.scrollTo(0, 0);
    }, 1);
  }, []);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.buttons}>
          <Headline level={1} displayBackBtn goBack={() => history.push('/company')}>
            {t('Unternehmensdaten')}
          </Headline>
          <Button
            isPending={isUpdating}
            disabled={isUpdating}
            buttonStyle={isUpdating ? 'dark' : 'primary'}
            onClick={onSubmit}
            className="w-200"
          >
            {t('Save')}
          </Button>
        </div>
      </div>
      <p className="mb-50">
        Auf dieser Seite können Sie die persönlichen Einstellungen der Firma bearbeiten und die aktuell gespeicherten
        Informationen einsehen.
      </p>
      <div className={styles.content}>
        <EditCompanyProfileForm />
      </div>
    </>
  );
};

export default EditCompanyProfile;

import React, { useContext, useMemo, useState } from 'react';
import SwitchSelector from 'react-switch-selector';
import { CheckIcon, TrashIcon } from '@heroicons/react/solid';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import moment from 'moment';
import firebase from 'services/firebase';
import Button from 'components/Button';
import Overlay from 'components/Overlay';
import axios from 'axios';
import ButtonBack from 'components/ButtonBack';
import Headline from 'components/Headline';
import Icon from 'components/Icon';
import { checkCouponValidFirebase, remainTrialDay, getIsWebView } from 'shared/functions/global';
import { AuthContext } from 'providers/AuthProvider';
import { monthlyPlanList, yearlyPlanList } from 'shared/constants/global';

import styles from './styles.module.scss';

export default function PurchasePlan() {
  const { userData } = useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();

  const db = firebase.firestore();

  const authContext = useContext(AuthContext);

  const durationOption = [
    {
      label: t('Monthly'),
      value: 'monthly',
    },
    {
      label: t('Yearly'),
      value: 'yearly',
    },
  ];

  const [currentSection, setCurrentSection] = useState<any>(durationOption[0].value);
  const planList = useMemo(
    () => (currentSection === durationOption[0].value ? monthlyPlanList[0] : yearlyPlanList[0]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSection]
  );

  const [deleteProfilePopupClass, setDeleteProfilePopupClass] = useState('hidden');

  const [couponCode, setCouponCode] = useState('');
  const [isCheckPending, setIsCheckPending] = useState(false);
  const [couponRabatt, setCouponRabatt] = useState(0);
  const [isCouponCodeValid, setIsCouponCodeValid] = useState(false);
  const [couponErrorText, setCouponErrorText] = useState('hidden');
  const [couponPrice, setCouponPrice] = useState(0);

  if (userData?.membership && (userData?.membership.type === 'pro' || userData?.membership.type === 'pro12')) {
    return <Redirect to="/" />;
  }

  const remainDay = remainTrialDay(userData?.created?.seconds);

  const useIAP = getIsWebView();

  const tenant = 'tortija';

  const logOutUser = async () => {
    try {
      await firebase.auth().signOut();
      window.location.reload();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const changeCouponCode = (event: any) => {
    setCouponCode(event.target.value);
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  const deleteUserAccount = async () => {
    try {
      const profile: UserInfo = authContext.userData as UserInfo;

      if (userData?.membership?.type === 'pro' || userData?.membership?.type === 'pro12') {
        if (userData?.membership?.paymentMethod === 'paypal') {
          await axios.post(
            `${process.env.REACT_APP_PAYPAL_SUBSCRIPTION_API ?? ''}/${userData?.membership?.id ?? ''}/cancel`,
            {
              reason: 'Not satisfied with the service',
            },
            {
              auth: {
                username: process.env.REACT_APP_PAYPAL_CLIENTID ?? '',
                password: process.env.REACT_APP_PAYPAL_SECRET ?? '',
              },
            }
          );
        } else {
          await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/cancel-subscription`, {
            subscriptionId: userData?.membership?.id,
          });
        }
      }

      const deleteMailText = `Name:${userData?.fullName}\n Email: ${userData?.email}\n Datum:${moment().format(
        'DD.MM.YYYY'
      )}\n Abo: ${userData?.membership?.type}`;

      // Send delete mail to us
      try {
        await axios({
          url: `${process.env.REACT_APP_API_URL}/sendTextMail`,
          method: 'post',
          data: {
            mailSubject: 'Usersaccount gelöscht',
            mailTo: 'torben@tortija.de',
            mailText: deleteMailText,
          },
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        }).then(
          response => {
            console.log(response);
          },
          error => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }

      await db
        .collection(`tenants/${tenant}/users`)
        .doc(authContext.user?.uid)
        .update({
          ...profile,
          toDelete: true,
        });
      toast.success('Dein Profil wurde erfolgreich gelöscht!');
      logOutUser();
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.warn(error.message);
    }
  };

  const checkCouponCode = async () => {
    setIsCheckPending(true);
    const checkCode = await checkCouponValidFirebase(couponCode);

    setCouponRabatt(checkCode);

    if (checkCode === 10) {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setCouponPrice(89);
    } else if (checkCode === 20) {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setCouponPrice(79);
    } else if (checkCode === 30) {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setCouponPrice(69);
    } else if (couponCode === 'T50TJ') {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setCouponPrice(49);
      setCouponRabatt(50);
    } else if (couponCode === 'hazel') {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setCouponPrice(49);
      setCouponRabatt(50);
    } else if (couponCode === 'TT30J') {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setCouponPrice(69);
      setCouponRabatt(30);
    } else if (couponCode === '40TTJ') {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setCouponPrice(59);
      setCouponRabatt(40);
    } else if (couponCode === 'TTJ20') {
      setCouponErrorText('hidden');
      setIsCouponCodeValid(true);
      setCouponPrice(79);
      setCouponRabatt(20);
    } else {
      setCouponErrorText('block');
    }

    setIsCheckPending(false);
  };

  return (
    <>
      <Overlay
        overlayClass={deleteProfilePopupClass}
        setOverlayClass={setDeleteProfilePopupClass}
        headline="Account löschen"
        icon={<TrashIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div>Wollen Sie ihr Profil wirklich löschen?</div>
            <div>Alle gespeicherten Daten werden dabei gelöscht!</div>
          </div>
          {userData?.membership?.stripeCustomerId !== undefined && (
            <div className="pt-10">
              Sie werden an eine Seite weitergeleitet, wo Sie Ihren Account kündigen können. Nach Ablauf Ihres
              Abonnement-Zeitraums wird Ihr Account automatisch gelöscht!
            </div>
          )}
          <div className="pt-30 flex gap-30 pb-40">
            <div>
              <Button className="w-full" onClick={() => deleteUserAccount()}>
                Account löschen
              </Button>
            </div>
            <div>
              <Button className="w-full" onClick={() => setDeleteProfilePopupClass('hidden')}>
                Abbrechen
              </Button>
            </div>
          </div>
        </div>
      </Overlay>

      <div className="bg-black min-h-screen">
        <div className="pt-12 sm:pt-16 lg:pt-20">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
            {userData?.withCouponCode === undefined || userData?.withCouponCode === false ? (
              <>
                <div className="pb-10">
                  <Link
                    to={{
                      pathname: '/',
                    }}
                  >
                    {' '}
                    <ButtonBack
                      text="Zurück zum Dashboard"
                      className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                    />
                  </Link>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="text-center">
              <Icon name="logo" width={200} className="mx-auto mb-30" />
              <Headline className={styles.header}>{t('Pricing Plans')}</Headline>
              {remainDay < 0 && userData?.membership?.type === 'free' && userData?.role === 2 && (
                <p className="mt-4 text-xl text-textColor">Dein Testzeitraum ist abgelaufen!</p>
              )}
              <p className="mt-4 text-xl text-textColor">{t('TrialEnded')}</p>
            </div>
          </div>
        </div>
        <div className="mt-8 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
          <div className="relative">
            <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-lg overflow-hidden rounded-3xl bg-lightGray shadow-lg lg:flex lg:max-w-none">
                <div className="flex-1 px-6 py-8 lg:p-12">
                  <h3 className="text-2xl font-bold text-textColor sm:text-3xl sm:tracking-tight">{planList.name}</h3>
                  <p className="mt-6 text-base text-textColor font-extralight">{planList.description}</p>
                  <div className="mt-8">
                    <div className="flex items-center">
                      <h4 className="flex-shrink-0 bg-lightGray pr-4 text-base font-semibold text-gray-600 pl-10">
                        {t("What's included")}
                      </h4>
                      <div className="flex-1 border-t-2 border-gray-200" />
                    </div>
                    <div className="font-extralight text-base pt-30">
                      Du hast bei der monatlichen und jährlichen Zahlungsweise jeweils Zugriff auf die vollen Funktionen
                      von Tortija.
                    </div>
                    <div className="font-extralight text-base pt-10">
                      Bei der jährlichen Zahlungsweise sparst du dir <span className="font-semibold"> 2 Monate.</span>
                    </div>
                    {userData?.withCouponCode &&
                      remainDay > 0 &&
                      userData?.membership?.type === 'free' &&
                      userData?.role === 2 && (
                        <div className="pt-30">
                          <Link
                            to={{
                              pathname: useIAP ? '/' : '/store-landingpage',
                            }}
                          >
                            <Button buttonStyle="text" className="text-13">
                              Ich möchte Tortija erstmal 7 Tage kostenlos testen!
                            </Button>
                          </Link>
                        </div>
                      )}
                  </div>
                </div>
                <div className="py-8 px-6 text-center lg:flex lg:flex-shrink-0 lg:flex-col lg:justify-center lg:p-12 md:w-500 w-350 mx-auto">
                  <p className="text-xl font-medium tracking-normal text-textColor mb-2">{t('Billing')}</p>
                  <div className={styles.switchWrapper}>
                    <SwitchSelector
                      onChange={setCurrentSection}
                      options={durationOption}
                      initialSelectedIndex={0}
                      fontSize={20}
                      selectionIndicatorMargin={0}
                      backgroundColor="black"
                      selectedFontColor="white"
                      fontColor="white"
                    />
                  </div>
                  <div className="mt-4 flex items-center justify-center text-5xl font-bold tracking-tight text-textColor">
                    {couponPrice > 0 && currentSection === 'yearly' ? (
                      <>
                        <span className={styles.strikethrough}>{t(planList.price)}€</span>{' '}
                        <span className="pl-20">{couponPrice}</span>
                      </>
                    ) : (
                      <span className="">{t(planList.price)}</span>
                    )}

                    <span className="ml-3 text-xl font-medium tracking-normal text-textColor">€</span>
                  </div>
                  {currentSection === 'yearly' && (
                    <>
                      <div className="font-extralight text-14 pt-10">
                        {couponPrice > 0
                          ? `${(couponPrice / 12).toFixed(2)}€ pro Monat`
                          : `${planList.priceDay}€ pro Monat`}
                      </div>
                      <div className="pt-10 px-10">
                        <hr />
                      </div>
                      {!isCouponCodeValid && !useIAP && (
                        <div className="flex gap-20 justify-center w-full pt-20">
                          <div className="my-auto">
                            <input
                              className="appearance-none block w-full px-27 py-10 rounded-full text-18 placeholder-gray-400 focus:outline-none bg-lightGrayAlternative bg-opacity-20 text-textColor"
                              onChange={changeCouponCode}
                              onFocus={setBodyClassForMobileSafari}
                              onBlur={removeBodyClassForMobileSafari}
                              placeholder="Rabattcode"
                            />
                          </div>
                          <div>
                            <Button
                              onClick={() => checkCouponCode()}
                              isPending={isCheckPending}
                              disabled={isCheckPending}
                            >
                              Anwenden
                            </Button>
                          </div>
                        </div>
                      )}
                      <div className={couponErrorText}>
                        <div className="text-red-500 text-13 pt-10 text-center">
                          Dieser Rabattcode ist entweder ungültig oder bereits abgelaufen!
                        </div>
                      </div>
                      {isCouponCodeValid && (
                        <div className="flex gap-20 w-full pt-20">
                          <div className="my-auto">
                            <span className="font-semibold">Rabattcode:</span>
                            <span className="font-extralight"> {couponCode}</span> (-{couponRabatt}%)
                          </div>
                          <div>
                            <Button className="px-20 py-10 cursor-default">
                              <CheckIcon width={30} height={30} className="text-textColor" />
                            </Button>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  <div className="mt-4">
                    <Link
                      to={`/purchase/payment/${planList.planType}?coupon=${couponCode.length > 0 ? couponCode : 0}`}
                    >
                      <Button buttonStyle="primary" className="w-full mt-8">
                        Weiter zum nächsten Schritt
                      </Button>
                    </Link>
                  </div>
                  <div className="mt-4 text-sm">
                    <div className="flex w-full mb-10">
                      <div className="flex-1 my-auto mx-20">
                        <hr className=" text-textColor my-auto" />
                      </div>
                      <div className="">oder</div>
                      <div className="flex-1 my-auto mx-20">
                        <hr />
                      </div>
                    </div>
                    <div className="flex justify-center space-x-30">
                      <button type="button" className="font-medium text-textColor" onClick={logOutUser}>
                        {t('Log out')}
                      </button>
                      <button
                        type="button"
                        className="font-medium text-textColor"
                        onClick={() => setDeleteProfilePopupClass('block')}
                      >
                        Account löschen
                      </button>
                    </div>
                  </div>
                  <div className="flex space-x-25 flex-wrap justify-center">
                    <div className="pt-20 text-12">
                      <a href="https://www.tortija.de/widerrufsrecht/" target="_blank" rel="noreferrer">
                        Widerrufsrecht
                      </a>
                    </div>
                    <div className="pt-20 text-12">
                      <a href="https://www.tortija.de/agb/" target="_blank" rel="noreferrer">
                        AGB
                      </a>
                    </div>
                    <div className="pt-20 text-12">
                      <a href="https://www.tortija.de/datenschutz/" target="_blank" rel="noreferrer">
                        Datenschutz
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

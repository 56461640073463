import React, { useEffect, useContext, useState } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import ReactGA from 'react-ga4';

const SocialMediaContainer: React.FC = () => {
  const { userData } = useContext(AuthContext);
  const [isTrackingChecked, setIsTrackingChecked] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (userData?.trackGoogleAnalytics !== undefined) {
      if (
        userData?.trackGoogleAnalytics === true &&
        !isTrackingChecked &&
        window.location.hostname === 'app.tortija.de'
      ) {
        ReactGA.initialize('G-ESNDWQ6XN3');
        ReactPixel.init('5801591503252258');
        ReactPixel.pageView();
        TiktokPixel.init('CKRNE1BC77UAIQIC34Q0');
        TiktokPixel.pageView();
      }
      setIsTrackingChecked(true);
    }
  }, [userData]);

  useEffect(() => {
    if (
      userData?.trackGoogleAnalytics !== undefined &&
      userData?.trackGoogleAnalytics === true &&
      !isTrackingChecked &&
      window.location.hostname === 'app.tortija.de'
    ) {
      ReactPixel.pageView();
      TiktokPixel.pageView();
    }
  }, [location]);

  return <></>;
};

export default SocialMediaContainer;

import React, { useContext, useMemo, useState, useRef, useEffect } from 'react';
import SwitchSelector from 'react-switch-selector';
import { useTranslation } from 'react-i18next';

import { AdjustmentsIcon, RefreshIcon, XIcon } from '@heroicons/react/outline';
import Drawer from 'components/Drawer';
import { AuthContext } from 'providers/AuthProvider';
import Headline from 'components/Headline';
import { OptionType } from 'react-switch-selector/dist/SwitchSelector';
import ButtonGroup from 'components/ButtonGroup';
import { defaultButtonTextColor, defaultTextColor } from 'layouts/Theme';
import MakroCircles from 'components/MakroCircles';
import { eatingHabitsOption, formOfNutrition, intolerancesOption } from 'shared/constants/profile-wizard';
import { MainContext } from 'providers/MainProvider';
import { FilterContext } from 'providers/FilterProvider';
import SwitchButton from 'components/SwitchButton';
import CustomSelect from 'components/CustomSelect';
import {
  difficultyGroup,
  defaultCaloriesRange,
  defaultCarbohydratesRange,
  defaultProteinRange,
  defaultFatRange,
} from 'shared/constants/filter';
import styles from './styles.module.scss';

type Props = {
  isFilterOpen?: boolean;
  closeFilterOpen: (value: boolean) => void;
  filterType: string;
  changeFilterType: (value: any) => void;
  recipesOption: OptionType[];
  clearFilter?: boolean;
  planStateDayValue?: any;
  planStateValue?: any;
};

const FilterDrawer: React.FC<Props> = ({
  isFilterOpen = false,
  closeFilterOpen,
  filterType,
  changeFilterType,
  recipesOption,
  clearFilter = false,
  planStateDayValue,
  planStateValue,
}) => {
  const { t } = useTranslation();
  const { ingredientList } = useContext(MainContext);
  const { userData, theme } = useContext(AuthContext);

  const selectIncludeRef = React.useRef() as any;
  const selectNotIncludeRef = React.useRef() as any;

  const {
    initialDifficulty,
    changeDifficulty,
    initialNutrition,
    changeNutrition,
    initialEatingHabits,
    changeEatingHabits,
    initialIntolerances,
    changeIncompatibilities,
    caloriesRange,
    changeCaloriesRange,
    carbohydratesRange,
    changeCarbohydratesRange,
    proteinRange,
    changeProteinRange,
    myFavoritesValue,
    changeMyFavoritesValue,
    myRecipesValue,
    changeMyRecipesValue,
    myCommunityRecipesValue,
    changeMyCommunityRecipesValue,
    fatRange,
    changeFatRange,
    ingredientsFilter,
    changeIngredient,
    ingredientsNotIncludeFilter,
    changeIngredientNotInclude,
  } = useContext(FilterContext);

  const [initialCaloriesMin, setInitialCaloriesMin] = useState(caloriesRange.min);
  const [initialCaloriesMax, setInitialCaloriesMax] = useState(caloriesRange.max);
  const [initialCarbohydratesMin, setInitialCarbohydratesMin] = useState(carbohydratesRange.min);
  const [initialCarbohydratesMax, setInitialCarbohydratesMax] = useState(carbohydratesRange.max);
  const [initialProteinMin, setInitialProteinMin] = useState(proteinRange.min);
  const [initialProteinMax, setInitialProteinMax] = useState(proteinRange.max);
  const [initialFatMin, setInitialFatMin] = useState(fatRange.min);
  const [initialFatMax, setInitialFatMax] = useState(fatRange.max);

  const [visibleRecipesClass, setVisibleRecipesClass] = useState('block');
  const [visibleIngredientsClass, setVisibleIngredientsClass] = useState('hidden');

  const caloriesMinRef = useRef<HTMLDivElement>(null);
  const caloriesMaxRef = useRef<HTMLDivElement>(null);
  const carbohydratesMinRef = useRef<HTMLDivElement>(null);
  const carbohydratesMaxRef = useRef<HTMLDivElement>(null);
  const proteinMinRef = useRef<HTMLDivElement>(null);
  const proteinMaxRef = useRef<HTMLDivElement>(null);
  const fatMinRef = useRef<HTMLDivElement>(null);
  const fatMaxRef = useRef<HTMLDivElement>(null);

  const ingredientsItems: OptionType[] = useMemo(
    () => ingredientList?.map(ingredient => ({ value: ingredient.name?.toLowerCase(), label: ingredient.name })) ?? [],
    [ingredientList]
  );

  const changeIngredientGroup = (value: any) => {
    changeIngredient(value.map((item: any) => item.value));
  };

  const changeIngredientNotIncludeGroup = (value: any) => {
    changeIngredientNotInclude(value.map((item: any) => item.value));
  };

  const changeFilterTypeClass = (typeClass: any): void => {
    if (typeClass === 'recipes') {
      setVisibleIngredientsClass('hidden');
      setVisibleRecipesClass('block');
    } else {
      setVisibleIngredientsClass('block');
      setVisibleRecipesClass('hidden');
    }
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  const selectCaloriesRange = (thisCaloriesValueMin: any, thisCaloriesValueMax: any) => {
    if (typeof thisCaloriesValueMax === 'number') {
      if (Number.isNaN(parseFloat(thisCaloriesValueMin.currentTarget.textContent))) {
        setInitialCaloriesMin(0);
        changeCaloriesRange({
          min: 0,
          max: thisCaloriesValueMax,
        });
      } else {
        changeCaloriesRange({
          min: parseFloat(thisCaloriesValueMin.currentTarget.textContent),
          max: thisCaloriesValueMax,
        });
      }
    }
    if (typeof thisCaloriesValueMin === 'number') {
      if (Number.isNaN(parseFloat(thisCaloriesValueMax.currentTarget.textContent))) {
        setInitialCaloriesMax(0);
        changeCaloriesRange({
          min: thisCaloriesValueMin,
          max: defaultCaloriesRange.max,
        });
      } else {
        changeCaloriesRange({
          min: thisCaloriesValueMin,
          max: parseFloat(thisCaloriesValueMax.currentTarget.textContent),
        });
      }
    }
  };

  const selectCarbohydratesRange = (thisCarbohydratesValueMin: any, thisCarbohydratesValueMax: any) => {
    if (typeof thisCarbohydratesValueMax === 'number') {
      if (Number.isNaN(parseFloat(thisCarbohydratesValueMin.currentTarget.textContent))) {
        setInitialCarbohydratesMin(0);
        changeCarbohydratesRange({
          min: 0,
          max: thisCarbohydratesValueMax,
        });
      } else {
        changeCarbohydratesRange({
          min: parseFloat(thisCarbohydratesValueMin.currentTarget.textContent),
          max: thisCarbohydratesValueMax,
        });
      }
    }
    if (typeof thisCarbohydratesValueMin === 'number') {
      if (Number.isNaN(parseFloat(thisCarbohydratesValueMax.currentTarget.textContent))) {
        setInitialCarbohydratesMax(0);
        changeCarbohydratesRange({
          min: thisCarbohydratesValueMin,
          max: defaultCarbohydratesRange.max,
        });
      } else {
        changeCarbohydratesRange({
          min: thisCarbohydratesValueMin,
          max: parseFloat(thisCarbohydratesValueMax.currentTarget.textContent),
        });
      }
    }
  };

  const selectProteinRange = (thisProteinValueMin: any, thisProteinValueMax: any) => {
    if (typeof thisProteinValueMax === 'number') {
      if (Number.isNaN(parseFloat(thisProteinValueMin.currentTarget.textContent))) {
        setInitialProteinMin(0);
        changeProteinRange({
          min: 0,
          max: thisProteinValueMax,
        });
      } else {
        changeProteinRange({
          min: parseFloat(thisProteinValueMin.currentTarget.textContent),
          max: thisProteinValueMax,
        });
      }
    }
    if (typeof thisProteinValueMin === 'number') {
      if (Number.isNaN(parseFloat(thisProteinValueMax.currentTarget.textContent))) {
        setInitialProteinMax(0);
        changeProteinRange({
          min: thisProteinValueMin,
          max: defaultProteinRange.max,
        });
      } else {
        changeProteinRange({
          min: thisProteinValueMin,
          max: parseFloat(thisProteinValueMax.currentTarget.textContent),
        });
      }
    }
  };

  const selectFatRange = (thisFatValueMin: any, thisFatValueMax: any) => {
    if (typeof thisFatValueMax === 'number') {
      if (Number.isNaN(parseFloat(thisFatValueMin.currentTarget.textContent))) {
        changeFatRange({
          min: 0,
          max: thisFatValueMax,
        });
        setInitialFatMin(0);
      } else {
        changeFatRange({
          min: parseFloat(thisFatValueMin.currentTarget.textContent),
          max: thisFatValueMax,
        });
      }
    }
    if (typeof thisFatValueMin === 'number') {
      if (Number.isNaN(parseFloat(thisFatValueMax.currentTarget.textContent))) {
        setInitialFatMax(0);
        changeFatRange({
          min: thisFatValueMin,
          max: defaultFatRange.max,
        });
      } else {
        changeFatRange({
          min: thisFatValueMin,
          max: parseFloat(thisFatValueMax.currentTarget.textContent),
        });
      }
    }
  };

  const changeDifficultyValue = (value: string[] | string) => {
    changeDifficulty(value as string[]);
  };

  const changeEatingHabitsValue = (value: string[] | string) => {
    changeEatingHabits(value as string[]);
  };

  const changeNutritionValue = (value: string[] | string) => {
    changeNutrition(value as string[]);
  };

  const changeIntolerancesValue = (value: string[] | string) => {
    changeIncompatibilities(value as string[]);
  };

  const setMyFavorites = (status: boolean, item: string): void => {
    changeMyFavoritesValue(status, item);
    // changeMyRecipesValue(false, 'Meine Rezepte');
    // changeMyCommunityRecipesValue(false, 'Community Rezepte');
  };

  const setMyRecipes = (status: boolean, item: string): void => {
    changeMyRecipesValue(status, item);
    // changeMyFavoritesValue(false, 'Meine Favoriten');
    // changeMyCommunityRecipesValue(false, 'Community Rezepte');
  };

  const setMyCommunityRecipes = (status: boolean, item: string): void => {
    changeMyCommunityRecipesValue(status, item);
    // changeMyRecipesValue(false, 'Meine Rezepte');
    // changeMyFavoritesValue(false, 'Meine Favoriten');
  };

  const clickMakroRange = (thisRef: any) => {
    if (thisRef.current) {
      thisRef.current.select();
    }
  };

  const getMakroGoalValues = (makroType: string, custom_value: number | undefined) => {
    let thisTotal = 0;

    if (custom_value) {
      thisTotal = custom_value;
    } else if (makroType === 'kcal') {
      thisTotal = Math.round(parseFloat(userData!.calories));
    } else if (makroType === 'carbohydrates') {
      thisTotal = userData!.carbohydrates_total;
    } else if (makroType === 'protein') {
      thisTotal = userData!.protein_total;
    } else {
      thisTotal = userData!.fat_total;
    }

    if (makroType === 'kcal') {
      return `${Math.round(thisTotal - thisTotal * 0.05)}-${Math.round(
        parseFloat(thisTotal.toString()) + parseFloat(thisTotal.toString()) * 0.05
      )}`;
    }
    if (makroType === 'carbohydrates') {
      return `${Math.round(thisTotal - thisTotal * 0.15)}-${Math.round(
        parseFloat(thisTotal.toString()) + parseFloat(thisTotal.toString()) * 0.15
      )}`;
    }
    if (makroType === 'protein') {
      return `${Math.round(thisTotal - thisTotal * 0.15)}-${Math.round(
        parseFloat(thisTotal.toString()) + parseFloat(thisTotal.toString()) * 0.15
      )}`;
    }
    return `${Math.round(thisTotal - thisTotal * 0.15)}-${Math.round(
      parseFloat(thisTotal.toString()) + parseFloat(thisTotal.toString()) * 0.15
    )}`;
  };

  const resetFilter = () => {
    changeDifficulty([]);
    changeIncompatibilities([]);
    changeNutrition([]);
    changeEatingHabits([]);
    changeMyFavoritesValue(false, 'Meine Favoriten');
    changeMyRecipesValue(false, 'Meine Rezepte');
    changeMyCommunityRecipesValue(false, 'Community Rezepte');

    if (selectIncludeRef.current) {
      selectIncludeRef.current.clearValue();
    }
    if (selectNotIncludeRef.current) {
      selectNotIncludeRef.current.clearValue();
    }

    if (caloriesMinRef.current) {
      caloriesMinRef.current.innerHTML = '';
    }
    if (caloriesMaxRef.current) {
      caloriesMaxRef.current.innerHTML = '';
    }
    if (carbohydratesMinRef.current) {
      carbohydratesMinRef.current.innerHTML = '';
    }
    if (carbohydratesMaxRef.current) {
      carbohydratesMaxRef.current.innerHTML = '';
    }
    if (proteinMinRef.current) {
      proteinMinRef.current.innerHTML = '';
    }
    if (proteinMaxRef.current) {
      proteinMaxRef.current.innerHTML = '';
    }
    if (fatMinRef.current) {
      fatMinRef.current.innerHTML = '';
    }
    if (fatMaxRef.current) {
      fatMaxRef.current.innerHTML = '';
    }

    changeCaloriesRange({
      min: defaultCaloriesRange.min,
      max: defaultCaloriesRange.max,
    });
    changeCarbohydratesRange({
      min: defaultCarbohydratesRange.min,
      max: defaultCarbohydratesRange.max,
    });
    changeProteinRange({
      min: defaultProteinRange.min,
      max: defaultProteinRange.max,
    });
    changeFatRange({
      min: defaultFatRange.min,
      max: defaultFatRange.max,
    });
  };

  useEffect(() => {
    if (clearFilter) {
      resetFilter();
    }
  }, [clearFilter]);

  return (
    <Drawer isOpen={isFilterOpen} closeDrawer={closeFilterOpen}>
      <div className="relative flex justify-between">
        <div className="absolute right-10 top-10">
          <XIcon
            onClick={() => closeFilterOpen(false)}
            width={25}
            height={25}
            className="text-accentColor mx-auto cursor-pointer"
          />
        </div>
        {planStateValue !== undefined && (
          <>
            <div className="hidden md:block h-400 sticky top-0 mr-20">
              <div className="font-bold text-20 pb-30">Plan Statistik</div>
              <MakroCircles
                kcal_value={parseInt(planStateDayValue?.kcal_total.toString(), 10)}
                carbohydrates_value={parseInt(planStateDayValue?.carbohydrates_total.toString(), 10)}
                protein_value={parseInt(planStateDayValue?.protein_total.toString(), 10)}
                fat_value={parseInt(planStateDayValue?.fat_total.toString(), 10)}
                custom_kcal_value={
                  planStateDayValue?.custom_kcal_total
                    ? planStateDayValue?.custom_kcal_total
                    : planStateValue?.custom_kcal_total
                    ? planStateValue?.custom_kcal_total
                    : undefined
                }
                custom_carbohydrates_value={
                  planStateDayValue?.custom_carbohydrates_total
                    ? planStateDayValue?.custom_carbohydrates_total
                    : planStateValue?.custom_carbohydrates_total
                    ? planStateValue?.custom_carbohydrates_total
                    : undefined
                }
                custom_protein_value={
                  planStateDayValue?.custom_protein_total
                    ? planStateDayValue?.custom_protein_total
                    : planStateValue?.custom_protein_total
                    ? planStateValue?.custom_protein_total
                    : undefined
                }
                custom_fat_value={
                  planStateDayValue?.custom_fat_total
                    ? planStateDayValue?.custom_fat_total
                    : planStateValue?.custom_fat_total
                    ? planStateValue?.custom_fat_total
                    : undefined
                }
              />
            </div>
          </>
        )}
        <div>
          {planStateDayValue !== undefined ? (
            <>
              <div className="md:hidden pt-80" />
              <div className={styles.mobileMakros}>
                <div className="flex-1 text-center">
                  <div className="font-semibold text-base">{Math.round(parseFloat(planStateDayValue?.kcal_total))}</div>
                  <div className={styles.recipeLabel}>kcal</div>
                  <div className="font-extralight text-12 text-center pt-5">Ziel:</div>
                  <div className="font-extralight text-12 text-center">
                    {getMakroGoalValues(
                      'kcal',
                      planStateDayValue?.custom_kcal_total
                        ? planStateDayValue?.custom_kcal_total
                        : planStateValue?.custom_kcal_total
                        ? planStateValue?.custom_kcal_total
                        : undefined
                    )}
                  </div>
                </div>
                <div className="flex-1 text-center">
                  <div className="font-semibold text-base">{planStateDayValue?.carbohydrates_total}g</div>
                  <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                  <div className="font-extralight text-12 text-center pt-5">Ziel:</div>
                  <div className="font-extralight text-12 text-center">
                    {getMakroGoalValues(
                      'carbohydrates',
                      planStateDayValue?.custom_carbohydrates_total
                        ? planStateDayValue?.custom_carbohydrates_total
                        : planStateValue?.custom_carbohydrates_total
                        ? planStateValue?.custom_carbohydrates_total
                        : undefined
                    )}
                  </div>
                </div>
                <div className="flex-1 text-center">
                  <div className="font-semibold text-base">{planStateDayValue?.protein_total}g</div>
                  <div className={styles.recipeLabel}>{t('Protein')}</div>
                  <div className="font-extralight text-12 text-center pt-5">Ziel:</div>
                  <div className="font-extralight text-12 text-center">
                    {getMakroGoalValues(
                      'protein',
                      planStateDayValue?.custom_protein_total
                        ? planStateDayValue?.custom_protein_total
                        : planStateValue?.custom_protein_total
                        ? planStateValue?.custom_protein_total
                        : undefined
                    )}
                  </div>
                </div>
                <div className="flex-1 text-center">
                  <div className="font-semibold text-base">{planStateDayValue?.fat_total}g</div>
                  <div className={styles.recipeLabel}>{t('Fat')}</div>
                  <div className="font-extralight text-12 text-center pt-5">Ziel:</div>
                  <div className="font-extralight text-12 text-center">
                    {getMakroGoalValues(
                      'fat',
                      planStateDayValue?.custom_fat_total
                        ? planStateDayValue?.custom_fat_total
                        : planStateValue?.custom_fat_total
                        ? planStateValue?.custom_fat_total
                        : undefined
                    )}
                  </div>
                </div>
              </div>
              <div className="pt-40 tablet:pt-0">
                <div className={styles.header}>
                  <AdjustmentsIcon width={28} height={28} className={styles.filterIcon} />
                  <Headline level={3}>{t('Filter')}</Headline>
                </div>
              </div>
            </>
          ) : (
            <div className={styles.header}>
              <AdjustmentsIcon width={28} height={28} className={styles.filterIcon} />
              <Headline level={3}>{t('Filter')}</Headline>
            </div>
          )}
          <div
            className="pb-20 flex gap-5 flex-wrap cursor-pointer"
            onClick={() => resetFilter()}
            onMouseDown={() => resetFilter()}
            aria-hidden="true"
          >
            <div className="my-auto">
              <RefreshIcon width={20} height={20} className="text-accentColor mx-auto cursor-pointer" />
            </div>
            <div className="hover:underline">Alles zurücksetzen</div>
          </div>
          <div className="h-auto">
            <SwitchSelector
              onChange={changeFilterTypeClass}
              options={recipesOption}
              initialSelectedIndex={0}
              fontSize={20}
              selectionIndicatorMargin={0}
              selectedFontColor={theme?.buttonTextColor ?? defaultButtonTextColor}
              fontColor={theme?.textColor ?? defaultTextColor}
            />
          </div>

          <div className={visibleRecipesClass}>
            <div className={styles.wrapper}>
              <SwitchButton label="Meine Favoriten" enabled={myFavoritesValue} onChange={setMyFavorites} notContainer />
              <SwitchButton label="Meine Rezepte" enabled={myRecipesValue} onChange={setMyRecipes} notContainer />
              {userData?.role === 5 && (
                <SwitchButton
                  label="Community Rezepte"
                  enabled={myCommunityRecipesValue}
                  onChange={setMyCommunityRecipes}
                  notContainer
                />
              )}

              <div>
                <div className="my-auto text-18 font-semibold">Kalorien</div>
                <div className="flex gap-50 flex-wrap justify-between pt-5">
                  <div className="flex-1">
                    <div className="font-extralight pb-1">Von:</div>
                    <div className="w-full font-light left text-xs border-opacity-30">
                      <span
                        className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                        role="textbox"
                        contentEditable
                        onFocus={setBodyClassForMobileSafari}
                        onBlur={removeBodyClassForMobileSafari}
                        suppressContentEditableWarning
                        onInput={e => selectCaloriesRange(e, caloriesRange.max)}
                        ref={caloriesMinRef}
                      >
                        {initialCaloriesMin === 0 ? '' : initialCaloriesMin}
                      </span>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="font-extralight pb-1">Bis:</div>
                    <div className="font-light left text-xs border-opacity-30 w-full">
                      <span
                        className="appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                        role="textbox"
                        contentEditable
                        onFocus={setBodyClassForMobileSafari}
                        onBlur={removeBodyClassForMobileSafari}
                        suppressContentEditableWarning
                        onInput={e => selectCaloriesRange(caloriesRange.min, e)}
                        ref={caloriesMaxRef}
                      >
                        {initialCaloriesMax === 3000 ? '' : initialCaloriesMax}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div className="my-auto text-18 font-semibold">Kohlenhydrate</div>
                <div className="flex gap-50 flex-wrap justify-between pt-5">
                  <div className="flex-1">
                    <div className="font-extralight pb-1">Von:</div>
                    <div className="w-full font-light left text-xs border-opacity-30">
                      <span
                        className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                        role="textbox"
                        contentEditable
                        onFocus={setBodyClassForMobileSafari}
                        onBlur={removeBodyClassForMobileSafari}
                        suppressContentEditableWarning
                        onInput={e => selectCarbohydratesRange(e, carbohydratesRange.max)}
                        ref={carbohydratesMinRef}
                      >
                        {initialCarbohydratesMin === 0 ? '' : initialCarbohydratesMin}
                      </span>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="font-extralight pb-1">Bis:</div>
                    <div className="font-light left text-xs border-opacity-30 w-full">
                      <span
                        className="appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                        role="textbox"
                        contentEditable
                        onFocus={setBodyClassForMobileSafari}
                        onBlur={removeBodyClassForMobileSafari}
                        suppressContentEditableWarning
                        onInput={e => selectCarbohydratesRange(carbohydratesRange.min, e)}
                        ref={carbohydratesMaxRef}
                      >
                        {initialCarbohydratesMax === 500 ? '' : initialCarbohydratesMax}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div className="my-auto text-18 font-semibold">Eiweiß</div>
                <div className="flex gap-50 flex-wrap justify-between pt-5">
                  <div className="flex-1">
                    <div className="font-extralight pb-1">Von:</div>
                    <div className="w-full font-light left text-xs border-opacity-30">
                      <span
                        className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                        role="textbox"
                        contentEditable
                        onFocus={setBodyClassForMobileSafari}
                        onBlur={removeBodyClassForMobileSafari}
                        suppressContentEditableWarning
                        onInput={e => selectProteinRange(e, proteinRange.max)}
                        ref={proteinMinRef}
                      >
                        {initialProteinMin === 0 ? '' : initialProteinMin}
                      </span>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="font-extralight pb-1">Bis:</div>
                    <div className="font-light left text-xs border-opacity-30 w-full">
                      <span
                        className="appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                        role="textbox"
                        contentEditable
                        onFocus={setBodyClassForMobileSafari}
                        onBlur={removeBodyClassForMobileSafari}
                        suppressContentEditableWarning
                        onInput={e => selectProteinRange(proteinRange.min, e)}
                        ref={proteinMaxRef}
                      >
                        {initialProteinMax === 150 ? '' : initialProteinMax}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div className="my-auto text-18 font-semibold">Fett</div>
                <div className="flex gap-50 flex-wrap justify-between pt-5">
                  <div className="flex-1">
                    <div className="font-extralight pb-1">Von:</div>
                    <div className="w-full font-light left text-xs border-opacity-30">
                      <span
                        className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                        role="textbox"
                        contentEditable
                        onFocus={setBodyClassForMobileSafari}
                        onBlur={removeBodyClassForMobileSafari}
                        suppressContentEditableWarning
                        onInput={e => selectFatRange(e, fatRange.max)}
                        ref={fatMinRef}
                      >
                        {initialFatMin === 0 ? '' : initialFatMin}
                      </span>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="font-extralight pb-1">Bis:</div>
                    <div className="font-light left text-xs border-opacity-30 w-full">
                      <span
                        className="appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                        role="textbox"
                        contentEditable
                        onFocus={setBodyClassForMobileSafari}
                        onBlur={removeBodyClassForMobileSafari}
                        suppressContentEditableWarning
                        onInput={e => selectFatRange(fatRange.min, e)}
                        ref={fatMaxRef}
                      >
                        {initialFatMax === 250 ? '' : initialFatMax}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <ButtonGroup
                options={difficultyGroup}
                initialOption={initialDifficulty}
                label="Level of difficulty"
                itemsClassName="grid-cols-3"
                changeSelectedItem={changeDifficultyValue}
              />
              <hr />
              <ButtonGroup
                options={formOfNutrition}
                initialOption={initialNutrition}
                label="Form of nutrition"
                itemsClassName="grid-cols-3"
                changeSelectedItem={changeNutritionValue}
              />
              <hr />
              <ButtonGroup
                options={eatingHabitsOption}
                initialOption={initialEatingHabits}
                label="Eating habits"
                changeSelectedItem={changeEatingHabitsValue}
                itemsClassName="grid-cols-2"
              />
              <hr />
              <ButtonGroup
                options={intolerancesOption}
                initialOption={initialIntolerances}
                label="Intolerances"
                itemsClassName="grid-cols-3"
                changeSelectedItem={changeIntolerancesValue}
              />
            </div>
          </div>
          <div className={visibleIngredientsClass}>
            <div className={styles.wrapper}>
              <div className="font-extralight">
                Hier kannst du nach Zutaten suchen (Mehrfachauswahl möglich) und der Filter sucht nach Rezepten wo diese
                Zutaten enthalten sind.
              </div>
              <CustomSelect
                label="Zutaten"
                name="goal"
                className={styles.selector}
                dropDownOption={ingredientsItems}
                isSearchable
                isMulti
                onChange={(value: any) => changeIngredientGroup(value)}
                defaultValue={ingredientsFilter?.map(item => ({ value: item.toLowerCase(), label: item }))}
                thisRef={selectIncludeRef}
                isFullSize
              />
              <div className="font-extralight pt-10">
                Hier kannst du nach Zutaten suchen (Mehrfachauswahl möglich) und der Filter sucht nach Rezepten wo diese
                Zutaten <u>nicht</u> enthalten sind.
              </div>
              <CustomSelect
                label="Zutaten"
                name="notGoal"
                className={styles.selector}
                dropDownOption={ingredientsItems}
                isSearchable
                isMulti
                onChange={(value: any) => changeIngredientNotIncludeGroup(value)}
                defaultValue={ingredientsNotIncludeFilter?.map(item => ({ value: item.toLowerCase(), label: item }))}
                thisRef={selectNotIncludeRef}
                isFullSize
              />
            </div>
          </div>
          {/* filterType === 'recipes' ? (
          <>
            <SwitchButton label="Meine Favoriten" enabled={myFavoritesValue} onChange={setMyFavorites} notContainer />
            <SwitchButton label="Meine Rezepte" enabled={myRecipesValue} onChange={setMyRecipes} notContainer />
            <div>
              <div className="my-auto text-18 font-semibold">Kalorien</div>
              <div className="flex gap-50 flex-wrap justify-between pt-5">
                <div className="flex-1">
                  <div className="font-extralight pb-1">Von:</div>
                  <div className="w-full font-light left text-xs border-opacity-30">
                    <span
                      className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightDarkGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                      role="textbox"
                      contentEditable
                      suppressContentEditableWarning
                      onInput={e => selectCaloriesRange(e, caloriesRange.max)}
                      ref={caloriesMinRef}
                    >
                      {initialCaloriesMin}
                    </span>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="font-extralight pb-1">Bis:</div>
                  <div className="font-light left text-xs border-opacity-30 w-full">
                    <span
                      className="appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightDarkGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                      role="textbox"
                      contentEditable
                      suppressContentEditableWarning
                      onInput={e => selectCaloriesRange(caloriesRange.min, e)}
                      ref={caloriesMaxRef}
                    >
                      {initialCaloriesMax}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div className="my-auto text-18 font-semibold">Kohlenhydrate</div>
              <div className="flex gap-50 flex-wrap justify-between pt-5">
                <div className="flex-1">
                  <div className="font-extralight pb-1">Von:</div>
                  <div className="w-full font-light left text-xs border-opacity-30">
                    <span
                      className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightDarkGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                      role="textbox"
                      contentEditable
                      suppressContentEditableWarning
                      onInput={e => selectCarbohydratesRange(e, carbohydratesRange.max)}
                      ref={carbohydratesMinRef}
                    >
                      {initialCarbohydratesMin}
                    </span>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="font-extralight pb-1">Bis:</div>
                  <div className="font-light left text-xs border-opacity-30 w-full">
                    <span
                      className="appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightDarkGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                      role="textbox"
                      contentEditable
                      suppressContentEditableWarning
                      onInput={e => selectCarbohydratesRange(carbohydratesRange.min, e)}
                      ref={carbohydratesMaxRef}
                    >
                      {initialCarbohydratesMax}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div className="my-auto text-18 font-semibold">Eiweiß</div>
              <div className="flex gap-50 flex-wrap justify-between pt-5">
                <div className="flex-1">
                  <div className="font-extralight pb-1">Von:</div>
                  <div className="w-full font-light left text-xs border-opacity-30">
                    <span
                      className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightDarkGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                      role="textbox"
                      contentEditable
                      suppressContentEditableWarning
                      onInput={e => selectProteinRange(e, proteinRange.max)}
                      ref={proteinMinRef}
                    >
                      {initialProteinMin}
                    </span>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="font-extralight pb-1">Bis:</div>
                  <div className="font-light left text-xs border-opacity-30 w-full">
                    <span
                      className="appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightDarkGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                      role="textbox"
                      contentEditable
                      suppressContentEditableWarning
                      onInput={e => selectProteinRange(proteinRange.min, e)}
                      ref={proteinMaxRef}
                    >
                      {initialProteinMax}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div className="my-auto text-18 font-semibold">Fett</div>
              <div className="flex gap-50 flex-wrap justify-between pt-5">
                <div className="flex-1">
                  <div className="font-extralight pb-1">Von:</div>
                  <div className="w-full font-light left text-xs border-opacity-30">
                    <span
                      className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightDarkGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                      role="textbox"
                      contentEditable
                      suppressContentEditableWarning
                      onInput={e => selectFatRange(e, fatRange.max)}
                      ref={fatMinRef}
                    >
                      {initialFatMin}
                    </span>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="font-extralight pb-1">Bis:</div>
                  <div className="font-light left text-xs border-opacity-30 w-full">
                    <span
                      className="appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightDarkGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                      role="textbox"
                      contentEditable
                      suppressContentEditableWarning
                      onInput={e => selectFatRange(fatRange.min, e)}
                      ref={fatMaxRef}
                    >
                      {initialFatMax}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <ButtonGroup
              options={difficultyGroup}
              initialOption={initialDifficulty}
              label="Level of difficulty"
              itemsClassName="grid-cols-3"
              changeSelectedItem={changeDifficultyValue}
            />
            <hr />
            <ButtonGroup
              options={formOfNutrition}
              initialOption={initialNutrition}
              label="Form of nutrition"
              itemsClassName="grid-cols-3"
              changeSelectedItem={changeNutritionValue}
            />
            <hr />
            <ButtonGroup
              options={eatingHabitsOption}
              initialOption={initialEatingHabits}
              label="Eating habits"
              changeSelectedItem={changeEatingHabitsValue}
              itemsClassName="grid-cols-2"
            />
            <hr />
            <ButtonGroup
              options={intolerancesOption}
              initialOption={initialIntolerances}
              label="Intolerances"
              itemsClassName="grid-cols-3"
              changeSelectedItem={changeIntolerancesValue}
            />
          </>
        ) : !ingredientList ? (
          <div className={styles.loading}>
            <ReactLoading type="bubbles" width={50} height={20} color="#84502B" />
          </div>
        ) : (
          <>
            <div className="font-extralight">
              Hier kannst du nach Zutaten suchen (Mehrfachauswahl möglich) und der Filter sucht nach Rezepten wo diese
              Zutaten enthalten sind.
            </div>
            <CustomSelect
              label="Zutaten"
              name="goal"
              className={styles.selector}
              dropDownOption={ingredientsItems}
              isSearchable
              isMulti
              onChange={(value: any | any) => changeIngredientGroup(value)}
              defaultValue={ingredientsFilter?.map(item => ({ value: item.toLowerCase(), label: item }))}
              thisRef={selectIncludeRef}
            />
            <div className="font-extralight pt-10">
              Hier kannst du nach Zutaten suchen (Mehrfachauswahl möglich) und der Filter sucht nach Rezepten wo diese
              Zutaten <u>nicht</u> enthalten sind.
            </div>
            <CustomSelect
              label="Zutaten"
              name="notGoal"
              className={styles.selector}
              dropDownOption={ingredientsItems}
              isSearchable
              isMulti
              onChange={(value: any | any) => changeIngredientNotIncludeGroup(value)}
              defaultValue={ingredientsNotIncludeFilter?.map(item => ({ value: item.toLowerCase(), label: item }))}
              thisRef={selectNotIncludeRef}
            />
          </>
        ) */}
        </div>
      </div>
    </Drawer>
  );
};

export default FilterDrawer;

import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { PlusIcon, AdjustmentsIcon, XIcon } from '@heroicons/react/outline';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import SearchBox from 'components/SearchBox';
import ReactLoading from 'react-loading';
import { Link, useLocation, useHistory } from 'react-router-dom';
import TransitionContainer from 'components/TransitionContainer';
import { handleOpenClosePopups, updateTourToFirebase } from 'shared/functions/global';
import { FilterContext } from 'providers/FilterProvider';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import { defaultTextColor } from 'layouts/Theme';
import FilterDrawerPlan from './components/FilterDrawerPlan';
import Headline from '../../components/Headline';
import NewPlanStep1 from './components/NewPlanStep1';
import NewPlanStep2 from './components/NewPlanStep2';
import NewPlanStep3 from './components/NewPlanStep3';
import NewPlanStep4 from './components/NewPlanStep4';
import NewPlanStep5 from './components/NewPlanStep5';
import NewPlanStep6 from './components/NewPlanStep6';
import CopyPlanStep1 from './components/CopyPlanStep1';
import CopyPlanStep2 from './components/CopyPlanStep2';
import PlanSection from './components/PlanSection';

import 'react-image-crop/dist/ReactCrop.css';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './styles.module.scss';

const Plans: React.FC = () => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { userData, tenant, theme } = useContext(AuthContext);

  const history = useHistory();

  const tourRef = useRef<HTMLDivElement>(null);
  const tourRefMobile = useRef<HTMLDivElement>(null);

  const [currentAddPlan, setCurrentAddPlan] = useState<any>({
    startDate: null,
    endDate: null,
    kcal_total: 0,
    carbohydrates_total: 0,
    dayEntries: [],
    protein_total: 0,
    fat_total: 0,
    imageUrl: '',
    ketogen: true,
    vegan: true,
    vegetarian: true,
    flexitarian: true,
    egg: false,
    fructose: false,
    histamine: false,
    lactose: false,
    nuts: false,
    peanuts: false,
    sorbitol: false,
    soy: false,
  });

  const location = useLocation<LocationState>();

  const { planSearchValue, setPlanSearchValue } = useContext(FilterContext);

  const [planLoaded, setPlanLoaded] = useState(false);

  const [isFilterOpen, setFilterOpen] = useState(false);
  const { planList, userPlanList } = useContext(MainContext);
  const [currentPlanExists, setCurrentPlanExists] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<PlanType[]>([]);
  const [createPlanPopupClass, setCreatePlanPopupClass] = useState('hidden');

  const [currentStep, setCurrentStep] = useState('1');

  const [initialPlanSection, setInitialPlanSection] = useState('');
  const [initialPlanCalories, setInitialPlanCalories] = useState('');

  const recipePopupContentRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
    },
    scrollTo: true,
    classes: 'shepherd-theme-dark',
    useModalOverlay: true,
  };

  function TourButton() {
    const tour = useContext(ShepherdTourContext);

    return (
      <div
        className="hidden"
        ref={tourRef}
        onClick={() => userData?.role === 2 && tour?.start()}
        onKeyDown={() => userData?.role === 2 && tour?.start()}
        aria-hidden="true"
      >
        <div>Test</div>
      </div>
    );
  }

  function TourButtonMobile() {
    const tourMobile = useContext(ShepherdTourContext);

    return (
      <div
        className="hidden"
        ref={tourRefMobile}
        onClick={() => userData?.role === 2 && tourMobile?.start()}
        onKeyDown={() => userData?.role === 2 && tourMobile?.start()}
        aria-hidden="true"
      >
        <div>Test</div>
      </div>
    );
  }

  const filterOpen = () => {
    setFilterOpen(true);
  };

  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlanSearchValue(event.target.value);
  };

  const checkCurrentPlanExists = () => {
    userPlanList
      ?.filter(element => element.planType !== 'manual')
      .map((plan: PlanType) => plan.startDate && getCurrentPlanData(plan));
  };

  const getCurrentPlanData = (plan: PlanType) => {
    const currentDate = moment();
    const thisStartDate = moment.unix(plan?.startDate.seconds).utc().format('YYYY-MM-DD');
    const thisEndDate = moment.unix(plan.endDate.seconds).utc().format('YYYY-MM-DD');

    if (
      moment(currentDate.format('YYYY-MM-DD')).isBetween(thisStartDate, thisEndDate, undefined, '(]') ||
      moment(currentDate.format('YYYY-MM-DD')).isBetween(thisStartDate, thisEndDate, undefined, '[)') ||
      moment(currentDate.format('YYYY-MM-DD')).isBetween(thisStartDate, thisEndDate, undefined, '[]')
    ) {
      setCurrentPlanExists(true);
      setCurrentPlan([plan]);
    }
  };

  const closeAddPlanPopup = () => {
    handleOpenClosePopups(setCreatePlanPopupClass, '', 'close');
    setCurrentStep('1');
  };

  useEffect(() => {
    if (userPlanList) checkCurrentPlanExists();
    setTimeout(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollIntoView();
      }

      setPlanLoaded(true);
    }, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPlanList]);

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById('searchFilter')?.offsetParent !== null) {
        if (
          tourRef.current &&
          (userData?.cookieBannerType !== 'initial' || userData?.role === 5) &&
          planList !== undefined
        ) {
          if (userData?.tour?.planOverview === undefined) {
            tourRef.current.click();
          }
        }
      }

      if (document.getElementById('searchFilterMobile')?.offsetParent !== null) {
        if (
          tourRefMobile.current &&
          (userData?.cookieBannerType !== 'initial' || userData?.role === 5) &&
          planList !== undefined
        ) {
          if (userData?.tour?.planOverview === undefined) {
            tourRefMobile.current.click();
          }
        }
      }
    }, 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planList]);

  useEffect(() => {
    let thisScrollPos = 0;

    if (location.state?.planExampleCalories !== undefined && location.state?.planExampleCategory !== undefined) {
      setInitialPlanCalories(location.state?.planExampleCalories);
      setInitialPlanSection(location.state?.planExampleCategory);
      history.replace({ state: {} });
    } else if (location.state?.scrollPos !== undefined) {
      thisScrollPos = location.state.scrollPos;

      setTimeout(() => {
        document.getElementById('mainContainer')?.scrollTo(0, thisScrollPos);
      }, 1);
    }
  }, []);

  return (
    <>
      <div className={createPlanPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Neuen Plan erstellen</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => closeAddPlanPopup()}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent} ref={recipePopupContentRef}>
              <div className="pt-15 pb-30 px-20">
                {currentStep === '1' && (
                  <NewPlanStep1
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    planState={setCurrentAddPlan}
                    planStateValue={currentAddPlan}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentStep === '2' && (
                  <NewPlanStep2
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    planState={setCurrentAddPlan}
                    planStateValue={currentAddPlan}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentStep === '3' && (
                  <NewPlanStep3
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    planState={setCurrentAddPlan}
                    planStateValue={currentAddPlan}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentStep === '4' && (
                  <NewPlanStep4
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    planState={setCurrentAddPlan}
                    planStateValue={currentAddPlan}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentStep === '5' && (
                  <NewPlanStep5
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    planState={setCurrentAddPlan}
                    planStateValue={currentAddPlan}
                    popupContainerRef={recipePopupContentRef}
                    mainPopup={setCreatePlanPopupClass}
                  />
                )}
                {currentStep === '6' && (
                  <NewPlanStep6
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    planState={setCurrentAddPlan}
                    planStateValue={currentAddPlan}
                    popupContainerRef={recipePopupContentRef}
                    mainPopup={setCreatePlanPopupClass}
                  />
                )}

                {currentStep === 'copy' && (
                  <CopyPlanStep1
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    planState={setCurrentAddPlan}
                    planStateValue={currentAddPlan}
                    popupContainerRef={recipePopupContentRef}
                  />
                )}
                {currentStep === 'copy2' && (
                  <CopyPlanStep2
                    currentStep={setCurrentStep}
                    currentStepValue={currentStep}
                    planState={setCurrentAddPlan}
                    planStateValue={currentAddPlan}
                    popupContainerRef={recipePopupContentRef}
                    mainPopup={setCreatePlanPopupClass}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {planList && (
        <>
          <ShepherdTour
            steps={[
              {
                id: 'intro',
                attachTo: { element: '#newPlan', on: 'top' },
                buttons: [
                  {
                    classes: 'shepherd-button-secondary',
                    text: 'Beenden',
                    type: 'cancel',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Weiter',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Neuen Ernährungsplan erstellen!',
                text: [
                  '<p>Erstelle dir hier einen <strong>neuen Ernährungsplan</strong> nach deinen Bedürfnissen.</p><p>&#160;</p><p><strong>Keine Idee was du kochen sollst?</strong></p><p>Du kannst dir auch einen kompletten Ernährungsplan von unserem Alghorithmus erstellen lassen.</p>',
                ],
                when: {
                  destroy() {
                    updateTourToFirebase(userData, 'planOverview', authContext.user?.uid!, tenant);
                    setTimeout(() => {
                      document.getElementById('mainContainer')?.scrollIntoView();
                    }, 1);
                  },
                },
              },
              {
                id: 'intro',
                attachTo: { element: '#searchFilter', on: 'bottom' },
                buttons: [
                  {
                    classes: 'shepherd-button-secondary',
                    text: 'Beenden',
                    type: 'cancel',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Weiter',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Suche und Filter!',
                text: [
                  '<p>Hier kannst du deine eigenen und unsere Beispielpläne durchsuchen und filtern.</p> <p>Bei der Suche kannst du auch nach den Kalorien suchen.</p>',
                ],
              },
              {
                id: 'intro',
                attachTo: { element: '#samplePlans', on: 'top' },
                buttons: [
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Zurück',
                    type: 'back',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Beenden',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Starte mit Beispielplänen!',
                text: [
                  '<p>Hier findest du von uns erstellte Beispielpläne. Stöbere gerne durch durch die Kategorien um dir Anregungen zu holen.</p> <p>Du kannst auch mit einem Beispielplan starten und diesen nach deinen Bedürfnissen anpassen.</p>',
                ],
              },
            ]}
            tourOptions={tourOptions}
          >
            <TourButton />
          </ShepherdTour>

          <ShepherdTour
            steps={[
              {
                id: 'intro',
                attachTo: { element: '#newPlan', on: 'top' },
                buttons: [
                  {
                    classes: 'shepherd-button-secondary',
                    text: 'Beenden',
                    type: 'cancel',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Weiter',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Neuen Ernährungsplan erstellen!',
                text: [
                  '<p>Erstelle dir hier einen <strong>neuen Ernährungsplan</strong> nach deinen Bedürfnissen.</p><p>&#160;</p><p><strong>Keine Idee was du kochen sollst?</strong></p><p>Du kannst dir auch einen kompletten Ernährungsplan von unserem Alghorithmus erstellen lassen.</p>',
                ],
                when: {
                  destroy() {
                    updateTourToFirebase(userData, 'planOverview', authContext.user?.uid!, tenant);
                    setTimeout(() => {
                      document.getElementById('mainContainer')?.scrollIntoView();
                    }, 1);
                  },
                },
              },
              {
                id: 'intro',
                attachTo: { element: '#searchFilterMobile', on: 'bottom' },
                buttons: [
                  {
                    classes: 'shepherd-button-secondary',
                    text: 'Beenden',
                    type: 'cancel',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Weiter',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: false,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Suche und Filter!',
                text: [
                  '<p>Hier kannst du deine eigenen und unsere Beispielpläne durchsuchen und filtern.</p> <p>Bei der Suche kannst du auch nach den Kalorien suchen.</p>',
                ],
                when: {
                  show() {
                    if (scrollContainerRef.current) {
                      scrollContainerRef.current.scrollIntoView();
                    }
                  },
                },
              },
              {
                id: 'intro',
                attachTo: { element: '#samplePlans', on: 'top' },
                buttons: [
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Zurück',
                    type: 'back',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Beenden',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Starte mit Beispielplänen!',
                text: [
                  '<p>Hier findest du von uns erstellte Beispielpläne. Stöbere gerne durch durch die Kategorien um dir Anregungen zu holen.</p> <p>Du kannst auch mit einem Beispielplan starten und diesen nach deinen Bedürfnissen anpassen.</p>',
                ],
              },
            ]}
            tourOptions={tourOptions}
          >
            <TourButtonMobile />
          </ShepherdTour>
        </>
      )}

      <TransitionContainer isShown={planLoaded}>
        <div className={styles.container}>
          <div className={styles.pageWrapperDesktop} ref={scrollContainerRef}>
            <div>
              <header className="sticky top-0 bg-bgColor z-10 pt-50 xl:pt-30 pb-10">
                <div className="flex justify-between items-center tablet:mb-35 mb-20">
                  <Headline level={1}>{t('Plans')}</Headline>
                  <div
                    id="newPlan"
                    className="flex my-auto p-7 rounded-lg cursor-pointer border-transparent border-2 hover:border-accentColor"
                    onClick={() => handleOpenClosePopups('', setCreatePlanPopupClass, 'open')}
                    onKeyDown={() => handleOpenClosePopups('', setCreatePlanPopupClass, 'open')}
                    aria-hidden="true"
                  >
                    <div className="text-sm font-light my-auto">Neuer Plan</div>
                    <div className="my-auto pl-5">
                      <PlusIcon width={25} height={25} className="text-accentColor cursor-pointer" />
                    </div>
                  </div>
                </div>
                <div className="hidden xl:block">
                  {!planList ? (
                    <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
                  ) : (
                    <>
                      {currentPlanExists ? (
                        <>
                          <Link to={`/plans/${currentPlan[0].uid}`}>
                            <img
                              className={styles.mainImage}
                              src={
                                currentPlan[0].imageUrl.length > 0
                                  ? currentPlan[0].imageUrl
                                  : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fdefault_plan.jpeg?alt=media&token=f7893caa-6bdd-47b3-a053-7dd3430fd97e'
                              }
                              alt=""
                              loading="lazy"
                            />
                            <div className="absolute w-full bottom-0 left-0 pt-40 p-20 xl:p-40 bg-gradient-to-t from-bgColor to-transparent">
                              <div className="font-medium xl:text-sm text-12">
                                Aktueller Plan -{' '}
                                {moment.unix(currentPlan[0].startDate.seconds).utc().format('DD[.]MM[.]YY')} -{' '}
                                {moment.unix(currentPlan[0].endDate.seconds).utc().format('DD[.]MM[.]YY')}
                              </div>
                              <div className="font-semi-bold xl:font-light text-xl xl:text-3xl">
                                {currentPlan[0].name}
                              </div>
                            </div>
                          </Link>
                        </>
                      ) : (
                        <div
                          className="cursor-pointer"
                          onClick={() => handleOpenClosePopups('', setCreatePlanPopupClass, 'open')}
                          onKeyDown={() => handleOpenClosePopups('', setCreatePlanPopupClass, 'open')}
                          aria-hidden="true"
                        >
                          <img
                            className={styles.mainImageWithoutPlan}
                            src="https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fdefault_plan.jpeg?alt=media&token=f7893caa-6bdd-47b3-a053-7dd3430fd97e"
                            alt=""
                            loading="lazy"
                          />
                          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <div className="font-medium text-2xl pb-10">Aktuell hast du keinen Plan</div>
                            <div className="font-light">
                              Klicke hier um einen neuen Plan zu erstellen oder wähle aus der Liste der Beispielpläne
                              einen passenden Plan aus
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </header>

              <div className="flex pb-30 xl:hidden" id="searchFilterMobile">
                <div className="flex-1">
                  <SearchBox onChange={changeSearch} searchValue={planSearchValue} inputType="search" />
                </div>
                <div className="my-auto pl-15 ">
                  <AdjustmentsIcon
                    width={28}
                    height={28}
                    onClick={filterOpen}
                    className="text-accentColor cursor-pointer transform rotate-90"
                  />
                </div>
              </div>
            </div>
            <div className="xl:hidden relative">
              {!planList ? (
                <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
              ) : (
                <>
                  {currentPlanExists ? (
                    <>
                      <Link to={`/plans/${currentPlan[0].uid}`}>
                        <img
                          className={styles.mainImage}
                          src="https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fdefault_plan.jpeg?alt=media&token=f7893caa-6bdd-47b3-a053-7dd3430fd97e"
                          alt=""
                          loading="lazy"
                        />
                        <div className="absolute w-full bottom-0 left-0 pt-40 p-20 xl:p-40 bg-gradient-to-t from-bgColor to-transparent">
                          <div className="font-medium xl:text-sm text-12">
                            Aktueller Plan -{' '}
                            {moment.unix(currentPlan[0].startDate.seconds).utc().format('DD[.]MM[.]YY')} -{' '}
                            {moment.unix(currentPlan[0].endDate.seconds).utc().format('DD[.]MM[.]YY')}
                          </div>
                          <div className="font-semi-bold xl:font-light text-xl xl:text-3xl">{currentPlan[0].name}</div>
                        </div>
                      </Link>
                    </>
                  ) : (
                    <div
                      className="cursor-pointer"
                      onClick={() => handleOpenClosePopups('', setCreatePlanPopupClass, 'open')}
                      onKeyDown={() => handleOpenClosePopups('', setCreatePlanPopupClass, 'open')}
                      aria-hidden="true"
                    >
                      <img
                        className={styles.mainImageWithoutPlan}
                        src="https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fdefault_plan.jpeg?alt=media&token=f7893caa-6bdd-47b3-a053-7dd3430fd97e"
                        alt=""
                        loading="lazy"
                      />
                      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <div className="font-medium text-2xl pb-10">Aktuell hast du keinen Plan</div>
                        <div className="font-light">
                          Klicke hier um einen neuen Plan zu erstellen oder wähle aus der Liste der Beispielpläne einen
                          passenden Plan aus
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={styles.planRightWrapper}>
              <div className={styles.planWrapper}>
                <div className={styles.planContent}>
                  <div>
                    <div className="pt-20">
                      <PlanSection
                        openNewPlan={setCreatePlanPopupClass}
                        openFilter={setFilterOpen}
                        initialPlanCalories={initialPlanCalories}
                        initialPlanSection={initialPlanSection}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TransitionContainer>
      <FilterDrawerPlan isFilterOpen={isFilterOpen} closeFilterOpen={setFilterOpen} />
    </>
  );
};

export default Plans;

import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toast';
import firebase from 'services/firebase';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'providers/AuthProvider';
import GenerateAlghorithmus from 'components/GenerateAlghorithmus';

type Props = {
  className?: string;
  currentStep?: any;
  currentStepValue?: string;
  planState?: any;
  planStateValue?: any;
  mainPopup?: any;
  popupContainerRef?: any;
};

const NewPlanStep6: React.FC<Props> = ({
  currentStep,
  currentStepValue,
  planState,
  mainPopup,
  planStateValue,
  popupContainerRef,
}) => {
  const [visible, setVisible] = useState('block');
  const [savePlan, setSavePlan] = useState(false);

  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const { userData, tenant } = useContext(AuthContext);

  const history = useHistory();

  useEffect(() => {
    if (currentStepValue !== '6') {
      setVisible('hidden');
    }
  }, [currentStepValue, planStateValue, visible, popupContainerRef]);

  useEffect(() => {
    if (savePlan === true) {
      savePlanToFirebase();
    }
  }, [savePlan]);

  const savePlanToFirebase = async () => {
    const newColumnsAdmin = {
      ...planStateValue,
      examplePlan: true,
    };

    const newColumnsFavorite = {
      uid: planStateValue.uid,
      name: planStateValue.name,
      origId: planStateValue.uid,
    };

    if (userData?.role === 1) {
      try {
        await firebase
          .firestore()
          .collection('plans')
          .doc(planStateValue.uid)
          .set(newColumnsAdmin as object);
        toast.success(t('Dein Plan wurde erfolgreich erstellt!'));
        history.push({
          pathname: `/plans/edit/${planStateValue.uid}`,
          state: {
            from: 'plan_add',
          },
        });
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } else {
      try {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(authContext.user?.uid)
          .collection('plans')
          .doc(planStateValue.uid)
          .set(planStateValue as object);
        try {
          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(authContext.user?.uid)
            .collection('favorites_plans')
            .doc()
            .set(newColumnsFavorite as object);
        } catch (error) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          toast.error('Es ist leider etwas schief gegangen!');
        }
        toast.success(t('Dein Plan wurde erfolgreich erstellt!'));
        history.push({
          pathname: `/plans/edit/${planStateValue.uid}`,
          state: {
            from: 'plan_add',
          },
        });
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    }

    currentStep('1');
    mainPopup('hidden');
  };

  return (
    <>
      <div className={visible}>
        <div className="px-20">
          <div className="pb-10">
            <div className="font-bold text-18">Alghorithmus Einstellungen</div>
          </div>
        </div>
        <div className="pt-10">
          <GenerateAlghorithmus
            isNewPlan
            currentPlan={planStateValue}
            setCurrentPlan={planState}
            savePlanState={setSavePlan}
          />
        </div>
      </div>
    </>
  );
};

export default NewPlanStep6;

import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { OptionType } from 'react-switch-selector/dist/SwitchSelector';

type Props = {
  isClearable?: boolean;
  dropDownOption: Array<OptionType>;
  name: string;
  label?: string;
  isSearchable?: boolean;
  isMulti?: boolean;
  thisRef?: any;
  className?: string;
  labelClassName?: string;
  onChange?: (value: OptionType | OptionType[]) => void | Promise<void>;
  defaultValue?: OptionType | OptionType[];
  value?: any;
  isFullSize?: boolean;
  isBorder?: boolean;
};

export const optionToValue = (value: OptionType[]) => value.map(item => item.value);

export default function CustomSelect({
  labelClassName,
  isClearable = false,
  isSearchable = false,
  dropDownOption,
  name,
  label = '',
  onChange,
  isMulti = false,
  defaultValue,
  thisRef,
  value,
  className,
  isFullSize,
  isBorder,
}: Props) {
  const { t } = useTranslation();

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent) && isSearchable) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent) && isSearchable) {
      document.body.classList.remove('keyboard-open');
    }
  };

  return (
    <div>
      {label && (
        <label className={classNames('block text-sm font-regular text-textColor opacity-50 mb-1', labelClassName)}>
          {t(label)}
        </label>
      )}
      <Select
        className={classNames(
          isBorder
            ? 'react-select-container-full-border'
            : isFullSize
            ? 'react-select-container-full'
            : 'react-select-container',
          'cursor-pointer',
          className
        )}
        classNamePrefix="react-select"
        unstyled
        isClearable={isClearable}
        isSearchable={isSearchable}
        placeholder="Bitte auswählen..."
        isMulti={isMulti}
        name={name}
        options={dropDownOption}
        onChange={onChange}
        onFocus={setBodyClassForMobileSafari}
        onBlur={removeBodyClassForMobileSafari}
        defaultValue={defaultValue}
        value={value}
        menuPlacement="auto"
        ref={thisRef}
      />
    </div>
  );
}

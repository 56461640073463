import React, { useEffect, useState } from 'react';
import firebase from 'services/firebase';
import ReactLoading from 'react-loading';
import Headline from 'components/Headline';
import moment from 'moment';
import styles from './styles.module.scss';

export default function B2COverview() {
  const [isPending, setIsPending] = useState(true);
  const [monthlyStripeAbos, setMonthlyStripeAbos] = useState(0);
  const [yearlyStripeAbos, setYearlyStripeAbos] = useState(0);
  const [monthlyPaypalAbos, setMonthlyPaypalAbos] = useState(0);
  const [yearlyPaypalAbos, setYearlyPaypalAbos] = useState(0);
  const [monthlyMobileAbos, setMonthlyMobileAbos] = useState(0);
  const [yearlyMobileAbos, setYearlyMobileAbos] = useState(0);

  const [monthlyStripeRevenue, setMonthlyStripeRevenue] = useState(0);
  const [monthlyPaypalRevenue, setMonthlyPaypalRevenue] = useState(0);
  const [monthlyMobileRevenue, setMonthlyMobileRevenue] = useState(0);
  const [yearlyStripeRevenue, setYearlyStripeRevenue] = useState(0);
  const [yearlyPaypalRevenue, setYearlyPaypalRevenue] = useState(0);
  const [yearlyMobileRevenue, setYearlyMobileRevenue] = useState(0);

  const [revenueJan, setRevenueJan] = useState(0);
  const [revenueFeb, setRevenueFeb] = useState(0);
  const [revenueMar, setRevenueMar] = useState(0);
  const [revenueApr, setRevenueApr] = useState(0);
  const [revenueMay, setRevenueMay] = useState(0);
  const [revenueJun, setRevenueJun] = useState(0);
  const [revenueJul, setRevenueJul] = useState(0);
  const [revenueAug, setRevenueAug] = useState(0);
  const [revenueSep, setRevenueSep] = useState(0);
  const [revenueOct, setRevenueOct] = useState(0);
  const [revenueNov, setRevenueNov] = useState(0);
  const [revenueDec, setRevenueDec] = useState(0);

  const [coupons, setCoupons] = useState([
    {
      value: 49,
      name: 'T50TJ',
      count: 0,
    },
    {
      value: 59,
      name: '40TTJ',
      count: 0,
    },
    {
      value: 69,
      name: 'TT30J',
      count: 0,
    },
    {
      value: 79,
      name: 'TTJ20',
      count: 0,
    },
    {
      value: 49,
      name: 'hazel',
      count: 0,
    },
    {
      value: 79,
      name: 'extended',
      count: 0,
    },
  ]);

  const getData = async () => {
    const getMonthlyStripeDocs = await firebase
      .firestore()
      .collection(`tenants/tortija/users`)
      .where('membership.paymentMethod', '==', 'stripe')
      .where('membership.type', '==', 'pro')
      .get();

    const getYearlyStripeDocs = await firebase
      .firestore()
      .collection(`tenants/tortija/users`)
      .where('membership.paymentMethod', '==', 'stripe')
      .where('membership.type', '==', 'pro12')
      .get();

    const getMonthlyPaypalDocs = await firebase
      .firestore()
      .collection(`tenants/tortija/users`)
      .where('membership.paymentMethod', '==', 'paypal')
      .where('membership.type', '==', 'pro')
      .get();

    const getYearlyPaypalDocs = await firebase
      .firestore()
      .collection(`tenants/tortija/users`)
      .where('membership.paymentMethod', '==', 'paypal')
      .where('membership.type', '==', 'pro12')
      .get();

    const getMonthlyMobileDocs = await firebase
      .firestore()
      .collection(`tenants/tortija/users`)
      .where('membership.paymentMethod', 'in', ['android', 'iOS'])
      .where('membership.type', '==', 'pro')
      .get();

    const getYearlyMobileDocs = await firebase
      .firestore()
      .collection(`tenants/tortija/users`)
      .where('membership.paymentMethod', 'in', ['android', 'iOS'])
      .where('membership.type', '==', 'pro12')
      .get();

    const getMonthlyStripe = getMonthlyStripeDocs.size;
    const getYearlyStripe = getYearlyStripeDocs.size;

    const getMonthlyPaypal = getMonthlyPaypalDocs.size;
    const getYearlyPaypal = getYearlyPaypalDocs.size;

    const getMonthlyMobile = getMonthlyMobileDocs.size;
    const getYearlyMobile = getYearlyMobileDocs.size;

    setMonthlyStripeAbos(getMonthlyStripe);
    setMonthlyPaypalAbos(getMonthlyPaypal);
    setMonthlyMobileAbos(getMonthlyMobile);

    setMonthlyStripeRevenue(getMonthlyStripe * 9.99);
    setMonthlyPaypalRevenue(getMonthlyPaypal * 9.99);
    setMonthlyMobileRevenue(getMonthlyMobile * 9.99);

    setYearlyStripeAbos(getYearlyStripe);
    setYearlyPaypalAbos(getYearlyPaypal);
    setYearlyMobileAbos(getYearlyMobile);

    let stripeRev = 0;
    let paypalRev = 0;
    let mobileRev = 0;

    let revJan = 0;
    let revFeb = 0;
    let revMar = 0;
    let revApr = 0;
    let revMay = 0;
    let revJun = 0;
    let revJul = 0;
    let revAug = 0;
    let revSep = 0;
    let revOct = 0;
    let revNov = 0;
    let revDec = 0;

    getYearlyStripeDocs.forEach(doc => {
      const { rabattCode } = doc.data().membership;

      const couponIndex = coupons.findIndex(item => item.name === rabattCode);
      if (couponIndex !== -1) {
        const updatedCoupons = [...coupons];
        updatedCoupons[couponIndex].count += 1;
        setCoupons(updatedCoupons);

        stripeRev += updatedCoupons[couponIndex].value;

        if (doc.data().membership.nextDate !== undefined) {
          const getMonth = moment.unix(doc.data().membership.nextDate).format('MM');

          if (getMonth === '01') {
            revJan += updatedCoupons[couponIndex].value;
          } else if (getMonth === '02') {
            revFeb += updatedCoupons[couponIndex].value;
          } else if (getMonth === '03') {
            revMar += updatedCoupons[couponIndex].value;
          } else if (getMonth === '04') {
            revApr += updatedCoupons[couponIndex].value;
          } else if (getMonth === '05') {
            revMay += updatedCoupons[couponIndex].value;
          } else if (getMonth === '06') {
            revJun += updatedCoupons[couponIndex].value;
          } else if (getMonth === '07') {
            revJul += updatedCoupons[couponIndex].value;
          } else if (getMonth === '08') {
            revAug += updatedCoupons[couponIndex].value;
          } else if (getMonth === '09') {
            revSep += updatedCoupons[couponIndex].value;
          } else if (getMonth === '10') {
            revOct += updatedCoupons[couponIndex].value;
          } else if (getMonth === '11') {
            revNov += updatedCoupons[couponIndex].value;
          } else if (getMonth === '12') {
            revDec += updatedCoupons[couponIndex].value;
          }
        }
      } else {
        stripeRev += 99;

        if (doc.data().membership.nextDate !== undefined) {
          const getMonth = moment.unix(doc.data().membership.nextDate).format('MM');

          if (getMonth === '01') {
            revJan += 99;
          } else if (getMonth === '02') {
            revFeb += 99;
          } else if (getMonth === '03') {
            revMar += 99;
          } else if (getMonth === '04') {
            revApr += 99;
          } else if (getMonth === '05') {
            revMay += 99;
          } else if (getMonth === '06') {
            revJun += 99;
          } else if (getMonth === '07') {
            revJul += 99;
          } else if (getMonth === '08') {
            revAug += 99;
          } else if (getMonth === '09') {
            revSep += 99;
          } else if (getMonth === '10') {
            revOct += 99;
          } else if (getMonth === '11') {
            revNov += 99;
          } else if (getMonth === '12') {
            revDec += 99;
          }
        }
      }
    });

    getYearlyPaypalDocs.forEach(doc => {
      const { rabattCode } = doc.data().membership;

      const couponIndex = coupons.findIndex(item => item.name === rabattCode);
      if (couponIndex !== -1) {
        const updatedCoupons = [...coupons];
        updatedCoupons[couponIndex].count += 1;
        setCoupons(updatedCoupons);

        paypalRev += updatedCoupons[couponIndex].value;

        if (doc.data().membership.nextDate !== undefined) {
          const getMonth = moment.unix(doc.data().membership.nextDate).format('MM');

          if (getMonth === '01') {
            revJan += updatedCoupons[couponIndex].value;
          } else if (getMonth === '02') {
            revFeb += updatedCoupons[couponIndex].value;
          } else if (getMonth === '03') {
            revMar += updatedCoupons[couponIndex].value;
          } else if (getMonth === '04') {
            revApr += updatedCoupons[couponIndex].value;
          } else if (getMonth === '05') {
            revMay += updatedCoupons[couponIndex].value;
          } else if (getMonth === '06') {
            revJun += updatedCoupons[couponIndex].value;
          } else if (getMonth === '07') {
            revJul += updatedCoupons[couponIndex].value;
          } else if (getMonth === '08') {
            revAug += updatedCoupons[couponIndex].value;
          } else if (getMonth === '09') {
            revSep += updatedCoupons[couponIndex].value;
          } else if (getMonth === '10') {
            revOct += updatedCoupons[couponIndex].value;
          } else if (getMonth === '11') {
            revNov += updatedCoupons[couponIndex].value;
          } else if (getMonth === '12') {
            revDec += updatedCoupons[couponIndex].value;
          }
        }
      } else {
        paypalRev += 99;

        if (doc.data().membership.nextDate !== undefined) {
          const getMonth = moment.unix(doc.data().membership.nextDate).format('MM');

          if (getMonth === '01') {
            revJan += 99;
          } else if (getMonth === '02') {
            revFeb += 99;
          } else if (getMonth === '03') {
            revMar += 99;
          } else if (getMonth === '04') {
            revApr += 99;
          } else if (getMonth === '05') {
            revMay += 99;
          } else if (getMonth === '06') {
            revJun += 99;
          } else if (getMonth === '07') {
            revJul += 99;
          } else if (getMonth === '08') {
            revAug += 99;
          } else if (getMonth === '09') {
            revSep += 99;
          } else if (getMonth === '10') {
            revOct += 99;
          } else if (getMonth === '11') {
            revNov += 99;
          } else if (getMonth === '12') {
            revDec += 99;
          }
        }
      }
    });

    getYearlyMobileDocs.forEach(doc => {
      if (doc.data().membership.createdAt !== undefined) {
        const getMonth = moment(doc.data().membership.createdAt).format('MM');

        if (getMonth === '01') {
          revJan += 99.99;
        } else if (getMonth === '02') {
          revFeb += 99.99;
        } else if (getMonth === '03') {
          revMar += 99.99;
        } else if (getMonth === '04') {
          revApr += 99.99;
        } else if (getMonth === '05') {
          revMay += 99.99;
        } else if (getMonth === '06') {
          revJun += 99.99;
        } else if (getMonth === '07') {
          revJul += 99.99;
        } else if (getMonth === '08') {
          revAug += 99.99;
        } else if (getMonth === '09') {
          revSep += 99.99;
        } else if (getMonth === '10') {
          revOct += 99.99;
        } else if (getMonth === '11') {
          revNov += 99.99;
        } else if (getMonth === '12') {
          revDec += 99.99;
        }
      }

      mobileRev += 99.99;
    });

    setRevenueJan(revJan);
    setRevenueFeb(revFeb);
    setRevenueMar(revMar);
    setRevenueApr(revApr);
    setRevenueMay(revMay);
    setRevenueJun(revJun);
    setRevenueJul(revJul);
    setRevenueAug(revAug);
    setRevenueSep(revSep);
    setRevenueOct(revOct);
    setRevenueNov(revNov);
    setRevenueDec(revDec);

    setYearlyStripeRevenue(stripeRev);
    setYearlyPaypalRevenue(paypalRev);
    setYearlyMobileRevenue(mobileRev);

    setIsPending(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.wrapper}>
      <Headline level={1} classLevel={3} className={styles.header}>
        B2C Übersicht
      </Headline>
      {isPending ? (
        <div className="h-full w-full md:flex block">
          <div className={styles.loading}>
            <div className="font-extralight">Aktuelle Zahlen werden geladen...</div>
            <ReactLoading type="bars" width={20} height={20} color="#ffffff" className="mx-auto" />
          </div>
        </div>
      ) : (
        <div>
          <div className="grid grid-cols-1 tablet:grid-cols-2 large:grid-cols-3 gap-20">
            <div>
              <div className="font-bold text-25 pb-10 pt-40">Monatliche Abos</div>
              <div className="bg-lightGray p-15 rounded-20 mb-20">
                <div>
                  <div className="flex gap-10 justify-between px-20">
                    <div className="my-auto">Anzahl Abos (Stripe):</div>
                    <div className="font-bold text-20">{monthlyStripeAbos}</div>
                  </div>
                  <div className="pt-10 flex justify-between gap-10 px-20">
                    <div className="my-auto">Anzahl Abos (Paypal):</div>
                    <div className="font-bold text-20">{monthlyPaypalAbos}</div>
                  </div>
                  <div className="pt-10 flex justify-between gap-10 px-20">
                    <div className="my-auto">Anzahl Abos (Mobil):</div>
                    <div className="font-bold text-20">{monthlyMobileAbos}</div>
                  </div>
                  <div className="pt-10 flex justify-between gap-10 px-20">
                    <div className="my-auto underline">Anzahl Abos (Gesamt):</div>
                    <div className="font-bold text-20 underline">
                      {monthlyStripeAbos + monthlyPaypalAbos + monthlyMobileAbos}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-lightGray p-15 rounded-20 mb-20">
                <div>
                  <div className="flex gap-10 justify-between px-20">
                    <div className="my-auto">Umsatz pro Monat (Stripe):</div>
                    <div className="font-bold text-20">{monthlyStripeRevenue} €</div>
                  </div>
                  <div className="pt-10 flex justify-between gap-10 px-20">
                    <div className="my-auto">Umsatz pro Monat (Paypal):</div>
                    <div className="font-bold text-20">{monthlyPaypalRevenue} €</div>
                  </div>
                  <div className="pt-10 flex justify-between gap-10 px-20">
                    <div className="my-auto">Umsatz pro Monat (Mobil):</div>
                    <div className="font-bold text-20">{monthlyMobileRevenue} €</div>
                  </div>
                  <div className="pt-10 flex justify-between gap-10 px-20">
                    <div className="my-auto underline">Umsatz pro Monat (Gesamt):</div>
                    <div className="font-bold text-20 underline">
                      {monthlyStripeRevenue + monthlyPaypalRevenue + monthlyMobileRevenue} €
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="font-bold text-25 pb-10 pt-40">Jährliche Abos</div>
              <div className="bg-lightGray p-15 rounded-20 mb-20">
                <div>
                  <div className="flex gap-10 justify-between px-20">
                    <div className="my-auto">Anzahl Abos (Stripe):</div>
                    <div className="font-bold text-20">{yearlyStripeAbos}</div>
                  </div>
                  <div className="pt-10 flex justify-between gap-10 px-20">
                    <div className="my-auto">Anzahl Abos (Paypal):</div>
                    <div className="font-bold text-20">{yearlyPaypalAbos}</div>
                  </div>
                  <div className="pt-10 flex justify-between gap-10 px-20">
                    <div className="my-auto">Anzahl Abos (Mobil):</div>
                    <div className="font-bold text-20">{yearlyMobileAbos}</div>
                  </div>
                  <div className="pt-10 flex justify-between gap-10 px-20">
                    <div className="my-auto underline">Anzahl Abos (Gesamt):</div>
                    <div className="font-bold text-20 underline">
                      {yearlyStripeAbos + yearlyPaypalAbos + yearlyMobileAbos}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-lightGray p-15 rounded-20 mb-20">
                <div>
                  <div className="flex gap-10 justify-between px-20">
                    <div className="my-auto">Umsatz pro Jahr (Stripe):</div>
                    <div className="font-bold text-20">{yearlyStripeRevenue} €</div>
                  </div>
                  <div className="pt-10 flex justify-between gap-10 px-20">
                    <div className="my-auto">Umsatz pro Jahr (Paypal):</div>
                    <div className="font-bold text-20">{yearlyPaypalRevenue} €</div>
                  </div>
                  <div className="pt-10 flex justify-between gap-10 px-20">
                    <div className="my-auto">Umsatz pro Jahr (Mobil):</div>
                    <div className="font-bold text-20">{yearlyMobileRevenue} €</div>
                  </div>
                  <div className="pt-10 flex justify-between gap-10 px-20">
                    <div className="my-auto underline">Umsatz pro Jahr (Gesamt):</div>
                    <div className="font-bold text-20 underline">
                      {yearlyStripeRevenue + yearlyPaypalRevenue + yearlyMobileRevenue} €
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="font-bold text-25 pb-10 pt-40">Jährliche Abos (Gutscheine)</div>
              <div className="bg-lightGray p-15 rounded-20 mb-20">
                <div>
                  {coupons.map((item, index) => (
                    <div key={index}>
                      <div className="flex gap-10 justify-between px-20">
                        <div className="my-auto w-1/2">{item.name}:</div>
                        <div className="font-bold text-20 w-1/4">{item.count}</div>
                        <div className="font-bold text-20 w-1/4">{item.count * item.value} €</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="font-bold text-25 pb-10 pt-40">Umsatz pro Monat (Jährliche Abos)</div>
            <div className="bg-lightGray p-15 rounded-20 mb-20 w-full tablet:w-1/2">
              <div>
                <div className="flex gap-10 justify-between px-20">
                  <div className="my-auto">Januar:</div>
                  <div className="font-bold text-20">{revenueJan} €</div>
                </div>
              </div>
            </div>
            <div className="bg-lightGray p-15 rounded-20 mb-20 w-full tablet:w-1/2 mt-10">
              <div>
                <div className="flex gap-10 justify-between px-20">
                  <div className="my-auto">Februar:</div>
                  <div className="font-bold text-20">{revenueFeb} €</div>
                </div>
              </div>
            </div>
            <div className="bg-lightGray p-15 rounded-20 mb-20 w-full tablet:w-1/2 mt-10">
              <div>
                <div className="flex gap-10 justify-between px-20">
                  <div className="my-auto">März:</div>
                  <div className="font-bold text-20">{revenueMar} €</div>
                </div>
              </div>
            </div>
            <div className="bg-lightGray p-15 rounded-20 mb-20 w-full tablet:w-1/2 mt-10">
              <div>
                <div className="flex gap-10 justify-between px-20">
                  <div className="my-auto">April:</div>
                  <div className="font-bold text-20">{revenueApr} €</div>
                </div>
              </div>
            </div>
            <div className="bg-lightGray p-15 rounded-20 mb-20 w-full tablet:w-1/2 mt-10">
              <div>
                <div className="flex gap-10 justify-between px-20">
                  <div className="my-auto">Mai:</div>
                  <div className="font-bold text-20">{revenueMay} €</div>
                </div>
              </div>
            </div>
            <div className="bg-lightGray p-15 rounded-20 mb-20 w-full tablet:w-1/2 mt-10">
              <div>
                <div className="flex gap-10 justify-between px-20">
                  <div className="my-auto">Juni:</div>
                  <div className="font-bold text-20">{revenueJun} €</div>
                </div>
              </div>
            </div>
            <div className="bg-lightGray p-15 rounded-20 mb-20 w-full tablet:w-1/2 mt-10">
              <div>
                <div className="flex gap-10 justify-between px-20">
                  <div className="my-auto">Juli:</div>
                  <div className="font-bold text-20">{revenueJul} €</div>
                </div>
              </div>
            </div>
            <div className="bg-lightGray p-15 rounded-20 mb-20 w-full tablet:w-1/2 mt-10">
              <div>
                <div className="flex gap-10 justify-between px-20">
                  <div className="my-auto">August:</div>
                  <div className="font-bold text-20">{revenueAug} €</div>
                </div>
              </div>
            </div>
            <div className="bg-lightGray p-15 rounded-20 mb-20 w-full tablet:w-1/2 mt-10">
              <div>
                <div className="flex gap-10 justify-between px-20">
                  <div className="my-auto">September:</div>
                  <div className="font-bold text-20">{revenueSep} €</div>
                </div>
              </div>
            </div>
            <div className="bg-lightGray p-15 rounded-20 mb-20 w-full tablet:w-1/2 mt-10">
              <div>
                <div className="flex gap-10 justify-between px-20">
                  <div className="my-auto">Oktober:</div>
                  <div className="font-bold text-20">{revenueOct} €</div>
                </div>
              </div>
            </div>
            <div className="bg-lightGray p-15 rounded-20 mb-20 w-full tablet:w-1/2 mt-10">
              <div>
                <div className="flex gap-10 justify-between px-20">
                  <div className="my-auto">November:</div>
                  <div className="font-bold text-20">{revenueNov} €</div>
                </div>
              </div>
            </div>
            <div className="bg-lightGray p-15 rounded-20 mb-20 w-full tablet:w-1/2 mt-10">
              <div>
                <div className="flex gap-10 justify-between px-20">
                  <div className="my-auto">Dezember:</div>
                  <div className="font-bold text-20">{revenueDec} €</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

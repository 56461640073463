import classNames from 'classnames';
import React, { useContext } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { AuthContext } from 'providers/AuthProvider';
import { savePlanChangesToFirebase } from 'shared/functions/global';
import styles from './styles.module.scss';

type Props = {
  planObject?: any;
  setPlanObject?: any;
  setPlanChanged?: any;
  className?: string;
  onlyNutritionForm?: boolean;
  edit?: boolean;
  isPlanOverview?: boolean;
  savePlan?: boolean;
};

const HashTagBadge: React.FC<Props> = ({
  planObject,
  className,
  onlyNutritionForm,
  edit,
  setPlanObject,
  setPlanChanged,
  isPlanOverview = false,
  savePlan = false,
}) => {
  const { userData, tenant } = useContext(AuthContext);
  const authContext = useContext(AuthContext);

  function deleteBadge(item: string) {
    const newColumns = {
      ...planObject,
      [item]: false,
    };

    if (setPlanChanged) {
      setPlanChanged(true);
    }

    if (savePlan) {
      savePlanChangesToFirebase(
        userData,
        newColumns,
        newColumns.uid,
        authContext.user?.uid,
        false,
        setPlanObject,
        tenant
      );
    } else {
      setPlanObject(newColumns);
    }
  }

  return (
    <>
      {planObject?.flexitarian === true ||
      planObject?.ketogen === true ||
      planObject?.vegetarian === true ||
      planObject?.vegan === true ||
      planObject?.lactose === false ||
      planObject?.gluten === false ? (
        <>
          <div className={className}>
            <div className={isPlanOverview ? 'flex flex-wrap gap-2' : 'flex flex-wrap gap-4'}>
              {planObject?.flexitarian === true && (
                <div>
                  {isPlanOverview ? (
                    <div className="rounded-3xl bg-accentColor py-5 px-10 font-bold text-10 text-buttonTextColor">
                      Flexitarisch
                    </div>
                  ) : (
                    <div className="rounded-3xl bg-accentColor py-5 px-15 font-bold text-14 text-buttonTextColor">
                      Flexitarisch
                    </div>
                  )}
                </div>
              )}
              {planObject?.ketogen === true && (
                <div>
                  {edit ? (
                    <div className="rounded-3xl bg-accentColor py-5 pl-15 pr-30 relative font-bold text-14">
                      <div>Ketogen</div>
                      <div className={styles.editIcon}>
                        <XIcon
                          width={15}
                          height={15}
                          className="text-textColor"
                          onClick={() => deleteBadge('ketogen')}
                        />
                      </div>
                    </div>
                  ) : isPlanOverview ? (
                    <div className="rounded-3xl bg-accentColor py-5 px-10 font-bold text-10 text-buttonTextColor">
                      Ketogen
                    </div>
                  ) : (
                    <div className="rounded-3xl bg-accentColor py-5 px-15 font-bold text-14 text-buttonTextColor">
                      Ketogen
                    </div>
                  )}
                </div>
              )}
              {planObject?.vegetarian === true && (
                <div>
                  {edit ? (
                    <div className="rounded-3xl bg-accentColor py-5 pl-15 pr-30 relative font-bold text-14">
                      <div>Vegetarisch</div>
                      <div className={styles.editIcon}>
                        <XIcon
                          width={15}
                          height={15}
                          className="text-textColor"
                          onClick={() => deleteBadge('vegetarian')}
                        />
                      </div>
                    </div>
                  ) : isPlanOverview ? (
                    <div className="rounded-3xl bg-accentColor py-5 px-10 font-bold text-10 text-buttonTextColor">
                      Vegetarisch
                    </div>
                  ) : (
                    <div className="rounded-3xl bg-accentColor py-5 px-15 font-bold text-14 text-buttonTextColor">
                      Vegetarisch
                    </div>
                  )}
                </div>
              )}
              {planObject?.vegan === true && (
                <div>
                  {edit ? (
                    <div className="rounded-3xl bg-accentColor py-5 pl-15 pr-30 relative font-bold text-14">
                      <div>Vegan</div>
                      <div className={styles.editIcon}>
                        <XIcon width={15} height={15} className="text-textColor" onClick={() => deleteBadge('vegan')} />
                      </div>
                    </div>
                  ) : isPlanOverview ? (
                    <div className="rounded-3xl bg-accentColor py-5 px-10 font-bold text-10 text-buttonTextColor">
                      Vegan
                    </div>
                  ) : (
                    <div className="rounded-3xl bg-accentColor py-5 px-15 font-bold text-14 text-buttonTextColor">
                      Vegan
                    </div>
                  )}
                </div>
              )}
              {onlyNutritionForm !== true && planObject?.lactose === false && isPlanOverview && (
                <div className="rounded-3xl bg-accentColor py-5 px-10 font-bold text-10 text-buttonTextColor">
                  Laktosefrei
                </div>
              )}
              {onlyNutritionForm !== true && planObject?.lactose === false && !isPlanOverview && (
                <div className="rounded-3xl bg-accentColor py-5 px-15 font-bold text-14 text-buttonTextColor">
                  Laktosefrei
                </div>
              )}
              {onlyNutritionForm !== true && planObject?.gluten === false && isPlanOverview && (
                <div className="rounded-3xl bg-accentColor py-5 px-10 font-bold text-10 text-buttonTextColor">
                  Glutenfrei
                </div>
              )}
              {onlyNutritionForm !== true && planObject?.gluten === false && !isPlanOverview && (
                <div className="rounded-3xl bg-accentColor py-5 px-15 font-bold text-14 text-buttonTextColor">
                  Glutenfrei
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div />
      )}
    </>
  );
};

export default HashTagBadge;

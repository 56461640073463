import React, { useContext, useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Label,
} from 'recharts';
import { toast } from 'react-toast';
import { AuthContext } from 'providers/AuthProvider';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type Props = {
  planStateValue?: any;
  thisWeekIndex?: number;
  withClick?: boolean;
  updateDayId?: string;
};

const WeekOverview: React.FC<Props> = ({ planStateValue, thisWeekIndex = 0, withClick = false, updateDayId }) => {
  const { t } = useTranslation();

  const [currentWeekIndex, setCurrentWeekIndex] = useState(thisWeekIndex);
  const [currentWeekGroupArray, setCurrentWeekGroupArray] = useState([[{ date: 0 }, { date: 1 }]]) as any;

  const { userData, theme } = useContext(AuthContext);
  const history = useHistory();

  const [currentWeekDataObject, setCurrentWeekDataObject] = useState([
    {
      name: 'Mo.',
      longLabel: 'Montag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Di.',
      longLabel: 'Dienstag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Mi.',
      longLabel: 'Mittwoch',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Do.',
      longLabel: 'Donnerstag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Fr.',
      longLabel: 'Freitag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Sa.',
      longLabel: 'Samstag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'So.',
      longLabel: 'Sonntag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
  ]);
  const initialWeekDataObject = [
    {
      name: 'Mo.',
      longLabel: 'Montag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Di.',
      longLabel: 'Dienstag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Mi.',
      longLabel: 'Mittwoch',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Do.',
      longLabel: 'Donnerstag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Fr.',
      longLabel: 'Freitag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Sa.',
      longLabel: 'Samstag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'So.',
      longLabel: 'Sonntag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
  ];

  const CustomTooltip = ({ active, payload, label }: { active: any; label: any; payload: any }) => {
    if (active) {
      return (
        <div className="bg-lightGray text-textColor px-30 py-30">
          {currentWeekDataObject?.filter((item: any) => item.name === label)[0].date !== '' ? (
            <>
              {planStateValue?.examplePlan === false && (
                <div className="font-bold">
                  {currentWeekDataObject?.filter((item: any) => item.name === label)[0].longLabel}
                </div>
              )}
              <div className="font-bold pb-10">
                {currentWeekDataObject?.filter((item: any) => item.name === label)[0].date}
              </div>
              <div>
                <p className="label text-calories"> {`${payload[0].name} : ${payload[0].value} %`} </p>
              </div>
              <div>
                <p className="label text-carbohydrates"> {`${payload[1].name} : ${payload[1].value} %`} </p>
              </div>
              <div>
                <p className="label text-protein"> {`${payload[2].name} : ${payload[2].value} %`} </p>
              </div>
              <div>
                <p className="label text-fat"> {`${payload[3].name} : ${payload[3].value} %`} </p>
              </div>
            </>
          ) : (
            <div>Für diesen Tag sind keine Daten im Plan vorhanden!</div>
          )}
        </div>
      );
    }

    return null;
  };

  const weekForward = () => {
    if (
      currentWeekGroupArray[Object.keys(currentWeekGroupArray)[currentWeekIndex + 1]] !== undefined &&
      planStateValue?.examplePlan === false
    ) {
      setCurrentWeekIndex(currentWeekIndex + 1);
      updateWeekOverview(currentWeekIndex + 1);
    } else if (
      planStateValue?.examplePlan === true &&
      (currentWeekIndex + 1) * 7 < planStateValue?.dayEntries?.length
    ) {
      setCurrentWeekIndex(currentWeekIndex + 1);
      updateWeekOverview(currentWeekIndex + 1);
    } else {
      toast.error('Es gibt keine weitere Woche in diesem Plan!');
    }
  };

  const weekBackward = () => {
    if (currentWeekIndex === 0) {
      toast.error('Es gibt keine vorherige Woche in diesem Plan!');
    } else {
      setCurrentWeekIndex(currentWeekIndex - 1);
      updateWeekOverview(currentWeekIndex - 1);
    }
  };

  // Get Makro Percentage Values
  const getMakroPercentages = (type: string, custom_value: any, value: any) => {
    let thisCalculatedValue = 0;

    if (custom_value) {
      thisCalculatedValue = Math.round((100 * value) / custom_value);
    } else if (type === 'kcal') {
      thisCalculatedValue = Math.round((100 * value) / parseFloat(userData!.calories));
    } else if (type === 'carbohydrates') {
      thisCalculatedValue = Math.round((100 * value) / userData!.carbohydrates_total);
    } else if (type === 'protein') {
      thisCalculatedValue = Math.round((100 * value) / userData!.protein_total);
    } else if (type === 'fat') {
      thisCalculatedValue = Math.round((100 * value) / userData!.fat_total);
    }

    return thisCalculatedValue;
  };

  const updateWeekOverview = (weekIndex: number) => {
    let thisWeekObject = [] as any;
    let thisText = null as any;

    const groups = planStateValue?.dayEntries?.reduce((acc: any, date: any) => {
      const thisAcc = acc;

      // create a composed key: 'year-week'
      const yearWeek = `${moment.unix(date.date.seconds).utc().year()}-${moment.unix(date.date.seconds).utc().week()}`;

      if (thisText === null) {
        thisText = yearWeek;
      }

      // add this key as a property to the result object
      if (!thisAcc[yearWeek]) {
        thisAcc[yearWeek] = [];
      }

      // push the current date that belongs to the year-week calculated befor
      thisAcc[yearWeek].push({ date: date.date.seconds });

      setCurrentWeekGroupArray(thisAcc);
      thisWeekObject = thisAcc;

      return thisAcc;
    }, []);

    let newColumns = [initialWeekDataObject] as any;

    if (thisText !== null) {
      thisWeekObject[Object.keys(thisWeekObject)[weekIndex]].map((entries: any) => {
        const getThisWeekName = moment.unix(entries.date).utc().format('dddd');
        const thisDayItem = planStateValue?.dayEntries.filter((item: any) => item.date.seconds === entries.date)[0];

        newColumns = [
          newColumns[0].map((weeks: any) => {
            if (weeks.longLabel !== getThisWeekName) return weeks;
            return {
              ...weeks,
              date: moment(moment.unix(thisDayItem?.date.seconds).utc()).locale('de').format('DD[.]MM[.]YYYY'),
              dayId: thisDayItem?.id,
              Kalorien: getMakroPercentages(
                'kcal',
                thisDayItem?.custom_kcal_total
                  ? thisDayItem?.custom_kcal_total
                  : planStateValue?.custom_kcal_total
                  ? planStateValue?.custom_kcal_total
                  : undefined,
                thisDayItem?.kcal_total
              ),
              Kohlenhydrate: getMakroPercentages(
                'carbohydrates',
                thisDayItem?.custom_carbohydrates_total
                  ? thisDayItem?.custom_carbohydrates_total
                  : planStateValue?.custom_carbohydrates_total
                  ? planStateValue?.custom_carbohydrates_total
                  : undefined,
                thisDayItem?.carbohydrates_total
              ),
              Eiweiß: getMakroPercentages(
                'protein',
                thisDayItem?.custom_protein_total
                  ? thisDayItem?.custom_protein_total
                  : planStateValue?.custom_protein_total
                  ? planStateValue?.custom_protein_total
                  : undefined,
                thisDayItem?.protein_total
              ),
              Fett: getMakroPercentages(
                'fat',
                thisDayItem?.custom_fat_total
                  ? thisDayItem?.custom_fat_total
                  : planStateValue?.custom_fat_total
                  ? planStateValue?.custom_fat_total
                  : undefined,
                thisDayItem?.fat_total
              ),
            };
          }),
        ];

        return entries.date;
      });

      if (planStateValue?.examplePlan === true) {
        let currentIndex = (weekIndex + 1) * 7;

        const newColumnsExample = [
          newColumns[0].map((weeks: any, index: number) => {
            const getThisDay = planStateValue?.dayEntries.filter((item: any) => item.id === currentIndex - 6)[0];
            currentIndex += 1;
            return {
              ...weeks,
              date: getThisDay !== undefined ? getThisDay.name : '',
              dayId: index,
              Kalorien: getMakroPercentages(
                'kcal',
                planStateValue?.examplePlan === true && userData?.role === 2
                  ? undefined
                  : getThisDay?.custom_kcal_total
                  ? getThisDay?.custom_kcal_total
                  : planStateValue?.custom_kcal_total
                  ? planStateValue?.custom_kcal_total
                  : undefined,
                getThisDay?.kcal_total
              ),
              Kohlenhydrate: getMakroPercentages(
                'carbohydrates',
                planStateValue?.examplePlan === true && userData?.role === 2
                  ? undefined
                  : getThisDay?.custom_carbohydrates_total
                  ? getThisDay?.custom_carbohydrates_total
                  : planStateValue?.custom_carbohydrates_total
                  ? planStateValue?.custom_carbohydrates_total
                  : undefined,
                getThisDay?.carbohydrates_total
              ),
              Eiweiß: getMakroPercentages(
                'protein',
                planStateValue?.examplePlan === true && userData?.role === 2
                  ? undefined
                  : getThisDay?.custom_protein_total
                  ? getThisDay?.custom_protein_total
                  : planStateValue?.custom_protein_total
                  ? planStateValue?.custom_protein_total
                  : undefined,
                getThisDay?.protein_total
              ),
              Fett: getMakroPercentages(
                'fat',
                planStateValue?.examplePlan === true && userData?.role === 2
                  ? undefined
                  : getThisDay?.custom_fat_total
                  ? getThisDay?.custom_fat_total
                  : planStateValue?.custom_fat_total
                  ? planStateValue?.custom_fat_total
                  : undefined,
                getThisDay?.fat_total
              ),
            };
          }),
        ];
        setCurrentWeekDataObject(newColumnsExample[0]);
      } else {
        setCurrentWeekDataObject(newColumns[0]);
      }
    }
  };

  useEffect(() => {
    updateWeekOverview(thisWeekIndex);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisWeekIndex]);

  useEffect(() => {
    updateWeekOverview(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pt-40">
      <div className="pl-20 pr-30 pb-10 flex justify-between">
        <div className="font-bold text-20">Wochenstatistik</div>
        <div className="flex">
          <div className="pt-1">
            <ChevronLeftIcon
              width={20}
              height={20}
              className="text-accentColor mx-auto cursor-pointer"
              onClick={() => weekBackward()}
            />
          </div>
          <div className="px-15">
            {planStateValue?.examplePlan === false ? (
              <>
                {moment
                  .unix(currentWeekGroupArray[Object.keys(currentWeekGroupArray)[currentWeekIndex]][0].date)
                  .utc()
                  .clone()
                  .startOf('week')
                  .format('DD[.]MM[.]')}{' '}
                -{' '}
                {moment
                  .unix(currentWeekGroupArray[Object.keys(currentWeekGroupArray)[currentWeekIndex]][0].date)
                  .utc()
                  .clone()
                  .endOf('week')
                  .format('DD[.]MM[.]')}
              </>
            ) : (
              <>{currentWeekIndex + 1}. Woche </>
            )}
          </div>
          <div className="pt-1">
            <ChevronRightIcon
              width={20}
              height={20}
              className="text-accentColor mx-auto cursor-pointer"
              onClick={() => weekForward()}
            />
          </div>
        </div>
      </div>
      {planStateValue?.examplePlan === true && (
        <div className="font-extralight pl-20 pr-30 pb-20">
          Dieser Tag würde deinen aktuellen Bedarf folgendermaßen decken:
        </div>
      )}
      {withClick ? (
        <ResponsiveContainer width="100%" aspect={4.0 / 3.0}>
          <BarChart
            data={currentWeekDataObject}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 0,
            }}
            onClick={data => {
              if (data && data.activePayload && data.activePayload.length > 0) {
                if (data.activePayload[0]?.payload?.date !== '' && data.activePayload[0]?.payload?.date !== undefined) {
                  return history.push({
                    pathname: `/plans/edit/${updateDayId}`,
                    state: {
                      from: 'plan-detail',
                      planDayId: data.activePayload[0].payload.dayId,
                    },
                  });
                }
                return false;
              }
              return false;
            }}
            barCategoryGap="20%"
          >
            <XAxis
              dataKey="name"
              tick={{ fill: theme?.textColor ?? '#ffffff' }}
              tickLine={false}
              padding={{ right: 40, left: 20 }}
            />
            <Tooltip content={<CustomTooltip active={undefined} payload={undefined} label={undefined} />} />
            <ReferenceLine
              y={100}
              stroke={theme?.textColor ?? '#ffffff'}
              strokeWidth={2}
              alwaysShow
              strokeDasharray="3 3"
            >
              <Label
                value="100%"
                offset={6}
                position="insideBottomRight"
                fill={theme?.textColor ?? '#ffffff'}
                fontWeight="bold"
                fontSize="16"
              />
            </ReferenceLine>
            <Bar dataKey="Kalorien" fill="#01A2E0" />
            <Bar dataKey="Kohlenhydrate" fill="#DE4C39" />
            <Bar dataKey="Eiweiß" fill="#8DC703" />
            <Bar dataKey="Fett" fill="#FBA500" />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer width="95%" height={300}>
          <BarChart
            data={currentWeekDataObject}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 0,
            }}
            barCategoryGap="20%"
          >
            <XAxis
              dataKey="name"
              tick={{ fill: theme?.textColor ?? '#ffffff' }}
              tickLine={false}
              padding={{ right: 40, left: 20 }}
            />
            <Tooltip content={<CustomTooltip active={undefined} payload={undefined} label={undefined} />} />
            <ReferenceLine
              y={100}
              stroke={theme?.textColor ?? '#ffffff'}
              strokeWidth={2}
              alwaysShow
              strokeDasharray="3 3"
            >
              <Label
                value="100%"
                offset={6}
                position="insideBottomRight"
                fill={theme?.textColor ?? '#ffffff'}
                fontWeight="bold"
                fontSize="16"
              />
            </ReferenceLine>
            <Bar dataKey="Kalorien" fill="#01A2E0" />
            <Bar dataKey="Kohlenhydrate" fill="#DE4C39" />
            <Bar dataKey="Eiweiß" fill="#8DC703" />
            <Bar dataKey="Fett" fill="#FBA500" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default WeekOverview;

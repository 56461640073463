import React, { useContext } from 'react';
import moment from 'moment';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Home from 'pages/Dashboard';
import MainLayout from 'layouts/MainLayout';
import Preloader from 'components/Preloader';
import ShoppingList from 'pages/ShoppingList';
import { AuthContext } from 'providers/AuthProvider';
import { FilterProvider } from 'providers/FilterProvider';
import { MainProvider } from 'providers/MainProvider';
import { remainTrialDay } from 'shared/functions/global';
import { ROLE_COMPANY_ADMIN, ROLE_TRAINER, ROLE_LOCATION_ADMIN, ROLE_USER, ROLE_MEMBER } from 'shared/constants/global';
import CompanyMember from 'pages/CompanyMember';
import AddCompanyMember from 'pages/CompanyMember/add';
import ImportCompanyMember from 'pages/CompanyMember/import';
import CompanyDashboard from 'pages/Company/components/CompanyDashboard';
import CompanySettings from 'pages/Company/components/CompanySettings';
import RoleRoutes from '../roleRoutes';
import ProfileRoutes from './routes/profileRoutes';
import PlanRoutes from './routes/planRoutes';
import NutritionRoutes from './routes/nutritionRoutes';
import AdminRoutes from './routes/adminRoutes';
import CompanyRoutes from './routes/companyRoutes';

const MainRoutes: React.FC = () => {
  const { userData } = useContext(AuthContext);
  const location = useLocation();

  // Redirect to construction page
  /* if (userData?.age && userData?.role === 2) {
    return <ConstructionLoader />;
  } */

  if (!userData) {
    return <Preloader />;
  }

  // Redirect Company Admin to dashboard page
  if (userData?.role === ROLE_COMPANY_ADMIN && location.pathname === '/') {
    return <Redirect to="/company-dashboard" />;
  }

  // Redirect Trainer to member page
  if (userData?.role === ROLE_TRAINER && location.pathname === '/') {
    return <Redirect to="/member" />;
  }

  // Redirect Location Admin to member page
  if (userData?.role === ROLE_LOCATION_ADMIN && location.pathname === '/') {
    return <Redirect to="/member" />;
  }

  // Redirect to profile wizard if profile is not complete (only for user)
  if (
    !userData.profileComplete &&
    userData?.role !== ROLE_COMPANY_ADMIN &&
    userData?.role !== ROLE_TRAINER &&
    userData?.role !== ROLE_LOCATION_ADMIN
  ) {
    return <Redirect to="/auth/profile-wizard" />;
  }

  const remainDay = remainTrialDay(userData.created?.seconds);
  if (remainDay < 0 && userData.membership?.type === 'free' && userData.role === ROLE_USER) {
    return <Redirect to="/purchase" />;
  }

  if (
    moment(moment().unix()).isAfter(userData?.membership?.nextDate) &&
    userData.membership?.type === 'canceled' &&
    userData.role === ROLE_USER
  ) {
    return <Redirect to="/purchase" />;
  }

  return (
    <MainProvider>
      <MainLayout>
        <FilterProvider>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/nutrition" component={NutritionRoutes} />
            <Route path="/profile" component={ProfileRoutes} />
            <Route path="/plans" component={PlanRoutes} />
            <Route path="/shopping-list" component={ShoppingList} />
            <RoleRoutes path="/admin" neededRole={[1]} component={AdminRoutes} />
            <RoleRoutes path="/company" neededRole={[3]} component={CompanyRoutes} />
            <RoleRoutes path="/company-dashboard" neededRole={[3]} component={CompanyDashboard} />
            <RoleRoutes path="/company-settings" neededRole={[3]} component={CompanySettings} />
            <RoleRoutes path="/member/add" neededRole={[3, 4, 6]} component={AddCompanyMember} />
            <RoleRoutes path="/member/import" neededRole={[3, 6]} component={ImportCompanyMember} />
            <RoleRoutes path="/member" neededRole={[3, 4, 6]} component={CompanyMember} />
            <Redirect to="/" />
          </Switch>
        </FilterProvider>
      </MainLayout>
    </MainProvider>
  );
};

export default MainRoutes;

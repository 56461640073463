import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon, TrashIcon, UserCircleIcon, XIcon, CheckCircleIcon } from '@heroicons/react/outline';
import { toast } from 'react-toast';
import classNames from 'classnames';
import axios from 'axios';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import LogoImg from 'assets/img/svg/logo.svg';
import { changeUserView } from 'shared/functions/global';
import firebase from 'services/firebase';
import { AuthContext } from 'providers/AuthProvider';
import { defaultTextColor } from 'layouts/Theme';
import ReactLoading from 'react-loading';
import Headline from 'components/Headline';
import Overlay from 'components/Overlay';
import { companyMenu } from 'shared/constants/global';
import Button from 'components/Button';

import styles from './styles.module.scss';

type Props = {};

const CompanyProfile: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { tenant, tenantData, userData, theme } = React.useContext(AuthContext);
  const [deleteAccountPopupClass, setDeleteAccountPopupClass] = useState('hidden');
  const [changeSwitchLoading, setChangeSwitchLoading] = useState(false);
  const [isUserSwitch, setIsUserSwitch] = useState(false);

  const history = useHistory();
  const db = firebase.firestore();
  const authContext = useContext(AuthContext);

  const logOutUser = async () => {
    try {
      await firebase.auth().signOut();
      localStorage.removeItem('currentUserToken');
      window.location.reload();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const changeUserViewExecute = async () => {
    setChangeSwitchLoading(true);
    if (userData?.role === 5 && (userData?.isAdminUser || userData?.isTrainerUser || userData?.isLocationAdminUser)) {
      const updateUser = await changeUserView(
        userData,
        authContext.user?.uid,
        tenant,
        userData?.isAdminUser ? 3 : userData?.isLocationAdminUser ? 6 : 4
      );

      if (updateUser.status === 'success') {
        authContext.setUserData((prevProfile: UserInfo) => ({
          ...prevProfile,
          role: userData?.isAdminUser ? 3 : userData?.isLocationAdminUser ? 6 : 4,
        }));

        setIsUserSwitch(false);
        setChangeSwitchLoading(false);
        history.push('/');
      } else {
        setChangeSwitchLoading(false);
        toast.error('Es ist leider etwas schiefgelaufen! Bitte versuche es nochmal oder kontaktiere uns!');
      }
    } else if (userData?.isAdminUser || userData?.role === 4 || userData?.role === 6) {
      const updateUser = await changeUserView(userData, authContext.user?.uid, tenant, 5);

      if (updateUser.status === 'success') {
        authContext.setUserData((prevProfile: UserInfo) => ({
          ...prevProfile,
          role: 5,
        }));

        setIsUserSwitch(false);
        setChangeSwitchLoading(false);
        history.push('/');
      } else {
        setChangeSwitchLoading(false);
        toast.error('Es ist leider etwas schiefgelaufen! Bitte versuche es nochmal oder kontaktiere uns!');
      }
    }
  };

  const deleteTenant = async () => {
    try {
      const deleteMailText = `Tenant ID: ${tenantData?.company?.companyName}\n City:${
        tenantData?.company.city
      }\n Email: ${tenantData?.company?.email}\n Datum:${moment().format('DD.MM.YYYY')}\n Abo: ${
        tenantData?.subscriptionId
      }`;

      // Send delete mail to us
      try {
        await axios({
          url: `${process.env.REACT_APP_API_URL}/sendTextMail`,
          method: 'post',
          data: {
            mailSubject: 'Usersaccount gelöscht',
            mailTo: 'torben@tortija.de',
            mailText: deleteMailText,
          },
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        }).then(
          response => {
            console.log(response);
          },
          error => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }

      await db.collection(`tenants`).doc(tenant).update({
        toDelete: true,
      });
      toast.success('Dein Account wurde erfolgreich gelöscht!');
      logOutUser();
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.warn(error.message);
    }
  };

  // @ts-ignore
  useEffect(() => {
    if (userData && userData?.firstLogin) {
      history.push('/company/design');
    }
    return () => {};
  }, [userData]);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('mainContainer')?.scrollTo(0, 0);
    }, 1);
  }, []);

  return (
    <>
      <Overlay
        overlayClass={deleteAccountPopupClass}
        setOverlayClass={setDeleteAccountPopupClass}
        headline="Account kündigen"
        icon={<TrashIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        {tenantData?.stripeConnect !== undefined ? (
          <div>
            <div className="pt-30 font-extralight text-base">
              <div>Sie haben Tortija Billing in Ihrem Account aktiviert.</div>
              <div>Bitte kontaktieren Sie uns damit wir gemeinsam den Account kündigen können!</div>
            </div>
            <div className="pt-30 flex gap-30 pb-40">
              <div>
                <Link
                  to={{
                    pathname: 'https://www.tortija.de/kontakt',
                  }}
                  target="_blank"
                  type="button"
                  className="my-auto"
                >
                  <Button className="w-full">Kontakt</Button>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="pt-30 font-extralight text-base">
              <div>Wollen Sie Ihren Account wirklich löschen?</div>
              <div>Alle gespeicherten Daten werden dabei gelöscht und die Mitgliedschaft wird gekündigt!</div>
            </div>
            <div className="pt-30 flex gap-30 pb-40">
              <div>
                <Button className="w-full" onClick={() => deleteTenant()}>
                  Account kündigen
                </Button>
              </div>
              <div>
                <Button className="w-full" onClick={() => setDeleteAccountPopupClass('hidden')}>
                  Abbrechen
                </Button>
              </div>
            </div>
          </div>
        )}
      </Overlay>

      <div className={styles.header}>
        <Headline className="text-20 md:text-35" level={1}>
          {t('Company')}
        </Headline>
        <div className={window.location.search.length === 0 ? 'h-40 block md:hidden mb-10' : 'h-40 block md:hidden'}>
          {window.location.search.length === 0 ? (
            <div className="relative">
              <div onClick={() => setIsUserSwitch(true)} aria-hidden>
                <img className="h-40 object-contain" width={160} height={140} src={theme?.logo ?? LogoImg} alt="Logo" />

                {changeSwitchLoading ? (
                  <div className="w-full flex justify-center">
                    <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
                  </div>
                ) : (
                  <div>
                    <div className="font-extralight text-12 flex justify-center space-x-5">
                      <UserCircleIcon width={15} height={15} className="text-textColor cursor-pointer my-auto" />
                      <div>{userData?.role === 3 ? 'Admin' : 'Nutzer'}</div>
                    </div>
                  </div>
                )}
              </div>
              {isUserSwitch && (
                <div className={styles.userSwitch}>
                  <div className="relative p-25">
                    <div className="absolute right-10 top-15">
                      <XIcon
                        width={20}
                        height={20}
                        className="mr-5 my-auto text-accentColor cursor-pointer"
                        onClick={() => setIsUserSwitch(false)}
                      />
                    </div>
                    {userData?.role === 3 ? (
                      <div
                        className="flex space-x-5 pb-15 pt-15 cursor-pointer"
                        onClick={() => changeUserViewExecute()}
                        aria-hidden
                      >
                        <CheckCircleIcon width={25} height={25} className="mr-5 my-auto text-accentColor" />
                        <div className="my-auto">Admin</div>
                      </div>
                    ) : (
                      <div
                        className="flex space-x-5 pb-15 pt-15 cursor-pointer"
                        onClick={() => changeUserViewExecute()}
                        aria-hidden
                      >
                        <div className="rounded-full w-20 h-20 border-2 border-accentColor ml-1 mr-5 my-auto">
                          <></>
                        </div>
                        <div className="my-auto">
                          {userData?.role === 4 || userData?.isTrainerUser
                            ? 'Trainer'
                            : userData?.role === 6 || userData?.isLocationAdminUser
                            ? 'Leiter'
                            : 'Admin'}
                        </div>
                      </div>
                    )}

                    {userData?.role !== 3 ? (
                      <div
                        className="flex space-x-5 cursor-pointer"
                        onClick={() => changeUserViewExecute()}
                        aria-hidden
                      >
                        <CheckCircleIcon width={25} height={25} className="mr-5 my-auto text-accentColor" />
                        <div className="my-auto">Nutzer</div>
                      </div>
                    ) : (
                      <div
                        className="flex space-x-5 cursor-pointer"
                        onClick={() => changeUserViewExecute()}
                        aria-hidden
                      >
                        <div className="rounded-full w-20 h-20 border-2 border-accentColor ml-1 mr-5 my-auto">
                          <></>
                        </div>
                        <div className="my-auto">Nutzer</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <img className="h-40 object-contain" width={160} height={140} src={theme?.logo ?? LogoImg} alt="Logo" />
          )}
        </div>
      </div>

      <div className={styles.wrapper}>
        <div className={classNames(styles.menuWrapper)}>
          <div className={classNames(styles.menuList)}>
            {companyMenu.map((item, index) => (
              <div className="relative" key={`item-${index}`}>
                <Link to={item.link}>
                  <Button buttonStyle="white" className="w-full bg-secondaryBgColor" key={index}>
                    <div className="w-full flex justify-between items-center">
                      <div className="flex">
                        <p className="text-18 font-light text-notActiveButtonTextColor">{t(item.label)}</p>
                      </div>

                      <ChevronRightIcon className="text-accentColor" height={30} width={30} />
                    </div>
                  </Button>
                </Link>
              </div>
            ))}
            <div className="flex flex-wrap gap-10 justify-between">
              <div>
                <Button className="py-3 md:py-15" onClick={logOutUser}>
                  {t('Log out')}
                </Button>
              </div>
              <div>
                <Button className="py-3 md:py-15" onClick={() => setDeleteAccountPopupClass('block')}>
                  Account kündigen
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyProfile;

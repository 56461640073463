import React from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';

type Props = {
  thisValue?: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onClick?: any;
  thisRef?: any;
  submitFunction?: any;
  width?: any;
  textCenter?: boolean;
  headline?: boolean;
  lightBackground?: boolean;
  placeHolder?: string;
  type?: string;
  customPaddingY?: string;
  customPaddingX?: string;
};

function keyPress(event: any, submitFunction: any) {
  if (event.charCode === 13) {
    event.preventDefault();
    if (submitFunction !== undefined) {
      submitFunction();
    }
  }
}

const setBodyClassForMobileSafari = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    document.body.classList.add('keyboard-open');
  }
};

const removeBodyClassForMobileSafari = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    document.body.classList.remove('keyboard-open');
  }
};

const CustomUserInput = ({
  thisRef,
  submitFunction,
  thisValue,
  onChange,
  onClick,
  width,
  name,
  textCenter = true,
  headline,
  lightBackground,
  placeHolder = '',
  customPaddingY,
  customPaddingX,
  type = 'text',
}: Props) => (
  <div
    className={`rounded-md border-solid border border-textColor border-opacity-30 px-2 py-${
      customPaddingY !== undefined ? customPaddingY : '1'
    } px-${customPaddingX !== undefined ? customPaddingX : '0'}`}
  >
    {headline ? (
      <input
        name={name}
        ref={thisRef}
        className="appearance-none block text-2xl pb-5 font-semibold placeholder-gray-400 focus:outline-none text-textColor text-center bg-transparent"
        style={{ width: `${width}ch` }}
        type="text"
        onChange={onChange}
        onFocus={setBodyClassForMobileSafari}
        onBlur={removeBodyClassForMobileSafari}
        onKeyPress={e => keyPress(e, submitFunction)}
        value={thisValue}
        autoComplete="off"
      />
    ) : !textCenter && placeHolder !== undefined ? (
      <input
        name={name}
        ref={thisRef}
        className="w-full appearance-none block py-5 text-16 font-extralight placeholder-gray-400 focus:outline-none text-textColor bg-transparent"
        type={type}
        onChange={onChange}
        onFocus={setBodyClassForMobileSafari}
        onBlur={removeBodyClassForMobileSafari}
        onKeyPress={e => keyPress(e, submitFunction)}
        value={thisValue}
        autoComplete="off"
        placeholder={placeHolder}
      />
    ) : (
      <input
        name={name}
        ref={thisRef}
        className={classNames(lightBackground ? styles.customUserInputLight : styles.customUserInput)}
        style={{ width: `${width}ch` }}
        type="text"
        onChange={onChange}
        onFocus={setBodyClassForMobileSafari}
        onBlur={removeBodyClassForMobileSafari}
        onClick={onClick}
        onKeyPress={e => keyPress(e, submitFunction)}
        inputMode="decimal"
        value={thisValue}
        autoComplete="off"
      />
    )}
  </div>
);
export default CustomUserInput;

import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import firebase from 'services/firebase';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toast';
import Icon from 'components/Icon';
import { AuthContext } from 'providers/AuthProvider';
import LogoImg from 'assets/img/svg/logo.svg';
import { getMobileOperatingSystem } from 'shared/functions/global';
import Container from 'components/Container';

import styles from './styles.module.scss';

const PurchaseConnectStatus = ({ clientSecret }: { clientSecret: string | null }) => {
  const userDomain = window.location.hostname;
  const db = firebase.firestore();
  const elements = useElements();
  const history = useHistory();
  const stripe = useStripe();
  const [status, setStatus] = useState('');
  const [subscriptionId, setSubscribtionId] = useState('');
  const [paymentIntendId, setPaymentIntendId] = useState('');
  const { theme, setTheme } = useContext(AuthContext);

  const logo = theme?.logo ?? LogoImg;

  const handleOnSuccess = async () => {
    if (!stripe || !elements) {
      return;
    }

    if (localStorage.getItem('tenant') !== undefined) {
      if (clientSecret?.startsWith('seti_')) {
        try {
          await axios({
            url: `${process.env.REACT_APP_API_URL}/updateUserDefaultPaymentMethod`,
            method: 'post',
            data: {
              stripeAccountId: localStorage.getItem('accountId'),
              customerId: localStorage.getItem('customerId'),
            },
            headers: {
              'content-type': 'application/json',
              Accept: 'application/json',
            },
          }).then(
            response => {
              console.log(response);
            },
            error => {
              console.log(error);
            }
          );
        } catch (error) {
          console.log('error: ', error);
        }
      }

      try {
        await firebase
          .auth()
          .createUserWithEmailAndPassword(localStorage.getItem('userMail')!, localStorage.getItem('userPw')!)
          .then(async userCredential => {
            const collection = `tenants/${localStorage.getItem('tenant')!}/users`;

            await userCredential.user?.updateProfile({
              displayName: localStorage.getItem('tenant'),
            });

            await db
              .collection(collection)
              .doc(userCredential.user?.uid)
              .set({
                email: localStorage.getItem('userMail'),
                fullName: localStorage.getItem('name'),
                gymID: '',
                role: 5,
                userAgent: getMobileOperatingSystem(),
                profileComplete: false,
                cookieBannerType: 'initial',
                created: firebase.firestore.FieldValue.serverTimestamp(),
                withCouponCode: false,
                membership: {
                  id: subscriptionId,
                  activated: true,
                  payerId: paymentIntendId,
                  stripeCustomerId: localStorage.getItem('customerId'),
                  nextDate: moment().unix(),
                  paymentMethod: 'stripeConnect',
                  type: 'pro',
                },
              });
          })
          .catch(error => {
            console.error(error.message);
            toast.error('5');
            toast.error(error.message);
          });

        // Send Mail to user
        let invitationMailText = `Hallo ${localStorage.getItem(
          'name'
        )!},\n\nvielen Dank für deine Registrieung bei der Ernährungssoftware von ${localStorage.getItem(
          'companyName'
        )!}.\n\nDeine Zahlung war erfolgreich und du kannst jetzt starten.\n\nBitte klicke auf den folgenden Link, um weitere Informationen zu erhalten und die Links zum Download der App zu bekommen:\n${
          localStorage.getItem('invitationLink') !== undefined
            ? localStorage.getItem('invitationLink')!
            : 'https://whitelabel.tortija.de/einladung/'
        }\n\nViel Spaß mit unserer Ernährungssoftware!\n\nDein Team von ${localStorage.getItem('companyName')!}`;

        if (localStorage.getItem('mailText') !== undefined && localStorage.getItem('mailText')!.length > 0) {
          invitationMailText = `Hallo ${localStorage.getItem('name')!},\n\n${localStorage.getItem('mailText')!}`;
        }

        const adminMailText = `Neue Registrierung über Tortija Billing:\n\nName: ${localStorage.getItem(
          'name'
        )!}\n\nEmail: ${localStorage.getItem('userMail')!}`;

        axios({
          url: `${process.env.REACT_APP_API_URL}/sendTextMail`,
          method: 'post',
          data: {
            mailSubject:
              localStorage.getItem('mailSubject') !== undefined && localStorage.getItem('mailSubject')!.length > 0
                ? localStorage.getItem('mailSubject')
                : `${localStorage.getItem('companyName')!} Registrierung`,
            mailTo: localStorage.getItem('userMail'),
            mailText: invitationMailText,
            mailFrom:
              localStorage.getItem('mailFrom') !== undefined && localStorage.getItem('mailFrom')!.length > 0
                ? localStorage.getItem('mailFrom')
                : 'info@tortija.de',
            mailName:
              localStorage.getItem('mailName') !== undefined && localStorage.getItem('mailName')!.length > 0
                ? localStorage.getItem('mailName')
                : '',
          },
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        }).then(
          response => {
            console.log(response);
          },
          error => {
            console.log(error);
          }
        );

        if (localStorage.getItem('adminMail') !== undefined) {
          axios({
            url: `${process.env.REACT_APP_API_URL}/sendCustomTextMail`,
            method: 'post',
            data: {
              mailSubject: `Tortija Billing: Neue Registrierung`,
              mailTo: localStorage.getItem('adminMail'),
              mailText: adminMailText,
              mailFrom: 'info@tortija.de',
            },
            headers: {
              'content-type': 'application/json',
              Accept: 'application/json',
            },
          }).then(
            response => {
              console.log(response);
            },
            error => {
              console.log(error);
            }
          );
        }

        history.push('/store-landingpage');
      } catch (error) {
        history.push('/store-landingpage');
        console.log('error: ', error);
      }
    }
  };

  useEffect(() => {
    if (status === 'succeeded' && subscriptionId && paymentIntendId) {
      if (localStorage.getItem('tenant') !== undefined) {
        toast.success('Deine Zahlung wurde erfolgreich durchgeführt. Vielen Dank!');
        handleOnSuccess();
      } else {
        toast.error('Es ist leider ein Fehler aufgetreten. Bitte versuche es später erneut1!');
      }
    }
    if (status === 'processing' && subscriptionId && paymentIntendId) {
      if (localStorage.getItem('tenant') !== undefined) {
        toast.success('Deine Zahlung wurde erfolgreich durchgeführt. Vielen Dank!');
        handleOnSuccess();
      } else {
        toast.error('Es ist leider ein Fehler aufgetreten. Bitte versuche es später erneut2!');
      }
    }
    if (status === 'requires_payment_method') {
      toast.error('Deine Zahlung ist fehlgeschlagen. Bitte versuche es mit einer anderen Zahlungsmethode.3');
      history.push(`/company-register/${localStorage.getItem('tenant')!}`);
    }
  }, [status, subscriptionId]);

  useEffect(() => {
    // search for customDomain inside tenants which is equal to userDomain
    firebase
      .firestore()
      .collection('customDomain-infos')
      .where('customDomain', '==', userDomain)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          const tenantInfo = querySnapshot.docs[0].data();
          setTheme(tenantInfo?.theme as TenantTheme);
        }
      });
  }, []);

  if (stripe) {
    if (clientSecret?.startsWith('pi_')) {
      // Handling for PaymentIntent
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        if (paymentIntent?.id !== undefined) {
          setPaymentIntendId(paymentIntent.id);
        }
        setStatus(paymentIntent?.status!);
        setSubscribtionId(localStorage.getItem('subscriptionId')!);
      });
    } else if (clientSecret?.startsWith('seti_')) {
      // Handling for SetupIntent
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
        if (setupIntent?.id !== undefined) {
          setPaymentIntendId(setupIntent.id);
        }
        setStatus(setupIntent?.status!);
        setSubscribtionId(localStorage.getItem('subscriptionId')!);
      });
    }
  }

  return (
    <Container>
      <div className="fixed h-screen w-screen left-0 top-0 bg-bgColor z-50 flex flex-col items-center justify-center">
        <img width={200} src={logo} alt="" />
        <div className="font-bold pt-30">
          <div className="text-center">Deine Zahlung wird bearbeitet...</div>
          <div className="pt-10 text-center">Dieser Vorgang kann unter Umständen auch etwas länger dauern.</div>
          <div className="pt-10 text-center">Bitte schließe dieses Fenster nicht. Du wirst gleich weitergeleitet.</div>
        </div>
      </div>
    </Container>
  );
};

export default PurchaseConnectStatus;

import React, { useState, useRef, useEffect, useContext } from 'react';
import {
  PlusIcon,
  DotsVerticalIcon,
  XIcon,
  DocumentDuplicateIcon,
  RefreshIcon,
  PlusCircleIcon,
  CogIcon,
  SwitchHorizontalIcon,
  CalendarIcon,
} from '@heroicons/react/outline';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { handleOpenClosePopups, savePlanChangesToFirebase } from 'shared/functions/global';
import { AuthContext } from 'providers/AuthProvider';
import { toast } from 'react-toast';
import DatePicker from 'react-datepicker';
import Card from 'components/Card';
import moment from 'moment';
import Checkbox from 'components/Checkbox';
import EditMealItem from '../EditMealItem';
import EditManualItem from '../EditManualItem';
import styles from './styles.module.scss';

type Props = {
  thisId?: number;
  label?: string;
  mealType?: string;
  planObject?: any;
  planState?: any;
  planStateValue?: any;
  dayId?: number;
  planDateArray?: any;
  copyItemState?: any;
  popupDynamicAddCopyClass?: any;
  addItemState?: any;
  newRecipeOverlayClass?: any;
  currentRecipeStep?: any;
  scrollRef?: any;
  openIngredientSearchPopupClass?: any;
  openRecipePopupClass?: any;
  setMealType?: any;
  moveIngredientPopupClass?: any;
  moveRecipePopupClass?: any;
  copyRecipePopupClass?: any;
  setCurrentChangeItemId?: any;
  copyIngredientPopupClass?: any;
  openGenerateMealPopupClass?: any;
};

const EditMealCard: React.FC<Props> = ({
  label,
  mealType = '',
  planObject,
  planState,
  planStateValue,
  dayId,
  planDateArray,
  copyItemState,
  popupDynamicAddCopyClass,
  addItemState,
  newRecipeOverlayClass,
  currentRecipeStep,
  scrollRef,
  openIngredientSearchPopupClass,
  openRecipePopupClass,
  setMealType,
  moveIngredientPopupClass,
  moveRecipePopupClass,
  copyRecipePopupClass,
  setCurrentChangeItemId,
  copyIngredientPopupClass,
  openGenerateMealPopupClass,
}) => {
  const { userData, tenantData } = useContext(AuthContext);
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();

  const [actionClass, setActionClass] = useState('hidden');

  const [currentMoveMealTypeValue, setCurrentMoveMealTypeValue] = useState('breakfast');

  const [currentCopyMealTypeValue, setCurrentCopyMealTypeValue] = useState('breakfast');
  const [startDate, setStartDate] = useState(null);
  const changeCopyDate = (dates: any) => {
    setStartDate(dates);
  };
  const CustomInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <div className="flex w-full">
          <div className="flex-1">
            <input {...props} />
          </div>
          <div className="ml-10 my-auto">
            <CalendarIcon width={25} height={25} className="text-accentColor mx-auto cursor-pointer" />
          </div>
        </div>
      </label>
    );
  };
  const [currentCopyTypeValue, setCurrentCopyTypeValue] = useState('date');

  const [currentId, setCurrentId] = useState(0);
  const [currentType, setCurrentType] = useState('');

  const mealTypeValues: any = [
    { value: 'breakfast', label: 'Frühstück' },
    { value: 'lunch', label: 'Mittagessen' },
    { value: 'dinner', label: 'Abendessen' },
    { value: 'snacks', label: 'Snacks' },
  ];

  const copyTypeValues: any = [
    { value: 'date', label: 'Auf ein bestimmtes Datum kopieren' },
    { value: 'all', label: 'In jeden Tag kopieren' },
  ];

  function copyMeal() {
    copyItemState(planObject?.[mealType]);
    popupDynamicAddCopyClass('block');
    setActionClass('hidden');
  }

  function generateMeal() {
    setMealType(mealType);
    openGenerateMealPopupClass('meal');
    setActionClass('hidden');
  }

  function getCurrentMealTotal(makroType: string, thisMealType: string) {
    const thisItem = planStateValue?.dayEntries?.filter((item: any) => item.id === dayId)[0][thisMealType];

    if (makroType === 'kcal') {
      return Math.round(parseFloat(thisItem?.kcal_total));
    }
    if (makroType === 'carbohydrates') {
      return Math.round(parseFloat(thisItem?.carbohydrates_total));
    }
    if (makroType === 'protein') {
      return Math.round(parseFloat(thisItem?.protein_total));
    }
    if (makroType === 'fat') {
      return Math.round(parseFloat(thisItem?.fat_total));
    }

    return '0';
  }

  function addNewMealRecipe() {
    const calculatedKcalTotalFromIngredients = planObject?.[mealType].ingredients.reduce((prev: any, current: any) => {
      return parseFloat(prev) + parseFloat(current.kcal_total);
    }, 0);

    const calculatedCarbohydratesTotalFromIngredients = planObject?.[mealType].ingredients.reduce(
      (prev: any, current: any) => {
        return parseFloat(prev) + parseFloat(current.carbohydrates_total);
      },
      0
    );

    const calculatedProteinTotalFromIngredients = planObject?.[mealType].ingredients.reduce(
      (prev: any, current: any) => {
        return parseFloat(prev) + parseFloat(current.protein_total);
      },
      0
    );

    const calculatedFatTotalFromIngredients = planObject?.[mealType].ingredients.reduce((prev: any, current: any) => {
      return parseFloat(prev) + parseFloat(current.fat_total);
    }, 0);

    setMealType(mealType);
    currentRecipeStep('1');
    addItemState({
      ingredients: [...planObject?.[mealType].ingredients],
      description: [],
      kcal_total: calculatedKcalTotalFromIngredients,
      carbohydrates_total: calculatedCarbohydratesTotalFromIngredients,
      protein_total: calculatedProteinTotalFromIngredients,
      fat_total: calculatedFatTotalFromIngredients,
      flexitarian: true,
      vegetarian: false,
      ketogen: false,
      vegan: false,
    });

    newRecipeOverlayClass('block');

    setActionClass('hidden');
  }

  function openSearchPopup(thisMealType: string) {
    setMealType(thisMealType);
    openIngredientSearchPopupClass('block');
  }

  return (
    <>
      <div className="rounded-3xl min-h-400 md:min-h-0 mx-0  mb-30 p-20 shadow-2xl bg-lightGray relative">
        <div className="flex justify-between">
          <div className="font-semibold  text-22">{planObject?.[mealType].label}</div>
          <div className="my-auto flex justify-between gap-5">
            <div id="addItem">
              <PlusIcon
                width={28}
                height={28}
                className="text-accentColor cursor-pointer"
                onClick={() => openSearchPopup(mealType)}
              />
            </div>
            <div className="relative">
              <div id="actionItem">
                <DotsVerticalIcon
                  width={28}
                  height={28}
                  className="text-accentColor cursor-pointer"
                  onClick={() => setActionClass('block')}
                />
              </div>
              <div
                className={`absolute bg-lightGray shadow-2xl rounded-xl top-40 w-300 border z-100 border-accentColor transition-opacity duration-1000 ${actionClass} ${styles.actionsMenu}`}
              >
                <div className="pb-20 relative font-extralight">
                  <div className="flex justify-between px-20 pt-20 pb-20">
                    <div className="flex">
                      <div className="my-auto pr-10">
                        <CogIcon width={25} height={25} className="text-accentColor mx-auto" />
                      </div>
                      <div className="text-xl my-auto font-light">Actions-Menü</div>
                    </div>

                    <div>
                      <XIcon
                        width={25}
                        height={25}
                        className="text-accentColor cursor-pointer"
                        onClick={() => setActionClass('hidden')}
                      />
                    </div>
                  </div>
                  {(userData?.role === 2 ||
                    tenantData?.settings?.functions?.recipeGenerator === undefined ||
                    userData?.isAdminUser ||
                    userData?.isTrainerUser ||
                    userData?.isLocationAdminUser ||
                    localStorage.getItem('currentUserToken') ||
                    tenantData?.settings?.functions?.recipeGenerator === true) && (
                    <div className="border-transparent border-2 hover:border-accentColor rounded-lg mx-20">
                      <div
                        className="flex justify-between border-b py-7 border-blackSemiDark"
                        onClick={() => generateMeal()}
                        onMouseDown={() => generateMeal()}
                        aria-hidden="true"
                      >
                        <div className=" cursor-pointer px-7">Diese Mahlzeit neu generieren</div>
                        <div>
                          <RefreshIcon width={25} height={25} className="text-accentColor mr-10" />
                        </div>
                      </div>
                    </div>
                  )}
                  {planObject?.[mealType].ingredients.length > 0 || planObject?.[mealType].recipes.length > 0 ? (
                    <div className="border-transparent border-2 hover:border-accentColor rounded-lg mx-20">
                      <div
                        className="flex justify-between border-b py-7 border-blackSemiDark cursor-pointer"
                        onClick={() => copyMeal()}
                        onMouseDown={() => copyMeal()}
                        aria-hidden="true"
                      >
                        <div className=" cursor-pointer px-7">Diese Mahlzeit kopieren</div>
                        <div>
                          <DocumentDuplicateIcon width={25} height={25} className="text-accentColor mr-10" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {planObject?.[mealType].ingredients.length > 0 && (
                    <div className="border-transparent border-2 hover:border-accentColor rounded-lg mx-20">
                      <div
                        className="flex justify-between py-7 cursor-pointer"
                        onClick={() => addNewMealRecipe()}
                        onMouseDown={() => addNewMealRecipe()}
                        aria-hidden="true"
                      >
                        <div className=" cursor-pointer px-7">Rezept aus Lebensmitteln erstellen</div>
                        <div>
                          <PlusCircleIcon width={25} height={25} className="text-accentColor mr-10" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-20 pb-10">
          <div className="flex w-full gap-4">
            <div className="flex-1 text-center">
              <div className="font-semibold text-base">{getCurrentMealTotal('kcal', mealType)}</div>
              <div className={styles.recipeLabel}>kcal</div>
            </div>
            <div className="flex-1 text-center">
              <div className="font-semibold text-base">{getCurrentMealTotal('carbohydrates', mealType)}g</div>
              <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
            </div>
            <div className="flex-1 text-center">
              <div className="font-semibold text-base">{getCurrentMealTotal('protein', mealType)}g</div>
              <div className={styles.recipeLabel}>{t('Protein')}</div>
            </div>
            <div className="flex-1 text-center">
              <div className="font-semibold text-base">{getCurrentMealTotal('fat', mealType)}g</div>
              <div className={styles.recipeLabel}>{t('Fat')}</div>
            </div>
          </div>
        </div>
        <div className="pt-10">
          {planObject?.[mealType].ingredients.length === 0 &&
            planObject?.[mealType].recipes.length === 0 &&
            planObject?.[mealType].manualItems?.length === 0 && (
              <div
                onClick={() => openSearchPopup(mealType)}
                onKeyDown={() => openSearchPopup(mealType)}
                aria-hidden="true"
              >
                <Card
                  isWithoutImage
                  className="border-transparent border-2 hover:border-accentColor cursor-pointer h-220"
                >
                  <div className="absolute top-10 left-20 opacity-100 font-semibold text-17">{label}</div>
                  <div className={styles.addIcon}>
                    <div className="pt-20">
                      <PlusIcon width={42} height={42} className="text-accentColor cursor-pointer mx-auto" />
                    </div>
                    <div className="text-center font-semibold text-18 px-20 pt-15">Hinzufügen</div>
                  </div>
                </Card>
              </div>
            )}
          {planObject?.[mealType].ingredients.map((item: any, index: number) => (
            <EditMealItem
              key={index}
              itemObject={item}
              planState={planState}
              planStateValue={planStateValue}
              type="Lebensmittel"
              dayId={dayId}
              mealType={mealType}
              moveRecipePopupOpener={moveRecipePopupClass}
              copyRecipePopupOpener={copyRecipePopupClass}
              itemChangeIdState={setCurrentId}
              itemChangeTypeState={setCurrentType}
              scrollRef={scrollRef}
              moveIngredientPopupClass={moveIngredientPopupClass}
              copyIngredientPopupClass={copyIngredientPopupClass}
              setMealType={setMealType}
              setCurrentChangeItemId={setCurrentChangeItemId}
              openRecipePopupClass={openRecipePopupClass}
              isLastObject={index + 1 === planObject?.[mealType].ingredients.length}
            />
          ))}
          {planObject?.[mealType].recipes.map((item: any, index: number) => (
            <EditMealItem
              key={index}
              itemObject={item}
              planState={planState}
              planStateValue={planStateValue}
              type="Rezept"
              dayId={dayId}
              mealType={mealType}
              moveRecipePopupOpener={moveRecipePopupClass}
              copyRecipePopupOpener={copyRecipePopupClass}
              itemChangeIdState={setCurrentId}
              itemChangeTypeState={setCurrentType}
              scrollRef={scrollRef}
              setMealType={setMealType}
              setCurrentChangeItemId={setCurrentChangeItemId}
              openRecipePopupClass={openRecipePopupClass}
              isLastObject={index + 1 === planObject?.[mealType].recipes.length}
            />
          ))}
          {planObject?.[mealType].manualItems?.map((item: any, index: number) => (
            <EditManualItem
              key={index}
              itemObject={item}
              planState={planState}
              planStateValue={planStateValue}
              type="Rezept"
              dayId={dayId}
              mealType={mealType}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default EditMealCard;

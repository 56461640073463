/* eslint-disable react/no-danger */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { useForm } from 'react-hook-form';
import Input from 'components/Input';
import axios from 'axios';
import firebase from 'services/firebase';
import { useParams, Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { toast } from 'react-toast';
import { loadStripe } from '@stripe/stripe-js/pure';
import { emailChecker, passwordChecker } from 'services/validations';
import ReactLoading from 'react-loading';
import { useTranslation } from 'react-i18next';
import { defaultTextColor } from 'layouts/Theme';
import { BookOpenIcon } from '@heroicons/react/outline';
import LogoImg from 'assets/img/svg/logo.svg';
import Overlay from 'components/Overlay';
import Button from 'components/Button';
import CheckoutFormCustomerBilling from '../payment/checkout';
import styles from './styles.module.scss';

type ParamsType = {
  tenant: string;
};

const CustomerBillingRegister: React.FC = () => {
  const { t } = useTranslation();
  const { tenant } = useParams<ParamsType>();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [paymentIntent, setPaymentIntent] = useState<any>(null);
  const [stripePromise, setStripePromise] = useState<any>(null);

  const [currentStep, setCurrentStep] = useState(1);
  const { theme, setTheme } = useContext(AuthContext);
  const [pageLoadedAndVerified, setPageLoadedAndVerified] = useState(false);
  const [stripeConnectData, setStripeConnectData] = useState<any>({});
  const [tenantData, setTenantData] = useState<any>({});
  const [verifyStatus, setVerifyStatus] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [openLegalPopupClass, setOpenLegalPopupClass] = useState('hidden');
  const [legalType, setLegalType] = useState('');
  const logo = theme?.logo ?? LogoImg;

  const [currentName, setCurrentName] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');

  const password = useRef({});
  password.current = watch('password', '');

  useEffect(() => {
    const getData = async () => {
      const getTenantData = (await firebase.firestore().collection('customDomain-infos').doc(tenant).get()).data();
      if (getTenantData === undefined) {
        setPageLoadedAndVerified(true);
      } else {
        if (
          getTenantData?.stripeConnect?.stripeConnectAccountId !== undefined &&
          getTenantData?.stripeConnect?.stripeConnectMonthlyFee !== undefined &&
          getTenantData?.stripeConnect?.stripeConnectPriceId !== undefined
        ) {
          setTheme(getTenantData?.theme as TenantTheme);
          setStripeConnectData(getTenantData?.stripeConnect);
          setTenantData(getTenantData);

          setStripePromise(
            loadStripe(
              process.env.REACT_APP_STRIPE_PUBLICKEY ?? '',
              { stripeAccount: getTenantData?.stripeConnect?.stripeConnectAccountId } ?? ''
            )
          );

          setVerifyStatus(true);
        }

        setPageLoadedAndVerified(true);
      }
    };

    if (pageLoadedAndVerified === false) {
      getData();
      localStorage.removeItem('name');
      localStorage.removeItem('accountId');
      localStorage.removeItem('userMail');
      localStorage.removeItem('userPw');
    }
  }, []);

  const onSubmit = async (data: any) => {
    setIsPending(true);
    setCurrentName(data.fullName);
    setCurrentEmail(data.email);
    setCurrentPassword(data.password);

    const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(data.email);

    if (signInMethods.length > 0) {
      setIsPending(false);
      toast.error('Unter dieser Email-Adresse existiert bereits ein Nutzer!');
    } else {
      const res = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/subscribeConnect`, {
        user: { email: data.email, name: data.fullName },
        planID: stripeConnectData?.stripeConnectPriceId,
        stripeAccountId: stripeConnectData?.stripeConnectAccountId,
        taxId: stripeConnectData?.stripeConnectTaxId,
        smallCompany: stripeConnectData?.stripeConnectIsSmallCompany,
        trialDays: stripeConnectData?.stripeConnectTrialDays ?? 0,
        footer: stripeConnectData?.stripeConnectIsSmallCompany
          ? 'Gemäß § 19 UStG wird keine Umsatzsteuer berechnet.'
          : '',
      });

      setPaymentIntent(res.data);

      localStorage.setItem('subscriptionId', res.data.subscriptionId);
      localStorage.setItem('customerId', res.data.customerId);

      setIsPending(false);

      setCurrentStep(2);
    }
  };

  const openLegalInfo = (thisLegalType: string) => {
    setLegalType(thisLegalType);
    setOpenLegalPopupClass('block');
  };

  return (
    <>
      <Overlay
        overlayClass={openLegalPopupClass}
        setOverlayClass={setOpenLegalPopupClass}
        headline={
          legalType === 'imprint'
            ? 'Impressum'
            : legalType === 'dataPrivacy'
            ? 'Datenschutzerklärung'
            : 'Nutzungsbedingungen'
        }
        icon={<BookOpenIcon width={25} height={25} className="text-accentColor mx-auto" />}
        fullSize
      >
        <div className="pb-50">
          <div className="pt-30 font-extralight text-base">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  legalType === 'imprint'
                    ? tenantData?.legal?.imprintText.replace(/(?:\r\n|\r|\n)/g, '<br />')
                    : legalType === 'dataPrivacy'
                    ? tenantData?.legal?.privacyPolicyText.replace(/(?:\r\n|\r|\n)/g, '<br />')
                    : tenantData?.legal?.termsOfUseText.replace(/(?:\r\n|\r|\n)/g, '<br />'),
              }}
            />
          </div>
        </div>
      </Overlay>

      {pageLoadedAndVerified ? (
        <>
          {verifyStatus === false ? (
            <div className="font-bold text-30 text-center pt-40">Dieses Formular ist aktuell nicht verfügbar</div>
          ) : (
            <div className="bg-bgColor mb-100 mx-20 text-textColor m-auto mt-11 flex flex-col items-center">
              <img width={160} src={logo} alt="Logo" />
              <h1 className="font-bold pt-60 text-35 desktop:w-1/2 desktop:px-20 text-center">
                {stripeConnectData?.stripeConnectRegisterHeadline !== undefined
                  ? stripeConnectData?.stripeConnectRegisterHeadline
                  : 'Registrierung'}
              </h1>
              <div className="font-extralight text-textColor text-20 pt-30 desktop:w-1/2 desktop:px-20 text-center">
                {stripeConnectData?.stripeConnectRegisterText !== undefined ? (
                  <div
                    className="font-extralight"
                    dangerouslySetInnerHTML={{
                      __html: stripeConnectData?.stripeConnectRegisterText.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                    }}
                  />
                ) : (
                  'Bitte füllen Sie die Felder aus, um sich bei uns zu registrieren.'
                )}
              </div>
              <div className="py-40 w-full desktop:w-1/3">
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                  {currentStep === 1 ? (
                    <>
                      <Input
                        name="fullName"
                        className="mb-20"
                        type="text"
                        label="Vollständiger Name"
                        autoComplete="given-name"
                        required
                        register={register('fullName', {
                          validate: (value: string) => {
                            if (!value.trim()) {
                              return t('Please enter your full name').toString();
                            }
                            return true;
                          },
                        })}
                        error={errors.fullName}
                      />
                      <Input
                        name="email"
                        className="mb-20"
                        type="text"
                        label="E-Mail"
                        autoComplete="email"
                        required
                        register={register('email', {
                          validate: (value: string) => {
                            if (!value.trim()) {
                              return t('Email Address is required').toString();
                            }
                            return emailChecker(value) || t('Please enter valid email address').toString();
                          },
                        })}
                        error={errors.email}
                      />
                      <Input
                        name="password"
                        type="password"
                        className="mb-20"
                        label={t('Password')}
                        autoComplete=""
                        required
                        register={register('password', {
                          minLength: {
                            value: 8,
                            message: 'Gebe mindestens 1 Großbuchstabe, 1 Zahl und 8 Zeichen ein.',
                          },
                          required: t('Please enter your password').toString(),
                          validate: (value: string) =>
                            passwordChecker(value) || t('Password must contain at least 1 number').toString(),
                        })}
                        error={errors.password}
                      />
                      <Input
                        name="confirm-password"
                        type="password"
                        label={t('Confirm password')}
                        className="mb-20"
                        autoComplete=""
                        register={register('confirm-password', {
                          required: t('Please confirm your password').toString(),
                          validate: (value: string) =>
                            value === password.current || t('Password and Confirm password do not match').toString(),
                        })}
                        error={errors['confirm-password']}
                      />
                      <div>
                        <Button isPending={isPending} disabled={isPending} className="w-full mt-40">
                          <span>Zum nächsten Schritt</span>
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.link} onClick={() => setCurrentStep(1)} aria-hidden="true">
                        Zurück
                      </div>
                      <div className="pb-10">
                        <div className="font-bold text-25 pt-10">
                          <span className="pr-10">Monatsbeitrag:</span>{' '}
                          {stripeConnectData.stripeConnectMonthlyFee.toString().replaceAll('.', ',')} €
                          {stripeConnectData.stripeConnectTrialDays !== undefined && (
                            <div>
                              <div className="font-bold text-25 pt-5">
                                <span className="pr-10">Kostenloser Testzeitraum:</span>{' '}
                                {stripeConnectData.stripeConnectTrialDays} Tage
                              </div>
                              <div className="font-extralight text-12">
                                Dein Konto wird zum ersten mal nach {stripeConnectData.stripeConnectTrialDays} Tagen
                                belastet.
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="pt-10">Dieses Abonnement kann monatlich gekündigt werden.</div>
                      </div>
                      {(paymentIntent?.clientSecret.payment_intent?.client_secret !== undefined ||
                        (paymentIntent?.clientSecret !== undefined &&
                          paymentIntent?.clientSecret?.includes('seti'))) && (
                        <Elements
                          key={stripeConnectData.stripeConnectPriceId}
                          stripe={stripePromise}
                          options={{
                            clientSecret: (() => {
                              if (typeof paymentIntent?.clientSecret === 'string') {
                                return paymentIntent.clientSecret.includes('seti')
                                  ? paymentIntent.clientSecret
                                  : paymentIntent.clientSecret.payment_intent?.client_secret;
                              }
                              return paymentIntent?.clientSecret?.payment_intent?.client_secret;
                            })(),
                            appearance: {
                              theme: 'stripe',
                              labels: 'floating',
                            },
                            locale: 'de',
                            loader: 'always',
                          }}
                        >
                          <CheckoutFormCustomerBilling
                            clientSecret={(() => {
                              if (typeof paymentIntent?.clientSecret === 'string') {
                                return paymentIntent.clientSecret.includes('seti')
                                  ? paymentIntent.clientSecret
                                  : paymentIntent.clientSecret.payment_intent?.client_secret;
                              }
                              return paymentIntent?.clientSecret?.payment_intent?.client_secret;
                            })()}
                            connectData={stripeConnectData}
                            userMail={currentEmail}
                            userName={currentName}
                            userPassword={currentPassword}
                            businessName={tenantData.company.companyName}
                            tenant={tenant}
                            adminMail={
                              stripeConnectData.stripeConnectRegisterEmail !== undefined
                                ? stripeConnectData.stripeConnectRegisterEmail
                                : tenantData?.company?.email
                            }
                            invitationLink={
                              tenantData?.invitationLink !== undefined
                                ? tenantData?.invitationLink
                                : 'https://whitelabel.tortija.de/einladung/'
                            }
                            mailSubject={
                              stripeConnectData?.stripeConnectMailSubject !== undefined &&
                              stripeConnectData?.stripeConnectMailSubject.length > 0
                                ? stripeConnectData?.stripeConnectMailSubject
                                : `${tenantData?.company?.companyName} Einladung`
                            }
                            mailFrom={
                              stripeConnectData?.stripeConnectMailFrom !== undefined &&
                              stripeConnectData?.stripeConnectMailFrom?.length > 0
                                ? stripeConnectData?.stripeConnectMailFrom
                                : 'info@tortija.de'
                            }
                            mailName={
                              stripeConnectData?.stripeConnectMailName !== undefined &&
                              stripeConnectData?.stripeConnectMailName?.length > 0
                                ? stripeConnectData?.stripeConnectMailName
                                : ''
                            }
                            mailText={
                              stripeConnectData?.stripeConnectMailText !== undefined &&
                              stripeConnectData?.stripeConnectMailText?.length > 0
                                ? stripeConnectData?.stripeConnectMailText
                                : ''
                            }
                          />
                        </Elements>
                      )}
                    </>
                  )}
                </form>
                <div className="text-center text-14 pt-20">
                  {tenantData?.legal !== undefined ? (
                    <>
                      {tenantData.customDomain !== undefined && tenantData.customDomain === window.location.hostname ? (
                        <>
                          {tenantData?.legal?.termsOfService && tenantData?.legal?.noWebsite === false ? (
                            <Link
                              to={{
                                pathname: tenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                              }}
                              target="_blank"
                              className={styles.link}
                            >
                              Widerrufsrecht
                            </Link>
                          ) : tenantData?.legal?.termsOfUseText ? (
                            <>
                              <div className="cursor-pointer">
                                <div className={styles.link} onClick={() => openLegalInfo('agb')} aria-hidden="true">
                                  Widerrufsrecht
                                </div>
                              </div>
                            </>
                          ) : (
                            <Link
                              to={{
                                pathname: tenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                              }}
                              target="_blank"
                              className={styles.link}
                            >
                              Widerrufsrecht
                            </Link>
                          )}
                        </>
                      ) : (
                        <>
                          {tenantData?.legal?.termsOfService && tenantData?.legal?.noWebsite === false ? (
                            <Link
                              to={{
                                pathname: tenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                              }}
                              target="_blank"
                              className={styles.link}
                            >
                              Widerrufsrecht
                            </Link>
                          ) : tenantData?.legal?.termsOfUseText ? (
                            <>
                              <div className="cursor-pointer">
                                <div className={styles.link} onClick={() => openLegalInfo('agb')} aria-hidden="true">
                                  Widerrufsrecht
                                </div>
                              </div>
                            </>
                          ) : (
                            <Link
                              to={{
                                pathname: tenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                              }}
                              target="_blank"
                              className={styles.link}
                            >
                              Widerrufsrecht
                            </Link>
                          )}
                        </>
                      )}
                    </>
                  ) : window.location.hostname === 'business.tortija.de' ? (
                    <Link
                      to={{ pathname: 'https://whitelabel.tortija.de/agb/' }}
                      target="_blank"
                      className={styles.link}
                    >
                      Widerrufsrecht
                    </Link>
                  ) : (
                    <Link to={{ pathname: 'https://www.tortija.de/agb/' }} target="_blank" className={styles.link}>
                      Widerrufsrecht
                    </Link>
                  )}
                </div>
              </div>
              <div className="pt-60 flex flex-wrap gap-15 desktop:space-x-30">
                <div>
                  {tenantData?.legal !== undefined ? (
                    <>
                      {tenantData.customDomain !== undefined && tenantData.customDomain === window.location.hostname ? (
                        <>
                          {tenantData?.legal?.imprint && tenantData?.legal?.noWebsite === false ? (
                            <Link
                              to={{
                                pathname: tenantData?.legal?.imprint ?? 'https://whitelabel.tortija.de/impressum/',
                              }}
                              target="_blank"
                              className={styles.link}
                            >
                              Impressum
                            </Link>
                          ) : tenantData?.legal?.imprintText ? (
                            <>
                              <div className="cursor-pointer">
                                <div
                                  className={styles.link}
                                  onClick={() => openLegalInfo('imprint')}
                                  aria-hidden="true"
                                >
                                  Impressum
                                </div>
                              </div>
                            </>
                          ) : (
                            <Link
                              to={{
                                pathname: tenantData?.legal?.imprint ?? 'https://whitelabel.tortija.de/impressum/',
                              }}
                              target="_blank"
                              className={styles.link}
                            >
                              Impressum
                            </Link>
                          )}
                        </>
                      ) : (
                        <>
                          {tenantData?.legal?.imprint && tenantData?.legal?.noWebsite === false ? (
                            <Link
                              to={{
                                pathname: tenantData?.legal?.imprint ?? 'https://whitelabel.tortija.de/impressum/',
                              }}
                              target="_blank"
                              className={styles.link}
                            >
                              Impressum
                            </Link>
                          ) : tenantData?.legal?.imprintText ? (
                            <>
                              <div className="cursor-pointer">
                                <div
                                  className={styles.link}
                                  onClick={() => openLegalInfo('imprint')}
                                  aria-hidden="true"
                                >
                                  Impressum
                                </div>
                              </div>
                            </>
                          ) : (
                            <Link
                              to={{
                                pathname: tenantData?.legal?.imprint ?? 'https://whitelabel.tortija.de/impressum/',
                              }}
                              target="_blank"
                              className={styles.link}
                            >
                              Impressum
                            </Link>
                          )}
                        </>
                      )}
                    </>
                  ) : window.location.hostname === 'business.tortija.de' ? (
                    <Link
                      to={{ pathname: 'https://whitelabel.tortija.de/impressum/' }}
                      target="_blank"
                      className={styles.link}
                    >
                      Impressum
                    </Link>
                  ) : (
                    <Link
                      to={{ pathname: 'https://www.tortija.de/impressum/' }}
                      target="_blank"
                      className={styles.link}
                    >
                      Impressum
                    </Link>
                  )}
                </div>
                <div>
                  {tenantData?.legal !== undefined ? (
                    <>
                      {tenantData.customDomain !== undefined && tenantData.customDomain === window.location.hostname ? (
                        <>
                          {tenantData?.legal?.privacyPolicy && tenantData?.legal?.noWebsite === false ? (
                            <Link
                              to={{
                                pathname:
                                  tenantData?.legal?.privacyPolicy ?? 'https://whitelabel.tortija.de/datenschutz/',
                              }}
                              target="_blank"
                              className={styles.link}
                            >
                              Datenschutz
                            </Link>
                          ) : tenantData?.legal?.privacyPolicyText ? (
                            <>
                              <div className="cursor-pointer">
                                <div
                                  className={styles.link}
                                  onClick={() => openLegalInfo('dataPrivacy')}
                                  aria-hidden="true"
                                >
                                  Datenschutz
                                </div>
                              </div>
                            </>
                          ) : (
                            <Link
                              to={{
                                pathname:
                                  tenantData?.legal?.privacyPolicy ?? 'https://whitelabel.tortija.de/datenschutz/',
                              }}
                              target="_blank"
                              className={styles.link}
                            >
                              Datenschutz
                            </Link>
                          )}
                        </>
                      ) : (
                        <>
                          {tenantData?.legal?.privacyPolicy && tenantData?.legal?.noWebsite === false ? (
                            <Link
                              to={{
                                pathname:
                                  tenantData?.legal?.privacyPolicy ?? 'https://whitelabel.tortija.de/datenschutz/',
                              }}
                              target="_blank"
                              className={styles.link}
                            >
                              Datenschutz
                            </Link>
                          ) : tenantData?.legal?.privacyPolicyText ? (
                            <>
                              <div className="cursor-pointer">
                                <div
                                  className={styles.link}
                                  onClick={() => openLegalInfo('dataPrivacy')}
                                  aria-hidden="true"
                                >
                                  Datenschutz
                                </div>
                              </div>
                            </>
                          ) : (
                            <Link
                              to={{
                                pathname:
                                  tenantData?.legal?.privacyPolicy ?? 'https://whitelabel.tortija.de/datenschutz/',
                              }}
                              target="_blank"
                              className={styles.link}
                            >
                              Datenschutz
                            </Link>
                          )}
                        </>
                      )}
                    </>
                  ) : window.location.hostname === 'business.tortija.de' ? (
                    <Link
                      to={{ pathname: 'https://whitelabel.tortija.de/datenschutz/' }}
                      target="_blank"
                      className={styles.link}
                    >
                      Datenschutz
                    </Link>
                  ) : (
                    <Link
                      to={{ pathname: 'https://www.tortija.de/datenschutz/' }}
                      target="_blank"
                      className={styles.link}
                    >
                      Datenschutz
                    </Link>
                  )}
                </div>
                <div>
                  {tenantData?.legal !== undefined ? (
                    <>
                      {tenantData.customDomain !== undefined && tenantData.customDomain === window.location.hostname ? (
                        <>
                          {tenantData?.legal?.termsOfService && tenantData?.legal?.noWebsite === false ? (
                            <Link
                              to={{
                                pathname: tenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                              }}
                              target="_blank"
                              className={styles.link}
                            >
                              Nutzungsbedingungen
                            </Link>
                          ) : tenantData?.legal?.termsOfUseText ? (
                            <>
                              <div className="cursor-pointer">
                                <div className={styles.link} onClick={() => openLegalInfo('agb')} aria-hidden="true">
                                  Nutzungsbedingungen
                                </div>
                              </div>
                            </>
                          ) : (
                            <Link
                              to={{
                                pathname: tenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                              }}
                              target="_blank"
                              className={styles.link}
                            >
                              Nutzungsbedingungen
                            </Link>
                          )}
                        </>
                      ) : (
                        <>
                          {tenantData?.legal?.termsOfService && tenantData?.legal?.noWebsite === false ? (
                            <Link
                              to={{
                                pathname: tenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                              }}
                              target="_blank"
                              className={styles.link}
                            >
                              Nutzungsbedingungen
                            </Link>
                          ) : tenantData?.legal?.termsOfUseText ? (
                            <>
                              <div className="cursor-pointer">
                                <div className={styles.link} onClick={() => openLegalInfo('agb')} aria-hidden="true">
                                  Nutzungsbedingungen
                                </div>
                              </div>
                            </>
                          ) : (
                            <Link
                              to={{
                                pathname: tenantData?.legal?.termsOfService ?? 'https://whitelabel.tortija.de/agb/',
                              }}
                              target="_blank"
                              className={styles.link}
                            >
                              Nutzungsbedingungen
                            </Link>
                          )}
                        </>
                      )}
                    </>
                  ) : window.location.hostname === 'business.tortija.de' ? (
                    <Link
                      to={{ pathname: 'https://whitelabel.tortija.de/agb/' }}
                      target="_blank"
                      className={styles.link}
                    >
                      Nutzungsbedingungen
                    </Link>
                  ) : (
                    <Link to={{ pathname: 'https://www.tortija.de/agb/' }} target="_blank" className={styles.link}>
                      Nutzungsbedingungen
                    </Link>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="h-full w-full md:flex block">
          <div className={styles.loading}>
            <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerBillingRegister;

import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'providers/AuthProvider';
import moment from 'moment';
import { toast } from 'react-toast';
import RecipeStepSwitch from '../../../Nutrition/components/RecipeStepSwitch';

type Props = {
  className?: string;
  currentStep?: any;
  currentStepValue?: string;
  planState?: any;
  planStateValue?: any;
  popupContainerRef?: any;
};

const NewPlanStep3: React.FC<Props> = ({
  currentStep,
  currentStepValue,
  planState,
  planStateValue,
  popupContainerRef,
}) => {
  const [visible, setVisible] = useState('block');
  const [currentImageUrl, setCurrentImageUrl] = useState(
    'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fexample_4.jpeg?alt=media&token=77894a70-1e16-4a02-a827-353303413c7e'
  );

  function returnToPreviousStep() {
    setVisible('hidden');
    currentStep('2');
  }

  const planAvatarImages = [
    {
      value:
        'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fexample_4.jpeg?alt=media&token=77894a70-1e16-4a02-a827-353303413c7e',
    },
    {
      value:
        'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fexample_2.jpeg?alt=media&token=a354aac2-1db7-4c81-b418-cb111286a17e',
    },
    {
      value:
        'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fexample_3.jpeg?alt=media&token=df31fd63-5c2c-421b-9813-97c371bb6a49',
    },
    {
      value:
        'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fexample_1.jpeg?alt=media&token=d48b84be-17df-4395-ab08-8f3344d432c5',
    },
    {
      value:
        'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fexample_5.jpeg?alt=media&token=32b916dc-34ea-4a2b-8c0e-841f1e1d1476',
    },
    {
      value:
        'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fdefault_plan.jpeg?alt=media&token=f7893caa-6bdd-47b3-a053-7dd3430fd97e',
    },
  ];

  useEffect(() => {
    if (currentStepValue !== '3') {
      setVisible('hidden');
    }
    if (planStateValue?.imageUrl !== '') {
      setCurrentImageUrl(planStateValue.imageUrl);
    }
  }, [currentStepValue, planStateValue, visible, popupContainerRef]);

  function jumpToNextStep() {
    const thisPlanStartDate = moment.unix(planStateValue.startDate.seconds).utc().startOf('day');
    const thisPlanEndDate = moment.unix(planStateValue.endDate.seconds).utc().startOf('day');

    const dayEntriesLength = thisPlanEndDate.diff(thisPlanStartDate, 'days') + 1;

    let thisDayEntriesData = [] as any;

    for (let g = 0; g < dayEntriesLength; g += 1) {
      const thisObject = {
        breakfast: {
          ingredients: [],
          recipes: [],
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
          label: 'Frühstück',
        },
        lunch: {
          ingredients: [],
          recipes: [],
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
          label: 'Mittagessen',
        },
        dinner: {
          ingredients: [],
          recipes: [],
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
          label: 'Abendessen',
        },
        snacks: {
          ingredients: [],
          recipes: [],
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
          label: 'Snacks',
        },
        kcal_total: 0,
        carbohydrates_total: 0,
        protein_total: 0,
        fat_total: 0,
        id: g + 1,
        name: `Tag ${g + 1}`,
        date: {
          seconds: moment.unix(planStateValue.startDate.seconds).utc().subtract(-g, 'days').unix(),
        },
      };

      thisDayEntriesData = [...thisDayEntriesData, thisObject];
    }

    const newColumns = {
      ...planStateValue,
      imageUrl: currentImageUrl,
      dayEntries: thisDayEntriesData,
    };

    planState(newColumns);
    setVisible('hidden');
    currentStep('4');
  }

  return (
    <>
      <div className={visible}>
        <div className="px-20">
          <div className="pb-100">
            <div className="pt-20 font-extralight text-base">Bitte wähle ein Bild für deinen Plan aus!</div>
            <div className="grid grid-cols-3 gap-4 pt-20">
              {planAvatarImages.map((item, index) =>
                item.value === currentImageUrl ? (
                  <div key={index} className="rounded-2xl border-4 border-accentColor">
                    <img
                      className="h-150 w-150 object-cover rounded-2xl"
                      alt={item.value}
                      src={item.value}
                      onClick={() => setCurrentImageUrl(item.value)}
                      onMouseDown={() => setCurrentImageUrl(item.value)}
                      aria-hidden="true"
                      loading="lazy"
                    />
                  </div>
                ) : (
                  <div key={index} className="rounded-2xl border-transparent border-4 hover:border-accentColor">
                    <img
                      className="h-150 w-150 object-cover rounded-2xl"
                      alt={item.value}
                      src={item.value}
                      onClick={() => setCurrentImageUrl(item.value)}
                      onMouseDown={() => setCurrentImageUrl(item.value)}
                      aria-hidden="true"
                      loading="lazy"
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <RecipeStepSwitch
        returnFunction={returnToPreviousStep}
        nextFunction={jumpToNextStep}
        currentStepValue="3"
        totalSteps={planStateValue?.planType === 'alghorithmus' ? 6 : 5}
      />
    </>
  );
};

export default NewPlanStep3;

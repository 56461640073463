import React, { useState, useEffect, useRef, useContext } from 'react';
import Button from 'components/Button';
import { intolerancesOption } from 'shared/constants/profile-wizard';
import SwitchButton from 'components/SwitchButton';
import firebase from 'services/firebase';
import { toast } from 'react-toast';
import moment from 'moment';
import { scrollToTop, generateRandomUid, savePlanChangesToFirebase } from 'shared/functions/global';
import { AuthContext } from 'providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { trimEnd } from 'lodash';
import RecipeStepSwitch from '../RecipeStepSwitch';

type Props = {
  className?: string;
  currentStep?: any;
  currentStepValue?: string;
  recipeState?: any;
  recipeStateValue?: any;
  mainPopup?: any;
  popupContainerRef?: any;
  isPlanRecipe?: boolean;
  planState?: any;
  planStateValue?: any;
  planMealType?: any;
  planDayId?: number | undefined;
};

const NewRecipeStep6: React.FC<Props> = ({
  currentStep,
  currentStepValue,
  recipeState,
  recipeStateValue,
  mainPopup,
  popupContainerRef,
  isPlanRecipe = false,
  planState,
  planStateValue,
  planMealType,
  planDayId,
}) => {
  const [visible, setVisible] = useState('block');
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { userData, tenant } = useContext(AuthContext);

  const editIngridientNameRef = useRef<HTMLDivElement>(null);

  const saveNewRecipeToFirebase = async () => {
    const thisRecipeUid = generateRandomUid();

    const newColumnsAdmin = {
      ...recipeStateValue,
      uid: thisRecipeUid,
      created: moment().unix(),
    };

    const newColumns = {
      ...recipeStateValue,
      userRecipe: true,
      uid: thisRecipeUid,
      created: moment().unix(),
    };

    const newColumnsB2BAdmin = {
      ...recipeStateValue,
      communityRecipe: true,
      uid: thisRecipeUid,
      created: moment().unix(),
    };

    const newColumnsFavorite = {
      uid: thisRecipeUid,
      name: recipeStateValue.name,
      origId: thisRecipeUid,
    };

    if (userData?.role === 1) {
      try {
        await firebase
          .firestore()
          .collection('recipes')
          .doc(thisRecipeUid)
          .set(newColumnsAdmin as object);
        toast.success(t('Dein Rezept wurde erfolgreich erstellt!'));
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } else if (
      (userData?.isAdminUser || userData?.isTrainerUser || userData?.isLocationAdminUser) &&
      recipeStateValue.communityRecipe === true
    ) {
      try {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/recipes`)
          .doc(thisRecipeUid)
          .set(newColumnsB2BAdmin as object);
        toast.success(t('Dein Rezept wurde erfolgreich erstellt1!'));
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } else {
      try {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(authContext.user?.uid)
          .collection('recipes')
          .doc(thisRecipeUid)
          .set(newColumns as object);
        try {
          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(authContext.user?.uid)
            .collection('favorites_recipes')
            .doc()
            .set(newColumnsFavorite as object);
        } catch (error: any) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          toast.error('Es ist leider etwas schief gegangen!');
        }
        toast.success(t('Dein Rezept wurde erfolgreich erstellt!'));
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    }

    currentStep('1');
    mainPopup('hidden');

    if (isPlanRecipe) {
      const currentDayItemIngredients = planStateValue?.dayEntries.filter((item: any) => item.id === planDayId)[0];

      const res = currentDayItemIngredients?.[planMealType].ingredients.map((el1: any) => ({
        ...el1,
        match: recipeStateValue.ingredients.some((el2: any) => el2.id === el1.id),
      }));

      const newColumnsNewRecipeObject = {
        ...newColumnsAdmin,
        id: Math.random().toString(),
        amount: 1,
        piece: 'Portion',
      };

      const newColumnsNewRecipe = {
        ...planStateValue,
        dayEntries: planStateValue.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== planDayId) return entries;
          return {
            ...entries,
            [planMealType]: {
              ...entries[planMealType],
              ingredients: [...res.filter((item: any) => item.match === false)],
              recipes: [newColumnsNewRecipeObject, ...entries[planMealType].recipes],
            },
          };
        }),
      };

      savePlanChangesToFirebase(
        userData,
        newColumnsNewRecipe,
        newColumnsNewRecipe.uid,
        authContext.user?.uid,
        false,
        planState,
        tenant
      );
      // planState(newColumnsNewRecipe);
    }
  };

  // Change incompatibility items
  const setIncompatibilities = (status: boolean, item: string): void => {
    const newColumns = {
      ...recipeStateValue,
      [item.toLowerCase()]: status,
    };

    recipeState(newColumns);
  };

  function returnToPreviousStep() {
    setVisible('hidden');
    currentStep('5');
  }

  useEffect(() => {
    if (currentStepValue !== '6') {
      setVisible('hidden');
    }

    if (visible === 'block') {
      scrollToTop(popupContainerRef);
    }

    if (editIngridientNameRef.current) {
      editIngridientNameRef.current.focus();
    }
  }, [currentStepValue, popupContainerRef, visible, recipeStateValue]);

  const changeName = (event: any) => {
    const thisCurrentName = event.currentTarget.textContent;

    const newColumns = {
      ...recipeStateValue,
      name: thisCurrentName,
    };

    recipeState(newColumns);
  };

  return (
    <>
      <div className={visible}>
        <div className="pt-20 font-extralight text-base">
          Folgende Unverträglichkeiten haben wir bei deinem Rezept erkannt (ohne Gewähr).
        </div>
        <div className="pt-10 font-extralight text-base">Bitte prüfe das nochmal und passe es bei Bedarf an.</div>
        <div className="pt-30 pb-80">
          {intolerancesOption.map((item: string, index: number) => (
            <>
              <SwitchButton
                key={index}
                label={item}
                enabled={Boolean(recipeStateValue[item.toLowerCase()])}
                isBackground={index % 2 === 0}
                onChange={setIncompatibilities}
              />
            </>
          ))}
        </div>
        <RecipeStepSwitch
          returnFunction={returnToPreviousStep}
          nextFunction={saveNewRecipeToFirebase}
          currentStepValue="6"
          totalSteps={6}
          isFinished
        />
      </div>
    </>
  );
};

export default NewRecipeStep6;

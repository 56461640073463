/* eslint-disable react/no-danger */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { toast } from 'react-toast';
import { AuthContext } from 'providers/AuthProvider';
import { PencilIcon, ClipboardCopyIcon, CheckCircleIcon } from '@heroicons/react/outline';
import ReactLoading from 'react-loading';
import Button from 'components/Button';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import CustomUserInput from 'components/CustomUserInput';
import { defaultTextColor } from 'layouts/Theme';
import firebase from 'services/firebase';
import Overlay from 'components/Overlay';
import {
  changeStripeConnectFee,
  changeStripeConnectEmail,
  changeStripeConnectHeadline,
  changeStripeConnectText,
  changeStripeConnectPriceAndProductId,
  changeStripeConnectSmallCompany,
  changeStripeConnectTrial,
  deleteStripeConnectTrial,
  changeStripeConnectTaxId,
  changeStripeConnectPortalId,
  changeStripeConnectMailFrom,
  changeStripeConnectMailSubject,
  changeStripeConnectMailText,
  changeStripeConnectMailName,
} from 'shared/functions/global';

import Headline from 'components/Headline';

import styles from './styles.module.scss';

type Props = {};

const CustomerBilling: React.FC<Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isAccountComplete, setIsAccountComplete] = useState(false);
  const [isFeeSet, setIsFeeSet] = useState(false);
  const [montlyFeePopupClass, setMontlyFeePopupClass] = useState('hidden');
  const [companyTypePopupClass, setCompanyTypePopupClass] = useState('hidden');
  const [editCompanyTypePopupClass, setEditCompanyTypePopupClass] = useState('hidden');
  const [editMontlyFeePopupClass, setEditMontlyFeePopupClass] = useState('hidden');
  const [editEmailPopupClass, setEditEmailPopupClass] = useState('hidden');
  const [editHeadlinePopupClass, setEditHeadlinePopupClass] = useState('hidden');
  const [editTrialPopupClass, setEditTrialPopupClass] = useState('hidden');
  const [editTextPopupClass, setEditTextPopupClass] = useState('hidden');
  const [monthlyFeeValue, setMonthlyFeeValue] = useState(0);
  const [currentEmail, setCurrentEmail] = useState('');
  const [currentHeadline, setCurrentHeadline] = useState('');
  const [currentTrial, setCurrentTrial] = useState(0);
  const [currentText, setCurrentText] = useState('');
  const [changedMonthlyFeeValue, setChangedMonthlyFeeValue] = useState(0);
  const [changedEmailValue, setChangedEmailValue] = useState('');
  const [changedHeadlineValue, setChangedHeadlineValue] = useState('');
  const [changedTrialValue, setChangedTrialValue] = useState(0);
  const [changedTextValue, setChangedTextValue] = useState('');
  const [isSmallCompanyValue, setIsSmallCompanyValue] = useState(false);

  const [currentMailFrom, setCurrentMailFrom] = useState('');
  const [currentMailSubject, setCurrentMailSubject] = useState('');
  const [currentMailName, setCurrentMailName] = useState('');
  const [currentMailText, setCurrentMailText] = useState('');

  const [changedMailFromValue, setChangedMailFromValue] = useState('');
  const [changedMailSubjectValue, setChangedMailSubjectValue] = useState('');
  const [changedMailNameValue, setChangedMailNameValue] = useState('');
  const [changedMailTextValue, setChangedMailTextValue] = useState('');

  const [mailFromPopupClass, setMailFromPopupClass] = useState('hidden');
  const [mailSubjectPopupClass, setMailSubjectPopupClass] = useState('hidden');
  const [mailNamePopupClass, setMailNamePopupClass] = useState('hidden');
  const [mailTextPopupClass, setMailTextPopupClass] = useState('hidden');

  const [checkCount, setCheckCount] = useState(0);
  const [currentTaxId, setCurrentTaxId] = useState('');
  const [currentPortalId, setCurrentPortalId] = useState('');

  const { tenant, tenantData, theme, setTenantData, companyData } = useContext(AuthContext);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('mainContainer')?.scrollTo(0, 0);
    }, 1);

    if (tenantData?.stripeConnect?.stripeConnectAccountId !== undefined) {
      getStripeAccountData();
    } else {
      setIsPageLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (checkCount < 10 && tenantData?.stripeConnect && tenantData?.stripeConnect?.stripeConnectAccountId) {
      if (tenantData?.stripeConnect?.stripeConnectTaxId === undefined && isAccountComplete) {
        createStripeTaxRate(isSmallCompanyValue);
        return;
      }

      if (tenantData?.stripeConnect?.stripeConnectCustomerPortalId === undefined && isAccountComplete) {
        createStripeCustomerPortal();
      }

      if (
        tenantData?.stripeConnect?.stripeConnectTaxId !== undefined &&
        tenantData?.stripeConnect?.stripeConnectCustomerPortalId !== undefined
      ) {
        setIsAccountComplete(true);
      }
    }
  }, [checkCount]);

  const getStripeAccountData = async () => {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/getStripeAccountDetails`,
      method: 'post',
      data: {
        accountId: tenantData?.stripeConnect?.stripeConnectAccountId,
      },
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    }).then(
      response => {
        if (response.data.details_submitted) {
          if (
            tenantData?.stripeConnect?.stripeConnectTaxId === undefined ||
            tenantData?.stripeConnect?.stripeConnectCustomerPortalId === undefined
          ) {
            setIsAccountComplete(true);
            // setCheckCount(1);
          } else {
            setIsAccountComplete(true);
          }
        }
        setIsPageLoaded(true);
      },
      error => {
        setIsPageLoaded(true);
        console.log(error);
      }
    );
  };

  const createStripeTaxRate = async (isSmallCompany: boolean) => {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/createStripeConnectTaxRate`,
      method: 'post',
      data: {
        stripeAccountId: tenantData?.stripeConnect?.stripeConnectAccountId,
        taxPercentage: isSmallCompany === true ? 0 : 19,
        country: tenantData?.company?.country ?? 'DE',
      },
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    }).then(
      response => {
        if (response.data.taxId) {
          const newObject = {
            ...tenantData,
            stripeConnect: {
              ...tenantData?.stripeConnect,
              stripeConnectTaxId: response.data.taxId,
            },
          };
          setTenantData(newObject as TenantInfo);

          changeStripeConnectTaxId(tenant, response.data.taxId, tenantData);
          setCheckCount(checkCount + 1);
        }
      },
      error => {
        setCheckCount(checkCount + 1);
        console.log(error);
      }
    );
  };

  const createStripeTaxRateEdit = async (isSmallCompany: boolean) => {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/createStripeConnectTaxRate`,
      method: 'post',
      data: {
        stripeAccountId: tenantData?.stripeConnect?.stripeConnectAccountId,
        taxPercentage: isSmallCompany === true ? 0 : 19,
        country: tenantData?.company?.country ?? 'DE',
      },
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    }).then(
      response => {
        if (response.data.taxId) {
          const newObject = {
            ...tenantData,
            stripeConnect: {
              ...tenantData?.stripeConnect,
              stripeConnectIsSmallCompany: isSmallCompany,
              stripeConnectTaxId: response.data.taxId,
            },
          };
          setTenantData(newObject as TenantInfo);

          changeStripeConnectSmallCompany(tenant, isSmallCompany, tenantData, undefined);
          changeStripeConnectTaxId(tenant, response.data.taxId, newObject);
          setEditCompanyTypePopupClass('hidden');
        }
      },
      error => {
        setEditCompanyTypePopupClass('hidden');
        console.log(error);
      }
    );
  };

  const createStripeCustomerPortal = async () => {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/createStripeConnectCustomerPortalConfiguration`,
      method: 'post',
      data: {
        stripeAccountId: tenantData?.stripeConnect?.stripeConnectAccountId,
        companyName: tenantData?.company?.companyName,
      },
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    }).then(
      response => {
        if (response.data) {
          const newObject = {
            ...tenantData,
            stripeConnect: {
              ...tenantData?.stripeConnect,
              stripeConnectCustomerPortalId: response.data,
            },
          };
          setTenantData(newObject as TenantInfo);
          changeStripeConnectPortalId(tenant, response.data, tenantData);
          setCheckCount(checkCount + 1);
        }
      },
      error => {
        setCheckCount(checkCount + 1);
        console.log(error);
      }
    );
  };

  const changeFeeValue = (event: any) => {
    const thisCurrentValue = event.target.value;

    setMonthlyFeeValue(thisCurrentValue);
  };

  const changeEmailValue = (event: any) => {
    const thisCurrentValue = event.target.value;

    setCurrentEmail(thisCurrentValue);
  };

  const changeEmailFromValue = (event: any) => {
    const thisCurrentValue = event.target.value;

    setCurrentMailFrom(thisCurrentValue);
  };

  const changeEmailSubjectValue = (event: any) => {
    const thisCurrentValue = event.target.value;

    setCurrentMailSubject(thisCurrentValue);
  };

  const changeEmailNameValue = (event: any) => {
    const thisCurrentValue = event.target.value;

    setCurrentMailName(thisCurrentValue);
  };

  const changeEmailTextValue = (event: any) => {
    const thisCurrentValue = event.target.value;

    setCurrentMailText(thisCurrentValue);
  };

  const changeHeadlineValue = (event: any) => {
    const thisCurrentValue = event.target.value;

    setCurrentHeadline(thisCurrentValue);
  };

  const changeTrialValue = (event: any) => {
    const thisCurrentValue = event.target.value;

    setCurrentTrial(parseFloat(thisCurrentValue));
  };

  const changeTextValue = (event: any) => {
    const thisCurrentValue = event.target.value;

    setCurrentText(thisCurrentValue);
  };

  const copyRegisterLink = () => {
    const copyLinkUrl = `${
      tenantData?.customDomain !== undefined ? `https://${tenantData.customDomain}` : 'https://business.tortija.de'
    }
/company-register/${tenant}`;

    navigator.clipboard.writeText(copyLinkUrl);
    toast.success('Link in die Zwischenablage kopiert!');
  };

  const copyCancelLink = () => {
    const copyLinkUrl = `${
      tenantData?.customDomain !== undefined ? `https://${tenantData.customDomain}` : 'https://business.tortija.de'
    }
/connect-cancel`;

    navigator.clipboard.writeText(copyLinkUrl);
    toast.success('Link in die Zwischenablage kopiert!');
  };

  const startOnboarding = async () => {
    setIsLoading(true);
    await axios({
      url: `${process.env.REACT_APP_API_URL}/createStripeConnectAccount`,
      method: 'post',
      data: {
        tenant,
        city: tenantData?.company?.city ?? '',
        street: `${tenantData?.company?.street ?? ''}${tenantData?.company.streetNumber ?? ''}`,
        companyName: tenantData?.company?.companyName ?? '',
        postalCode: tenantData?.company?.zip ?? '',
        country: tenantData?.company?.country ?? 'DE',
        refreshUrl:
          tenantData?.customDomain !== undefined
            ? `https://${tenantData.customDomain}/company/customer-billing`
            : `https://business.tortija.de/company/customer-billing`,
        returnUrl:
          tenantData?.customDomain !== undefined
            ? `https://${tenantData.customDomain}/company/customer-billing`
            : `https://business.tortija.de/company/customer-billing`,
      },
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    }).then(
      response => {
        window.location.href = response.data;
      },
      error => {
        toast.error('Es ist leider etwas schief gelaufen!');
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  const toCent = (amount: string) => {
    const str = parseFloat(amount.replace(',', '.'));
    return Math.round((Math.abs(str) / 100) * 10000);
  };

  const addInitialStripeMonthlyFee = async () => {
    setIsLoading(true);
    const saveFeeToFirebase = await changeStripeConnectFee(tenant, monthlyFeeValue, tenantData);

    const newTenantDataObject = {
      ...tenantData,
      stripeConnect: {
        ...tenantData?.stripeConnect,
        stripeConnectMonthlyFee: parseFloat(monthlyFeeValue.toString()),
      },
    };

    if (saveFeeToFirebase.status === 'success') {
      await axios({
        url: `${process.env.REACT_APP_API_URL}/createStripeConnectPriceAndProduct`,
        method: 'post',
        data: {
          stripeAccountId: tenantData?.stripeConnect?.stripeConnectAccountId,
          productName: `Monatsbeitrag - ${moment().format('YYYYMMDD')}`,
          price: toCent(monthlyFeeValue.toString()),
        },
        headers: {
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      }).then(
        response => {
          const getIdFunction = async () => {
            const getIds = await changeStripeConnectPriceAndProductId(
              tenant,
              response.data.priceId,
              response.data.productId,
              newTenantDataObject
            );

            if (getIds.status === 'success') {
              setIsFeeSet(true);
              setChangedMonthlyFeeValue(monthlyFeeValue);
              setIsLoading(false);
              setMontlyFeePopupClass('hidden');
              setEditMontlyFeePopupClass('hidden');

              const tenantInfo = (await firebase.firestore().collection(`tenants`).doc(tenant).get()).data();
              setTenantData(tenantInfo as TenantInfo);

              toast.success('Der Preis wurde erfolgreich gespeichert!');
            } else {
              setIsLoading(false);
              setMontlyFeePopupClass('hidden');
              setEditMontlyFeePopupClass('hidden');
              toast.error('Es ist leider etwas schief gelaufen. Bitte probieren Sie es nochmal oder kontaktieren uns!');
            }
          };

          getIdFunction();
        },
        error => {
          setIsLoading(false);
          setMontlyFeePopupClass('hidden');
          setEditMontlyFeePopupClass('hidden');
          toast.error('Es ist leider etwas schief gelaufen. Bitte probieren Sie es nochmal oder kontaktieren uns!');
          console.log(error);
        }
      );
    }
  };

  const updateStripeConnectEmail = async () => {
    await changeStripeConnectEmail(tenant, currentEmail, tenantData);
    setChangedEmailValue(currentEmail);

    const newTenantObject = {
      ...tenantData,
      stripeConnect: {
        ...tenantData?.stripeConnect,
        stripeConnectRegisterEmail: currentEmail,
      },
    } as any;

    setTenantData(newTenantObject);
    setEditEmailPopupClass('hidden');
  };

  const updateStripeConnectMailFrom = async () => {
    await changeStripeConnectMailFrom(tenant, currentMailFrom, tenantData);
    setChangedMailFromValue(currentMailFrom);

    const newTenantObject = {
      ...tenantData,
      stripeConnect: {
        ...tenantData?.stripeConnect,
        stripeConnectMailFrom: currentMailFrom,
      },
    } as any;

    setTenantData(newTenantObject);
    setMailFromPopupClass('hidden');
  };

  const updateStripeConnectMailSubject = async () => {
    await changeStripeConnectMailSubject(tenant, currentMailSubject, tenantData);
    setChangedMailSubjectValue(currentMailSubject);

    const newTenantObject = {
      ...tenantData,
      stripeConnect: {
        ...tenantData?.stripeConnect,
        stripeConnectMailSubject: currentMailSubject,
      },
    } as any;

    setTenantData(newTenantObject);
    setMailSubjectPopupClass('hidden');
  };

  const updateStripeConnectMailName = async () => {
    await changeStripeConnectMailName(tenant, currentMailName, tenantData);
    setChangedMailNameValue(currentMailName);

    const newTenantObject = {
      ...tenantData,
      stripeConnect: {
        ...tenantData?.stripeConnect,
        stripeConnectMailName: currentMailName,
      },
    } as any;

    setTenantData(newTenantObject);
    setMailNamePopupClass('hidden');
  };

  const updateStripeConnectMailText = async () => {
    await changeStripeConnectMailText(tenant, currentMailText, tenantData);
    setChangedMailTextValue(currentMailText);

    const newTenantObject = {
      ...tenantData,
      stripeConnect: {
        ...tenantData?.stripeConnect,
        stripeConnectMailText: currentMailText,
      },
    } as any;

    setTenantData(newTenantObject);
    setMailTextPopupClass('hidden');
  };

  const deactivateeStripeConnectTrial = async () => {
    await deleteStripeConnectTrial(tenant);
    setChangedTrialValue(0);

    const newTenantObject = {
      ...tenantData,
    } as any;

    if (newTenantObject.stripeConnect?.stripeConnectTrialDays !== undefined) {
      delete newTenantObject.stripeConnect.stripeConnectTrialDays;
    }

    setTenantData(newTenantObject);
    setEditTrialPopupClass('hidden');
  };

  const updateStripeConnectSmallCompany = async (type: boolean) => {
    setIsSmallCompanyValue(type);
    setCompanyTypePopupClass('hidden');
    setIsPageLoaded(false);
    await changeStripeConnectSmallCompany(tenant, type, tenantData, setTenantData);
    setCheckCount(1);
    setIsPageLoaded(true);
  };

  const updateStripeConnectSmallCompanyEdit = async (type: boolean) => {
    setIsSmallCompanyValue(type);
    setCompanyTypePopupClass('hidden');
    setIsPageLoaded(false);
    await changeStripeConnectSmallCompany(tenant, type, tenantData, setTenantData);
    setIsPageLoaded(true);
  };

  const updateStripeConnectHeadline = async () => {
    await changeStripeConnectHeadline(tenant, currentHeadline, tenantData);
    setChangedHeadlineValue(currentHeadline);

    const newTenantObject = {
      ...tenantData,
      stripeConnect: {
        ...tenantData?.stripeConnect,
        stripeConnectRegisterHeadline: currentHeadline,
      },
    } as any;

    setTenantData(newTenantObject);
    setEditHeadlinePopupClass('hidden');
  };

  const updateStripeConnectTrial = async () => {
    setIsLoading(true);
    await changeStripeConnectTrial(tenant, currentTrial, tenantData);
    setChangedTrialValue(currentTrial);

    const newTenantObject = {
      ...tenantData,
      stripeConnect: {
        ...tenantData?.stripeConnect,
        stripeConnectTrialDays: currentTrial,
      },
    } as any;

    setIsLoading(false);
    setTenantData(newTenantObject);
    setEditTrialPopupClass('hidden');
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  const updateStripeConnectText = async () => {
    await changeStripeConnectText(tenant, currentText, tenantData);
    setChangedTextValue(currentText);

    const newTenantObject = {
      ...tenantData,
      stripeConnect: {
        ...tenantData?.stripeConnect,
        stripeConnectRegisterText: currentText,
      },
    } as any;

    setTenantData(newTenantObject);
    setEditTextPopupClass('hidden');
  };

  return (
    <>
      <Overlay
        overlayClass={montlyFeePopupClass}
        setOverlayClass={setMontlyFeePopupClass}
        headline="Monatlicher Abonnement Preis"
        icon={<PencilIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div>Die Beiträge Ihrer Kunden werden monatlich abgerechnet über ein monatliches Abonnement.</div>
            <div className="pt-10">
              Bitte geben Sie in das Feld den Betrag ein, den Sie Ihren Kunden monatlich berechnen wollen.
            </div>
            <div className="pt-10">Diese Einstellung können Sie jederzeit anpassen.</div>
          </div>
          <div className="pt-30 pb-80">
            <div>
              Monatlicher Abonnement-Beitrag für Ihre Kunden<sup>*</sup>:
            </div>
            <div className="pt-5 w-full flex space-x-10">
              <CustomUserInput
                name="description"
                textCenter={false}
                onChange={e => changeFeeValue(e)}
                type="number"
                placeHolder="Monatlicher Beitrag"
              />
              <div className="my-auto desktop:text-20">€ (inkl. MwSt.)</div>
            </div>
            {monthlyFeeValue > 0 && (
              <div className="font-extralight">entspricht {(monthlyFeeValue / 1.19).toFixed(2)} € netto</div>
            )}
            <Button
              isPending={isLoading}
              disabled={isLoading}
              className="w-full mt-30"
              onClick={() => addInitialStripeMonthlyFee()}
            >
              Speichern
            </Button>
            <div className="font-extralight text-14 pt-10">
              * Alle Preise sind in EURO angegeben und verstehen sich inkl. der geltenden gesetzlichen Mehrwertsteuer
            </div>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={editMontlyFeePopupClass}
        setOverlayClass={setEditMontlyFeePopupClass}
        headline="Monatlichen Abonnement Preis ändern"
        icon={<PencilIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div className="font-bold">Hinweis:</div>
            <div className="pt-10">
              Wenn Sie den Preis ändern, dann wirkt sich das nur auf neue Abonnements aus. Bestehende Abonnements können
              nicht verändert werden!
            </div>
            <div className="pt-10">Bitte geben Sie den gewünschten Preis in das Feld unten ein.</div>
          </div>
          <div className="pt-30 pb-80">
            <div>
              Monatlicher Abonnement-Beitrag für Ihre Kunden:<sup>*</sup>
            </div>
            <div className="pt-5 w-full flex space-x-10">
              <CustomUserInput
                name="description"
                textCenter={false}
                onChange={e => changeFeeValue(e)}
                type="number"
                placeHolder="Monatlicher Beitrag"
              />
              <div className="my-auto desktop:text-20">€ (inkl. MwSt.)</div>
            </div>
            {monthlyFeeValue > 0 && (
              <div className="font-extralight">entspricht {(monthlyFeeValue / 1.19).toFixed(2)} € netto</div>
            )}
            <Button
              isPending={isLoading}
              disabled={isLoading}
              className="w-full mt-30"
              onClick={() => addInitialStripeMonthlyFee()}
            >
              Speichern
            </Button>
            <div className="font-extralight text-14 pt-10">
              * Alle Preise sind in EURO angegeben und verstehen sich inkl. der geltenden gesetzlichen Mehrwertsteuer
            </div>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={companyTypePopupClass}
        setOverlayClass={setCompanyTypePopupClass}
        headline="Einstellungen Rechnungsstellung"
        icon={<PencilIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div className="pt-10 font-bold text-25 leading-28">
              Sind Sie als Kleinunternehmer tätig und nutzen Sie die Kleinunternehmer-Regelung?
            </div>
            <div className="font-bold pt-20">Hinweis:</div>
            <div>
              Wenn Sie diese Frage mit "Ja" beantworten, stellen wir die Rechnungen an Ihre Kunden ohne ausgewiesener
              Mehrwertsteuer aus und platzieren einen Hinweis zum Paragraph 19 (Gemäß § 19 UStG wird keine Umsatzsteuer
              berechnet.).
            </div>
          </div>
          <div className="pt-30 pb-40">
            <div>
              <Button className="w-full" onClick={() => updateStripeConnectSmallCompany(true)}>
                Ja, ich nutze die Kleinunternehmer-Regelung
              </Button>
            </div>
            <div className="pt-20">
              <Button className="w-full" onClick={() => updateStripeConnectSmallCompany(false)}>
                Nein
              </Button>
            </div>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={editEmailPopupClass}
        setOverlayClass={setEditEmailPopupClass}
        headline="E-Mail Benutzer-Registrierung ändern"
        icon={<PencilIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div className="font-bold">Hinweis:</div>
            <div className="pt-10">
              Wenn Sie die E-Mail Adresse ändern, dann wirkt sich das nur auf neue Registrierungen aus.
            </div>
            <div className="pt-10">Bitte geben Sie die E-Mail Adresse in das Feld unten ein.</div>
          </div>
          <div className="pt-30 pb-80">
            <div>E-Mail Adresse Benutzer-Registrierungen:</div>
            <div className="pt-5 w-full">
              <CustomUserInput
                name="description"
                textCenter={false}
                onChange={e => changeEmailValue(e)}
                type="text"
                placeHolder="E-Mail Adresse"
              />
            </div>
            <Button
              isPending={isLoading}
              disabled={isLoading}
              className="w-full mt-30"
              onClick={() => updateStripeConnectEmail()}
            >
              Speichern
            </Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={mailFromPopupClass}
        setOverlayClass={setMailFromPopupClass}
        headline="Mail an Nutzer: Absender E-Mail Adresse"
        icon={<PencilIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div className="font-bold">Hinweis:</div>
            <div className="pt-10">Standardmäßig werden die Emails mit dem 'info@tortija.de' Absender verschickt.</div>
            <div>Sie können das an dieser Stelle überschreiben.</div>
            <div className="pt-10">
              Bitte beachten Sie, dass jede Absenderadresse bei unserem Mailing-Anbieter verifiziert werden muss. Wenn
              Sie die Adresse ändern wollen und diese noch nicht verifiziert ist, kontaktieren Sie uns.
            </div>
          </div>
          <div className="pt-30 pb-80">
            <div>Absender E-Mail Adresse:</div>
            <div className="pt-5 w-full">
              <CustomUserInput
                name="description"
                textCenter={false}
                onChange={e => changeEmailFromValue(e)}
                type="text"
                placeHolder="Absender E-Mail Adresse"
              />
            </div>
            <Button
              isPending={isLoading}
              disabled={isLoading}
              className="w-full mt-30"
              onClick={() => updateStripeConnectMailFrom()}
            >
              Speichern
            </Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={mailSubjectPopupClass}
        setOverlayClass={setMailSubjectPopupClass}
        headline="Mail an Nutzer: Betreff"
        icon={<PencilIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div className="font-bold">Hinweis:</div>
            <div className="pt-10">Standardmäßig wird folgender Betreff gesetzt:</div>
            <div>[Name des Unternehmens] Einladung</div>
            <div className="pt-10">Sie können den Betreff an dieser Stelle überschreiben.</div>
          </div>
          <div className="pt-30 pb-80">
            <div>Betreff:</div>
            <div className="pt-5 w-full">
              <CustomUserInput
                name="description"
                textCenter={false}
                onChange={e => changeEmailSubjectValue(e)}
                type="text"
                placeHolder="Betreff"
              />
            </div>
            <Button
              isPending={isLoading}
              disabled={isLoading}
              className="w-full mt-30"
              onClick={() => updateStripeConnectMailSubject()}
            >
              Speichern
            </Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={mailNamePopupClass}
        setOverlayClass={setMailNamePopupClass}
        headline="Mail an Nutzer: Absender Name"
        icon={<PencilIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div className="font-bold">Hinweis:</div>
            <div className="pt-10">Sie können den Namen des Email Absenders an dieser Stelle überschreiben.</div>
          </div>
          <div className="pt-30 pb-80">
            <div>Absender Name:</div>
            <div className="pt-5 w-full">
              <CustomUserInput
                name="description"
                textCenter={false}
                onChange={e => changeEmailNameValue(e)}
                type="text"
                placeHolder="Absender Name"
              />
            </div>
            <Button
              isPending={isLoading}
              disabled={isLoading}
              className="w-full mt-30"
              onClick={() => updateStripeConnectMailName()}
            >
              Speichern
            </Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={mailTextPopupClass}
        setOverlayClass={setMailTextPopupClass}
        headline="Mail an Nutzer: Text unterhalb der Ansprache"
        icon={<PencilIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div className="pt-10">
              Der Nutzer erhält immer eine persönliche Ansprache mit Angabe seines hinterlegten Namens.
            </div>
            <div>Sie können den Text nach der Ansprache an dieser Stelle überschreiben.</div>
          </div>
          <div className="pt-30 pb-80">
            <div>Text unterhalb der Ansprache:</div>
            <div className="pt-5 w-full">
              <textarea
                onChange={e => changeEmailTextValue(e)}
                className="w-full h-200 text-textColor bg-lightGray rounded-20 p-15"
                onFocus={setBodyClassForMobileSafari}
                onBlur={removeBodyClassForMobileSafari}
              >
                {currentMailText.length > 0
                  ? currentMailText
                  : tenantData?.stripeConnect?.stripeConnectMailText !== undefined
                  ? tenantData?.stripeConnect?.stripeConnectMailText
                  : ''}
              </textarea>
            </div>
            <Button
              isPending={isLoading}
              disabled={isLoading}
              className="w-full mt-30"
              onClick={() => updateStripeConnectMailText()}
            >
              Speichern
            </Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={editHeadlinePopupClass}
        setOverlayClass={setEditHeadlinePopupClass}
        headline="Überschrift Benutzer-Registrierung ändern"
        icon={<PencilIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div className="pt-10">Bitte geben Sie die Überschrift in das Feld unten ein.</div>
          </div>
          <div className="pt-30 pb-80">
            <div>Überschrift Benutzer-Registrierungsformular:</div>
            <div className="pt-5 w-full">
              <CustomUserInput
                name="description"
                textCenter={false}
                onChange={e => changeHeadlineValue(e)}
                type="text"
                placeHolder="Überschrift"
              />
            </div>
            <Button
              isPending={isLoading}
              disabled={isLoading}
              className="w-full mt-30"
              onClick={() => updateStripeConnectHeadline()}
            >
              Speichern
            </Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={editTrialPopupClass}
        setOverlayClass={setEditTrialPopupClass}
        headline="Kostenloser Zeitraum ändern"
        icon={<PencilIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div className="pt-10">
              Wenn Sie Ihren Kunden die App für einen kostenlosen Zeitraum zur Verfügung stellen wollen, geben Sie bitte
              in dem Feld die Dauer in Tagen an.
            </div>
            <div className="pt-10">Der Kunde wird nach diesem Zeitraum dann das erste mal abgerechnet.</div>
          </div>
          <div className="pt-30 pb-80">
            <div>Kostenloser Zeitraum in Tagen:</div>
            <div className="pt-5 w-full">
              <CustomUserInput
                name="trialPeriod"
                textCenter={false}
                onChange={e => changeTrialValue(e)}
                type="number"
                placeHolder="Tage"
              />
            </div>
            <Button
              isPending={isLoading}
              disabled={isLoading}
              className="w-full mt-30"
              onClick={() => updateStripeConnectTrial()}
            >
              Speichern
            </Button>
            <Button
              isPending={isLoading}
              disabled={isLoading}
              buttonStyle="white"
              className="w-full mt-30"
              onClick={() => deactivateeStripeConnectTrial()}
            >
              Funktion deaktivieren
            </Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={editCompanyTypePopupClass}
        setOverlayClass={setEditCompanyTypePopupClass}
        headline="Einstellungen Rechnungsstellung"
        icon={<PencilIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div className="pt-10 font-bold text-25 leading-28">
              Sind Sie als Kleinunternehmer tätig und nutzen Sie die Kleinunternehmer-Regelung?
            </div>
            <div className="font-bold pt-20">Hinweis:</div>
            <div>
              Wenn Sie diese Frage mit "Ja" beantworten, stellen wir die Rechnungen an Ihre Kunden ohne ausgewiesener
              Mehrwertsteuer aus und platzieren einen Hinweis zum Paragraph 19 (Gemäß § 19 UStG wird keine Umsatzsteuer
              berechnet.).
            </div>
          </div>
          <div className="pt-30 pb-40">
            <div>
              <Button className="w-full" onClick={() => createStripeTaxRateEdit(true)}>
                Ja, ich nutze die Kleinunternehmer-Regelung
              </Button>
            </div>
            <div className="pt-20">
              <Button className="w-full" onClick={() => createStripeTaxRateEdit(false)}>
                Nein
              </Button>
            </div>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={editTextPopupClass}
        setOverlayClass={setEditTextPopupClass}
        headline="Text Benutzer-Registrierung ändern"
        icon={<PencilIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div className="pt-10">Bitte geben Sie den Text in das Feld unten ein.</div>
          </div>
          <div className="pt-30 pb-80">
            <div>Text Benutzer-Registrierungsformular:</div>
            <div className="pt-5 w-full">
              <textarea
                onChange={e => changeTextValue(e)}
                className="w-full h-200 text-textColor bg-lightGray rounded-20 p-15"
                onFocus={setBodyClassForMobileSafari}
                onBlur={removeBodyClassForMobileSafari}
              >
                {currentText.length > 0
                  ? currentText
                  : tenantData?.stripeConnect?.stripeConnectRegisterText !== undefined
                  ? tenantData?.stripeConnect?.stripeConnectRegisterText
                  : ''}
              </textarea>
            </div>
            <Button
              isPending={isLoading}
              disabled={isLoading}
              className="w-full mt-30"
              onClick={() => updateStripeConnectText()}
            >
              Speichern
            </Button>
          </div>
        </div>
      </Overlay>

      {isPageLoaded ? (
        <>
          <div className={styles.header}>
            <div className={styles.buttons}>
              <Headline level={1} displayBackBtn goBack={() => history.push('/company')}>
                Abrechnung mit Kunden
              </Headline>
            </div>
          </div>
          {checkCount > 0 && !isAccountComplete ? (
            <div className="h-full w-full md:flex block">
              <div className={styles.loading}>
                <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
                <div>Der Account wird gerade eingerichtet. Bitte haben Sie noch einen Moment Geduld...</div>
              </div>
            </div>
          ) : isAccountComplete &&
            (tenantData?.stripeConnect?.stripeConnectMonthlyFee === undefined ||
              tenantData?.stripeConnect?.stripeConnectIsSmallCompany === undefined) ? (
            <div>
              <div className="font-bold text-25">Ihr Stripe Account wurde erfolgreich angelegt!</div>
              <div className="pt-40 font-bold text-18">Nächste Schritte:</div>
              <div
                className="pt-10 flex space-x-20 cursor-pointer"
                onClick={() =>
                  tenantData?.stripeConnect?.stripeConnectIsSmallCompany === undefined &&
                  setCompanyTypePopupClass('block')
                }
                aria-hidden="true"
              >
                {tenantData?.stripeConnect?.stripeConnectIsSmallCompany !== undefined ? (
                  <CheckCircleIcon width={20} height={20} className="my-auto text-accentColor" />
                ) : (
                  <div className="rounded-full w-20 h-20 border-2 border-accentColor my-auto cursor-pointer" />
                )}
                <div className="font-extralight">Setzen Sie die Einstellungen für die Rechnungsstellung</div>
              </div>
              <div
                className="pt-10 flex space-x-20 cursor-pointer"
                onClick={() =>
                  tenantData?.stripeConnect?.stripeConnectIsSmallCompany !== undefined &&
                  setMontlyFeePopupClass('block')
                }
                aria-hidden="true"
              >
                {tenantData?.stripeConnect?.stripeConnectMonthlyFee !== undefined ? (
                  <CheckCircleIcon width={20} height={20} className="my-auto text-accentColor" />
                ) : (
                  <div className="rounded-full w-20 h-20 border-2 border-accentColor my-auto cursor-pointer" />
                )}
                <div className="font-extralight">
                  Bestimmen Sie den Preis für das monatliche Abonnement Ihrer Kunden
                </div>
              </div>
            </div>
          ) : isAccountComplete &&
            tenantData?.stripeConnect?.stripeConnectIsSmallCompany !== undefined &&
            (tenantData?.stripeConnect?.stripeConnectMonthlyFee !== undefined || isFeeSet) ? (
            <div>
              <div className="font-bold text-18">Registrierungs- und Kündigungsformular</div>
              <div className="font-extralight">
                Es wurden Links zu einem Registrierungsformular und einem Kündigungsformular in Ihrem Design generiert.
              </div>
              <div className="font-extralight">Diese Links können Sie an Ihre Kunden weitergeben.</div>
              <div className="pt-10 font-extralight w-full desktop:w-3/4">
                Für jeden Kunden, der sich über dieses Registrierungsformular registriert, wird der monatliche Beitrag
                über die ausgewählte Zahlungsmethode des Kunden eingezogen.
              </div>
              <div className="pt-10 font-extralight w-full desktop:w-3/4">
                Zusätzlich wird der Kunde automatisch in Ihre Mitgliederliste importiert und erhält eine E-Mail mit
                einem Einladungslink.
              </div>
              <div className="pt-10 font-extralight w-full desktop:w-3/4">
                Ihre Kunden haben die Möglichkeit, monatlich zum Ende des aktuellen Zeitraums zu kündigen. Wenn ein
                Kunde über das Formular kündigt, wird er automatisch am Ende des aktuellen Zeitraums gelöscht.
              </div>
              <div className="pt-10 font-extralight w-full desktop:w-3/4">
                Sie müssen sich nicht mehr darum kümmern, die Accounts manuell zu verwalten.
              </div>
              <div className="mt-20 bg-lightGray p-20 rounded-2xl w-fit">
                <div className="my-auto">Link zum Registrierungsformular:</div>
                <div className="flex flex-wrap gap-15">
                  <div className="my-auto font-bold text-20 truncate">
                    <a
                      href={`${
                        tenantData?.customDomain !== undefined
                          ? `https://${tenantData.customDomain}`
                          : 'https://business.tortija.de'
                      }/company-register/${tenant}`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-accentColor"
                    >
                      {tenantData?.customDomain !== undefined
                        ? `https://${tenantData.customDomain}`
                        : 'https://business.tortija.de'}
                      /company-register/{tenant}
                    </a>
                  </div>
                  <ClipboardCopyIcon
                    onClick={() => copyRegisterLink()}
                    width={20}
                    height={20}
                    className="text-textColor my-auto cursor-pointer"
                  />
                </div>
              </div>
              <div className="mt-20 bg-lightGray p-20 rounded-2xl w-fit">
                <div className="my-auto">Link zum Kündigungsformular:</div>
                <div className="flex flex-wrap gap-15">
                  <div className="my-auto font-bold text-20 truncate">
                    <a
                      href={`${
                        tenantData?.customDomain !== undefined
                          ? `https://${tenantData.customDomain}`
                          : 'https://business.tortija.de'
                      }/connect-cancel`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-accentColor"
                    >
                      {tenantData?.customDomain !== undefined
                        ? `https://${tenantData.customDomain}`
                        : 'https://business.tortija.de'}
                      /connect-cancel
                    </a>
                  </div>
                  <ClipboardCopyIcon
                    onClick={() => copyCancelLink()}
                    width={20}
                    height={20}
                    className="text-textColor my-auto cursor-pointer"
                  />
                </div>
              </div>
              <div className="pt-40 desktop:pt-60 font-bold text-18 pb-10">Einstellungen</div>
              <div className="flex flex-wrap space-x-20 bg-lightGray p-20 rounded-2xl w-fit">
                <div className="my-auto">Monatlicher Abonnement-Preis für Ihre Kunden (inkl. MwSt.)</div>
                <div className="font-bold text-20">
                  {changedMonthlyFeeValue > 0
                    ? changedMonthlyFeeValue
                    : tenantData?.stripeConnect?.stripeConnectMonthlyFee}{' '}
                  € <sup>*</sup>
                </div>
                <div className="my-auto">
                  <PencilIcon
                    width={20}
                    height={20}
                    className="text-accentColor mx-auto cursor-pointer"
                    onClick={() => setEditMontlyFeePopupClass('block')}
                  />
                </div>
              </div>
              <div className="mt-10 bg-lightGray p-20 rounded-2xl w-fit">
                <div className="my-auto">
                  <div>Kostenloser Zeitraum in Tagen</div>
                  <div className="font-extralight text-12">
                    Optional können Sie Ihren Kunden die App für einen kostenlosen Zeitraum (in Tagen) zur Verfügung
                    stellen. Der Kunde wird erst nach Ablauf der definierten Tage das erste mal abgerechnet.
                  </div>
                </div>
                <div className="flex space-x-20 pt-10">
                  <div className="font-bold text-20">
                    {tenantData?.stripeConnect?.stripeConnectTrialDays === undefined ? (
                      <div>Deaktiviert</div>
                    ) : (
                      <div>
                        {changedTrialValue > 0 ? changedTrialValue : tenantData?.stripeConnect?.stripeConnectTrialDays}{' '}
                        Tage
                      </div>
                    )}
                  </div>
                  <div className="my-auto">
                    <PencilIcon
                      width={20}
                      height={20}
                      className="text-accentColor mx-auto cursor-pointer"
                      onClick={() => setEditTrialPopupClass('block')}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap mt-10 gap-20 gap-y-10 bg-lightGray p-20 rounded-2xl w-fit">
                <div className="my-auto">E-Mail Adresse für Benutzer-Registrierungen</div>
                <div className="font-bold text-20 truncate">
                  {changedEmailValue.length > 0
                    ? changedEmailValue
                    : tenantData?.stripeConnect?.stripeConnectRegisterEmail !== undefined
                    ? tenantData?.stripeConnect?.stripeConnectRegisterEmail
                    : tenantData?.company?.email}
                </div>
                <div className="my-auto">
                  <PencilIcon
                    onClick={() => setEditEmailPopupClass('block')}
                    width={20}
                    height={20}
                    className="text-accentColor mx-auto cursor-pointer"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mt-10 gap-20 gap-y-10 bg-lightGray p-20 rounded-2xl w-fit">
                <div className="my-auto">Registrierungsformular: Überschrift</div>
                <div className="font-bold text-20">
                  {changedHeadlineValue.length > 0
                    ? changedHeadlineValue
                    : tenantData?.stripeConnect?.stripeConnectRegisterHeadline !== undefined
                    ? tenantData?.stripeConnect?.stripeConnectRegisterHeadline
                    : 'Standard-Überschrift'}
                </div>
                <div className="my-auto">
                  <PencilIcon
                    onClick={() => setEditHeadlinePopupClass('block')}
                    width={20}
                    height={20}
                    className="text-accentColor mx-auto cursor-pointer"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mt-10 gap-20 gap-y-10 bg-lightGray p-20 rounded-2xl w-fit">
                <div className="my-auto">Registrierungsformular: Text unterhalb der Überschrift</div>
                {changedTextValue.length > 0 ? (
                  <div
                    className="font-bold text-20"
                    dangerouslySetInnerHTML={{
                      __html: changedTextValue.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                    }}
                  />
                ) : tenantData?.stripeConnect?.stripeConnectRegisterText !== undefined ? (
                  <div
                    className="font-bold text-20"
                    dangerouslySetInnerHTML={{
                      __html: tenantData?.stripeConnect?.stripeConnectRegisterText.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                    }}
                  />
                ) : (
                  <div className="font-bold text-20">Standard-Text</div>
                )}

                <div className="my-auto">
                  <PencilIcon
                    onClick={() => setEditTextPopupClass('block')}
                    width={20}
                    height={20}
                    className="text-accentColor mx-auto cursor-pointer"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mt-10 gap-20 gap-y-10 bg-lightGray p-20 rounded-2xl w-fit">
                <div className="my-auto">Mehrwertsteuer auf Rechnungen</div>
                <div className="font-bold text-20">
                  {tenantData.stripeConnect.stripeConnectIsSmallCompany === true ? 'Kleinunternehmer (0 %)' : '19 %'}
                </div>
                <div className="my-auto">
                  <PencilIcon
                    onClick={() => setEditCompanyTypePopupClass('block')}
                    width={20}
                    height={20}
                    className="text-accentColor mx-auto cursor-pointer"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mt-10 gap-20 gap-y-10 bg-lightGray p-20 rounded-2xl w-fit">
                <div className="my-auto">Mail an Nutzer: Absender E-Mail Adresse</div>
                <div className="font-bold text-20 truncate">
                  {changedMailFromValue.length > 0
                    ? changedMailFromValue
                    : tenantData?.stripeConnect?.stripeConnectMailFrom !== undefined
                    ? tenantData?.stripeConnect?.stripeConnectMailFrom
                    : 'info@tortija.de'}
                </div>
                <div className="my-auto">
                  <PencilIcon
                    onClick={() => setMailFromPopupClass('block')}
                    width={20}
                    height={20}
                    className="text-accentColor mx-auto cursor-pointer"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mt-10 gap-20 gap-y-10 bg-lightGray p-20 rounded-2xl w-fit">
                <div className="my-auto">Mail an Nutzer: Absender Name</div>
                <div className="font-bold text-20 truncate">
                  {changedMailNameValue.length > 0
                    ? changedMailNameValue
                    : tenantData?.stripeConnect?.stripeConnectMailName !== undefined
                    ? tenantData?.stripeConnect?.stripeConnectMailName
                    : 'Standard Name'}
                </div>
                <div className="my-auto">
                  <PencilIcon
                    onClick={() => setMailNamePopupClass('block')}
                    width={20}
                    height={20}
                    className="text-accentColor mx-auto cursor-pointer"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mt-10 gap-20 gap-y-10 bg-lightGray p-20 rounded-2xl w-fit">
                <div className="my-auto">Mail an Nutzer: Betreff</div>
                <div className="font-bold text-20 truncate">
                  {changedMailSubjectValue.length > 0
                    ? changedMailSubjectValue
                    : tenantData?.stripeConnect?.stripeConnectMailSubject !== undefined
                    ? tenantData?.stripeConnect?.stripeConnectMailSubject
                    : `${companyData?.companyName ?? '[Name des Unternehmens]'} Registrierung`}
                </div>
                <div className="my-auto">
                  <PencilIcon
                    onClick={() => setMailSubjectPopupClass('block')}
                    width={20}
                    height={20}
                    className="text-accentColor mx-auto cursor-pointer"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mt-10 gap-20 gap-y-10 bg-lightGray p-20 rounded-2xl w-fit">
                <div className="my-auto">Mail an Nutzer: Text unterhalb der Ansprache</div>
                <div className="font-bold text-20 truncate">
                  {changedMailTextValue.length > 0 ? (
                    <div
                      className="font-bold text-20"
                      dangerouslySetInnerHTML={{
                        __html: changedMailTextValue.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                      }}
                    />
                  ) : tenantData?.stripeConnect?.stripeConnectMailText !== undefined ? (
                    <div
                      className="font-bold text-20"
                      dangerouslySetInnerHTML={{
                        __html: tenantData?.stripeConnect?.stripeConnectMailText.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                      }}
                    />
                  ) : (
                    <div className="font-bold text-20">Standard-Text</div>
                  )}
                </div>
                <div className="my-auto">
                  <PencilIcon
                    onClick={() => setMailTextPopupClass('block')}
                    width={20}
                    height={20}
                    className="text-accentColor mx-auto cursor-pointer"
                  />
                </div>
              </div>
              <div>
                * Alle Preise sind in EURO angegeben und verstehen sich inkl. der geltenden gesetzlichen Mehrwertsteuer
              </div>
              <div className="pt-40 desktop:pt-60 font-bold text-18 pb-10">Aktuelle Zahlungen und Abonnements</div>
              <div className="font-extralight">
                Sie können den Status Ihrer Zahlungen und Abonnements in Ihrem Stripe Account prüfen.
              </div>
              <Link
                to={{
                  pathname: 'https://dashboard.stripe.com/dashboard',
                }}
                target="_blank"
              >
                <Button className="py-10 mt-10">Zum Stripe Account</Button>
              </Link>
            </div>
          ) : (
            <div>
              <div className="font-bold text-30">Rechnen Sie direkt mit Ihren Kunden ab mit</div>
              <div className="font-bold text-30 text-accentColor">Tortija Billing</div>
              <div className="w-full desktop:w-3/4">
                <p className="pt-40 mb-20">
                  Es besteht die Möglichkeit, dass Sie über unsere Plattform direkt mit Ihren Kunden monatlich
                  abrechnen.
                </p>
                <p>Sie können dabei selbst festlegen wie hoch der monatliche Beitrag für Ihre Kunden sein soll.</p>
                <p className="pt-40 font-bold text-25">Zahlungsanbieter</p>
                <p>Für die sichere Bearbeitung von Zahlungen arbeiten wir mit "Stripe" zusammen.</p>
                <p className="pt-20">
                  Stripe ist der Marktführer für den Bereich Online-Zahlungsabwicklung. Stripe verarbeitet jährlich
                  Beträge in Milliardenhöhe für innovative Unternehmen auf der ganzen Welt. Mehr Informationen finden
                  sie hier:
                </p>
                <a href="https://stripe.com/de" target="_blank" rel="noreferrer" className="underline text-accentColor">
                  https://stripe.com/de
                </a>
                <div className="pt-40 font-bold text-25">Wie funktioniert es?</div>
                <div>
                  <div className="flex">
                    <div className="w-30 text-accentColor font-bold text-20">1.</div>
                    <div className="my-auto">
                      Nach Klick auf den Button "Jetzt starten" werden Sie auf eine Stripe Seite weitergeleitet, wo Sie
                      einen kostenlosen Zugang erstellen.
                    </div>
                  </div>
                  <div className="flex pt-20">
                    <div className="w-30 text-accentColor font-bold text-20">2.</div>
                    <div className="my-auto">
                      Nach der Erstellung des Accounts können Sie den Beitrag festlegen, den Sie Ihren Kunden monatlich
                      in Rechnung stellen möchten.
                    </div>
                  </div>
                  <div className="flex pt-20">
                    <div className="w-30 text-accentColor font-bold text-20">3.</div>
                    <div className="my-auto">
                      Wir generieren in Ihrem Branding einen Link zu einem Anmelde- und Kündigungsformular, was Sie dann
                      an Ihre Kunden weitergeben können.
                    </div>
                  </div>
                  <div className="flex pt-20">
                    <div className="w-30 text-accentColor font-bold text-20">4.</div>
                    <div className="my-auto">
                      Ihre Kunden registrieren sich über das Formular, wählen eine Zahlungsmethode aus und die Beiträge
                      werden automatisch eingezogen.
                    </div>
                  </div>
                  <div className="flex pt-20">
                    <div className="w-30 text-accentColor font-bold text-20">5.</div>
                    <div className="my-auto">
                      Sie können Ihre Umsätze jederzeit über Ihr Stripe-Konto einsehen und auch die Intervalle
                      bestimmen, in denen Sie Ihre Umsätze ausgezahlt bekommen.
                    </div>
                  </div>
                </div>
                <p className="pt-40 font-bold text-25">Was kostet es?</p>
                <p>
                  Pro Transaktion (via Kreditkarte oder SEPA) werden 5 % Tortija Billing Plattformgebühr berechnet +
                  Stripe Gebühren. Die Stripe Gebühren sind je nach ausgewählter Zahlungsmethode unterschiedlich.
                </p>
                <p className="pt-15">
                  Bei SEPA Lastschrift beträgt die Gebühr z.B. 0,35 €<sup>*</sup> pro Transaktion und bei
                  Kredikarten-Zahlung 1,5 % + 0,25 €<sup>*</sup> (Stand: November 2023).
                </p>
                <p>Die genauen Gebühren können Sie auf der Stripe Website einsehen:</p>
                <a
                  href="https://stripe.com/de/pricing/local-payment-methods"
                  target="blank"
                  className="underline text-accentColor"
                >
                  https://stripe.com/de/pricing/local-payment-methods
                </a>
                <p className="pt-20 font-bold">Beispiel-Rechnung:</p>
                <p>
                  Sie berechnen Ihren Kunden 9,99 €<sup>*</sup> im Monat. Es würden sich folgende Gebühren bei einer
                  Zahlung über SEPA Lastschrift ergeben:
                </p>
                <p>
                  9,99 €<sup>*</sup> abzüglich 5 % Tortija Billing Plattformgebühr (0,49 €<sup>*</sup>) abzüglich Stripe
                  Bearbeitungsgebühr (0,35 €<sup>*</sup>) = 9,15 €<sup>*</sup>
                </p>
                <p>
                  In diesem Beispiel würden Sie also nach Abzug aller Gebühren 9,15 €<sup>*</sup> erhalten.
                </p>
                <p className="pt-20 font-bold">Folgende Zahlungsmethoden werden aktuell unterstützt:</p>
                <ul>
                  <li>- SEPA Lastschrift</li>
                  <li>- Kreditkarte</li>
                </ul>

                <div className="font-extralight text-14 pt-10">
                  * Alle Preise sind in EURO angegeben und verstehen sich inkl. der geltenden gesetzlichen
                  Mehrwertsteuer.
                </div>

                <div className="pt-40">
                  <Button isPending={isLoading} onClick={() => startOnboarding()}>
                    Jetzt starten
                  </Button>
                  {isLoading && <div>Bitte warten Sie einen Moment. Sie werden gleich weitergeleitet...</div>}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="h-full w-full md:flex block">
          <div className={styles.loading}>
            <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerBilling;

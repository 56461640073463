import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Profile from 'pages/Profile';
import InBodyOverview from 'pages/Profile/components/analyze/components/InBody/components/InBodyOverview';
import AnalyzeInBody from 'pages/Profile/components/analyze/components/InBody';
import FaqRoutes from './faqRoutes';

const ProfileRoutes: React.FC = () => (
  <Switch>
    <Route exact path="/profile" component={Profile} />
    <Route path="/profile/faq" component={FaqRoutes} />
    <Route path="/profile/inbody/:id" exact component={InBodyOverview} />
    <Route path="/profile/inbody" component={AnalyzeInBody} />
    <Redirect to="/profile" />
  </Switch>
);

export default ProfileRoutes;

import React, { useContext } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SwitchSelector from 'react-switch-selector';
import { ChevronDownIcon, ExternalLinkIcon } from '@heroicons/react/solid';
import { defaultTextColor, defaultButtonTextColor } from 'layouts/Theme';
import Button from 'components/Button';

type Props = {
  linkListContentClass: string;
  linkListChevronClass: string;
  setFunctionsChevronClass: any;
  setFunctionsContentClass: any;
  setMacroChevronClass: any;
  setMacroContentClass: any;
  setLinkListChevronClass: any;
  setLinkListContentClass: any;
  changeLinkPosition: any;
  openNewLinkPopup: any;
  openEditLinkPopup: any;
  startDeleteLinkItem: any;
  setLocationsContentClass: any;
  setLocationsChevronClass: any;
  setInvitationContentClass: any;
  setInvitationChevronClass: any;
};

const SettingsLinkList: React.FC<Props> = ({
  linkListContentClass,
  setFunctionsChevronClass,
  setMacroChevronClass,
  setMacroContentClass,
  setLinkListChevronClass,
  setFunctionsContentClass,
  linkListChevronClass,
  setLinkListContentClass,
  changeLinkPosition,
  openNewLinkPopup,
  openEditLinkPopup,
  startDeleteLinkItem,
  setLocationsContentClass,
  setLocationsChevronClass,
  setInvitationChevronClass,
  setInvitationContentClass,
}) => {
  const { tenantData, setTenantData, theme } = useContext(AuthContext);
  const { t } = useTranslation();

  const linkListOption = [
    {
      label: 'Mein Club',
      value: 'club',
    },
    {
      label: t('Dashboard'),
      value: 'dashboard',
    },
  ];

  // Toggle LinkList area
  const toggleLinkList = (): void => {
    if (linkListContentClass === 'hidden') {
      setLinkListContentClass('block');
      setLinkListChevronClass('text-accentColor transition duration-300 transform rotate-180');
    } else {
      setLinkListContentClass('hidden');
      setLinkListChevronClass('text-accentColor transition duration-300 transform rotate-360');
    }

    setMacroChevronClass('text-accentColor');
    setMacroContentClass('hidden');

    setFunctionsChevronClass('text-accentColor');
    setFunctionsContentClass('hidden');

    setLocationsChevronClass('text-accentColor');
    setLocationsContentClass('hidden');

    setInvitationChevronClass('text-accentColor');
    setInvitationContentClass('hidden');
  };

  return (
    <>
      <div className="mt-30 rounded-3xl bg-lightGray p-20">
        <div className="flex space-x-10 cursor-pointer" onClick={() => toggleLinkList()} aria-hidden="true">
          <div className="font-bold text-20">Linkliste</div>
          <div>
            <ChevronDownIcon className={linkListChevronClass} height={30} width={30} />
          </div>
        </div>
        <div className={linkListContentClass}>
          <div className="font-extralight pt-5 pb-20">Sie können hier eine Liste an Links für Ihre Kunden pflegen.</div>
          <div className="border border-accentColor rounded-2xl p-15">
            <div className="font-extralight">An welcher Stelle sollen die Links ausgespielt werden?</div>
            <div className="max-w-400 pt-20 pb-10">
              <SwitchSelector
                options={linkListOption}
                onChange={changeLinkPosition}
                initialSelectedIndex={
                  tenantData?.settings?.linkListPosition === undefined ||
                  tenantData?.settings?.linkListPosition === 'club'
                    ? 0
                    : 1
                }
                fontSize={20}
                selectionIndicatorMargin={0}
                selectedFontColor={theme?.buttonTextColor ?? defaultButtonTextColor}
                fontColor={theme?.textColor ?? defaultTextColor}
              />
            </div>
          </div>
          <div className="pt-20 pb-10">
            <Button className="py-10" onClick={() => openNewLinkPopup()}>
              Neuen Link hinzufügen
            </Button>
          </div>
          {tenantData?.settings?.linkList !== undefined && tenantData?.settings?.linkList?.length > 0 && (
            <div className="font-bold text-20 pt-20 pb-10">Linkliste</div>
          )}
          {tenantData?.settings?.linkList
            ?.sort((a, b) => (b.sortNumber ?? 0) - (a.sortNumber ?? 0))
            .map((item: SettingsLinkList, index: number) => (
              <div key={index} className="rounded-2xl border border-accentColor mb-15 p-20">
                <div className="flex">
                  <div className="w-150 font-bold">Name:</div>
                  <div>{item.name}</div>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-150 font-bold">Adresse (URL):</div>
                  <div className="cursor-pointer w-full">
                    <Link
                      to={{
                        pathname: item.url,
                      }}
                      target="_blank"
                      className="flex"
                    >
                      <div className="truncate max-w-quarter">{item.url}</div>
                      <div className="pl-5 my-auto">
                        <ExternalLinkIcon className="text-accentColor" height={20} width={20} />
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="flex space-x-20 pt-20">
                  <Button
                    className="py-5 text-12"
                    onClick={() => openEditLinkPopup(item.id, item.name, item.url, item.sortNumber ?? 0)}
                  >
                    Bearbeiten
                  </Button>
                  <Button className="py-5 text-12" onClick={() => startDeleteLinkItem(item.id)}>
                    Löschen
                  </Button>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default SettingsLinkList;

import React, { useContext, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LogoImg from 'assets/img/svg/logo.svg';
import FacebookIcon from 'assets/img/svg/facebook.svg';
import AppleIcon from 'assets/img/svg/apple.svg';
import Button from 'components/Button';
import firebase from 'services/firebase';
import { AuthContext } from 'providers/AuthProvider';
import ReactLoading from 'react-loading';
import { toast } from 'react-toast';
import { defaultTextColor } from 'layouts/Theme';
import styles from './styles.module.scss';

const LoginOverview: React.FC = () => {
  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const { theme } = useContext(AuthContext);
  const history = useHistory();
  const [isFacebookLogging, setIsFacebookLogging] = useState(false);
  const [isAppleLogging, setIsAppleLogging] = useState(false);
  const [isRedirectLogging, setIsRedirectLogging] = useState(false);
  const logo = theme?.logo ?? LogoImg;

  const signInWithApple = async () => {
    setIsAppleLogging(true);
    const db = firebase.firestore();
    try {
      const provider = new firebase.auth.OAuthProvider('apple.com');
      const userCredential: firebase.auth.UserCredential = await firebase.auth().signInWithPopup(provider);

      const isExist = (await db.collection('users').doc(userCredential.user?.uid).get()).exists;
      if (!isExist) {
        await db.collection('users').doc(userCredential.user?.uid).set({
          email: userCredential.user?.email,
          fullName: userCredential.user?.displayName,
          role: 2,
          profileComplete: false,
          created: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
      authContext.setUser(userCredential.user as firebase.User);
      const userInfo = (await db.collection('users').doc(userCredential.user?.uid).get()).data();
      authContext.setUserData(userInfo as UserInfo);
      setIsAppleLogging(false);
      history.push('/');
    } catch (error: any) {
      setIsAppleLogging(false);
      toast.warn(error.message);
    }
  };

  const signInWithFacebook = async (event: any) => {
    setIsFacebookLogging(true);
    const db = firebase.firestore();
    try {
      const facebookProvider = new firebase.auth.FacebookAuthProvider();
      facebookProvider.addScope('email');
      // const userCredential: firebase.auth.UserCredential = await firebase.auth().signInWithPopup(facebookProvider);
      const getUserData = await firebase.auth().signInWithRedirect(facebookProvider);

      const userCredential = await firebase.auth().getRedirectResult();

      const isExist = (await db.collection('users').doc(userCredential.user?.uid).get()).exists;
      if (!isExist) {
        await db
          .collection('users')
          .doc(userCredential.user?.uid)
          .set({
            email:
              userCredential.user?.email === null
                ? userCredential.user?.providerData[0]?.email
                : userCredential.user?.email,
            fullName: userCredential.user?.displayName,
            role: 2,
            profileComplete: false,
            cookieBannerType: 'initial',
            created: firebase.firestore.FieldValue.serverTimestamp(),
          });
      }
      authContext.setUser(userCredential.user as firebase.User);
      const userInfo = (await db.collection('users').doc(userCredential.user?.uid).get()).data();
      authContext.setUserData(userInfo as UserInfo);
      setIsFacebookLogging(false);
      event.preventDefault();
      history.push('/');
    } catch (error: any) {
      setIsFacebookLogging(false);
      toast.warn(error.message);
    }
  };

  const getUserData = async () => {
    const db = firebase.firestore();
    const userCredential = await firebase.auth().getRedirectResult();

    if (userCredential.user?.uid !== undefined) {
      setIsRedirectLogging(true);
      try {
        const isExist = (await db.collection('users').doc(userCredential.user?.uid).get()).exists;
        if (!isExist) {
          await db.collection('users').doc(userCredential.user?.uid).set({
            email: userCredential.user?.email,
            fullName: userCredential.user?.displayName,
            role: 2,
            profileComplete: false,
            cookieBannerType: 'initial',
            created: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }
        authContext.setUser(userCredential.user);
        const userInfo = (await db.collection('users').doc(userCredential.user?.uid).get()).data();
        authContext.setUserData(userInfo as UserInfo);
        setIsRedirectLogging(false);
        history.push('/');
      } catch (error: any) {
        setIsAppleLogging(false);
        toast.warn(error.message);
      }
    }
  };

  useEffect(() => {
    getUserData();
  }, [firebase.auth().getRedirectResult()]);

  return (
    <>
      {isRedirectLogging ? (
        <div className="h-full w-full md:flex block">
          <div className={styles.loading}>
            <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <img className={styles.logo} width={160} height={140} src={logo} alt="" />
          {window.location.hostname === 'business.tortija.de' && (
            <div className="text-center font-extralight pt-5">Business</div>
          )}

          <div className={styles.content}>
            {/* <Button type="button" buttonStyle="white" className={styles.button}>
    <img src={GoogleIcon} className={styles.socialLogo} width={27} height={27} alt="" />
    <span className={styles.text}>{t('Sign in with google')}</span>
  </Button> */}

            {/*  <Button
              type="button"
              buttonStyle="white"
              className={styles.button}
              onClick={e => signInWithFacebook(e)}
              isPending={isFacebookLogging}
              disabled={isFacebookLogging}
            >
              <img src={FacebookIcon} className={styles.socialLogo} width={27} height={27} alt="" />
              <span className={styles.text}>{t('Sign in with Facebook')}</span>
            </Button>

            <Button
              type="button"
              buttonStyle="white"
              className={styles.button}
              onClick={signInWithApple}
              isPending={isAppleLogging}
              disabled={isAppleLogging}
            >
              <img src={AppleIcon} className={styles.socialLogo} width={27} height={27} alt="" />
              <span className={styles.text}>{t('Sign in with apple')}</span>
            </Button>
*/}
            <Button
              type="button"
              className={styles.button}
              onClick={() => {
                history.push('/auth/login');
              }}
            >
              <span className={styles.text}>{t('Sign in with email')}</span>
            </Button>

            {window.location.hostname === 'app.tortija.de' ? (
              <>
                <Button
                  type="button"
                  buttonStyle="white"
                  className={styles.button}
                  onClick={() => {
                    history.push('/auth/register/code');
                  }}
                >
                  <span className={styles.text}>Mit Zugangscode registrieren</span>
                </Button>

                <div className={styles.comment}>
                  <span>{t("Don't you have account?")}</span>
                  <Link to="/auth/register" className={styles.link}>
                    {t('Sign up')}
                  </Link>
                </div>
              </>
            ) : (
              <Button
                type="button"
                buttonStyle="white"
                className={styles.button}
                onClick={() => {
                  history.push('/auth/register');
                }}
              >
                <span className={styles.text}>{t('Sign up')}</span>
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default LoginOverview;

import React, { ReactNode, useContext } from 'react';
import classNames from 'classnames';
import ReactLoading from 'react-loading';
import { defaultTextColor } from 'layouts/Theme';
import { AuthContext } from 'providers/AuthProvider';
import styles from './style.module.scss';

type Props = {
  type?: 'submit' | 'button' | 'reset' | undefined;
  buttonStyle?: 'primary' | 'white' | 'dark' | 'text';
  className?: string;
  children: ReactNode;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  isPending?: boolean;
};

export default function Button({
  type = 'submit',
  className = '',
  children,
  disabled = false,
  buttonStyle = 'primary',
  onClick = () => {},
  isPending = false,
}: Props) {
  const { theme } = useContext(AuthContext);
  return (
    <button
      // eslint-disable-next-line
      type={type}
      disabled={disabled}
      className={classNames(
        className,
        styles.buttonStyle,
        { [styles.primaryAddition]: buttonStyle === 'primary' },
        { [styles.colorAddition]: buttonStyle === 'white' },
        { [styles.colorAddition]: buttonStyle === 'dark' },
        { [styles.textStyle]: buttonStyle === 'text' }
      )}
      onClick={onClick}
    >
      {isPending ? (
        <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
      ) : (
        children
      )}
    </button>
  );
}

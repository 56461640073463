import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from '@heroicons/react/solid';
import Styles from './style.module.scss';

type Props = {
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  className?: string;
  searchValue: string;
  thisRef?: any;
  inputType?: string;
};

const setBodyClassForMobileSafari = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    document.body.classList.add('keyboard-open');
  }
};

const removeBodyClassForMobileSafari = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    document.body.classList.remove('keyboard-open');
  }
};

const SearchBox = ({ className = '', onChange, thisRef, searchValue, inputType }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('relative', className)}>
      <input
        ref={thisRef}
        tabIndex={0}
        className={Styles.inputStyle}
        onChange={onChange}
        onFocus={setBodyClassForMobileSafari}
        onBlur={removeBodyClassForMobileSafari}
        defaultValue={searchValue}
        placeholder={t('Search')}
        type={inputType !== undefined ? inputType : 'search'}
        enterKeyHint={inputType !== undefined ? 'done' : 'search'}
      />
      <div className={Styles.searchWrapper}>
        <SearchIcon className={Styles.searchIcon} height={27} width={27} />
      </div>
    </div>
  );
};

export default SearchBox;

import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement, CardElement } from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';

import Button from 'components/Button';
import styles from './styles.module.scss';

type Props = {
  connectData: any;
  userMail: string;
  userName: string;
  userPassword: string;
  businessName: string;
  tenant: string;
  adminMail: string;
  invitationLink: string;
  mailSubject: string;
  mailName: string;
  mailText: any;
  mailFrom: string;
  clientSecret: any;
};

const CheckoutFormCustomerBilling: React.FC<Props> = ({
  connectData,
  userMail,
  userName,
  userPassword,
  businessName,
  tenant,
  adminMail,
  invitationLink,
  mailSubject,
  mailName,
  mailFrom,
  mailText,
  clientSecret,
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const { t } = useTranslation();
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    localStorage.setItem('name', userName);
    localStorage.setItem('accountId', connectData?.stripeConnectAccountId);
    localStorage.setItem('userMail', userMail);
    localStorage.setItem('userPw', userPassword);
    localStorage.setItem('tenant', tenant);
    localStorage.setItem('companyName', businessName);
    localStorage.setItem('adminMail', adminMail);
    localStorage.setItem('invitationLink', invitationLink);
    localStorage.setItem('mailSubject', mailSubject);
    localStorage.setItem('mailName', mailName);
    localStorage.setItem('mailFrom', mailFrom);
    localStorage.setItem('mailText', mailText);
  }, []);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsPending(true);

    try {
      if (clientSecret.includes('seti_')) {
        const { error } = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: `${process.env.REACT_APP_BASE_URL}/purchase-connect/success`,
          },
        });

        if (error) {
          toast.error('Es ist leider etwas schief gelaufen. Melde dich bitte bei uns!');
          setIsPending(false);
          return;
        }
      } else {
        // If clientSecret is for a PaymentIntent
        const confirmPaymentRes = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${process.env.REACT_APP_BASE_URL}/purchase-connect/success`,
          },
        });

        if (confirmPaymentRes.error) {
          toast.error('Es ist leider etwas schief gelaufen. Melde dich bitte bei uns!');
          setIsPending(false);
          return;
        }
      }
    } catch (error) {
      console.log('error: ', error);
      toast.error('Es ist leider etwas schief gelaufen. Melde dich bitte bei uns!');
    }

    setIsPending(false);
  };

  return (
    <div>
      <PaymentElement options={{ business: { name: businessName } }} />
      <Button
        onClick={() => handleSubmit()}
        className="w-full rounded-full py-15 mt-30"
        isPending={isPending}
        disabled={isPending}
      >
        Zahlungspflichtig bestellen
      </Button>
    </div>
  );
};

export default CheckoutFormCustomerBilling;

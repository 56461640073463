import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'components/Table';
import { entryCodeCollection } from 'shared/constants/global';
import { generateRandomUid } from 'shared/functions/global';
import { toast } from 'react-toast';
import Headline from 'components/Headline';
import firebase from 'services/firebase';
import SearchBox from 'components/SearchBox';
import ReactLoading from 'react-loading';
import CustomUserInput from 'components/CustomUserInput';
import Button from 'components/Button';
import SwitchButton from 'components/SwitchButton';
import moment from 'moment';
import styles from './styles.module.scss';

const skeletonTable: TableDataType[][] = new Array(3).fill([
  { type: 'text', value: '' },
  { type: 'text', value: '' },
  { type: 'image', value: '' },
  { type: 'qr', value: '' },
]);

export default function EntryCodes() {
  const { t } = useTranslation();

  const header = ['Codes', 'Firma', 'Email', 'Datum', 'Menge', 'Typ', 'Laufzeit (Tage)', 'Wieviele benutzt?'];

  const [couponData, setCouponData] = useState<TableDataType[][]>([]);
  const [originalCouponData, setOriginalCouponData] = useState<TableDataType[][]>([]);
  const [isPending, setIsPending] = useState(false);
  const [isMultiPending, setIsMultiPending] = useState(false);
  const [isNewCode, setIsNewCode] = useState(false);
  const [isMulti, setIsMulti] = useState(false);

  const [currentAmount, setCurrentAmount] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [currentDuration, setCurrentDuration] = useState('');
  const [currentCompanyName, setCurrentCompanyName] = useState('');

  const amountRef = useRef<HTMLInputElement>(null);
  const companyNameRef = useRef<HTMLInputElement>(null);
  const durationRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  const getCouponData = async () => {
    setIsPending(true);

    const data: TableDataType[][] = (await entryCodeCollection.get()).docs.map(item => {
      return [
        {
          type: item.data().codeType === 'single' ? 'text' : 'entryCode',
          value: item.data().codeType === 'single' ? item.data().code : item.data().uid,
        },
        { type: 'text', value: item.data().companyName },
        { type: 'text', value: item.data().email.toString() },
        { type: 'text', value: moment.unix(item.data().date).format('DD.MM.YYYY') },
        { type: 'text', value: item.data().amount.toString() },

        { type: 'text', value: item.data().codeType === 'single' ? 'Einzelcode' : 'Multi-Code' },
        { type: 'text', value: item.data().durationDays.toString() },
        {
          type: 'text',
          value: `${item.data().count} / ${item.data().amount}`,
        },
      ];
    });

    setCouponData(data);
    setOriginalCouponData(data);
    setIsPending(false);
  };

  // Change form of nutrition items
  const setMultiValue = (status: boolean, item: string): void => {
    setIsMulti(status);
  };

  const onSubmit = async () => {
    setIsNewCode(false);
    try {
      const db = firebase.firestore();

      if (isMulti) {
        setIsMultiPending(true);
        const thisNewDocumentId = generateRandomUid();
        let isBatchValid = true;

        await db
          .collection('entryCodes')
          .doc(thisNewDocumentId)
          .set({
            code: '',
            companyName: currentCompanyName,
            email: currentEmail,
            itemType: 'primary',
            codeType: 'multi',
            date: moment().utc().unix(),
            amount: parseFloat(currentAmount),
            durationDays: parseFloat(currentDuration),
            count: 0,
            uid: thisNewDocumentId,
          });

        let counter = 0;
        let commitCounter = 0;
        const batches = [] as any;
        const codeArray = [] as any;
        batches[commitCounter] = db.batch();
        const ref = firebase.firestore().collection('entryCodes');

        /* eslint-disable no-await-in-loop */
        for (let i = 1; i <= parseFloat(currentAmount); i += 1) {
          if (counter <= 498) {
            counter += 1;
            const couponCodeLoop = (Math.floor(Math.random() * (9999999 - 1000000 + 1)) + 1000000).toString();
            const docDataLoop = await firebase
              .firestore()
              .collection('entryCode')
              .where('coupon', '==', couponCodeLoop)
              .get();
            const thisNewDocumentIdLoop = generateRandomUid();

            codeArray.push(couponCodeLoop);

            if (docDataLoop.empty) {
              const thisRef = ref.doc(thisNewDocumentIdLoop);
              batches[commitCounter].set(thisRef, {
                code: couponCodeLoop,
                companyName: currentCompanyName,
                itemType: 'code',
                codeType: 'multi',
                date: moment().utc().unix(),
                uid: thisNewDocumentIdLoop,
                parentUid: thisNewDocumentId,
                durationDays: parseFloat(currentDuration),
                amount: parseFloat(currentAmount),
              });
            } else {
              isBatchValid = false;
            }
          } else {
            counter = 0;
            commitCounter += 1;
            batches[commitCounter] = db.batch();
          }
        }

        // Check if array has no duplicate codes
        const uniqueSet = new Set(codeArray);
        const hasArrayNoDuplicates = uniqueSet.size === codeArray.length;

        if (isBatchValid && hasArrayNoDuplicates) {
          for (let i = 0; i < batches.length; i += 1) {
            batches[i].commit().then(function () {
              console.count('wrote batch');
              getCouponData();
              setIsPending(false);
              setIsMultiPending(false);
            });
          }
        } else {
          toast.error('Es ist leider etwas schief gelaufen. Bitte probiere es erneut!');
        }
      } else {
        setIsPending(true);
        const couponCode = (Math.floor(Math.random() * (9999999 - 1000000 + 1)) + 1000000).toString();
        const docData = await firebase.firestore().collection('entryCode').where('coupon', '==', couponCode).get();
        const thisNewDocumentId = generateRandomUid();

        if (docData.empty) {
          await db
            .collection('entryCodes')
            .doc(thisNewDocumentId)
            .set({
              code: couponCode,
              companyName: currentCompanyName,
              email: currentEmail,
              itemType: 'primary',
              codeType: 'single',
              date: moment().utc().unix(),
              amount: parseFloat(currentAmount),
              durationDays: parseFloat(currentDuration),
              count: 0,
              uid: thisNewDocumentId,
            });

          getCouponData();
          setIsPending(false);
        } else {
          toast.error('Bitte versuche es erneut. Der Code war bereits vorhanden!');
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    } catch (error: any) {
      toast.error(error);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    }
  };

  const changeAmount = (event: any) => {
    const thisCurrentValue = event.target.value;

    setCurrentAmount(thisCurrentValue);
  };

  const changeCompanyName = (event: any) => {
    const thisCurrentValue = event.target.value;

    setCurrentCompanyName(thisCurrentValue);
  };

  const changeDuration = (event: any) => {
    const thisCurrentValue = event.target.value;

    setCurrentDuration(thisCurrentValue);
  };

  const changeEmail = (event: any) => {
    const thisCurrentValue = event.target.value;

    setCurrentEmail(thisCurrentValue);
  };

  const filterArrayByValue = (arr: any[][], filterValue: string): any[][] => {
    const thisFilterValue = filterValue.toLowerCase();

    const isValueFoundInInnerArr = (innerArr: any[]): boolean =>
      innerArr.some(obj => obj.value.toLowerCase().includes(thisFilterValue));

    return arr.filter(innerArr => isValueFoundInInnerArr(innerArr));
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filteredData = filterArrayByValue(originalCouponData, event.target.value);
    setCouponData(filteredData);
  };

  useEffect(() => {
    getCouponData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (amountRef.current) {
      amountRef.current.value = '';
    }

    if (emailRef.current) {
      emailRef.current.value = '';
    }

    if (companyNameRef.current) {
      companyNameRef.current.value = '';
    }

    if (durationRef.current) {
      durationRef.current.value = '';
    }

    setCurrentAmount('');
    setCurrentCompanyName('');
    setCurrentDuration('');
    setCurrentEmail('');
    setIsMulti(false);
    // eslint-disable-next-line
  }, [isNewCode]);

  return (
    <div className={styles.wrapper}>
      <Headline level={1} classLevel={3} className={styles.header}>
        Zugangscodes
      </Headline>

      <div className={isNewCode === true ? 'block' : 'hidden'}>
        <div className="py-20 flex space-x-30">
          <div className="my-auto w-350 text-18">Wieviele Codes sollen generiert werden?</div>
          <div className="my-auto w-250">
            <CustomUserInput
              thisRef={amountRef}
              name="description"
              textCenter={false}
              onChange={e => changeAmount(e)}
              type="text"
              placeHolder="Menge"
            />
          </div>
        </div>
        <div className="pt-10 pb-20 flex space-x-30">
          <div className="my-auto w-350 text-18">Firmenname</div>
          <div className="my-auto w-250">
            <CustomUserInput
              thisRef={companyNameRef}
              name="description"
              textCenter={false}
              onChange={e => changeCompanyName(e)}
              type="text"
              placeHolder="Firma"
            />
          </div>
        </div>
        <div className="pt-10 pb-20 flex space-x-30">
          <div className="my-auto w-350 text-18">Kontakt Email</div>
          <div className="my-auto w-250">
            <CustomUserInput
              thisRef={emailRef}
              name="description"
              textCenter={false}
              onChange={e => changeEmail(e)}
              type="text"
              placeHolder="Email"
            />
          </div>
        </div>
        <div className="pt-10 pb-30 flex space-x-30">
          <div className="my-auto w-350 text-18">Laufzeit (in Tagen)?</div>
          <div className="my-auto w-250">
            <CustomUserInput
              thisRef={durationRef}
              name="description"
              textCenter={false}
              onChange={e => changeDuration(e)}
              type="text"
              placeHolder="Laufzeit (Tage)"
            />
          </div>
        </div>
        <div className="w-450 pb-30 ">
          <SwitchButton notContainer label="Mehrere Codes generieren?" enabled={isMulti} onChange={setMultiValue} />
        </div>
        <div className="flex space-x-20 pb-20">
          <Button onClick={() => onSubmit()}>Generieren</Button>
          <Button buttonStyle="white" onClick={() => setIsNewCode(false)}>
            Abbrechen
          </Button>
        </div>
      </div>

      <div className={isNewCode === false ? 'py-20 block' : 'hidden'}>
        <div className="flex gap-30">
          <Button onClick={() => setIsNewCode(true)}>Zugangscode generieren</Button>
          <div>
            <SearchBox onChange={onSearchChange} searchValue="" inputType="search" />
          </div>
        </div>
      </div>

      {isPending || !couponData ? (
        <Table header={Array.from(new Array(4))} body={skeletonTable} className="w-full" isSkeleton />
      ) : isMultiPending ? (
        <div>
          <div className="flex justify-center items-center pt-20">
            <div>
              <div className="flex justify-center items-center">
                <ReactLoading type="bars" width={20} height={20} color="white" />
              </div>
              <div className="pt-20">
                Die Codes werden erstellt. Dies kann je nach Menge einen Augenblick dauern. Bitte lade die Seite nicht
                neu!
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Table header={header} body={couponData} className="w-full" />
        </>
      )}
    </div>
  );
}

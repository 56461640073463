import React, { useContext } from 'react';
import ReactLoading from 'react-loading';
import { AuthContext } from 'providers/AuthProvider';
import { defaultTextColor } from 'layouts/Theme';
import styles from './style.module.scss';

const Preloader = () => {
  const { theme } = useContext(AuthContext);
  return (
    <div className={styles.wrapper}>
      {theme?.logo && <img src={theme?.logo} alt="" loading="lazy" style={{ width: 200 }} />}
      <ReactLoading type="bubbles" width={50} height={20} color={theme?.textColor ?? defaultTextColor} />
    </div>
  );
};

export default Preloader;

import React, { useContext, useState, useEffect, useRef } from 'react';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  HeartIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
  XIcon,
  CloudDownloadIcon,
  CollectionIcon,
  CalendarIcon,
  DocumentDuplicateIcon,
  ShareIcon,
} from '@heroicons/react/outline';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import ButtonBack from 'components/ButtonBack';
import SwitchSelector from 'react-switch-selector';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import ReactLoading from 'react-loading';
import { toast } from 'react-toast';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import Icon from 'components/Icon';
import {
  handleOpenClosePopups,
  generateRandomUid,
  shareToFirebase,
  scrollToTop,
  updateTourToFirebase,
} from 'shared/functions/global';
import MakroCircles from 'components/MakroCircles';
import WeekOverview from 'components/WeekOverview';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import firebase from 'services/firebase';
import { defaultTextColor, defaultButtonTextColor } from 'layouts/Theme';
import Button from 'components/Button';
import MealCard from '../components/MealCard';
import HashTagBadge from '../components/HashTagBadge';
import IngridientPopupItem from '../components/IngridientPopupItem';

import styles from './styles.module.scss';

registerLocale('de', de);

type ParamsType = {
  id: string;
};

const PlanDetail: React.FC = () => {
  const { id } = useParams<ParamsType>();
  const { t } = useTranslation();
  const moment = extendMoment(Moment);
  const history = useHistory();
  const { userData, tenant, theme, user } = useContext(AuthContext);
  const [currentDayId, setCurrentDayId] = useState(1);
  const [jumpTopTop, setJumpToTop] = useState(false);
  const [excludeDateArray, setExcludeDateArray] = useState<any>([]);

  const tourRef = useRef<HTMLDivElement>(null);
  const tourRefMobile = useRef<HTMLDivElement>(null);

  const sharePopupRecipeInputRef = useRef<HTMLSpanElement>(null);

  const [shareMailArray, setShareMailArray] = useState<any>([]);

  const [currentSamplePlanCalories, setCurrentSamplePlanCalories] = useState('');
  const [currentSamplePlanCategory, setCurrentSamplePlanCategory] = useState('');

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
    },
    scrollTo: true,
    classes: 'shepherd-theme-dark',
    useModalOverlay: true,
  };

  function TourButton() {
    const tour = useContext(ShepherdTourContext);

    return (
      <div
        className="hidden"
        ref={tourRef}
        onClick={() => userData?.role === 2 && tour?.start()}
        onKeyDown={() => userData?.role === 2 && tour?.start()}
        aria-hidden="true"
      >
        <div>Test</div>
      </div>
    );
  }

  function TourButtonMobile() {
    const tourMobile = useContext(ShepherdTourContext);

    return (
      <div
        className="hidden"
        ref={tourRefMobile}
        onClick={() => userData?.role === 2 && tourMobile?.start()}
        onKeyDown={() => userData?.role === 2 && tourMobile?.start()}
        aria-hidden="true"
      >
        <div>Test</div>
      </div>
    );
  }

  const [currentPlan, setCurrentPlan] = useState<any>({
    startDate: {
      seconds: 0,
    },
    endDate: {
      seconds: 0,
    },
    dayEntries: [
      {
        breakfast: {
          ingredients: [],
          recipes: [],
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
          label: 'Frühstück',
        },
        lunch: {
          ingredients: [],
          recipes: [],
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
          label: 'Mittagessen',
        },
        dinner: {
          ingredients: [],
          recipes: [],
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
          label: 'Abendessen',
        },
        snacks: {
          ingredients: [],
          recipes: [],
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
          label: 'Snacks',
        },
        id: 1,
        name: 'Tag 1',
        kcal_total: 0,
        carbohydrates_total: 0,
        protein_total: 0,
        fat_total: 0,
        date: {
          seconds: 0,
        },
      },
    ],
  });

  const handleSetShareMail = (mailString: string) => {
    if (sharePopupRecipeInputRef.current) {
      sharePopupRecipeInputRef.current.innerHTML = mailString;
      setCurrentShareMail(mailString);
    }
  };

  const plan = useContext(MainContext).planList?.find(item => item.uid === id);
  const favoritesPlansList = useContext(MainContext).favoritesPlansList?.find(item => item.origId === id);
  const authContext = useContext(AuthContext);
  const { planList, userPlanList, tempRecipeObject, setTempRecipeObject } = useContext(MainContext);
  const [currentDayItem, setCurrentDayItem] = useState(
    currentPlan?.dayEntries?.filter((item: any) => item.id === currentDayId)[0]
  );
  const [planFavorite, setPlanFavorite] = useState(false);
  const [currentMealType, setCurrentMealType] = useState('breakfast');
  const [currentMealLabel, setCurrentMealLabel] = useState('Frühstück');
  const [currentIngredientName, setCurrentIngredientName] = useState('');

  const [currentScrollPos, setCurrentScrollPos] = useState(0);

  const [planStartType, setPlanStartType] = useState('');

  const [deleteRecipeOverlayClass, setDeleteRecipeOverlayClass] = useState('hidden');

  const [sharePlanOverlayClass, setSharePlanOverlayClass] = useState('hidden');

  const [shareLoading, setShareLoading] = useState(false);

  const [currentDayBreakfastImage, setCurrentDayBreakfastImage] = useState('');
  const [currentDayBreakfastMealLabel, setCurrentDayBreakfastMealLabel] = useState('');
  const [currentDayBreakfastMealCounter, setCurrentDayBreakfastMealCounter] = useState(0);
  const [currentDayBreakfastType, setCurrentDayBreakfastType] = useState('add');
  const [currentDayBreakfastKcalTotal, setCurrentDayBreakfastKcalTotal] = useState(0);
  const [currentDayBreakfastCarbohydratesTotal, setCurrentDayBreakfastCarbohydratesTotal] = useState(0);
  const [currentDayBreakfastProteinTotal, setCurrentDayBreakfastProteinTotal] = useState(0);
  const [currentDayBreakfastFatTotal, setCurrentDayBreakfastFatTotal] = useState(0);

  const [currentDayLunchImage, setCurrentDayLunchImage] = useState('');
  const [currentDayLunchMealLabel, setCurrentDayLunchMealLabel] = useState('');
  const [currentDayLunchMealCounter, setCurrentDayLunchMealCounter] = useState(0);
  const [currentDayLunchType, setCurrentDayLunchType] = useState('add');
  const [currentDayLunchKcalTotal, setCurrentDayLunchKcalTotal] = useState(0);
  const [currentDayLunchCarbohydratesTotal, setCurrentDayLunchCarbohydratesTotal] = useState(0);
  const [currentDayLunchProteinTotal, setCurrentDayLunchProteinTotal] = useState(0);
  const [currentDayLunchFatTotal, setCurrentDayLunchFatTotal] = useState(0);

  const [currentDayDinnerImage, setCurrentDayDinnerImage] = useState('');
  const [currentDayDinnerMealLabel, setCurrentDayDinnerMealLabel] = useState('');
  const [currentDayDinnerMealCounter, setCurrentDayDinnerMealCounter] = useState(0);
  const [currentDayDinnerType, setCurrentDayDinnerType] = useState('add');
  const [currentDayDinnerKcalTotal, setCurrentDayDinnerKcalTotal] = useState(0);
  const [currentDayDinnerCarbohydratesTotal, setCurrentDayDinnerCarbohydratesTotal] = useState(0);
  const [currentDayDinnerProteinTotal, setCurrentDayDinnerProteinTotal] = useState(0);
  const [currentDayDinnerFatTotal, setCurrentDayDinnerFatTotal] = useState(0);

  const [currentDaySnacksImage, setCurrentDaySnacksImage] = useState('');
  const [currentDaySnacksMealLabel, setCurrentDaySnacksMealLabel] = useState('');
  const [currentDaySnacksMealCounter, setCurrentDaySnacksMealCounter] = useState(0);
  const [currentDaySnacksType, setCurrentDaySnacksType] = useState('add');
  const [currentDaySnacksKcalTotal, setCurrentDaySnacksKcalTotal] = useState(0);
  const [currentDaySnacksCarbohydratesTotal, setCurrentDaySnacksCarbohydratesTotal] = useState(0);
  const [currentDaySnacksProteinTotal, setCurrentDaySnacksProteinTotal] = useState(0);
  const [currentDaySnacksFatTotal, setCurrentDaySnacksFatTotal] = useState(0);

  const [viewMealPopupClass, setViewMealPopupClass] = useState('hidden');
  const [viewIngredientPopupClass, setViewIngredientPopupClass] = useState('hidden');

  const [choosePlanPopupClass, setChoosePlanPopupClass] = useState('hidden');

  const [currentName, setCurrentName] = useState('');

  const [currentShareMail, setCurrentShareMail] = useState('');

  const mainLeftColumnRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const mobileRecipeContentRef = useRef<HTMLDivElement>(null);

  const [startDate, setStartDate] = useState(null);

  const onChange = (dates: any) => {
    setStartDate(dates);
  };

  const displayTypeOptions = [
    {
      label: 'Tag',
      value: 'day',
    },
    {
      label: 'Woche',
      value: 'week',
    },
  ];

  const [currentSection, setCurrentSection] = useState<any>(displayTypeOptions[0].value);
  const [currentSwitchIndex, setCurrentSwitchIndex] = useState(0);
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);

  const CustomInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <div className="flex w-full">
          <div className="flex-1">
            <input {...props} />
          </div>
          <div className="ml-10 my-auto">
            <CalendarIcon width={25} height={25} className="text-accentColor mx-auto cursor-pointer" />
          </div>
        </div>
      </label>
    );
  };

  const location = useLocation<LocationState>();

  const changePlanDay = (dayId: number) => {
    setCurrentDayId(dayId);

    getMealImage('breakfast', dayId);
    getMealImage('lunch', dayId);
    getMealImage('dinner', dayId);
    getMealImage('snacks', dayId);
  };

  const triggerShare = async () => {
    setShareLoading(true);
    await shareToFirebase(
      'plan',
      currentPlan,
      currentShareMail,
      setSharePlanOverlayClass,
      userData,
      tenant,
      authContext.user?.uid
    );
    setShareLoading(false);
  };

  const getMealImage = (type: string, dayId: number) => {
    if (type === 'breakfast') {
      const thisBreakfastItem = currentPlan?.dayEntries?.filter((item: any) => item.id === dayId)[0].breakfast;

      let thisCounter = 0;
      if (thisBreakfastItem) {
        if (
          thisBreakfastItem.recipes.length > 0 ||
          thisBreakfastItem.ingredients.length > 0 ||
          thisBreakfastItem.manualItems?.length > 0
        ) {
          setCurrentDayBreakfastType('show');
          if (thisBreakfastItem.recipes.length > 0) {
            setCurrentDayBreakfastImage(thisBreakfastItem.recipes[0].imageUrl);
            setCurrentDayBreakfastMealLabel(thisBreakfastItem.recipes[0].name);
            thisCounter += thisBreakfastItem.recipes.length;
          }
          if (thisBreakfastItem.ingredients.length > 0) {
            thisCounter += thisBreakfastItem.ingredients.length;
          }
          if (thisBreakfastItem.ingredients.length > 0 && thisBreakfastItem.recipes.length === 0) {
            setCurrentDayBreakfastImage(thisBreakfastItem.ingredients[0].imageUrl);
            setCurrentDayBreakfastMealLabel(thisBreakfastItem.ingredients[0].name);
          }
        } else {
          setCurrentDayBreakfastImage('');
          setCurrentDayBreakfastMealLabel('');
          setCurrentDayBreakfastType('add');
        }

        setCurrentDayBreakfastKcalTotal(thisBreakfastItem.kcal_total);
        setCurrentDayBreakfastCarbohydratesTotal(thisBreakfastItem.carbohydrates_total);
        setCurrentDayBreakfastProteinTotal(thisBreakfastItem.protein_total);
        setCurrentDayBreakfastFatTotal(thisBreakfastItem.fat_total);
        setCurrentDayBreakfastMealCounter(thisCounter - 1);
      }
    }

    if (type === 'lunch') {
      const thisLunchItem = currentPlan?.dayEntries?.filter((item: any) => item.id === dayId)[0].lunch;

      let thisCounter = 0;
      if (thisLunchItem) {
        if (thisLunchItem.recipes.length > 0 || thisLunchItem.ingredients.length > 0) {
          setCurrentDayLunchType('show');
          if (thisLunchItem.recipes.length > 0) {
            setCurrentDayLunchImage(thisLunchItem.recipes[0].imageUrl);
            setCurrentDayLunchMealLabel(thisLunchItem.recipes[0].name);
            thisCounter += thisLunchItem.recipes.length;
          }
          if (thisLunchItem.ingredients.length > 0) {
            thisCounter += thisLunchItem.ingredients.length;
          }
          if (thisLunchItem.ingredients.length > 0 && thisLunchItem.recipes.length === 0) {
            setCurrentDayLunchImage(thisLunchItem.ingredients[0].imageUrl);
            setCurrentDayLunchMealLabel(thisLunchItem.ingredients[0].name);
          }
        } else {
          setCurrentDayLunchImage('');
          setCurrentDayLunchMealLabel('');
          setCurrentDayLunchType('add');
        }

        setCurrentDayLunchKcalTotal(thisLunchItem.kcal_total);
        setCurrentDayLunchCarbohydratesTotal(thisLunchItem.carbohydrates_total);
        setCurrentDayLunchProteinTotal(thisLunchItem.protein_total);
        setCurrentDayLunchFatTotal(thisLunchItem.fat_total);
        setCurrentDayLunchMealCounter(thisCounter - 1);
      }
    }

    if (type === 'dinner') {
      const thisDinnerItem = currentPlan?.dayEntries?.filter((item: any) => item.id === dayId)[0].dinner!;

      let thisCounter = 0;
      if (thisDinnerItem) {
        if (thisDinnerItem.recipes.length > 0 || thisDinnerItem.ingredients.length > 0) {
          setCurrentDayDinnerType('show');
          if (thisDinnerItem.recipes.length > 0) {
            setCurrentDayDinnerImage(thisDinnerItem.recipes[0].imageUrl);
            setCurrentDayDinnerMealLabel(thisDinnerItem.recipes[0].name);
            thisCounter += thisDinnerItem.recipes.length;
          }
          if (thisDinnerItem.ingredients.length > 0) {
            thisCounter += thisDinnerItem.ingredients.length;
          }
          if (thisDinnerItem.ingredients.length > 0 && thisDinnerItem.recipes.length === 0) {
            setCurrentDayDinnerImage(thisDinnerItem.ingredients[0].imageUrl);
            setCurrentDayDinnerMealLabel(thisDinnerItem.ingredients[0].name);
          }
        } else {
          setCurrentDayDinnerImage('');
          setCurrentDayDinnerMealLabel('');
          setCurrentDayDinnerType('add');
        }

        setCurrentDayDinnerKcalTotal(thisDinnerItem.kcal_total);
        setCurrentDayDinnerCarbohydratesTotal(thisDinnerItem.carbohydrates_total);
        setCurrentDayDinnerProteinTotal(thisDinnerItem.protein_total);
        setCurrentDayDinnerFatTotal(thisDinnerItem.fat_total);
        setCurrentDayDinnerMealCounter(thisCounter - 1);
      }
    }

    if (type === 'snacks') {
      const thisSnacksItem = currentPlan?.dayEntries?.filter((item: any) => item.id === dayId)[0].snacks;

      let thisCounter = 0;
      if (thisSnacksItem) {
        if (thisSnacksItem.recipes.length > 0 || thisSnacksItem.ingredients.length > 0) {
          setCurrentDaySnacksType('show');
          if (thisSnacksItem.recipes.length > 0) {
            setCurrentDaySnacksImage(thisSnacksItem.recipes[0].imageUrl);
            setCurrentDaySnacksMealLabel(thisSnacksItem.recipes[0].name);
            thisCounter += thisSnacksItem.recipes.length;
          }
          if (thisSnacksItem.ingredients.length > 0) {
            thisCounter += thisSnacksItem.ingredients.length;
          }
          if (thisSnacksItem.ingredients.length > 0 && thisSnacksItem.recipes.length === 0) {
            setCurrentDaySnacksImage(thisSnacksItem.ingredients[0].imageUrl);
            setCurrentDaySnacksMealLabel(thisSnacksItem.ingredients[0].name);
          }
        } else {
          setCurrentDaySnacksImage('');
          setCurrentDaySnacksMealLabel('');
          setCurrentDaySnacksType('add');
        }

        setCurrentDaySnacksKcalTotal(thisSnacksItem.kcal_total);
        setCurrentDaySnacksCarbohydratesTotal(thisSnacksItem.carbohydrates_total);
        setCurrentDaySnacksProteinTotal(thisSnacksItem.protein_total);
        setCurrentDaySnacksFatTotal(thisSnacksItem.fat_total);
        setCurrentDaySnacksMealCounter(thisCounter - 1);
      }
    }
  };

  function openStartPlan(startType: string) {
    setChoosePlanPopupClass('block');
    setPlanStartType(startType);
  }

  const openSharePopup = async () => {
    const getShareMails = await firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(authContext.user?.uid)
      .collection('messages-emails')
      .limit(5)
      .get();

    if (getShareMails.size > 0) {
      const sharedMailArray = [] as any;

      const getSharedMailsDocuments = getShareMails.docs.map(doc => {
        sharedMailArray.push(doc.data());

        return 0;
      });

      setShareMailArray(sharedMailArray);
    }

    setCurrentShareMail('');
    setSharePlanOverlayClass('block');
  };

  const changePlanName = (event: any) => {
    const thisCurrentName = event.currentTarget.textContent;

    setCurrentName(thisCurrentName);
  };

  const changePlanShareEmail = (event: any) => {
    const thisCurrentEmail = event.currentTarget.textContent;

    setCurrentShareMail(thisCurrentEmail.toLowerCase());
  };

  const openMealPopup = (mealType: any, mealLabel: any) => {
    setViewMealPopupClass('block');
    setCurrentMealType(mealType);
    setCurrentMealLabel(mealLabel);
  };

  const openIngredient = (mealType: string, ingredientName: string) => {
    setViewMealPopupClass('hidden');
    setViewIngredientPopupClass('block');
    setCurrentMealType(mealType);
    setCurrentIngredientName(ingredientName);
  };

  const savePlanToFavorites = async () => {
    const newColumns = {
      uid: currentPlan?.uid,
      name: currentPlan?.name,
      origId: currentPlan?.uid,
    };

    if (planFavorite) {
      try {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(authContext.user?.uid)
          .collection('favorites_plans')
          .doc(favoritesPlansList?.uid)
          .delete();

        setPlanFavorite(false);
        toast.success('Der Plan wurde erfolgreich von deinen Favoriten entfernt!');
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } else {
      try {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(authContext.user?.uid)
          .collection('favorites_plans')
          .doc()
          .set(newColumns as object);

        setPlanFavorite(true);
        toast.success('Der Plan wurde erfolgreich zu deinen Favoriten gespeichert!');
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    }
  };

  function getCurrentMealTotal(makroType: string, mealType: string) {
    const thisItem = currentPlan?.dayEntries?.filter((item: any) => item.id === currentDayId)[0][mealType];

    if (makroType === 'kcal') {
      return thisItem?.kcal_total;
    }
    if (makroType === 'carbohydrates') {
      return thisItem?.carbohydrates_total;
    }
    if (makroType === 'protein') {
      return thisItem?.protein_total;
    }
    if (makroType === 'fat') {
      return thisItem?.fat_total;
    }

    return '0';
  }

  function changeSwitch() {
    if (currentSwitchIndex === 0) {
      setCurrentSection('week');
      setCurrentSwitchIndex(1);
      setCurrentWeekIndex(0);
    } else {
      setCurrentSection('day');
      setCurrentSwitchIndex(0);
    }
  }

  // Push Function
  const saveNewPlanToFirebase = async (planObject: any) => {
    try {
      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(authContext.user?.uid)
        .collection('plans')
        .doc(planObject.uid)
        .set(planObject as object);
      toast.success(t('Dein Plan wurde erfolgreich gestartet!'));
      history.push(`/plans/${planObject.uid}`);
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.error('Es ist leider etwas schief gegangen!');
    }
  };

  function getPlanAverageValues(planObject: any, makroType: string) {
    const getDayEntriesKcal = planObject?.dayEntries.filter((item: any) => item.kcal_total > 0);
    const getDayEntriesCarbohydrates = planObject?.dayEntries.filter((item: any) => item.carbohydrates_total > 0);
    const getDayEntriesProtein = planObject?.dayEntries.filter((item: any) => item.protein_total > 0);
    const getDayEntriesFat = planObject?.dayEntries.filter((item: any) => item.fat_total > 0);

    const calorieTotal = getDayEntriesKcal.reduce(
      (totalCalories: number, dayObject: any) => totalCalories + parseFloat(dayObject.kcal_total),
      0
    );

    const carbohydratesTotal = getDayEntriesCarbohydrates.reduce(
      (totalCalories: number, dayObject: any) => totalCalories + parseFloat(dayObject.carbohydrates_total),
      0
    );

    const proteinTotal = getDayEntriesProtein.reduce(
      (totalCalories: number, dayObject: any) => totalCalories + parseFloat(dayObject.protein_total),
      0
    );

    const fatTotal = getDayEntriesFat.reduce(
      (totalCalories: number, dayObject: any) => totalCalories + parseFloat(dayObject.fat_total),
      0
    );

    const averageValueKcal = Math.round(parseFloat(calorieTotal) / getDayEntriesKcal.length);
    const averageValueCarbohydrates = Math.round(parseFloat(carbohydratesTotal) / getDayEntriesCarbohydrates.length);
    const averageValueProtein = Math.round(parseFloat(proteinTotal) / getDayEntriesProtein.length);
    const averageValueFat = Math.round(parseFloat(fatTotal) / getDayEntriesFat.length);

    if (calorieTotal === 0) {
      return 0;
    }
    if (makroType === 'kcal') {
      return averageValueKcal;
    }
    if (makroType === 'carbohydrates') {
      return averageValueCarbohydrates;
    }
    if (makroType === 'protein') {
      return averageValueProtein;
    }
    if (makroType === 'fat') {
      return averageValueFat;
    }
    return 0;
  }

  const startPlan = () => {
    let isPlanValid = true;

    if (currentPlan?.name !== undefined) {
      if (userData?.role === 1) {
        planList?.forEach(function (key) {
          const thisCheckPlanStartDate = moment.unix(key.startDate.seconds).utc().format('DD-MM-YY');
          const thisCheckPlanEndDate = moment.unix(key.endDate.seconds).utc().format('DD-MM-YY');

          const thisConvertedPlanStartDate = moment(thisCheckPlanStartDate, 'DD-MM-YY');
          const thisConvertedPlanEndDate = moment(thisCheckPlanEndDate, 'DD-MM-YY');

          const thisUserStartDate = moment(startDate).format('YYYY-MM-DD');
          const thisUserEndDate = moment(startDate).subtract(-currentPlan?.dayEntries?.length, 'days');

          const thisPlanRange = moment.range(thisConvertedPlanStartDate, thisConvertedPlanEndDate);
          const thisUserRange = moment.range(moment(thisUserStartDate), moment(thisUserEndDate));

          if (thisUserRange.overlaps(thisPlanRange, { adjacent: true }) === true) {
            isPlanValid = false;
          }
        });
      } else {
        userPlanList?.forEach(function (key) {
          const thisCheckPlanStartDate = moment.unix(key.startDate.seconds).utc().format('DD-MM-YY');
          const thisCheckPlanEndDate = moment.unix(key.endDate.seconds).utc().format('DD-MM-YY');

          const thisConvertedPlanStartDate = moment(thisCheckPlanStartDate, 'DD-MM-YY');
          const thisConvertedPlanEndDate = moment(thisCheckPlanEndDate, 'DD-MM-YY');

          const thisUserStartDate = moment(startDate).format('YYYY-MM-DD');
          const thisUserEndDate = moment(startDate).subtract(-currentPlan?.dayEntries?.length, 'days');

          const thisPlanRange = moment.range(thisConvertedPlanStartDate, thisConvertedPlanEndDate);
          const thisUserRange = moment.range(moment(thisUserStartDate), moment(thisUserEndDate));

          if (thisUserRange.overlaps(thisPlanRange, { adjacent: true }) === true) {
            isPlanValid = false;
          }
        });
      }
    }

    const thisRecipeUid = generateRandomUid();

    if (currentName === '') {
      toast.error('Bitte vergebe noch einen Namen für deinen Plan!');
    } else if (startDate === null) {
      toast.error('Bitte gebe ein Datum ein!');
    } else if (isPlanValid && currentPlan?.dayEntries) {
      const newColumnsAdd = {
        ...currentPlan,
        name: currentName,
        startDate: {
          ...currentPlan?.startDate,
          seconds: moment(startDate).subtract(-1, 'days').unix(),
        },
        endDate: {
          ...currentPlan?.endDate,
          seconds: moment(startDate).subtract(-currentPlan?.dayEntries?.length, 'days').unix(),
        },
        examplePlan: false,
        uid: thisRecipeUid,
        origId: thisRecipeUid,
        created: moment().unix(),
        dayEntries: currentPlan?.dayEntries?.map((entries: any, index: number) => {
          return {
            ...entries,
            date: {
              ...entries.date,
              seconds: moment(startDate)
                .subtract(-(index + 1), 'days')
                .unix(),
            },
          };
        }),
      };

      if (newColumnsAdd.custom_kcal_total !== undefined) {
        delete newColumnsAdd.custom_kcal_total;
      }
      if (newColumnsAdd.custom_carbohydrates_total !== undefined) {
        delete newColumnsAdd.custom_carbohydrates_total;
      }
      if (newColumnsAdd.custom_protein_total !== undefined) {
        delete newColumnsAdd.custom_protein_total;
      }
      if (newColumnsAdd.custom_fat_total !== undefined) {
        delete newColumnsAdd.custom_fat_total;
      }
      if (newColumnsAdd.custom_makro_type !== undefined) {
        delete newColumnsAdd.custom_makro_type;
      }
      if (newColumnsAdd.examplePlanCalories !== undefined) {
        delete newColumnsAdd.examplePlanCalories;
      }
      if (newColumnsAdd.examplePlanNutrition !== undefined) {
        delete newColumnsAdd.examplePlanNutrition;
      }

      setChoosePlanPopupClass('hidden');
      saveNewPlanToFirebase(newColumnsAdd);
    } else {
      toast.error('In diesem Zeitraum existiert schon ein Plan. Bitte wähle ein anderes Datum!');
    }
  };

  // Delete Function
  const deletePlanFromFirebase = async () => {
    if (userData?.role === 1) {
      try {
        await firebase.firestore().collection('plans').doc(id).delete();
        toast.success(t('Dein Plan wurde erfolgreich gelöscht!'));
        history.push('/plans');
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } else {
      try {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(authContext.user?.uid)
          .collection('plans')
          .doc(id)
          .delete();
        toast.success(t('Dein Plan wurde erfolgreich gelöscht!'));
        history.push('/plans');
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    }
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  useEffect(() => {
    if (currentDayItem?.kcal_total) {
      if (typeof location.state !== undefined && location.state !== null) {
        if (location.state?.planDayId !== undefined) {
          setCurrentDayId(location.state.planDayId);
        }

        if (location.state?.id !== undefined) {
          setCurrentDayId(location.state.id);
        }

        if (jumpTopTop === false) {
          setJumpToTop(true);
          setTimeout(() => {
            if (scrollContainerRef.current) {
              scrollContainerRef.current.scrollIntoView(false);
            }
          }, 1);
        }

        if (location.state?.type !== undefined && location.state?.mealLabel !== undefined) {
          openMealPopup(location.state.type, location.state.mealLabel);

          setTimeout(() => {
            if (mainLeftColumnRef.current) {
              mainLeftColumnRef.current.scrollIntoView(false);
            }
          }, 1);
          setTimeout(() => {
            if (mobileRecipeContentRef.current) {
              mobileRecipeContentRef.current.scrollIntoView(false);
            }
          }, 1);
        }

        setTimeout(() => {
          history.replace({ state: {} });
        }, 10);
      } else {
        setTimeout(() => {
          if (mainLeftColumnRef.current) {
            mainLeftColumnRef.current.scrollIntoView();
          }
        }, 1);
        setTimeout(() => {
          if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollIntoView();
          }
        }, 10);
      }
    }

    if (location.state?.planDayId !== undefined) {
      setCurrentDayId(location.state.planDayId);
    }

    if (location.state?.planExampleCalories !== undefined && location.state?.planExampleCategory !== undefined) {
      setCurrentSamplePlanCalories(location.state.planExampleCalories);
      setCurrentSamplePlanCategory(location.state.planExampleCategory);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDayItem]);

  useEffect(() => {
    if (currentDayItem?.kcal_total) {
      getMealImage('breakfast', currentDayId);
      getMealImage('lunch', currentDayId);
      getMealImage('dinner', currentDayId);
      getMealImage('snacks', currentDayId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDayItem]);

  useEffect(() => {
    if (favoritesPlansList !== undefined) {
      setPlanFavorite(true);
    }
  }, [favoritesPlansList]);

  useEffect(() => {
    if (tempRecipeObject?.kcal_total !== undefined && tempRecipeObject.sharedPlan !== undefined) {
      setCurrentPlan(tempRecipeObject);
      setTempRecipeObject({});
    } else if (id !== 'shared') {
      setCurrentPlan(plan);
    }
  }, [plan, tempRecipeObject]);

  useEffect(() => {
    if (currentPlan?.kcal_total !== undefined) {
      setCurrentDayItem(currentPlan?.dayEntries?.filter((item: any) => item.id === currentDayId)[0]);
    }
  }, [currentPlan, currentDayId, tempRecipeObject]);

  useEffect(() => {
    if (location.state?.scrollPos !== undefined) {
      setCurrentScrollPos(location.state?.scrollPos);
    }
  }, [location.state?.scrollPos]);

  useEffect(() => {
    const excludeDatesStaticArray = [] as any;

    if (userData?.role === 1) {
      planList?.forEach(key => {
        key.dayEntries?.forEach(day => {
          excludeDatesStaticArray.push(
            moment
              .unix(day.date.seconds)
              .utc()
              .subtract(+1, 'days')
              .toDate()
          );
        });
      });
    } else {
      userPlanList?.forEach(key => {
        key.dayEntries?.forEach(day => {
          excludeDatesStaticArray.push(
            moment
              .unix(day.date.seconds)
              .utc()
              .subtract(+1, 'days')
              .toDate()
          );
        });
      });
    }

    setExcludeDateArray(excludeDatesStaticArray);
  }, [userPlanList, planList]);

  useEffect(() => {
    if (currentPlan?.uid !== undefined && currentPlan?.examplePlan) {
      setTimeout(() => {
        if (document.getElementById('addToList')?.offsetParent !== null) {
          if (
            tourRef.current &&
            (userData?.cookieBannerType !== 'initial' || userData?.role === 5) &&
            planList !== undefined
          ) {
            if (userData?.tour?.plansDetail === undefined) {
              tourRef.current.click();
            }
          }
        }

        if (document.getElementById('addToListMobile')?.offsetParent !== null) {
          if (
            tourRefMobile.current &&
            (userData?.cookieBannerType !== 'initial' || userData?.role === 5) &&
            planList !== undefined
          ) {
            if (userData?.tour?.plansDetail === undefined) {
              tourRefMobile.current.click();
            }
          }
        }
      }, 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlan]);

  return (
    <>
      <div className={viewMealPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <CollectionIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">{currentMealLabel}</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setViewMealPopupClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-20">
                <div className="flex w-full gap-4">
                  <div className="flex-1 text-center">
                    <div className="font-semibold text-base">{getCurrentMealTotal('kcal', currentMealType)}</div>
                    <div className={styles.recipeLabel}>kcal</div>
                  </div>
                  <div className="flex-1 text-center">
                    <div className="font-semibold text-base">
                      {getCurrentMealTotal('carbohydrates', currentMealType)}g
                    </div>
                    <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                  </div>
                  <div className="flex-1 text-center">
                    <div className="font-semibold text-base">{getCurrentMealTotal('protein', currentMealType)}g</div>
                    <div className={styles.recipeLabel}>{t('Protein')}</div>
                  </div>
                  <div className="flex-1 text-center">
                    <div className="font-semibold text-base">{getCurrentMealTotal('fat', currentMealType)}g</div>
                    <div className={styles.recipeLabel}>{t('Fat')}</div>
                  </div>
                </div>
              </div>
              <div className="pt-25 pb-30 pl-20">
                {currentDayItem?.[currentMealType].recipes.map((item: any, index: number) => (
                  <IngridientPopupItem
                    ingridientItem={item}
                    type="Rezept"
                    mealType={currentMealType}
                    dayId={currentDayId}
                    planUid={currentPlan?.origId}
                    mealLabel={currentMealLabel}
                    key={`receipt-${index}`}
                  />
                ))}
                {currentDayItem?.[currentMealType].ingredients.map((item: any, index: number) => (
                  <IngridientPopupItem
                    ingridientItem={item}
                    ingredientType={currentMealType}
                    openPopup={openIngredient}
                    type="Lebensmittel"
                    key={`food-${index}`}
                  />
                ))}
                {currentDayItem?.[currentMealType].manualItems?.map((item: any, index: number) => (
                  <IngridientPopupItem
                    ingridientItem={item}
                    ingredientType={currentMealType}
                    type="Manuell"
                    key={`manual-${index}`}
                    isManual
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={deleteRecipeOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <TrashIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Plan löschen</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setDeleteRecipeOverlayClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-15 pl-20 pb-30">
                <div className="pt-20 font-light text-base">Wollen Sie diesen Plan wirklich löschen?</div>
                <div className="pt-30 flex gap-30">
                  <div>
                    <Button className="w-full" onClick={() => deletePlanFromFirebase()}>
                      Ja
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="w-full"
                      onClick={() => handleOpenClosePopups(setDeleteRecipeOverlayClass, '', 'close')}
                    >
                      Nein
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={viewIngredientPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <CollectionIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Lebensmittel</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setViewIngredientPopupClass, setViewMealPopupClass, 'both')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className={styles.editPictureIngridientPopupContent}>
                {currentDayItem?.[currentMealType].ingredients
                  .filter((ingredientItem: any) => ingredientItem.name === currentIngredientName)
                  .map((item: any, index: number) => (
                    <div key={index}>
                      <div className="pt-30">
                        <div className="text-xl pl-25">
                          <div>{item.name}</div>
                          <div className="text-12 font-extralight">
                            {item.amount} {item.piece} ({item.portion_g}g)
                          </div>
                        </div>
                      </div>
                      <div className="pt-30 pb-20 xl:pb-40">
                        <div className="text-xl pl-25">Nährwertangabe</div>
                        <div className="pt-10 flex pl-25">
                          <div className={styles.nutritionBorderItemFirst}>
                            <div>
                              <div className="font-semibold text-base">{item.kcal_total}</div>
                              <div className={styles.recipeLabel}>kcal</div>
                            </div>
                            <span className="divider" />
                          </div>
                          <div className={styles.nutritionBorderItem}>
                            <div className="font-semibold text-base">{item.carbohydrates_total}g</div>
                            <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                          </div>
                          <div className={styles.nutritionBorderItem}>
                            <div className="font-semibold text-base">{item.protein_total}g</div>
                            <div className={styles.recipeLabel}>{t('Protein')}</div>
                          </div>
                          <div className="text-center px-20">
                            <div className="font-semibold text-base">{item.fat_total}g</div>
                            <div className={styles.recipeLabel}>{t('Fat')}</div>
                          </div>
                        </div>

                        {item.imageUrl.length > 0 && (
                          <>
                            <div className="pt-40 pb-30 pl-25">
                              <img
                                src={item.imageUrl}
                                alt="Ingridient"
                                className="rounded-2xl w-full object-cover"
                                loading="lazy"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={sharePlanOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <ShareIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>

                <div className="text-xl my-auto font-light">Plan teilen</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setSharePlanOverlayClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className={styles.editPictureIngridientPopupContent}>
                <div className="mx-20 pt-20 pb-40 font-extralight">
                  <div>
                    <div className="pt-20 font-extralight pb-1">
                      Bitte gebe die Email Adresse der Person ein mit der du diesen Plan teilen möchtest:
                    </div>
                    <div className="flex">
                      <div className="w-3/4">
                        <div className="font-light left text-xs border-opacity-30 w-full">
                          <span
                            className="appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                            role="textbox"
                            contentEditable
                            onFocus={setBodyClassForMobileSafari}
                            onBlur={removeBodyClassForMobileSafari}
                            ref={sharePopupRecipeInputRef}
                            suppressContentEditableWarning
                            onInput={e => changePlanShareEmail(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pt-20 font-extralight pb-1">
                      Der Plan erscheint dann bei der Person im Profil unter "Nachrichten".
                    </div>
                    {shareMailArray.length > 0 && <div className="font-bold pt-10">Zuletzt:</div>}
                    {shareMailArray.map((item: any, index: number) => (
                      <div
                        key={index}
                        className="flex gap-10 pt-5 cursor-pointer"
                        onClick={() => handleSetShareMail(item.mail)}
                        aria-hidden="true"
                      >
                        <div>
                          <PlusIcon
                            width={25}
                            height={25}
                            className="text-accentColor mx-auto cursor-pointer rotate-180"
                          />
                        </div>
                        <div>{item.mail}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="pl-20 pr-10 pb-40">
                  <Button className="w-full" isPending={shareLoading} onClick={() => triggerShare()}>
                    Plan teilen
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {planStartType !== 'download' ? (
        <div className={choosePlanPopupClass}>
          <div className={styles.backgroundPopupLayer}>
            <div className={styles.editPicturePopup}>
              <div className="flex justify-between pt-20 pl-20">
                <div className="flex">
                  <div className="my-auto pr-10">
                    {planStartType === 'add' ? (
                      <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
                    ) : (
                      <CollectionIcon width={25} height={25} className="text-accentColor mx-auto" />
                    )}
                  </div>
                  {planStartType === 'add' ? (
                    <div className="text-xl my-auto font-light">Plan hinzufügen</div>
                  ) : (
                    <div className="text-xl my-auto font-light">Plan kopieren</div>
                  )}
                </div>
                <div className="my-auto pr-20">
                  <XIcon
                    width={25}
                    height={25}
                    className="text-accentColor mx-auto cursor-pointer"
                    onClick={() => handleOpenClosePopups(setChoosePlanPopupClass, '', 'close')}
                  />
                </div>
              </div>
              <div className={styles.editPictureIngridientPopupContent}>
                <div className={styles.editPictureIngridientPopupContent}>
                  <div className="mx-20 pt-20 pb-40 font-extralight">
                    {planStartType === 'add' ? (
                      <div className="text-20 font-bold">Du möchtest den Plan "{currentPlan?.name}" starten</div>
                    ) : (
                      <div className="text-20 font-bold">Du möchtest den Plan "{currentPlan?.name}" kopieren</div>
                    )}
                    <div className="pt-10">
                      Dieser Plan hat eine Laufzeit von{' '}
                      <span className="font-bold">{currentPlan?.dayEntries?.length}</span> Tagen.
                    </div>
                    <div className="pt-30">Bitte wähle ein Startdatum aus:</div>
                    <div className="flex">
                      <div className="w-3/4 font-light left text-xs border-opacity-30">
                        <DatePicker
                          className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                          selected={startDate}
                          onChange={onChange}
                          excludeDates={excludeDateArray}
                          startDate={startDate}
                          locale="de"
                          dateFormat="dd.MM.yyyy"
                          withPortal
                          customInput={React.createElement(React.forwardRef(CustomInput))}
                          calendarStartDay={1}
                          onFocus={e => e.target.blur()}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="pt-20 font-extralight pb-1">Vergebe einen Namen für deinen Plan:</div>
                      <div className="flex">
                        <div className="w-3/4">
                          <div className="font-light left text-xs border-opacity-30 w-full">
                            <span
                              className="appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                              role="textbox"
                              contentEditable
                              onFocus={setBodyClassForMobileSafari}
                              onBlur={removeBodyClassForMobileSafari}
                              suppressContentEditableWarning
                              onInput={e => changePlanName(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pl-20 pr-10 pb-40">
                    <Button className="w-full" onClick={() => startPlan()}>
                      Plan starten
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className={styles.pageWrapper} ref={scrollContainerRef}>
        <div className={styles.recipeImageContainer} style={{ backgroundImage: `url(${currentPlan?.imageUrl})` }}>
          <div className="flex justify-between">
            <div className="z-10 w-32 pt-50 xl:pt-4 pl-4">
              <div className={styles.backBtnBg}>
                {typeof location.state !== undefined &&
                location.state !== null &&
                location.state?.from === 'dashboard' ? (
                  <Link
                    to={{
                      pathname: '/',
                      state: {
                        uid: location.state?.uid,
                        id: location.state?.id,
                        isCurrentPlansDashboard: location.state?.isCurrentPlansDashboard,
                        isBackLink: true,
                      },
                    }}
                  >
                    <ButtonBack
                      text={t('Return')}
                      className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                    />
                  </Link>
                ) : (
                  <Link
                    to={{
                      pathname: '/plans',
                      state: {
                        from: 'plan-detail',
                        scrollPos: currentScrollPos,
                        planExampleCategory: currentSamplePlanCategory,
                        planExampleCalories: currentSamplePlanCalories,
                      },
                    }}
                  >
                    <ButtonBack text={t('Return')} />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.recipeWrapper}>
          <div className={styles.recipeContent} ref={mobileRecipeContentRef}>
            <div className="pt-4 flex justify-center gap-4">
              <div
                title="Favoriten"
                className="rounded-2xl border-transparent border-2 hover:border-accentColor p-5 cursor-pointer"
                onClick={() => savePlanToFavorites()}
                onKeyDown={() => savePlanToFavorites()}
                aria-hidden="true"
              >
                <HeartIcon
                  width={28}
                  height={28}
                  className="text-accentColor mx-auto"
                  fill={(planFavorite && '#C97132') || 'none'}
                />
                <div className="font-extralight text-10 pt-1">Zu Favoriten</div>
              </div>
              {userData?.role === 1 || currentPlan?.examplePlan === false ? (
                <>
                  <div
                    title="Löschen"
                    className="rounded-full border-transparent border-2 hover:border-accentColor p-5 cursor-pointer"
                    onClick={() => setDeleteRecipeOverlayClass('block')}
                    onKeyDown={() => setDeleteRecipeOverlayClass('block')}
                    aria-hidden="true"
                  >
                    <TrashIcon width={28} height={28} className="text-accentColor mx-auto" />
                    <div className="font-extralight text-10 pt-1">Löschen</div>
                  </div>
                  <div
                    title="Teilen"
                    className="rounded-full border-transparent border-2 hover:border-accentColor p-5 cursor-pointer"
                    onClick={() => openSharePopup()}
                    onKeyDown={() => openSharePopup()}
                    aria-hidden="true"
                  >
                    <ShareIcon width={28} height={28} className="text-accentColor mx-auto" />
                    <div className="font-extralight text-10 pt-1">Teilen</div>
                  </div>
                  <a
                    href={`${process.env.REACT_APP_API_URL}/generatePdf?templateName=nutritionPlan&tenant=${tenant}&user=${user?.uid}&planId=${currentPlan?.uid}`}
                    title="Download"
                    target="_blank"
                    rel="noreferrer"
                    className="hidden rounded-2xl border-transparent border-2 hover:border-accentColor p-5 cursor-pointer"
                  >
                    <Icon name="pdfFile" width={28} height={28} className="text-accentColor mx-auto fill-[#c97132]" />
                    <div className="font-extralight text-10 pt-1">Drucken</div>
                  </a>
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="mx-20 mt-20 z-10 text-left text-xl font-semibold">
              <div className="text-textColor leading-8">{currentPlan?.name}</div>
            </div>

            {currentPlan?.examplePlan === true ? (
              <>
                <div className="pt-10 mx-20 font-extralight">
                  <span className="font-semibold">Laufzeit:</span> {currentPlan?.dayEntries?.length} Tage
                </div>
                {userData?.role === 2 ? (
                  <div className="pt-10 mx-20 font-extralight">Tortija-Beispielplan</div>
                ) : (
                  <div className="pt-10 mx-20 font-extralight">Beispielplan</div>
                )}
              </>
            ) : (
              <div className="flex mx-20">
                <div className="pt-20 font-extralight">
                  <span className="font-semibold">Laufzeit:</span>{' '}
                  {moment.unix(currentPlan?.startDate?.seconds).utc().format('DD[.]MM[.]YY')} -{' '}
                  {moment.unix(currentPlan?.endDate?.seconds).utc().format('DD[.]MM[.]YY')}
                </div>
              </div>
            )}

            <div className="pt-4 px-6">
              <div className="flex w-full gap-4 sticky py-4 top-0 z-10 bg-bgColor">
                <div className="flex-1 text-center">
                  <div className="font-semibold text-base">
                    {currentPlan?.kcal_total !== undefined && Math.round(getPlanAverageValues(currentPlan, 'kcal'))}{' '}
                    <sup>*</sup>
                  </div>
                  <div className={styles.recipeLabel}>kcal</div>
                </div>
                <div className="flex-1 text-center">
                  <div className="font-semibold text-base">
                    {currentPlan?.kcal_total !== undefined &&
                      Math.round(getPlanAverageValues(currentPlan, 'carbohydrates'))}
                    g <sup>*</sup>
                  </div>
                  <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                </div>
                <div className="flex-1 text-center">
                  <div className="font-semibold text-base">
                    {currentPlan?.kcal_total !== undefined && Math.round(getPlanAverageValues(currentPlan, 'protein'))}g{' '}
                    <sup>*</sup>
                  </div>
                  <div className={styles.recipeLabel}>{t('Protein')}</div>
                </div>
                <div className="flex-1 text-center">
                  <div className="font-semibold text-base">
                    {currentPlan?.kcal_total !== undefined && Math.round(getPlanAverageValues(currentPlan, 'fat'))}g{' '}
                    <sup>*</sup>
                  </div>
                  <div className={styles.recipeLabel}>{t('Fat')}</div>
                </div>
              </div>
            </div>
            <div className="font-extralight px-6 text-8">* Durchschnittliche Nährwertangaben des Plans</div>

            {userData?.role === 1 && (
              <div className="flex gap-40 pt-40 justify-center">
                <div
                  className="rounded-3xl bg-lightGray h-150 w-150"
                  onClick={() => openStartPlan('copy')}
                  onMouseDown={() => openStartPlan('copy')}
                  aria-hidden="true"
                >
                  <div className="pt-20">
                    <DocumentDuplicateIcon width={42} height={42} className="text-accentColor cursor-pointer mx-auto" />
                  </div>
                  <div className="text-center font-semibold text-18 px-20 pt-15">Plan kopieren</div>
                </div>
                <Link
                  to={{
                    pathname: `/plans/edit/${id}`,
                    state: {
                      from: 'plan-detail',
                      planDayId: currentDayId,
                    },
                  }}
                >
                  <div className="rounded-3xl bg-lightGray h-150 w-150">
                    <div className="pt-20">
                      <PencilIcon width={42} height={42} className="text-accentColor cursor-pointer mx-auto" />
                    </div>
                    <div className="text-center font-semibold text-18 px-20 pt-30">Bearbeiten</div>
                  </div>
                </Link>
              </div>
            )}

            {userData?.role !== 1 && currentPlan?.examplePlan ? (
              <div className="flex gap-40 pt-40 justify-center">
                <div
                  className="rounded-3xl bg-lightGray w-full mx-40"
                  onClick={() => openStartPlan('add')}
                  onMouseDown={() => openStartPlan('add')}
                  aria-hidden="true"
                  id="addToListMobile"
                >
                  <div className="pt-20">
                    <PlusIcon width={42} height={42} className="text-accentColor cursor-pointer mx-auto" />
                  </div>
                  <div className="text-center font-semibold text-18 px-20 pt-15 pb-20">Zu meiner Liste hinzufügen</div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {userData?.role !== 1 && currentPlan?.examplePlan === false ? (
              <div className="flex gap-40 pt-40 justify-center">
                <div
                  className="rounded-3xl bg-lightGray h-150 w-150"
                  onClick={() => openStartPlan('copy')}
                  onMouseDown={() => openStartPlan('copy')}
                  aria-hidden="true"
                >
                  <div className="pt-20">
                    <DocumentDuplicateIcon width={42} height={42} className="text-accentColor cursor-pointer mx-auto" />
                  </div>
                  <div className="text-center font-semibold text-18 px-20 pt-15">Plan kopieren</div>
                </div>
                <Link
                  to={{
                    pathname: `/plans/edit/${id}`,
                    state: {
                      from: 'plan-detail',
                      planDayId: currentDayId,
                    },
                  }}
                >
                  <div className="rounded-3xl bg-lightGray h-150 w-150">
                    <div className="pt-20">
                      <PencilIcon width={42} height={42} className="text-accentColor cursor-pointer mx-auto" />
                    </div>
                    <div className="text-center font-semibold text-18 px-20 pt-30">Bearbeiten</div>
                  </div>
                </Link>
              </div>
            ) : (
              <></>
            )}
            <div className="pt-40">
              {currentPlan?.description !== undefined && (
                <>
                  <div className="font-semibold px-6 text-20">Beschreibung</div>
                  <div className="pt-10 font-extralight px-6">
                    <div>{currentPlan?.description}</div>
                  </div>
                </>
              )}
              <div className="pt-20 mx-20">
                <HashTagBadge planObject={currentPlan} />
              </div>
            </div>
            <div className="font-bold text-20 pt-20 mx-20">Übersicht</div>
            <div id="planOverviewMobile">
              <div className="mx-20 pt-10">
                <SwitchSelector
                  onChange={changeSwitch}
                  options={displayTypeOptions}
                  initialSelectedIndex={0}
                  fontSize={20}
                  selectionIndicatorMargin={0}
                  selectedFontColor={theme?.buttonTextColor ?? defaultButtonTextColor}
                  fontColor={theme?.textColor ?? defaultTextColor}
                />
              </div>
              {currentSection === 'day' ? (
                <>
                  <div className="pt-15  pb-15 mx-20 flex gap-15 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-600">
                    {currentPlan?.dayEntries &&
                      currentPlan?.dayEntries.map((item: any, index: number) => (
                        <>
                          <div
                            key={index}
                            onClick={() => changePlanDay(item.id)}
                            onKeyDown={() => changePlanDay(item.id)}
                            aria-hidden="true"
                            className="cursor-pointer"
                          >
                            <div
                              className={
                                currentDayId === index + 1
                                  ? 'bg-accentColor text-buttonTextColor rounded-full  w-50 h-50 pt-5 border-transparent border-2 hover:border-accentColor'
                                  : 'bg-lightGray rounded-full  w-50 h-50 pt-5 border-transparent border-2 hover:border-accentColor'
                              }
                            >
                              {currentPlan?.examplePlan ? (
                                <>
                                  <div className="font-thin text-10 text-center">Tag</div>
                                  <div className="text-17 font-light text-center">{index + 1}</div>
                                </>
                              ) : (
                                <>
                                  <div className="font-thin text-10 text-center">
                                    {moment(moment.unix(currentPlan?.startDate.seconds).utc())
                                      .locale('de')
                                      .subtract(-index, 'days')
                                      .startOf('day')
                                      .format('dd')}
                                  </div>
                                  <div className="text-17 font-light text-center">
                                    {moment(moment.unix(currentPlan?.startDate.seconds).utc())
                                      .subtract(-index, 'days')
                                      .startOf('day')
                                      .format('D')}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                  <div className="rounded-3xl bg-lightGray mt-20 mx-6 pb-40">
                    <div className="font-extralight pt-20 pb-30 px-20 text-14">
                      Dieser Tag würde deinen aktuellen Bedarf folgendermaßen decken
                    </div>
                    <MakroCircles
                      kcal_value={currentDayItem?.kcal_total}
                      carbohydrates_value={currentDayItem?.carbohydrates_total}
                      protein_value={currentDayItem?.protein_total}
                      fat_value={currentDayItem?.fat_total}
                      custom_kcal_value={
                        currentPlan?.examplePlan === true && userData?.role === 2
                          ? undefined
                          : currentDayItem?.custom_kcal_total
                          ? currentDayItem?.custom_kcal_total
                          : currentPlan?.custom_kcal_total
                          ? currentPlan?.custom_kcal_total
                          : undefined
                      }
                      custom_carbohydrates_value={
                        currentPlan?.examplePlan === true && userData?.role === 2
                          ? undefined
                          : currentDayItem?.custom_carbohydrates_total
                          ? currentDayItem?.custom_carbohydrates_total
                          : currentPlan?.custom_carbohydrates_total
                          ? currentPlan?.custom_carbohydrates_total
                          : undefined
                      }
                      custom_protein_value={
                        currentPlan?.examplePlan === true && userData?.role === 2
                          ? undefined
                          : currentDayItem?.custom_protein_total
                          ? currentDayItem?.custom_protein_total
                          : currentPlan?.custom_protein_total
                          ? currentPlan?.custom_protein_total
                          : undefined
                      }
                      custom_fat_value={
                        currentPlan?.examplePlan === true && userData?.role === 2
                          ? undefined
                          : currentDayItem?.custom_fat_total
                          ? currentDayItem?.custom_fat_total
                          : currentPlan?.custom_fat_total
                          ? currentPlan?.custom_fat_total
                          : undefined
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                  <WeekOverview planStateValue={currentPlan} withClick={!currentPlan.examplePlan} updateDayId={id} />
                </>
              )}
            </div>
            <div className="grid grid-cols-2 gap-30 pt-40 mx-6" id="planDaysMobile">
              <MealCard
                kcal_value={currentDayBreakfastKcalTotal}
                carbohydrates_value={currentDayBreakfastCarbohydratesTotal}
                protein_value={currentDayBreakfastProteinTotal}
                fat_value={currentDayBreakfastFatTotal}
                label="Frühstück"
                mealLabel={currentDayBreakfastMealLabel}
                mealCounter={currentDayBreakfastMealCounter}
                imageUrl={currentDayBreakfastImage}
                type={currentDayBreakfastType}
                mealType="breakfast"
                onClick={openMealPopup}
              />
              <MealCard
                kcal_value={currentDayLunchKcalTotal}
                carbohydrates_value={currentDayLunchCarbohydratesTotal}
                protein_value={currentDayLunchProteinTotal}
                fat_value={currentDayLunchFatTotal}
                label="Mittagessen"
                mealLabel={currentDayLunchMealLabel}
                mealCounter={currentDayLunchMealCounter}
                imageUrl={currentDayLunchImage}
                type={currentDayLunchType}
                mealType="lunch"
                onClick={openMealPopup}
              />
              <MealCard
                kcal_value={currentDayDinnerKcalTotal}
                carbohydrates_value={currentDayDinnerCarbohydratesTotal}
                protein_value={currentDayDinnerProteinTotal}
                fat_value={currentDayDinnerFatTotal}
                label="Abendessen"
                mealLabel={currentDayDinnerMealLabel}
                mealCounter={currentDayDinnerMealCounter}
                imageUrl={currentDayDinnerImage}
                type={currentDayDinnerType}
                mealType="dinner"
                onClick={openMealPopup}
              />
              <MealCard
                kcal_value={currentDaySnacksKcalTotal}
                carbohydrates_value={currentDaySnacksCarbohydratesTotal}
                protein_value={currentDaySnacksProteinTotal}
                fat_value={currentDaySnacksFatTotal}
                label="Snacks"
                mealLabel={currentDaySnacksMealLabel}
                mealCounter={currentDaySnacksMealCounter}
                imageUrl={currentDaySnacksImage}
                type={currentDaySnacksType}
                mealType="snacks"
                onClick={openMealPopup}
              />
            </div>
          </div>
        </div>
      </div>
      {currentPlan?.uid !== undefined && (
        <>
          <ShepherdTour
            steps={[
              {
                id: 'intro',
                attachTo: { element: '#addToList', on: 'top' },
                buttons: [
                  {
                    classes: 'shepherd-button-secondary',
                    text: 'Beenden',
                    type: 'cancel',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Weiter',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Ernährungsplan starten!',
                text: [
                  '<p>Wenn dir ein Beispielplan gefällt, kannst du diesen durch Klick auf diesen Button starten.</p><p> Du musst im nächsten Schritt nur noch ein Startdatum festlegen und dann kannst du loslegen.</p>',
                ],
                when: {
                  destroy() {
                    updateTourToFirebase(userData, 'plansDetail', authContext.user?.uid!, tenant);
                    setTimeout(() => {
                      document.getElementById('mainContainer')?.scrollIntoView();
                    }, 1);
                  },
                },
              },
              {
                id: 'intro',
                attachTo: { element: '#planOverview', on: 'left' },
                buttons: [
                  {
                    classes: 'shepherd-button-secondary',
                    text: 'Beenden',
                    type: 'cancel',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Weiter',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Tages und Wochenansicht!',
                text: [
                  '<p>Hier siehst du die Tages- und Wochenansicht von dem Beispielplan.</p><p>Du kannst die Tage anklicken und in der Anzeige der Nährwerte prüfen, ob dieser Beispielplan zu deinen Werten passt.</p><p>In der Wochenansicht kannst du das über die gesamte Woche prüfen. Die 100% Linie gibt dir eine schnelle Richtlinie.</p>',
                ],
              },
              {
                id: 'intro',
                attachTo: { element: '#planDays', on: 'top' },
                buttons: [
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Zurück',
                    type: 'back',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Beenden',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Übersicht der Mahlzeiten!',
                text: [
                  '<p>Hier findest du die einzelnen Mahlzeiten. Durch Klick auf eine Mahlzeit kannst du prüfen, was für die jeweilige Mahlzeit eingeplant wurde.</p>',
                ],
              },
            ]}
            tourOptions={tourOptions}
          >
            <TourButton />
          </ShepherdTour>

          <ShepherdTour
            steps={[
              {
                id: 'intro',
                attachTo: { element: '#addToListMobile', on: 'top' },
                buttons: [
                  {
                    classes: 'shepherd-button-secondary',
                    text: 'Beenden',
                    type: 'cancel',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Weiter',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Ernährungsplan starten!',
                text: [
                  '<p>Wenn dir ein Beispielplan gefällt, kannst du diesen durch Klick auf diesen Button starten.</p><p> Du musst im nächsten Schritt nur noch ein Startdatum festlegen und dann kannst du loslegen.</p>',
                ],
                when: {
                  destroy() {
                    updateTourToFirebase(userData, 'plansDetail', authContext.user?.uid!, tenant);
                    setTimeout(() => {
                      document.getElementById('mainContainer')?.scrollIntoView();
                    }, 1);
                  },
                },
              },
              {
                id: 'intro',
                attachTo: { element: '#planOverviewMobile', on: 'bottom' },
                buttons: [
                  {
                    classes: 'shepherd-button-secondary',
                    text: 'Beenden',
                    type: 'cancel',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Weiter',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Tages und Wochenansicht!',
                text: [
                  '<p>Hier siehst du die Tages- und Wochenansicht von dem Beispielplan.</p><p>Du kannst die Tage anklicken und in der Anzeige der Nährwerte prüfen, ob dieser Beispielplan zu deinen Werten passt.</p><p>In der Wochenansicht kannst du das über die gesamte Woche prüfen. Die 100% Linie gibt dir eine schnelle Richtlinie.</p>',
                ],
              },
              {
                id: 'intro',
                attachTo: { element: '#planDaysMobile', on: 'top' },
                buttons: [
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Zurück',
                    type: 'back',
                  },
                  {
                    classes: 'shepherd-button-primary',
                    text: 'Beenden',
                    type: 'next',
                  },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                highlightClass: 'highlight',
                scrollTo: true,
                cancelIcon: {
                  enabled: true,
                },
                title: 'Übersicht der Mahlzeiten!',
                text: [
                  '<p>Hier findest du die einzelnen Mahlzeiten. Durch Klick auf eine Mahlzeit kannst du prüfen, was für die jeweilige Mahlzeit eingeplant wurde.</p>',
                ],
              },
            ]}
            tourOptions={tourOptions}
          >
            <TourButtonMobile />
          </ShepherdTour>
        </>
      )}

      {currentPlan?.kcal_total === undefined ? (
        <div className="h-full w-full xl:flex hidden">
          <div className={styles.loading}>
            <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
          </div>
        </div>
      ) : (
        <div className="xl:flex hidden">
          <div className="w-1/2 max-w-2xl">
            <div className="sticky top-0">
              <div className="w-32 pt-60">
                <div>
                  {typeof location.state !== undefined &&
                  location.state !== null &&
                  location.state?.from === 'dashboard' ? (
                    <Link
                      to={{
                        pathname: '/',
                        state: {
                          uid: location.state?.uid,
                          id: location.state?.id,
                          isCurrentPlansDashboard: location.state?.isCurrentPlansDashboard,
                          isBackLink: true,
                        },
                      }}
                    >
                      <ButtonBack
                        text={t('Return')}
                        className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                      />
                    </Link>
                  ) : (
                    <Link
                      to={{
                        pathname: '/plans',
                        state: {
                          from: 'plan-detail',
                          scrollPos: currentScrollPos,
                          planExampleCategory: currentSamplePlanCategory,
                          planExampleCalories: currentSamplePlanCalories,
                        },
                      }}
                    >
                      <ButtonBack
                        text={t('Return')}
                        className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                      />
                    </Link>
                  )}
                </div>
              </div>
              <div className="pt-40 font-semibold text-30">{currentPlan?.name}</div>
              {currentPlan?.examplePlan === true ? (
                <>
                  <div className="pt-10 font-extralight">
                    <span className="font-semibold">Laufzeit:</span> {currentPlan?.dayEntries?.length} Tage
                  </div>
                  {userData?.role === 2 ? (
                    <div className="pt-10 font-extralight">Tortija-Beispielplan</div>
                  ) : (
                    <div className="pt-10 font-extralight">Beispielplan</div>
                  )}
                </>
              ) : (
                <div className="flex">
                  <div className="pt-10 font-extralight">
                    <span className="font-semibold">Laufzeit:</span>{' '}
                    {moment.unix(currentPlan?.startDate?.seconds).utc().format('DD[.]MM[.]YY')} -{' '}
                    {moment.unix(currentPlan?.endDate?.seconds).utc().format('DD[.]MM[.]YY')}
                  </div>
                </div>
              )}
              <div className="pt-20">
                <img
                  className="object-cover rounded-3xl h-300 w-full"
                  src={currentPlan?.imageUrl}
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="pt-20 flex justify-center gap-4">
                <div
                  title="Favoriten"
                  className="rounded-2xl border-transparent border-2 hover:border-accentColor p-5 cursor-pointer"
                  onClick={() => savePlanToFavorites()}
                  onKeyDown={() => savePlanToFavorites()}
                  aria-hidden="true"
                >
                  <HeartIcon
                    width={28}
                    height={28}
                    className="text-accentColor mx-auto"
                    fill={(planFavorite && '#C97132') || 'none'}
                  />
                  <div className="font-extralight text-10 pt-1">Zu Favoriten</div>
                </div>
                {userData?.role === 1 || currentPlan?.examplePlan === false ? (
                  <>
                    <div
                      title="Löschen"
                      className="rounded-2xl border-transparent border-2 hover:border-accentColor p-5 cursor-pointer"
                      onClick={() => setDeleteRecipeOverlayClass('block')}
                      onKeyDown={() => setDeleteRecipeOverlayClass('block')}
                      aria-hidden="true"
                    >
                      <TrashIcon width={28} height={28} className="text-accentColor mx-auto" />
                      <div className="font-extralight text-10 pt-1">Löschen</div>
                    </div>
                    <div
                      title="Teilen"
                      className="rounded-2xl border-transparent border-2 hover:border-accentColor pt-5 pl-5 pb-5 pr-10 cursor-pointer"
                      onClick={() => openSharePopup()}
                      onKeyDown={() => openSharePopup()}
                      aria-hidden="true"
                    >
                      <ShareIcon width={28} height={28} className="text-accentColor mx-auto" />
                      <div className="font-extralight text-10 pt-1">Teilen</div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <a
                  href={`${process.env.REACT_APP_API_URL}/generatePdf?templateName=nutritionPlan&tenant=${tenant}&user=${user?.uid}&planId=${currentPlan?.uid}`}
                  title="Download"
                  target="_blank"
                  rel="noreferrer"
                  className="rounded-2xl border-transparent border-2 hover:border-accentColor p-5 cursor-pointer"
                >
                  <Icon name="pdfFile" width={28} height={28} className="text-accentColor mx-auto fill-[#c97132]" />
                  <div className="font-extralight text-10 pt-1">Drucken</div>
                </a>
              </div>
              <div className="pt-40 font-semibold text-20">Durchschnittliche Nährwertangaben des Plans</div>
              <div className="flex w-full gap-4 pt-10">
                <div className="flex-1 text-center">
                  <div className="font-semibold text-base">
                    {currentPlan?.kcal_total !== undefined && Math.round(getPlanAverageValues(currentPlan, 'kcal'))}
                  </div>
                  <div className={styles.recipeLabel}>kcal</div>
                </div>
                <div className="flex-1 text-center">
                  <div className="font-semibold text-base">
                    {currentPlan?.kcal_total !== undefined &&
                      Math.round(getPlanAverageValues(currentPlan, 'carbohydrates'))}
                    g
                  </div>
                  <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                </div>
                <div className="flex-1 text-center">
                  <div className="font-semibold text-base">
                    {currentPlan?.kcal_total !== undefined && Math.round(getPlanAverageValues(currentPlan, 'protein'))}g
                  </div>
                  <div className={styles.recipeLabel}>{t('Protein')}</div>
                </div>
                <div className="flex-1 text-center">
                  <div className="font-semibold text-base">
                    {currentPlan?.kcal_total !== undefined && Math.round(getPlanAverageValues(currentPlan, 'fat'))}g
                  </div>
                  <div className={styles.recipeLabel}>{t('Fat')}</div>
                </div>
              </div>
              <div className="pt-40">
                {currentPlan?.description !== undefined && (
                  <>
                    <div className="font-semibold text-20">Beschreibung</div>
                    <div className="pt-10 font-extralight px-6">
                      <div>{currentPlan?.description}</div>
                    </div>
                  </>
                )}
                <div className="pt-20 px-6">
                  <HashTagBadge planObject={currentPlan} />
                </div>
              </div>
              {/* <div>
              <Button className="w-full" onClick={() => saveTestPlanToFirebase()}>
                {t('SaveAsNewRecipe')}
              </Button>
            </div> */}
            </div>
          </div>
          <div className="w-1/2 pt-130 max-w-2xl" ref={mainLeftColumnRef}>
            {userData?.role === 1 && (
              <div className="flex gap-40 pt-60 justify-center">
                <div
                  className="rounded-3xl bg-lightGray h-150 w-150 cursor-pointer border-transparent border-2 hover:border-accentColor"
                  onClick={() => openStartPlan('copy')}
                  onMouseDown={() => openStartPlan('copy')}
                  aria-hidden="true"
                >
                  <div className="pt-20">
                    <DocumentDuplicateIcon width={42} height={42} className="text-accentColor mx-auto" />
                  </div>
                  <div className="text-center font-semibold text-18 px-20 pt-15">Plan kopieren</div>
                </div>
                <Link
                  to={{
                    pathname: `/plans/edit/${id}`,
                    state: {
                      from: 'plan-detail',
                      planDayId: currentDayId,
                    },
                  }}
                >
                  <div className="rounded-3xl bg-lightGray h-150 w-150 cursor-pointer border-transparent border-2 hover:border-accentColor">
                    <div className="pt-20">
                      <PencilIcon width={42} height={42} className="text-accentColor mx-auto" />
                    </div>
                    <div className="text-center font-semibold text-18 px-20 pt-30">Bearbeiten</div>
                  </div>
                </Link>
              </div>
            )}

            {userData?.role !== 1 && (currentPlan?.examplePlan || id === 'shared') ? (
              <div className="flex gap-40 pt-60 justify-center">
                <div
                  className="rounded-3xl bg-lightGray h-150 w-250 cursor-pointer border-transparent border-2 hover:border-accentColor"
                  onClick={() => openStartPlan('add')}
                  onMouseDown={() => openStartPlan('add')}
                  aria-hidden="true"
                  id="addToList"
                >
                  <div className="pt-20">
                    <PlusIcon width={42} height={42} className="text-accentColor mx-auto" />
                  </div>
                  <div className="text-center font-semibold text-18 px-20 pt-15">Zu meiner Liste hinzufügen</div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {userData?.role !== 1 && currentPlan?.examplePlan === false && id !== 'shared' ? (
              <div className="flex gap-40 pt-60 justify-center">
                <div
                  className="rounded-3xl bg-lightGray h-150 w-150 cursor-pointer border-transparent border-2 hover:border-accentColor"
                  onClick={() => openStartPlan('copy')}
                  onMouseDown={() => openStartPlan('copy')}
                  aria-hidden="true"
                >
                  <div className="pt-20">
                    <DocumentDuplicateIcon width={42} height={42} className="text-accentColor mx-auto" />
                  </div>
                  <div className="text-center font-semibold text-18 px-20 pt-15">Plan kopieren</div>
                </div>

                <Link
                  to={{
                    pathname: `/plans/edit/${id}`,
                    state: {
                      from: 'plan-detail',
                      planDayId: currentDayId,
                    },
                  }}
                >
                  <div className="rounded-3xl bg-lightGray h-150 w-150 cursor-pointer border-transparent border-2 hover:border-accentColor">
                    <div className="pt-20">
                      <PencilIcon width={42} height={42} className="text-accentColor mx-auto" />
                    </div>
                    <div className="text-center font-semibold text-18 px-20 pt-30">Bearbeiten</div>
                  </div>
                </Link>
              </div>
            ) : (
              <></>
            )}

            <div className="mx-40">
              <div className="font-bold text-20 pt-20">Übersicht</div>
              <div id="planOverview">
                <div className="pt-10">
                  <SwitchSelector
                    onChange={changeSwitch}
                    options={displayTypeOptions}
                    initialSelectedIndex={0}
                    fontSize={20}
                    selectionIndicatorMargin={0}
                    selectedFontColor={theme?.buttonTextColor ?? defaultButtonTextColor}
                    fontColor={theme?.textColor ?? defaultTextColor}
                  />
                </div>
                {currentSection === 'day' ? (
                  <>
                    <div className="pt-20 flex gap-15 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-600 pb-30">
                      {currentPlan?.dayEntries &&
                        currentPlan?.dayEntries.map((item: any, index: number) => (
                          <>
                            <div
                              key={index}
                              onClick={() => changePlanDay(item.id)}
                              onKeyDown={() => changePlanDay(item.id)}
                              aria-hidden="true"
                              className="cursor-pointer"
                            >
                              <div
                                className={
                                  currentDayId === index + 1
                                    ? 'bg-accentColor text-buttonTextColor rounded-full  w-50 h-50 pt-5 border-transparent border-2 hover:border-accentColor'
                                    : 'bg-lightGray rounded-full  w-50 h-50 pt-5 border-transparent border-2 hover:border-accentColor'
                                }
                              >
                                {currentPlan?.examplePlan ? (
                                  <>
                                    <div className="font-thin text-10 text-center">Tag</div>
                                    <div className="text-17 font-light text-center">{index + 1}</div>
                                  </>
                                ) : (
                                  <>
                                    <div className="font-thin text-10 text-center">
                                      {moment(moment.unix(currentPlan?.startDate.seconds).utc())
                                        .locale('de')
                                        .subtract(-index, 'days')
                                        .startOf('day')
                                        .format('dd')}
                                    </div>
                                    <div className="text-17 font-light text-center">
                                      {moment(moment.unix(currentPlan?.startDate.seconds).utc())
                                        .subtract(-index, 'days')
                                        .startOf('day')
                                        .format('D')}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                    <div className="rounded-3xl bg-lightGray mt-20 pb-40 mb-40">
                      <div className="font-extralight pt-20 pb-30 px-20 text-14">
                        Dieser Tag würde deinen aktuellen Bedarf folgendermaßen decken
                      </div>
                      <MakroCircles
                        kcal_value={currentDayItem?.kcal_total}
                        carbohydrates_value={currentDayItem?.carbohydrates_total}
                        protein_value={currentDayItem?.protein_total}
                        fat_value={currentDayItem?.fat_total}
                        custom_kcal_value={
                          currentPlan?.examplePlan === true && userData?.role === 2
                            ? undefined
                            : currentDayItem?.custom_kcal_total
                            ? currentDayItem?.custom_kcal_total
                            : currentPlan?.custom_kcal_total
                            ? currentPlan?.custom_kcal_total
                            : undefined
                        }
                        custom_carbohydrates_value={
                          currentPlan?.examplePlan === true && userData?.role === 2
                            ? undefined
                            : currentDayItem?.custom_carbohydrates_total
                            ? currentDayItem?.custom_carbohydrates_total
                            : currentPlan?.custom_carbohydrates_total
                            ? currentPlan?.custom_carbohydrates_total
                            : undefined
                        }
                        custom_protein_value={
                          currentPlan?.examplePlan === true && userData?.role === 2
                            ? undefined
                            : currentDayItem?.custom_protein_total
                            ? currentDayItem?.custom_protein_total
                            : currentPlan?.custom_protein_total
                            ? currentPlan?.custom_protein_total
                            : undefined
                        }
                        custom_fat_value={
                          currentPlan?.examplePlan === true && userData?.role === 2
                            ? undefined
                            : currentDayItem?.custom_fat_total
                            ? currentDayItem?.custom_fat_total
                            : currentPlan?.custom_fat_total
                            ? currentPlan?.custom_fat_total
                            : undefined
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="pb-20">
                      <WeekOverview
                        planStateValue={currentPlan}
                        withClick={!currentPlan.examplePlan}
                        updateDayId={id}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="grid grid-cols-2 gap-30" id="planDays">
                <MealCard
                  kcal_value={currentDayBreakfastKcalTotal}
                  carbohydrates_value={currentDayBreakfastCarbohydratesTotal}
                  protein_value={currentDayBreakfastProteinTotal}
                  fat_value={currentDayBreakfastFatTotal}
                  label="Frühstück"
                  mealLabel={currentDayBreakfastMealLabel}
                  mealCounter={currentDayBreakfastMealCounter}
                  imageUrl={currentDayBreakfastImage}
                  type={currentDayBreakfastType}
                  mealType="breakfast"
                  onClick={openMealPopup}
                />
                <MealCard
                  kcal_value={currentDayLunchKcalTotal}
                  carbohydrates_value={currentDayLunchCarbohydratesTotal}
                  protein_value={currentDayLunchProteinTotal}
                  fat_value={currentDayLunchFatTotal}
                  label="Mittagessen"
                  mealLabel={currentDayLunchMealLabel}
                  mealCounter={currentDayLunchMealCounter}
                  imageUrl={currentDayLunchImage}
                  type={currentDayLunchType}
                  mealType="lunch"
                  onClick={openMealPopup}
                />
                <MealCard
                  kcal_value={currentDayDinnerKcalTotal}
                  carbohydrates_value={currentDayDinnerCarbohydratesTotal}
                  protein_value={currentDayDinnerProteinTotal}
                  fat_value={currentDayDinnerFatTotal}
                  label="Abendessen"
                  mealLabel={currentDayDinnerMealLabel}
                  mealCounter={currentDayDinnerMealCounter}
                  imageUrl={currentDayDinnerImage}
                  type={currentDayDinnerType}
                  mealType="dinner"
                  onClick={openMealPopup}
                />
                <MealCard
                  kcal_value={currentDaySnacksKcalTotal}
                  carbohydrates_value={currentDaySnacksCarbohydratesTotal}
                  protein_value={currentDaySnacksProteinTotal}
                  fat_value={currentDaySnacksFatTotal}
                  label="Snacks"
                  mealLabel={currentDaySnacksMealLabel}
                  mealCounter={currentDaySnacksMealCounter}
                  imageUrl={currentDaySnacksImage}
                  type={currentDaySnacksType}
                  mealType="snacks"
                  onClick={openMealPopup}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PlanDetail;

import React, { useContext, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import axios from 'axios';

import { generateRandomUid } from 'shared/functions/global';
import * as XLSX from 'xlsx';
import firebase from 'services/firebase';
import Headline from 'components/Headline';
import Button from 'components/Button';
import { AuthContext } from 'providers/AuthProvider';
import { companyRoleOptions } from 'shared/constants/global';
import styles from './styles.module.scss';

export default function ImportCompanyMember() {
  const { t } = useTranslation();
  const history = useHistory();
  const { tenant, companyData, tenantData } = useContext(AuthContext);
  const [isPending, setIsPending] = useState(false);
  const [showEntries, setShowEntries] = useState(false);
  const [importPending, setImportPending] = useState(false);
  const [showWrongEntries, setShowWrongEntries] = useState(false);
  const [fileName, setFileName] = useState('');

  const [importComplete, setImportComplete] = useState(false);

  const [data, setData] = useState<any>([]);
  const [wrongData, setWrongData] = useState<any>([]);
  const [notImportedData, setNotImportedData] = useState<any>([]);

  const handleShowEntries = () => {
    if (showEntries) {
      setShowEntries(false);
    } else {
      setShowEntries(true);
    }
  };

  const handleShowWrongEntries = () => {
    if (showWrongEntries) {
      setShowWrongEntries(false);
    } else {
      setShowWrongEntries(true);
    }
  };

  const clearData = () => {
    setData([]);
    setWrongData([]);
  };

  const importData = async () => {
    setImportPending(true);
    setIsPending(true);
    const db = firebase.firestore();
    const isBatchValid = true;

    const notImportedDataArray = [] as any;

    const batches = [] as any;
    batches[0] = db.batch();
    const ref = firebase.firestore().collection('user-invitations');

    /* eslint-disable no-await-in-loop */
    for (let i = 0; i <= data.length - 1; i += 1) {
      let isItemValid = true;

      const thisNewDocumentIdLoop = generateRandomUid();
      const thisRef = ref.doc(thisNewDocumentIdLoop);

      const inviteData = await ref.where('email', '==', data[i].Email).get();
      if (!inviteData.empty) {
        notImportedDataArray.push(data[i]);
        isItemValid = false;
      }

      // check if user is already member
      const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(data[i].Email);
      if (signInMethods.length > 0) {
        notImportedDataArray.push(data[i]);
        isItemValid = false;
      }

      const memberData = await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .where('email', '==', data[i].Email)
        .get();
      if (!memberData.empty) {
        notImportedDataArray.push(data[i]);
        isItemValid = false;
      }

      if (isItemValid) {
        let invitationMailText = `Hallo ${data[i].Name},\n\ndu wurdest zu der Ernährungssoftware von ${
          companyData?.companyName
        } eingeladen.\n\nBitte klicke auf den folgenden Link, um weitere Informationen zu erhalten:\n${
          window.location.hostname === 'demo.tortija.de'
            ? 'https://whitelabel.tortija.de/demo/'
            : tenantData?.invitationLink !== undefined
            ? tenantData?.invitationLink
            : 'https://whitelabel.tortija.de/einladung/'
        }\n\nViel Spaß mit unserer Ernährungssoftware!`;

        if (
          tenantData?.settings?.invitationMail?.mailText !== undefined &&
          tenantData?.settings?.invitationMail?.mailText?.length > 0
        ) {
          invitationMailText = `Hallo ${data[i].Name},\n\n${tenantData?.settings?.invitationMail?.mailText}`;
        }

        await axios({
          url: `${process.env.REACT_APP_API_URL}/sendTextMail`,
          method: 'post',
          data: {
            mailSubject:
              tenantData?.settings?.invitationMail?.mailSubject !== undefined &&
              tenantData?.settings?.invitationMail?.mailSubject?.length > 0
                ? tenantData?.settings?.invitationMail?.mailSubject
                : `${companyData?.companyName} Einladung`,
            mailTo: data[i].Email,
            mailText: invitationMailText,
            mailFrom:
              tenantData?.settings?.invitationMail?.mailFrom !== undefined &&
              tenantData?.settings?.invitationMail?.mailFrom?.length > 0
                ? tenantData?.settings?.invitationMail?.mailFrom
                : 'info@tortija.de',
            mailName:
              tenantData?.settings?.invitationMail?.mailName !== undefined &&
              tenantData?.settings?.invitationMail?.mailName?.length > 0
                ? tenantData?.settings?.invitationMail?.mailName
                : '',
          },
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        }).then(
          response => {
            console.log(response);
          },
          error => {
            console.log(error);
          }
        );

        let getLocationID = 0;

        if (data[i].Standort) {
          const getLocationRef = tenantData?.settings?.locations?.filter(item => item.name === data[i].Standort)[0];

          if (getLocationRef !== undefined) {
            getLocationID = getLocationRef.id;
          }
        }

        let getRoleId = 5;

        if (data[i].Rolle !== undefined && data[i].Rolle !== 'Admin') {
          const filterRole = companyRoleOptions.filter((item: any) => item.label === data[i].Rolle)[0];

          if (filterRole !== undefined) {
            getRoleId = filterRole.value;
          }
        }

        await batches[0].set(thisRef, {
          email: data[i].Email,
          fullName: data[i].Name,
          location: getLocationID,
          role: getRoleId,
          trainer: null,
          trainerRef: null,
          tenant,
          created: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
    }

    await batches[0].commit().then(function () {
      console.log('wrote batch');
    });

    if (notImportedDataArray.length > 0) {
      setNotImportedData(notImportedDataArray);
    }

    setImportComplete(true);
    setIsPending(false);
    setImportPending(false);
  };

  const handleFileUpload = (e: any) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    setFileName(e.target.files[0].name);
    reader.onload = (item: any) => {
      const dataImport = item.target.result;
      const workbook = XLSX.read(dataImport, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);

      const importArray = [] as any;
      const importArrayWrongEntries = [] as any;

      let index = 2;

      // Check data
      parsedData.forEach((obj: any) => {
        if (obj.Name !== undefined && obj.Email !== undefined) {
          const regExMail = /\S+@\S+\.\S+/;

          // Check if mail is valid
          if (regExMail.test(obj.Email) && obj.Name.length > 0) {
            importArray.push({
              Name: obj.Name,
              Email: obj.Email,
              Standort: obj.Standort ?? '',
              Rolle: obj.Rolle ?? '',
            });
          } else {
            importArrayWrongEntries.push({
              Name: obj.Name,
              Email: obj.Email,
              Standort: obj.Standort ?? '',
              Rolle: obj.Rolle ?? '',
              Line: index,
            });
          }
        } else {
          importArrayWrongEntries.push({
            Name: obj.Name !== undefined ? obj.Name : '',
            Email: obj.Email !== undefined ? obj.Email : '',
            Standort: obj.Standort !== undefined ? obj.Standort : '',
            Rolle: obj.Rolle !== undefined ? obj.Rolle : '',
            Line: index,
          });
        }

        index += 1;
      });

      if (importArray.length > 0) {
        if (importArray.length < 501) {
          setData(importArray);
        } else {
          toast.error(
            'Du kannst maximal 500 Einträge in einem Vorgang importieren. Bitte teile deine Excel Datei auf!'
          );
        }
      } else {
        toast.error(
          'Leider konnte die Excel-Datei nicht verarbeitet werden. Bitte prüfen Sie die Vorlage und Ihre Daten!'
        );
      }

      if (importArrayWrongEntries.length > 0) {
        setWrongData(importArrayWrongEntries);
      }

      const getForm = document.getElementById('uploadForm') as HTMLFormElement;
      getForm?.reset();
    };
  };

  return (
    <>
      <div className={styles.header}>
        <Headline level={1} displayBackBtn goBack={history.goBack}>
          {t('Import Member')}
        </Headline>
      </div>
      {importPending ? (
        <div className="text-center mt-40 font-bold text-25 w-full">
          <div>Die Daten werden importiert.....</div>
          <div>Das kann je nach Menge etwas Zeit in Anspruch nehmen.</div>
          <div className="pt-30">Bitte laden Sie die Seite nicht neu!</div>
        </div>
      ) : (
        <>
          {importComplete ? (
            <div className="w-full">
              <div className="font-bold text-25">Der Importvorgang war erfolgreich!</div>
              {notImportedData.length > 0 && (
                <div>
                  <div className="pt-10">
                    Folgende Einträge konnten nicht importiert werden, weil die Mitglieder entweder schon eingeladen
                    wurde oder einen Tortija Account besitzt.
                  </div>
                  <div>Bitte wenden Sie sich dazu nochmal bei uns und wir prüfen das.</div>
                  <div className="flex w-full md:w-1/2 pt-20">
                    <div className="w-1/2 font-bold">Name</div>
                    <div className="font-bold">E-Mail Adresse</div>
                    <div className="font-bold">Standort</div>
                    <div className="font-bold">Rolle</div>
                  </div>
                </div>
              )}
              {notImportedData.map((item: any, index: number) => (
                <div key={index} className="flex w-full md:w-1/2 pb-5">
                  <div className="w-1/2">{item.Name}</div>
                  <div>{item.Email}</div>
                  <div>{item.Standort}</div>
                  <div>{item.Rolle}</div>
                </div>
              ))}
              <Link to="/member" type="button" className="my-auto pt-30">
                <Button>Zur Mitgliederliste</Button>
              </Link>
            </div>
          ) : (
            <>
              <div>
                <p className="mb-10">Auf dieser Seite können Sie neue Mitglieder importieren.</p>
                <p className="mb-10">
                  Bitte laden Sie sich dazu unsere Excel Vorlage herunter und tragen in jeder Zeile den Namen und die
                  E-Mail Adresse von dem Mitglied ein.
                </p>
                <p className="mb-10">
                  <strong>Hinweis 1:</strong> Das Standort-Feld ist optional und muss nicht zwingend gepflegt werden.
                </p>
                <p>
                  <strong>Hinweis 2:</strong> Verfügbare Werte für Rolle: Mitglied, Trainer, Gruppenleiter
                </p>
                <p className="mb-10">
                  Admin Accounts können nicht importiert werden und müssen manuell angelegt werden!
                </p>
                <p className="mb-10">
                  <strong>Hinweis 3:</strong> Es können <u>maximal 500 Mitglieder</u> pro Importvorgang importiert
                  werden! Wenn Sie mehr als 500 Mitglieder importieren wollen, teilen Sie die Dateien bitte entsprechend
                  ein!
                </p>
                <p className="mb-30">
                  Jedes Mitglied bekommt dann eine E-Mail mit einem Einladungslink und muss sich einmal registrieren.
                  Sie bezahlen erst für das Mitglied, wenn die Person sich registriert hat.
                </p>
                <div className="mb-50">
                  <Link
                    to={{
                      pathname:
                        'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/Import%2FTortija_Import_Mitglieder_Vorlage.xlsx?alt=media&token=5e3872d9-a89b-4748-8320-22291cf13e95',
                    }}
                    target="_blank"
                  >
                    <Button className="py-10">Download Excel Vorlage</Button>
                  </Link>
                </div>
              </div>
              {data.length === 0 && (
                <div>
                  <div className="mb-10">
                    Bitte wählen Sie hier eine ausgefüllte Excel-Datei auf Basis unserer Vorlage aus.
                  </div>
                  <form id="uploadForm">
                    <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
                  </form>
                </div>
              )}

              {data.length > 0 && (
                <div>
                  <div>
                    Die Datei <u>"{fileName}"</u> wurde erfolgreich hochgeladen und geprüft.
                  </div>
                  <div className="pt-10">
                    <Button onClick={() => clearData()} className="py-10">
                      Neuer Import
                    </Button>
                  </div>
                  <div className="pt-30">
                    Es werden <strong>{data.length} Mitglieder</strong> importiert.
                  </div>
                  <div className="mt-10">
                    <Button onClick={() => handleShowEntries()} className="py-10">
                      Einträge anzeigen
                    </Button>
                  </div>
                  {showEntries && data.length > 0 && (
                    <>
                      <div className="grid grid-cols-3 pt-20">
                        <div>
                          <div className="font-bold">Name</div>
                          {data.map((item: any, index: number) => (
                            <div key={index} className="flex w-full md:w-1/2 pb-5">
                              <div>{item.Name}</div>
                            </div>
                          ))}
                        </div>
                        <div>
                          <div className="font-bold">E-Mail Adresse</div>
                          {data.map((item: any, index: number) => (
                            <div key={index} className="flex w-full md:w-1/2 pb-5">
                              <div>{item.Email}</div>
                            </div>
                          ))}
                        </div>
                        <div>
                          <div className="font-bold">Standort</div>
                          {data.map((item: any, index: number) => (
                            <div key={index} className="flex w-full md:w-1/2 pb-5">
                              <div>{item.Standort.length > 0 ? item.Standort : <>&nbsp;</>}</div>
                            </div>
                          ))}
                        </div>
                        <div>
                          <div className="font-bold">Rolle</div>
                          {data.map((item: any, index: number) => (
                            <div key={index} className="flex w-full md:w-1/2 pb-5">
                              <div>{item.Rolle.length > 0 ? item.Rolle : <>&nbsp;</>}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}

                  {wrongData.length > 0 && (
                    <>
                      <div className="pt-30">
                        Es wurden <strong>{wrongData.length} fehlerhafte Einträge</strong> erkannt. Diese werden nicht
                        importiert.
                      </div>
                      <div className="mt-10">
                        <Button onClick={() => handleShowWrongEntries()} className="py-10">
                          Einträge anzeigen
                        </Button>
                      </div>
                    </>
                  )}

                  {showWrongEntries && data.length > 0 && (
                    <>
                      <table className="w-1/2 mt-10">
                        <tr>
                          <td className="font-bold">Name</td>
                          <td className="font-bold">E-Mail Adresse</td>
                          <td className="font-bold">Excel Zeilennummer</td>
                        </tr>
                        {wrongData.map((item: any, index: number) => (
                          <tr key={index}>
                            <td>{item.Name}</td>
                            <td>{item.Email}</td>
                            <td>{item.Line}</td>
                          </tr>
                        ))}
                      </table>
                    </>
                  )}
                  <div className={styles.content}>
                    <Button isPending={isPending} onClick={() => importData()} className="w-full">
                      Jetzt importieren
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import * as ScanditSDK from 'scandit-sdk';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import { toast } from 'react-toast';
import axios from 'axios';
import { configure, BarcodePicker as ScanditSDKBarcodePicker } from 'scandit-sdk';

type HeaderProps = {
  visible?: boolean;
  playSoundOnScan?: boolean;
  vibrateOnScan?: boolean;
  scanningPaused?: boolean;
  guiStyle?: string;
  videoFit?: string;
  scanSettings?: object;
  enableCameraSwitcher?: boolean;
  enableTorchToggle?: boolean;
  enableTapToFocus?: boolean;
  enablePinchToZoom?: boolean;
  accessCamera?: boolean;
  camera?: object;
  cameraSettings?: object;
  targetScanningFPS?: number;
  onScan?: any;
  onError?: any;
  setVisible?: any;
  setContentVisible?: any;
  setFoodId: Function;
  visiblePopup: boolean;
};

const BarcodePicker: React.FC<HeaderProps> = ({
  visible,
  onScan,
  vibrateOnScan,
  setVisible,
  setContentVisible,
  setFoodId,
  visiblePopup,
}) => {
  const { accessTokenString } = useContext(MainContext);
  const [currentBarCodePicker, setCurrentBarCodePicker] = useState<ScanditSDK.BarcodePicker>();
  const { userData } = useContext(AuthContext);

  // Configure the library and activate it with a license key
  const configurationPromise = configure(
    'AWNETIqBHh7vDk+sCRG9zFMvA5UvIh+InBvF5ChOY3fzC23wa2iVtVxQt0x8cb07mDod3o56p1ACXRrc9jm4KcpwOqYudoP63kPy/Zd5OotGVR08ZSeDghFyWXYLX1enL2m5r8pPcgtwWCvYi2c6mA5IOLSbVIq94FHvbRRS7TSLbuk0cHfb79ZsZ2C6TbZX4HWpx6hhStGJVIlM/1mVS4xnftU2acemg11RO7NTmzm4daTZAHmEB3osVniQdTIOimMyWY1lJ9n4ZDkV700Rkj0ytbGjMU6cHFlLH3w0Lb8Ee3oiPBYE6BdlHPeICCb/NkrrlYpW1YN7La2GwEU4U5sm4BhAc7CXtXq7Q6QZHIZLetAEehzFCwxiri7KQqPp3WjhfORd5dzYdX1nl12sqTB8h6lNUO9v1FncwXxlV8pJQugrjFtQQLhFEaXeYhwLTF7s4mNUGRELTznb5UUEzi9fTZZJVQEs+UXC2UFp8PIsLmOij07lEWlWdu4bRtAlnUcN6418pqcLbnqV30WE8Ilcv9YjT0t0KzPQYZFh1Eu6fSX6e2Wt8TJQalbrQFBjwgJSsWEUAeqzMACXRUA1Qxf3ZCneRLCDDGagKg91ZdAhWvOgoh6enQzr/BvqHNz6GXvcpsC3rfuP40xN6VpsfsCqfAhZA46eg1x8zni6LAxVxHrMleTHq5cSQ5HkETWLX3oWgrPLN+7DePHKX59AraAgGLZQq+dvwVuuV4HUb9Kzr+N5AfDnu8RvHEYr6vNKpMslrit62sL2C5Y7Xehk0VoLtLuE336GA/1tACNgqQAH3s3/GTWjVVgQuIowkDlho/dyXZtNKDLaPdC9VglQ1DSR8CgEFXmDTPVl9JCamsCc/7CKU+mcS3i3dD4HaNSQRs5pyakn/yxIPf5cjzUbSX/UvwilTKb+njtEIMU30iJQfXkdW6vi7vISArVXgJkiwlV7DZ9GI7hOckteDQKdAXV3KeOrcrjGt0Vjq93tIAEjQzt7kuZW8XQqCXk1uvy+ThXRiI6eeEN9XpXElzR7N13titJP1RyIX0HJOdUY0MHcrPWaqlsWprmkKBNBe+AAN7j/oGbjSixmeMaE6RXE7RISLHoTY4nvhbGJ/Qnr4TdFBn9QPHn/Z7yWDdwwXzKmuRXFSWlBG2UOEeoaxwoa0b6DXBiOz+a3V+3ITXD1a9v0Jnmdhh21awKsiuils/1l+eB1JEbeYSLvEbDnadqa6zJnsLRkNIk7M4p76ZGpccUALE0JLC9DlcV2Yu6JJLow',
    {
      engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/',
    }
  ).catch(error => {
    console.log(error);
  });

  const scanSettings = new ScanditSDK.ScanSettings({
    enabledSymbologies: [ScanditSDK.Barcode.Symbology.EAN8, ScanditSDK.Barcode.Symbology.EAN13],
    codeDuplicateFilter: -1,
  });

  useEffect(() => {
    const portalDiv = document.getElementById('barcodeContainer')!;

    let scanditBarcodePicker!: ScanditSDK.BarcodePicker;

    configurationPromise.then(() => {
      ScanditSDKBarcodePicker.create(portalDiv, {
        playSoundOnScan: false,
        vibrateOnScan: false,
        accessCamera: false,
        visible,
      }).then(barcodePicker => {
        barcodePicker.applyScanSettings(scanSettings);
        setCurrentBarCodePicker(barcodePicker);
        barcodePicker.on('scan', scanResult => {
          let thisBarCode = '';
          const getBarCode = scanResult.barcodes.reduce((string, barcode) => {
            thisBarCode = barcode.data;
            return `${string}${barcode.data}\n\n`;
          }, '');

          if (visible === true && thisBarCode !== '0') {
            if (accessTokenString.length === 0) {
              try {
                axios({
                  url: `${process.env.REACT_APP_API_URL}/generateToken`,
                  method: 'post',
                  headers: {
                    'content-type': 'application/json',
                    Accept: 'application/json',
                  },
                }).then(response => {
                  const thisAccessTokenString = response.data.access_token;

                  try {
                    axios({
                      url: `${process.env.REACT_APP_API_URL}/getBarcode`,
                      method: 'post',
                      data: {
                        token: thisAccessTokenString,
                        barcode: thisBarCode,
                        language: userData?.country ?? 'DE',
                      },
                      headers: {
                        'content-type': 'application/json',
                        Accept: 'application/json',
                      },
                    }).then(response2 => {
                      if (response2.data.food_id !== undefined) {
                        setContentVisible(false);
                        setFoodId(response2.data.food_id.value);
                        barcodePicker.pauseScanning(true);
                      } else {
                        setVisible(false);
                        setContentVisible(false);
                        toast.warn('Es wurde leider kein Lebensmittel zu diesem Barcode gefunden!');
                        barcodePicker.pauseScanning(true);
                      }
                    });
                  } catch (err) {
                    console.log('error');
                  }
                });
              } catch (err) {
                console.log('error');
              }
            } else {
              try {
                axios({
                  url: `${process.env.REACT_APP_API_URL}/getBarcode`,
                  method: 'post',
                  data: {
                    token: accessTokenString,
                    barcode: thisBarCode,
                    language: userData?.country ?? 'DE',
                  },
                  headers: {
                    'content-type': 'application/json',
                    Accept: 'application/json',
                  },
                }).then(response => {
                  if (response.data.food_id !== undefined) {
                    setContentVisible(false);
                    setFoodId(response.data.food_id.value);
                    barcodePicker.pauseScanning(true);
                  } else {
                    setVisible(false);
                    setContentVisible(false);
                    toast.warn('Es wurde leider kein Lebensmittel zu diesem Barcode gefunden!');
                    barcodePicker.pauseScanning(true);
                  }
                });
              } catch (err) {
                console.log('error');
              }
            }
          }
        });
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (visiblePopup === true) {
      currentBarCodePicker?.accessCamera();
      currentBarCodePicker?.resumeScanning();
    } else {
      currentBarCodePicker?.pauseScanning(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visiblePopup]);

  return <div />;
};

export default BarcodePicker;

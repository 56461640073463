import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import axios from 'axios';
import firebase from 'services/firebase';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon, DownloadIcon, ClockIcon, CheckIcon, XIcon } from '@heroicons/react/solid';
import { getIsWebView } from 'shared/functions/global';
import Headline from 'components/Headline';
import { AuthContext } from 'providers/AuthProvider';
import Button from 'components/Button';
import { defaultTextColor } from 'layouts/Theme';

import styles from './styles.module.scss';

type Props = {};

interface Price {
  id: string;
  metadata: {
    order: string;
    required: string;
  };
  name: string;
  price: number;
  tiers: Array<{ up_to: number; unit_amount: number }>;
  type: string;
}

interface StripeInvoice {
  date: string;
  amount: number;
  quantity: number;
  status: any;
  link: string;
}

const CompanyBilling: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { tenantData, theme, tenant, setTenantData } = useContext(AuthContext);
  const history = useHistory();
  const [prices, setPrices] = useState<Price[]>([]);
  const [loadingInvoices, setLoadingInvoices] = useState(true);
  const [isNativeView, setIsNativeView] = useState(false);

  useEffect(() => {
    const checkIsNativeView = getIsWebView();

    if (checkIsNativeView === true) {
      setIsNativeView(true);
    }

    const fetchPrices = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/getPrices`);
      setPrices(res.data);
    };
    const fetchInvoices = () => {
      const data = {
        tenant,
      };

      axios({
        url: `${process.env.REACT_APP_API_URL ?? ''}/fetchTenantInvoicesFromStripe`,
        method: 'post',
        data,
        headers: {
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      }).then(response => {
        updateTenant();
      });
    };

    fetchPrices();
    fetchInvoices();

    setTimeout(() => {
      document.getElementById('mainContainer')?.scrollTo(0, 0);
    }, 1);
  }, []);

  const updateTenant = async () => {
    const tenantInfo = (await firebase.firestore().collection(`tenants`).doc(tenant).get()).data();
    setTenantData(tenantInfo as TenantInfo);
    setLoadingInvoices(false);
  };

  const groupedData = tenantData?.billing?.invoices?.reduce<Record<string, StripeInvoice[]>>((grouped, item) => {
    const year = item.date.split('.')[2];
    const newGrouped = { ...grouped };
    newGrouped[year] = [...(newGrouped[year] || []), item as unknown as StripeInvoice];
    return newGrouped;
  }, {});

  const currentYear = new Date().getFullYear().toString();

  return (
    <>
      <div className={styles.header}>
        <div className={styles.buttons}>
          <Headline level={1} displayBackBtn goBack={() => history.push('/company')}>
            {t('Lizenz und Rechnungen')}
          </Headline>
        </div>
      </div>
      <p className="mb-50">
        Auf dieser Seite können Sie Ihre Rechnungen herunterladen und die aktuelle Anzahl aktiver Mitglieder und die
        daraus resultierenden monatlichen Kosten einsehen.
      </p>
      <div className={styles.content}>
        <Headline level={2}>Lizenz</Headline>
        <div className="grid grid-cols-1 tablet:grid-cols-2 gap-20">
          {tenantData?.billing?.userCount && (
            <div className="bg-lightGray p-15 rounded-20">
              <label className="block text-18 font-regular opacity-50 mb-1">Aktuelle Nutzeranzahl</label>
              <Headline level={3}>{tenantData?.billing?.userCount}</Headline>
              <label className="block text-18 font-regular opacity-50 mb-1 pt-20">Gebuchte Pakete</label>
              {tenantData?.features?.mobileApp && <Headline level={3}>Mobile App</Headline>}
              {tenantData?.features?.reweOnlineShop && <Headline level={3}>Anbindung an Rewe Onlineshop</Headline>}
              {tenantData?.features?.inBody && <Headline level={3}>Anbindung an InBody</Headline>}
              {tenantData?.features?.reweOnlineShop === false &&
                tenantData?.features?.mobileApp === false &&
                tenantData?.features?.inBody === false && <Headline level={3}>Keine</Headline>}
              {tenantData?.features?.reweOnlineShop === false || tenantData?.features?.mobileApp === false ? (
                <div className="font-extralight pt-40">
                  <div>Möchten Sie ein weiteres Paket dazu buchen?</div>
                  <div className="pt-10">
                    <Link to={{ pathname: 'https://whitelabel.tortija.de/paket-upgrade/' }} target="_blank">
                      <Button className="py-10">Kontaktiere uns</Button>
                    </Link>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}

          {tenantData?.billing?.monthlyCosts && (
            <div className="bg-lightGray p-15 rounded-20">
              <label className="block text-18 font-regular opacity-50 mb-1">Monatliche Kosten</label>
              <Headline level={3}>
                {(tenantData?.billing?.monthlyCosts / 100).toString().replace('.', ',')}€{' '}
                <small className="text-12">zzgl. MwSt</small>
              </Headline>

              <div>
                {!prices ? (
                  <ReactLoading type="bars" width={30} height={30} color={theme?.textColor ?? defaultTextColor} />
                ) : (
                  <>
                    <label className="block text-18 font-regular opacity-50 mb-1 mt-6">
                      Preis pro Monat pro Nutzer (netto)
                    </label>
                    <div className="grid grid-cols-2">
                      {prices
                        .filter(price => price.name === 'Business Abo')
                        .map((price, outerIndex) => (
                          <React.Fragment key={`${price.id}-${outerIndex}`}>
                            <div key={outerIndex} className="col-start-1">
                              <p>
                                <b>Nutzer</b>
                              </p>
                              {price.tiers.map((tier, innerIndex) => {
                                const previousTier = innerIndex === 0 ? null : price.tiers[innerIndex - 1];
                                const isLastTier = innerIndex === price.tiers.length - 1;
                                return (
                                  <p key={`${outerIndex}-${innerIndex}`}>
                                    <span>
                                      {previousTier
                                        ? `${
                                            isLastTier ? `ab ${previousTier.up_to + 1}` : `${previousTier.up_to + 1} - `
                                          }`
                                        : '1 - '}
                                    </span>
                                    <span>{isLastTier ? '' : `${tier.up_to}`}</span>
                                  </p>
                                );
                              })}
                            </div>
                            <div className="col-start-2">
                              <p>
                                <b>Preis</b>
                              </p>
                              {price.tiers.map((tier, innerIndex) => (
                                <p key={`${outerIndex}-${innerIndex}`}>{`${(tier.unit_amount / 100)
                                  .toFixed(2)
                                  .replace('.', ',')} €`}</p>
                              ))}
                            </div>
                          </React.Fragment>
                        ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        <div>
          <Link to={{ pathname: 'https://whitelabel.tortija.de/agb/' }} target="_blank">
            <Button className="py-10">Allgemeine Geschäftsbedingungen</Button>
          </Link>
        </div>

        {tenantData?.legal?.avvLink !== undefined && tenantData?.legal?.avvDate !== undefined && (
          <div className="pt-50">
            <Headline level={2} className="mb-15">
              Auftragsverarbeitungs-Vertrag (AVV)
            </Headline>
            <label className="block text-18 font-regular opacity-50 mb-1">
              Der Vertrag wurde am {tenantData?.legal?.avvDate} vereinbart!
            </label>
            <div>
              <a
                href={
                  isNativeView === true
                    ? `https://www.tortija.de/redirect.php?url=${tenantData?.legal?.avvLink}`
                    : tenantData?.legal?.avvLink
                }
                target="_blank"
                rel="noreferrer"
                className="flex items-center"
              >
                <DownloadIcon className="w-25 h-25 text-accentColor mr-5" />
                <Button buttonStyle="text">Download PDF</Button>
              </a>
            </div>
          </div>
        )}

        <div className="pt-50">
          <Headline level={2} className="mb-15">
            Rechnungen
          </Headline>

          {loadingInvoices ? (
            <div>Die aktuellen Rechnungen werden geladen...</div>
          ) : (
            <div>
              {tenantData?.billing?.invoices && tenantData?.billing?.invoices.length > 0 ? (
                <>
                  {groupedData &&
                    Object.keys(groupedData).map(year => (
                      <Disclosure key={year} defaultOpen={year === currentYear}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-20 font-medium text-left rounded-20 bg-lightGray focus:outline-none mb-3">
                              <span>{year}</span>
                              <ChevronUpIcon
                                className={`${open ? 'transform rotate-180' : ''} w-35 h-35 text-accentColor`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4">
                              {groupedData?.[year].map((invoice, index) => (
                                <div
                                  key={index}
                                  className="flex items-center justify-between border-b border-opacity-50 py-10 mb-10"
                                >
                                  <div className="flex flex-wrap space-y-10">
                                    <div className="w-180">
                                      <p className="text-18">{invoice.date}</p>
                                      <p className="opacity-50">
                                        {invoice.quantity} Nutzer -{' '}
                                        {(invoice.amount / 100).toString().replace('.', ',')}€
                                      </p>
                                    </div>
                                    {invoice.status === 'paid' ? (
                                      <div className={styles.statusPayed}>
                                        <div className="ml-5 text-buttonTextColor">Bezahlt</div>
                                        <div>
                                          <CheckIcon className="w-20 h-20 text-buttonTextColor mr-5" />
                                        </div>
                                      </div>
                                    ) : invoice.status === 'void' ? (
                                      <div className={styles.statusPayment}>
                                        <div className="ml-5">Storniert</div>
                                        <div>
                                          <XIcon className="w-20 h-20 text-accentColor mr-5" />
                                        </div>
                                      </div>
                                    ) : (
                                      <div className={styles.statusPayment}>
                                        <div className="ml-5">Offen</div>
                                        <div>
                                          <ClockIcon className="w-20 h-20 text-accentColor mr-5" />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {invoice.link ? (
                                    <div className="flex space-x-15 flex-wrap">
                                      <a
                                        href={
                                          isNativeView === true
                                            ? `https://www.tortija.de/redirect.php?url=${invoice.link}`
                                            : invoice.link
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        className="flex items-center"
                                      >
                                        <div className="relative my-auto">
                                          <div className="">
                                            <Button className="py-10 pr-50">Download PDF</Button>
                                          </div>
                                          <div className="absolute right-15 top-2">
                                            <DownloadIcon
                                              width={25}
                                              height={25}
                                              className="text-buttonTextColor mr-5"
                                            />
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  ) : (
                                    <p className="text-18 opacity-50">
                                      Rechnung wird erstellt. Dies kann bis zu einer Stunde dauern.
                                    </p>
                                  )}
                                </div>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                </>
              ) : (
                'Derzeit sind noch keine Rechnungen verfügbar. Bitte versuche es später erneut.'
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyBilling;

import firebase from 'firebase';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
};
firebase.initializeApp(firebaseConfig);

// const appCheck = firebase.appCheck();
// appCheck.activate('6LfVzpAjAAAAAD6Fhlhql4u62-MOvNyS6-lIxZOe', true);

firebase
  .firestore()
  .enablePersistence()
  .catch(err => {
    console.log(err.message);
  });

export default firebase;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { toast } from 'react-toast';
import firebase from 'services/firebase';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'providers/AuthProvider';
import { generateRandomUid } from 'shared/functions/global';
import RecipeStepSwitch from '../../../Nutrition/components/RecipeStepSwitch';

type Props = {
  className?: string;
  currentStep?: any;
  currentStepValue?: string;
  planState?: any;
  planStateValue?: any;
  mainPopup?: any;
  popupContainerRef?: any;
};

const NewPlanStep4: React.FC<Props> = ({
  currentStep,
  currentStepValue,
  planState,
  mainPopup,
  planStateValue,
  popupContainerRef,
}) => {
  const [visible, setVisible] = useState('block');
  const [currentDescriptionValue, setCurrentDescriptionValue] = useState('');
  const { t } = useTranslation();

  const descriptionRef = useRef<HTMLDivElement>(null);

  function returnToPreviousStep() {
    setVisible('hidden');
    currentStep('3');
  }

  useEffect(() => {
    if (currentStepValue !== '4') {
      setVisible('hidden');
    }
    if (descriptionRef.current) {
      descriptionRef.current.focus();
    }
  }, [currentStepValue, planStateValue, visible, popupContainerRef]);

  const handleChange = (event: any) => {
    const thisValue = event.currentTarget.textContent.trim();

    setCurrentDescriptionValue(thisValue);
  };

  function jumpToNextStep() {
    const newColumns = {
      ...planStateValue,
      description: currentDescriptionValue,
    };

    if (currentDescriptionValue.length > 0) {
      planState(newColumns);
    }

    setVisible('hidden');
    currentStep('5');
  }

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  return (
    <>
      <div className={visible}>
        <div className="px-20">
          <div className="pb-100">
            <div className="font-bold text-18">Beschreibung / Notiz</div>
            <div className="pt-20 font-extralight text-base">
              Hier kannst du für deinen Plan noch eine Beschreibung bzw. Notiz hinzufügen.
            </div>
            <div className="pt-10 font-extralight text-base">
              Wenn du keine hinzufügen möchtest, kannst du auch direkt zum nächsten Schritt gehen!
            </div>
            <div
              ref={descriptionRef}
              onInput={handleChange}
              className="text-textColor mt-20 leading-6 bg-lightGray border border-opacity-30 border-textColor p-10 rounded-xl"
              contentEditable
              onFocus={setBodyClassForMobileSafari}
              onBlur={removeBodyClassForMobileSafari}
              suppressContentEditableWarning
            >
              {planStateValue?.description}
            </div>
          </div>
        </div>
      </div>
      <RecipeStepSwitch
        returnFunction={returnToPreviousStep}
        nextFunction={jumpToNextStep}
        currentStepValue="4"
        totalSteps={planStateValue?.planType === 'alghorithmus' ? 6 : 5}
      />
    </>
  );
};

export default NewPlanStep4;

import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'providers/AuthProvider';
import BGDesktopImg from 'assets/img/desktop/index-bg.png';
import BGImg from 'assets/img/mobile/index-bg.png';
import styles from './style.module.scss';

type Props = {
  isBackImage?: boolean;
  children: React.ReactNode;
};

const AuthLayout: React.FC<Props> = ({ children, isBackImage = false }) => {
  const { theme, tenantData, authenticated, userData } = useContext(AuthContext);

  if (
    authenticated &&
    window.location.pathname !== '/auth/oauth' &&
    window.location.pathname.includes('/auth') &&
    (userData?.profileComplete === true || userData?.role === 3 || userData?.role === 4 || userData?.role === 6)
  ) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.backImage}>
        <img className={styles.image} width={1100} height={900} src={theme?.bgImage ?? BGDesktopImg} alt="" />
      </div>
      <div className={styles.body}>
        <div
          className={classNames(
            styles.container,
            'custom-scrollbar',
            tenantData?.legal !== undefined ? 'bg-bgColor' : ''
          )}
        >
          {isBackImage && (
            <img
              className={styles.image}
              width={400}
              height={700}
              src={theme?.bgImage ?? BGImg}
              alt=""
              loading="lazy"
            />
          )}
          <div
            className={classNames(styles.content, {
              [tenantData?.legal !== undefined ? styles.isBackBusiness : styles.isBack]: isBackImage,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;

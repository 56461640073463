import React, { useContext, useState } from 'react';
import firebase from 'firebase';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import TransitionContainer from 'components/TransitionContainer';
import Headline from 'components/Headline';
import axios from 'axios';
import FirstPersonalInformation from 'pages/ProfileWizard/components/PersonalInformation/firstPage';
import ThirdPersonalInformation from 'pages/ProfileWizard/components/PersonalInformation/thirdPage';
import FormOfNutrition from 'pages/ProfileWizard/components/FormOfNutrition';
import EatingHabits from 'pages/ProfileWizard/components/EatingHabits';
import Incompatibilities from 'pages/ProfileWizard/components/Incompatibilities';
import ShareProfile from 'pages/ProfileWizard/components/ShareProfile';
import Button from 'components/Button';
import { AuthContext } from 'providers/AuthProvider';
import { ROLE_MEMBER } from 'shared/constants/global';
import SecondPersonalInformation from 'pages/ProfileWizard/components/PersonalInformation/secondPage';
import GoalStep from 'pages/ProfileWizard/components/GoalStep';
import styles from './styles.module.scss';

type Props = {
  isShown: boolean;
  title: string;
  goBack?: React.MouseEventHandler<HTMLButtonElement>;
};

const EditProfile: React.FC<Props> = ({ isShown, title, goBack = () => {} }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { userData, tenant, tenantData } = useContext(AuthContext);
  const db = firebase.firestore();

  const [isUpdating, setIsUpdating] = useState(false);

  const makroTypes = [
    { value: 'standard', label: 'Standard', carbohydrates: '50', protein: '25', fat: '25' },
    { value: 'ketogen', label: 'Ketogen', carbohydrates: '5', protein: '30', fat: '65' },
    { value: 'user', label: 'Benutzerdefiniert' },
  ];

  const onSubmit = async () => {
    let thisCarbohydratesValue = 0;
    let thisProteinValue = 0;
    let thisFatValue = 0;

    if (userData?.custom_nutrition_type !== undefined) {
      if (userData?.custom_nutrition_type === 'user') {
        thisCarbohydratesValue = Math.round(
          (parseFloat(userData?.calories) * parseFloat(userData?.custom_carbohydrates_percent!)) / 100 / 4.1
        );
        thisProteinValue = Math.round(
          (parseFloat(userData?.calories) * parseFloat(userData?.custom_protein_percent!)) / 100 / 4.1
        );
        thisFatValue = Math.round(
          (parseFloat(userData?.calories) * parseFloat(userData?.custom_fat_percent!)) / 100 / 9.3
        );
      } else {
        const getCarbohydratesPercent = makroTypes.filter(st => st.value === userData?.custom_nutrition_type)[0]
          .carbohydrates;
        const getProteinPercent = makroTypes.filter(st => st.value === userData?.custom_nutrition_type)[0].protein;
        const getFatPercent = makroTypes.filter(st => st.value === userData?.custom_nutrition_type)[0].fat;

        if (getCarbohydratesPercent !== undefined && getProteinPercent !== undefined && getFatPercent !== undefined) {
          thisCarbohydratesValue = Math.round(
            (parseFloat(userData?.calories) * parseFloat(getCarbohydratesPercent)) / 100 / 4.1
          );
          thisProteinValue = Math.round((parseFloat(userData?.calories) * parseFloat(getProteinPercent)) / 100 / 4.1);
          thisFatValue = Math.round((parseFloat(userData?.calories) * parseFloat(getFatPercent)) / 100 / 9.3);
        }
      }
    } else if (userData?.calories !== undefined) {
      thisCarbohydratesValue = Math.round((parseFloat(userData?.calories) * 50) / 100 / 4.1);
      thisProteinValue = Math.round((parseFloat(userData?.calories) * 25) / 100 / 4.1);
      thisFatValue = Math.round((parseFloat(userData?.calories) * 25) / 100 / 9.3);
    }

    setIsUpdating(true);
    try {
      const profile: UserInfo = authContext.userData as UserInfo;

      await db
        .collection(`tenants/${tenant}/users`)
        .doc(authContext.user?.uid)
        .set({
          ...profile,
          carbohydrates_total:
            thisCarbohydratesValue !== undefined && thisCarbohydratesValue !== 0
              ? thisCarbohydratesValue
              : userData?.carbohydrates_total,
          protein_total:
            thisProteinValue !== undefined && thisProteinValue !== 0 ? thisProteinValue : userData?.protein_total,
          fat_total: thisFatValue !== undefined && thisFatValue !== 0 ? thisFatValue : userData?.fat_total,
        });

      toast.success('Dein Profil wurde erfolgreich gespeichert!');
      setIsUpdating(false);
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.warn(error.message);
      setIsUpdating(false);
    }

    /* if (userData?.inBody?.mobileNumber !== undefined) {
      let inBodyApiKey = '';
      let inBodyAccount = '';

      try {
        const checkApiKeyObject = await firebase
          .firestore()
          .collection('apiKeys')
          .where('tenant', '==', tenant)
          .get()
          .then((querySnapshot: any) => {
            if (querySnapshot.size > 0) {
              const apiKeyObject = querySnapshot.docs[0].data();
              if (apiKeyObject.company === 'Inbody' && apiKeyObject.tenant === tenant) {
                inBodyApiKey = apiKeyObject.inBodyApiKey;
                inBodyAccount = apiKeyObject.inBodyAccount;
              }
            }
          })
          .catch(error => {
            console.log(error);
          });

        const options = {
          method: 'POST',
          url: 'https://api.tortija.de/api/inBodyWebhook',
          headers: { 'content-type': 'application/json' },
          data: {
            action: 'update',
            inBodyApiKey,
            inBodyAccount,
            tenant,
            mobileNumber: userData?.inBody?.mobileNumber,
          },
          json: true,
          withCredentials: false,
        } as any;

        await axios
          .request(options)
          .then(response => {
            console.log('updated');
            setIsUpdating(false);
          })
          .catch(err => {
            setIsUpdating(false);
            console.log(err);
          });
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        setIsUpdating(false);
      }

      toast.success('Dein Profil wurde erfolgreich gespeichert!');
    } */
  };

  return (
    <TransitionContainer isShown={isShown}>
      <div className={styles.header}>
        <Headline level={1} className="mb-20">
          {t(title)}
        </Headline>
        <div className={styles.buttons}>
          <Headline level={4} displayBackBtn goBack={goBack}>
            {t('Return')}
          </Headline>
          <Button
            isPending={isUpdating}
            disabled={isUpdating}
            buttonStyle={isUpdating ? 'dark' : 'primary'}
            onClick={onSubmit}
            className="w-200"
          >
            {t('Change')}
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        {(tenantData?.settings?.functions?.profileGoal === false ||
          tenantData?.settings?.functions?.profileEatForm === false ||
          tenantData?.settings?.functions?.profileEatingHabits === false ||
          tenantData?.settings?.functions?.profileIncompatibilities === false) && (
          <div className="font-extralight">
            <div>Einige Einstellungsmöglichkeiten sind deaktiviert.</div>
            <div>Wenn du etwas an diesen Einstellungen ändern möchtest, sprich bitte deinen Trainer / Coach an.</div>
          </div>
        )}
        <div>
          <div className="pb-30">
            <ThirdPersonalInformation isProfile />
          </div>
          <GoalStep isProfile />
          {(tenantData?.settings?.functions?.caloriesGoal === undefined ||
            tenantData?.settings?.functions?.caloriesGoal === true ||
            userData?.isAdminUser ||
            userData?.isTrainerUser ||
            userData?.isLocationAdminUser ||
            localStorage.getItem('currentUserToken')) && (
            <div className="rounded-3xl bg-lightGray md:mt-40 mt-20 py-40 px-10 md:px-40">
              <div>
                In den Einstellungen unter "Kalorienbedarf" kannst du deinen Kalorienbedarf und die
                Makronährstoff-Verteilung auch manuell anpassen.
              </div>
            </div>
          )}
        </div>

        {userData?.role === ROLE_MEMBER && <ShareProfile />}
        <FirstPersonalInformation />
        <SecondPersonalInformation hiddenHeader />
        <FormOfNutrition isProfile />
        <EatingHabits isProfile />
        <Incompatibilities isProfile />
      </div>
    </TransitionContainer>
  );
};

export default EditProfile;
